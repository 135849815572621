import React from "react";
import { SkeletonText } from "carbon-components-react";
import * as S from "./profile.styled";

export const SkeletonProfile = () => {
  return (
    <S.Container>
      <S.ProfilePicContainer>
        <S.SkeletonImg />
      </S.ProfilePicContainer>
      <S.Heading>Name:</S.Heading>
      <S.FieldSet>
        <SkeletonText heading={true} />
      </S.FieldSet>
      <S.Heading>Email:</S.Heading>
      <S.FieldSet>
        <SkeletonText heading={true} />
      </S.FieldSet>
      <S.Heading>Phone:</S.Heading>
      <S.FieldSet>
        <SkeletonText heading={true} />
      </S.FieldSet>
    </S.Container>
  );
};
