import React from "react";
import * as S from "./empty.styled";
const Empty = () => {
  return (
    <S.Container>
      <S.Content>
        {/* <S.Img src="/icons/high--five.svg" />
        <S.SubHeading>No service request selected </S.SubHeading> */}
        <S.SubHeading>No Activity</S.SubHeading>
      </S.Content>
    </S.Container>
  );
};

export default Empty;
