import React from "react";

const Empty = () => {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        height: "200px",
        justifyContent: "center",
      }}
    >
      <p>Domain priorities have not been set</p>
    </div>
  );
};

export default Empty;
