import styled from "styled-components";

export const Container = styled.div`
  padding: 2rem 1rem;
  background-color: #ffffff;
  margin: 1rem;
  text-align: center;
  @media (min-width: 1280px) {
    width: 1000px;
    padding: 3rem;
    margin: 0 0 4rem 0;
  }
`;
export const Heading = styled.h1`
  font-size: clamp(1.9rem, 7vw, 1.75rem);
  font-weight: 500;
`;
export const Text = styled.div`
  margin-top: 1.75rem;
  font-size: clamp(1.6rem, 3vw, 1.25rem);
`;
