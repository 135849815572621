import { TextInput } from "carbon-components-react";
import { StyledWrapper } from "../AboutMe.styled";
import { shouldAutoComplete } from "../../../../../../../utils";

const HousingOccupancy = ({
  housingOccupancy,
  setHousingOccupancy,
  setDisableButton
}) => {
  return (
    <StyledWrapper style={{flexDirection: 'column'}}>
      <TextInput
        type="number"
        id="housingoccupancy"
        labelText=""
        placeholder="1"
        onChange={(e) => {
          if (e.target.value >= 0) {
            setDisableButton(false);
            setHousingOccupancy(e.target.value);
          } else {
            setDisableButton(true);
          }
        }}
        value={housingOccupancy}
        style={{width: '20rem'}}
        autoComplete={shouldAutoComplete}
      />
    </StyledWrapper>
  );
};

export default HousingOccupancy;
