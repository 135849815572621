import React from "react";
import ReactMarkdown from "react-markdown";
import * as S from "./item.styled";

export const Item = ({ label, heading, paragraphs }) => {
  return (
    <S.Container aria-label={label} style={{ whiteSpace: "pre-wrap" }}>
      <S.Heading aria-label={label}>{heading}</S.Heading>
      <ReactMarkdown>{paragraphs}</ReactMarkdown>
      {/* {paragraphs.map((paragraph) => (
        <>
          <S.Text>{paragraph}</S.Text>
          <div style={{ marginBottom: "1.25rem" }}>&nbsp;</div>
        </>
      ))} */}
    </S.Container>
  );
};
