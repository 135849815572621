import DoughnutChart from "./doughnut";
import BarChart from "./bar-chart";
import "@carbon/charts/styles.css";
// import { getTranslatedDomain } from "./utils"; // Not sure who wrote this or what it does, but breaks the system
const ToggleCharts = ({
  cms,
  domain,
  title,
  label,
  loading,
  data,
  type,
  toggle
}) => {
  const swappedData = data.map((item) => ({
    group: item.group,
    value: item.value
  }));
  if (type === "donut") {
    return (
      <DoughnutChart
        cms={cms}
        title={title}
        label={label}
        doughnutData={swappedData}
        loading={loading}
        toggle={toggle}
      />
    );
  } else if (type === "bar") {
    return (
      <BarChart
        cms={cms}
        title={title}
        loading={loading}
        barData={swappedData}
        toggle={toggle}
      />
    );
  } else {
    return (
      <BarChart
        cms={cms}
        title={title}
        loading={loading}
        barData={swappedData}
        toggle={toggle}
      />
    );
  }
};

export default ToggleCharts;
