import styled from "styled-components";

export const Card = styled.div`
  width: 95%;
  min-height: 12rem;
  margin: auto;
  margin-top: 1rem;
  background-color: white;
  padding: 1rem;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  border-radius: 5px; /* 5px rounded corners */
  :hover {
    background-color: aliceblue;
  }

  @media (max-width: 900px) {
    width: 95%;
    height: 18rem;
  }
`;
