import { Button } from 'carbon-components-react';
import { useMenuContext } from '../context/menu.context';
import S from '../elasticsearch.module.scss';

const Sidebar = ({
  wasSearched,
  showFilters,
  setshowFilters,
  children
}) => {

  const {
    mobileView
  } = useMenuContext();

  const show = () => {
    if (mobileView) {
      return wasSearched && showFilters;
    }
    return wasSearched;
  };

  return (
    <div
      className={S.sidebarContainer}
      style={{ display: show() ? 'block' : 'none' }}
    >
      {mobileView && (
        <Button
          style={{ minHeight: '2rem', marginBottom: '0.5rem', width: '100%' }}
          onClick={() => {
            setshowFilters(!show);
          }}
        >
          Save Filters
        </Button>
      )}
      {children}
    </div>
  );
};

export default Sidebar;