import styled from "styled-components";

export const Fieldset = styled.div`
  display: flex;
  padding: 0.25rem 0.5rem;
  width: 100%;
  align-items: center;
  cursor: pointer;
  background-color: ${(props) => (props.theme.hover ? "#e0e0e0" : "#fff")};
`;

export const Img = styled.img`
  width: 100%;
  height: 100%;
`;

export const Text = styled.p`
  padding-left: 0.25rem;
`;

export const CloseBtn = styled.div`
  position: absolute;
  outline: none;
  top: 21rem;
  padding: 1rem 2rem;
  background: #f4f4f4;
  border: 1px solid rgba(0, 0, 0, 0.25);
  display: none;
  @media (pointer: coarse) {
    display: block;
    display: flex;
    justify-content: center;
  }
`;

export const Circle = styled.div`
  background-color: ${(props) => props.theme.color};
  border-radius: 100%;
  margin: 0.15rem;
  padding: 0.75rem;
  width: 50px;
  height: 50px;
`;

export const Sub = styled.div`
  display: ${(props) => props.theme.show};
  background: ${(props) => props.theme.bg};
`;

export const ItemContainer = styled.div`
  position: relative;
  overflow-y: scroll;
  height: auto;
  max-height: 18rem;
`;
