import React, { useState, useEffect } from "react";
import { CSVLink } from 'react-csv';
import {
  DivHeader,
  MyClientsDiv,
  TableContainer,
} from "./static.jsx";
import styled from "styled-components";
import {
  StructuredListSkeleton,
  Table,
  TableHead,
  TableRow,
  TableHeader,
  TableBody,
  Pagination,
  TableCell,
  DataTable,
  TableToolbar,
  TableToolbarContent,
  TableToolbarSearch,
  Button,
  TooltipIcon
} from "carbon-components-react";
import BookmarkRow from "./BookmarkRow.jsx";
import ViewDashboardRow from "./ViewDashboardRow.jsx";
import CreateReferralRow from "./CreateReferralRow.jsx";
import ClientNameInformationRow from "./ClientNameInformationRow.jsx";
import ClientProfilePictureRow from "./ClientProfilePictureRow.jsx";
import ClientDateOfBirthRow from "./ClientDateOfBirthRow.jsx";
import ClientInformationPhoneNumberRow from "./ClientInformationPhoneNumberRow.jsx"
import {
  Bookmark20,
  BookmarkAdd20,
  BookmarkFilled20,
  Download16,
  Information16,
} from "@carbon/icons-react";

const MyClients = ({ loading, clients, clientDashboard, createReferral, setClients, isSuper }) => {
  const [filterBookmarked, setFilterBookmarked] = useState(true)
  const [formattedRows, setFormattedRows] = useState([]);
  const [formattedRowsDownload, setFormattedRowsDownload] = useState([]);

// For responsiveness
const [width, setWidth] = useState(window.innerWidth);
const isMobile = 500;

const filterBookmark = ()=> {
  let items = [];

  if(!filterBookmarked) {
    items = clients.map((el) => (
      { ...el, id: el.id }
    ));
    setFormattedRows(items)
  } else {
    clients.filter(el=> {
      if(el.bookmarked){
        items.push(el)
      }
    });
    setFormattedRows(items)
  }
}

useEffect(() => {
  const handleResizeWindow = () => setWidth(window.innerWidth);
  window.addEventListener("resize", handleResizeWindow);
  return () => {
    window.removeEventListener("resize", handleResizeWindow);
  };
}, []);


useEffect(()=> {
  const items =  clients.map((el) => (
    {
      ...el, 
      id: el.id, 
      phone: el?.phone,
      dateOfBirth: modifyDoB(el.dateOfBirth || el.dob)
    }
  ))

    setFormattedRows(items)
  }, [clients])

  const modifyDoB = (dob) => {
    if (dob !== undefined && dob !== "") {
      const temp = dob.split("-");
      return `${temp[1]}/${temp[2]}/${temp[0]}`;
    }
    return "";
  };

  useEffect(() => {
    let tempRows = [...formattedRows]
    tempRows.forEach(a => {
      delete a.img
      delete a.currentStatus
    })
    setFormattedRowsDownload(tempRows);
  }, [formattedRows])


const headers = [
  {
    key: "bookmarked",
    header: "Bookmark",
    label: "Bookmark",
    isSortable: false,
  },
  // {
  //   key: "img",
  //   header: "",
  //   isSortable: false,
  // },
  {
    key: "name",
    header: "Name",
    label: "Name",
    isSortable: false,
  },
  {
    key: "dateOfBirth",
    header: "Date of Birth",
    label: "Date of Birth",
    isSortable: false,
  },
  {
    key: "phone",
    header: "Phone Number",
    label: "Phone Number",
    isSortable: false,
  },
  {
    key: "viewDashboard",
    header: "View Dashboard",
    isSortable: false,
  },
  {
    key: "createReferral",
    header: "Provide Support",
    isSortable: false
  }
];

const headersDownload = [
  {
    key: "id",
    header: "Id",
    label: "Id",
    isSortable: false,
  },
  {
    key: "bookmarked",
    header: "Bookmark",
    label: "Bookmark",
    isSortable: false,
  },
  {
    key: "name",
    header: "Name",
    label: "Name",
    isSortable: false,
  },
  {
    key: "dateOfBirth",
    header: "Date of Birth",
    label: "Date of Birth",
    isSortable: false,
  },
  {
    key: "phone",
    header: "Phone Number",
    label: "Phone Number",
    isSortable: false,
  }
];

function printingCells(cell, id){
  if(cell.info.header === 'bookmarked') {
    return(
    <BookmarkRow
      key={Math.random()}
      client={cell.value}
      clientId={id}
      clientDashboard={clientDashboard}
      createReferral={createReferral}
      setClients={setClients}
      isSuper={isSuper}
    />
    )
    }
    // if (cell.info.header === 'img' && isMobile) {
    //   return(
    //   <ClientProfilePictureRow
    //     key={Math.random()}
    //     client={cell.value}
    //     clientDashboard={clientDashboard}
    //     createReferral={createReferral}
    //   />
    //    )
    // }
    if(cell.info.header === 'name'){
      return(
        <ClientNameInformationRow
        key={Math.random()}
        client={cell.value}
      />
      )
    }
    if(cell.info.header === 'dateOfBirth'){
      return(
        <ClientDateOfBirthRow
        key={Math.random()}
        client={cell.value}/>
      )
    }
    if(cell.info.header === 'phone'){
      return(
        <ClientInformationPhoneNumberRow
        key={Math.random()}
        client={cell.value}
        />
      )
    }
    if(cell.info.header === 'viewDashboard'){
      return(
        <ViewDashboardRow
          key={Math.random()}
          clientId={id}
          clientDashboard={clientDashboard}
          createReferral={createReferral}
        />
      )
    }
    if(cell.info.header === 'createReferral'){
      return(
        <CreateReferralRow
          key={Math.random()}
          clientDashboard={clientDashboard}
          clientId={id}
          createReferral={createReferral}
        />
      )
    }
  }

  return (
    <MyClientsDiv>
      <DivHeader style={{ marginTop: "1rem", marginBottom: "1rem", justifyContent: "space-between" }}>
        <h5>
          Connected Clients
          <TooltipIcon
              tooltipText="Search for individuals already on your care team."
              renderIcon={Information16}
              style={{position: "relative", top: "2px", marginLeft: "4px"}}
              direction="right"
          />
        </h5>
        <div style={{display: "flex", alignItems: "center"}}>
        <h5 style={{ marginRight: "1rem"}}>Download</h5>
        <CSVLink 
          data={formattedRowsDownload} 
          headers={headersDownload} 
          filename={`connected-clients-${new Date().toISOString().split("T")[0]}`}
          label="none"
        >
          <DownloadButton><Download16 /></DownloadButton>
        </CSVLink>
        </div>
      </DivHeader>
      {!clients ? (
        <TextWrapper>
        <h3>
          <strong>
            No clients found
          </strong>
        </h3>
      </TextWrapper>
      ): (
        <div  style={{ marginLeft:"1rem" , marginRight: "1rem" }}>
        {loading ? (
            <StructuredListSkeleton />
          ) : (
            <TableContainer style={{marginBottom: '2rem', maxWidth: "calc(100vw - 2rem)"}}>
              <DataTable
              rows={formattedRows}
              headers={headers}
              isSortable
              >
                {({
                  rows,
                  headers,
                  getTableProps,
                  getHeaderProps,
                  getRowProps,
                  onInputChange
                }) => (
                  <TableContainer>
                    <TableToolbar>
                      <TableToolbarContent>
                        <TableToolbarSearch
                        onChange={onInputChange}
                        placeholder="Search a client on your care team"
                        persistent={true}
                        >
                        </TableToolbarSearch>
                        <Button 
                          disabled={isSuper}
                          kind="secondary"
                          hasIconOnly
                          iconDescription="Un-Bookmark Patient"
                          onClick={()=> {
                            setFilterBookmarked(!filterBookmarked)
                            filterBookmark()
                          }}
                        >
                          Bookmarks
                          {filterBookmarked ? (
                            <Bookmark20 style={{marginLeft: "4px"}} />
                          ):(
                            <BookmarkFilled20 style={{marginLeft: "4px"}} />
                          )}
                        </Button>
                      </TableToolbarContent>
                    </TableToolbar>
                    <Table {...getTableProps()}>
                      <TableHead>
                        <TableRow>
                          {headers.map((header) => (
                            <TableHeader
                            {...getHeaderProps({ header })}
                            isSortable={header.isSortable}
                            >
                              {header.header}
                            </TableHeader>
                          ))}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {rows.map((row, i)=> {
                          return(
                            <TableRow key={`${i}:${row.id}`} {...getRowProps({ row })}>
                              {row.cells.map((cell)=> (
                                printingCells(cell, row.id)
                              ))}
                            </TableRow>
                          )
                        })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                )
                }
              </DataTable>

          </TableContainer>
          )}
        </div>
      )}
    </MyClientsDiv>
  );
};

const TextWrapper = styled.div`
  display: flex;
  /* justify-content: space-between; */
  margin-left: 1.3rem;
  /* margin-top: 0.5rem; */
  margin-bottom: 0.5rem;
  @media screen and (max-width: 500px) {
    h3 {
      font-size: 18px;
    }
  }
`;

const DownloadButton = styled(Button)`
  min-height: auto;
  padding: 1rem;
  height: 3rem;
`;

export default MyClients;
