import React, { useState, useContext, useEffect } from 'react';
import styled from 'styled-components';
import kit from 'ich-ui-kit';
import { Loading } from 'carbon-components-react';
import { ChevronLeft32 } from '@carbon/icons-react';
import {
  Card,
  AccountTitleContainer,
  AccountTitle,
  AccountTitleRow
} from '../../components';
import AssignServiceCard from './ServiceCard';

const Services = ({ staffToAssign, goBack }) => {
  const { user } = useContext(kit.UserContext);
  const { apiService } = useContext(kit.ApiContext);
  const [loading, setLoading] = useState(false);
  const [services, setServices] = useState([]);

  useEffect(() => {
    const getProviderInfo = async () => {
      try {
        if (!!staffToAssign && !!staffToAssign.id) {
          setLoading(true);
          const providerServices = await apiService.get(
            `/providerstaff/services?userId=${
              staffToAssign.id
            }&assignedOnly=false`
          );
          if (providerServices.ok) {
            let services = providerServices.services;
            setServices(services);
          }
        } else {
          throw new Error('staffToAssign is empty');
        }
        setLoading(false);
      } catch (err) {
        console.log(err);
      }
    };
    getProviderInfo();
  }, [apiService, staffToAssign]);

  return (
    <Card moreWidth>
      <AccountTitleContainer>
        <AccountTitleRow>
          <StyledCheveronLeft32 onClick={goBack} />
          <AccountTitle>{staffToAssign.name}'s Services</AccountTitle>
        </AccountTitleRow>
      </AccountTitleContainer>
      {loading ? (
        <Loading style={{ margin: '2rem auto' }} withOverlay={false} />
      ) : (
        services.map((provider) => {
          return (
            <AssignServiceCard
              user={user}
              provider={provider}
              staffToAssign={staffToAssign}
            />
          );
        })
      )}
    </Card>
  );
};

const StyledCheveronLeft32 = styled(ChevronLeft32)`
  background-color: white;
  color: blue;
  width: 3rem;
  height: 3rem;
  padding: 0.5rem;
  cursor: pointer;
  &:hover {
    background-color: #e5e5e5;
  }
`;

export default Services;
