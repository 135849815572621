import React from "react";
import { Modal, Grid, Row, Column, MultiSelect } from "carbon-components-react";
import styled from "styled-components";
import { updateReferralFilter } from "../../helpers/filter-helpers";

const OutboundFilterModal = ({
  allServices,
  modalOpen,
  setModalOpen,
  referrals,
  displayedItems,
  setDisplayedItems,
  assignStatuses,
  setAssignStatuses,
  referStatuses,
  setReferStatuses,
  selectServices,
  setSelectServices,
  filterBookmarked,
}) => {
  let services = [];
  allServices.map((serv, i) => {
    let name = serv.name;
    services.push({ id: serv.id, label: name });
  });

  const astatuses = [
    { id: "assigned", label: "Assigned" },
    { id: "unassigned", label: "Unassigned" },
  ];

  const rstatuses = [
    { id: "pending", label: "New Referral" },
    { id: "waiting for client", label: "Waiting for Client" },
    { id: "waiting for provider", label: "Waiting for Provider" },
    { id: "working", label: "In Progress" },
    { id: "closure", label: "Pending Closure" },
    { id: "close - client-rejected", label: "Closed - Canceled by Client" },
    { id: "closed - unable to resolve", label: "Closed - Unresolved" },
    { id: "closed - resolved", label: "Closed - Resolved" },
    { id: "closed - unable to contact", label: "Closed - Unable to Contact" },
    { id: "closed - canceled", label: "Closed - Canceled" },
    { id: "rejected by referee", label: "Client Rejected" },
    { id: "rejected by receiver", label: "Provider Cannot Accept" },
    { id: "about to expire - pending", label: "About to Expire" },
    { id: "referral expired", label: "Referral Expired" },
  ];

  return (
    <StyledModal
      open={modalOpen}
      shouldSubmitOnEnter={false}
      size="md"
      primaryButtonText={"Filter"}
      secondaryButtonText={"Cancel"}
      onRequestClose={() => {
        document
          .querySelectorAll(".bx--tag__close-icon")
          .forEach((e) => e.click());
        setReferStatuses([]);
        setAssignStatuses([]);
        setSelectServices([]);
        setDisplayedItems(referrals);
        setModalOpen(false);
      }}
      onSecondarySubmit={() => {
        document
          .querySelectorAll(".bx--tag__close-icon")
          .forEach((e) => e.click());
        setReferStatuses([]);
        setAssignStatuses([]);
        setSelectServices([]);
        setDisplayedItems(referrals);
        setModalOpen(false);
      }}
      onRequestSubmit={() => {
        updateReferralFilter(
          selectServices,
          assignStatuses,
          referStatuses,
          referrals,
          setDisplayedItems,
          filterBookmarked
        );
        setModalOpen(false);
      }}
    >
      <ModalWrapper>
        <TitleWrapper>
          <Title>Filter Referrals</Title>
          <Subtitle>
            Enter the referral information you would like to filter for
          </Subtitle>
        </TitleWrapper>
        <StyledGrid direction="column" style={{ padding: 0 }}>
          <Column>
            <StyledRowTop>
              <StyledMultiSelectService
                id={"firstselect"}
                items={services}
                disabled={false}
                open={true}
                itemToString={(item) => (item ? item.label : "")}
                titleText="Service"
                label="Filter by Service"
                initialSelectedItems={[]}
                onChange={(e) => {
                  setSelectServices(e.selectedItems);
                  // initialselectServices = e.selectedItems
                }}
                selectionFeedback="top-after-reopen"
              />
            </StyledRowTop>
            <StyledRowBottom>
              <StyledMultiSelectStatus
                items={astatuses}
                disabled={false}
                open={true}
                itemToString={(item) => (item ? item.label : "")}
                titleText="Assignment Status"
                label="Filter by Assignment"
                initialSelectedItems={[]}
                onChange={(e) => {
                  setAssignStatuses(e.selectedItems);
                  // initialassignStatuses = e.selectedItems
                }}
                selectionFeedback="top-after-reopen"
              />
              <StyledMultiSelect
                items={rstatuses}
                disabled={false}
                open={true}
                itemToString={(item) => (item ? item.label : "")}
                titleText="Referral Status"
                label="Filter by Referral Status"
                initialSelectedItems={[]}
                onChange={(e) => {
                  setReferStatuses(e.selectedItems);
                  // initialreferStatuses = e.selectedItems
                }}
                selectionFeedback="top-after-reopen"
              />
            </StyledRowBottom>
          </Column>
        </StyledGrid>
      </ModalWrapper>
    </StyledModal>
  );
};

const StyledModal = styled(Modal)`
  margin-right: 0;

  .bx--btn--primary {
    background-color: #0f62fe;
    :hover {
      background-color: #0f62fe90 !important;
    }
  }
  .bx--btn--secondary {
    background-color: #393939 !important;
    :hover {
      background-color: #39393990 !important;
    }
  }
  .bx--modal-content {
    @media screen and (max-width: 376px) {
      height: auto;
    }
  }
  .bx--modal-container {
    @media screen and (width: 375px) {
      height: 90%;
    }
    @media screen and (max-width: 416px) {
      height: 80%;
      width: 95%;
      top: 10%;
    }
  }
`;

const StyledColumn = styled(Column)`
  height: 500px;
`;

const StyledGrid = styled(Grid)`
  margin: 3rem 0rem 0rem 2rem;
  justify-content: space-evenly;
  width: auto;
  @media screen and (max-width: 767px) {
    width: 280px;
    margin: 3rem 0rem 0rem 2rem;
  }
`;

const StyledRowTop = styled(Row)`
  margin-bottom: 2rem;
  height: 170px;
`;

const StyledRowBottom = styled(Row)`
  // justify-content: space-between;
`;

const StyledMultiSelect = styled(MultiSelect)`
  width: 260px;
  @media screen and (max-width: 767px) {
    margin-bottom: 2rem;
  }
`;

const StyledMultiSelectStatus = styled(MultiSelect)`
  width: 260px;
  margin-right: 1rem;
  @media screen and (max-width: 767px) {
    margin-bottom: 2rem;
  }
`;

const StyledMultiSelectService = styled(MultiSelect)`
  width: 500px;
  @media screen and (max-width: 767px) {
    margin-bottom: 2rem;
    width: 260px;
  }
`;

const ModalWrapper = styled.div`
  margin-right: 0;
  height: 500px;
  width: 100%;
`;

const TitleWrapper = styled.div`
  justify-content: left;
  margin-bottom: 2rem;
`;

const Title = styled.h4``;

const Subtitle = styled.p``;

export default OutboundFilterModal;
