import { Button, ModalWrapper } from "carbon-components-react";
import styled from "styled-components";

// isSuper Assume Role
export const FlexHeader = styled.div`
  display: flex;
  width: calc(100% - 22rem);
  justify-content: space-between;
  margin-left: 10rem;
  align-items: baseline;

  @media screen and (max-width: 900px) {
    margin-left: 0;
    width: 100%;
    flex-direction: column;
    align-items: center;
  }
`;

export const AssumeRoleModal = styled(ModalWrapper)`
  .bx--btn--primary {
    height: fit-content;
    @media screen and (max-width: 900px) {
      margin-bottom: 1rem;
    }
  }
`

export const AssumeRoleButton = styled(Button)`
  height: fit-content;
  @media screen and (max-width: 900px) {
    margin-bottom: 1rem;
  }
`;

export const InnerContainer = styled.div`
  width: 100%;
  max-width: 1500px;
  padding: 0 1.5rem;
  height: auto;
`;

export const Header = styled.div`
  margin: 1.5rem 0 2rem;
  padding: 0 1rem;
  @media ${(props) => props.theme.breakpoints.md} {
    padding: 0;
  }
`;

export const Container = styled.div`
  width: 100%;
  position: relative;
  padding: 1rem;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
`;

export const TableContent = styled.div`
  height: 75vh;
  width: 100%;
  overflow-y: scroll;
`;

export const ActionContainer = styled.div`
  height: 1rem;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  @media (max-width: 900px) {
    margin: auto;
  }
`;

export const QrCodeCounterWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 1rem;
  gap: 0.25rem;
`;

export const QrCodeCounterText = styled.span`
  font-weight: 600;
  font-size: 18px;
`;
