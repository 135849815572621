import styled from "styled-components";

export const Container = styled.div`
  position: relative;
  width: 100%;
  min-height: 105vh;
  display: flex;
  @media ${(props) => props.theme.breakpoints.md} {
    padding: 0 1.5rem;
  }
  @media ${(props) => props.theme.breakpoints.sm} {
    display: grid;
    grid-template-columns: 40% 60%;
    grid-auto-rows: auto;
  }
  @media ${(props) => props.theme.breakpoints.lg} {
    display: grid;
    grid-template-columns: 35% 65%;
  }
`;

export const Feed = styled.div`
  position: relative;
  padding: 0 1rem;
  display: ${(props) => {
    if (!props.theme.isSM) {
      return "flex";
    } else {
      if (props.theme.referral !== undefined) {
        return "none";
      } else {
        return "flex";
      }
    }
  }};
  flex-flow: column nowrap;
  max-height: 100vh;
  overflow-y: auto;
  overflow-x: hidden;

  @media ${(props) => props.theme.breakpoints.sm} {
    padding: 0 0.5rem;
    overflow-y: scroll;
    height: auto;
  }
`;

export const Referral = styled.div`
  width: 100%;
  min-height: 100vh;
  display: ${(props) => {
    if (!props.theme.isSM) {
      return "block";
    } else {
      if (props.theme.referral !== undefined) {
        return "block";
      } else {
        return "none";
      }
    }
  }};

  ${(props) => {
    if (props.theme.referral === undefined) {
      return "max-height: 100vh";
    }
  }};

  padding: ${(props) => {
    if (props.theme.referral === undefined) {
      return "0 0.5rem";
    }
    return "0 0.5rem 1rem";
  }};

  // xs 540
  // sm 768
  // md 1024
`;
