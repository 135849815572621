import styled from "styled-components";
import { motion } from "@carbon/motion";
import { Button } from "carbon-components-react";

export const SideNav = styled.nav`
  margin-top: 3rem;
  box-shadow: 2px 0px 2px rgba(0, 0, 0, 0.1), 3px 0px 5px rgba(0, 0, 0, 0.05);
  position: absolute;
  background: #ffffff;
  overflow-x: hidden;
  height: 100%;
  left: ${(props) => {
    if (props.theme.isOpen) {
      return "0;";
    }
    return "-18rem;";
  }};
  top: 0;
  transition-timing-function: ${motion("entrance", "productive")};
  transition-duration: 240ms;
  width: 17rem;
  z-index: 999;

  @media (min-width: 1024px) {
    position: relative;
    width: 18.75rem;
    height: auto;
    background: #ffffff;
  }
`;

export const MobileBtn = styled(Button)`
  position: fixed !important;
  box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.25);
  top: 5rem;
  z-index: 11;
`;
export const Overlay = styled.div`
  position: absolute;
  z-index: 2;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  visibility: ${(props) => {
    if (props.theme.isOpen) {
      return "visible;";
    }
    return "hidden;";
  }};
  opacity: ${(props) => {
    if (props.theme.isOpen) {
      return "1;";
    }
    return "0;";
  }};
  transition-timing-function: ${motion("entrance", "productive")};
  transition-duration: 700ms;
  transition-property: opacity;
  z-index: ${(props) => {
    if (props.theme.isOpen) {
      return "11;";
    }
    return "0;";
  }};
  @media (min-width: 1024px) {
    display: none;
  }
`;

export const AccountWrapper = styled.div`
  @media screen and (min-width: 900px){
    height: 120%;
  }
`;
