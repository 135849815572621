import React, { useState } from "react";
import styled from "styled-components";
import {
  CheckmarkOutline20,
  IbmSecurity24,
  Collaborate24,
  Unknown24,
  WarningAltFilled16,
  CheckmarkOutline16,
  WarningAltFilled20,
  WarningAltFilled24,
} from "@carbon/icons-react";
import { Tooltip } from "carbon-components-react";

const CheckIcon = styled(CheckmarkOutline20)`
  margin-right: 0.5rem;
`;

const UnknownIcon = styled(Unknown24)`
  margin-right: 0.5rem;
`;

const CapacityIcon = styled(CheckIcon)`
  fill: #42be65;
`;

const WarningCapacityIcon = styled(CheckIcon)`
  fill: orange;
`;

const AtCapacityIcon = styled(CheckIcon)`
  fill: red;
`;

const EligableIcon = styled(IbmSecurity24)`
  margin-right: 0.5rem;
  fill: ${(props) => (props.eligable ? "#42be65" : "red")};
`;

const CareTeamIcon = styled(Collaborate24)`
  fill: #42be65;
  margin-right: 0.5rem;
`;

const Pill = styled.div`
  display: flex;
  width: 10rem;
  height: 2.5rem;
  margin-right: 1rem;
  margin-top: 1rem;
  padding: 0.3rem 0.5rem 0.3rem 0.5rem;
  color: white;
  border-radius: 50rem;
  background-color: #5c5c5c;
  align-items: center;
  @media screen and (max-width: 768px) {
    font-size: 10px;
    width: 6rem;
    /* span {
      font-size: 12px;
    }*/
  }
  /*span {
    font-size: 12px;
  } */
`;

const SmallPill = styled.div`
  display: flex;
  width: 10rem;
  margin-right: 1rem;
  margin-top: 1rem;
  padding: 0.3rem 0.5rem 0.3rem 0.5rem;
  color: white;
  border-radius: 50rem;
  background-color: #5c5c5c;
  align-items: center;
  @media screen and (max-width: 768px) {
    font-size: 10px;
    width: 6rem;
  }
`;

const ExpirePill = styled(SmallPill)`
  background-color: #4d4d4d;
`;

const BadgeDiv = styled.div`
  display: flex;
  flex-direction: row;
  width: 380px;
  @media screen and (max-width: 670px) {
    width: 250px;
    flex-direction: column;
    margin-left: 52px;
    margin-bottom: 1rem;
  }
  @media screen and (max-width: 310px) {
    flex-direction: column;
    margin-left: 45px;
    margin-bottom: 1rem;
  }
`;

export const CapacityBadge = ({ capacity }) => {
  switch (capacity) {
    case "GREEN":
      return (
        <Pill>
          <CapacityIcon />
          <span style={{ lineHeight: "1" }}>Available Now</span>
        </Pill>
      );
    case "YELLOW":
      return (
        <Pill>
          <WarningCapacityIcon />
          <span style={{ lineHeight: "1" }}>Almost at Capacity</span>
        </Pill>
      );
    case "RED":
      return (
        <Pill>
          <AtCapacityIcon />
          <span style={{ lineHeight: "1" }}>No Capacity</span>
        </Pill>
      );
    case "":
      return (
        <Pill>
          <UnknownIcon />
          <span style={{ lineHeight: "1" }}>Capacity Unknown</span>
        </Pill>
      );
    default:
      return (
        <Pill>
          <UnknownIcon />
          <span style={{ lineHeight: "1" }}>Capacity Unknown</span>
        </Pill>
      );
  }
};


export const EligableBadge = ({ eligable }) => {
  return (
    <>
      <Pill>
        <EligableIcon eligable={eligable} />
        <span style={{ lineHeight: "1" }}>
          {eligable ? "Connected Provider" : "Out of Network"}
        </span>
      </Pill>
    </>
  );
};

export const OnCareTeamBadge = () => {
  return (
    <>
      <Pill>
        <CareTeamIcon />
        <span style={{ lineHeight: "1" }}>On Care Team</span>
      </Pill>
    </>
  );
};

export const TypeBadge = () => {
  return (
    <>
      <Pill>
        <CareTeamIcon />
        <span style={{ lineHeight: "1" }}>Housing</span>
      </Pill>
    </>
  );
};

export const AboutToExpireBadge = () => {
  const [openExpireTooltip, setOpenExpireTooltip] = useState(false);
  return (
    <>
      <ExpirePill
        onMouseEnter={() => setOpenExpireTooltip(true)}
        onMouseLeave={() => setOpenExpireTooltip(false)}
      >
        <div style={{ paddingTop: "0.5rem" }}>
          <Tooltip
            open={openExpireTooltip}
            direction="bottom"
            showIcon={false}
            align="start"
          >
            <p>
              Referrals expire in 30 days if not accepted by
              client and provider.
            </p>
          </Tooltip>
        </div>
        <WarningAltFilled24 style={{ color: "#ff3636" }} />
        <span style={{ paddingLeft: "0.5rem" }}>About to Expire</span>
      </ExpirePill>
    </>
  );
};

export const Badges = ({
  type,
  onTeam,
  capacity,
  connected,
  style,
  showCapacity,
}) => {
  return (
    <>
      <BadgeDiv style={style}>
        {connected && <EligableBadge eligable={connected} />}
        {showCapacity && connected && <CapacityBadge capacity={capacity} />}
        {!!type && <TypeBadge />}

        {!!onTeam && <OnCareTeamBadge />}
      </BadgeDiv>
    </>
  );
};

// import React from "react";
// import styled from "styled-components";
// import {
//   CheckmarkOutline24,
//   IbmSecurity24,
//   Collaborate24,
//   Unknown24,
// } from "@carbon/icons-react";

// const CheckIcon = styled(CheckmarkOutline24)`
//   margin-right: 0.5rem;
// `;

// const UnknownIcon = styled(Unknown24)`
//   margin-right: 0.5rem;
// `;

// const CapacityIcon = styled(CheckIcon)`
//   fill: #42be65;
// `;

// const WarningCapacityIcon = styled(CheckIcon)`
//   fill: orange;
// `;

// const AtCapacityIcon = styled(CheckIcon)`
//   fill: red;
// `;

// const EligableIcon = styled(IbmSecurity24)`
//   fill: #80affe;
//   margin-right: 0.5rem;
// `;

// const CareTeamIcon = styled(Collaborate24)`
//   fill: #42be65;
//   margin-right: 0.5rem;
// `;

// const Pill = styled.div`
//   display: flex;
//   width: 12rem;
//   margin-right: 1rem;
//   margin-top: 1rem;
//   padding: 0.3rem 0.5rem 0.3rem 0.5rem;
//   color: white;
//   border-radius: 50rem;
//   background-color: #5c5c5c;
//   align-items: center;
// `;

// const MiniPill = styled(Pill)`
//   width: 6rem;
//   margin: 0;
// `;

// const BadgeDiv = styled.div`
//   display: flex;
//   flex-direction: row;
//   @media (max-width: 900px) {
//     flex-direction: column;
//     margin: auto;
//   }
// `;

// export const CapacityBadge = ({ capacity }) => {
//   switch (capacity) {
//     case "GREEN":
//       return (
//         <Pill>
//           <CapacityIcon />
//           <span>Available Now</span>
//         </Pill>
//       );
//     case "YELLOW":
//       return (
//         <Pill>
//           <WarningCapacityIcon />
//           <span>Almost at Capacity</span>
//         </Pill>
//       );
//     case "RED":
//       return (
//         <Pill>
//           <AtCapacityIcon />
//           <span>No Capacity</span>
//         </Pill>
//       );
//     case null:
//       return (
//         <Pill>
//           <UnknownIcon />
//           <span>Capacity Unknown</span>
//         </Pill>
//       );
//     default:
//       return (
//         <Pill>
//           <CheckIcon />
//           <span>Not Set</span>
//         </Pill>
//       );
//   }
// };

// export const EligableBadge = () => {
//   return (
//     <>
//       <Pill>
//         <EligableIcon />
//         <span>In Network</span>
//       </Pill>
//     </>
//   );
// };

// export const OnCareTeamBadge = () => {
//   return (
//     <>
//       <Pill>
//         <CareTeamIcon />
//         <span>On Care Team</span>
//       </Pill>
//     </>
//   );
// };

// export const TypeBadge = () => {
//   return (
//     <>
//       <MiniPill>
//         <span>Housing</span>
//       </MiniPill>
//     </>
//   );
// };

// export const Badges = ({ onTeam, capacity, eligable, style, showCapacity = true }) => {
//   return (
//     <>
//       <BadgeDiv style={style}>
//         {!!showCapacity && <CapacityBadge capacity={capacity} />}
//         {!!eligable && <EligableBadge />}
//         {!!onTeam && <OnCareTeamBadge />}
//       </BadgeDiv>
//     </>
//   );
// };
