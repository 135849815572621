import { useEffect, useState } from "react";
import { useStrapi } from "../../hooks/useStrapi";

export const useServiceDirectoryCMS = () => {
  const init = {
    heading_service_directory: "Service Directory",
    btn_clear_filters: "Clear Filters",
    placeholder_search: "Search by service, provider, or need",
    placeholder_zipcode: "Zip code (00000)",
    label_recommended: "Recommended",
    label_get_support: "Get Support",
    badge_connected: "Connected Provider",
    badge_on_care_team: "On Care Team",
    label_service_capacity: "Service Capacity",
    badge_almost_capacity: "Almost at Capacity",
    badge_no_capacity: "No Capacity",
    capacity_unknown: "Capacity Unknown",
    text_num_org:
      "Number of Organizations Actively Participating in the MyWayfinder Network.",
    label_age_ranges: "Age Ranges",
    label_age_child: "Child",
    label_age_youth: "Youth",
    label_age_young_ad: "Young Adult",
    label_age_adult: "Adult",
    label_age_older: "Older Adult",
    label_languages: "Languages",
    label_distance: "Distance",
    label_distance_none: "None",
    label_distance_within: "Within [x] miles",
    label_distance_less: "Less than [x] miles",
    label_distance_more: "More than [x] miles",
    // badge_avl_now: "Available Now",
    badge_avl_now: "Available",
    btn_availability: "Availability",
    btn_recommended: "Recommended",
    btn_bookmark: "Bookmarked",
    text_recommended_services:
      "Doing a check-in can help us better understand your needs and recommend resources that can support you",
    label_checkin: "Check-In",
    btn_list: "List",
    btn_map: "Map",
    btn_filter: "Filter",
    label_bookmarked_services: "Bookmarked Services",
    label_services_matching: "Services matching your search",
    label_no_search: "No Search Results",
    label_search_services: "Search for Services",
    btn_search: "Search",
    label_resource_map: "Resource Map",
    btn_view_more: "View more",
    btn_view_less: "View less",
    badge_out_of_network: "Out of Network",
    label_none: "None",
    confidential_address_text: "Address is confidential, please call for more information",
    list_actions: {
      id: 3,
      heading: "Actions",
      label: "Actions",
      item: [
        {
          id: 7,
          text: "Add to Care Team",
          value: "Add to Care Team",
          key: "add_care_team"
        },
        {
          id: 8,
          text: "Create Referral",
          value: "Create Referral",
          key: "create_referral"
        },
        {
          id: 9,
          text: "Send Needs Request",
          value: "Send Needs Request",
          key: "send_needs_request"
        },
        {
          id: 10,
          text: "Bookmark",
          value: "Bookmark",
          key: "bookmark"
        },
        {
          id: 15,
          text: "Remove Bookmark",
          value: "Remove Bookmark",
          key: "remove_bookmark"
        },
        {
          id: 18,
          text: "Remove from care team",
          value: "Remove from care team",
          key: "remove_care_team"
        }
      ]
    },
  };
  const { data, loading } = useStrapi({
    cms: `page-service-directory`
  });

  const [serviceDirectory, setServiceDirectory] = useState(init);

  useEffect(() => {
    if (loading) {
      setServiceDirectory(init);
      return;
    }
    setServiceDirectory((state) => ({
      heading_service_directory:
        data.heading_service_directory || state.heading_service_directory,
      btn_clear_filters: data.btn_clear_filters || state.btn_clear_filters,
      placeholder_search: data.placeholder_search || state.placeholder_search,
      placeholder_zipcode:
        data.placeholder_zipcode || state.placeholder_zipcode,
      label_recommended: data.label_recommended || state.label_recommended,
      label_get_support: data.label_get_support || state.label_get_support,
      badge_connected: data.badge_connected || state.badge_connected,
      badge_on_care_team: data.badge_on_care_team || state.badge_on_care_team,
      label_service_capacity:
        data.label_service_capacity || state.label_service_capacity,
      badge_almost_capacity:
        data.badge_almost_capacity || state.badge_almost_capacity,
      badge_no_capacity: data.badge_no_capacity || state.badge_no_capacity,
      capacity_unknown: data.capacity_unknown || state.capacity_unknown,
      text_num_org: data.text_num_org || state.text_num_org,
      label_age_ranges: data.label_age_ranges || state.label_age_ranges,
      label_age_child: data.label_age_child || state.label_age_child,
      label_age_youth: data.label_age_youth || state.label_age_youth,
      label_age_young_ad: data.label_age_young_ad || state.label_age_young_ad,
      label_age_adult: data.label_age_adult || state.label_age_adult,
      label_age_older: data.label_age_older || state.label_age_older,
      label_languages: data.label_languages || state.label_languages,
      label_distance: data.label_distance || state.label_distance,
      label_distance_none:
        data.label_distance_none || state.label_distance_none,
      label_distance_within:
        data.label_distance_within || state.label_distance_within,
      label_distance_less:
        data.label_distance_less || state.label_distance_less,
      label_distance_more:
        data.label_distance_more || state.label_distance_more,
      badge_avl_now: data.badge_avl_now || state.badge_avl_now,
      list_actions: data.list_actions || state.list_actions,
      btn_availability: data.btn_availability || state.btn_availability,
      btn_recommended: data.btn_recommended || state.btn_recommended,
      btn_bookmark: data.btn_bookmark || state.btn_bookmark,
      text_recommended_services:
        data.text_recommended_services || state.text_recommended_services,
      label_checkin: data.label_checkin || state.label_checkin,
      btn_list: data.btn_list || state.btn_list,
      btn_map: data.btn_map || state.btn_map,
      btn_filter: data.btn_filter || state.btn_filter,
      label_bookmarked_services:
        data.label_bookmarked_services || state.label_bookmarked_services,
      label_services_matching:
        data.label_services_matching || state.label_services_matching,
      label_no_search: data.label_no_search || state.label_no_search,
      label_search_services:
        data.label_search_services || state.label_search_services,
      btn_search: data.btn_search || state.btn_search,
      label_resource_map: data.label_resource_map || state.label_resource_map,
      btn_view_more: data.btn_view_more || state.btn_view_more,
      btn_view_less: data.btn_view_less || state.btn_view_less,
      badge_out_of_network:
        data.badge_out_of_network || state.badge_out_of_network,
      label_none: data.label_none || state.label_none,
      confidential_address_text: data.confidential_address_text || state.confidential_address_text
    }));
  }, [data]);

  return { serviceDirectory };
};

export const useServiceInfoCMS = () => {
  // TODO: add init content here
  const init = {
    label_overview: "Description",
    label_service_area: "Service Area",
    label_coverage_area: "Coverage Area",
    label_eligibility: "Eligibility",
    label_required_documents: "Documents Needed",
    label_accessibility: "Accessibility",
    label_fees: "Fees",
    label_application_process: "How to Apply",
    label_address: "Address",
    label_contact: "Contact",
    label_hours: "Hours",
    label_other: "Other",
    labels_nav: [
      { id: 1, link_text: "Description"},
      { id: 2, link_text: "Eligibility"},
      { id: 3, link_text: "Coverage Area" },
      { id: 4, link_text: "Other" },
    ],
  };
  const { data, loading } = useStrapi({
    cms: `page-service-info`,
  });

  const [serviceInfo, setServiceInfo] = useState(init);

  // TODO: uncomment once wired to Strapi
  useEffect(() => {
    if (loading) {
      setServiceInfo(init);
      return;
    }
    setServiceInfo((state) => ({
      heading_overview: data.heading_overview || state.heading_overview,
      heading_services: data.heading_services || state.heading_services,
      btn_send_needs_request:
        data.btn_send_needs_request || state.btn_send_needs_request,
      label_overview: data.label_overview || state.label_overview,
      label_service_area: data.label_service_area || state.label_service_area,
      label_coverage_area:
        data.label_coverage_area || state.label_coverage_area,
      label_eligibility: data.label_eligibility || state.label_eligibility,
      label_required_documents:
        data.label_required_documents || state.label_required_documents,
      label_fees: data.label_fees || state.label_fees,
      label_application_process:
        data.label_application_process || state.label_application_process,
      label_address: data.label_address || state.label_address,
      label_contact: data.label_contact || state.label_contact,
      label_other: data.label_other || state.label_other,
      labels_nav: data.labels_nav  || state.labels_nav
    }));
  }, [data]);

  return { serviceInfo };
};
