import { useState, useEffect } from "react";
import styled from "styled-components";
import { Button, TextInput } from "carbon-components-react";
import * as S from "../Questions.styles";
import { shouldAutoComplete } from "../../../../../../utils";

const Income2 = ({
  cms,
  domain,
  width,
  setAllowContinue,
  incomeResponse,
  setIncomeResponse,
  setFeedbackIndex,
  setOverallIndex
}) => {
  const [valid, setValid] = useState(false);

  useEffect(() => {
    if (incomeResponse.q2.answer !== " ") {
      setButtonSelection(incomeResponse.q2.answer);
      setAllowContinue(false);
    } else {
      setAllowContinue(true);
    }
  }, []);

  const buttonLabels = ["Myself", "2", "3", "4", "5", "6", "7 or more"];

  const answerMapping = {
    Myself: 0,
    2: 1,
    3: 2,
    4: 3,
    5: 4,
    6: 5,
    "7 or more": 6
  };
  const [buttonSelection, setButtonSelection] = useState();

  const checkInput = (input) => {
    if (input.match(/^[0-9]+$/)) {
      setValid(true);
      setIncomeResponse((state) => ({
        ...state,
        q2: {
          text: "2 How many people including yourself live in your household?",
          answer: input
        }
      }));
      setAllowContinue(false);
    } else {
      setValid(false);
      setAllowContinue(true);
    }
  };

  return (
    <S.TextContainer>
      <h5>{cms.income.question_2}</h5>
      <S.Wrapper>
        {/* {width < 769 ? (
          <S.DropdownWrapper>
            <S.StyledDropdown
            ariaLabel="Dropdown"
            id="carbon-dropdown-example"
            items={buttonLabels}
            label={buttonSelection || "Select option..."}
            onChange={(e) => {
              setAllowContinue(false);
              setButtonSelection(e.selectedItem);
              console.log(e);
              console.log(e.selectedItem);
              setIncomeResponse((state) => ({
                ...state,
                q2: {text: "2 How many people including yourself live in your household?", answer: answerMapping[e.selectedItem]}
              }));
              setFeedbackIndex((index) => index + 1)
              setOverallIndex((index) => index + 1)
            }}
            
            />
        </S.DropdownWrapper>
        ):(
          <S.ButtonGroup>
          {
            buttonLabels.map((label, i) => {
              let bcolor;
              {buttonSelection === label ? bcolor = true : bcolor = false}
              return(
                <S.StyledButton 
                  key={i}
                  theme={{bcolor: bcolor, width: '80px', padding: '1rem', margin: '1rem'}}
                  kind="tertiary"
                  onClick={() => {
                    setAllowContinue(false);
                    setButtonSelection(label);
                    setIncomeResponse((state) => ({
                      ...state,
                      q2: {text: "2 How many people including yourself live in your household?", answer: answerMapping[label]},
                    }))
                  }}
                  >{label}</S.StyledButton>
                )
            })
          }
        </S.ButtonGroup>
      )} */}
      </S.Wrapper>
      <InputWrapper>
        <TextInput
          required
          placeholder={cms.income.placeholder_enter_number}
          valid={valid}
          onChange={(e) => checkInput(e.target.value)}
          autoComplete={shouldAutoComplete}
        ></TextInput>
      </InputWrapper>
    </S.TextContainer>
  );
};

export default Income2;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
  @media screen and (min-width: 1800px) {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
  @media screen and (max-width: 768px) {
    height: 60%;
    overflow-y: scroll;
  }
`;

export const InputWrapper = styled.div`
  margin-top: 5%;
  display: flex;
  width: 40%;
  justify-content: center;
  h5 {
    margin-right: 0.25rem;
  }
  @media screen and (min-width: 1800px) {
    width: 35%;
  }
  @media screen and (max-width: 768px) {
    margin: 1rem 0rem 2rem 0rem;
    width: 50%;
    align-items: center;
  }
`;
