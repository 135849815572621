import React from "react";
import { Link, Button, TextInput, Dropdown, TextArea } from "carbon-components-react";
import styled from "styled-components";
import { parseHtml } from "../../../../utils";
import * as S from "./ReviewAndSubmit.styles";
import { Send16 } from "@carbon/icons-react";
import { useState } from "react";

const ReviewAndSubmit = ({ client, service, user, submitReferral, domains }) => {
  const date = new Date();
  const [noteText, setNoteText] = useState('');
  const [domain, setDomain] = useState("");
  const [cannotSubmit, setCannotSubmit] = useState(true);

  const handleDropdownOptions = () => {
    if(domains?.length >= 1){
      return domains;
    } else {
      return dropdownLabels;
    }
  }

  const dropdownLabels = ['Food', 'Housing', 'Health', 'Employment', 'Income', 'Education', 'Financial', 'Transportation', 'Safety', 'Social Connections', 'Dependent Care' ]
  
  return (
    <MaxWidthContainer>
      <SectionWrapper>
        <span>
          <SectionHeading>Referral submitted by:</SectionHeading>
          <p>{user?.aliasName || user?.fullName || user?.givenName}</p>
        </span>
      </SectionWrapper>

      <SectionWrapper>
        <span>
          <SectionHeading>Referral for:</SectionHeading>
          <p>
            {client?.name
              ? client.name
              : `${client?.givenName || client?.firstName} ${client?.familyName || client?.lastName}`}
          </p>
        </span>
      </SectionWrapper>
      <SectionWrapper>
        <span>
          <SectionHeading>Provider Info</SectionHeading>
          <p>{parseHtml(service?.overview)}</p>
        </span>
      </SectionWrapper>
      <SectionWrapper>
        <span>
          <SectionHeading>Referral Notes</SectionHeading>
            <TextArea
              data-id={`create-referral-note-input`}
              id="note-input"
              labelText="Add Note"
              hideLabel
              placeholder="Add note to this referral..."
              value={noteText}
              onChange={(e) => {
                setNoteText(e.target.value)
              }}
              invalid={noteText.length > 1000}
              invalidText='Referral note cannot exceed 1000 characters'
              style={{ border:  '1px solid #8d8d8d'}}
              light
            />
        </span>
      </SectionWrapper>
      <SectionWrapper>
        <span>
          <SectionHeading>Referral Domain</SectionHeading>
          <Dropdown
            data-id={`create-referral-domain-dropdown`}
            ariaLabel="Domain Dropdown"
            id="carbon-dropdown-example"
            items={handleDropdownOptions()}
            label={domain || "Select reason for referral..."}
            onChange={(e) => {
              setDomain(e.selectedItem);
              setCannotSubmit(false);
            }}>

            </Dropdown>
        </span>
      </SectionWrapper>
      <SectionWrapper>
        <span>
          <SectionHeading>Referral Date</SectionHeading>
          <p>{date.toLocaleDateString()}</p>
        </span>
      </SectionWrapper>

      <SectionWrapper>
        <StyledButton 
          data-id={`create-referral-submit-btn`}
          disabled={cannotSubmit || noteText.length < 3 || noteText.length > 1000} 
          onClick={() => submitReferral(noteText, domain)}
        >Submit Referral
        </StyledButton>
        <p style={{marginTop: ".5rem", color: "gray"}}>You must enter a note and select a domain to submit the referral.</p>
      </SectionWrapper>
    </MaxWidthContainer>
  );
};

export default ReviewAndSubmit;

const MaxWidthContainer = styled.div`
  max-width: 500px;
  margin: 0 auto;
`;

const StyledButton = styled(Button)`
  max-width: 100%;
  width: 100%;
`;

const SectionHeading = styled.h5`
  margin: 1rem 0;
`;

const SectionWrapper = styled.div`
  margin: 0rem 0rem 3rem 0rem;

  && .bx--select {
    max-width: 100%;
    width: 40%;
  }

  @media (max-width: 480px) {
    && .bx--select {
      width: 100%;
    }
  }
`;
