import styled from "styled-components";
import { useState, useEffect, useContext } from 'react';
import { createPortal } from "react-dom";
import { 
    Button, 
    DataTable, 
    InlineLoading, 
    Modal, 
    Table, 
    TableBody, 
    TableCell, 
    TableContainer, 
    TableHead, 
    TableHeader, 
    TableRow, 
    TextInput 
} from "carbon-components-react";
import { TrashCan20 } from "@carbon/icons-react";
import kit from "ich-ui-kit";
import { shouldAutoComplete } from "../../../utils";
import { useMWFSearch, getSavedSearches, addSavedSearch, deleteSavedSearch } from "../../../store/MWFSearchContext";

const mount = document.getElementById("modal-root");

function SavedSearchesModal(props) {
    const { apiService } = useContext(kit.ApiContext);
    const [input, setInput] = useState("");
    const [isError, setIsError] = useState(false);
    const { user } = useContext(kit.UserContext);
    const [{savedSearches, savedSearchesIsLoading, savedSearchesError, addSavedSearchesIsLoading, addSavedSearchesError, deleteSavedSearchIsLoading, deleteSavedSearchError}, dispatch] = useMWFSearch();


    useEffect(() => {
        getSavedSearches(dispatch, { apiService, user, savedSearches, savedSearchesIsLoading });
    }, []);


    useEffect(() => {
        if(isError){
            setTimeout(() => {
                setIsError(false);
            }, 3000);
        }
    }, [isError]);

    const handleClick = (term) => {
        // updated for enhanced search
        props.handleSearch(term);
        props.close(false);
    }

    const handleSave = async () => {
        addSavedSearch(dispatch, { apiService, user, addSavedSearchesIsLoading, term: input })
    }

    const handleDelete = async (id) => {
        deleteSavedSearch(dispatch, { apiService, user, deleteSavedSearchIsLoading, id })
    }

    const headerData = [
        {
            key: "term",
            header: "Search Terms"
        }
    ];

    function sort(x, y){
        if (x.cells[0].value.toLowerCase() < y.cells[0].value.toLowerCase()) return -1;
        if (x.cells[0].value.toLowerCase() > y.cells[0].value.toLowerCase()) return 1;
        return 0;
    }

    return createPortal(
        <StyledModal
            passiveModal
            id={`saved-searches-modal`}
            data-id={`saved-searches-modal`}
            data-cy={`saved-searches-modal`}
            modalHeading='Saved Searches'
            aria-label="Saved Searches Modal"
            hasScrollingContent
            size="sm"
            open={props.open}
            onRequestClose={() => {
                props.close(false);
                setInput("");
                setIsError(false);
            }}
        >
            <SearchWrapper>
                <TextInput 
                    id='saved-searches-input'
                    data-id='saved-searches-input'
                    data-cy='saved-searches-input'
                    placeholder="Enter a search to save..."
                    labelText=''
                    value={input}
                    onChange={(e) => setInput(e.target.value)}
                    disabled={addSavedSearchesIsLoading}
                    autoComplete={shouldAutoComplete}
                />
                <Button
                    size="md"
                    onClick={handleSave}
                    disabled={addSavedSearchesIsLoading}
                >
                    {addSavedSearchesIsLoading ? 
                        <InlineLoading style={{minHeight:"auto"}} /> 
                        : 
                        "Save"
                    }
                </Button>
            </SearchWrapper>
            {savedSearches && savedSearches?.length > 0 ?
                <DataTable rows={savedSearches} headers={headerData}>
                    {({ rows, headers, getHeaderProps, getTableProps }) => (
                        <TableContainer>
                        <Table {...getTableProps()}>
                            <TableHead>
                                <TableRow>
                                    {headers.map((header) => (
                                        <TableHeader {...getHeaderProps({ header })}>
                                            {header.header}
                                        </TableHeader>
                                    ))}
                                    <TableHeader></TableHeader>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                            {savedSearchesIsLoading ? (
                                <InlineLoading 
                                    description="Loading" 
                                    style={{
                                        paddingLeft: "1rem",
                                        paddingTop: "1rem"
                                    }}
                                />
                            ) : (
                                <>
                                    {rows.sort(sort).map((row) => (
                                        <TableRow key={row.id}>
                                            {row.cells.map((cell) => (
                                                <TableCell key={cell.id}>
                                                    <Clickable onClick={() => handleClick(cell.value)}>
                                                        {cell.value}
                                                    </Clickable>
                                                </TableCell>
                                            ))}
                                            <TableCell 
                                                key={`${row.id}-delete`}
                                                style={{textAlign:"right"}}
                                            >
                                                <Clickable disabled={deleteSavedSearchIsLoading} onClick={() => handleDelete(row.id)}>
                                                    <TrashCan20 color="gray" />
                                                </Clickable>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </>
                            )}
                            </TableBody>
                        </Table>
                        </TableContainer>
                    )}
                </DataTable>
                :
                <h5>No saved search terms found. Start saving search terms to quickly access your favorite searches.</h5>
            }
        </StyledModal>,
        mount
    )
}

const SearchWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1rem;
    margin-top: 1.5rem;
`;

const StyledModal = styled(Modal)`
    .bx--modal-content:focus {
        outline: none;
    }
`;

const Clickable = styled.a`
    cursor: pointer;
`;

export default SavedSearchesModal;