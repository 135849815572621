import React, { useState } from "react";
import {
  Row,
  Column,
  StructuredListWrapper,
  StructuredListBody,
  StructuredListHead,
  StructuredListRow,
  StructuredListCell
} from "carbon-components-react";

import {
  PrintablePage,
  DateContainer,
  PageContent,
  Summary,
  RecommendedResources,
  Questions,
  Header
} from "./print.styled";

const PrintablePageComponent = ({
  summary,
  recommendedResources,
  questions
}) => {
  const [date, setDate] = useState(new Date());
  const monthsArray = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December"
  ];

  const determineAssessmentSummary = (color) => {
    switch (color) {
      case "GREEN":
        return 5;
      case "LIGHT_GREEN":
        return 4;
      case "YELLOW":
        return 3;
      case "ORANGE":
        return 2;
      case "RED":
        return 1;
      default:
        return 0;
    }
  };

  const day = new Date(date).getDate();
  const month = new Date(date).getMonth();
  const year = new Date(date).getFullYear();

  const DateDisplay = () => {
    let monthAdjusted = false;
    let dayAdjusted = false;
    if (month < 9) {
      monthAdjusted = true;
    }
    if (day < 10) {
      dayAdjusted = true;
    }

    if (monthAdjusted && dayAdjusted) {
      return (
        <p>
          0{month + 1}.0{day}.{year}
        </p>
      );
    } else if (monthAdjusted && !dayAdjusted) {
      return (
        <p>
          0{month + 1}.{day}.{year}
        </p>
      );
    } else if (!monthAdjusted && dayAdjusted) {
      return (
        <p>
          {month + 1}.0{day}.{year}
        </p>
      );
    }
    return (
      <p>
        {month + 1}.{day}.{year}
      </p>
    );
  };
  return (
    <PrintablePage id="printable">
      <Row>
        <Column>
          <Header>
            <img
              src="/MyWayFinderBanner.png"
              alt="MyWayfinder Banner"
              width="650px"
              height="100%"
              style={{ display: "block" }}
            />
          </Header>
        </Column>
      </Row>
      <DateContainer>
        <div>
          <h5>
            Your {monthsArray[month]} {year} Check-in
          </h5>
        </div>
        <DateDisplay />
      </DateContainer>
      <PageContent>
        <Row>
          <Column>
            <Summary>
              <h3>Summary</h3>
              <h4>
                Overall: {determineAssessmentSummary(summary.overall || "")}/5
              </h4>
              <ul>
                <li>
                  Health and Well-Being:{" "}
                  {determineAssessmentSummary(summary.health || "")}
                  /5
                </li>
                <li>
                  Education Opportunity:{" "}
                  {determineAssessmentSummary(summary.education || "")}/5
                </li>
                <li>
                  Economic Opportunity:{" "}
                  {determineAssessmentSummary(summary.economic || "")}/5
                </li>
                <li>
                  Safety and Security:{" "}
                  {determineAssessmentSummary(summary.safety || "")}/5
                </li>
              </ul>
            </Summary>
          </Column>
        </Row>
        <br />
        <br />
        <Row>
          <Column>
            <Questions id="questions">
              <h3>Wellness Rankings (1-5)</h3>
              <ol>
                {questions.map(({ text, answer }, i) => (
                  <li key={Math.random()}>
                    {text} {answer}
                  </li>
                ))}
              </ol>
            </Questions>
          </Column>
        </Row>
        <br />
        <br />
        <Row>
          <Column>
            <h3>Recommended Resources</h3>
            <RecommendedResources>
              {recommendedResources.length > 0 ? (
                <StructuredListWrapper style={{ marginBottom: "1rem" }}>
                  <StructuredListHead>
                    <StructuredListRow>
                      <StructuredListCell head>
                        Service Name
                      </StructuredListCell>
                      <StructuredListCell head>
                        Category
                      </StructuredListCell>
                      <StructuredListCell></StructuredListCell>
                    </StructuredListRow>
                  </StructuredListHead>
                  {recommendedResources.map(({ id, name, category }, i) => (
                    <StructuredListBody key={Math.random()}>
                      <StructuredListRow>
                        <StructuredListCell>{name}</StructuredListCell>
                        <StructuredListCell>{category}</StructuredListCell>
                      </StructuredListRow>
                    </StructuredListBody>
                  ))}
                </StructuredListWrapper>
              ) : (
                <p>
                  Based on your assessment responses we will recommend resources
                  that can help you.
                </p>
              )}
            </RecommendedResources>
          </Column>
        </Row>
      </PageContent>
    </PrintablePage>
  );
};

export default PrintablePageComponent;
