import styled from "styled-components";
import { Button, TableContainer, TableRow } from "carbon-components-react";
import { Flag16, Edit16, Add16, AddAlt16 } from "@carbon/icons-react";
import { Popup } from "react-mapbox-gl";

// General
export const PageContainer = styled.div`
  margin: 1rem 0.5rem;
  @media screen and (min-width: 500px) {
    margin: 1rem 1.5rem;
  }
  @media screen and (min-width: 900px) {
    margin: 1.5rem 2rem;
  }
`;

export const CardDiv = styled.div`
  background-color: white;
  padding: 0.5rem;
  border-radius: 2px; /* 2px rounded corners */
  @media screen and (min-width: 900px) {
    padding: 1rem;
  }
`;

export const CardHeader = styled.p`
  font-size: 18px;
  font-weight: 600;
`;

export const CardSubHeader = styled.p`
  font-size: 12px;
  font-weight: 400;
  color: gray;
`;

export const CardHeaderWrapper = styled.div``;

export const Flex = styled.div`
  display: flex;
`;

export const FlexCenter = styled.div`
  display: flex;
  justify-content: center;
`;

export const ResponsiveGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 12px;
  @media screen and (min-width: 900px) {
    grid-template-columns: 1fr 1fr;
    gap: 1rem;
  }
  @media screen and (min-width: 1300px) {
    grid-template-columns: 1fr 1fr 1fr;
  }
`;

// Table
export const StyledTableContainer = styled(TableContainer)`
  .bx--data-table {
    th:nth-child(8),
    th:nth-child(9) {
      display: none;
    }
    
  }
  .bx--data-table tbody {
      background-color: none;
  }
}

`;

export const StyledTableRow = styled(TableRow)`
  td {
    background-color: ${(props) => props.theme.bcolor};
  }
`;

export const SearchWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @media screen and (min-width: 900px) {
    width: 20rem;
  }
  @media screen and (min-width: 1024px) {
    width: 30rem;
  }
`;

export const FilterWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const FilterButtonWrapper = styled.div`
  align-self: flex-end;
  @media screen and (min-width: 500px) {
    margin-left: 0.5rem;
  }
  @media screen and (min-width: 900px) {
    margin-left: 1rem;
  }
  @media screen and (min-width: 1024px) {
    margin-left: 2rem;
  }
`;

export const FilterButton = styled(Button)`
  background-color: black !important;
  min-height: auto;
  padding: 1rem;
  height: 3rem;
  &:hover {
    background-color: red !important;
  }
`;

export const ToolbarInner = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: space-between;
  @media screen and (min-width: 900px) {
    flex-direction: row;
  }
`;

export const TableSubLabel = styled.span`
  display: flex;
  font-size: 12px;
  cursor: pointer;
`;

export const StyledFlag = styled(Flag16)`
  width: 12px;
  margin-left: 4px;
`;

export const StyledEdit = styled(Edit16)`
  width: 12px;
  margin-left: 4px;
`;

export const StyledAdd = styled(AddAlt16)`
  width: 12px;
  margin-left: 4px;
  position: relative;
  top: 2px;
`;

// Toggle Charts Button
export const StyledToggle = styled(Button)``;

export const ToggleHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const ToggleWrapper = styled.div`
  position: absolute;
  inset: 10px auto auto 45px;
`;

export const ToggleButton = styled.div`
  width: 100px;
  border: 1px solid rgba(0, 0, 0, 0.4);
  text-align: center;
  padding-top: 5px;
  color: #404040;
  background: #ffff;
  cursor: pointer;
  min-height: 2rem;
  border-radius: 3px;
`;

export const PopUpContent = styled.div`
  display: flex;
  flex-direction: column;
`;

export const PopUpTextWrapper = styled.div`
  width: 90%;
  height: fit-content;
  h4 {
    font-size: 18px;
    font-weight: 600;
  }
  h5 {
    font-size: 16px;
    font-weight: 500;
  }
`;

export const PopUpAddressWrapper = styled.div`
  width: 80%;
  height: fit-content;
  /* height: 100%; */ /* align-self: center; */
  p {
    font-size: 16px;
    font-weight: 400;
    color: #7d7d7d;
  }
`;

export const PopUpTopRow = styled.div`
  display: flex;
  justify-content: space-between;
`;
export const PopUpBottomRow = styled(PopUpTopRow)`
  display: flex;
  flex-direction: column;
  margin-top: 0.5rem;
`;

export const IconWrapper = styled.div`
  width: fit-content;
  height: fit-content;
`;
export const StyledPopup = styled(Popup)`
  max-width: 50%;
`;
