export default function validate(values) {
  const errors = {};
  if (values.username) {
    if (values.username === "") {
      errors.username = "Email address is required";
    } else if (!/\S+@\S+\.\S+/.test(values.username)) {
      errors.username = "Email address is invalid";
    }
  }

  if (values.password) {
    if (values.password === "") {
      errors.password = "Password is required";
    }
  }
  return errors;
}
