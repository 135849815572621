import React from "react";
import * as S from "./menu.styled";

import { Item } from "./item";

export const Menu = ({ currentPage, setCurrentPage, setIsOpen, user }) => {
  const items = [
    {
      id: Math.random(),
      page: "Profile",
      text: "Profile"
    }
  ];

  if (user && user.isViewOnly) {
    items.push({
      id: Math.random(),
      page: "ProviderInfo",
      text: "Directory Info"
    });
    items.push({
      id: Math.random(),
      page: "LongitudinalRecord",
      text: "Event History"
    });
    items.push({
      id: Math.random(),
      page: "Badges",
      text: "Badges"
    });
  }

  if ((user && user.isAdmin) || (user && user.superAssumedRole?.role === "admin")) {
    items.push({
      id: Math.random(),
      page: "UserManagement",
      text: "Provider User Set Up"
    });
  } else {

    if (user && user.isSuper) {
      items.push({
        id: Math.random(),
        page: "ProviderManagement",
        text: "Provider Management"
      });
    }

    if (user && !user.isSuper && !user.is211Admin) {
      items.push({
        id: Math.random(),
        page: "ProviderInfo",
        text: "Directory Info"
      });
    }


    if (user && !user.isSuper && !user.is211Admin) {
      items.push({
        id: Math.random(),
        page: "Notifications",
        text: "Notification Preferences"
      });
      items.push({
        id: Math.random(),
        page: "LongitudinalRecord",
        text: "Event History"
      });
      items.push({
        id: Math.random(),
        page: "Badges",
        text: "Badges"
      });
    }
  }
  if (user && !user.isSuper && !user.superAssumedRole?.orgId && !user.is211Admin) {
    items.push({
      id: Math.random(),
      page: "Notifications",
      text: "Notification Preferences"
    });
  }

  if(user && !user.superAssumedRole?.orgId){
    items.push({
      id: Math.random(),
      page: "Invite",
      text: "Invite Community"
    });
  }

  return (
    <S.Container>
      {items.map((item) => (
        <Item
          key={item.id}
          text={item.text}
          to={item.page}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          setIsOpen={setIsOpen}
        />
      ))}
    </S.Container>
  );
};
