import { Select } from "carbon-components-react";
import styled from "styled-components";

export const StyledSelect = styled(Select)`
  z-index: 9000;
  display: ${(props) => (props.display ? "block" : "none")};

  width: 100%;
  && .bx--select-input {
    color: white;
    background-color: rgba(0,93,131,1);
  }
  
  && .bx--select__arrow {
    fill: white;
  }
`;