import S from "../../serviceinfo.module.scss";
import { useEffect, useRef, useState, useContext, createRef } from "react";
import { NavLink, Link, useHistory, useLocation } from "react-router-dom";
import kit from "ich-ui-kit";
import DOMPurify from "dompurify";

import {
  Tooltip
} from "carbon-components-react";

import {
  CheckmarkOutline16,
  EarthFilled16,
  Email16,
  Location16,
  PhoneFilled16,
  Time16,
} from "@carbon/icons-react";

import { CapacityBadge, CNResourceBadge } from "../../../../components/Badges/v2";
import MapBox from "../../../../components/Map/map";
import {GetSupport} from "../../../../components/GetSupport";

import {
  removeHtml,
  parseToArray,
  constructOrgOverviewUrl,
  formatProviderAddress,
  generateClickToCallLink
} from "../../../../utils";
import { useServiceInfoCMS } from "../../cms";

const InfoFieldSet = ({ headerText, children, refName, dataCy }) => {
  return (
    <div className={S.infoFieldSet} data-cy={dataCy} ref={refName}>
      <h5 className={S.styledHeader}>{headerText}</h5>
      <div className={S.infoFieldContent}>{children}</div>
    </div>
  );
};

const SingleService = ({
  cms,
  user,
  service,
  serviceDirectoryCms,
  careTeam,
  loggedIn,
  provURL,
  providerBookmarked,
  handleBookmarked,
  handleCareTeams,
  handleNeedsRequest,
  handleCreateReferral,
  handleViewReferral,
  handleCNResource,
}) => {
  const {serviceInfo: serviceInfoCms} = useServiceInfoCMS();

  const generateDataCy = (label="", serviceId) => {
    return `service-info-field-${label.replaceAll(/\s/g, "-").toLowerCase()}-${serviceId}`
  }
  
  // Contexts
  const { apiService } = useContext(kit.ApiContext);

  // States
  const [width, setWidth] = useState(window.innerWidth);


  useEffect(() => {
    const handleResizeWindow = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleResizeWindow);
    return () => {
      window.removeEventListener("resize", handleResizeWindow);
    };
  }, []);

  const navRefs = {
    firstLink: createRef(), // description - assigned corresponding refs to sections
    secondLink: createRef(), // eligibility
    thirdLink: createRef(), // coverage area
    fourthLink: createRef(), // other
  };

  const navRefsList = [
    navRefs.firstLink,
    navRefs.secondLink,
    navRefs.thirdLink,
    navRefs.fourthLink,
  ];

  const scrollSmoothHandler = (ref) => {
    ref.current.scrollIntoView({ behavior: "smooth" });
  };

  const handleNavLinkClick = (evt, ref) => {
    evt.preventDefault();
    scrollSmoothHandler(ref);
  };

  return (
    <div className={S.serviceInfoPage}>
      {/* Long Details Section w/ Header and Nav Bar*/}
      <div className={S.longDetailsSection}>
        <header className={S.serviceInfoHeader}>
          {/* <Button
            className={S.bookmarkButton}
            aria-label="Bookmark Service"
            kind="ghost"
            onClick={() => {
              handleBookmarked(providerBookmarked ? "remove" : "add");
            }}
          >
            <span className={S.srOnly}>
              {providerBookmarked ? "Bookmarked" : "Not Bookmarked"}
            </span>
            {providerBookmarked ? (
              <FavoriteFilled16 className={S.favorited} />
            ) : (
              <Favorite16 className={S.notFavorited} />
            )}
          </Button> */}
          <div className={S.serviceNamesAndConsent}>
            <h1>
              {service?.name}
              <span className={S.connected}>
                {service?.connected && (
                  <Tooltip
                    className={S.checkMark}
                    direction="top"
                    aria-label="Connected"
                    renderIcon={CheckmarkOutline16}
                  >
                    Connected
                  </Tooltip>
                )}
              </span>
            </h1>
            <p className={S.orgName}>
              <Link
                to={constructOrgOverviewUrl(service?.orgId, service?.orgSource)}
              >
                {service?.orgName}
              </Link>
            </p>
          </div>
          <div className={S.badgeWrapper}>
            {service?.connected && service?.capacity && (
              <CapacityBadge
                capacity={service?.capacity}
                style={{ marginLeft: "6px" }}
              />
            )}
            {service?.cnResource && service?.cnResource === "1" && (
              <CNResourceBadge />
            )}
          </div>
        </header>

        <nav aria-label="Service Info Navigation">
          <ul className={S.serviceInfoNavBar}>
            {serviceInfoCms.labels_nav &&
              serviceInfoCms.labels_nav.map((link, index) => (
                <NavLink
                  key={index}
                  onClick={(e) => handleNavLinkClick(e, navRefsList[index])}
                  to={{}}
                >
                  {link.link_text}
                </NavLink>
              ))}
          </ul>
        </nav>

        <div
          style={{
            // position: "relative",
            display: "flex",
            flexFlow: "column nowrap",
            overflowY: "auto",
          }}
        >
          <InfoFieldSet
            headerText={serviceInfoCms.label_overview || "Description"}
            refName={navRefs.firstLink}
            dataCy={generateDataCy(
              serviceInfoCms.label_overview || "Description",
              service.serviceId
            )}
          >
            {service.overview && (
              <div
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(service.overview, {
                    FORBID_ATTR: ["style"],
                  }),
                }}
              ></div>
            )}
          </InfoFieldSet>

          <InfoFieldSet
            headerText={serviceInfoCms.label_eligibility || "Eligibility"}
            refName={navRefs.secondLink}
            dataCy={generateDataCy(
              serviceInfoCms.label_eligibility || "Eligibility",
              service.serviceId
            )}
          >
            {service.eligibility && <p>{removeHtml(service.eligibility)}</p>}
          </InfoFieldSet>

          {/* <InfoFieldSet
            headerText={serviceInfoCms.label_service_area}
            dataCy={generateDataCy(
              serviceInfoCms.label_service_area,
              service.serviceId
            )}
          >
            <p>{removeHtml(service.serviceArea)}</p>
          </InfoFieldSet>
         */}

          <InfoFieldSet
            headerText={serviceInfoCms.label_coverage_area || "Coverage Area"}
            refName={navRefs.thirdLink}
            dataCy={generateDataCy(
              serviceInfoCms.label_coverage_area || "Coverage Area",
              service.serviceId
            )}
          >
            {service.coverageArea && <p>{removeHtml(service.coverageArea)}</p>}
          </InfoFieldSet>

          <div ref={navRefs.fourthLink}>
          <InfoFieldSet
              headerText={serviceInfoCms.label_application_process || "How to Apply"}
              dataCy={generateDataCy(
                serviceInfoCms.label_application_process || "How to Apply",
                service.serviceId
              )}
            >              {service.applicationProcess && (
                <p>{removeHtml(service.applicationProcess)}</p>
              )}
            </InfoFieldSet>


            <InfoFieldSet
              headerText={serviceInfoCms.label_required_documents || "Documents Needed"}
              dataCy={generateDataCy(
                serviceInfoCms.label_required_documents || "Documents Needed",
                service.serviceId
              )}
            >
              {service.requiredDocs && (
                <p>{removeHtml(service.requiredDocs)}</p>
              )}
            </InfoFieldSet>

            <InfoFieldSet
              headerText={serviceInfoCms.label_fees || "Fees"}
              dataCy={generateDataCy(
                serviceInfoCms.label_fees  || "Fees",
                service.serviceId
              )}
            >
              {service.fees && <p>{removeHtml(service.fees)}</p>}
            </InfoFieldSet>

            {/* CONFIRM WITH CLIENT */}
            <InfoFieldSet
              headerText={serviceInfoCms.label_accessibility || "Accessibility"}
              dataCy={generateDataCy(
                serviceInfoCms.label_accessibility || "Accessibility",
                service.serviceId
              )}
            >
              {service.accessibility &&
                (!service.accessibility ? (
                  <p>{serviceInfoCms.text_no_information}</p>
                ) : !service.accessibility.includes("|") ? (
                  <p>{removeHtml(service.accessibility)}</p>
                ) : (
                  parseToArray(service.accessibility).map((val, index) => {
                    return <p key={index}>{removeHtml(val)}</p>;
                  })
                ))}
            </InfoFieldSet>
          </div>
        </div>
      </div>

      {/* Short Details Section */}
      <div
        className={S.shortDetailsSection}
        style={{ paddingTop: "1em" }}
        id="info-list"
      >
        <div className={S.mapWrapper}>
          <MapBox services={[service]} />
        </div>

        <InfoFieldSet
          headerText={serviceInfoCms.label_address || "Address"}
          dataCy={generateDataCy(
            serviceInfoCms.label_address || "Address",
            service.serviceId
          )}
        >          <Location16 className={S.infoIcons} />
          <span>
            {removeHtml(
              formatProviderAddress(service, cms.confidential_address_text)
            )}
          </span>
        </InfoFieldSet>

        <InfoFieldSet
          headerText={serviceInfoCms.label_hours || "Hours"}
          dataCy={generateDataCy(serviceInfoCms.label_hours || "Hours", service.serviceId)}
        >
          {service?.hours && (
            <p>
              <Time16 className={S.infoIcons} />
              <span>{removeHtml(service.hours)}</span>
            </p>
          )}
        </InfoFieldSet>

        <InfoFieldSet
          headerText={serviceInfoCms.label_contact || "Contact"}
          dataCy={generateDataCy(
            serviceInfoCms.label_contact || "Contact",
            service.serviceId
          )}
        >
          {service.phone && (
            <p data-cy={generateDataCy("phone", service.serviceId)}>
              <PhoneFilled16 className={S.infoIcons} />
              <span>
                {generateClickToCallLink(service.phone) ? (
                  <a href={generateClickToCallLink(service.phone)}>
                    {service.phone}
                  </a>
                ) : (
                  service.phone
                )}
              </span>
            </p>
          )}
          {service.orgEmail && (
            <p data-cy={generateDataCy("email", service.serviceId)}>
              <Email16 className={S.infoIcons} />
              <span>{removeHtml(service.orgEmail)}</span>
            </p>
          )}
          {provURL && (
            <p data-cy={generateDataCy("url", service.serviceId)}>
              <EarthFilled16 className={S.infoIcons} />
              <span>
                <a target="_blank" rel="noreferrer noopener" href={provURL}>
                  {removeHtml(provURL)}
                </a>
              </span>
            </p>
          )}
        </InfoFieldSet>
      </div>
      <br />
      <div className={S.getSupportButtonWrapper}>
        <GetSupport
          connected={service?.connected}
          providerEmail={service?.orgEmail}
          activeReferralId={null} // TODO
          onNeedsRequest={handleNeedsRequest}
          onCreateReferral={handleCreateReferral}
          onViewReferral={handleViewReferral}
          isCNResource={Boolean(
            service?.cnResource && service?.cnResource === "1"
          )}
          handleCNResource={handleCNResource}
          hasCapacity={service?.capacity !== "RED"}
        />
      </div>
    </div>
  );
};

export default SingleService;
