export const getTranslatedDomain = (dn, domain) => {
  if (!dn || !domain) return;
  const text = dn.toLowerCase().split(" ").join("_");
  return domain[text];
};

export const categories = [
  {
    domain: "Dependent Care",
    link: "/domains/dependentcare.svg",
    color: "#72a94e"
  },
  {
    domain: "Education",
    link: "/domains/education.svg",
    color: "#72a94e"
  },
  {
    domain: "Employment",
    link: "/domains/employment.svg",
    color: "#005d83"
  },
  {
    domain: "Financial",
    link: "/domains/financial.svg",
    color: "#93d500"
  },
  {
    domain: "Food",
    link: "/domains/food.svg",
    color: "#005d83"
  },
  {
    domain: "Health",
    link: "/domains/Health.svg",
    color: "#0091b3"
  },
  {
    domain: "Housing",
    link: "/domains/housing.svg",
    color: "#4ac1e0"
  },
  {
    domain: "Income",
    link: "/domains/financial.svg",
    color: "#005d83"
  },
  {
    domain: "Safety",
    link: "/domains/safety.svg",
    color: "#005d83"
  },
  {
    domain: "Social Connections",
    link: "/domains/socialconnection.svg",
    color: "#93d500"
  },
  {
    domain: "Transportation",
    link: "/domains/transportation.svg",
    color: "#0091b3"
  },
];

