export const fetchWithRetry = async (
  url,
  options = {},
  retries = 3,
  backoff = 300
) => {
  try {
    const res = await fetch(url, options);

    if (res.ok) return res;

    if (retries > 0 && res.status === 500) {
      return new Promise((resolve) => {
        setTimeout(() => {
          resolve(fetchWithRetry(url, options, retries - 1, backoff * 2));
        }, backoff);
      });
    }
    throw new Error(res);
  } catch (err) {
    return err;
  }
};

export const cleanZipFormatting = (stringToClean) => {
  let cleanedString = stringToClean;
  let stringToRemove = stringToClean?.match(/<zip>(.*?)<\/zip>/g);
  if (stringToRemove) {
    cleanedString = stringToClean.replace(stringToRemove, "");
  }
  return cleanedString;
}

export const extractZipFromSearch = (searchTerm = "") => {
  if (searchTerm.includes("<zip></zip>")) return "";
  let zipIndex = searchTerm.indexOf("<zip>");
  if (zipIndex !== -1) {
    return searchTerm.slice(zipIndex + 5, -6);
  }
  return "";
};

export const determineAssessmentSummaryColor = (color) => {
  switch (color) {
    case "GREEN":
      return "#95c93d";
    case "LIGHT_GREEN":
      return "#4ac1e0";
    case "YELLOW":
      return "#f4db5f";
    case "ORANGE":
      return "#faa41a";
    case "RED":
      return "#ec5c29";
    default:
      return "#d8d8d8";
  }
};

export const shouldAutoComplete = "off";

export const getAge = (dateString) => {
  const today = new Date();
  const birthDate = new Date(dateString);
  let age = today.getFullYear() - birthDate.getFullYear();
  const m = today.getMonth() - birthDate.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    age--;
  }
  return age;
};

export const parseToArray = (str) => {
  return str.split("|");
};

export const displayAddress = (pro) => {
  let address = pro.address || "";
  let city = pro.city || "";
  let state = pro.state || "";
  let zip = pro.zip || "";
  if (!address.length && !city.length && !state.length && !zip.length) {
    return "No information available";
  } else if (
    !(!address.length && !city.length && !state.length && zip.length) &&
    address.length
  ) {
    return `${address}, ${city} ${state} ${zip}`;
  } else if (
    !address.length &&
    !(!address.length && !city.length && !state.length && zip.length)
  ) {
    return `${city}, ${state} ${zip}`;
  }
  return "Area postal code: "`${zip}`;
};

export const formatUrl = (url) => {
  let provURL = url ? url.toLowerCase() : null;

  if (provURL && provURL.indexOf(".") > 0 && provURL.indexOf(".") < 4) {
    provURL = "https://" + provURL;
  }
  return url;
};

export const modifyDoB = (dob) => {
  if (dob !== undefined && dob !== "") {
    if (dob.includes('-')) {
      const temp = dob.split("-");
      return `${temp[1]}/${temp[2]}/${temp[0]}`;
    } else {
      return dob;
    }
  }
  return "";
};

export const parseHtml = (_text) => {
  if (_text) {
    let result = _text.replace(/;/gi, ' ');
    result = result.split('*').join(' ');
    // eslint-disable-next-line no-control-regex
    result = result.replace(/[^\x00-\x7F]/gi, '');
    result = new DOMParser().parseFromString(result, "text/html");
    result = result.body.textContent;
    return result.replace(/[\u{0080}-\u{FFFF}]/gu, " ");
  }
  return "";
};

export const formatDate = (str) => {
  let x = str.replace(/\D/g, "").match(/(\d{0,2})(\d{0,2})(\d{0,4})/);

  return !x[2] ? x[1] : x[1] + "/" + x[2] + (x[3] ? "/" + x[3] : "");
};

// Parse service's domains
export const cleanDomains = (input) => {
  let arr = input.map((domain) => domain.split(":")[0]).filter(el => el !== '');
  arr = arr.map((domain) => {
    domain = domain[0]?.toUpperCase() + domain.slice(1)
    if (domain.includes(" ")) {
      let words = domain.split(" ")
      domain = words[0] + " " + words[1][0]?.toUpperCase() + words[1].slice(1)
    }
    return domain
  });
  return arr
}

export const getLocalTime = (referralsArray) => {
  referralsArray = referralsArray?.map((ref) => {
    try {
      let authoredLocalTime;
      if (ref.authoredOn.includes("T")) {
        authoredLocalTime = ref.authoredOn;
      } else {
        authoredLocalTime = ref.authoredOn.replace(" ", "T") + "Z";
      }
      authoredLocalTime = new Date(authoredLocalTime).toLocaleDateString();
      return ({...ref, authoredOn: authoredLocalTime}) 
    } catch (e) {
      return ref;
    }
  })
  referralsArray = referralsArray?.sort((a,b) => new Date(b?.authoredOn) - new Date(a?.authoredOn))
  return referralsArray;
}

export const getLocalTimeOnce = (timestamp) => {
  let localTime;
  if (timestamp?.includes("T")) {
    localTime = timestamp;
  } else {
    localTime = timestamp?.replace(" ", "T") + "Z";
  }
  return new Date(localTime)?.toLocaleDateString();
}

export const getLocalTimeWithHHMMOnce = (timestamp) => {
  let localTime;
  if (timestamp?.includes("T")) {
    localTime = timestamp;
  } else {
    localTime = timestamp?.replace(" ", "T") + "Z";
  }
  return new Date(localTime)?.toLocaleString(undefined, {
    year: "numeric",
    month: "numeric",
    day: "numeric",
    hour: "numeric",
    minute: "numeric"
  });
}

const uppercaseFirstLetter = (string) => {
  const words = string.split(' ');
  words.forEach(([first, ...rest], i) => {
    words[i] = first?.toUpperCase() + rest.join('');
  });
  return words.join(' ');
};

export const getDisplayedStatus = (incomingStatus, referree, viewOnly = false, requestType) => {
  try {
    let status = incomingStatus?.toLowerCase();
    if (requestType != null && requestType != undefined && requestType != "") {
      let type = requestType.toLowerCase();
      if (status === "pending" && type === "assistance request") {
        return "New Request";
      }
    }
    if((status.includes("needs request") || status.includes("pending")) && requestType === "Email") {
      return "Pending";
    } else  if (status.includes("working") || status.includes("reopen")) {
      return "In Progress";
    } else if (status.includes("waiting for client")) {
      if (!viewOnly && referree) {
        return "Waiting for " + referree;
      } else {
        return "Waiting for Client"
      }   
    } else if (status.includes("waiting for provider")) {
      return "Waiting for Provider"  
    } else if ((status.includes("close ") && status.includes("client")) || (status.includes("close ") && status.includes("rejected"))) {
      if (!viewOnly && referree) {
        return "Closed - Canceled by " + referree;
      }
      return "Closed - Canceled by Client";
    } else if (status.includes("closure") || status.includes("close ")) {
      return uppercaseFirstLetter(incomingStatus.split(":")[0]);
    } else if (status === "pending" || status === "about to expire - pending") {
      return "New Referral";
    } else if (status.includes("rejected by receiver")) {
      return "Provider Cannot Accept";
    } else if (status.includes("rejected by")) {
      if (!viewOnly && referree) {
        return "Canceled by " + referree;
      }
      return "Canceled by Client";
    } else if (status.includes("closed")) {
      if (status.includes("unable to resolve")) {
        return "Closed - Unresolved"
      } else {
        return uppercaseFirstLetter(incomingStatus.split(":")[0]);
      }
    } else if (status.includes("cancel")) {
      return "Provider Cannot Accept"
    } else {
      return uppercaseFirstLetter(incomingStatus);
    }
  } catch (e) {
    console.log(e)
  }
};

export const getDescriptiveHistory = (historyItem, referral) => {
  try {
    let description = historyItem.description;
    let newd = "";
    if (description.includes("Referee")) {
      newd = description.replace("Referee", referral.referree);
    } else if (description.includes("Client")) {
      newd = description.replace("Client", referral.referree);
    } else if (description.includes("Referrer")) {
      newd = description.replace("Referrer", referral.requester);
    } else if (description.includes("Close ")) {
      newd = description.replace("Close", "Closed");
    } else if (
      description.includes("Waiting for Provider") || description.includes("Accepted by Provider")
    ) {
      newd = description;
    } else if (
      description.includes("Provider") &&
      historyItem.author &&
      historyItem.author !== "username"
    ) {
      newd = description.replace("Provider", historyItem.author);
    } else if (description.includes("reassigned")) {
      newd = "Referral " + referral.id + " has been reassigned";
    } else {
      newd = description;
    }
    if (newd.includes("Status of")) {
      let status = newd.split("is now ")[1];
      let newStatus;
      if (status.includes("About to Expire")) {
        newStatus = "About to Expire"
      } else {
        newStatus = getDisplayedStatus(status, referral.referree);
      }
      newd = newd.replace(status, newStatus);
    }
    return newd;
   } catch (e) {
     console.log(e)
   }
};

// Sort Table Data By Date - takes in an array of objects
export const sortTableByDate = (data, order = "desc", propName = "endDate") => {
  if(data){
    let sorted;
    if(order === "asc"){
      sorted = data.sort((a, b) => new Date(a[propName]).valueOf() - new Date(b[propName]).valueOf());
      return sorted;
    } else {
      sorted = data.sort((a, b) => new Date(b[propName]).valueOf() - new Date(a[propName]).valueOf());
      return sorted;
    }
  } else {
    return [];
  }
}

export const parseHiddenText = (txt) => {
  // 211 has some hidden text to enhance their searching.
  // Removing that text here.
  return txt?.replace(/=(.*)/gm, "");
};

export const removeHtml = (_text) => {
  if (_text !== null && _text !== undefined) {
    let res = new DOMParser().parseFromString(_text, "text/html");
    res = res.body.textContent;
    return res.replace(/[\u{0080}-\u{FFFF}]/gu, " ");
  }
  return _text;
};

export const validateUrl = (url) => {
  const matches = url.match(/((<\/(a-Z)*\b[^>]*>)|(<(a-Z)*\b[^>]*>))/);
  if (matches) {
    return false;
  } else {
     return url;
  }
}

export const checkIfContains = (text, checker) => text.toLowerCase().includes(checker);

export const capitalizeFirstLetter = (string) => {
  string = string.split('_').join(' ');
  return string.charAt(0)?.toUpperCase() + string.slice(1);
}

export const capitalizeFirstLetterMultiple = (string) => {
  string = string.split('_');
  string = string
    .map((word) => word.charAt(0)?.toUpperCase() + word.slice(1))
    .join(' ');
  return string;
}

export const truncateString = (string, maxChar) => {
  let result = string;
  if (maxChar && string && string.length >= maxChar) {
    result = string.slice(0, maxChar) + '...';
  }
  return result;
};

// Badges

// Client Badge
export const getNewBadge = async (user, badgeType) => {
  if (user.id) {
    try {
      let res = await fetch(
        process.env.REACT_APP_API_ENDPOINT + "/badges",
        {
          method: "post",
          credentials: "include",
          headers: {
            Authorization: `Bearer ${user.accessToken} ${user.idToken}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            type: badgeType,
          }),
        }
      );
    } catch (err) {
      console.log(err);
    }
  }
};

export const parseFeedbackBadges = (text, fallback) => {  
  if (text && text.includes(',')) {
    const list = text.split(',');
    return (
      <ul>
        {list.map((l) => (<li style={{padding: '0.5rem'}}>{l}</li>))}
      </ul>
    )
  } else {
    return <p>{text || fallback}</p>
  }
};

export const isNoteInputTooLong = (text, max) => {
  if (text?.length && text?.length > max) {
    return true;
  } else {
    return false;
  }
}
export const static_confidential_address_text = "Address is confidential, please call for more information";

export const formatProviderAddress = (provider, confidential_address_text) => {
  if (!provider.loc_confidential_address) {
    const tempProvAddress = provider.address ? provider.address + ", " : "";
    const tempProvCity = provider.city ? provider.city + ", " : "";
    const tempProvState = provider.state ? provider.state + ", " : "";
    const tempProvZip = provider.zip ? provider.zip : "";
    return tempProvAddress + tempProvCity + tempProvState + tempProvZip
  } else {
    return confidential_address_text || static_confidential_address_text;
  }
}

export const truncateText = (str, charLimit) => {
  if (str && !isNaN(charLimit) && str.length > charLimit) {
    // Find the last space within the limit
    const lastSpace = str.lastIndexOf(' ', charLimit);
    if (lastSpace === -1) {
      return str.substring(0, charLimit) + '...';
    }
    // Truncate at the last whole word
    return str.substring(0, lastSpace) + '...';
  }
  return str;
}

export const constructOrgOverviewUrl = (orgId, orgSource) => {
  const url = `/provider-information?id=${orgId}&orgSource=${orgSource || "211"}`
  if (validateUrl(url)) return url;
  return '';
};

export const constructServiceOverviewUrl = (serviceAtLocationId, orgSource) => {
  const url = `/service-information?id=${serviceAtLocationId}&orgSource=${orgSource || "211"}`
  if (validateUrl(url)) return url;
  return '';
};

/*  VALIDATIONS  */ 

export const generateClickToCallLink = (phoneNumber) => {
  try {
    if (!phoneNumber) return '';
    // cleaning and standardizing phone number
    phoneNumber = phoneNumber?.toLowerCase();
    phoneNumber = phoneNumber?.replaceAll(' ', '');
    phoneNumber = phoneNumber?.replaceAll('.', '');
    phoneNumber = phoneNumber?.replaceAll('(', '');
    phoneNumber = phoneNumber?.replaceAll(')', '-');
    phoneNumber = phoneNumber?.replaceAll(/(?=x\d{1})x/gm, 'ext');
    phoneNumber = phoneNumber?.replaceAll('-', '');

    const [baseNumber, extension] = phoneNumber?.split('ext');

    let link = '';
    if (
      /^\d{3}$/gm.test(baseNumber) ||
      /^\d{7}$/gm.test(baseNumber) ||
      /^\d{10}$/gm.test(baseNumber)
    ) {
      link = `tel:${baseNumber}`;
    } else if (/^\d{11}$/gm.test(baseNumber)) {
      link = `tel:+${baseNumber}`;
    } else {
      return '';
    }

    if (extension) {
      link += `,${extension}`;
    }
    return link;
  } catch (e) {
    return '';
  }
};

// Regex to check for optional phone number: Match with either valid phone number or empty phone number 
// Matches with xxx-xxx-xxxx and other formats
export const checkOptionalPhoneNumberRegex =
/^(\+\d{1,2}\s?)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$|^\s*$/;

// Regex to check for required phone number
// Matches with xxx-xxx-xxxx and other formats
export const checkRequiredPhoneNumberRegex = 
/^(\+\d{1,2}\s?)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/;

// Regex to check valid email
export const checkEmailRegex =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const validateEmail = (email) => {
  return checkEmailRegex.test(email);
};
// Regex to check if string matches MM/DD/YYYY format (MM: 01-12, DD: 01-31, YYYY: 0000-9999
export const dateMMDDYYYYFormatRegex = /^(^(?:0[1-9]|1[012])\/(?:0[1-9]|[12][0-9]|3[01])(\/)\d{4}$)/


// Regex to check if string matches MM/DD/YYYY format (MM: 01-12, DD: 01-31, YYYY: 1900-2099)
export const dobMMDDYYYYFormatRegex =  /^(^(?:0[1-9]|1[012])\/(?:0[1-9]|[12][0-9]|3[01])\/(?:(19|20)[0-9][0-9])$)/

/**
 * validateDateOfBirth(e) - validates date of birth and sends back status and associated error message
 * @param {string} e  - date of birth input from user
 * @param {*} setValue 
 * @returns {{string, string}} - {isValid, message} object - "isValid" status for date of bith input and "message" to display for error
 */
export const validateDateOfBirth = (e) => {

  let date = e;
    if (date && date.length === 10) {
      const today = new Date();
      const dob = new Date(date);
 
      // returns false if invalid date passed
      const dobCheck = new Date(date).toString();
      if(dobCheck === 'Invalid Date') {
        return {
          isValid: false,
          message: 'Invalid Date'
         };
      }

      // returns false if under age
      let age = today.getFullYear() - dob.getFullYear();
      const month = today.getMonth() - dob.getMonth();
      if (month < 0 || (month === 0 && today.getDate() < dob.getDate())) {
        age--;
      }
      if (age >= 13) {
        return {
          isValid: true,
          message: "" 
         };
      } else {
        return {
          isValid: false,
          message: "Must be 13 years or older"
         };
      }
    } else {
      return {
        isValid: false,
        message: "Enter a valid date in format MM/DD/YYYY"
      }
       };
}

// a function to retry loading a chunk to avoid chunk load error for out of date code
export const lazyRetry = (componentImport, name) => {
  return new Promise((resolve, reject) => {
      // check if the window has already been refreshed
      const hasRefreshed = JSON.parse(
          window.sessionStorage.getItem(`retry-${name}-refreshed`) || 'false'
      );
      // try to import the component
      componentImport().then((component) => {
          window.sessionStorage.setItem(`retry-${name}-refreshed`, 'false'); // success so reset the refresh
          resolve(component);
      }).catch((error) => {
          if (!hasRefreshed) { // not been refreshed yet
              window.sessionStorage.setItem(`retry-${name}-refreshed`, 'true'); // we are now going to refresh
              return window.location.reload(); // refresh the page
          }
          reject(error); // Default error behaviour as already tried refresh
      });
  });
};

/**
 * Checks if a given name is valid according to specified criteria.
 * < 50 characters
 * character of any language (can contain unicode) or space, -, comma, single quote
 *
 * @param {string} name - The name to be validated.
 * @returns {boolean} True if the name is valid; otherwise, false.
 */
export const isValidName = (name) => {
  const validNameRegex = /^[\p{L}\p{M}\s\-.']+$/u;
  let validCharacterLength = false;
  let validRegex = validNameRegex.test(name);

  if (name.length <= 50) validCharacterLength = true;

  if (validCharacterLength && validRegex) {
    return true;
  }

  return false;
}