import {
    DataTableSkeleton,
    DataTable,
    Table,
    TableHead,
    TableRow,
    TableHeader,
    TableBody,
    TableCell,
		TableContainer,
		TableToolbar,
		TableToolbarSearch
} from 'carbon-components-react';
import { useHistory } from 'react-router-dom';
import { sortTableByDate } from '../../../../utils';

const headers = [
	{
		key: 'patientId'
	},
	{
		key: "id",
		header: "ID"
	},
	{
		key: 'endDate',
		header: 'Date',
	},
	{
		key: 'patientName',
		header: 'Caller',
	},
	{
		key: 'submitterName',
		header: 'Submitter'
	}
];

function CallLogTable({ loading, data }) {
	const history = useHistory();

	const handle211CallView = (logId, patientId) => {
    history.push({
      pathname: "/referral-dashboard/view-call",
      state: {
        referralId: logId,
        patientId: patientId,
				from: "callLogDashboard"
      }
    });
  };

	if(loading){
		return ( 
			<>
				<TableToolbarSearch disabled />
				<DataTableSkeleton 
					headers={headers} 
					showToolbar={false}
					showHeader={false}
				/> 
			</>
		)
	} else {
		return (
			<>
			<DataTable rows={sortTableByDate(data)} headers={headers} isSortable>
				{({ rows, headers, getTableProps, getHeaderProps, getRowProps, onInputChange }) => (
					<TableContainer>
						<TableToolbar style={{
							backgroundColor: "#fff",
							border: "2px solid #E0E0E0",
							borderBottom: "0"
						}}>
							<TableToolbarSearch 
								onChange={onInputChange} 
								placeholder="Search..."
							/>
						</TableToolbar>
						<Table {...getTableProps()}>
							<TableHead>
								<TableRow>
									{headers.slice(1).map((header) => (
										<TableHeader {...getHeaderProps({ header })}>
											{header.header}
										</TableHeader>
									))}
									<th />
								</TableRow>
							</TableHead>
							<TableBody>
								{rows.map((row) => (
									<TableRow {...getRowProps({ row })}>
										{row.cells.slice(1).map((cell) => (
											<TableCell key={cell.id}>
												{/* row.cells[0].value --> patientId */}
												{cell.info.header === 'id' ? (
													<div
														onClick={() => handle211CallView(cell.value, row.cells[0].value)}
														style={{cursor: "pointer"}}
													>
														<a>
															{cell.value}
														</a>
													</div>
												) : (
													<>
														{cell.value}
													</>
												)}
											</TableCell>
										))}
									</TableRow>
								))}
							</TableBody>
						</Table>
					</TableContainer>
				)}
			</DataTable>
			
			{data.length === 0 && !loading && (
				<div style={{padding: "2rem 0"}}>
					<p style={{textAlign: "center"}}>Error getting Call Log data. Please try again later.</p>
				</div>
			)}
			</>
		)
	}
}

export default CallLogTable;