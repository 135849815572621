import ReferralFeedback from "./iconBadges/positive-feedback.svg";
import ReferralFeedbackBlue from "./iconBadges/positive-feedback-blue.svg";


export const svgToComponent = (imgUrl, blue) => {
    if (imgUrl == 'positive-feedback.svg'){
        if(blue){
            return ReferralFeedbackBlue;
        } else {
            return ReferralFeedback;
        }
    } else if (imgUrl === 'feedback.svg'){
        if(blue){
            return ReferralFeedbackBlue;
        } else {
            return ReferralFeedback;
        }
    }
}

export const tooltipTexts = {
    'feedback': "This badge is earned by responding to a feedback request.", // Provider Feedback
    // 1-25-24 - Both needed, at the moment, it seems different environments use different names for the same badge: ('positive-feedback' on training/prod, 'survey-feedback' on dev)
    'survey-feedback': "This badge is earned by having more than 2/3 users that take your referral survey nominate you for a badge. When you earn this badge, you can lose this badge if you fall below the 2/3 threshold.", // Positive Feedback
    'positive-feedback': "This badge is earned by having more than 2/3 users that take your referral survey nominate you for a badge. When you earn this badge, you can lose this badge if you fall below the 2/3 threshold.", // Positive Feedback 
};

export const tooltipRedirects = {
    'survey-feedback': '/',
    'positive-feedback': '/',
    'feedback': null,
};

export const tooltips = [
    {
        badgeType: "positive-feedback",
        tooltipText: "This badge is earned by having more than 2/3 users that take your referral survey nominate you for a badge. When you earn this badge, you can lose this badge if you fall below the 2/3 threshold.",
        tooltipRedirect: "/"
    },
    {
        badgeType: "feedback",
        tooltipText: "This badge is earned by responding to a feedback request.",
        tooltipRedirect: "/"
    },
];

export const tooltipSelector = (badge) => {
    let tooltipText;
    let tooltipRedirect;

    for (let key in tooltips){
        if(tooltips[key].badgeType == badge){
            tooltipText = tooltips[key].tooltipText;
            tooltipRedirect = tooltips[key].tooltipRedirect;
        }
    }
    return [tooltipText, tooltipRedirect];
}