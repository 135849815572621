import OverviewCardsOutbound from "./overview-cards-outbound";
import { useHistory } from "react-router";
import kit from "ich-ui-kit";
import {
  DataTable,
  Table,
  TableHeader,
  TableBody,
  TableCell,
  TableToolbar,
  TableToolbarContent,
  OverflowMenu,
  OverflowMenuItem,
  Tooltip,
  PaginationNav,
} from "carbon-components-react";
import {
  CardDiv,
  Flex,
  ToolbarInner,
  FlexCenter,
  TableSubLabel,
  StyledEdit,
  StyledTableContainer,
  StyledTableRow,
  StyledOverflowMenu,
  StyledAdd,
  StyledCircle,
  StyledTableHead
} from "../../index.styled";
import {
  sortHeader,
  avgAge,
  ageColor,
  getCompletedReferrals,
  aStatuses,
  rStatuses,
} from "../../helpers/filter-helpers";
import OutboundFilterModal from "../modals/filter-modal-outbound";
import {
  WarningAltFilled16,
} from "@carbon/icons-react";
import { useState, useContext } from "react";
import { downloadHeaders, TobiData, headers } from "../../utils";
import { getDisplayedStatus } from "../../../../utils";
import DashboardFilters from "../filters";

const OutboundReferrals = ({
  outboundItems,
  setOutboundItems,
  outboundItemsAll,
  setOutboundItemsAll,
  renderStatus,
  outboundItemsDownload,
  width,
  tab,
}) => {
  const { apiService } = useContext(kit.ApiContext);
  const { user } = useContext(kit.UserContext);
  const history = useHistory();

  const [currentPage, setCurrentPage] = useState(0);
  const [pageMin, setPageMin] = useState(0);
  const [pageMax, setPageMax] = useState(20);

  const [labels, setLabels] = useState([]);
  const [openExpireTooltip, setOpenExpireTooltip] = useState(false);

  // Responsiveness
  const isMobile = 900;
  const filterOnly = 500;

  // Modal States
  function unique(arr, keyProps) {
    const kvArray = arr.map((entry) => {
      const key = keyProps.map((k) => entry[k]).join("|");
      return [key, entry];
    });
    const map = new Map(kvArray);
    return Array.from(map.values());
  }
  // const [outboundItemsAll, setOutboundItemsAll] = useState(outboundItems)
  const services = unique(
    outboundItemsAll.map((m) => {
      return {
        id: m.serviceId,
        name: m.name,
      };
    }),
    ["serviceId", "name"]
  );
  const [openFilterModal, setFilterModalOpen] = useState(false);
  const [assignStatuses, setAssignStatuses] = useState([]);
  const [referStatuses, setReferStatuses] = useState([]);
  const [selectServices, setSelectServices] = useState([]);
  const [parentRefers, setParentRefers] = useState({ id: "parent-open", label: "Open" });
  const [dateStart, setDateStart] = useState("");
  const [dateEnd, setDateEnd] = useState("");

  // Filter and search
  const [sortHeaderState, setSortHeaderState] = useState("");
  const [sortHeaderDirection, setSortHeaderDirection] = useState(0);
  const [filterBookmarked, setFilterBookmarked] = useState(false);

  // useEffect(() => {
  //   setOutboundItemsAll(outboundItems)
  // }, [apiService]);

  const addLabel = (
    color,
    refId,
    clientId,
    setMockDataState,
    setDisplayedItems,
    referrals
  ) => {
    const currLabels = [...labels];
    currLabels.push({ id: refId, clientId: clientId, label: color });
    setLabels(currLabels);
    addLabelToDb(clientId, refId, color);
  };

  const updateLabel = (color, refId, clientId) => {
    const currLabels = [...labels];
    currLabels.map((item) => {
      if (item.id === refId) {
        item.label = color;
      }
    });
    setLabels(currLabels);
    changeLabelToDb(clientId, refId, color);
  };

  const removeLabel = (
    color,
    refId,
    clientId,
    setMockDataState,
    setDisplayedItems,
    referrals
  ) => {
    let currLabels = [...labels];
    currLabels = currLabels.filter((item) => item.id !== refId);
    setLabels(currLabels);
    removeLabelFromDb(clientId, refId, color);
  };

  const addLabelToDb = async (clientId, referralId, status) => {
    await apiService.post(
      `/labels/referrals?assumedId=${user.superAssumedRole?.leadId || ""}`,
      {
        clientId: clientId,
        referralId: referralId,
        status: status,
      }
    );
  };

  const changeLabelToDb = async (clientId, referralId, status) => {
    await apiService.put(
      `/labels/referrals?assumedId=${user.superAssumedRole?.leadId || ""}`,
      {
        clientId: clientId,
        referralId: referralId,
        status: status,
      }
    );
  };

  const removeLabelFromDb = async (clientId, referralId, status) => {
    await apiService.delete(
      `/labels/referrals?referralId=${referralId}&clientId=${clientId}&assumedId=${
        user.superAssumedRole?.leadId || ""
      }`
    );
  };
  return (
    <>
      <OverviewCardsOutbound
        referrals={outboundItemsAll}
        mockData={outboundItems}
        outboundItems={outboundItems}
        setOutboundItems={setOutboundItems}
        totalReferrals={outboundItemsAll.length}
        myAssignments={
          outboundItemsAll
            ? outboundItemsAll.filter((f) => f?.assignedTo === user.name).length
            : 0
        }
        unassigned={
          outboundItemsAll
            ? outboundItemsAll.filter((f) => !f?.assignedTo).length
            : 0
        }
        averageAge={
          outboundItemsAll
            ? avgAge(outboundItemsAll) === Infinity
              ? "--"
              : avgAge(outboundItemsAll)
            : 0
        }
        completed={
          outboundItemsAll ? getCompletedReferrals(outboundItemsAll).length : 0
        }
        dateStart={dateStart}
        dateEnd={dateEnd}
        selectServices={selectServices}
        assignStatuses={assignStatuses}
        referStatuses={referStatuses}
        filterBookmarked={filterBookmarked}
        parentRefers={parentRefers}
      />
      <CardDiv>
        <DataTable
          rows={TobiData}
          headers={headers}
          // isSortable
        >
          {({
            headers,
            rows,
            getRowProps,
            getTableProps,
            getHeaderProps,
            onInputChange,
          }) => (
            <StyledTableContainer>
              <TableToolbar
                style={{
                  marginBottom: "1rem",
                  backgroundColor: "white",
                }}
              >
                <TableToolbarContent style={{ height: "100%" }}>
                  <ToolbarInner>
                    <DashboardFilters
                      user={user}
                      allServices={services}
                      setSelectServices={setSelectServices}
                      displayedItems={outboundItems}
                      setDisplayedItems={setOutboundItems}
                      referrals={outboundItemsAll}
                      selectServices={selectServices}
                      assignStatuses={assignStatuses}
                      setAssignStatuses={setAssignStatuses}
                      referStatuses={referStatuses}
                      filterBookmarked={filterBookmarked}
                      setFilterBookmarked={setFilterBookmarked}
                      astatuses={aStatuses}
                      rstatuses={rStatuses}
                      downloadHeaders={downloadHeaders}
                      displayedItemsDownload={outboundItemsDownload}
                      width={width}
                      filterOnly={filterOnly}
                      parentRefers={parentRefers}
                      setParentRefers={setParentRefers}
                      dateStart={dateStart}
                      setDateStart={setDateStart}
                      setDateEnd={setDateEnd}
                      dateEnd={dateEnd}
                      tab={tab}
                    />
                  </ToolbarInner>
                </TableToolbarContent>
              </TableToolbar>
              <Table {...getTableProps()}>
                <StyledTableHead>
                  {headers.map((header) => {
                    if (!user.isViewOnly) {
                      return (
                        <TableHeader
                          style={{ display: "visible" }}
                          {...getHeaderProps({
                            header,
                          })}
                          onMouseDown={(e) => {
                            e.preventDefault();
                            sortHeader(
                              header,
                              setOutboundItems,
                              sortHeaderState,
                              sortHeaderDirection,
                              setSortHeaderDirection,
                              setSortHeaderState,
                              outboundItemsAll,
                              labels,
                              selectServices,
                              assignStatuses,
                              referStatuses,
                              filterBookmarked,
                              parentRefers
                            );
                          }}
                          isSortable={header.isSortable}
                        >
                          {header.header}
                        </TableHeader>
                      );
                    } else if (
                      header.header !== "Labels" &&
                      header.header !== "Client"
                    ) {
                      return (
                        <TableHeader
                          style={{ display: "visible" }}
                          {...getHeaderProps({
                            header,
                          })}
                          onMouseDown={(e) => {
                            e.preventDefault();
                            sortHeader(
                              header,
                              setOutboundItems,
                              sortHeaderState,
                              sortHeaderDirection,
                              setSortHeaderDirection,
                              setSortHeaderState,
                              outboundItemsAll,
                              labels,
                              selectServices,
                              assignStatuses,
                              referStatuses,
                              filterBookmarked
                            );
                          }}
                          isSortable={header.isSortable}
                        >
                          {header.header}
                        </TableHeader>
                      );
                    }
                  })}
                </StyledTableHead>
                <TableBody>
                  {outboundItems?.slice(pageMin, pageMax).map((row, i) => {
                    if (row) {
                      return (
                        <StyledTableRow
                          key={i}
                          {...getRowProps({ row })}
                          theme={{
                            bcolor: ageColor({ rowDate: row.authoredOn, rowStatus: row.status }),
                          }}
                          style={{ cursor: "default" }}
                        >
                          <TableCell style={{ paddingRight: "0rem" }}>
                            {row?.status?.toLowerCase()?.includes("about to expire") && (
                              <div
                                onMouseEnter={() => setOpenExpireTooltip(true)}
                                onMouseLeave={() => setOpenExpireTooltip(false)}
                              >
                                <WarningAltFilled16 style={{ color: "red" }} />
                                <Tooltip
                                  open={openExpireTooltip}
                                  direction="bottom"
                                  showIcon={false}
                                  align="start"
                                >
                                  <p>About to Expire</p>
                                </Tooltip>
                              </div>
                            )}
                          </TableCell>
                          {user.isViewOnly ? (
                            <TableCell style={{ cursor: "pointer" }}>
                              {row?.id}
                            </TableCell>
                          ) : (
                            <TableCell
                              style={{ cursor: "pointer" }}
                              onClick={(e) => {
                                const clientID = row?.referreeId;
                                history.push({
                                  pathname: "/referral-dashboard/referral",
                                  state: {
                                    clientID,
                                    referralId: row?.id,
                                    referral: row,
                                    cameFrom: "referral-dashboard",
                                    cameFromTab: "Outbound",
                                    onCareTeam: row?.isOnTeam,
                                  },
                                });
                              }}
                            >
                              <a>{row?.id}</a>
                            </TableCell>
                          )}
                          <TableCell>{row?.authoredOn}</TableCell>
                          {row?.isOnTeam && !user.isViewOnly ? (
                            <TableCell
                              style={{ cursor: "pointer" }}
                              onClick={(e) => {
                                const clientID = row?.referreeId;
                                history.push({
                                  pathname: "/360dashboard",
                                  state: {
                                    client: clientID,
                                    clientId: clientID,
                                    onCareTeam: true,
                                  },
                                });
                              }}
                            >
                              <a>{row?.referree || " "}</a>
                            </TableCell>
                          ) : (
                            !user.isViewOnly && (
                              <TableCell>{row?.referree}</TableCell>
                            )
                          )}
                          <TableCell style={{ cursor: "default" }}>
                            {row?.name}
                            <br />
                            <TableSubLabel style={{ cursor: "default" }}>
                              {row?.orgName}
                            </TableSubLabel>
                          </TableCell>
                          <TableCell>
                            {row?.assignedTo && row?.assignedTo !== "undefined"
                              ? row?.assignedTo
                              : "Unassigned"}
                          </TableCell>
                          <TableCell>
                            {getDisplayedStatus(
                              row.status,
                              row.referree,
                              user.isViewOnly
                            )}
                          </TableCell>
                          <TableCell style={{ cursor: "default" }}>
                            {row?.requester}
                            <br />
                            <TableSubLabel style={{ cursor: "default" }}>
                              {row?.requesterOrg}
                            </TableSubLabel>
                          </TableCell>
                          {!user.isViewOnly && (
                            <>
                              {labels?.find((l) => l.id === row.id) ? (
                                <TableCell>
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      marginTop: "5px",
                                    }}
                                  >
                                    {labels.find((l) => l.id === row.id).label}
                                    <StyledCircle
                                      theme={{
                                        color: labels
                                          .find((l) => l.id === row.id)
                                          .label.toLowerCase(),
                                      }}
                                    />
                                  </div>
                                  {/* <br /> */}
                                  <TableSubLabel>
                                    <Flex style={{ alignItems: "center" }}>
                                      {width > isMobile ? "Edit Label" : "Edit"}
                                      <StyledOverflowMenu
                                        renderIcon={StyledEdit}
                                        flipped
                                        size="sm"
                                        data-floating-menu-container
                                      >
                                        <OverflowMenuItem
                                          onClick={(e) =>
                                            updateLabel(
                                              "Blue",
                                              row.id,
                                              row?.referreeId,
                                              setOutboundItems,
                                              outboundItemsAll
                                            )
                                          }
                                          itemText="Blue"
                                        />
                                        <OverflowMenuItem
                                          onClick={(e) =>
                                            updateLabel(
                                              "Orange",
                                              row.id,
                                              row?.referreeId,
                                              setOutboundItems,
                                              outboundItemsAll
                                            )
                                          }
                                          itemText="Orange"
                                        />
                                        <OverflowMenuItem
                                          onClick={(e) =>
                                            updateLabel(
                                              "Purple",
                                              row.id,
                                              row?.referreeId,
                                              setOutboundItems,
                                              outboundItemsAll
                                            )
                                          }
                                          itemText="Purple"
                                        />
                                        <OverflowMenuItem
                                          onClick={(e) =>
                                            removeLabel(
                                              "Purple",
                                              row.id,
                                              row?.referreeId,
                                              setOutboundItems,
                                              outboundItemsAll
                                            )
                                          }
                                          itemText="Remove label"
                                        />
                                      </StyledOverflowMenu>
                                    </Flex>
                                  </TableSubLabel>
                                </TableCell>
                              ) : (
                                <TableCell>
                                  <Flex style={{ alignItems: "center" }}>
                                    Add a label
                                    <OverflowMenu
                                      renderIcon={StyledAdd}
                                      flipped
                                      size="sm"
                                      data-floating-menu-container
                                    >
                                      <OverflowMenuItem
                                        onClick={(e) =>
                                          addLabel(
                                            "Blue",
                                            row.id,
                                            row?.referreeId,
                                            setOutboundItems,
                                            outboundItemsAll
                                          )
                                        }
                                        itemText="Blue"
                                      />
                                      <OverflowMenuItem
                                        onClick={(e) =>
                                          addLabel(
                                            "Orange",
                                            row.id,
                                            row?.referreeId,
                                            setOutboundItems,
                                            outboundItemsAll
                                          )
                                        }
                                        itemText="Orange"
                                      />
                                      <OverflowMenuItem
                                        onClick={(e) =>
                                          addLabel(
                                            "Purple",
                                            row.id,
                                            row?.referreeId,
                                            setOutboundItems,
                                            outboundItemsAll
                                          )
                                        }
                                        itemText="Purple"
                                      />
                                    </OverflowMenu>
                                  </Flex>
                                </TableCell>
                              )}
                            </>
                          )}
                        </StyledTableRow>
                      );
                    }
                  })}
                </TableBody>
              </Table>
            </StyledTableContainer>
          )}
        </DataTable>
        <FlexCenter>
          <PaginationNav
            page={currentPage}
            itemsShown={width < isMobile ? 4 : 10}
            totalItems={Math.floor(outboundItems.length / 20) + 1}
            onChange={(e) => {
              setPageMin(e * 20);
              setPageMax(e * 20 + 20);
              setCurrentPage(e);
            }}
          />
        </FlexCenter>
        <OutboundFilterModal
          referrals={outboundItemsAll}
          allServices={services}
          modalOpen={openFilterModal}
          setModalOpen={setFilterModalOpen}
          displayedItems={outboundItems}
          setDisplayedItems={setOutboundItems}
          assignStatuses={assignStatuses}
          setAssignStatuses={setAssignStatuses}
          referStatuses={referStatuses}
          setReferStatuses={setReferStatuses}
          selectServices={selectServices}
          setSelectServices={setSelectServices}
          filterBookmarked={filterBookmarked}
        />
      </CardDiv>
    </>
  );
};

export default OutboundReferrals;
