import { useState, useEffect } from "react";
import styled from "styled-components";
import { Button, TextInput } from "carbon-components-react";
import * as S from "../Questions.styles";

const Transportation1 = ({
  cms,
  domain,
  width,
  setAllowContinue,
  transportationResponse,
  setTransportationResponse,
  setFeedbackIndex,
  setOverallIndex
}) => {
  const [response, setResponse] = useState("");
  const [notReceivedService, setNotReceivedService] = useState(false);

  const [buttonSelection, setButtonSelection] = useState({
    0: false,
    1: false
  });

  useEffect(() => {
    if (transportationResponse.q1.answer === true) {
      setButtonSelection({ 0: true, 1: false });
      setAllowContinue(false);
    } else if (transportationResponse.q1.answer === false) {
      setButtonSelection({ 0: false, 1: true });
      setAllowContinue(false);
    } else {
      setAllowContinue(true);
    }
  }, []);

  const nextQuestion = (label) => {
    if (label === false) {
      setFeedbackIndex((index) => index + 2);
      setOverallIndex((index) => index + 2);
    } else {
      setFeedbackIndex((index) => index + 1);
      setOverallIndex((index) => index + 1);
    }
  };

  return (
    <S.TextContainer>
      {/* <h4>Question 7 of 12</h4> */}
      <h5>{cms.transportation.question_1}</h5>
      <S.Wrapper>
        <S.ButtonWrapper>
          <StyledButton
            theme={{ bcolor: buttonSelection[0] }}
            kind="tertiary"
            onClick={() => {
              // setAllowContinue(false);
              setTransportationResponse((state) => ({
                ...state,
                q1: {
                  text: "1 Do you or your household own a motor vehicle?",
                  answer: true
                }
              }));
              setButtonSelection({ 0: true, 1: false });
              nextQuestion(true);
            }}
          >
            {cms.transportation.btn_yes}
          </StyledButton>
          <StyledButton
            theme={{ bcolor: buttonSelection[1] }}
            kind="tertiary"
            onClick={() => {
              // setAllowContinue(false);
              setTransportationResponse((state) => ({
                ...state,
                q1: {
                  text: "1 Do you or your household own a motor vehicle?",
                  answer: false
                }
              }));
              setButtonSelection({ 0: false, 1: true });
              nextQuestion(false);
            }}
          >
            {cms.transportation.btn_no}
          </StyledButton>
        </S.ButtonWrapper>
      </S.Wrapper>
    </S.TextContainer>
  );
};

export default Transportation1;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  @media screen and (min-width: 1800px) {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
  @media screen and (max-width: 768px) {
    height: 60%;
    overflow-y: scroll;
  }
`;

const StyledButton = styled(Button)`
color: ${(props) => (props.theme.bcolor ? "white" : "rgba(0,145,179,1)")};
background-color: ${(props) => (props.theme.bcolor ? "#0f62fe" : "white")};
:hover {
  color: "white";
  background-color: ${(props) => (
    props.theme.bcolor ? "white" : "rgba(0,145,179,1)"
  )};
  }
  :focus {
    color: ${(props) => (props.theme.bcolor ? "white" : "rgba(0,145,179,1)")};
    background-color: ${(props) => (props.theme.bcolor ? "#0f62fe" : "white")};
:active {
  color: "white" !important;
  background-color: "#0f62fe";
}



@media screen and (max-width: 768px) {
  width: 5px;
  height: 4px;
}
`;
