import QRCode from "qrcode.react";
import { Button } from "carbon-components-react";

const QRCodeGen = (props) => {
  let urlBuilder;
  if(props.createdFrom){
    urlBuilder = `${process.env.REACT_APP_CLIENT_URL}create-account?createdFrom=QR-${props.createdFrom}`;
  } else {
    urlBuilder = `${process.env.REACT_APP_CLIENT_URL}create-account`;
  }
  const downloadQR = () => {
    const canvas = document.getElementById("7777");
    const pngUrl = canvas
      .toDataURL("image/png")
      .replace("image/png", "image/octet-stream");
    let downloadLink = document.createElement("a");
    downloadLink.href = pngUrl;
    downloadLink.download = "QR Code.png";
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };

  if (props.displayDownloadLink) {
    return (
      <div>
        <QRCode id="7777" value={urlBuilder} />
        <br />
        <Button
          style={{ cursor: "pointer", marginTop: ".5rem" }}
          onClick={downloadQR}
        >
          Download QR Code
        </Button>
      </div>
    );
  } else {
    return (
      <div>
        <QRCode id="7777" value={urlBuilder} />
      </div>
    );
  }
};

export default QRCodeGen;
