import {
  CheckmarkOutline16,
  CloseOutline16,
  EarthFilled16,
  Edit16,
  Information16,
  Person16,
  PhoneFilled16,
  Time16
} from "@carbon/icons-react";
import {
  Button,
  Dropdown,
  InlineLoading,
  InlineNotification,
  MultiSelect,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
  Toggle,
  Tooltip
} from "carbon-components-react";
import DOMPurify from "dompurify";
import kit from "ich-ui-kit";
import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router";
import styled from "styled-components";
import { getDisplayedStatus, validateUrl, static_confidential_address_text } from "../../utils.js";
import { CapacityBadge, EligableBadge } from "../Badges/badges.jsx";
import {
  ActionContainer,
  AtCapacityIcon,
  BottomRow,
  ButtonOption,
  ButtonOptionContainer,
  CapacityIcon,
  CapacityIconSmall,
  ConnectionDesktopWrapper,
  ConnectionText,
  ConnectionTextMobile,
  InfoIcon,
  InfoWrapper,
  LeftColumn,
  MinHeightModal,
  ProviderInfoCard,
  ProviderInfoHeader,
  ProviderInfoHeaderContent,
  RightColumn,
  SetupDiv,
  SetupItem,
  StyledEdit,
  StyledLocation16,
  StyledModal,
  StyledPill,
  ToggleConnectionText,
  TopRow,
  UnknownIcon,
  Warn,
  WarningCapacityIcon,
  WellbeingImg,
  WellbeingImgSelect,
  WellBeingSelectWrapper
} from "./static.jsx";
import {
  displayAddress,
  parseToArray,
  renderOptions,
  stateOfWellbeingOptions
} from "./utils";
import { getLocalTimeOnce } from '../../utils';
const ProviderCard = ({
  provider,
  setSetupComplete,
  setupComplete,
  slls,
  loadingSLLs,
  isSetupComplete,
  setGetProviderData,
  getProviderData,
  openReferrals,
  loading
}) => {
  const { apiService } = useContext(kit.ApiContext);
  const { user } = useContext(kit.UserContext);
  const [onTeam, setOnTeam] = useState(false);
  const history = useHistory();
  // Capacity
  const [capacity, setCapacity] = useState(provider.capacity);
  const [updatingCapacity, setUpdatingCapacity] = useState(false);
  const [updatedCapacity, setUpdatedCapacity] = useState(false);
  // Wellbeing
  const [wellbeingList, setWellbeingList] = useState([]);
  const [newWellBeing, setNewWellBeing] = useState([]);
  const [updatingStateOfWellbeing, setUpdatingStateOfWellbeing] =
    useState(false);
  // Assign Service Lead
  const [serviceLeads, setServiceLeads] = useState(
    [...new Set(slls.filter((d) => d.isLead === true))]
  );
  const [submittingServiceLead, setSubmittingServiceLead] = useState(false);
  const [orgStaff, setOrgStaff] = useState([...new Set(slls)]);
  const [orgStaffLoading, setOrgStaffLoading] = useState(false);
  // Modals
  const [updateModalOpen, setUpdateModalOpen] = useState(false);
  const [selectModalOpen, setSelectModalOpen] = useState(false);
  const [assignModalOpen, setAssignModalOpen] = useState(false);
  // Service Connection
  const [isConnected, setIsConnected] = useState(provider.connected);
  const [connectionIsLoading, setConnectionIsLoading] = useState(false);
  const [connectionModal, setConnectionModal] = useState(false);
  const [connectionErrModal, setConnectionErrModal] = useState(false);

  const [openDetailsModal, setOpenDetailsModal] = useState(false);

  let provURL = provider.url ? provider.url.toLowerCase() : null;
  if (provURL && provURL.indexOf(".") > 0 && provURL.indexOf(".") < 4) {
    provURL = "https://" + provURL;
  }

  useEffect(() => {
    setNewWellBeing(
      provider?.stateOfWellbeing && provider?.stateOfWellbeing?.length
        ? [...provider?.stateOfWellbeing.split("|")]
        : []
    );
    setWellbeingList(
      provider?.stateOfWellbeing && provider?.stateOfWellbeing?.length
        ? [...provider?.stateOfWellbeing.split("|")]
        : []
    );
  }, [provider]);

  useEffect(() => {
    setOrgStaffLoading(true);
    let cleanedData = slls.filter(
      (s) => s.aliasName || `${s.firstName} ${s.lastName}`
    );
    cleanedData = cleanedData.map((member) => {
      return {
        ...member,
        label: member.aliasName || `${member.firstName} ${member.lastName}`
      };
    });
    setOrgStaff(cleanedData);
    setServiceLeads([...new Set(slls.filter((d) => d.isLead === true))]);
    setOrgStaffLoading(false);
  }, [apiService, slls]);

  const CapacityBadgeStyled = ({ capacity }) => {
    switch (capacity) {
      case "GREEN":
        return (
          <StyledPill>
            <CapacityIconSmall />
            <span>Available Now</span>
          </StyledPill>
        );
      case "YELLOW":
        return (
          <StyledPill>
            <WarningCapacityIcon />
            <span>Almost at Capacity</span>
          </StyledPill>
        );
      case "RED":
        return (
          <StyledPill>
            <AtCapacityIcon />
            <span>No Capacity</span>
          </StyledPill>
        );
      case "":
        return (
          <StyledPill>
            <UnknownIcon />
            <span>Capacity Unknown</span>
          </StyledPill>
        );
      default:
        return (
          <StyledPill>
            <UnknownIcon />
            <span>Capacity Unknown</span>
          </StyledPill>
        );
    }
  };

  const updateCapacity = async (cap) => {
    if (provider.serviceId) {
      try {
        setUpdatingCapacity(true);
        const response = await fetch(
          `${process.env.REACT_APP_API_ENDPOINT}/providers/services/capacity`,
          {
            method: "put",
            credentials: "include",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${user.accessToken} ${user.idToken}`
            },
            body: JSON.stringify({
              serviceId: provider.serviceId,
              sourceSystem: provider.orgSource || "211",
              previousCapacity: provider.capacity || 'N/A',
              capacity: cap,
              userId: user.id,
              userName: user.name,
              locationId: provider?.locationId,
              serviceLocationId: provider?.serviceAtLocationKey
            })
          }
        );
        const info = await response.json();
        if (info) {
          setCapacity(cap);
        }
      } catch (err) {
        console.log(err);
      }
    }
    setUpdatingCapacity(false);
    setUpdatedCapacity(true);
    setGetProviderData(!getProviderData);
    setTimeout(() => {
      setUpdatedCapacity(false);
      setUpdateModalOpen(false);
    }, 2000);
  };

  const determineModal = (e) => {
    const option = e;
    if (option === "update") {
      setUpdateModalOpen(true);
    } else if (option === "select") {
      setSelectModalOpen(true);
    } else if (option === "assign") {
      setAssignModalOpen(true);
    }
  };

  const determineFaceStatus = (e) => {
    if (newWellBeing && newWellBeing.length > 0) {
      if (newWellBeing.includes(e.toString())) {
        return { opacity: "1", transform: "scale(1.1)" };
      } else {
        return { opacity: "0.5", transform: "scale(1)" };
      }
    } else {
      return { opacity: "0.5", transform: "scale(1)" };
    }
  };

  const handleWellbeingSubmit = async () => {
    if (provider) {
      try {
        setUpdatingStateOfWellbeing(true);
        const data = await apiService.put(
          `/providers/services/state-of-wellbeing?assumedId=${
            user.superAssumedRole?.leadId || ""
          }`,
          {
            serviceId: provider.serviceId,
            orgSource: provider.orgSource || "211",
            sowb: newWellBeing.join("|"),
            locationId: provider?.locationId
          }
        );
        if (data.ok) {
          setGetProviderData(!getProviderData);
          setSelectModalOpen(false);
        }
      } catch (err) {
        console.log(err);
      }
      setGetProviderData(!getProviderData);
      setUpdatingStateOfWellbeing(false);
    }
  };

  const handleServiceLineSubmit = async () => {
    setSubmittingServiceLead(true);
    if (serviceLeads) {
      const data = await apiService.post("/account/service-line-leads", {
        serviceLineLead: serviceLeads.map((lead) => lead.userId),
        serviceId: provider?.serviceAtLocationKey
      });
      if (data.ok) {
        setAssignModalOpen(false);
      } else {
        // TODO - Show there was an error with an inline notification
      }
    }
    setGetProviderData(!getProviderData);
    setSubmittingServiceLead(false);
  };

  const handleWellbeingCheckbox = (el) => {
    if (!newWellBeing.includes(el.id)) {
      setNewWellBeing([...newWellBeing, el.id]);
    } else {
      let temp = [...newWellBeing];
      temp = temp.filter((wb) => wb !== el.id);
      setNewWellBeing(temp);
    }
  };

  const renderCircle = () => {
    let loopArray;
    if (newWellBeing && newWellBeing.length) {
      loopArray = newWellBeing;
    } else {
      loopArray = wellbeingList;
    }
    return (
      <div style={{ marginLeft: "1rem", marginTop: "0.5rem" }}>
        {loopArray
          .sort((a, b) => parseInt(a) - parseInt(b))
          .map((wellbeing) => {
            if (wellbeing === "1") {
              return (
                <WellbeingImg src="/faces/not-okay-red.svg" alt="Red Smiley" />
              );
            } else if (wellbeing === "2") {
              return (
                <WellbeingImg src="/faces/ok-orange.svg" alt="Orange Smiley" />
              );
            } else if (wellbeing === "3") {
              return (
                <WellbeingImg
                  src="/faces/good-yellow.svg"
                  alt="Yellow Smiley"
                />
              );
            } else if (wellbeing === "4") {
              return (
                <WellbeingImg src="/faces/fine-blue.svg" alt="Blue Smiley" />
              );
            } else if (wellbeing === "5") {
              return (
                <WellbeingImg
                  src="/faces/future-fine-green.svg"
                  alt="Green Smiley"
                />
              );
            }
          })}
      </div>
    );
  };
  const [tooltipOpen, setToolTipOpen] = useState(false);

  // Toggle Service Connection
  const toggleServiceConnection = async () => {
    setConnectionIsLoading(true);
    const toggling = await apiService.put(
      `/providers/services?assumedId=${
        user.superAssumedRole?.leadId || ""
      }`,
      {
        operation: isConnected ? 'disconnect' : 'connect',
        sourceSystem: provider.orgSource,
        serviceLocationId: provider?.serviceAtLocationKey
      }
    );
    if (toggling.ok) {
      setIsConnected(!isConnected);
    } else {
      console.error("Error: ", toggling);
    }
    setConnectionIsLoading(false);
    setConnectionModal(false);
  };

  const openModal = async () => {
    if (openReferrals.length > 0) {
      setConnectionErrModal(true);
    } else {
      setConnectionModal(true);
    }
  };

  const removeHtml = (_text) => {
    if (_text !== null && _text !== undefined) {
      let res = new DOMParser().parseFromString(_text, "text/html");
      res = res.body.textContent;
      return res.replace(/[\u{0080}-\u{FFFF}]/gu, " ");
    }
    return _text;
  };

  const getProviderAddress = () => {
    return displayAddress(provider, static_confidential_address_text)
  }

  const Badges = ({ onTeam, capacity, eligable }) => {
    return (
      <BadgeDiv eligable={eligable}>
        {eligable && (
          <EligableBadge
            eligable={eligable}
            badgeStyle={{ marginTop: "0.5rem" }}
          />
        )}
        {eligable && (
          <CapacityBadge
            capacity={capacity}
            badgeStyle={{ marginTop: "0.5rem" }}
          />
        )}
      </BadgeDiv>
    );
  };
  return (
    <>
      <ProviderInfoCard>
        <TopRow>
          <LeftColumn>
            <ProviderInfoHeader>
              <ProviderInfoHeaderContent>
                <h5
                  style={{ cursor: "pointer" }}
                  onClick={() => setOpenDetailsModal(true)}
                >
                  {provider.name}
                </h5>
                {user.isAdmin && !user.isViewOnly ? (
                  <ConnectionTextMobile
                    onClick={
                      isConnected ? () => openModal() : toggleServiceConnection
                    }
                  >
                    {isConnected ? "Connected" : "Not Connected"}
                  </ConnectionTextMobile>
                ) : (
                  <ConnectionTextMobile>
                    {isConnected ? "Connected" : "Not Connected"}
                  </ConnectionTextMobile>
                )}
                <InfoWrapper>
                  <StyledLocation16 />
                  <span>{getProviderAddress()}</span>
                </InfoWrapper>
              </ProviderInfoHeaderContent>
            </ProviderInfoHeader>
          </LeftColumn>
          <RightColumn style={{}}>
            {!isSetupComplete && (
              <>
                {capacity &&
                wellbeingList.length !== 0 &&
                serviceLeads &&
                serviceLeads.length !== 0 ? (
                  <ActionContainer>
                    <h5>Setup Complete</h5>
                    <CapacityIcon />
                  </ActionContainer>
                ) : (
                  <ActionContainer>
                    <h5>Setup Required</h5>
                    <Warn />
                  </ActionContainer>
                )}
              </>
            )}
            {user.isAdmin && !user.isViewOnly ? (
              <ConnectionDesktopWrapper
                isSetupComplete={isSetupComplete}
                style={{ cursor: loading ? "default" : "pointer" }}
              >
                {connectionIsLoading ? (
                  <InlineLoading
                    description="Updating..."
                    style={{ minHeight: "1.5rem" }}
                  />
                ) : (
                  <Toggle
                    id={
                      "connection-toggle" +
                      provider.serviceId +
                      "-" +
                      provider.locationId
                    }
                    labelA="Not Connected"
                    labelB="Connected"
                    defaultToggled={isConnected}
                    toggled={
                      connectionErrModal || connectionModal
                        ? false
                        : isConnected
                    }
                    onClick={
                      loading
                        ? () => false
                        : isConnected
                        ? () => openModal()
                        : toggleServiceConnection
                    }
                  />
                )}
              </ConnectionDesktopWrapper>
            ) : (
              <ConnectionDesktopWrapper isSetupComplete={isSetupComplete}>
                {connectionIsLoading ? (
                  <InlineLoading
                    description="Updating..."
                    style={{ minHeight: "1.5rem" }}
                  />
                ) : isConnected ? (
                  <ConnectionText>
                    Connected <CheckmarkOutline16 color="green" />
                  </ConnectionText>
                ) : (
                  <ConnectionText>
                    Not Connected <CloseOutline16 color="red" />
                  </ConnectionText>
                )}
              </ConnectionDesktopWrapper>
            )}
          </RightColumn>
        </TopRow>
        {user.isAdmin && !user.isViewOnly ? (
          <BottomRow>
            {capacity ? (
              <SetupDiv>
                <SetupItem
                  onClick={(e) => {
                    e.preventDefault();
                    determineModal("update");
                  }}
                >
                  <h5>Update Service Capacity</h5>
                  <StyledEdit />
                </SetupItem>
                <div
                  style={{ marginLeft: "1rem", fontSize: "10px !important" }}
                >
                  <CapacityBadgeStyled capacity={capacity} />
                </div>
              </SetupDiv>
            ) : (
              <SetupItem
                onClick={(e) => {
                  e.preventDefault();
                  determineModal("update");
                }}
              >
                <h5>Set Service Capacity</h5>
                <StyledEdit />
              </SetupItem>
            )}
            {wellbeingList ? (
              <SetupDiv style={{ flexDirection: "column" }}>
                <SetupItem
                  onClick={(e) => {
                    e.preventDefault();
                    determineModal("select");
                  }}
                >
                  <h5>Recommended States of Well-being</h5>
                  <StyledEdit />
                </SetupItem>
                <div>{renderCircle()}</div>
              </SetupDiv>
            ) : (
              <SetupItem
                onClick={(e) => {
                  e.preventDefault();
                  determineModal("select");
                }}
              >
                <h5>Recommended States of Well-being</h5>
                <StyledEdit />
              </SetupItem>
            )}
            {loadingSLLs ? (
              <SetupDiv>
                <InlineLoading
                  style={{ marginLeft: "5rem", marginTop: "1rem" }}
                />
              </SetupDiv>
            ) : serviceLeads ? (
              <SetupDiv>
                <SetupItem
                  onClick={(e) => {
                    e.preventDefault();
                    determineModal("assign");
                  }}
                >
                  <h5>Update Service Line Lead</h5>
                  <StyledEdit />
                </SetupItem>
                <h6 style={{ marginLeft: "1rem" }}>
                  {serviceLeads.map((lead) => lead.aliasName || `${lead.firstName} ${lead.lastName}`).join(", ")}
                </h6>
              </SetupDiv>
            ) : slls && slls.length > 0 ? (
              <SetupItem
                onClick={(e) => {
                  e.preventDefault();
                  determineModal("assign");
                }}
              >
                <h5>Identify Service Line Lead</h5>
                <StyledEdit />
              </SetupItem>
            ) : (
              <SetupItem canupdate={"grey"}>
                <h5
                  onMouseEnter={(e) => {
                    e.preventDefault();
                    setToolTipOpen(!tooltipOpen);
                  }}
                  onMouseLeave={(e) => {
                    e.preventDefault();
                    setToolTipOpen(!tooltipOpen);
                  }}
                >
                  Identify Service Line Lead
                </h5>
                <StyledEdit />
                <Tooltip open={tooltipOpen} direction="top" showIcon={false}>
                  <p>
                    You need to assign staff to this service to identify a
                    service line lead. Please visit the Provider User Set Up section
                    of your Admin Account to invite and assign staff to
                    services.
                  </p>
                </Tooltip>
              </SetupItem>
            )}
          </BottomRow>
        ) : (
          <BottomRow>
            {capacity ? (
              <SetupDiv>
                <SetupItem>
                  <h5>Service Capacity</h5>
                </SetupItem>
                <div
                  style={{ marginLeft: "1rem", fontSize: "10px !important" }}
                >
                  <CapacityBadgeStyled capacity={capacity} />
                </div>
              </SetupDiv>
            ) : (
              <SetupDiv>
                <SetupItem>
                  <h5>Service Capacity</h5>
                </SetupItem>
                <div
                  style={{ marginLeft: "1rem", fontSize: "10px !important" }}
                >
                  <CapacityBadgeStyled capacity={"UNKNOWN"} />
                </div>
              </SetupDiv>
            )}
            {wellbeingList ? (
              <SetupDiv>
                <SetupItem>
                  <h5>Recommended States of Well-being</h5>
                </SetupItem>
                <div>{renderCircle()}</div>
              </SetupDiv>
            ) : (
              <SetupItem>
                <h5>Recommended States of Well-being</h5>
              </SetupItem>
            )}
            {loadingSLLs || orgStaffLoading ? (
              <SetupDiv>
                <InlineLoading
                  style={{ marginLeft: "5rem", marginTop: "1rem" }}
                />
              </SetupDiv>
            ) : serviceLeads ? (
              <SetupDiv>
                <SetupItem>
                  <h5>Service Line Lead</h5>
                </SetupItem>
                <h6 style={{ marginLeft: "1rem" }}>
                  {serviceLeads.map((lead) => lead.aliasName || `${lead.firstName} ${lead.lastName}`).join(", ")}
                </h6>
              </SetupDiv>
            ) : !serviceLeads ? (
              <SetupItem>
                <h5>Service Line Lead Not Identified</h5>
              </SetupItem>
            ) : (
              <SetupItem canupdate={"grey"}>
                <h5>Service Line Lead Not Identified</h5>
              </SetupItem>
            )}
          </BottomRow>
        )}
      </ProviderInfoCard>

      <StyledModal
        open={updateModalOpen}
        onRequestClose={() => setUpdateModalOpen(false)}
        modalHeading="Update Capacity"
        passiveModal
      >
        {updatingCapacity ? (
          <InlineLoading description={"Updating..."} />
        ) : (
          <>
            <ButtonOptionContainer>
              {renderOptions(onTeam, provider.orgEmail).map((option) => (
                <ButtonOption
                  key={option.key}
                  onClick={() => updateCapacity(option.value)}
                  kind="tertiary"
                >
                  {option.label}
                </ButtonOption>
              ))}
            </ButtonOptionContainer>
          </>
        )}
        {updatedCapacity && (
          <InlineNotification
            kind="success"
            title="Updated capacity successfully"
          />
        )}
      </StyledModal>
      <StyledModal
        open={selectModalOpen}
        onRequestClose={() => {
          setGetProviderData(!getProviderData);
          // setNewWellBeing(wellbeingList)
          setSelectModalOpen(false);
        }}
        onSecondarySubmit={() => {
          // setNewWellBeing(wellbeingList)
          setGetProviderData(!getProviderData);
          setSelectModalOpen(false);
        }}
        primaryButtonDisabled={
          updatingStateOfWellbeing
        }
        modalHeading="Recommended States of Well-being"
        primaryButtonText={
          updatingStateOfWellbeing ? (
            <InlineLoading
              description="Updating..."
              style={{ minHeight: "auto" }}
            />
          ) : (
            "Submit"
          )
        }
        secondaryButtonText="Cancel"
        onRequestSubmit={handleWellbeingSubmit}
      >
        <div>
          <p>Please select States of Well-being that this Service addresses.</p>
          <br />
          <br />
          <fieldset
            style={{ display: "flex", justifyContent: "space-around" }}
            id={provider.serviceId}
          >
            {stateOfWellbeingOptions().map((option, i) => (
              <WellBeingSelectWrapper
                key={provider.serviceId + "-" + option.id}
                onClick={(e) => handleWellbeingCheckbox(e.target)}
                style={determineFaceStatus(option.id)}
              >
                <WellbeingImgSelect
                  src={option.src}
                  alt={option.alt}
                  id={option.id}
                />
              </WellBeingSelectWrapper>
            ))}
          </fieldset>
        </div>
      </StyledModal>
      <MinHeightModal
        open={assignModalOpen}
        onRequestClose={() => {
          setGetProviderData(!getProviderData);
          setAssignModalOpen(false);
        }}
        onSecondarySubmit={() => {
          setGetProviderData(!getProviderData);
          setAssignModalOpen(false);
        }}
        primaryButtonDisabled={
          loadingSLLs || !serviceLeads || submittingServiceLead
        }
        modalHeading="Assign a Service Line Lead"
        primaryButtonText={
          submittingServiceLead ? (
            <InlineLoading description="Submitting" />
          ) : (
            "Submit"
          )
        }
        secondaryButtonText="Cancel"
        onRequestSubmit={handleServiceLineSubmit}
      >
        {loadingSLLs || orgStaffLoading ? (
          <InlineLoading description={"Loading Staff Members..."} />
        ) : (
          <MultiSelect
            id={"service-lead"}
            items={orgStaff}
            itemToString={(item) => (item ? item.label : "")}
            label={"Choose " + provider.name + " Staff"}
            titleText="Select a Lead"
            initialSelectedItems={[]}
            onChange={(e) => {
              setServiceLeads(e.selectedItems);
            }}
            selectionFeedback="top-after-reopen"
          />
        )}
      </MinHeightModal>
      <StyledModal
        open={connectionModal}
        onRequestClose={() => setConnectionModal(false)}
        onSecondarySubmit={() => setConnectionModal(false)}
        primaryButtonDisabled={connectionIsLoading || loading}
        modalHeading="Disconnect Service"
        primaryButtonText={
          connectionIsLoading ? (
            <InlineLoading
              description="Disconnecting..."
              style={{ minHeight: "auto" }}
            />
          ) : (
            "Disconnect"
          )
        }
        secondaryButtonText="Cancel"
        onRequestSubmit={toggleServiceConnection}
      >
        {loading ? (
          <InlineLoading />
        ) : (
          <div>
            <p>Are you sure you want to disconnect this service?</p>
          </div>
        )}
      </StyledModal>
      <StyledModal
        open={connectionErrModal}
        onRequestClose={() => setConnectionErrModal(false)}
        modalHeading={"Cannot Disconnect Service " + provider.name}
        passiveModal
      >
        {loading ? (
          <InlineLoading />
        ) : (
          <div>
            <p>
              This service has active referrals and cannot be disconnected.
              Please close the active referral(s) listed below before
              disconnecting this service.
            </p>
            <br />
            <Table>
              <TableHead>
                <TableRow>
                  {["Id", "Date", "Client", "Status"].map((header) => (
                    <TableHeader id={header.key} key={header}>
                      {header}
                    </TableHeader>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {openReferrals.map((row) => (
                  <TableRow key={row.id}>
                    <TableCell
                      key={row.id}
                      style={{ cursor: 'pointer'}}
                      onClick={(e) => {
                        const clientID = row?.referreeId;
                        history.push({
                          pathname: "/referral-dashboard/referral",
                          state: {
                            clientID,
                            referralId: row?.id,
                            referral: row,
                            cameFrom: "referral-dashboard",
                            onCareTeam: row?.isOnTeam
                          }
                        });
                      }}
                    >
                      <a>{row.id}</a>
                    </TableCell>
                    <TableCell key={row.id + row.authoredOn}>
                      {getLocalTimeOnce(row.authoredOn)}
                    </TableCell>
                    <TableCell key={row.referree}>{row.referree}</TableCell>
                    <TableCell key={row.id + row.status}>
                      {getDisplayedStatus(row.status)}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        )}
      </StyledModal>
      <StyledModal
        open={openDetailsModal}
        onRequestClose={() => setOpenDetailsModal(false)}
        passiveModal
        modalHeading={provider.name}
        modalLabel=""
      >
        <Content>
          <Badges capacity={provider.capacity} eligable={provider?.connected} />
          {(user.isAdmin || user.is211Admin || user.isSuper) && (
            <Button
              kind="tertiary"
              renderIcon={Information16}
              iconDescription="Information Icon"
              onClick={() =>
                window.open(
                  validateUrl(`https://211lifeline.org/form_agencies.php?id=${provider.serviceId}`)
                )
              }
            >
              Edit this Information in iCarol
            </Button>
          )}
          <div
            style={{
              position: "relative",
              display: "flex",
              flexFlow: "column nowrap",
              overflowY: "auto"
            }}
          >
            {provider.overview && (
              <Fieldset>
                <StyledHeader>Overview:</StyledHeader>
                <div
                  dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(provider.overview, {
                      FORBID_ATTR: ["style"]
                    })
                  }}
                ></div>
              </Fieldset>
            )}
            {provider.serviceArea && (
              <Fieldset>
                <StyledHeader>Service Area:</StyledHeader>
                <p>{removeHtml(provider.serviceArea)}</p>
              </Fieldset>
            )}
            {provider.eligibility && (
              <Fieldset>
                <StyledHeader>Eligibility:</StyledHeader>
                <p>{removeHtml(provider.eligibility)}</p>
              </Fieldset>
            )}
            {provider.requiredDocs && (
              <Fieldset>
                <StyledHeader>Required Documents:</StyledHeader>
                <p>{removeHtml(provider.requiredDocs)}</p>
              </Fieldset>
            )}
            {provider.fees && (
              <Fieldset>
                <StyledHeader>Fees:</StyledHeader>
                <p>{removeHtml(provider.fees)}</p>
              </Fieldset>
            )}
            {provider.applicationProcess && (
              <Fieldset>
                <StyledHeader>Application Process:</StyledHeader>
                <p>{removeHtml(provider.applicationProcess)}</p>
              </Fieldset>
            )}
            {provider.accessibility && (
              <Fieldset>
                <StyledHeader>Accessibility:</StyledHeader>
                {!provider.accessibility ? (
                  <p>No information available at this time</p>
                ) : !provider.accessibility.includes("|") ? (
                  <p>{removeHtml(provider.accessibility)}</p>
                ) : (
                  parseToArray(provider.accessibility).map((val) => {
                    return <p>{removeHtml(val)}</p>;
                  })
                )}
              </Fieldset>
            )}
            {provider.moreInformation && (
              <Fieldset>
                <StyledHeader>More Information:</StyledHeader>
                <p>{removeHtml(provider.moreInformation)}</p>
              </Fieldset>
            )}
          </div>
          <div style={{ paddingTop: "1em" }} id="info-list">
            <InfoWrapper>
              <StyledLocation16 />
              <span>{removeHtml(displayAddress(provider))}</span>
            </InfoWrapper>
            {provider.hours && (
              <InfoWrapper>
                <StyledTime16 />
                <span>{removeHtml(provider.hours)}</span>
              </InfoWrapper>
            )}
            {provURL && (
              <InfoWrapper>
                <StyledEarthFilled16 />
                <span>
                  <a target="_blank" href={provURL} rel="noreferrer noopener">
                    {removeHtml(provider.url)}
                  </a>
                </span>
              </InfoWrapper>
            )}
            {provider.phone && (
              <InfoWrapper>
                <StyledPhoneFilled16 />
                <span>{removeHtml(provider.phone)}</span>
              </InfoWrapper>
            )}
          </div>
        </Content>
      </StyledModal>
    </>
  );
};

const ReferralButton = styled(Button)`
  margin-top: 0.5rem;
  padding-right: 0.5rem;
  min-height: 2rem;
  height: 2rem;
  width: 15rem;
  @media screen and (min-width: 767px) {
    height: 2.5rem;
  }
  @media screen and (min-width: 1023px) {
    height: 3rem;
  }
`;

const Content = styled.div`
  position: relative;
  display: flex;
  flex-flow: column nowrap;
  overflow-y: auto;
  width: 100%;
  padding-bottom: 1.5rem;
`;

const ModalContentWrapper = styled.div`
  width: 95%;
  @media screen and (max-width: 600px) {
    width: 98%;
  }
`;

const Fieldset = styled.div`
  position: relative;
  height: auto;
  width: 100%;
  padding: 1rem 0;
`;

const StyledTime16 = styled(Time16)`
  color: blue;
  margin-right: 8px;
`;

const StyledEarthFilled16 = styled(EarthFilled16)`
  color: blue;
  margin-right: 8px;
`;

const StyledPhoneFilled16 = styled(PhoneFilled16)`
  color: blue;
  margin-right: 8px;
`;

const StyledPerson16 = styled(Person16)`
  color: blue;
  margin-right: 8px;
`;

const ProviderHeaderWrapper = styled.div`
  padding-bottom: 1em;
`;
const StyledHeader = styled.h5`
  margin-top: 0.5rem;
  margin-bottom: 0.3rem;
`;

const ButtonTextWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

const StyledButton = styled(Button)`
  padding-right: 25px;
  @media screen and (max-width: 500px) {
    min-height: 2rem;
    height: 2rem;
    font-weight: 300;
    padding-right: 14px;
  }
`;

const BadgeDiv = styled.div`
  display: flex;
  flex-direction: row;
  width: 380px;
  margin-bottom: 1rem;
  @media screen and (max-width: 670px) {
    width: 250px;
    flex-direction: column;
    margin-left: 52px;
    margin-bottom: 1rem;
  }
  @media screen and (max-width: 310px) {
    flex-direction: column;
    margin-left: 45px;
    margin-bottom: 1rem;
  }
`;

export default ProviderCard;
