import kit from "ich-ui-kit";
import { useContext, useEffect, useState } from "react";
import { getLocalTime } from "../../utils";
import GaugeChart from "./components/gauge";
import DashboardHeaderContainer from "./components/header";
import OverviewCards from "./components/overview-cards";
import ToggleCharts from "./components/toggle-charts";
import WellBeingImprovements from "./components/well-being-improvements";
import { PageContainer, ResponsiveGrid } from "./index.styled";
import { categories } from "./utils";

const ImpactPage = () => {
  const { user } = useContext(kit.UserContext);
  const { apiService } = useContext(kit.ApiContext);

  // Loading States
  // const [isWouldYouRecommendLoading, setIsWouldYouRecommendLoading] =
  //   useState(false);
  const [clientsLoading, setClientsLoading] = useState(false);
  const [careLoading, setCareLoading] = useState(false);
  const [providersLoading, setProvidersLoading] = useState(false);
  const [providerInfoLoading, setProviderInfoLoading] = useState(false);
  const [wellBeingLoading, setWellBeingLoading] = useState(false);

  // Overview Cards States
  const [registeredClients, setRegisteredClients] = useState(0);
  const [totalCareTeams, setCareTeams] = useState(0);
  const [totalProviders, setProviders] = useState(0);
  // const [wouldYouRecMwf, setWouldYouRecMwf] = useState(0);
  const [referralsReceived, setReferralsReceived] = useState(0);
  const [referralsSent, setReferralsSent] = useState(0);
  const [avgDays, setAvgDays] = useState(0);

  // Well Being Improvement States
  const [wellBeingImprovements, setWellBeingImprovements] = useState([]);

  // Table States
  const [referralsSentByService, setReferralsSentByService] = useState([]);
  const [referralsReceivedByService, setReferralsReceivedPerService] = useState(
    []
  );
  const [connectedByServiceTable, setConnectedByServiceTable] = useState([]);

  // Toggle States
  const [toggleConnectedByServiceTable, setToggleConnectedByServiceTable] =
    useState("bar");
  const [
    toggleReferralsReceivedByService,
    setToggleReferralsReceivedByService
  ] = useState("bar");
  const [toggleReferralsSentByService, setToggleReferralsSentByService] =
    useState("bar");

  // For Responsiveness
  const [width, setWidth] = useState(window.innerWidth);
  useEffect(() => {
    const handleResizeWindow = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleResizeWindow);
    return () => {
      window.removeEventListener("resize", handleResizeWindow);
    };
  }, []);

  useEffect(() => {
    const wellBeingWithZero = categories.map((el) => ({ ...el, value: "0" }));
    setWellBeingImprovements(wellBeingWithZero);
  }, []);

  // For Toggling
  const toggleChart = (state, setState) => {
    switch (state) {
      case "donut":
        setState("bar");
        return;
      case "bar":
        setState("donut");
        return;
    }
  };

  useEffect(() => {
    getAllClients();
    getCareTeam();
    getAllConnectedProviders();
    // getWouldYouReferMwf();
    getWellBeing();
    getProviderInfo();
  }, []);

  // Registered users
  const getAllClients = async () => {
    setClientsLoading(true);
    const clientsTotal = await apiService.get("/stats/clients");
    if (clientsTotal) {
      setRegisteredClients(clientsTotal.total);
    }
    setClientsLoading(false);
  };

  // Well Being Improvements
  const getWellBeing = async () => {
    setWellBeingLoading(true);
    const response = await apiService.get(
      `/stats/count/improvements?isProvider=true?assumedId=${
        user.superAssumedRole?.leadId || ""
      }`
    );
    if (response.ok) {
      const formatted = categories.map((el, i) => {
        let myvalue = response.data.find((val) => val.domain === el.domain)?.count;
        return {
          ...el,
          value: myvalue
        };
      });
      setWellBeingImprovements(formatted);
    }
    setWellBeingLoading(false);
  };

  // Care Team Connections
  const getCareTeam = async () => {
    setCareLoading(true);
    const careTeam = await apiService.get(
      `/stats/careteams?assumedId=${user.superAssumedRole?.leadId || ""}`
    );
    if (careTeam?.ok) {
      setCareTeams(careTeam?.totalCount);

      // Formatting for table
      const list = [];
      careTeam.careTeamStats.map((values) => {
        list.push({ group: values.serviceName, value: values.serviceCount });
      });
      list.sort((a, b) => {
        return b.value - a.value;
      });
      setConnectedByServiceTable(list);
    }
    setCareLoading(false);
  };

  // All connected providers
  const getAllConnectedProviders = async () => {
    setProvidersLoading(true);
    const providersTotal = await apiService.get(
      "/stats/count/connectedproviders"
    );
    if (providersTotal.ok) {
      setProviders(providersTotal.total);
    }
    setProvidersLoading(false);
  };

  // Would you refer percent
  // const getWouldYouReferMwf = async () => {
  //   setIsWouldYouRecommendLoading(true);
  //   const { ok, data } = await apiService.get(
  //     `/stats/nps?isProvider=${true}&assumedId=${
  //       user.superAssumedRole?.leadId || user.id
  //     }`
  //   );
  //   if (ok) {
  //     setWouldYouRecMwf(parseInt(data));
  //   }
  //   setIsWouldYouRecommendLoading(false);
  // };

  // Referrals
  const getProviderInfo = async () => {
    setProviderInfoLoading(true);
    let tempallservices = [];
    try {
        let receivedAllRefs = await apiService.get(`/referrals?assumedId=${user.superAssumedRole?.leadId || ""}`);
        let receivedRefs = getLocalTime(receivedAllRefs.referralsByService);
        let sentRefs = getLocalTime(receivedAllRefs.referralsByRequester);

        let avgDaysToCompletion = 0;
        if (
          receivedAllRefs?.referralsByRequester &&
          receivedAllRefs?.referralsByService
        ) {
          let daysToCompletion = [];

          const tempReferralsSentByService = [];
          setReferralsSent(sentRefs);
          tempallservices = [...new Set(sentRefs.map((val) => val.servName))];
          sentRefs.map((el) => {
            // Calculate Avg Completion Time
            if (el.closeDate && el.authoredOn) {
              const startDate = new Date(el.authoredOn);
              const endDate = new Date(el.closeDate);
              let difference = endDate.getTime() - startDate.getTime();
              difference = difference / (1000 * 3600 * 24);
              daysToCompletion.push(difference);
            }
            // For Bar Chart
            const tempObj = { group: el.name, value: 0 };
            if (tempallservices.includes(el.name)) {
              if (
                tempReferralsSentByService.some((obj) => obj.group === el.name)
              ) {
                tempReferralsSentByService.map(
                  (obj) => obj.group === el.name && obj.value++
                );
              } else {
                tempReferralsSentByService.push(tempObj);
              }
            }
          });
          tempReferralsSentByService.sort((a, b) => {
            return b.value - a.value;
          });
          setReferralsSentByService(tempReferralsSentByService.splice(0,10));

          const tempReferralsReceivedPerService = [];
          setReferralsReceived(receivedRefs);
          receivedRefs.map((el) => {
            // Calculate Avg Completion Time
            if (el.closeDate && el.authoredOn) {
              const startDate = new Date(el.authoredOn);
              const endDate = new Date(el.closeDate);
              let difference = endDate.getTime() - startDate.getTime();
              difference = difference / (1000 * 3600 * 24);
              daysToCompletion.push(difference);
            }

            // For Bar Chart
            const tempObj = { group: el.name, value: 0 };
            if (
              tempReferralsReceivedPerService.some(
                (obj) => obj.group === el.name
              )
            ) {
              tempReferralsReceivedPerService.map(
                (obj) => obj.group === el.name && obj.value++
              );
            } else {
              tempReferralsReceivedPerService.push(tempObj);
            }
          });
          tempReferralsReceivedPerService.sort((a, b) => {
            return b.value - a.value;
          });
          setReferralsReceivedPerService(tempReferralsReceivedPerService);

          avgDaysToCompletion =
            daysToCompletion.reduce((a, b) => a + b, 0) /
            daysToCompletion.length;
          if (avgDaysToCompletion && avgDaysToCompletion !== 0) {
            setAvgDays(parseInt(avgDaysToCompletion));
          }
        }
        setProviderInfoLoading(false);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      <DashboardHeaderContainer backgroundColor="#4ac1e0" />
      <PageContainer>
        <OverviewCards
          totalCareTeams={totalCareTeams}
          totalNeedsRequests={avgDays}
          totalProviderOrgs={totalProviders}
          totalReferrals={referralsSent.length}
          totalResources={referralsReceived.length}
          registeredIndividuals={registeredClients}
          careLoading={careLoading}
          needsLoading={providerInfoLoading}
          clientsLoading={clientsLoading}
          providersLoading={providersLoading}
          servicesAndReferralsLoading={providerInfoLoading}
        />
        <WellBeingImprovements
          data={wellBeingImprovements}
          loading={wellBeingLoading}
        />
        <ResponsiveGrid>
          <ToggleCharts
            title={"Connected Individuals by Service"}
            label={"Individuals"}
            data={connectedByServiceTable}
            loading={careLoading}
            type={toggleConnectedByServiceTable}
            toggle={() =>
              toggleChart(
                toggleConnectedByServiceTable,
                setToggleConnectedByServiceTable
              )
            }
          />
          <ToggleCharts
            title={"Number of Referrals Received"}
            label={"Referrals"}
            data={referralsReceivedByService}
            loading={providerInfoLoading}
            type={toggleReferralsReceivedByService}
            toggle={() =>
              toggleChart(
                toggleReferralsReceivedByService,
                setToggleReferralsReceivedByService
              )
            }
          />
          <ToggleCharts
            title={"Number of Referrals Sent"}
            label={"Referrals"}
            data={referralsSentByService}
            loading={providerInfoLoading}
            type={toggleReferralsSentByService}
            toggle={() =>
              toggleChart(
                toggleReferralsSentByService,
                setToggleReferralsSentByService
              )
            }
          />
          {/* <GaugeChart
            title={"Satisfaction Score"}
            subtitle={"Would you recommend MyWayfinder to a friend?"}
            responsiveness={width}
            value={wouldYouRecMwf}
            delta={0}
            loading={isWouldYouRecommendLoading}
          /> */}
        </ResponsiveGrid>
      </PageContainer>
    </>
  );
};

export default ImpactPage;
