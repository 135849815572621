import React, { useState, useEffect } from "react";
import { useTheme } from "styled-components";
import * as S from "./eSearch.styled";

import { Category } from "./category";

export const SearchBy = ({
  setInputType,
  setCategory,
  domains,
  setSelected,
  style
}) => {
  const theme = useTheme();
  const menu = [
    {
      category: "",
      subcategory: [[{ label: "", link: "", slug: "" }]]
    }
  ];

  const items = [
    {
      domain: "Dependent Care",
      link: "/domains/dependentcare.svg",
      color: "#72a94e"
    },
    {
      domain: "Education",
      link: "/domains/education.svg",
      color: "#72a94e"
    },
    {
      domain: "Employment",
      link: "/domains/employment.svg",
      color: "#005d83"
    },
    {
      domain: "Financial",
      link: "/domains/financial.svg",
      color: "#93d500"
    },
    {
      domain: "Food",
      link: "/domains/food.svg",
      color: "#005d83"
    },
    {
      domain: "Health",
      link: "/domains/Health.svg",
      color: "#0091b3"
    },
    {
      domain: "Housing",
      link: "/domains/housing.svg",
      color: "#4ac1e0"
    },
    {
      domain: "Income",
      link: "/domains/financial.svg",
      color: "#005d83"
    },
    {
      domain: "Safety",
      link: "/domains/safety.svg",
      color: "#005d83"
    },
    {
      domain: "Social Connections",
      link: "/domains/socialconnection.svg",
      color: "#93d500"
    },
    {
      domain: "Transportation",
      link: "/domains/transportation.svg",
      color: "#0091b3"
    }
  ];
  const [desc, setDesc] = useState("");
  const [shw, setShw] = useState("none");
  const [filteredItems, setFilteredItems] = useState(items);
  const [loading, setLoading] = useState(false);

  //
  const [current, setCurrent] = useState("");
  const [subCateg, setSubCateg] = useState(undefined);
  const [showSubCateg, setShowSubCateg] = useState(false);

  useEffect(() => {
    // if (need === "") {
    //   setFilteredItems(items);
    // }
    // setLoading(true);
    // const newState = items.filter((item) => {
    //   const domain = need === undefined ? "" : need;
    //   let pattern = new RegExp(`^${domain.toLowerCase()}`, "gi");
    //   return pattern.test(item.domain.toLowerCase());
    // });
    // setFilteredItems(newState);
    // setLoading(false);
  }, []);

  return (
    <>
      <S.Container
        // onMouseLeave={() => {
        //   setShow(false);
        // }}
        theme={{
          ...theme,
          show: showSubCateg
        }}
        style={style}
      >
        <S.ItemContainer>
          {loading
            ? undefined
            : domains.map((category) => {
                const meta = items.find(
                  (item) =>
                    item.domain.toLowerCase() === category.domain.toLowerCase()
                );

                return (
                  <Category
                    setInputType={setInputType}
                    setCategory={setCategory}
                    category={{
                      ...category,
                      link: meta.link,
                      color: meta.color
                    }}
                    current={{
                      isCurrent: current,
                      setCurrent: setCurrent,
                      selected: setSelected
                    }}
                    subCategory={{
                      set: setSubCateg,
                      setShow: setShowSubCateg
                    }}
                  />
                );
              })}
        </S.ItemContainer>
        <S.Sub
          // onMouseLeave={() => {
          //   setTimeout(() => {
          //     setShowSubCateg(false);
          //   }, 150);
          // }}

          theme={{
            ...theme,
            show: showSubCateg
          }}
        >
          <S.SubCategContainer>
            {subCateg !== undefined
              ? subCateg.subCategory.map((sub) => {
                  const key = Object.keys(sub)[0];
                  return (
                    <S.SubCateg
                      onClick={() => {
                        const temp = subCateg;
                        setInputType("sub");
                        setCategory({
                          domain: temp.domain,
                          subCategory: temp.subCategory.map((s) => {
                            const k = Object.keys(s)[0];
                            if (k === key) {
                              s.checked = true;
                              return s;
                            }
                            return s;
                          }),
                          isSubCategory: true
                        });
                        setSelected(sub[key]);
                      }}
                    >
                      {sub[key]}
                    </S.SubCateg>
                  );
                })
              : undefined}
          </S.SubCategContainer>
        </S.Sub>
      </S.Container>
    </>
  );
};
