import React, { useState, useEffect } from "react";
import {
  TextInput,
  ToastNotification,
  InlineLoading,
  Modal,
} from "carbon-components-react";
import S from "./changePassword.module.scss";
import validate from "./validator";
import useForm from "./../../../../../hooks/useForm";
import { CustomTooltip } from "./Tooltip";
import { shouldAutoComplete } from "../../../../../utils";

export const ChangePasswordWrapper = ({
  user,
  clearUser,
  setShowSuccessToast,
  showSuccessToast,
  open,
  setOpen,
  channel
}) => {
  const [loading, setLoading] = useState(false);

  const [passwordType, setPasswordType] = useState("password");
  const [confirmType, setConfirmType] = useState("password");
  const [newType, setNewType] = useState("password");

  const [wrongPasswordToast, setWrongPasswordToast] = useState(false);
  const [showTool, setShowTool] = useState(false);
  const { values, setValues, errors, isDisabled, setIsDisabled, handleChange } =
    useForm(validate);

  useEffect(() => {
    setValues({
      currentPassword: "",
      password: "",
      confirmPassword: "",
    });
    setIsDisabled({
      confirmPassword: true,
    });
  }, [setValues, setIsDisabled]);

  const togglePasswordVisibility = () => {
    setPasswordType(passwordType === "password" ? "text" : "password");
  };

  const toggleConfirmPasswordVisibility = () => {
    setConfirmType(confirmType === "password" ? "text" : "password");
  };

  const toggleNewPasswordVisibility = () => {
    setNewType(newType === "password" ? "text" : "password");
  };

  const toast = (set) => {
    set(true);
    setTimeout(() => {
      set(false);
    }, 3000);
  };

  const handleClose = () => {
    setOpen(!open);
    setValues({
      currentPassword: "",
      password: "",
      confirmPassword: "",
    });
  };

  const broadcastPasswordChange = () => {
    localStorage.setItem("passwordChange", JSON.stringify(true));
  };

  const logOutUser = () => {
    window.location.href = "/login";
    clearUser();
    channel?.postMessage({ key: "logout" }); // Notify other tabs
    sessionStorage.removeItem("penn-provider");
    localStorage.clear();
  };

  const handleSubmit = async () => {
    if (!errors.confirmPassword) {
      setLoading(true);
      const response = await fetch(
        `${process.env.REACT_APP_API_ENDPOINT}/iam/change-credentials`,
        {
          method: "put",
          credentials: "include",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${user.accessToken} ${user.idToken}`,
          },
          body: JSON.stringify({
            username: user.username,
            password: values.password,
            currentPassword: values.currentPassword,
            firstLogin: false,
          }),
        }
      );
      if (response.status === 200) {
        handleClose();
        toast(setShowSuccessToast);
        setTimeout(() => {
          broadcastPasswordChange();
          logOutUser();
        }, 2800);
      } else {
        toast(setWrongPasswordToast);
      }
      setLoading(false);
    }
  };

  return (
    <>
      <Modal
        className={S.modal}
        size="sm"
        open={open}
        primaryButtonText={
          loading ? <InlineLoading description="Submitting..." /> : "Submit"
        }
        primaryButtonDisabled={
          values.currentPassword === "" || isDisabled.confirmPassword || loading
        }
        secondaryButtonText="Cancel"
        onRequestClose={handleClose}
        onRequestSubmit={() => handleSubmit()}
        shouldCloseAfterSubmit={showSuccessToast}
        modalHeading="Change Password"
      >
        {wrongPasswordToast && (
          <ToastNotification
            title="Error Updating!"
            caption="Either your old password is incorrect or your new password does not meet requirements. Please try again."
            style={{ marginBottom: ".5rem", width: "auto" }}
          />
        )}
        <div className={S.textDiv}>
          <p>
            Your new password must be completely different from any recently
            used password.
          </p>
          <br />
          <TextInput.ControlledPasswordInput
            type={passwordType}
            togglePasswordVisibility={togglePasswordVisibility}
            id="currentPassword"
            labelText="Current Password"
            value={values.currentPassword || ""}
            onChange={handleChange}
            autoComplete={shouldAutoComplete}
          />
          <br />
          <TextInput.ControlledPasswordInput
            type={newType}
            togglePasswordVisibility={toggleNewPasswordVisibility}
            id="password"
            labelText="New Password"
            value={values.password || ""}
            onChange={handleChange}
            onClick={() => setShowTool(true)}
            onFocus={() => setShowTool(!showTool)}
            onBlur={() => setShowTool(!showTool)}
            autoComplete={shouldAutoComplete}
          />
          <div style={{ marginLeft: "15rem" }}>
            <CustomTooltip
              errors={errors.password || []}
              open={showTool}
              showIcon={false}
            />
          </div>
          <br />
          <TextInput.ControlledPasswordInput
            type={confirmType}
            togglePasswordVisibility={toggleConfirmPasswordVisibility}
            id="confirmPassword"
            labelText="Confirm Password"
            value={values.confirmPassword || ""}
            onChange={handleChange}
            invalid={!!errors.confirmPassword}
            invalidText={errors.confirmPassword}
            autoComplete={shouldAutoComplete}
          />
        </div>
      </Modal>
    </>
  );
};
