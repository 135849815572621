import {
  Button,
  ComboBox,
  DatePicker,
  DatePickerInput,
  MultiSelect,
  Search,
} from "carbon-components-react";
import S from "./filters.module.scss";
import { CSVLink } from "react-csv";
import { Download16 } from "@carbon/icons-react";
import { BookmarkFilled16 } from "@carbon/icons-react";
import { Bookmark16 } from "@carbon/icons-react";
import {
  rParentStatuses,
  searchFor,
  updateDate,
} from "../../helpers/filter-helpers";
import { useEffect, useState } from "react";
import styled from "styled-components";
import { shouldAutoComplete } from "../../../../utils";

const DashboardFilters = ({
  user,
  allServices,
  setSelectServices,
  displayedItems,
  setDisplayedItems,
  referrals,
  selectServices,
  assignStatuses,
  setAssignStatuses,
  referStatuses,
  filterBookmarked,
  setFilterBookmarked,
  astatuses,
  rstatuses,
  downloadHeaders,
  displayedItemsDownload,
  width,
  filterOnly,
  parentRefers,
  setParentRefers,
  dateStart,
  setDateStart,
  dateEnd,
  setDateEnd,
  paginationSetCurrentPage,
  setPageMin,
  setPageMax,
  paginationItemsPerPage,
  tab
}) => {
  const selectionRange = {
    startDate: new Date(),
    endDate: new Date(),
    key: "selection",
  };
  const [dateSelection, setDateSelection] = useState("");

  const handleAgeColor = (color) => {
    const today = new Date();
    switch (color) {
      case "Yellow (5-9 days old)":
        let fiveDaysAgo = new Date();
        fiveDaysAgo.setDate(today.getDate() - 5);
        let nineDaysAgo = new Date();
        nineDaysAgo.setDate(today.getDate() - 9);
        setDateEnd(fiveDaysAgo);
        setDateStart(nineDaysAgo);
        updateDate(
          nineDaysAgo,
          fiveDaysAgo,
          setDisplayedItems,
          referrals,
          selectServices,
          assignStatuses,
          referStatuses,
          filterBookmarked,
          parentRefers
        );
        break;
      case "Red (10 or more days old)":
        let tenDaysAgo = new Date();
        tenDaysAgo.setDate(today.getDate() - 10);
        setDateEnd(tenDaysAgo);
        setDateStart("");
        updateDate(
          "",
          tenDaysAgo,
          setDisplayedItems,
          referrals,
          selectServices,
          assignStatuses,
          referStatuses,
          filterBookmarked,
          parentRefers
        );
        break;
      case null:
      default:
        setDateEnd("");
        setDateStart("");
        updateDate(
          "",
          "",
          setDisplayedItems,
          referrals,
          selectServices,
          assignStatuses,
          referStatuses,
          filterBookmarked,
          parentRefers
        );
        break;
    }
  };

  const getFileName = () => {
    if (tab === 'My Services') {
      return `referrals-received-${new Date().toISOString().split('T')[0]}.csv`;
    } else if (tab === 'Outbound') {
      return `referrals-sent-${new Date().toISOString().split('T')[0]}.csv`;
    } else {
      return `my-organization-${new Date().toISOString().split('T')[0]}.csv`;
    }
  };

  return (
    <div className={S.filterWrapper}>
      <div className={S.searchWrapper}>
        <Search
          onChange={(e) => {
            searchFor(
              e.target.value,
              setDisplayedItems,
              referrals,
              selectServices,
              assignStatuses,
              referStatuses,
              filterBookmarked,
              parentRefers
            );
          }}
          placeholder="Search for referral information"
          style={{ backgroundColor: "#f4f4f4" }}
          labelText=""
        />
      </div>
      {/* <Flex> */}
      <div className={S.filterButtonWrapper}>
        <StyledComboBox
          id="default"
          aria-label="Date created"
          placeholder="Date created"
          items={[
            "Yellow (5-9 days old)",
            "Red (10 or more days old)",
            "Custom date range",
          ]}
          onChange={(e) => {
            handleAgeColor(e.selectedItem);
            setDateSelection(e.selectedItem);
          }}
        />
      </div>
      {dateSelection === "Custom date range" && (
        <div className={S.filterButtonWrapper}>
          <DatePicker
            datePickerType="range"
            onChange={(e) => {
              e[0] &&
                setDateStart(
                  e[0]
                );
              e[1] &&
                setDateEnd(
                  e[1]
                );
              let start = dateStart;
              let month;
              if (e[0]) {
                start = e[0];
              }
              let end = dateEnd;
              let endMonth;
              if (e[1]) {
                endMonth = e[1].getMonth() + 1;
                end =
                  e[1];
              }
              updateDate(
                start,
                end,
                setDisplayedItems,
                referrals,
                selectServices,
                assignStatuses,
                referStatuses,
                filterBookmarked,
                parentRefers
              );
              setPageMin(0);
              setPageMax(paginationItemsPerPage);
              paginationSetCurrentPage(0);
            }}
            className={S.rangePicker}
          >
            <DatePickerInput
              id="date-picker-input-id-start"
              placeholder="mm/dd/yyyy"
              aria-label="Start date"
              size="md"
              style={{ height: "3rem" }}
              autoComplete={shouldAutoComplete}
            />
            <DatePickerInput
              id="date-picker-input-id-finish"
              placeholder="mm/dd/yyyy"
              aria-label="End date"
              size="md"
              style={{ height: "3rem" }}
              autoComplete={shouldAutoComplete}
            />
          </DatePicker>
        </div>
      )}
      <div className={S.filterButtonWrapper}>
        <StyledMultiSelectService
          id="Service-multi-select-1"
          className={S.styledMultiSelectService}
          items={allServices}
          itemToString={(item) => (item ? item.name : "")}
          label="Service"
          onChange={(e) => {
            setSelectServices(e.selectedItems);
            updateDate(
              dateStart,
              dateEnd,
              setDisplayedItems,
              referrals,
              e.selectedItems,
              assignStatuses,
              referStatuses,
              filterBookmarked,
              parentRefers
            );
          }}
        />
      </div>
      <div className={S.filterButtonWrapper}>
        <StyledMultiSelectService
          id="Service-multi-select-2"
          className={S.styledMultiSelectService}
          items={astatuses}
          itemToString={(item) => (item ? item.label : "")}
          label="Assignment"
          onChange={(e) => {
            setAssignStatuses(e.selectedItems);
            updateDate(
              dateStart,
              dateEnd,
              setDisplayedItems,
              referrals,
              selectServices,
              e.selectedItems,
              referStatuses,
              filterBookmarked,
              parentRefers
            );
          }}
        />
      </div>
      <div className={S.filterButtonWrapper}>
        <StyledComboBox
          id="default"
          aria-label="Status"
          placeholder="Status"
          items={rParentStatuses}
          itemToString={(item) => (item ? item.label : "")}
          initialSelectedItem={parentRefers}
          onChange={(e) => {
            setParentRefers(e.selectedItem);
            updateDate(
              dateStart,
              dateEnd,
              setDisplayedItems,
              referrals,
              selectServices,
              assignStatuses,
              referStatuses,
              filterBookmarked,
              e.selectedItem
            );
          }}
        />
      </div>
      {!user.isViewOnly && (
        <div className={S.filterButtonWrapper}>
          {width < filterOnly ? (
            <Button
              className={S.bookmarkButton}
              kind="secondary"
              onClick={(e) => {
                let bookmarks = !filterBookmarked;
                setFilterBookmarked(!filterBookmarked);
                updateDate(
                  dateStart,
                  dateEnd,
                  setDisplayedItems,
                  referrals,
                  selectServices,
                  assignStatuses,
                  referStatuses,
                  bookmarks,
                  parentRefers
                );
              }}
            >
              {filterBookmarked ? <BookmarkFilled16 /> : <Bookmark16 />}
            </Button>
          ) : (
            <Button
              className={S.bookmarkButton}
              kind="secondary"
              onClick={(e) => {
                let bookmarks = !filterBookmarked;
                setFilterBookmarked(!filterBookmarked);
                updateDate(
                  dateStart,
                  dateEnd,
                  setDisplayedItems,
                  referrals,
                  selectServices,
                  assignStatuses,
                  referStatuses,
                  bookmarks,
                  parentRefers
                );
              }}
            >
              Bookmarks{" "}
              {filterBookmarked ? (
                <BookmarkFilled16 />
              ) : (
                <Bookmark16 style={{ marginLeft: "4px" }} />
              )}
            </Button>
          )}
        </div>
      )}
      <div className={S.filterButtonWrapper}>
        <Button
          onClick={() => {
            updateDate("", "", setDisplayedItems, referrals, [], [], [], false, []);
            document
              .querySelectorAll(".bx--tag__close-icon")
              .forEach((e) => e.click());
            document
              .querySelectorAll('[title="Clear selected item"]')
              .forEach((e) => e.click());
          }}
          className={S.bookmarkButton}
        >
          Clear
        </Button>
      </div>
      {/* </Flex> */}
      <div className={S.filterButtonWrapper}>
        <CSVLink
          data={displayedItemsDownload}
          headers={downloadHeaders}
          filename={getFileName()}
          label="none"
        >
          <Button className={S.downloadButton}>
            <Download16 />
          </Button>
        </CSVLink>
      </div>
    </div>
  );
};

const StyledMultiSelectService = styled(MultiSelect)`
  width: 9rem;
  min-height: 3rem;
  .bx--list-box__menu {
    min-width: 100%;
    width: fit-content;
    max-width: 30rem;
    z-index: 10000;
  }
  @media screen and (max-width: 767px){
    width: 260px;
  }
`;

const StyledComboBox = styled(ComboBox)`
  width: 10rem;
  min-height: 3rem;
  z-index: 999;
  margin-top: 0.87rem;
  .bx--list-box__menu {
    // min-width: 110%;
    width: fit-content;
    z-index: 10000;
  }
  @media screen and (max-width: 767px){
    width: 260px;
  }
`;

export default DashboardFilters;
