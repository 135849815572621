import { useState } from "react";
import styled from "styled-components";
import { Button, TextInput } from "carbon-components-react";
import * as S from "./Questions.styles";

const Question1 = () => {
  const [response, setResponse] = useState("");
  const [notReceivedService, setNotReceivedService] = useState(false);

  const [buttonSelection, setButtonSelection] = useState({
    0: false,
    1: false
  })
  return (
    <Wrapper>
      
      Nothing here
      
    </Wrapper>
  );
};

export default Question1;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  @media screen and (min-width: 1800px) {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
  @media screen and (max-width: 768px) {
    height: 60%;
    overflow-y: scroll;
  }
`;

const StyledButton = styled(Button)`
  color: ${(props) => (
    props.theme.bcolor ? "white" : "rgba(0,145,179,1)"
  )};
  background-color: ${(props) => (
    props.theme.bcolor ? "#0f62fe" : "white"
  )};
:hover {
  color: ${(props) => (
    props.theme.bcolor ? "rgba(0,145,179,1)" : "white"
  )};
  background-color: ${(props) => (
    props.theme.bcolor ? "white" : "rgba(0,145,179,1)"
  )};
}
:active {
  color: "white";
  background-color: "#0f62fe"
}
:focus {
  color: ${(props) => (
    props.theme.bcolor ? "white" : "rgba(0,145,179,1)"
  )};
  background-color: ${(props) => (
    props.theme.bcolor ? "#0f62fe" : "white"
  )};
}


@media screen and (max-width: 768px) {
  width: 5px;
  height: 4px;
}
`;
