import { useState, useEffect } from "react";
import * as S from "../Questions.styles";
import { Dropdown } from "carbon-components-react";
import styled from "styled-components";
// import { TextContainer } from "./Employment2";

const Transportation4 = ({
  cms,
  domain,
  width,
  setAllowContinue,
  transportationResponse,
  setTransportationResponse,
  setFeedbackIndex,
  setOverallIndex
}) => {
  const buttonLabels = [
    "Usually",
    "Sometimes",
    "Rarely",
    "Never",
    "I do not use public transportation"
  ];

  const getTranslatedLabel = (label) => {
    switch (label) {
      case "Usually":
        return cms.transportation.btn_usually;
      case "Sometimes":
        return cms.transportation.btn_sometimes;
      case "Rarely":
        return cms.transportation.btn_rarely;
      case "Never":
        return cms.transportation.btn_never;
      case "I do not use public transportation":
        return cms.transportation.btn_do_not;
      default:
        return "undefined";
    }
  };

  const [buttonSelection, setButtonSelection] = useState();

  useEffect(() => {
    if (transportationResponse.q4.answer !== " ") {
      setButtonSelection(transportationResponse.q4.answer);
      setAllowContinue(false);
    } else {
      setAllowContinue(true);
    }
  }, []);

  return (
    <S.TextContainer>
      {/* <h4>Question 10 of 12</h4> */}
      <h5>{cms.transportation.question_4}</h5>
      <S.Wrapper>
        {width < 769 ? (
          <S.DropdownWrapper>
            <S.StyledDropdown
              ariaLabel="Dropdown"
              id="carbon-dropdown-example"
              items={buttonLabels}
              label={buttonSelection || "Select option..."}
              onChange={(e) => {
                setButtonSelection(e.selectedItem);
                setTransportationResponse((state) => ({
                  ...state,
                  q4: {
                    text: "4 Does public transportation (e.g. RTS) meet your needs?",
                    answer: e.selectedItem
                  }
                }));
                setFeedbackIndex((index) => index + 1);
                setOverallIndex((index) => index + 1);
              }}
            />
          </S.DropdownWrapper>
        ) : (
          <S.ButtonGroup>
            {buttonLabels.map((label, i) => {
              let bcolor;
              {
                buttonSelection === label ? (bcolor = true) : (bcolor = false);
              }
              return (
                <S.StyledButton
                  key={i}
                  theme={{ bcolor: bcolor, width: "115px", margin: "1.5rem" }}
                  kind="tertiary"
                  onClick={() => {
                    setTransportationResponse((state) => ({
                      ...state,
                      q4: {
                        text: "4 Does public transportation (e.g. RTS) meet your needs?",
                        answer: label
                      }
                    }));
                    setButtonSelection(label);
                    setFeedbackIndex((index) => index + 1);
                    setOverallIndex((index) => index + 1);
                  }}
                >
                  {getTranslatedLabel(label)}
                </S.StyledButton>
              );
            })}
          </S.ButtonGroup>
        )}
      </S.Wrapper>
    </S.TextContainer>
  );
};

export default Transportation4;
