import styled from "styled-components";
import {
  Button,
  TableContainer,
  TableRow,
  OverflowMenu,
  TableHeader,
  TableHead,
  Dropdown,
  MultiSelect,
} from "carbon-components-react";
import {
  Flag16,
  Edit16,
  Add16,
  AddAlt16,
  CircleFilled16,
  WarningAlt16,
  WarningAltFilled16,
} from "@carbon/icons-react";

// General
export const PageContainer = styled.div`
  position: relative;
  z-index: 2;
  margin: 1rem 0.5rem;
  @media screen and (min-width: 500px) {
    margin: 1rem 1.5rem;
  }
  @media screen and (min-width: 900px) {
    margin: 1.5rem 2rem;
  }
`;

export const CardDiv = styled.div`
  min-height: 100vh;
  background-color: white;
  padding: 0.5rem;
  border-radius: 2px; /* 2px rounded corners */
  @media screen and (min-width: 900px) {
    padding: 1rem;
  }
`;

export const Flex = styled.div`
  display: flex;
`;

export const FlexCenter = styled.div`
  display: flex;
  justify-content: center;
`;

// Table
export const StyledTableContainer = styled(TableContainer)`
  .bx--data-table {
    th:nth-child(8),
    th:nth-child(9) {
      display: none;
    }
    
  }
  .bx--data-table tbody {
      background-color: #f4f4f4;
  }
  .bx--data-table tbody tr {
    background-color: #f4f4f4;
    :hover {
      background-color: #f4f4f4;
    }
  }
  ${(props) => !props.loading && `
    .bx--data-table-content {
      min-height: 200px;
      @media screen and (min-width: 900px) {
        overflow: hidden
      }
    }
  `}
`;

export const StyledTableRow = styled(TableRow)`
  color: #161616;
  td {
    background-color: ${(props) => props.theme.bcolor} !important;
    color: #161616;
  }
  @media screen and (max-width: 900px) {
    font-size: 11px;
  }
`;

export const StyledTableHead = styled(TableHead)`
  th {
    display: table-cell !important;
  }
`;

export const SearchWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-self: flex-start;
  margin-top: 0.87rem;
  width: 20rem;
  @media screen and (min-width: 500px) {
    margin-right: 0.5rem;
  }
  @media screen and (min-width: 900px) {
    margin-right: 1rem;
  }
  @media screen and (min-width: 1024px) {
    margin-right: 1rem;
  }
`;

export const FilterWrapper = styled.div`
  display: flex;
  align-self: flex-start;
  flex-wrap: wrap;
`;

export const DownloadWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-self: flex-start;
`;

export const FilterButtonWrapper = styled.div`
  align-self: flex-start;
  @media screen and (min-width: 500px) {
    margin-right: 0.5rem;
  }
  @media screen and (min-width: 900px) {
    margin-right: 1rem;
  }
  @media screen and (min-width: 1024px) {
    margin-right: 1rem;
  }
`;

export const FilterButton = styled(Button)`
  background-color: lightgrey !important;
  color: black !important;
  min-height: auto;
  padding: 1rem;
  height: 3rem;
  &:hover {
    background-color: red !important;
  }
`;

export const BookmarkButton = styled(Button)`
  background-color: lightgrey !important;
  color: black !important;
  min-height: auto;
  padding: 1rem;
  height: 3rem;
  margin-top: 0.87rem;
  &:hover {
    background-color: #8eba71 !important;
  }
`;

export const DownloadButton = styled(Button)`
  min-height: auto;
  padding: 1rem;
  height: 3rem;
  margin-top: 0.87rem;
  &:hover {
    background-color: red !important;
  }
`;

export const StyledDropdown = styled(Dropdown)`
  width: 10rem;
  min-height: 3rem;
  z-index: 999;
  margin-top: 0.87rem;
  .bx--list-box__menu {
    min-width: 100%;
    width: fit-content;
    z-index: 10000;
  }
  @media screen and (max-width: 767px){
    width: 260px;
  }
`;

export const ToolbarInner = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  // justify-content: space-between;
  @media screen and (min-width: 900px) {
    flex-direction: row;
  }
`;

export const TableSubLabel = styled.span`
  display: flex;
  font-size: 12px;
  cursor: pointer;
  @media screen and (max-width: 900px) {
    font-size: 10px;
  }
`;

export const StyledFlag = styled(Flag16)`
  width: 12px;
  margin-left: 4px;
`;

export const StyledEdit = styled(Edit16)`
  width: 12px;
  padding: 0px;
  // margin-left: 5px;
  // margin-right: 5px;
  margin-top: 0px;
  fill: #525252;
  @media screen and (max-width: 900px) {
    width: 10px;
  }
`;

export const StyledAdd = styled(AddAlt16)`
  // width: 12px;
  // margin-left: 4px;
  position: relative;
  // top: 2px;
  fill: #525252;
  margin-left: 0px;
  @media screen and (max-width: 900px) {
    height: 1rem;
  }
`;

export const StyledCircle = styled(CircleFilled16)`
  position: relative;
  fill: ${(props) => props.theme.color};
  margin-left: ${(props) => {
    if (props.theme.color === "purple") {
      return "38px";
    } else if (props.theme.color === "blue") {
      return "51px";
    } else {
      return "35px";
    }
  }};
  @media screen and (max-width: 900px) {
    height: 1rem;
    margin-left: ${(props) => {
      if (props.theme.color === "purple") {
        return "38px";
      } else if (props.theme.color === "blue") {
        return "51px";
      } else {
        return "35px";
      }
    }};
  }
`;

export const StyledOverflowMenu = styled(OverflowMenu)`
  height: 1rem;
  width: 1.5rem;
  @media screen and (max-width: 900px) {
    width: 1rem;
    height: 1rem;
  }
`;

export const StyledWarningAltFilled = styled(WarningAltFilled16)`
  color: red;
`;

export const StyledMultiSelectService = styled(MultiSelect)`
  width: 10rem;
  min-height: 3rem;
  .bx--list-box__menu {
    min-width: 100%;
    width: fit-content;
    z-index: 10000;
  }
  @media screen and (max-width: 767px){
    width: 260px;
  }
  .bx--multi-select .bx--list-box__menu {
    min-width: 120%;
  }
`;