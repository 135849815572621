import { useState } from "react";
import { Flex } from "../../index.styled";
import { Filter20, FilterRemove20 } from "@carbon/icons-react";
import { CardContainer, CardTitle, CardValue, FlatCard } from "../overview-cards";
import { getCompletedReferrals, updateDate } from '../../helpers/filter-helpers';
import { Tooltip } from "carbon-components-react";

const OverviewCardsOutbound = ({
  referrals,
  outboundItems,
  setOutboundItems,
  myAssignments,
  unassigned,
  averageAge,
  completed,
  mockData,
  totalReferrals,
  dateStart,
  dateEnd,
  selectServices,
  assignStatuses,
  referStatuses,
  filterBookmarked,
  parentRefers
}) => {
  const originalData = referrals;
  const [myAssignmentsActive, setMyAssignmentsActive] = useState(false);
  const [completedActive, setCompletedActive] = useState(false);
  const [unassignedActive, setUnassignedActive] = useState(false);
  const [openCompletedTip, setOpenCompletedTip] = useState(false);
  const [openAvgDaysTip, setOpenAvgDaysTip] = useState(false);

  // Filtering Functions
  const displayCompleted = () => {
    if (completedActive) {
      updateDate(
        dateStart,
        dateEnd,
        setOutboundItems,
        originalData,
        selectServices,
        assignStatuses,
        referStatuses,
        filterBookmarked,
        parentRefers
      );
    } else {
      let filtered;
      filtered = getCompletedReferrals(originalData);
      updateDate(
        dateStart,
        dateEnd,
        setOutboundItems,
        filtered,
        selectServices,
        assignStatuses,
        referStatuses,
        filterBookmarked,
        parentRefers
      );
      setMyAssignmentsActive(false);
      setUnassignedActive(false);
    }
  };
  const displayUnassigned = () => {
    if (unassignedActive) {
      updateDate(
        dateStart,
        dateEnd,
        setOutboundItems,
        originalData,
        selectServices,
        assignStatuses,
        referStatuses,
        filterBookmarked,
        parentRefers
      );
    } else {
      let filtered;
      filtered = originalData.filter((item) => {
        return item && !item.assignedTo;
      });
      updateDate(
        dateStart,
        dateEnd,
        setOutboundItems,
        filtered,
        selectServices,
        assignStatuses,
        referStatuses,
        filterBookmarked,
        parentRefers
      );
      setMyAssignmentsActive(false);
      setCompletedActive(false);
    }
  };

  return (
    <>
      <CardContainer>
        {/* {!user.isSuper && !user.is211Admin && (
          <FlatCard
            style={{ backgroundColor: "white", color: "white"}}
          >
            <CardTitle>My Assignments</CardTitle>
            <Flex
              style={{
                alignItems: "baseline",
                justifyContent: "space-between"
              }}
            >
              <CardValue>{myAssignments || 0}</CardValue>
              {myAssignmentsActive ? <FilterRemove20 /> : <Filter20 />}
            </Flex>
          </FlatCard>
        )} */}
        <FlatCard>
          <CardTitle>Total Sent</CardTitle>
          <CardValue>{totalReferrals || 0}</CardValue>
        </FlatCard>
        <FlatCard
          onClick={(e) => {
            e.preventDefault();
            displayCompleted();
            setCompletedActive(completedActive ? false : true);
          }}
        >
          <div style={{display: 'flex'}}>
          <CardTitle>Total Completed</CardTitle>
          <div
              onMouseEnter={() => setOpenCompletedTip(true)}
              onMouseLeave={() => setOpenCompletedTip(false)}
            >
              <Tooltip open={openCompletedTip} direction="right">
                A completed referral is a referral that has been closed by a provider.
                <br /> <br />
                Completed referrals statuses are Canceled by Client,
                Closed, Expired, or Provider Cannot Accept.
              </Tooltip>
            </div>
            </div>
          <Flex
            style={{ alignItems: "baseline", justifyContent: "space-between" }}
          >
            <CardValue>{completed || 0}</CardValue>
            {completedActive ? <FilterRemove20 /> : <Filter20 />}
          </Flex>
        </FlatCard>
          <FlatCard
          onClick={(e) => {
            e.preventDefault();
            displayUnassigned();
            setUnassignedActive(unassignedActive ? false : true);
          }}
          >
          <CardTitle>Unassigned</CardTitle>
          <Flex
            style={{ alignItems: "baseline", justifyContent: "space-between" }}
          >
            <CardValue>{unassigned || 0}</CardValue>
            {unassignedActive ? <FilterRemove20 /> : <Filter20 />}
          </Flex>
        </FlatCard>
        <FlatCard>
          <div style={{display: 'flex'}}>
          <CardTitle>Avg. Days to Completion</CardTitle>
          <div
                onMouseEnter={() => setOpenAvgDaysTip(true)}
                onMouseLeave={() => setOpenAvgDaysTip(false)}
              >
                <Tooltip open={openAvgDaysTip} direction="right">
                 Avg. days to completion calculates the average number of days between the start date and close date for all completed referrals.
                </Tooltip>
              </div>
            </div>
          <Flex style={{ alignItems: "baseline" }}>
            <CardValue>{averageAge || 0}</CardValue>
            <span>days</span>
          </Flex>
        </FlatCard>
      </CardContainer>
    </>
  );
};

export default OverviewCardsOutbound;
