import { SkeletonText } from "carbon-components-react";

const BadgeTracker = (props) => {
    const { bgColor, completed, questionsNum, style, isLoading } = props;

    const containerStyles = {
        backgroundColor: "#e0e0de",
        borderRadius: 50,
        maxWidth: "339px",
        minWidth: "300px",
        marginBottom: "2rem"
    };
    
    const fillerStyles = {
        height: '100%',
        width: `${completed}%`,
        backgroundColor: bgColor,
        borderRadius: 'inherit',
        textAlign: 'right',
        padding: '0.1rem',
        fontSize: '12px'
    };

    const fillerStylesNotPercent = {
        height: '100%',
        width: `${(completed / questionsNum) * 100}%`,
        backgroundColor: bgColor,
        borderRadius: 'inherit',
        textAlign: 'right'
    }
    
    const labelStyles = {
        padding: 5,
        color: 'white',
        fontWeight: 'bold'
    };

    return (
        <>
            {!isLoading ? (
                <div style={style}>
                    <div style={containerStyles}>
                        <div style={questionsNum ? fillerStylesNotPercent : fillerStyles }>
                            {questionsNum ?
                                <span style={labelStyles}>{`${completed}/${questionsNum}`}</span>
                                :
                                <span style={labelStyles}>{`${completed}%`}</span>
                            }
                        </div>
                    </div>
                </div>
            ):(
                <SkeletonText style={{width: "342px", height: "14px", marginBottom: "33.5px"}} />
            )}
        </>
    )
}

export default BadgeTracker;