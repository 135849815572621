import {
  Button,
  Column,
  ComposedModal,
  DataTable,
  FileUploaderButton,
  FileUploaderItem,
  InlineLoading,
  Modal,
  ModalFooter,
  ModalHeader,
  ProgressIndicator,
  ProgressStep,
  SkeletonText,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableHeader,
  TableRow,
  TableSelectRow,
  TableToolbar,
  TableToolbarContent,
  TableToolbarSearch,
  TextInput,
  ToastNotification,
  Tooltip
} from 'carbon-components-react';
import Compressor from 'compressorjs';
import { default as kit } from 'ich-ui-kit';
import { default as React, useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { NavContext } from '../../../store/NavContext';
import QRCodeGen from './qr-code-gen';
import {
  AccountCard,
  AccountGrid,
  AccountInfo,
  AccountRow,
  AccountRowSmall,
  AccountSubtitle,
  AccountTitle,
  AccountTitleContainer,
  AccountTitleRow,
  Card,
  Container,
  Error,
  FlexBetweenInputs,
  ImageCardGrid,
  ImageCardSplitGrid,
  ImageContainer,
  ImageLoading,
  LeftColumn,
  LoadingTextWrapper,
  ProfileCard,
  QRCodeWrapper,
  RightColumnMain,
  StyledButtonRow,
  StyledCancelLink,
  StyledLink,
  StyledModalBody,
  StyledToastNotification,
  TitleRow
} from '../components';
import { ChangePasswordWrapper } from './../information/components/changepassword';
import {
  AssumeRoleButton,
  FlexHeader,
  QrCodeCounterText,
  QrCodeCounterWrapper
} from './subpage.styled';
import { validateUrl, capitalizeFirstLetterMultiple, shouldAutoComplete, isValidName } from '../../../utils';

const Profile = ({
  profile,
  setProfile,
  user,
  loading,
  updateUser,
  clearUser,
  channel
}) => {
  const [file, setFile] = useState();
  const [open, setOpen] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [edit, setEdit] = useState(false);
  const [tempImage, setTempImage] = useState();
  const [image, setImage] = useState(null);
  const [showSuccessToast, setShowSuccessToast] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [staff, setStaff] = useState([]);
  const [error, setError] = useState(false);
  const [fieldErrors, setFieldErrors] = useState({ phone: '', aliasName: '' });
  const [editLoading, setEditLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [values, setValues] = useState({
    phone: profile?.phone,
    givenName: profile?.givenName,
    familyName: profile?.familyName,
    pronouns: profile?.pronouns
  });
  const { apiService } = useContext(kit.ApiContext);
  const [isFileSelected, setIsFileSelected] = useState(false);
  const { setProviderImageChange } = useContext(NavContext);
  const [alias, setAlias] = useState({
    firstName: profile?.aliasName
      ? profile?.aliasName.split(' ')[0]
      : profile?.firstName,
    lastName: profile?.aliasName ? profile?.aliasName.split(' ')[1] : profile?.lastName
  });
  const [fromQrCode, setFromQrCode] = useState(0);

  useEffect(() => {
    setValues({
      phone: profile?.phone,
      firstName: profile?.firstName,
      lastName: profile?.lastName,
      pronouns: profile?.pronouns
    });
    setAlias({
      firstName: profile?.aliasName
        ? profile?.aliasName.split(' ')[0]
        : profile?.firstName,
      lastName: profile?.aliasName
        ? profile?.aliasName.split(' ').slice(1).join(' ')
        : profile?.lastName
    });
  }, [profile]);

  useEffect(() => {
    const getSignedUpCount = async () => {
      const signedUpFromQR = await apiService.get(
        `/account/created-accounts-from?userId=${user.id}`
      );
      if (signedUpFromQR.count) {
        setFromQrCode(signedUpFromQR.count);
      } else {
        setFromQrCode(0);
      }
    };

    if (!user.isSuper && !user.is211Admin && !user.superAssumedRole?.leadId) {
      getSignedUpCount();
    }
  }, []);

  useEffect(() => {
    const getProviderImage = async () => {
      const data = await apiService.get(
        `/profileimages?assumedId=${user.superAssumedRole?.leadId || ''}`
      );
      if (data.ok) {
        setImage(data.image);
      } else {
        console.log(data.message);
      }
    };
    getProviderImage();
  }, [apiService, user?.superAssumedRole?.leadId]);

  const validatePhone = () => {
    let valid = false;
    if (values.phone === '' || values.phone.length === 12) {
      setFieldErrors((state) => ({ ...state, phone: "" }));
      valid = true;
    } else {
      setFieldErrors((state) => ({ ...state, phone: 'Invalid phone number' }));
    }
    return valid;
  };

  const validateAliasName = () => {
    setAlias({firstName: alias.firstName?.trim(), lastName: alias.lastName?.trim()})
    let valid = false;
    const isValid =
    (alias.firstName === "" && alias.lastName === "") ||
    (alias.firstName !== "" && isValidName(alias.firstName) &&
    alias.lastName !==  "" && isValidName(alias.lastName))


    if (isValid) {
      setFieldErrors((state) => ({ ...state, aliasName: "" }));
      valid = true;
    } else {
      setFieldErrors((state) => ({ ...state, aliasName: "Invalid Name" }));
    }
    return valid;
  };

  const handlePhoneChange = (e) => {
    e.preventDefault();
    let x = e.target.value
      .replace(/\D/g, '')
      .match(/(\d{0,3})(\d{0,3})(\d{0,4})/);

    setValues((values) => ({
      ...values,
      [e.target.id]: (e.target.value = !x[2]
        ? x[1]
        : x[1] + '-' + x[2] + (x[3] ? '-' + x[3] : ''))
    }));
  };

  const handleNameChange = (e) => {
    e.preventDefault();

    setValues((values) => ({
      ...values,
      [e.target.id]: e.target.value
    }));
  };

  const handleAliasChange = (e) => {
    e.preventDefault();
    const cleanId = e.target.id.split('-')[1];
    if(e.target.value === ' ') {
      e.target.value = ''
    }
    setAlias((state) => ({
      ...state,
      [cleanId]: e.target.value
    }));
  };

  const handlePronounsChange = (e) => {
    e.preventDefault();

    setValues((values) => ({
      ...values,
      [e.target.id]: e.target.value
    }));
  };

  const handleRemoveFile = () => {
    setTempImage(null);
    setFile(null);
    setIsFileSelected(false);
  };

  const toast = (set) => {
    set(true);
    setTimeout(() => {
      set(false);
    }, 10000);
  };

  const handleImage = (event) => {
    setIsFileSelected(true);
    if (event.target?.files?.length) {
      const image = event.target.files[0];
      new Compressor(image, {
        quality: 0.2, // 0.6 can also be used, but its not recommended to go below.
        success: (res) => {
          const blobToFile = new File(
            [res],
            `${image.name}` || 'ProfilePicture.jpeg',
            { type: `image/${image.name.split('.')[1]}` || 'image/jpeg' }
          );
          const reader = new FileReader();
          reader.onloadend = function (e) {
            if (reader.result) {
              setFile(blobToFile);
              setTempImage(reader.result);
            }
          };
          reader.readAsDataURL(blobToFile);
          // setProfile({...profile, image: blobToFile});
        }
      });
    }
  };

  const handleRemoveImage = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_ENDPOINT}/profileimages?assumedId=${user.superAssumedRole?.leadId || ''}`,
        {
          method: 'delete',
          headers: {
            Authorization: `Bearer ${user.accessToken} ${user.idToken}`
          }
        }
      );
      if (response.status === 200) {
        setDeleteOpen(true);
        setImage('/blank.png');
        handleRemoveFile()
        setProviderImageChange({ eventType: 'remove' });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleImageChange = async (e) => {
    e.preventDefault();

    try {
      let formData = new FormData();
      formData.append("profile", file);
      const response = await fetch(
        `${process.env.REACT_APP_API_ENDPOINT}/profileimages?assumedId=${user.superAssumedRole?.leadId || ''}`,
        {
          method: 'post',
          headers: {
            Authorization: `Bearer ${user.accessToken} ${user.idToken}`,
          },
          body: formData
        }
      );

      if (response.status === 200) {
        setImage(tempImage);
        setOpen(false);
        setProviderImageChange({ eventType: 'add', img: tempImage });
      } else if (response.status === 400) {
        let data = await response.json();
        setErrorMessage(data.message);
        toast(setError);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleCancelImageChange = () => {
    setOpen(false);
    handleRemoveFile();
  };

  const handleSubmit = async () => {
    if (validatePhone() && await validateAliasName()) {
      setEditLoading(true);
      try {
        const data = await apiService.put(`/providerstaff/account`, {
          phone: values.phone,
          firstName: values.firstName || profile.firstName,
          lastName: values.lastName|| profile.lastName,
          alias:
            (alias.firstName?.trim() || '') +
            (alias.lastName?.trim() ? ' ' + alias.lastName : ''),
          userId: user.id,
          pronouns: values.pronouns
        });
        if (data.ok) {
          setProfile({
            ...profile,
            firstName: values.firstName,
            lastName: values.lastName,
            phone: values.phone,
            aliasName:
              (alias.firstName || '') +
              (alias.lastName ? ' ' + alias.lastName : ''),
            pronouns: values.pronouns
          });
          if (user.isSuper) {
            updateUser({
              firstName: values?.firstName || profile.firstName,
              name: values?.firstName + ' ' + values?.lastName,
              aliasName:
              (alias.firstName || '') +
              (alias.lastName ? ' ' + alias.lastName : '')
            });
          } else {
            updateUser({
              ...user,
              name:
                (alias.firstName || '') +
                (alias.lastName ? ' ' + alias.lastName : ''),
              aliasName: (alias.firstName || '') +
              (alias.lastName ? ' ' + alias.lastName : '')
            });
          }
          setEdit(!edit);
        }
      } catch (err) {
        console.log(err);
      }
      setEditLoading(false);
    }
  };

  const handleCancelEdit = () => {
    setEdit(!edit);
    setValues({
      phone: profile?.phone,
      firstName: profile?.firstName,
      lastName: profile?.lastName,
      pronouns: profile?.pronouns
    });
    setAlias({
      firstName: profile?.aliasName
        ? profile?.aliasName.split(' ')[0]
        : profile?.firstName,
      lastName: profile?.aliasName
        ? profile?.aliasName.split(' ').slice(1).join(' ')
        : profile?.lastName
    });
    setFieldErrors({ phone: '', aliasName: '' });
  };

  // Assume Role Functionality
  const [roleModalOpen, setRoleModalOpen] = useState(false);
  const [roleSuccess, setRoleSuccess] = useState(false);
  const [roleError, setRoleError] = useState(false);
  const [roleLoading, setRoleLoading] = useState(false);
  const [selectedOrg, setSelectedOrg] = useState('');
  const [selectedOrgName, setSelectedOrgName] = useState('');
  const [selectedRole, setSelectedRole] = useState('');
  const [selectedUser, setSelectedUser] = useState('');
  const [connectedProviders, setConnectedProviders] = useState([]);

  const headerDataOrg = [{ key: 'name', header: 'Organization' }];
  const headerDataUser = [
    { key: 'name', header: 'Name' },
    { key: 'role', header: 'Role' }
  ];

  const stopRoleSelection = async () => {
    // Create event history log
    await apiService.post(`/iam/assume-role`, {
      orgId: user.superAssumedRole.orgId,
      orgName: user.superAssumedRole.orgName,
      userId: user.superAssumedRole.leadId,
      role: user.superAssumedRole.role,
      action: 'logout'
    });
    const currentUser = await apiService.get(
      `/providerstaff/account?userId=${user.id}`
    );

    if (currentUser) {
      setProfile({
        ...profile,
        firstName: currentUser.firstName,
        lastName: currentUser.lastName,
        phone: currentUser.phone,
        aliasName: currentUser.aliasName,
        pronouns: currentUser.pronouns,
        email: currentUser.email
      });
    }
    updateUser({
      ...user,
      is211Admin: false,
      superAssumedRole: {},
      isSuper: true,
      isAdmin: false,
      isViewOnly: false,
      name: user.superAssumedRole.oldName
    });
  };

  const newRoleSelection = async () => {
    const clear = () => {
      setTimeout(() => {
        setRoleSuccess(false);
        setRoleError(false);
        setSelectedOrg('');
        setSelectedOrgName('');
        setSelectedUser('');
        setSelectedRole('');
        setRoleModalOpen(false);
        setCurrentIndex(0);
      }, 4000);
    };

    setRoleLoading(true);
    const orgId = selectedOrg;
    const orgName = selectedOrgName;

    // Create event history log
    await apiService.post(`/iam/assume-role`, {
      orgId: orgId,
      orgName: orgName,
      userId: selectedUser,
      role: selectedRole,
      action: 'login'
    });
    const assumedUser = await apiService.get(
      `/providerstaff/account?userId=${selectedUser}`
    );

    if (assumedUser) {
      setProfile({
        ...profile,
        firstName: assumedUser.firstName,
        lastName: assumedUser.lastName,
        phone: assumedUser.phone,
        aliasName: assumedUser.aliasName,
        pronouns: assumedUser.pronouns,
        email: assumedUser.email
      });
      updateUser({
        superAssumedRole: {
          orgId: orgId,
          orgName: orgName,
          leadId: assumedUser?.userId || '',
          leadName:
            assumedUser?.aliasName ||
            assumedUser?.firstName + ' ' + assumedUser.lastName,
          role: selectedRole,
          oldName: user.Id
        },
        isSuper: false,
        isAdmin: assumedUser?.role === 'admin',
        is211Admin: assumedUser?.role === '211_provider_admin',
        is211Provider: assumedUser?.role === '211_provider',
        isViewOnly: assumedUser?.role === 'view_only',
        name: assumedUser?.firstName + ' ' + assumedUser?.lastName
      });
      setRoleLoading(false);
      setRoleSuccess(true);
      clear();
    } else {
      setRoleLoading(false);
      setRoleError(true);
      clear();
    }
  };

  const fetchStaff = async () => {
    try {
      const data = await apiService.get(
        `/providerstaff?orgId=${selectedOrg}&assumedId=${
          user.superAssumedRole?.leadId || ''
        }&acceptedOnly=true`
      );
      if (data?.staff) {
        const formattedStaff = data.staff.map((s) => {
          const name = `${s.firstName} ${s.lastName}`
          return { ...s, name, id: s.userId, dbId: s.id }
          });
        setStaff(formattedStaff);
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    const getAllOrgs = async () => {
      const allOrgs = await apiService.get('/providers');
      if (allOrgs?.providers?.length > 0) {
        let formatted = [...allOrgs?.providers, ...connectedProviders];
        formatted.push({ id: 0, name: '211/LIFE LINE' })
        formatted = formatted.sort((a, b) =>
          a.name > b.name ? 1 : b.name > a.name ? -1 : 0
        );
        setConnectedProviders(formatted);
      }
    };

    (user.isSuper || user.superAssumedRole?.leadId) &&
      connectedProviders.length < 3 &&
      getAllOrgs();
  }, []);

  const handleRequestSubmit = (index) => {
    switch (index) {
      case 0:
        setCurrentIndex(index + 1);
        fetchStaff();
        return;
      case 1:
        newRoleSelection();
        return;
      case 2:
        setRoleModalOpen(false);
        return;
      default:
        return;
    }
  };

  const determinePrimaryButtonText = (index) => {
    if (index == 0) {
      return 'Next';
    } else {
      return 'Submit';
    }
  };

  const determineSecondaryButtonText = (index) => {
    if (index == 0) {
      return 'Cancel';
    } else {
      return 'Back';
    }
  };

  const close = () => {
    setCurrentIndex(0);
    setRoleModalOpen(false);
    setSelectedOrg('');
    setSelectedOrgName('');
    setSelectedUser('');
    setSelectedRole('');
  };

  const ProgressBar = ({ currentIndex }) => {
    return (
      <div style={{ marginBottom: '2rem', overflow: 'visible' }}>
        <ProgressIndicator currentIndex={currentIndex} spaceEqually={true}>
          <ProgressStepStyled
            label="Select Organization"
            secondaryLabel="Required"
            description="Step 1: Select Organization"
          />
          <ProgressStepStyled
            label="Select User"
            secondaryLabel="Required"
            description="Step 2: Select User"
          />
        </ProgressIndicator>
      </div>
    );
  };

  const renderContent = (index) => {
    switch (index) {
      case 0:
        return (
          <>
            <ProgressBar currentIndex={currentIndex} />
            <p style={{ fontSize: '1rem', paddingBottom: '1rem' }}>
              Select the role you would like to assume
            </p>
            <DataTable rows={connectedProviders} headers={headerDataOrg} radio>
              {({
                rows,
                headers,
                getHeaderProps,
                getTableProps,
                getTableContainerProps,
                getRowProps,
                getSelectionProps,
                onInputChange
              }) => (
                <>
                  <TableToolbar
                    style={{
                      borderBottom: '1px solid #999',
                      backgroundColor: '#fff',
                      marginBottom: '0.5rem'
                    }}
                  >
                    <TableToolbarContent>
                      <TableToolbarSearch
                        onChange={onInputChange}
                        placeholder="Search..."
                        persistent={true}
                      />
                    </TableToolbarContent>
                  </TableToolbar>
                  <TableContainer {...getTableContainerProps()}>
                    <Table {...getTableProps()}>
                      <TableHead>
                        <TableRow>
                          <th scope="col" />
                          {headers.map((header, i) => (
                            <TableHeader
                              key={i}
                              {...getHeaderProps({ header })}
                            >
                              {header.header}
                            </TableHeader>
                          ))}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {rows.map((row, i) => {
                          return (
                            <TableRow
                              key={i}
                              {...getRowProps({ row })}
                              onChange={() => {
                                if (row.id === 0) {
                                  setSelectedOrg('211_prov');
                                } else {
                                  setSelectedOrg(row.id);
                                }
                                setSelectedOrgName(row.cells[0].value || '211');
                              }}
                            >
                              <TableSelectRow {...getSelectionProps({ row })} />
                              {row.cells.map((cell) => (
                                <TableCell key={cell.id}>
                                  {cell.value}
                                </TableCell>
                              ))}
                            </TableRow>
                          );
                        })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </>
              )}
            </DataTable>
          </>
        );
      case 1:
        return (
          <>
            <ProgressBar currentIndex={currentIndex} />
            <p style={{ fontSize: '1rem', paddingBottom: '0.5rem' }}>
              <strong>Selected Organization: </strong>
              {selectedOrgName}
            </p>
            <p style={{ fontSize: '1rem', paddingBottom: '1rem' }}>
              Select the user you would like to assume
            </p>
            {roleSuccess ? (
              <ToastNotification
                title="Success"
                subtitle={`You have successfully assumed the ${
                  selectedOrgName || ''
                } role.`}
                kind="success"
                style={{ width: '100%' }}
              />
            ) : roleError ? (
              <ToastNotification
                title="Error"
                subtitle={`An error occured. This provider may not have a staff lead assigned. Please try again later.`}
                kind="error"
                style={{ width: '100%' }}
              />
            ) : (
              <DataTable rows={staff} headers={headerDataUser} radio>
                {({
                  rows,
                  headers,
                  getHeaderProps,
                  getTableProps,
                  getTableContainerProps,
                  getRowProps,
                  getSelectionProps,
                  onInputChange
                }) => (
                  <>
                    <TableToolbar
                      style={{
                        borderBottom: '1px solid #999',
                        backgroundColor: '#fff',
                        marginBottom: '0.5rem'
                      }}
                    >
                      <TableToolbarContent>
                        <TableToolbarSearch
                          onChange={onInputChange}
                          placeholder="Search..."
                          persistent={true}
                        />
                      </TableToolbarContent>
                    </TableToolbar>
                    <TableContainer {...getTableContainerProps()}>
                      <Table {...getTableProps()}>
                        <TableHead>
                          <TableRow>
                            <th scope="col" />
                            {headers.map((header, i) => (
                              <TableHeader
                                key={i + '-header'}
                                {...getHeaderProps({ header })}
                              >
                                {header.header}
                              </TableHeader>
                            ))}
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {rows.map((row, i) => (
                            <TableRow
                              key={`${i}-${row.id}`}
                              {...getRowProps({ row })}
                              onChange={() => {
                                setSelectedUser(row.id);
                                setSelectedRole(row.cells[1].value || 'Staff');
                              }}
                            >
                              <TableSelectRow {...getSelectionProps({ row })} />
                              {row.cells.map((cell) => (
                                <TableCell key={cell.id}>
                                  {capitalizeFirstLetterMultiple(cell.value) || 'Staff'}
                                </TableCell>
                              ))}
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </>
                )}
              </DataTable>
            )}
          </>
        );
      case 2:
        return <p>Failed to assume role</p>;
      default:
        return <p>Failed to assume role</p>;
    }
  };

  return (
    <>
      {showSuccessToast && (
        <StyledToastNotification
          kind="success"
          title="Success"
          caption="Password Changed Successfully!"
          style={{
            marginBottom: '.5rem',
            position: 'absolute',
            right: '0'
          }}
        />
      )}

      <FlexHeader>
        <TitleRow>Profile Information</TitleRow>
        {(user.is211Admin && user.isSuper) || user.superAssumedRole?.leadId ? (
          <AssumeRoleButton
            onClick={() => {
              stopRoleSelection();
            }}
            kind="danger"
          >
            Disable Role
          </AssumeRoleButton>
        ) : (
          user.isSuper && (
            <AssumeRoleButton onClick={() => setRoleModalOpen(true)}>
              Assume Role
            </AssumeRoleButton>
          )
        )}
        {user.isSuper && (
          <Modal
            open={roleModalOpen}
            onRequestClose={() => close()}
            onSecondarySubmit={() => {
              if (currentIndex === 0) {
                close();
              } else {
                setCurrentIndex(currentIndex - 1);
                setSelectedOrgName('');
                setSelectedOrg('');
              }
            }}
            modalHeading="Assume Role"
            modalLabel="Super User Roles"
            primaryButtonText={determinePrimaryButtonText(currentIndex)}
            secondaryButtonText={determineSecondaryButtonText(currentIndex)}
            onRequestSubmit={() => handleRequestSubmit(currentIndex)}
            primaryButtonDisabled={
              selectedOrg === '' || roleSuccess || roleLoading
            }
          >
            {renderContent(currentIndex)}
          </Modal>
        )}
      </FlexHeader>

      <AccountGrid>
        <ComposedModal open={open} size="sm" onClose={() => setOpen(false)}>
          <ModalHeader></ModalHeader>
          <StyledModalBody>
            <Column>
              <ImageContainer
                style={{
                  backgroundImage:
                    tempImage
                      ? `url(${tempImage})`
                      : `url("/blank.png")`,
                  width: '120px',
                  height: '120px'
                }}
              />
            </Column>
            <Column>
              <div style={{ marginBottom: '25px' }}>Upload Profile Image</div>
              <div style={{ marginBottom: '15px' }}>Maximum size: 3MB</div>
              <div style={{ marginBottom: '15px' }}>
                Format: jpg and png files
              </div>
              <FileUploaderButton
                name="profile"
                accept={['.jpg', '.png']}
                buttonKind="secondary"
                labelText="Upload"
                disableLabelChanges={true}
                onChange={handleImage}
              />
              {isFileSelected && (
                <FileUploaderItem
                  iconDescription="Clear file"
                  status={file?.name ? 'edit' : 'uploading'}
                  name={file?.name}
                  onDelete={handleRemoveFile}
                />
              )}
              {error && <Error>{errorMessage}</Error>}
            </Column>
          </StyledModalBody>
          <ModalFooter>
            <Button kind="cancel" onClick={handleCancelImageChange}>
              Cancel
            </Button>
            <Button kind="primary" onClick={handleImageChange}>
              Save
            </Button>
          </ModalFooter>
        </ComposedModal>
        <Modal
          open={deleteOpen}
          size="sm"
          onRequestClose={() => setDeleteOpen(false)}
          passiveModal
          modalHeading="You have deleted your profile image"
        />
        <LeftColumn>
          <Container>
            <AccountCard>
              <AccountTitleContainer>
                <AccountTitleRow>
                  <AccountTitle>Account user information</AccountTitle>
                </AccountTitleRow>
              </AccountTitleContainer>
              {loading ? (
                <LoadingTextWrapper>
                  <SkeletonText heading paragraph lineCount={4} />
                </LoadingTextWrapper>
              ) : (
                <>
                  <AccountRow>
                    <AccountSubtitle>Name</AccountSubtitle>
                  </AccountRow>
                  <AccountRowSmall>
                    <AccountInfo>
                      {profile.firstName + ' ' + profile.lastName}
                    </AccountInfo>
                  </AccountRowSmall>
                  <AccountRow>
                    <AccountSubtitle>Email</AccountSubtitle>
                  </AccountRow>
                  <AccountRowSmall>
                    <AccountInfo>{profile.email}</AccountInfo>
                  </AccountRowSmall>
                  <AccountRow>
                    <AccountSubtitle>
                      <Button
                        onClick={() => setModalOpen(!modalOpen)}
                        kind="primary"
                      >
                        Change Password
                      </Button>
                      <ChangePasswordWrapper
                        setShowSuccessToast={setShowSuccessToast}
                        showSuccessToast={showSuccessToast}
                        user={user}
                        open={modalOpen}
                        setOpen={setModalOpen}
                        clearUser={clearUser}
                        channel={channel}
                      />
                    </AccountSubtitle>
                  </AccountRow>
                </>
              )}
            </AccountCard>
          </Container>
          <Container>
            <AccountCard>
              <AccountTitleContainer>
                <AccountTitleRow
                  style={{ paddingBottom: '0.5rem', alignItems: 'center' }}
                >
                  <AccountTitle>Contact information</AccountTitle>
                  {edit ? (
                    <Button
                      kind="ghost"
                      aria-label="Cancel-edit-contact-information"
                      style={{ minHeight: 'auto', padding: '1px' }}
                      onClick={() => {
                        setEdit(!edit);
                      }}
                    >
                      <StyledCancelLink
                        aria-hidden="false"
                        aria-label="Cancel-edit-contact-information"
                      />
                    </Button>
                  ) : (
                    <Button
                      kind="ghost"
                      style={{ minHeight: 'auto', padding: '1px' }}
                      aria-label="Edit-contact-information"
                      onClick={() => {
                        setEdit(!edit);
                      }}
                    >
                      <StyledLink
                        aria-hidden="false"
                        aria-label="Edit-contact-information"
                      />
                    </Button>
                  )}
                </AccountTitleRow>
              </AccountTitleContainer>
              {loading ? (
                <LoadingTextWrapper>
                  <SkeletonText heading paragraph lineCount={3} />
                </LoadingTextWrapper>
              ) : (
                <>
                  <AccountRow>
                    <AccountSubtitle>Name</AccountSubtitle>
                  </AccountRow>
                  <AccountRowSmall>
                    <AccountInfo>
                      {edit && user.isSuper ? (
                        <FlexBetweenInputs>
                          <TextInput
                            id="firstName"
                            value={values?.firstName}
                            onChange={handleNameChange}
                            size="sm"
                            style={{ margin: 0 }}
                            autoComplete={shouldAutoComplete}
                          />
                          <TextInput
                            id="lastName"
                            value={values?.lastName}
                            onChange={handleNameChange}
                            size="sm"
                            style={{ margin: 0 }}
                            autoComplete={shouldAutoComplete}
                          />
                        </FlexBetweenInputs>
                      ) : (
                        profile?.firstName + ' ' + profile?.lastName
                      )}
                    </AccountInfo>
                  </AccountRowSmall>
                  {/* {user.is211Admin && ( */}
                  <>
                    <AccountRow>
                      <AccountSubtitle>Alias</AccountSubtitle>
                    </AccountRow>
                    <AccountRowSmall>
                      <AccountInfo>
                        {edit ? (
                          <FlexBetweenInputs>
                            <TextInput
                              id="alias-firstName"
                              value={alias?.firstName}
                              onChange={handleAliasChange}
                              invalid={!!fieldErrors.aliasName}
                              invalidText={fieldErrors.aliasName}
                              size="sm"
                              style={{ margin: 0 }}
                              autoComplete={shouldAutoComplete}
                            />
                            <TextInput
                              id="alias-lastName"
                              value={alias.lastName || ''}
                              onChange={handleAliasChange}
                              invalid={!!fieldErrors.aliasName}
                              size="sm"
                              style={{ margin: 0 }}
                              autoComplete={shouldAutoComplete}
                            />
                          </FlexBetweenInputs>
                        ) : (
                          (profile?.aliasName || "")
                        )}
                      </AccountInfo>
                    </AccountRowSmall>
                  </>
                  <>
                    <AccountRow>
                      <AccountSubtitle>Pronouns</AccountSubtitle>
                      <Tooltip direction="right" tabIndex={0}>
                        <a
                          style={{
                            color: 'white',
                            textDecoration: 'underline',
                            cursor: 'pointer'
                          }}
                          kind="secondary"
                          onClick={() =>(
                            window.open(validateUrl('https://www.mypronouns.org/what-and-why'))
                            )
                          }
                        >
                          Why use pronouns?
                        </a>
                      </Tooltip>
                    </AccountRow>
                    <AccountRowSmall>
                      <AccountInfo>
                        {edit ? (
                          <FlexBetweenInputs>
                            <TextInput
                              id="pronouns"
                              value={values?.pronouns}
                              onChange={handlePronounsChange}
                              size="sm"
                              style={{ margin: 0 }}
                              autoComplete={shouldAutoComplete}
                            />
                          </FlexBetweenInputs>
                        ) : (
                          values?.pronouns
                        )}
                      </AccountInfo>
                    </AccountRowSmall>
                  </>
                  {/* )} */}
                  <AccountRow>
                    <AccountSubtitle>Phone number</AccountSubtitle>
                    <Tooltip direction="bottom" tabIndex={0}>
                      <p>
                        Please add your phone number if you would like to
                        receive your Access Code via text. Your Phone Number
                        will not be shared with anyone.
                      </p>
                    </Tooltip>
                  </AccountRow>
                  <AccountRowSmall>
                    <AccountInfo>
                      {edit ? (
                        <TextInput
                          id="phone"
                          placeholder="xxx-xxx-xxxx"
                          invalid={!!fieldErrors.phone}
                          invalidText={fieldErrors.phone}
                          value={values.phone}
                          onChange={handlePhoneChange}
                          size="sm"
                          autoComplete={shouldAutoComplete}
                        />
                      ) : (
                        profile?.phone
                      )}
                    </AccountInfo>
                  </AccountRowSmall>
                  {edit && (
                    <StyledButtonRow>
                      <Button
                        kind="cancel"
                        onClick={handleCancelEdit}
                        style={{ width: 'auto' }}
                      >
                        Cancel
                      </Button>
                      <Button
                        disabled={editLoading}
                        kind="primary"
                        onClick={handleSubmit}
                        style={{ width: 'auto' }}
                      >
                        {editLoading ? (
                          <InlineLoading
                            description="Submitting..."
                            style={{ minHeight: 'auto' }}
                          />
                        ) : (
                          'Submit'
                        )}
                      </Button>
                    </StyledButtonRow>
                  )}
                </>
              )}
            </AccountCard>
          </Container>
        </LeftColumn>
        <RightColumnMain style={{ marginTop: '0' }}>
          <ProfileCard>
            <AccountTitleContainer>
              <AccountTitleRow>
                <AccountTitle>Profile photo</AccountTitle>
              </AccountTitleRow>
            </AccountTitleContainer>
            <ImageCardGrid>
              <div>
                {loading ? (
                  <ImageLoading />
                ) : (
                  <ImageContainer
                    style={{
                      backgroundImage:
                        image || profile.image
                          ? `url(${image || profile.image})`
                          : `url("/blank.png")`
                    }}
                  />
                )}
              </div>
              <ImageCardSplitGrid>
                <Button onClick={() => setOpen(true)} kind="primary">
                  Upload
                </Button>
                <Button kind="danger" size="field" onClick={handleRemoveImage}>
                  Delete
                </Button>
              </ImageCardSplitGrid>
            </ImageCardGrid>
          </ProfileCard>
          <Container>
            {!user.isSuper &&
              !user.is211Admin &&
              !user.superAssumedRole?.leadId && (
                <AccountCard style={{ textAlign: 'center' }}>
                  <AccountTitleContainer>
                    <AccountTitleRow>
                      <AccountTitle>QR Code</AccountTitle>
                    </AccountTitleRow>
                  </AccountTitleContainer>
                  <QrCodeCounterWrapper>
                    <span>Accounts Created From QR:</span>
                    <QrCodeCounterText>{fromQrCode}</QrCodeCounterText>
                  </QrCodeCounterWrapper>
                  {loading ? (
                    <QRCodeWrapper
                      style={{
                        marginTop: '0.25rem',
                        display: 'flex',
                        justifyContent: 'center'
                      }}
                    >
                      <ImageLoading
                        style={{ width: '140px', height: '140px' }}
                      />
                    </QRCodeWrapper>
                  ) : (
                    <QRCodeWrapper>
                      <QRCodeGen
                        orgId={profile.orgId}
                        displayDownloadLink={true}
                        createdFrom={profile.orgId + '-211'} // TODO - Make the '211' orgSource dynamic
                      />
                    </QRCodeWrapper>
                  )}
                </AccountCard>
              )}
          </Container>
        </RightColumnMain>
      </AccountGrid>
    </>
  );
};
export default Profile;
const ProgressStepStyled = styled(ProgressStep)`
  .bx--progress-label {
    overflow: visible;
  }
`;
