import { checkIfContains, getDisplayedStatus } from "../../../utils";

export const sortHeader = (
  header,
  setDisplayedItems,
  sortHeaderState,
  sortHeaderDirection,
  setSortHeaderDirection,
  setSortHeaderState,
  referrals,
  labels,
  selectServices,
  assignStatuses,
  referStatuses,
  filterBookmarked,
  parentRefers,
  dateStart,
  dateEnd
) => {
  const key = header.key;
  let newDirection;
  let items = referralFilterHelper(
    selectServices,
    assignStatuses,
    referStatuses,
    referrals,
    setDisplayedItems,
    filterBookmarked,
    parentRefers
  );
  items = calculateDate(dateStart, dateEnd, items);

  if (header.header === "Labels") {
    // eslint-disable-next-line array-callback-return
    items.map((item) => {
      if (item) {
        if (
          item.id &&
          labels &&
          labels.find((label) => label?.id === item.id)
        ) {
          let color = labels.find((label) => label.id === item.id).label;
          item.label = color;
        } else {
          item.label = "Z";
        }
      }
    });
  }

  if (sortHeaderState === header.header) {
    if (sortHeaderDirection === 1) {
      if (header.header === "Date") {
        items.sort((a, b) => {
          var c = new Date(a.authoredOn?.split("/").join(" "));
          var d = new Date(b.authoredOn?.split("/").join(" "));
          return c - d;
        });
      } else if (header.header === "Assignment") {
        items.sort((a, b) => {
          let c = "";
          let d = "";
          a[key] ? (c = "Assigned") : (c = "Unassigned");
          b[key] ? (d = "Assigned") : (d = "Unassigned");
          if (c > d) {
            return -1;
          } else {
            return 1;
          }
        });
      } else if (header.header === "Id") {
        items.sort((a, b) => (parseInt(a[key]) < parseInt(b[key]) ? 1 : -1));
      } else {
        items.sort((a, b) => (a[key] < b[key] ? 1 : -1));
      }
      newDirection = 2;
    } else if (sortHeaderDirection === 2) {
      newDirection = 0;
    } else if (sortHeaderDirection === 0) {
      if (header.header === "Date") {
        items.sort((a, b) => {
          var c = new Date(a.authoredOn?.split("/").join(" "));
          var d = new Date(b.authoredOn?.split("/").join(" "));
          return d - c;
        });
      } else if (header.header === "Assignment") {
        items.sort((a, b) => {
          let c = "";
          let d = "";
          a[key] ? (c = "Assigned") : (c = "Unassigned");
          b[key] ? (d = "Assigned") : (d = "Unassigned");
          if (c > d) {
            return 1;
          } else {
            return -1;
          }
        });
      } else if (header.header === "Id") {
        items.sort((a, b) => (parseInt(a[key]) < parseInt(b[key]) ? -1 : 1));
      } else {
        items.sort((a, b) => (a[key] < b[key] ? -1 : 1));
      }
      newDirection = 1;
    }
    setDisplayedItems(items);
    setSortHeaderDirection(newDirection);
  } else {
    setSortHeaderState(header.header);
    setSortHeaderDirection(1);
    if (header.header === "Date") {
      items.sort((a, b) => {
        var c = new Date(a.authoredOn?.split("/").join(" "));
        var d = new Date(b.authoredOn?.split("/").join(" "));
        return d - c;
      });
    } else if (header.header === "Assignment") {
      items.sort((a, b) => {
        let c = "";
        let d = "";
        a[key] ? (c = "Assigned") : (c = "Unassigned");
        b[key] ? (d = "Assigned") : (d = "Unassigned");
        if (c > d) {
          return 1;
        } else {
          return -1;
        }
      });
    } else {
      items.sort((a, b) => (a[key] > b[key] ? 1 : -1));
    }

    setDisplayedItems(items);
  }
};

export const searchFor = (
  e,
  setDisplayedItems,
  referrals,
  selectServices,
  assignStatuses,
  referStatuses,
  filterBookmarked,
  parentRefers
) => {
  let items = referralFilterHelper(
    selectServices,
    assignStatuses,
    referStatuses,
    referrals,
    setDisplayedItems,
    filterBookmarked,
    parentRefers
  );
  let searchItem = e?.toLowerCase().replace(/\s+/g, "") || "";
  items = items.filter((item) => {
    searchItem = searchItem.replace(/\s/g, "");
    const displayedStatus = getDisplayedStatus(
      item.status,
      item?.referree,
      false
    );
    return (
      item.id?.includes(searchItem) ||
      item.referree?.toLowerCase().replace(/\s+/g, "").includes(searchItem) ||
      displayedStatus?.toLowerCase().replace(/\s+/g, "").includes(searchItem) ||
      item.name?.toLowerCase().replace(/\s+/g, "").includes(searchItem) ||
      item.assignedTo?.toLowerCase().replace(/\s+/g, "").includes(searchItem) ||
      item.requester?.toLowerCase().replace(/\s+/g, "").includes(searchItem)
    );
  });
  setDisplayedItems(items);
};

export const getElapsed = (rowDate, currDate) => {
  const rowD = new Date(rowDate);
  let elapsed = currDate - rowD;
  elapsed /= 1000 * 60 * 60 * 24;
  return elapsed;
};

export const avgAge = (mockData) => {
  const closedOnes = getCompletedReferrals(mockData);
  const count = closedOnes.length;
  let total = 0;
  let elapsed = 0;

  closedOnes.map((item) => {
    if (item) {
      if (item.closeDate) {
        let endDate = item.closeDate?.split("-");
        endDate = new Date(endDate[1] + " " + endDate[2] + " " + endDate[0]);
        elapsed = getElapsed(item.authoredOn, endDate);
        total += elapsed;
      }
    }
  });
  return Math.round(total / count);
};

export const ageColor = ({ rowDate, rowStatus }) => {
  const currDate = new Date();
  const elapsed = getElapsed(rowDate?.split("/").join(" "), currDate);
  const open = rOpenStatuses.map((s) => s.id);

  if (open.includes(rowStatus.toLowerCase())) {
    if (elapsed < 5) {
      return "rgba(244,244,244,255)";
    } else if (elapsed < 10) {
      return "rgba(255, 245, 156, 0.5)";
    } else {
      return "rgba(255, 159, 156, 0.5)";
    }
  } else {
    return "rgba(244,244,244,255)";
  }
};

const calculateDate = (dateStart, dateEnd, items) => {
  if (dateStart && dateEnd) {
    items = items.filter((item) => {
      var a = new Date(item.authoredOn);
      var b = new Date(dateStart);
      var c = new Date(dateEnd);
      if (a >= b && a <= c) {
        return true;
      } else {
        return false;
      }
    });
  } else if (dateStart) {
    items = items.filter((item) => {
      var a = new Date(item?.authoredOn);
      var b = new Date(dateStart);
      if (a >= b) {
        return true;
      } else {
        return false;
      }
    });
  } else if (dateEnd) {
    items = items.filter((item) => {
      var a = new Date(item.authoredOn);
      var c = new Date(dateEnd);
      if (a <= c) {
        return true;
      } else {
        return false;
      }
    });
  }
  return items;
};

export const updateDate = (
  dateStart,
  dateEnd,
  setDisplayedItems,
  referrals,
  selectServices,
  assignStatuses,
  referStatuses,
  filterBookmarked,
  parentRefers
) => {
  let items = referralFilterHelper(
    selectServices,
    assignStatuses,
    referStatuses,
    referrals,
    setDisplayedItems,
    filterBookmarked,
    parentRefers
  );
  items = calculateDate(dateStart, dateEnd, items);
  items.sort((a, b) => {
    var c = new Date(a.authoredOn);
    var d = new Date(b.authoredOn);
    return d - c;
  });
  setDisplayedItems(items);
};

const referralFilterHelper = (
  selectServices,
  assignStatuses,
  referStatuses,
  referrals,
  setDisplayedItems,
  filterBookmarked,
  parentRefers
) => {
  const servs = selectServices.map((s) => s.name);
  const asses = assignStatuses.map((s) => s.label);
  const refers = referStatuses.map((s) => s.id);
  const open = rOpenStatuses.map((s) => s.id);
  const prefers = parentRefers ? parentRefers.id : "";
  let dItems = referrals;

  if (prefers) {
    if (prefers.includes("parent-closed")) {
      dItems = dItems.filter(
        (item) => !open.includes(item?.status?.toLowerCase())
      );
    } else {
      dItems = dItems.filter((item) =>
        open.includes(item?.status?.toLowerCase())
      );
    }
  }

  if (asses.length > 0) {
    if (asses.includes("Assigned") && !asses.includes("Unassigned")) {
      dItems = dItems.filter(
        (item) =>
          item &&
          item.assignedTo &&
          item.assignedTo !== "undefined" &&
          item.assignedTo !== "Undefined" &&
          item.assignedTo !== " " &&
          item.assignedTo !== ""
      );
    } else if (!asses.includes("Assigned") && asses.includes("Unassigned")) {
      dItems = dItems.filter(
        (item) =>
          item &&
          (!item.assignedTo ||
            item.assignedTo === "undefined" ||
            item.assignedTo === "Undefined" ||
            item.assignedTo === " " ||
            item.assignedTo === "")
      );
    }
  }

  if (servs.length > 0) {
    dItems = dItems.filter((item) => servs.includes(item?.name));
  }
  if (filterBookmarked) {
    dItems = dItems.filter((el) => el.bookmarked === true);
  }
  return dItems;
};

export const updateReferralFilter = (
  selectServices,
  assignStatuses,
  referStatuses,
  referrals,
  setDisplayedItems,
  filterBookmarked
) => {
  let dItems = referralFilterHelper(
    selectServices,
    assignStatuses,
    referStatuses,
    referrals,
    setDisplayedItems,
    filterBookmarked
  );
  setDisplayedItems(dItems);
};

export const getCompletedReferrals = (referrals) => {
  if (referrals && referrals?.length > 0) {
    return referrals.filter(
      (f) =>
        checkIfContains(f?.status, "closed") ||
        checkIfContains(f?.status, "rejected") ||
        checkIfContains(f?.status, "cancel") ||
        checkIfContains(f?.status, "expired")
    );
  } else {
    return referrals;
  }
};

export const aStatuses = [
  { id: "assigned", label: "Assigned" },
  { id: "unassigned", label: "Unassigned" },
];

export const rParentStatuses = [
  { id: "parent-open", label: "Open" },
  { id: "parent-closed", label: "Closed" },
];

export const rOpenStatuses = [
  { id: "pending", label: "New Referral" },
  { id: "waiting for client", label: "Waiting for Client" },
  { id: "waiting for provider", label: "Waiting for Provider" },
  { id: "working", label: "In Progress" },
  { id: "closure", label: "Pending Closure" },
  { id: "about to expire - pending", label: "About to Expire" },
  { id: "about to expire - waiting for client", label: "Waiting for Client" },
  { id: "about to expire - waiting for provider", label: "Waiting for Provider" },
  { id: "about to expire - closed - unable to contact: email", label: "About to Expire" },
];

export const rClosedStatuses = [
  { id: "rejected by referee", label: "Canceled by Client" },
  { id: "rejected by receiver", label: "Provider Cannot Accept" },
  { id: "referral expired", label: "Referral Expired" },
  { id: "close - client-rejected", label: "Closed - Canceled by Client" },
  { id: "closed - unable to resolve", label: "Closed - Unresolved" },
  { id: "closed - resolved", label: "Closed - Resolved" },
  { id: "closed - unable to contact", label: "Closed - Unable to Contact" },
  { id: "closed - canceled", label: "Closed - Canceled" },
];

export const rStatuses = [
  { id: "pending", label: "New Referral" },
  { id: "waiting for client", label: "Waiting for Client" },
  { id: "waiting for provider", label: "Waiting for Provider" },
  { id: "working", label: "In Progress" },
  { id: "closure", label: "Pending Closure" },
  { id: "close - client-rejected", label: "Closed - Canceled by Client" },
  { id: "closed - unable to resolve", label: "Closed - Unresolved" },
  { id: "closed - resolved", label: "Closed - Resolved" },
  { id: "closed - unable to contact", label: "Closed - Unable to Contact" },
  { id: "closed - canceled", label: "Closed - Canceled" },
  { id: "rejected by referee", label: "Canceled by Client" },
  { id: "rejected by receiver", label: "Provider Cannot Accept" },
  { id: "about to expire - pending", label: "About to Expire" },
  { id: "referral expired", label: "Referral Expired" },
];
