import { useState } from "react";
import { Dropdown, TextInput, InlineLoading } from "carbon-components-react";
import * as S from "./styled";

function InviteInput({
  setInputVal,
  setInputType,
  inputType,
  inList,
  loading,
  inputVal,
  setIsPhoneError,
  setIsEmailError,
  isPhoneError,
  isEmailError,
  resetInputs,
  isPhoneNumberInvalid,
  setIsPhoneNumberInvalid,
  handleSubmit,
  source,
}) {
  const [isBtnDisabled, setIsBtnDisabled] = useState(true);
  // Formatters
  const formatPhone = (value) => {
    const temp = ("" + value)
      .replace(/\D/g, "")
      .match(/^(\d{0,3})(\d{0,3})(\d{0,4})$/);

    let res = "";
    if (temp.input === "") return null;
    if (temp[1] !== "") res = temp[1];
    if (temp[2] !== "") res = "(" + res + ") " + temp[2];
    if (temp[3] !== "") res = res + "-" + temp[3];

    return res;
  };

  // Utils

  const resetErrors = () => {
    setIsEmailError(false);
    setIsPhoneError(false);
    setIsPhoneNumberInvalid(false);
  };

  const handleInputChange = (e) => {
    const value = e.target.value;

    if (inputType === "Phone" && value.length > 14) return;

    setInputVal(value);

    if (value === "") {
      resetErrors();
      return;
    }

    switch (inputType) {
      case "Phone":
        const phoneNumber = formatPhone(value);
        if (phoneNumber === null) {
          setIsPhoneError(true);
          setIsBtnDisabled(true);
          return;
        }
        setInputVal(phoneNumber);
        setIsPhoneError(false);
        setIsBtnDisabled(phoneNumber.length === 14 ? false : true);
        break;

      case "Email":
        const regExTest = /\S+@\S+\.\S+/.test(value);
        if (!regExTest) {
          setIsEmailError(true);
          setIsBtnDisabled(true);
          return;
        }
        setIsEmailError(false);
        setIsBtnDisabled(regExTest ? false : true);
        break;
      default:
        break;
    }
  };

  const dropdownItems = ["Email", "Phone"];
  const resetComponent = () => {
    resetInputs();
    if (source === "accountPage") setIsBtnDisabled(true);
  };
  return (
    <>
      <S.InputWrapper>
        <Dropdown
          id='home-invite-select'
          label=''
          items={dropdownItems}
          onChange={(e) => {
            resetComponent();
            setInputType(e.selectedItem);
          }}
          initialSelectedItem={dropdownItems[inputType === "Phone" ? 1 : 0]}
        />
        <TextInput
          id='home-invite-input'
          labelText=''
          placeholder={
            inputType === "Email" ? "example@example.com" : "(585)-555-5555"
          }
          onChange={(e) => handleInputChange(e)}
          aria-label={inputType === "Email" ? "Enter email address of person to invite" : "Enter phone number of person to invite"}
          value={inputVal}
        />
        {handleSubmit && (
          <S.StyledButton
            disabled={isBtnDisabled || inputVal.length === 0}
            onClick={() => handleSubmit()}
          >
            {loading ? <InlineLoading /> : "Invite"}
          </S.StyledButton>
        )}
      </S.InputWrapper>
      {isEmailError && inputType === "Email" && (
        <S.ErrorText>
          {"Please enter a valid email address: example@example.com"}
        </S.ErrorText>
      )}
      {isPhoneError && inputType === "Phone" && (
        <S.ErrorText>
          {"Please enter a valid phone number: (585)-555-5555"}
        </S.ErrorText>
      )}
      {isPhoneNumberInvalid && inputType === "Phone" && (
        <S.ErrorText>
          {
            "The number you have entered is not a working number. Please enter a valid phone number."
          }
        </S.ErrorText>
      )}
      {inList && (
        <S.ErrorText>{"You have already invited this individual"}</S.ErrorText>
      )}
    </>
  );
}

export default InviteInput;
