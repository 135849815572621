import { useEffect, useState } from "react";
import { useStrapi } from "../../../../../hooks/useStrapi";

export const useDropdownItems = ({ init, link }) => {
  const { data, loading } = useStrapi({
    cms: link
  });

  const [list, setList] = useState(init);

  useEffect(() => {
    if (loading) {
      setList(init);
      return;
    }
    const {
      createdAt,
      updatedAt,
      publishedAt,
      locale,
      items,
      not_listed,
      ...lists
    } = data;

    //Get Default Item
    let defaultItem = Object.entries(lists).find((item) => {
      return item[0].includes("default");
    });

    //Remove default item from current list
    delete lists[defaultItem[0]];

    // parse lists to have same fields as init
    const parsed = Object.entries(lists).reduce((accu, el) => {
      // check for record if defined but empty
      if (el[1] === null) return accu;
      const item = Object.entries(el[1]);

      // check if ghost record
      if (item[0] == undefined) return accu;
      if (item[0][0] === "data") return accu;
      // check if record is mistakenly added as array
      if (item[0][0] === "0") {
        if (item[0][1] === undefined || item[0][1] === "") return accu;
        accu.push({
          id: item[0][1].id,
          text: item[0][1].english_translation,
          show: item[0][1].text,
          key: el[0]
        });
        return accu;
      }
      if (item[1][1] === undefined || item[1][1] === "") return accu;
      accu.push({
        id: item[0][1],
        show: item[1][1],
        text: item[2][1],
        key: el[0]
      });
      return accu;
    }, []);

    // add back default item
    setList([
      {
        id: defaultItem[1].id,
        key: defaultItem[0],
        show: defaultItem[1].text,
        text: defaultItem[1].english_translation
      },
      ...parsed
    ]);
    if (not_listed === null) return;
    setList((state) => {
      const temp = state;
      return [
        ...temp,
        {
          id: not_listed.id,
          text: not_listed.english_translation,
          show: not_listed.text,
          key: "not_listed"
        }
      ];
    });
  }, [data]);

  return {
    list: () => list
  };
};
