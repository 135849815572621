import { SelectItem } from "carbon-components-react";
import { useDropdownItems } from "../../../../../components/360ProfileForm/dropdown/dropdownCMS";
import { maritalItems } from "../../../../../components/360ProfileForm/dropdown/marital.items";
import { StyledDropdownWrapper, StyledSelect, StyledWrapper } from "../AboutMe.styled";
const MaritalStatus = ({ setAboutMeResponse, setDisableButton }) => {
  const { list: listMarital } = useDropdownItems({
    init: maritalItems,
    link: "list-marital"
  });
  const marital = listMarital().reduce((accu, el) => {
    accu[el.key] = el;
    return accu;
  }, {});

  const handleSelectChange = (e) => {
    if (e.target.value === "default") {
      setDisableButton(true);
      return;
    }
    setDisableButton(false);
    setAboutMeResponse((state) => ({
      ...state,
      maritalStatus: e.target.value
    }));
  };

  return (
    <StyledWrapper style={{flexDirection: 'column'}}>
      <StyledDropdownWrapper>
        <StyledSelect
          id="maritalstatus"
          labelText=" "
          defaultValue="default"
          onChange={(e) => handleSelectChange(e)}
        >
          <SelectItem
            text={marital.default.show}
            value="default"
            id="default"
          />
          <SelectItem text={marital.single.show} value="Single" id="s" />
          <SelectItem text={marital.married.show} value="Married" id="m" />
          <SelectItem
            text={marital.cohabitating.show}
            value="Cohabitating"
            id="c"
          />
          <SelectItem
            text={marital.separated.show}
            value="Separated"
            id="sep"
          />
          <SelectItem text={marital.divorced.show} value="Divorced" id="d" />
          <SelectItem text={marital.widowed.show} value="Widowed" id="w" />
        </StyledSelect>
      </StyledDropdownWrapper>
    </StyledWrapper>
  );
};

export default MaritalStatus;
