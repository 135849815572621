import { useEffect, useState } from 'react';
import { useStrapi } from '../../../hooks/useStrapi';

export const useCheckinCMS = () => {
  const domainInit = {
    food: 'Food',
    health: 'Health',
    housing: 'Housing',
    education: 'Education',
    social_connections: 'Social Connections',
    safety: 'Safety',
    income: 'Income',
    transportation: 'Transportation',
    dependent_care: 'Dependent Care',
    financial: 'Financial',
    employment: 'Employment'
  };
  const { data: domainData, loading: domainLoading } = useStrapi({
    cms: `list-domain`
  });
  const [domain, setDomain] = useState(domainInit);
  useEffect(() => {
    if (domainLoading) {
      setDomain(domainInit);
      return;
    }
    if (domainData) {
      setDomain((state) => ({
        food: domainData.food || state.food,
        health: domainData.health || state.health,
        housing: domainData.housing || state.housing,
        education: domainData.education || state.education,
        social_connections:
          domainData.social_connections || state.social_connections,
        safety: domainData.safety || state.safety,
        income: domainData.income || state.income,
        transportation: domainData.transportation || state.transportation,
        dependent_care: domainData.dependent_care || state.dependent_care,
        financial: domainData.financial || state.financial,
        employment: domainData.employment || state.employment
      }));
    }
  }, [domainData]);

  const wellbeingInit = {
    excellent: 'Excellent',
    great: 'Great',
    good: 'Good',
    okay: 'Okay',
    not_okay: 'Not Okay'
  };
  const { data: wellbeingData, loading: wellbeingLoading } = useStrapi({
    cms: `list-wellbeing`
  });
  const [wellbeing, setWellbeing] = useState(wellbeingInit);
  useEffect(() => {
    if (wellbeingLoading) {
      setWellbeing(wellbeingInit);
      return;
    }
    setWellbeing((state) => ({
      excellent: wellbeingData.items.excellent || state.excellent,
      great: wellbeingData.items.great || state.great,
      good: wellbeingData.items.good || state.good,
      okay: wellbeingData.items.okay || state.okay,
      not_okay: wellbeingData.items.not_okay || state.not_okay
    }));
  }, [wellbeingData]);

  const checkinInit = {
    heading: 'Check-Ins',
    subheading_recommendations: 'Recommendations',
    btn_view_provider: 'View Provider',
    btn_view_all: 'View All',
    subheading_checkin_summary: 'Check-In Summary',
    subheading_checkin_domains: 'Check-In Domains',
    summary_positive_desc: 'You are doing really well in these areas',
    summary_negative_desc: 'It looks like you could use some support with',
    btn_print_result: 'Print Results',
    btn_email_results: 'Email Results',
    domains_positive_desc: 'You are doing really well in these domains',
    domains_negative_desc: 'It looks like you could use some support with',
    text_based_response:
      'Based on your responses we found local service providers who can help you.',
    label_legend: 'Legend',
    text_non_answered: 'No questions answered',
    summary_domains: {
      id: 1,
      economic_opportunity: 'Economic Opportunity',
      health_and_well_being: 'Health and Wellbeing',
      education_opportunity: 'Education Opportunity',
      safety_and_security: 'Safety and Security'
    }
  };
  const { data: checkinData, loading: checkinLoading } = useStrapi({
    cms: `page-checkin-summary`
  });
  const [checkin, setCheckin] = useState(checkinInit);
  useEffect(() => {
    if (checkinLoading) {
      setCheckin(checkinInit);
      return;
    }
    setCheckin((state) => ({
      heading: checkinData.heading || state.heading,
      subheading_recommendations:
        checkinData.subheading_recommendations ||
        state.subheading_recommendations,
      btn_view_provider:
        checkinData.btn_view_provider || state.btn_view_provider,
      btn_view_all: checkinData.btn_view_all || state.btn_view_all,
      label_legend: checkinData.label_legend || state.label_legend,
      subheading_checkin_summary:
        checkinData.subheading_checkin_summary ||
        state.subheading_checkin_summary,
      subheading_checkin_domains:
        checkinData.subheading_checkin_domains ||
        state.subheading_checkin_domains,
      summary_positive_desc:
        checkinData.summary_positive_desc || state.summary_positive_desc,
      summary_negative_desc:
        checkinData.summary_negative_desc || state.summary_negative_desc,
      btn_print_result: checkinData.btn_print_result || state.btn_print_result,
      btn_email_results:
        checkinData.btn_email_results || state.btn_email_results,
      domains_positive_desc:
        checkinData.domains_positive_desc || state.domains_positive_desc,
      domains_negative_desc:
        checkinData.domains_negative_desc || state.domains_negative_desc,
      text_based_response:
        checkinData.text_based_response || state.text_based_response,
      text_non_answered:
        checkinData.text_non_answered || state.text_non_answered,
      summary_domains: checkinData.summary_domains || state.summary_domains
    }));
  }, [checkinData]);

  return { checkin, wellbeing, domain };
};
