import {
  Close24,
  IntentRequestScaleIn20,
  IntentRequestScaleOut20
} from "@carbon/icons-react";
import { InlineLoading, Loading } from "carbon-components-react";
import kit from "ich-ui-kit";
import React, { useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import { getDisplayedStatus } from "../../../utils.js";
import * as S from "../subpages/referrals/card/card.styled";
import {
  CardBadge,
  CardExplanation,
  CardImage,
  CardInfo,
  CardTitle,
  FlexFeedCard
} from "./static.jsx";
import { isFeedCardActionDisabled } from '../utils';

const convertTimestamp = (timestamp) => {
  try {
    let authoredLocalTime = timestamp;
    if (timestamp?.includes('T')) {
      authoredLocalTime = timestamp;
    } else {
      authoredLocalTime = timestamp?.replace(' ', 'T') + 'Z';
    }
    authoredLocalTime = new Date(authoredLocalTime).toLocaleString();
    return authoredLocalTime;
  } catch (e) {
    console.log(e);
    return '';
  }
};

const convertTimestamp10Days = (timestamp) => {
  try {
    let authoredLocalTime = timestamp;
    if (timestamp?.includes('T')) {
      authoredLocalTime = timestamp;
    } else {
      authoredLocalTime = timestamp?.replace(' ', 'T') + 'Z';
    }
    authoredLocalTime = new Date(authoredLocalTime);
    authoredLocalTime.setDate(authoredLocalTime.getDate() + 10);
    return authoredLocalTime.toLocaleDateString();
  } catch (e) {
    console.log(e);
    return '';
  }
};

export function ReferralCard({
  cardData,
  clearActivity,
  setActivityInactive,
  getLatestFeed,
  inbound,
  clearReferralActivity,
  setReferralActivityInactive,
  setReferralActivitiesInactive,
  currentReferralLoading,
  setCurrentReferralLoading
}) {
  const history = useHistory();
  const { user } = useContext(kit.UserContext);
  const [ref, setRef] = useState({});
  const { apiService } = useContext(kit.ApiContext);
  const [disabledButtons, setDisabledButtons] = useState(false);
  const [statusLoading, setStatusLoading] = useState(false);

  const updateStatus = async (status, cardData, set1ActivityInactive=true) => {
    setStatusLoading(true);
    setCurrentReferralLoading(cardData.referralId);
    try {
      await apiService.put(`/referrals/status?assumedId=${user.superAssumedRole?.leadId || ""}`, {
        patientId: cardData.patientId,
        status,
        referralId: cardData.referralId,
        serviceId: cardData.serviceId,
        assignedTo: cardData.assignedTo,
        originatorId: cardData.senderId
      });
      if(set1ActivityInactive) {
        await setActivityInactive(cardData.id, cardData.type);
      } else {
        await setReferralActivitiesInactive(
          cardData.patientId,
          cardData.referralId
        ); 
      }
    } catch (err) {
      console.log(err);
    } finally {
      setStatusLoading(false);
      setCurrentReferralLoading("");
    }
    // clearActivity(cardData.id, cardData.type);
  };

  const card = (data) => {
    const d = new Date(data.timestamp);
    d.setSeconds(d.getSeconds() + 99);
    return (
      <S.Card data-id={`feed-card-${data?.referralId}`}>
        {inbound ? <IntentRequestScaleIn20 /> : <IntentRequestScaleOut20 />}
        <S.CardInfoWrapperReferral>
          <S.CardHeader>
            <S.Header>
              <S.TimeStamp>{d.toLocaleString()}</S.TimeStamp>
              {data.referralId !== undefined && inbound ? (
                <S.TextSM>
                  Referral #{data.referralId}: Service Request You Received
                </S.TextSM>
              ) : (
                <S.TextSM>
                  Referral #{data.referralId}: Service Request You Sent
                </S.TextSM>
              )}
            </S.Header>
            {data.closeBtn && (
              <S.CloseBtn onClick={data.close} data-id={`feed-card-close-btn`}>
                <Close24 />
              </S.CloseBtn>
            )}
          </S.CardHeader>
          <S.CardBody>
            <S.Description
              dangerouslySetInnerHTML={{ __html: data.description }}
            />
          </S.CardBody>
          {data.btnGroup}
        </S.CardInfoWrapperReferral>
      </S.Card>
    );
  };

  switch (cardData.subType) {
    case 'closed':
      return card({
        id: cardData.id,
        referralId: cardData.referralId,
        title: 'Referral Closed',
        timestamp: convertTimestamp(cardData.date),
        description:
          cardData.patientId === cardData.senderId
            ? `<strong>${cardData.providerStaffName}</strong> declined their referral to <strong>${cardData.serviceName}</strong>.`
            : `<strong>${cardData.providerStaffName}</strong> from <strong>${cardData.orgName}</strong> closed a referral to <strong>${cardData.serviceName}</strong>.`,
        close: () => {
          clearActivity(cardData.id, cardData.type);
        },
        closeBtn: true,
        disabled: cardData.active === 0 ? true : false,
        btnGroup: (
          <S.ButtonGroup>
            <S.ButtonLeft>
              <S.ViewReferralButton
                data-id={`feed-card-view-btn`}
                kind="tertiary"
                onClick={() => {
                  // getLatestFeed();
                  history.push({
                    pathname: '/feed/referrals',
                    state: {
                      referralId: cardData.referralId,
                      client: cardData.patientId
                    }
                  });
                }}
              >
                View Referral
              </S.ViewReferralButton>
            </S.ButtonLeft>
          </S.ButtonGroup>
        )
      });
    case 'note':
      return card({
        id: cardData.id,
        referralId: cardData.referralId,
        title: 'New Referral Note',
        timestamp: convertTimestamp(cardData.date),
        description: `<strong>Referral note from ${cardData.providerStaffName}</strong`,
        close: () => {
          clearActivity(cardData.id, cardData.type);
        },
        closeBtn: true,
        btnGroup: (
          <S.ButtonGroup>
            <S.ButtonLeft>
              <S.ViewButton
                data-id={`feed-card-view-btn`}
                kind="tertiary"
                onClick={() => {
                  // getLatestFeed();
                  history.push({
                    pathname: '/feed/referrals',
                    state: {
                      referralId: cardData.referralId,
                      client: cardData.patientId,
                      cardId: cardData.id
                    }
                  });
                }}
              >
                View Referral Note
              </S.ViewButton>
            </S.ButtonLeft>
          </S.ButtonGroup>
        )
      });
    case 'assigned':
      return card({
        id: cardData.id,
        referralId: cardData.referralId,
        title: 'Referral Assignment Updated',
        timestamp: convertTimestamp(cardData.date),
        description: `Referral to <strong>${
          cardData.serviceName
        }</strong> was assigned${
          cardData.assignedToName
            ? ' to <strong>' + cardData.assignedToName + '</strong>.'
            : '.'
        }`,
        close: () => {
          clearActivity(cardData.id, cardData.type);
        },
        closeBtn: true,
        disabled: cardData.active === 0 ? true : false,
        btnGroup: (
          <S.ButtonGroup>
            <S.ButtonLeft>
              <S.ViewReferralButton
                data-id={`feed-card-view-btn`}
                kind="tertiary"
                onClick={() => {
                  history.push({
                    pathname: '/feed/referrals',
                    state: {
                      referralId: cardData.referralId,
                      client: cardData.patientId
                    }
                  });
                }}
              >
                View Referral
              </S.ViewReferralButton>
            </S.ButtonLeft>
          </S.ButtonGroup>
        )
      });
    case 'created':
      return card({
        id: cardData.id,
        referralId: cardData.referralId,
        title: 'New Referral Created',
        timestamp: convertTimestamp(cardData.date),
        description: inbound ? `<strong>Referral received to ${cardData.serviceName}</strong>` : `<strong>Referral sent to ${cardData.serviceName}</strong>`,
        close: () => {
          clearActivity(cardData.id, cardData.type);
        },
        disabled:
          cardData?.orgId !== user?.orgId ||
          disabledButtons
          ? true
          : false ||
          cardData.active === 0, 
        closeBtn: isFeedCardActionDisabled(inbound, cardData, disabledButtons),
        btnGroup: 
          currentReferralLoading === cardData.referralId ? (
            <InlineLoading
              status="active"
              iconDescription="Loading"
              description="Loading..." 
              /> 
          ) : (
          <S.ButtonGroup>
            <S.ButtonLeft>
              <S.ViewReferralButton
                data-id={`feed-card-view-btn`}
                kind="tertiary"
                onClick={() => {
                  // getLatestFeed();
                  history.push({
                    pathname: '/feed/referrals',
                    state: {
                      referralId: cardData.referralId,
                      client: cardData.patientId,
                      cardId: cardData.id
                    }
                  });
                }}
              >
                View Referral
              </S.ViewReferralButton>
            </S.ButtonLeft>
            <S.ButtonRight>
              <S.ViewButton
                data-id={`feed-card-accept-btn`}
                kind="primary"
                disabled={isFeedCardActionDisabled(
                  inbound,
                  cardData,
                  disabledButtons
                )}
                onClick={async () => {
                  setDisabledButtons(true);
                  await updateStatus('Waiting for Client', cardData, false);   
                  setCurrentReferralLoading("");
                }}
              >
                Accept
              </S.ViewButton>
              <S.ViewButton
                data-id={`feed-card-decline-btn`}
                kind="tertiary"
                disabled={isFeedCardActionDisabled(
                  inbound,
                  cardData,
                  disabledButtons
                )}
                onClick={async () => {
                  setDisabledButtons(true);
                  await updateStatus("Rejected by Receiver", cardData, false);
                  history.push({
                    pathname: '/feed/referrals',
                    state: {
                      referralId: cardData.referralId,
                      client: cardData.patientId,
                      isDecline: true,
                      cardId: cardData.id
                    }
                  });
                }}
              >
                Reject
              </S.ViewButton>
            </S.ButtonRight>
          </S.ButtonGroup>
        )
      });
    case 'update':
      if (
        cardData.referralStatus &&
        cardData.referralStatus.toLowerCase().includes('rejected')
      ) {
        return card({
          id: cardData.id,
          referralId: cardData.referralId,
          title: 'Referral Rejected',
          timestamp: convertTimestamp(cardData.date),
          description:
            cardData.patientId === cardData.senderId
              ? `<strong>${cardData.providerStaffName}</strong> declined their referral to <strong>${cardData.serviceName}</strong>.`
              : `<strong>${cardData.providerStaffName}</strong> has rejected <strong>${cardData.patientName}'s</strong> referral to <strong>${cardData.serviceName}</strong>`,
          close: () => {
            clearActivity(cardData.id, cardData.type);
          },
          closeBtn: true,
          disabled: cardData.active === 0 ? true : false,
          btnGroup: (
            <S.ButtonGroup>
              <S.ButtonLeft>
                <S.ViewReferralButton
                  data-id={`feed-card-view-btn`}
                  kind="tertiary"
                  onClick={() => {
                    // getLatestFeed();
                    history.push({
                      pathname: '/feed/referrals',
                      state: {
                        referralId: cardData.referralId,
                        client: cardData.patientId
                      }
                    });
                  }}
                >
                  View Referral
                </S.ViewReferralButton>
              </S.ButtonLeft>
            </S.ButtonGroup>
          )
        });
      } else {
        return card({
          id: cardData.id,
          referralId: cardData.referralId,
          title: 'Referral Status Updated',
          timestamp: convertTimestamp(cardData.date),
          description: `Referral status updated to <strong>${getDisplayedStatus(
            cardData.referralStatus,
            '',
            true
          )}</strong>`,
          close: () => {
            clearActivity(cardData.id, cardData.type);
          },
          closeBtn: true,
          disabled: cardData.active === 0 ? true : false,
          btnGroup: (
            <S.ButtonGroup>
              <S.ButtonLeft>
                <S.ViewReferralButton
                  data-id={`feed-card-view-btn`}
                  kind="tertiary"
                  onClick={() => {
                    // getLatestFeed();
                    history.push({
                      pathname: '/feed/referrals',
                      state: {
                        referralId: cardData.referralId,
                        client: cardData.patientId
                      }
                    });
                  }}
                >
                  View Referral
                </S.ViewReferralButton>
              </S.ButtonLeft>
            </S.ButtonGroup>
          )
        });
      }
    case 'expired':
      return card({
        id: cardData.id,
        referralId: cardData.referralId,
        title: 'Referral Expired',
        timestamp: convertTimestamp(cardData.date),
        description: `Referral to <strong>${cardData.serviceName}</strong> has expired.`,
        close: () => {
          clearActivity(cardData.id, cardData.type);
        },
        closeBtn: true,
        btnGroup: (
          <S.ButtonGroup>
            <S.ButtonLeft>
              <S.ViewReferralButton
                data-id={`feed-card-view-btn`}
                kind="tertiary"
                onClick={() => {
                  // getLatestFeed();
                  history.push({
                    pathname: "/feed/referrals",
                    state: {
                      referralId: cardData.referralId,
                      client: cardData.patientId
                    }
                  });
                }}
              >
                View Referral
              </S.ViewReferralButton>
            </S.ButtonLeft>
          </S.ButtonGroup>
        )
      });
    case 'expire-warning':
      return card({
        id: cardData.id,
        referralId: cardData.referralId,
        title: 'New Referral Created',
        timestamp: convertTimestamp(cardData.date),
        description: `Referral ${inbound ? 'received' : 'sent'} to <strong>${
          cardData.serviceName
        }</strong> is about to expire. If no action is taken this referral will expire on ${convertTimestamp10Days(
          cardData.date
        )}.`,
        close: () => {
          clearActivity(cardData.id, cardData.type);
        },
        disabled: disabledButtons ? true : false || cardData.active === 0,
        closeBtn: isFeedCardActionDisabled(inbound, cardData, disabledButtons),
        btnGroup: 
          currentReferralLoading === cardData.referralId ? (
            <InlineLoading
              status="active"
              iconDescription="Loading"
              description="Loading..." 
              /> 
          ) : (
          <S.ButtonGroup>
            <S.ButtonLeft>
              <S.ViewReferralButton
                data-id={`feed-card-view-btn`}
                kind="tertiary"
                onClick={() => {
                  // getLatestFeed();
                  history.push({
                    pathname: '/feed/referrals',
                    state: {
                      referralId: cardData.referralId,
                      client: cardData.patientId
                    }
                  });
                }}
              >
                View Referral
              </S.ViewReferralButton>
            </S.ButtonLeft>
            <S.ButtonRight>
              <S.ViewButton
                data-id={`feed-card-accept-btn`}
                kind="primary"
                disabled={isFeedCardActionDisabled(
                  inbound,
                  cardData,
                  disabledButtons
                )}
                onClick={async () => {
                  setDisabledButtons(true);
                  await updateStatus("Waiting for Client", cardData, false);
                }}
              >
                Accept
              </S.ViewButton>
              <S.ViewButton
                data-id={`feed-card-decline-btn`}
                kind="tertiary"
                disabled={isFeedCardActionDisabled(
                  inbound,
                  cardData,
                  disabledButtons
                )}
                onClick={async () => {
                  setDisabledButtons(true);
                  await updateStatus("Rejected by Receiver", cardData, false);
                  history.push({
                    pathname: '/feed/referrals',
                    state: {
                      referralId: cardData.referralId,
                      client: cardData.patientId,
                      isDecline: true,
                      cardId: cardData.id
                    }
                  });
                }}
              >
                Reject
              </S.ViewButton>
            </S.ButtonRight>
          </S.ButtonGroup>
        )
      });
    default:
      return null;
  }
}

export const NeedsRequestCard = ({
  cardData,
  careTeam,
  clearActivity,
  setActivityInactive,
  loading
}) => {
  const history = useHistory();
  const { apiService } = useContext(kit.ApiContext);
  const { user } = useContext(kit.UserContext);
  const [loadingAddCareTeam, setLoadingAddCareTeam] = useState(false);
  const [disabledButtons, setDisabledButtons] = useState(false);
  const d = new Date(cardData.date);
  d.setSeconds(d.getSeconds() + 99);
  let cardTimestamp = d.toLocaleString();
  const card = (data) => {
    const d = new Date(data.timestamp);
    d.setSeconds(d.getSeconds() + 99);
    return (
      <S.Card data-id={`feed-card-${cardData?.referralId}`}>
        <S.CardInfoWrapper>
          <S.CardHeader>
            <S.Header>
              <S.TimeStamp>{d.toLocaleString()}</S.TimeStamp>
              <S.TextSM>
                Needs Request #{data.referralId}
              </S.TextSM>
            </S.Header>
            {data.closeBtn && (
              <S.CloseBtn onClick={data.close} data-id={`feed-card-close-btn`}>
                <Close24 />
              </S.CloseBtn>
            )}
          </S.CardHeader>
          <S.CardBody>
            <S.Description
              dangerouslySetInnerHTML={{ __html: data.description }}
            />
          </S.CardBody>
          {data.btnGroup}
        </S.CardInfoWrapper>
      </S.Card>
    );
  };
  switch (cardData.subType) {
    case 'created':
      return card({
        id: cardData.id,
        referralId: cardData.referralId,
        title: 'Needs Request',
        timestamp: convertTimestamp(cardData.date),
        description: `<strong>${cardData.providerStaffName}</strong> sent a Needs Request to <strong>${cardData.serviceName}</strong>`,
        close: () => {
          clearActivity(cardData.id, cardData.type);
        },
        closeBtn: isFeedCardActionDisabled(true, cardData, disabledButtons),
        disabled: cardData.active === 0 ? true : false,
        btnGroup:
          loading || loadingAddCareTeam ? (
            <InlineLoading />
          ) : careTeam ? (
            <S.ButtonGroup>
              <S.ButtonLeft>
                <S.ViewButton
                  data-id={`feed-card-view-btn`}
                  kind={cardData.active === 1 ? "primary" : "tertiary"}
                  onClick={async () => {
                    await setActivityInactive(
                      cardData.id,
                      cardData.type
                    );
                    history.push({
                      pathname: "/360dashboard",
                      state: {
                        client: cardData.patientId
                      }
                    });
                  }}
                >
                  View 360 Dashboard
                </S.ViewButton>
              </S.ButtonLeft>
            </S.ButtonGroup>
          ) : (
            <S.ButtonGroup>
              <S.ButtonLeft>
                <S.ViewButton
                  data-id={`feed-card-send-btn`}
                  disabled={
                    cardData.active === 0 || disabledButtons ? true : false
                  }
                  kind={
                    cardData.active === 0 || disabledButtons
                      ? "tertiary"
                      : "primary"
                  }
                  onClick={async () => {
                    await setActivityInactive(
                      cardData.id,
                      cardData.type
                    );
                    setDisabledButtons(true);
                    setLoadingAddCareTeam(true);
                    try {
                      await apiService.post(
                        `/careteam/request?clientId=${
                          cardData.patientId
                        }&serviceAtLocationId=${
                          cardData.serviceAtLocationKey
                        }&serviceName=${
                          cardData.serviceName
                        }&orgId=${
                          cardData.orgId
                        }&assumedId=${
                          user.superAssumedRole?.leadId || ''
                        }`
                      );
                    } catch (err) {
                      console.log(err);
                    }
                    setLoadingAddCareTeam(false);
                  }}
                >
                  Send Care Team Request
                </S.ViewButton>
              </S.ButtonLeft>
            </S.ButtonGroup>
          )
      });
    default:
      return null;
  }
};

export const CareCard = ({
  cardData,
  clearActivity,
  setActivityInactive,
  careTeam,
  loading
}) => {
  const card = (data) => {
    const d = new Date(data.timestamp);
    d.setSeconds(d.getSeconds() + 99);
    return (
      <S.Card data-id={`feed-card-${cardData?.referralId || data?.id}`}>
        <S.CardInfoWrapper>
          <S.CardHeader>
            <S.Header>
              <S.TimeStamp>{d.toLocaleString()}</S.TimeStamp>
            </S.Header>
            {data.closeBtn && (
              <S.CloseBtn onClick={data.close} data-id={`feed-card-close-btn`}>
                <Close24 />
              </S.CloseBtn>
            )}
          </S.CardHeader>
          <S.CardBody>
            <S.Description
              dangerouslySetInnerHTML={{ __html: data.description }}
            />
          </S.CardBody>
          {data.btnGroup}
        </S.CardInfoWrapper>
      </S.Card>
    );
  };

  const history = useHistory();
  const d = new Date(cardData.date);
  d.setSeconds(d.getSeconds() + 99);
  let cardTimestamp = d.toLocaleString();
  if (cardData.subType.toLowerCase() === 'declined') {
    return card({
      id: cardData.id,
      referralId: cardData.referralId,
      timestamp: convertTimestamp(cardData.date),
      description: `<strong>${cardData.providerStaffName}</strong> declined
      <strong>${cardData.serviceName}</strong> Care Team Request`,
      close: () => {
        clearActivity(cardData.id, cardData.type);
      },
      closeBtn: true,
      disabled: cardData.active === 0 ? true : false
    });
  } else if (cardData.subType.toLowerCase() === 'removed') {
    return card({
      id: cardData.id,
      referralId: cardData.referralId,
      timestamp: convertTimestamp(cardData.date),
      description: `<strong>${cardData.providerStaffName}</strong> removed
      <strong>${cardData.serviceName}</strong> from their Care Team <br />`,
      close: () => {
        clearActivity(cardData.id, cardData.type);
      },
      closeBtn: true,
      disabled: cardData.active === 0 ? true : false,
      btnGroup: `Reason: ${cardData.message}`
    });
  } else {
    const handleViewDashboardClick = async () => {
      await setActivityInactive(cardData.id, cardData.type);
      history.push({
        pathname: '/360dashboard',
        state: {
          client: cardData.senderId
        }
      });
    };

    return card({
      id: cardData.id,
      referralId: cardData.referralId,
      timestamp: convertTimestamp(cardData.date),
      description: `<strong>${cardData.providerStaffName}</strong> added
      <strong>${cardData.serviceName}</strong> to their Care Team`,
      close: () => {
        clearActivity(cardData.id, cardData.type);
      },
      closeBtn: cardData.active === 1 ? false : true,
      disabled: cardData.active === 0 || !careTeam ? true : false,
      btnGroup: cardData.subType &&
        cardData.subType.toLowerCase() === 'accepted' && (
          <>
            {careTeam && (
              <>
                {' '}
                {loading ? (
                  <InlineLoading />
                ) : (
                  <S.ButtonGroup>
                    <S.ButtonLeft>
                      <S.ViewButton
                        data-id={`feed-card-view-btn`}
                        kind={cardData.active === 1 ? 'primary' : 'tertiary'}
                        onClick={handleViewDashboardClick}
                      >
                        View 360 Dashboard
                      </S.ViewButton>
                    </S.ButtonLeft>
                  </S.ButtonGroup>
                )}
              </>
            )}
          </>
        )
    });
  }
};

export function EarnedBadge(cardData) {
  return (
    <FlexFeedCard data-id={`feed-card-badge`}>
      <div
        style={{
          paddingLeft: '2rem',
          textAlign: 'left',
          justifyContent: 'left'
        }}
      >
        <CardTitle>{cardData.title}</CardTitle>
        <CardInfo>{convertTimestamp(cardData.date)}</CardInfo>
        <h5>Congratulations!</h5>
        <CardExplanation>
          You earned the{' '}
          <span
            style={{
              fontWeight: 'bold',
              textTransform: 'uppercase',
              color: 'blue'
            }}
          >
            {cardData.explanation}
          </span>{' '}
          Badge!
        </CardExplanation>
      </div>
      <div
        style={{
          paddingRight: '2rem',
          textAlign: 'center',
          justifyContent: 'space-between'
        }}
      >
        <CardBadge>
          <CardImage
            style={{ height: '100px' }}
            alt={cardData.title}
            src={cardData.imgUrl}
          />
          <p>{cardData.caption}</p>
        </CardBadge>
      </div>
    </FlexFeedCard>
  );
}

export function RecommendedBadge(cardData) {
  return (
    <FlexFeedCard data-id={`feed-card-recommended`}>
      <div
        style={{
          paddingLeft: '2rem',
          textAlign: 'left',
          justifyContent: 'left'
        }}
      >
        <CardTitle>{cardData.title}</CardTitle>
        <CardInfo>{convertTimestamp(cardData.date)}</CardInfo>
        <h5>Earn this badge!</h5>
        <CardExplanation>
          You can earn the{' '}
          <span
            style={{
              fontWeight: 'bold',
              textTransform: 'uppercase',
              color: 'blue'
            }}
          >
            {cardData.explanation}
          </span>{' '}
          Badge!
        </CardExplanation>
      </div>
      <div
        style={{
          paddingRight: '2rem',
          textAlign: 'center',
          justifyContent: 'space-between'
        }}
      >
        <CardBadge>
          <CardImage
            style={{ height: '100px' }}
            alt={cardData.title}
            src={cardData.imgUrl}
          />
          <p>{cardData.caption}</p>
        </CardBadge>
      </div>
    </FlexFeedCard>
  );
}

export function ReminderCard({ cardData, loading, clearActivity }) {
  const history = useHistory();
  return (
    <S.Card data-id={`feed-card-${cardData.id}`}>
      <S.CardHeader>
        <S.Header>
          <S.TimeStamp>{new Date(cardData.date).toLocaleString()}</S.TimeStamp>
          <S.TextSM>Reminder</S.TextSM>
        </S.Header>
        <S.CloseBtn onClick={clearActivity} data-id={`feed-card-close-btn`}>
          <Close24 />
        </S.CloseBtn>
      </S.CardHeader>
      <S.CardBody>
        <S.Description>
          {cardData?.serviceName || 'A service of yours'} has had no capacity
          for over 30 days. Please make sure this is correct.
        </S.Description>
      </S.CardBody>
      <S.ButtonGroup>
        <S.ButtonLeft>
          <S.ViewButton
            data-id={`feed-card-view-btn`}
            kind="tertiary"
            onClick={() => {
              history.push({
                pathname: '/account',
                state: {
                  fromFeed: 'view-services'
                }
              });
            }}
          >
            View Services
          </S.ViewButton>
        </S.ButtonLeft>
      </S.ButtonGroup>
    </S.Card>
  );
}

export const AccessUpdateCard = ({ cardData, clearActivity }) => {
  const card = (data) => {
    const d = new Date(data.timestamp);
    d.setSeconds(d.getSeconds() + 99);
    return (
      <S.Card data-id={`feed-card-${data?.id}`}>
        <S.CardInfoWrapper>
          <S.CardHeader>
            <S.Header>
              <S.TimeStamp>{d.toLocaleString()}</S.TimeStamp>
            </S.Header>
            {data.closeBtn && (
              <S.CloseBtn onClick={data.close} data-id={`feed-card-close-btn`}>
                <Close24 />
              </S.CloseBtn>
            )}
          </S.CardHeader>
          <S.CardBody>
            <S.Description
              dangerouslySetInnerHTML={{ __html: data.description }}
            />
          </S.CardBody>
          {data.btnGroup}
        </S.CardInfoWrapper>
      </S.Card>
    );
  };

  const d = new Date(cardData.date);
  d.setSeconds(d.getSeconds() + 99);
  let cardTimestamp = d.toLocaleString();
  if (cardData.subType.toLowerCase() === 'promoted') {
    return card({
      id: cardData.id,
      timestamp: cardTimestamp,
      description: `<strong>${cardData.providerStaffName}</strong> granted you admin access.`,
      close: () => {
        clearActivity(cardData.id, cardData.type);
      },
      closeBtn: true,
      disabled: cardData.active === 0 ? true : false
    });
  } else if (cardData.subType.toLowerCase() === 'demoted') {
    return card({
      id: cardData.id,
      // referralId: cardData.referralId,
      timestamp: cardTimestamp,
      description: `<strong>${cardData.providerStaffName}</strong> removed
      your admin access.`,
      close: () => {
        clearActivity(cardData.id, cardData.type);
      },
      closeBtn: true,
      disabled: cardData.active === 0 ? true : false
    });
  }
};
