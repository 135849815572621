/* eslint-disable no-lone-blocks */
import React, { useContext, useState, useEffect, useLayoutEffect } from "react";
import { Switch, Route, useLocation } from "react-router-dom";
import { useTheme } from "styled-components";
import Empty from "./subpages/referrals/empty";
import { FeedPanel, FeedButton, Notification } from "./components/static.jsx";
import {
  EarnedBadge,
  ReferralCard,
  CareCard,
  NeedsRequestCard,
  ReminderCard,
  AccessUpdateCard,
} from "./components/ActivityPanes.jsx";

import kit from "ich-ui-kit";
import { ActivityFeedContext } from "../../store/ActivityFeedContext";
import * as S from "./feed.styled";
import { Referral } from "./subpages/referrals";
import { Loading } from "carbon-components-react";

const ActivityFeed = () => {
  const [pane, setPane] = useState("mostRecent");
  const [loading, setLoading] = useState(false);
  const [referralDefaultView, setReferralDefaultView] = useState(false);
  const [defaultReferral, setDefaultReferral] = useState("");
  const {
    activityFeed,
    numberUnread,
    readFeedType,
    clearActivity,
    setActivityInactive,
    getLatestFeed,
    getFeed,
    clearReferralActivity,
    setReferralActivityInactive,
    setReferralActivitiesInactive
  } = useContext(ActivityFeedContext);
  const { apiService } = useContext(kit.ApiContext);
  const { user } = useContext(kit.UserContext);
  const [userOrgName, setUserOrgName] = useState("");
  const location = useLocation();
  const theme = useTheme();
  const [feedLoading, setFeedLoading] = useState(false);
  const [initialLoading, setInitialLoading] = useState(false);
  const [currentReferralLoading, setCurrentReferralLoading] = useState("");
  const [careTeam, setCareTeam] = useState([]);
  const [services, setServices] = useState(undefined);

  useLayoutEffect(() => {
    setFeedLoading(true);
    getFeed(() => {
      setFeedLoading(false);
    })
  }, []);


  useEffect(() => {
    const displayOrgName = (providers) => {
      if (user.is211Provider === true  || user.is211Admin === true ) {
        setUserOrgName("211");
      } else if (user.isSuper === true && !user?.superAssumedRole?.orgId) {
        setUserOrgName("");
      } else if (
        user.is211Provider === false &&
        user.is211Admin === false &&
        (user.isSuper === false || user?.superAssumedRole?.orgId)
      ) {
        setUserOrgName(providers.name);
      }
    }

    const getProviderInfo = async () => {
      try {
        const {providers} = await apiService.post(
          `/search/provider?user=${user.superAssumedRole?.leadId || user.id}`
        );
        let orgId;
        let tempallservices;
        if (providers) {
          orgId = providers.id;
          tempallservices = providers.services;
          displayOrgName(providers);
          if (user.isAdmin || user.isSuper) {
            setServices(providers.services);
          } else {
            const providerServices2 = await apiService.get(
              `/providerstaff/service/dashboard?assumedUserId=${user.superAssumedRole?.leadId}`
            );
            if (providerServices2.ok) {
              setServices(
                tempallservices.filter((f) =>
                  providerServices2.servIds.includes(f.serviceId)
                )
              );
            }
          }
        }
      } catch (err) {
        console.log(err);
      }
    };

    const getCareTeam = async () => {
      try {
        const patients = await apiService.get(
          `/clients/careteam?assumedUserId=${user?.superAssumedRole?.leadId || null}`
        ); if (patients && patients.length) {
          setCareTeam(patients);
        }
      } catch (err) {
        console.log(err);
      }
    };

    if (!services || activityFeed.length !== 0) {
      setInitialLoading(true);
      getProviderInfo();
      getCareTeam();
      setInitialLoading(false);
    }
  }, [apiService]);

  useEffect(() => {
    switch (location.pathname) {
      case "/feed":
        setPane("mostRecent");
        return;
      case "/feed/referrals":
        setPane("referrals");
        return;
      case "/feed/care-team":
        setPane("careTeam");
        return;
      case "/feed/recommended":
        setPane("recommended");
        return;
      default:
        setPane("mostRecent");
    }
  }, [location.pathname]);

  const getReferralDefaultViewElement = () => {
    let referrals = activityFeed.filter((card) => card.type === "referral");

    if (referrals) {
      let defaultReferral = referrals[0];
      setDefaultReferral(defaultReferral);
      setReferralDefaultView(true);
    }
  };

  const handleServiceReqClick = () => {
    setPane("referrals");
    readFeedType("needs-request");
    readFeedType("referral");
  };

  const handleCareTeamClick = () => {
    setPane("careTeam");
    readFeedType("care-team");
  };

  return (
    <S.Container>
      <S.InnerContainer>
        <FeedPanel>
          {(initialLoading || feedLoading) && <Loading></Loading>}
          <FeedButton
            data-id={`feed-recent-tab`}
            theme={{
              ...theme,
              isActive: pane === "mostRecent",
            }}
            kind={pane === "mostRecent" ? "secondary" : "menu"}
            onClick={() => setPane("mostRecent")}
            to="/feed"
          >
            Recent Activity
          </FeedButton>
          <FeedButton
            data-id={`feed-referrals-tab`}
            theme={{
              ...theme,
              isActive: pane === "referrals",
            }}
            onClick={handleServiceReqClick}
            to="/feed/referrals"
          >
            {numberUnread.referral > 0 && (
              <Notification
                quantity={numberUnread?.referral || 0}
              />
            )}
            Service Requests
          </FeedButton>
          <FeedButton
            data-id={`feed-care-team-tab`}
            theme={{
              ...theme,
              isActive: pane === "careTeam",
            }}
            onClick={handleCareTeamClick}
            to="/feed/care-team"
          >
            {(numberUnread["care-team"] > 0 ||
              numberUnread["access"] > 0 ||
              numberUnread["service-cap"] > 0) && (
              <Notification
                quantity={
                  numberUnread["care-team"] ||
                  0 + numberUnread["access"] ||
                  0 + numberUnread["service-cap"] ||
                  0
                }
              />
            )}
            Care Team
          </FeedButton>
        </FeedPanel>
        <S.Content>
          <Switch>
            <Route path="/feed/referrals" exact>
              {activityFeed.filter((card) => card.type === "referral")
                .length === 0 ? (
                <Empty />
              ) : (
                <Referral
                  services={services}
                  referralDefaultView={referralDefaultView}
                  careTeam={careTeam}
                  loadingCareTeam={loading}
                  currentReferralLoading={currentReferralLoading}
                  setCurrentReferralLoading={setCurrentReferralLoading}
                  userOrgName = {userOrgName}
                />
              )}
            </Route>
            <Route path="/feed/care-team" exact>
              {activityFeed.filter((card) => card.type === "care-team")
                .length === 0 ? (
                <Empty />
              ) : (
                activityFeed
                  .filter((card) => card.type === "care-team")
                  .sort((a, b) => new Date(b.date) - new Date(a.date))
                  .map((cardData, index) => {
                    let onTeam = false;
                    if (careTeam?.find((p) => cardData.senderId === p.id)) {
                      onTeam = true;
                    }
                    return (
                      <CareCard
                        loading={loading}
                        careTeam={onTeam}
                        key={`care-card-${cardData.id}`}
                        clearActivity={clearActivity}
                        cardData={cardData}
                        setActivityInactive={setActivityInactive}
                      />
                    );
                  })
              )}
            </Route>
            <Route path="/feed">
              {activityFeed.filter((card) => card.type !== "recommendedBadge")
                .length === 0 ? (
                <Empty />
              ) : (
                activityFeed
                  .sort((a, b) => new Date(b.date) - new Date(a.date))
                  .map((cardData) => {
                    let onTeam = false;
                    let onTeamNeeds = false;
                    let inbound = false;
                    if (careTeam?.find((p) => cardData.senderId === p.id && cardData.serviceAtLocationKey === p.serviceAtLocationKey)) {
                      onTeam = true;
                    }
                    if (careTeam?.find((p) => cardData.senderId === p.id && cardData.serviceAtLocationKey === p.serviceAtLocationKey)) {
                      onTeamNeeds = true;
                    }
                    if (
                      services?.find((p) => cardData.serviceAtLocationKey == p.serviceAtLocationKey)
                    ) {
                      inbound = true;
                    }
                    return cardData.type === "referral" ? (
                      <ReferralCard
                        key={`feed-card-${cardData.id}`}
                        clearActivity={clearActivity}
                        inbound={inbound}
                        cardData={cardData}
                        getLatestFeed={getLatestFeed}
                        setActivityInactive={setActivityInactive}
                        clearReferralActivity={clearReferralActivity}
                        setReferralActivityInactive={setReferralActivityInactive}
                        currentReferralLoading={currentReferralLoading}
                        setCurrentReferralLoading={setCurrentReferralLoading}
                        setReferralActivitiesInactive={setReferralActivitiesInactive}
                      />
                    ) : cardData.type === "needs-request" ? (
                      <NeedsRequestCard
                        key={`feed-card-${cardData.id}`}
                        loading={loading}
                        careTeam={onTeamNeeds}
                        clearActivity={clearActivity}
                        cardData={cardData}
                        getLatestFeed={getLatestFeed}
                        setActivityInactive={setActivityInactive}
                      />
                    ) : cardData.type === "care-team" ? (
                      <CareCard
                        loading={loading}
                        careTeam={onTeam}
                        key={`feed-card-${cardData.id}`}
                        clearActivity={clearActivity}
                        cardData={cardData}
                        setActivityInactive={setActivityInactive}
                      />
                    ) : cardData.subType === "reminder" ? (
                      <ReminderCard
                        loading={loading}
                        cardData={cardData}
                        clearActivity={clearActivity}
                        key={`feed-card-${cardData.id}`}
                      />
                    ) : cardData.type === "earnedBadge" ? (
                      EarnedBadge
                    ) : cardData.type === "access" ? (
                      <AccessUpdateCard
                        loading={loading}
                        cardData={cardData}
                        clearActivity={clearActivity}
                        key={`feed-card-${cardData.id}`}
                      />
                    ) : (
                      ""
                    );
                  })
              )}
            </Route>
          </Switch>
        </S.Content>
      </S.InnerContainer>
    </S.Container>
  );
};

export default ActivityFeed;
