import { useEffect, useState } from "react";
import { useStrapi } from "../../hooks/useStrapi";

export const useFaqCMS = () => {
  const init = {
    heading: "Frequently Asked Questions (FAQs)",
    items: [
      {
        id: 4,
        title: "What is MyWayfinder?",
        content:
          "MyWayfinder is secure technology platform that helps individuals better understand their health and wellbeing. With MyWayfinder you can take assessments and locate providers who can support your journey to better health. As a provider you can view individuals 360 Dashboard once they have consented to add you to their care team."
      },
      {
        id: 5,
        title: "Can I use MyWayfinder anonymously?",
        content:
          "And it is! As an anonymous individual you can take needs assessments and receive recommended resources near your location. You can also use the resource map to find providers based on category of need."
      },
      {
        id: 6,
        title: "Why should I create a MyWayfinder account?",
        content:
          "With a MyWayfinder account you can share personal information and assessment information with your Care Team."
      },
      {
        id: 11,
        title: "What is a Care Team?",
        content:
          "Your Care Team consists of Service Providers who you have consented to share your 360 Dashboard with. These Service Providers use this information to better understand your needs and help you on your journey to better health and wellbeing."
      },
      {
        id: 12,
        title: "What is consent?",
        content:
          "On MyWayfinder you have control over who can see your data. Consent is the process of adding a provider to your Care Team so that they can better serve you. You can always add or remove providers from your Care Team."
      },
      {
        id: 13,
        title: "How do I delete my account?",
        content:
          'You can delete your account by navigating to the profile page via the account icon in the top right corner (on a desktop computer) or within the menu icon (on a mobile device). Once you are in the profile section navigate to "Consents" and click the large red "Deactivate MyWayfinder Account" button.'
      },
      {
        id: 7,
        title: "Can I share some feedback?",
        content:
          " There is! We value your feedback and will work to incorporate it in future releases. Share your feedback [here]."
      },
      {
        id: 14,
        title: "Is there a cost to use MyWayfinder?",
        content:
          "There is no cost for individuals and families to use MyWayfinder."
      },
      {
        id: 15,
        title: "Who can see my personal information?",
        content:
          "Each user can control who can see their personal information. Using your Dashboard, you will be able to invite Care Team members to view your Dashboard and choose what you want them to see."
      },
      {
        id: 16,
        title:
          "Is there a way to access MyWayfinder if I don't have a computer or smart phone?",
        content:
          "We are in the process of working with providers to create Community Hubs where people can access MyWayfinder on their own or staff and volunteers can help individuals and families navigate the information available on MyWayfinder."
      },
      {
        id: 17,
        title: "Will you use my information without my permission?",
        content:
          "Personal identifiable information will not be shared with anyone you do not provide access to (consent). Anonymous data samples may be used to respond to help support our overall community health, but your name and any personal identifiable information will not used."
      }
    ],
    background_image: {small: {url:""}, medium: {url: ""}, large: {url: ""}}
  };
  const { data, loading } = useStrapi({
    cms: `page-faq`
  });

  const [faq, setFaq] = useState(init);

  useEffect(() => {
    if (loading) {
      setFaq(init);
      return;
    }
    setFaq((state) => ({
      heading: data.heading || state.heading,
      items: data.items || state.items,
      background_image: data.background_image?.data?.attributes?.formats || state.background_image
    }));
  }, [data]);

  return { faq };
};
