import React, { useState, useEffect, useContext } from "react";
import { useIdleTimer } from "react-idle-timer";
import kit from "ich-ui-kit";
import { Modal } from "carbon-components-react";
import { useTimeoutCMS } from "./cms";

export const IdleTimer = ({ setLoadingLogout, channel}) => {
  const { cms } = useTimeoutCMS();
  const { user, clearUser, updateUser } = useContext(kit.UserContext);
  const { apiService } = useContext(kit.ApiContext);

  const minutesUntilTimeout = 60; //60 minutes - time it will take for user to receive timeout warning message
  const timeoutCountdownSeconds = 120; // 2 minutes - time between timeout warning message and being logged out
  //  60 - 2 = 58, time it will take for user to receive timeout warning message

  // milliseconds * seconds * minutes
  const timeout = 1000 * 60 * minutesUntilTimeout; // converts minutesUntilTimeout into milliseconds
  const promptBeforeIdle = 1000 *  timeoutCountdownSeconds * 1; // converts timeoutCountdownSeconds into milliseconds 
  const [remaining, setRemaining] = useState(timeout); 
  const [modalOpen, setModalOpen] = useState(false);
  const [warningModalOpen, setWarningModalOpen] = useState(false);
  const [leader, setLeader] = useState(true);

  const millisToSeconds = (millis) => {
    return Math.floor(millis/1000);
  }

  function millisToMinutesAndSeconds(millis) {
    let minutes = Math.floor(millis / 60000);
    let seconds = Math.floor((millis % 60000) / 1000);
    let display = ""
    let text_minutes = minutes === 1 ? cms.text_minutes : `${cms.text_minutes}s`;
    if(minutes > 0) {
      display += ` ${minutes} ${text_minutes} ${cms.text_and} `
    }
      display += seconds;
    return display;
  }
  
  const handleOnActive = () => {
    // check if the use change password?
  };

  useEffect(() => {
    if (
      Object.keys(user).filter((key) => key !== "notifications").length !== 0
    ) {
      start();
    } else {
      pause();
    }
  }, [user]);

  useEffect(() => {
      setInterval(() => {
        setRemaining(getRemainingTime());
      }, 1000);
  }, []);

  const handleOnIdle = async () => {
    if(user && user.idToken) {
      clearUser();
      sessionStorage.removeItem("penn-provider")
      channel.postMessage({ key: "logout" }); // Notify other tabs to log out
      localStorage.clear()
      pause();
      setWarningModalOpen(false);
      setModalOpen(true);
      if (leader) {
        if (user.superAssumedRole?.leadId) {
          await apiService.post(`/iam/assume-role`, {
            orgId: user.superAssumedRole.orgId,
            orgName: user.superAssumedRole.orgName,
            userId: user.superAssumedRole.leadId,
            role: user.superAssumedRole.role,
            action: 'logout'
          });
        }
      } else {
          setTimeout(() => {
            window.location.href = '/login';  
          }, "4000")   
      }
    }
    setModalOpen(false);     
  };

  const onPrompt = () => {
    if(user && user.idToken) {
      setWarningModalOpen(true);
    }
  };

  const onMessage = async (data) => {
    switch (data.action) {
      case 'LOGOUT_USER':
        clearUser();
        channel.postMessage({ key: "logout" }); // Notify other tabs
        sessionStorage.removeItem("penn-provider");
        localStorage.clear()
        pause();
        if (user.superAssumedRole?.leadId) {
          await apiService.post(`/iam/assume-role`, {
            orgId: user.superAssumedRole.orgId,
            orgName: user.superAssumedRole.orgName,
            userId: user.superAssumedRole.leadId,
            role: user.superAssumedRole.role,
            action: 'logout'
          });
        }
        setModalOpen(false);
        setWarningModalOpen(false);
        window.location.href = "/login";
        break
      case 'STILL_HERE':
        setWarningModalOpen(false)
        activate();
        break
      default:
    }
  }

  const { getRemainingTime, activate, start, pause, isPrompted, message } = useIdleTimer({
    timeout,
    promptBeforeIdle,
    onPrompt,
    onMessage,
    onActive: handleOnActive,
    onIdle: handleOnIdle,
    crossTab: true,
    syncTimers: 200
  });

  const handleStillHere = () => {
    setWarningModalOpen(false)
    message({ action: 'STILL_HERE' }, true)
  }

  const handleLogout = () => {
    clearUser();
    setModalOpen(false);
    setWarningModalOpen(false);
    message({ action: 'LOGOUT_USER' }, true)
  }

  return (
    <div>
      <Modal
        data-id="session-timeout-modal"
        modalAriaLabel={cms.text_expired_session}
        modalHeading={`${cms.text_expired_session}!`}
        aria-hidden="false"
        passiveModal
        open={modalOpen}
        onRequestClose={handleLogout}
      >
        <hr />
        <h3>
          You're being timed out due to inactivity.
          <br />
          You'll now be redirected to the login page.
        </h3>
      </Modal>
      <Modal
        data-id="session-timeout-warning-modal"
        aria-label={cms.text_session_timeout_warning}
        modalHeading={cms.text_warning_heading}
        aria-hidden="false"
        open={warningModalOpen}
        size="md"
        onRequestClose={handleStillHere}
        primaryButtonText={
          <span>
            {cms.button_warning_continue}
            <span aria-hidden={true}>
              {`, ${cms.button_warning_continue_description}`}
            </span>
            <span className="srOnly">
              {` ${cms.button_warning_continue_description_aria}`}
            </span>
          </span>
        }
        secondaryButtonText={
          <span>
            {cms.button_warning_exit}
            <span aria-hidden={true}>
              {`, ${cms.button_warning_exit_description}`}
            </span>
            <span className="srOnly">
              {` ${cms.button_warning_exit_description_aria}`}
            </span>
          </span>
        }
        closeButtonLabel={
          `Close popup, ${cms.button_warning_continue_description}`
        }
        onSecondarySubmit={handleLogout}
        onRequestSubmit={handleStillHere}
        preventCloseOnClickOutside
>
        <hr />
        {cms.text_warning.replace(
            "[remaining_time]",
            millisToMinutesAndSeconds(remaining)
          )}
      </Modal>
    </div>
  );
};
