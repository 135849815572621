export const hispanicItems = () => {
  const init = [
    {
      id: 70,
      show: "Select Hispanic Origin",
      text: "Select Hispanic Origin",
      key: "default_hispanic"
    },
    {
      id: 32,
      show: "No, Not of Hispanic Origin",
      text: "Not of hispanic origin",
      key: "not_of"
    },
    {
      id: 35,
      show: "Yes, Puerto Rican",
      text: "Puerto Rican",
      key: "puerto_rican"
    },
    {
      id: 36,
      show: "Yes, Cuban",
      text: "Cuban",
      key: "cuban"
    },
    {
      id: 34,
      show: "Yes, Mexican, Mexican American, Chicano",
      text: "Yes, Mexican, Mexican American, Chicano",
      key: "mex_american"
    },
    {
      id: 31,
      text: "Yes, Not Listed",
      show: "Not Listed(Specify)",
      key: "not_listed"
    }
  ];
  return init;
};
