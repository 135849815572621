import styled from "styled-components";
import { Button } from "carbon-components-react";
import { ChevronRight24 } from "@carbon/icons-react";

export const Wrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  padding-top: 2rem;
  padding-left: 0.5rem;
  padding-right: 8rem;
  @media screen and (min-height: 880px) and (min-width: 1800px) {
    padding-top: 2.5rem;
  }
  @media screen and (max-width: 768px)
  {
    margin-top: 0rem;
    padding-right: 0rem;
    height: auto;
    padding-top: 0rem;
  }
  
`;

export const ProgressBarWrapper = styled.div`
  margin-top: 3rem;
  margin-bottom: 3rem;
  overflow: visible;
  width: 130%;
  @media screen and (max-width: 768px){
    margin: 0rem 0rem 0rem 0.5rem;
    display: flex;
    justify-content: center;
    width: 100%;
  }
`;

export const TextWrapper = styled.div`
  justify-content: space-between;
  margin-top: 1rem;
  margin-bottom: 1rem;
  p {
    font-size: 18px;
    font-weight: 400;
  }
  h2 {
    font-weight: 500;
    line-height: 1;
    font-size: 22px;
  }
  h4 {
    font-weight: 300;
    line-height: 1.3;
    padding-top: 1.5rem;
    padding-bottom: 1rem;
  }
  @media screen and (min-height: 880px) and (min-width: 1600px) {
    p {
      font-weight: 400;
      font-size: 20px;
    }
  }
  @media screen and (max-width: 768px) {
    margin-bottom: 0rem;
    margin-top: 0.5rem;
    p {
      font-size: 14px;
      font-weight: 300;
    }
    h2 {
      font-weight: 300;
      line-height: 1;
      font-size: 14px;
    }
  }
`;

export const TextTitleContainer = styled.div`
  margin-top: 1rem;
  font-size: 18px;
  font-weight: 400;
`

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-left: 0.8rem;
  height: 35%;
  @media screen and (min-height: 880px) and (min-width: 1800px) {
    height: 30%;
    margin-top: 1rem;
  }
  @media screen and (max-width: 768px) {
    h4 {
      font-weight: 400;
      line-height: 1;
      font-size: 14px;
      margin-top: 0.25rem;
      margin-bottom: 0.5rem;
    }
  }
  `;

export const GoBackButton = styled.p`
  margin-left: 0.8rem;
  width: fit-content;
  color: yellowgreen;
  cursor: pointer;
  text-decoration: underline;
  @media screen and (max-width: 768px) {
    font-size: 12px;
    margin-top: 1rem;
  }
`;

export const ButtonWrapper = styled.div`
  width: fit-content;
  margin-top: 4.5rem;
  margin-bottom: 3rem;
  @media screen and (max-height: 900px) and (max-width: 600px) {
    margin-top: 30%;
    margin-bottom: 1rem;
  }
  @media screen and (max-width: 768px) {
    margin-top: 0.5rem;
    margin-bottom: 0rem;
  }
`;

export const StyledButton = styled(Button)`
  padding-right: 12px;
  @media screen and (max-width: 768px) {
    padding-right: 6px;
  }
`;

export const InterfaceContainer = styled.div`
  display: flex;
  height: 60%;
  width: 600px;
  @media screen and (min-height: 880px) and (min-width: 1800px) {
    /* height: 50%; */
  }
  @media screen and (max-width: 800px) and (max-height: 900px) {
    width: 100%;
    margin-top: 1rem;
  }
`;

export const StyledChevronIcon = styled(ChevronRight24)`
  margin-left: 2rem;
  height: 20px;
`;

export const StyledImg = styled.img`
  width: 100%;
  height: 100%;
`;