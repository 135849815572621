import React from "react";
import {
  DataTable,
  Table,
  TableHead,
  TableRow,
  TableHeader,
  TableBody,
  TableCell,
  TableToolbarContent,
  TableToolbarSearch,
  TableToolbar
} from "carbon-components-react";
import Empty from "../../../../components/Empty";
import * as S from "./careTeam.styled";
import { convertCellToDateString } from "./helpers";
import { constructServiceOverviewUrl } from "../../../../utils";

const CurrentCareTeam = ({ currentCareTeam }) => {
  const headers = [
    {
      key: "service_name",
      header: "Service Name"
    },
    {
      key: "org_name",
      header: "Organization"
    },
    {
      key: "openReferrals",
      header: "Open Referrals"
    },
    {
      key: "closedReferrals",
      header: "Closed Referrals"
    },
    {
      key: "startDate",
      header: "Start Date"
    },
    {
      key: "id"
    },
    {
      key: "org_id"
    },
    {
      key: "orgSource"
    },
    {
      key: "serviceId"
    },
    {
      key: "locationId"
    },
    {
      key: "serviceAtLocationId"
    }
  ];

  return (
    <S.Content>
      {currentCareTeam && currentCareTeam.length > 0 ? (
        <DataTable rows={currentCareTeam} headers={headers}>
          {({
            rows,
            headers,
            getTableProps,
            getHeaderProps,
            getRowProps,
            onInputChange
          }) => (
            <>
              <TableToolbar>
                <TableToolbarContent>
                  <TableToolbarSearch
                    onChange={onInputChange}
                    placeholder={"Search"}
                    persistent={true}
                    style={{ border: "1px solid #c6c6c6", maxWidth: "30rem" }}
                  />
                </TableToolbarContent>
              </TableToolbar>
              <Table {...getTableProps()}>
                <TableHead>
                  <TableRow>
                    {headers.map((header, idx) => {
                      if (idx > 4) return;
                      return (
                        <TableHeader {...getHeaderProps({ header })}>
                          {header.header}
                        </TableHeader>
                      );
                    })}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows.map((row, i) => {
                    if (row.id !== undefined)
                      return (
                        <TableRow
                          aria-label={row.cells[0]}
                          key={Math.random()}
                          {...getRowProps({ row })}
                        >
                          {row.cells.map((cell, idx) => {
                            if (idx > 4) return;
                            return (
                              <TableCell aria-label={cell.value} key={Math.random()}>
                                {cell.info.header !== "service_name" ? (
                                  convertCellToDateString(cell.value)
                                ) : (
                                  <>
                                    <a
                                      href={constructServiceOverviewUrl(
                                        row.cells[10].value,
                                        row.cells[7].value
                                      )}
                                      rel="noreferrer noopener"
                                      target="_blank"
                                      style={{
                                        cursor: "pointer",
                                        textDecoration: "none"
                                      }}
                                    >
                                      {cell.value}
                                    </a>
                                  </>
                                )}
                              </TableCell>
                            );
                          })}
                        </TableRow>
                      );
                  })}
                </TableBody>
              </Table>
            </>
          )}
        </DataTable>
      ) : (
        <Empty
          src="/icons/collaboration.svg"
          text=" Care Team Empty."
          btn={<></>}
          style={{
            minHeight: "60vh",
            marginTop: "1rem"
          }}
        />
      )}
    </S.Content>
  );
};

export default CurrentCareTeam;
