import styled from "styled-components";
import { Flex } from "../../index.styled";

const OrgOverviewCards = ({
    referrals,
    lowestcompleted,
    mostReferrals,
    highestNPS
}) => {


    return (
        <>
            <CardContainer>
                <FlatCard>
                    <CardTitle>Most Referrals</CardTitle>
                    <Flex style={{alignItems: "baseline", justifyContent: "space-between"}}>
                        <CardValue>{mostReferrals || "--"}</CardValue>
                    </Flex>
                </FlatCard>
                <FlatCard>
                    <CardTitle>Highest NPS</CardTitle>
                    <CardValue>{highestNPS || "--"}</CardValue>
                </FlatCard>
                <FlatCard>
                    <CardTitle>Lowest Completion Time</CardTitle>
                    <Flex style={{alignItems: "baseline", justifyContent: "space-between"}}>
                        <CardValue>{lowestcompleted || "--"}</CardValue>
                    </Flex>
                </FlatCard>
            </CardContainer>
        </>
    )
}

const CardContainer = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr; 
    gap: 6px; 
    margin-bottom: 1rem;
    @media screen and (min-width: 900px){
        grid-template-columns: 0fr 1fr 1fr 2fr 2fr; 
        gap: 12px; 
        margin-bottom: 1.5rem;
    }
`;

const FlatCard = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    height: 10rem;
    margin: auto;
    background-color: white;
    padding: 0.5rem;
    border-radius: 2px; /* 2px rounded corners */
    -webkit-box-shadow: 2px 2px 5px 2px rgba(0,0,0,0.15); 
    box-shadow: 2px 2px 5px 2px rgba(0,0,0,0.15);
    transition: 0.2s;
    @media screen and (min-width: 900px){
        width: 20rem;
        height: 10rem;
        padding: 1rem
    }
    &:hover {
        background-color: #F5F5F5;
        cursor: pointer;
    }
`;

const CardTitle = styled.p`
  font-size: 12px;
  @media screen and (min-width: 500px){
      font-size: 14px;
  }
  @media screen and (min-width: 900px){
    font-size: 16px;
}
`;

const CardValue = styled.h2`
    font-weight: 500;
    margin-right: 4px;
    @media screen and (max-width: 500px){
        font-size: 14px;
      }
    @media screen and (min-width: 500px){
      font-size: 16px;
    }
    @media screen and (min-width: 700px){
      font-size: 18px;
    }
    @media screen and (min-width: 900px){
      font-size: 20px;
    }
`;

export default OrgOverviewCards;