import React, { useState, useEffect } from "react";
// eslint-disable-next-line import/no-extraneous-dependencies
import { useHistory } from "react-router";
import { FluidForm, ToastNotification } from "carbon-components-react";

import {
  ResetContainer,
  ResetFormBox,
  ResetFormContainer,
  ResetTitleBox,
  ResetButton,
  StyledTextInput,
  InputDiv,
} from "./components/styled";
import useForm from "../../hooks/useForm";
import useQuery from "../../hooks/useQuery";
import validate from "./validator";
import { CustomTooltip } from "./components/Tooltip";
import { shouldAutoComplete } from "../../utils";
import { useLoginCMS } from "../login/cms"; 

const ResetPasswordPage = () => {
  const { login: loginCMS } = useLoginCMS();
  const history = useHistory();
  const query = useQuery();
  const [passwordType, setPasswordType] = useState("password");
  const [confirmType, setConfirmType] = useState("password");
  const [showTool, setShowTool] = useState(false);
  const [context, setContext] = useState("");
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const { values, setValues, errors, isDisabled, setIsDisabled, handleChange } =
    useForm(validate);

  useEffect(() => {
    setValues({
      password: "",
      confirmPassword: "",
    });

    setIsDisabled({
      confirmPassword: true,
    });
  }, [setValues, setIsDisabled]);

  useEffect(() => {
    let contextFound = query.get("context");
    setContext(contextFound);
  }, []);

  const handleReset = async (e) => {
    let password = values.password.trim();
    let response = await fetch(
      process.env.REACT_APP_API_ENDPOINT + "/reset-confirmation",
      {
        method: "post",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          password: password,
          context: context,
        }),
      }
    );
    if (response.status === 200) {
      setSuccess(true);
      history.push("/login");
    } else {
      setError(true);
    }
  };

  const togglePasswordVisibility = () => {
    setPasswordType(passwordType === "password" ? "text" : "password");
  };

  const toggleConfirmVisibility = () => {
    setConfirmType(confirmType === "password" ? "text" : "password");
  };

  return (
    <ResetContainer>
      <ResetFormBox>
        {success && (
          <ToastNotification
            kind="success"
            title="Password Reset"
            caption="You will now be redirected to the Login page"
            style={{ minWidth: "30rem", marginBottom: ".5rem" }}
          />
        )}
        {error && (
          <ToastNotification
            kind="error"
            title="Invalid Password"
            caption="Your new password must meet requirements and cannot be one you've recently used. Please try again."
            style={{ minWidth: "30rem", marginBottom: ".5rem" }}
          />
        )}
        <ResetFormContainer>
          <h2>Reset Password</h2>
          <p>
            Your new password must meet requirements and be completely different
            from any recently used password.
          </p>
          <FluidForm style={{ width: "500px" }}>
            <InputDiv>
              <div style={{ marginLeft: "15rem" }}>
                <CustomTooltip
                  errors={errors.password || []}
                  open={showTool}
                  showIcon={false}
                  align="end"
                  direction="top"
                />
              </div>
              <StyledTextInput.ControlledPasswordInput
                type={passwordType}
                togglePasswordVisibility={togglePasswordVisibility}
                id="password"
                labelText="Password"
                onChange={handleChange}
                tooltipAlignment={"end"}
                onClick={() => setShowTool(true)}
                onBlur={() => setShowTool(!showTool)}
                onFocus={() => setShowTool(!showTool)}
                autoComplete={shouldAutoComplete}
              ></StyledTextInput.ControlledPasswordInput>

              <StyledTextInput.ControlledPasswordInput
                type={confirmType}
                togglePasswordVisibility={toggleConfirmVisibility}
                id="confirmPassword"
                labelText="Confirm Password"
                onChange={handleChange}
                invalid={!!errors.confirmPassword}
                invalidText={errors.confirmPassword}
                tooltipAlignment={"end"}
                autoComplete={shouldAutoComplete}
              />

              <ResetButton
                disabled={isDisabled.confirmPassword}
                onClick={handleReset}
              >
                Reset Password
              </ResetButton>
            </InputDiv>
          </FluidForm>
        </ResetFormContainer>
      </ResetFormBox>
      <ResetTitleBox
        bgImage={
          loginCMS.background_image
            ? `${process.env.REACT_APP_STRAPI_ENDPOINT}${
                window.innerWidth < 400
                  ? loginCMS.background_image?.small?.url
                  : window.innerWidth < 600
                  ? loginCMS.background_image?.medium?.url
                  : loginCMS.background_image?.large?.url
              }`
            : ""
        }
      ></ResetTitleBox>
    </ResetContainer>
  );
};

export default ResetPasswordPage;
