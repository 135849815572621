import React, { useState, useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Loading } from "carbon-components-react";
import kit from "ich-ui-kit";

import NeedsAssesmentModal from '../../components/NeedsAssessmentModal';
// import AddCareTeamModal from '../../components/AddCareTeamModal/index.jsx';
// import AnonymousModal from '../../components/AnonymousModal/index.jsx';
import { AssistanceRequestModal } from "../../components/AssistanceRequestModal";
import { EligableBadge } from '../../components/Badges/badges';
import ProviderCard from '../../components/ServiceCard';
import MapBox from '../../components/Map/map';
import { SearchProvider } from '../resources/context/SearchResultsContext.context';
// import RemoveFromCareTeam from '../../components/ProviderCardsRemoveCareTeamModal';
import UnderConstruction from '../under-construction';
import SingleService from './components/single-service';
import useQuery from '../../hooks/useQuery';
import { parseHiddenText, removeHtml } from '../../utils';

import { useServiceInfoCMS, useServiceDirectoryCMS } from './cms';

import S from './serviceinfo.module.scss'

// TODO: error message if id is not passed
// TODO: error message if service is not found

const ServiceInfoPage = () => {
  // Foundational
  const query = useQuery();
  const history = useHistory();
  const { serviceInfo: serviceInfoCms } = useServiceInfoCMS();
  const { serviceDirectory: serviceDirectoryCms } = useServiceDirectoryCMS();
  const { apiService } = useContext(kit.ApiContext);
  const { user } = useContext(kit.UserContext);
  const addToCareTeam = query.get('addToCareTeam');
  const serviceAtLocationId = query.get('id');
  const orgSource = query.get('orgSource');
  const referralId = query.get('referralId');
  const senderId = query.get('sender');
  const domain = query.get('needType');
  const cardId = query.get('cardId');

  // States
  const [careTeamModal, setCareTeamModal] = useState(addToCareTeam);
  const [loading, setLoading] = useState();
  const [loadingCareTeam, setLoadingCareTeam] = useState();
  const [loadingBookmarks, setLoadingBookmarks] = useState(false);
  const [eligable, setEligable] = useState(false);
  const [careTeam, setCareTeam] = useState([]);
  const [service, setService] = useState({});
  const [loggedIn, setLoggedIn] = useState(false);
  const [needsModal, setNeedsModal] = useState(false);
  const [isUsingActions, setIsUsingActions] = useState(false);
  const [newCareTeam, setNewCareTeam] = useState();
  const [openRemoveFromCareTeam, setOpenRemoveFromCareTeam] = useState(false);
  const [careTeamRemoved, setCareTeamRemoved] = useState(false);
  const [bookmarked, setBookmarked] = useState();
  const [providerBookmarked, setProviderBookmarked] = useState(service?.bookmarked || false);
  const [isCNResourceModalOpen,setIsCNResourceModalOpen] = useState(false);
  const [bookmarkLoading, setBookmarkLoading] = useState(false);
  const [anonymousModal, setAnonymousModal] = useState(false);
  const [anonymousModalSelection, setAnonymousModalSelection] = useState(false);

  // useEffects
  useEffect(() => {
    if (user && user.id) {
      setLoggedIn(true);
    }
  }, [user]);

  useEffect(() => {
    const getServiceInfo = async () => {
      try {
        setLoading(true);
        if (
          serviceAtLocationId === "" ||
          serviceAtLocationId === "null" ||
          serviceAtLocationId === "undefined"
        ) throw new Error('orgID is empty'); // TODO: show error screen
        const postUrl = user.id ? `/search/service` : `/search/public/service`;
        const _service = await apiService.post(
          `${postUrl}?serviceAtLocationId=${serviceAtLocationId}&orgSource=${
            orgSource || "211"
          }`
        );

        if (_service) {
          setService(_service);
        }
        setLoading(false);
      } catch (err) {
        console.log(err);
      }
    };
    getServiceInfo();
  }, [serviceAtLocationId, orgSource, user]);

  useEffect(() => {
    const getCareTeam = async () => {
      try {
        setLoadingCareTeam(true);
        const data = await apiService.get('/careteam');
        if (data && data.cTeam && data.cTeam.length > 0) {
          setCareTeam(data.cTeam.map(({ serviceId, locationId }) => `${serviceId}|${locationId}`));
        }
      } catch (err) {
        console.log(err);
      }
      setLoadingCareTeam(false);
    };
    getCareTeam();
  }, [apiService, user]);

  useEffect(() => {
    const addToCareTeam = () => {
      if (newCareTeam && !careTeam?.includes(`${newCareTeam?.serviceId}|${newCareTeam?.locationId}`)) {
        const temp = careTeam;
        temp.push(`${newCareTeam?.serviceId}|${newCareTeam?.locationId}`);
        setCareTeam(temp);
        setNewCareTeam();
      }
    };
    addToCareTeam();
  }, [newCareTeam]);

  useEffect(() => {
    const removeFromCareTeam = () => {
      if (careTeamRemoved && careTeam?.includes(`${careTeamRemoved?.serviceId}|${careTeamRemoved?.locationId}`)) {
        const result = careTeam.filter(
          (ids) => ids !== `${careTeamRemoved?.serviceId}|${careTeamRemoved?.locationId}`
        );
        setCareTeam(result);
      }
    };
    removeFromCareTeam();
  }, [careTeamRemoved]);

  // Functions

  // Bookmark add and remove functions
  const addBookmark = async () => {
    try {
      setBookmarkLoading(true);
      const { orgSource } = await apiService.post(`/bookmarks/providers`, {
        serviceId: service.serviceId,
        orgSource: service.orgSource,
        locId: service.locationId
      });
      if (orgSource) {
        setProviderBookmarked(true);
        // broadcastEventToResourcePage({
        //   provider: provider,
        //   eventType: "remove",
        // });
            }
      setBookmarkLoading(false);
    } catch (err) {
      console.log(err);
      setBookmarkLoading(false);
    }
  };

  const removeBookmark = async () => {
    try {
      setBookmarkLoading(true);
      const { orgSource } = await apiService.delete(`/bookmarks/providers`, {
        serviceId: service.serviceId,
        orgSource: service.orgSource,
        locId: service.locationId
      });
      if (orgSource) {
        setProviderBookmarked(false);
        // broadcastEventToResourcePage({
        //   provider: provider,
        //   eventType: "remove",
        // });
      }
      setBookmarkLoading(false);
    } catch (err) {
      console.log(err);
      setBookmarkLoading(false);
    }
  };

  const handleBookmarked = (_type) => {
    const cases = {
      add: () => {
        setBookmarked({ provider: service, eventType: "add" });
        addBookmark()
      },
      remove: () => {
        setBookmarked({ provider: service, eventType: "remove" });
        removeBookmark()
      },
    };

    cases[_type]();
  };

  const handleCareTeams = (_type) => {
    if (loggedIn) {
      if (_type === 'Add to care team') {
        setCareTeamModal(true);
      } else {
        setOpenRemoveFromCareTeam(true);
      }
    } else {
      setAnonymousModalSelection('care-team');
      setAnonymousModal(true);
    }
  };

  const handleNeedsRequest = (_type) => {
    setNeedsModal(true);
  };

  const handleCreateReferral = () => {
    history.push({
      pathname: '/referral',
      state: {
        orgId: service.orgId,
        serviceId: service.serviceId,
        patientId:
          history?.location.state?.patientId ||
          history.state?.patientId || null,
        referralType: 'Referral Type',
        // referralType: dom || 'Referral Type',
        orgSource: service.orgSource,
        locationId: service.locationId,
        c360_orgId: service.c360_orgId,
        domains: service.domains,
        serviceAtLocationKey: service.serviceAtLocationKey
      }
    });
    return;
  };

  const handleViewReferral = () => {
    history.push({
      pathname: '/360dashboard/service-requests/view',
      state: {
        client:
          history?.location.state?.patientId ||
          history.state?.patientId || null,
        referralId: null,
        //TODO referralId: activeReferralId, 
        cameFrom: 'patient',
        onCareTeam: true
      }
    });
    return;
  };

  const handleCNResource = (_type) => {
    setIsCNResourceModalOpen(true);
  };
  const handleCNResourceModalClose = (_type) => {
    setIsCNResourceModalOpen(false);
  }


  // Etc
  let provURL = service.url ? service.url.toLowerCase() : null;

  if (provURL && provURL.indexOf('.') > 0 && provURL.indexOf('.') < 4) {
    provURL = 'https://' + provURL;
  }

  return (
    <SearchProvider>
      {(loading || loadingCareTeam || loadingBookmarks) && <Loading />}
      {/* {!loading && (
        <AddCareTeamModal
          careTeamModal={careTeamModal}
          setCareTeamModal={setCareTeamModal}
          provider={service}
          senderId={senderId}
          referralId={referralId}
          providerID={service?.serviceId}
          need={domain}
          cardId={cardId}
          setNewCareTeam={setNewCareTeam}
        />
      )}
      <AnonymousModal
        open={anonymousModal}
        setOpen={setAnonymousModal}
        anonymousModalSelection={anonymousModalSelection}
        setAnonymousModalSelection={setAnonymousModalSelection}
        serviceName={service?.name || ''}
        providerName={service?.orgName || ''}
        orgEmail={service.orgEmail}
        serviceId={service?.serviceId}
        orgSource={service?.orgSource}
        orgKey={service?.orgId}
        isConnected={service?.connected}
        locationId={service.locationId}
        c360_orgId={service.c360_orgId}
        domains={service.domains}
      />
      <RemoveFromCareTeam
        open={openRemoveFromCareTeam}
        setOpen={setOpenRemoveFromCareTeam}
        selectedProvider={service}
        setCareTeamRemoved={setCareTeamRemoved}
      /> */}
      <NeedsAssesmentModal
        open={needsModal}
        setOpen={setNeedsModal}
        modalHeading={`Needs Request for ` + service?.orgName}
        modalLabel={'Do you want someone to contact you?'}
        providerName={service?.orgName || ''}
        serviceName={service?.name || ''}
        orgEmail={service?.orgEmail}
        serviceId={service?.serviceId}
        orgSource={service?.orgSource}
        locationId={service?.locationId}
      />
      <AssistanceRequestModal
        open={isCNResourceModalOpen}
        onClose={handleCNResourceModalClose}
        serviceName={service?.name || ""}
        providerName={service?.orgName || ""}
        orgEmail={service.orgEmail}
        serviceId={service?.serviceId}
        orgSource={service?.orgSource}
        orgKey={service?.orgId}
        locationId={service?.locationId}
      />

      <div className={S.pageContainer}>
      {loading ? (
          <Loading />
        ) : (
          <SingleService
            service={service}
            serviceInfoCms={serviceInfoCms}
            careTeam={careTeam}
            cms={{...useServiceInfoCMS, ...serviceDirectoryCms}}
            loggedIn={loggedIn}
            provURL={provURL}
            handleCareTeams={handleCareTeams}
            handleNeedsRequest={handleNeedsRequest}
            providerBookmarked={providerBookmarked}
            setBookmarked={setBookmarked}
            handleBookmarked={handleBookmarked}
            handleCreateReferral={handleCreateReferral}
            handleViewReferral={handleViewReferral}
            handleCNResource={handleCNResource}
          />
        )}
      </div>
    </SearchProvider>
  );
};

export default ServiceInfoPage;
