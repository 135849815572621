import kit from "ich-ui-kit";
import React, { useContext, useEffect, useState } from "react";
import validate from "../components/UIShell/components/call-log-panel/validator";
import useForm from "../hooks/useForm";
import { parseHtml } from "../utils";

export const CallLogContext = React.createContext([]);

export const CallLogProvider = ({ children }) => {
  const [clearForm, setClearForm] = useState(false);
  const [activeLog, setActiveLog] = useState(
    !!window.localStorage.getItem("sip-call-log-start") ? true : false
  );
  const [isSuccess, setIsSuccess] = useState(false);
  const [openCallLog, setOpenCallLog] = useState(false);
  const [indvHasAccount, setIndvHasAccount] = useState(false);
  const [shouldNotSendResources, setShouldNotSendResources] = useState(false);
  const [inputVal, setInputVal] = useState("");
  const [preferredLanguage, setPreferredLanguage] = useState("English");
  const [inputType, setInputType] = useState("Email");
  const [client, setClient] = useState(null);
  const [loading, setLoading] = useState(null);
  const [resources, setResources] = useState([]);
  const [isIntakeSubmitted, setIsIntakeSubmitted] = useState(false);
  const [resourceEvent, setResourceEvent] = useState();
  const { values, setValues, handleChange, errors } = useForm(validate);
  const { apiService } = useContext(kit.ApiContext);

  useEffect(() => {
    if (
      Object.keys(values).length === 0 &&
      !!window.localStorage.getItem("sip-call-log-form")
    ) {
      let cachedValues = JSON.parse(
        window.localStorage.getItem("sip-call-log-form")
      );
      setValues(cachedValues);
    }

    if (
      Boolean(window.localStorage.getItem("sip-call-log-resources")) &&
      !Boolean(resources.length)
    ) {
      let cachedResources = JSON.parse(
        window.localStorage.getItem("sip-call-log-resources")
      );
      setResources(cachedResources);
    }

    if (
      window.localStorage.getItem("sip-call-log-preferred-language")
    ) {
      let cachedPreferredLanguage = window.localStorage.getItem("sip-call-log-preferred-language");
      setPreferredLanguage(cachedPreferredLanguage);
    } 
  }, []);

  useEffect(() => {
    // If form has more data than default field
    if (Object.keys(values).length > 1) {
      window.localStorage.setItem("sip-call-log-form", JSON.stringify(values));
    }
  }, [values]);

  useEffect(() => {
    if (!Boolean(resources.length)) {
      window.localStorage.removeItem("sip-call-log-resources");
      return;
    }
    window.localStorage.setItem(
      "sip-call-log-resources",
      JSON.stringify(resources)
    );
  }, [resources]);


  // ****** Helpers ******
  const resourceIndexInCart = (r) => {
    let foundIndex;
    foundIndex = resources.findIndex(
      ({ serviceId, locationId, c360_orgId }) =>
        serviceId === r.serviceId &&
        locationId === r.locationId &&
        c360_orgId === r.c360_orgId
    );

    return foundIndex;
  };

  const parseHtmlFromResources = (method, resources) => {
    // Parsing out html from several fields when sending by SMS only
    if (method === "Phone") {
      return resources.map((r) => {
        return {
          ...r,
          eligibility: parseHtml(r.eligibility),
          orgDesc: parseHtml(r.orgDesc),
          overview: parseHtml(r.overview)
        }
      })
    } else {
      return resources
    }
  }

  // *********************

  const initiateCallLog = () => {
    setActiveLog(true);
    window.localStorage.setItem("sip-call-log-start", new Date().toISOString());
  };

  const clearCallLog = (andClose = "andClose") => {
    setClearForm(true);
    if(andClose != "noClose"){
      setOpenCallLog(false);
      setActiveLog(false);
      window.localStorage.removeItem("sip-call-log-start");
    }
    window.localStorage.removeItem("sip-call-log-form");
    window.localStorage.removeItem("sip-call-log-resources");
    setValues({
      lineDesignation: "MyWayfinder"
    });
    setIndvHasAccount(false);
    setShouldNotSendResources(false);
    setInputVal("");
    setPreferredLanguage("");
    setInputType("Email");
    setClient(null);
    setLoading(null);
    setResources([]);
    setIsIntakeSubmitted(false);
  };

  const submitCallLog = async (e) => {
    e.stopPropagation();
    try {
      let cleanedResources = parseHtmlFromResources(inputType, resources);

      setLoading(true);
      const data = await apiService.post("/calllogs", {
        startDateTime: window.localStorage.getItem("sip-call-log-start"),
        endDateTime: new Date(),
        indvHasAccount,
        shouldNotSendResources,
        patientId: client?.id,
        resources: cleanedResources,
        form: { ...values },
        sendResourcesMethod: inputType,
        sendResourcesContact: inputVal,
        preferredLanguage: window.localStorage.getItem("sip-call-log-preferred-language"),
      });
      if (data.id) {
        setIsSuccess(true);
        setTimeout(() => {
          clearCallLog();
          setIsSuccess(false);
        }, 3000);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const isInCart = (r) => {
    let indx = resourceIndexInCart(r);
    if (indx >= 0) {
      return true;
    } else {
      return false;
    }
  };

  const updateResources = (r) => {
    // serviceId, locationId, and c360_orgId should be unique
    let foundIndex;
    let temp = [...resources];

    foundIndex = resourceIndexInCart(r);

    if (foundIndex >= 0) {
      // remove
      temp.splice(foundIndex, 1);
    } else {
      // add
      temp.push(r);
    }
    setResources(temp);
  };
  // o = orgId, l = locationId, s = serviceId
  const updateResourceNote = (note, o, l, s) => {
    let temp = [];
    temp = resources.map((r) => {
      if (r.c360_orgId === o && r.locationId == l && r.serviceId == s) {
        return { ...r, note: note };
      }
      return r;
    });

    setResources(temp);
  };

  const updateNeedsStatus = (status, o, l, s) => {
    let temp = [];
    temp = resources.map((r) => {
      if (r.c360_orgId === o && r.locationId == l && r.serviceId == s) {
        return { ...r, needsStatus: status };
      }
      return r;
    });

    setResources(temp);
  };

  return (
    <CallLogContext.Provider
      value={{
        values,
        setValues,
        activeLog,
        initiateCallLog,
        handleChange,
        indvHasAccount,
        setIndvHasAccount,
        shouldNotSendResources,
        setShouldNotSendResources,
        client,
        setClient,
        resources,
        updateResources,
        isInCart,
        setResources,
        errors,
        submitCallLog,
        loading,
        inputVal,
        setInputVal,
        preferredLanguage, 
        setPreferredLanguage,
        inputType,
        setInputType,
        isIntakeSubmitted,
        setIsIntakeSubmitted,
        updateResourceNote,
        updateNeedsStatus,
        openCallLog,
        setOpenCallLog,
        isSuccess,
        resourceEvent,
        setResourceEvent,
        clearCallLog,
        clearForm,
        setClearForm
      }}
    >
      {children}
    </CallLogContext.Provider>
  );
};

export const useClient = () => {
  const context = useContext(CallLogContext)
  if (context === undefined) {
    throw new Error('useCount must be used within a CountProvider')
  }
  return context;
}

export const CallLogConsumer = CallLogContext.Consumer;
