import styled from "styled-components";
import { Button } from "carbon-components-react";

// Invite
export const InputWrapper = styled.div`
  display: grid;
  @media screen and (min-width: 416px) and (max-width: 767px) {
    gap: 12px;
  }
  @media screen and (min-width: 767px) {
    grid-template-columns: 2fr 7fr 1fr;
  }
  margin: 1rem 0;
`;

export const StyledButton = styled(Button)`
  min-height: auto;
  width: fit-content;
  @media screen and (min-width: 767px) {
    padding: 0 1rem;
    width: auto;
  }
`;

export const ErrorText = styled.p`
    font-size: 14px;
    margin: 1rem 0;
    color: red;
`;

