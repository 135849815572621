import { appendClassName, getFilterValueDisplay } from "../view-helpers";
import { FilterContainer } from "../../resources/sidebar";

const CustomFacetView = ({
  className,
  label,
  onMoreClick,
  onRemove,
  onSelect,
  options,
  showMore,
  showSearch,
  onSearch,
  searchPlaceholder,
  transformDisplay,
  filterOptions
}) => {
  let _options = options;
  if (filterOptions) {
    _options = filterOptions(options)
  }

  return (
    <fieldset className={appendClassName("sui-facet", className)}>
      <div className="sui-multi-checkbox-facet">
        <FilterContainer heading={label || ""}>
          {/* Facet searchbox - shows if isFilterable = true */}
          {showSearch && (
            <div className="sui-facet-search">
              <input
                className="sui-facet-search__text-input"
                type="search"
                placeholder={searchPlaceholder || "Search"}
                onChange={(e) => {
                  onSearch(e.target.value);
                }}
              />
            </div>
          )}
          {_options.length < 1 && <div>No matching options</div>}
          {/* Facet options */}
          {_options
            .map((option) => {
              const checked = option.selected;
              const value = option.value;
              const displayValue = transformDisplay
                ? transformDisplay(option.value)
                : option.value;
              return (
                <label
                  key={`${getFilterValueDisplay(option.value)}`}
                  htmlFor={`example_facet_${label}${getFilterValueDisplay(
                    option.value
                  )}`}
                  className="sui-multi-checkbox-facet__option-label"
                >
                  <div className="sui-multi-checkbox-facet__option-input-wrapper">
                    <input
                      data-transaction-name={`facet - ${label}`}
                      id={`example_facet_${label}${getFilterValueDisplay(
                        option.value
                      )}`}
                      type="checkbox"
                      className="sui-multi-checkbox-facet__checkbox"
                      checked={checked}
                      onChange={() =>
                        checked ? onRemove(value) : onSelect(value)
                      }
                    />
                    <span className="sui-multi-checkbox-facet__input-text">
                      {option.value || option.value === 0
                        ? displayValue
                        : "Confidential Address"}
                    </span>
                  </div>
                  <span className="sui-multi-checkbox-facet__option-count">
                    {option.count.toLocaleString("en")}
                  </span>
                </label>
              );
            })}
        </FilterContainer>
      </div>

      {showMore && (
        <button
          type="button"
          className="sui-facet-view-more"
          onClick={onMoreClick}
          aria-label="Show more options"
        >
          + More
        </button>
      )}
    </fieldset>
  );
};

export default CustomFacetView;
