import React from "react";
import ReactMarkdown from "react-markdown";
import * as S from "./markdowncard.styled";
import { generateClickToCallLink } from '../../utils';

export const MarkdownCard = ({ id, heading, paragraphs }) => {
  return (
    <S.Container aria-label={'section-' + id} style={{ whiteSpace: "pre-wrap" }}>
      {/* SIPD-4017 | Accordion component to allow for collapsible and expandable content */}
      <S.StyledAccordion>
        <S.StyledAccordionItem title={heading ? heading : 'Details'}>
          <S.Text>
            {paragraphs &&
              (generateClickToCallLink(paragraphs) ? 
                <a href={generateClickToCallLink(paragraphs)}>{paragraphs}</a>
                : 
                <ReactMarkdown>{paragraphs}</ReactMarkdown>
              )
            }
          </S.Text>
        </S.StyledAccordionItem>
      </S.StyledAccordion>
    </S.Container>
  );
};
