import { useState, useEffect } from "react";
import styled from "styled-components";
import { Button, TextInput } from "carbon-components-react";
import * as S from "../Questions.styles";

const Transportation3 = ({
  cms,
  domain,
  width,
  setAllowContinue,
  transportationResponse,
  setTransportationResponse,
  setFeedbackIndex,
  setOverallIndex
}) => {
  const [buttonSelection, setButtonSelection] = useState();

  useEffect(() => {
    if (transportationResponse.q3.answer !== " ") {
      setButtonSelection(transportationResponse.q3.answer);
      setAllowContinue(false);
    } else {
      setAllowContinue(true);
    }
  }, []);

  const buttonLabels = [
    "$0 - $49.99",
    "$50 - $99.99",
    "$100 - $149.99",
    "$150 - $199.99",
    "$200 or More"
  ];

  return (
    <S.TextContainer>
      {/* <h4>Question 9 of 12</h4> */}
      <h5>{cms.transportation.question_3}</h5>
      <S.Wrapper>
        {width < 769 ? (
          <S.DropdownWrapper>
            <S.StyledDropdown
              ariaLabel="Dropdown"
              id="carbon-dropdown-example"
              items={buttonLabels}
              label={buttonSelection || "Select option..."}
              onChange={(e) => {
                // setAllowContinue(false);
                setButtonSelection(e.selectedItem);
                setTransportationResponse((state) => ({
                  ...state,
                  q3: {
                    text: "3 How much do you spend on transportation a month, including vehicle maintenance?",
                    answer: e.selectedItem
                  }
                }));
                // setFeedbackIndex((index) => index + 1);
                // setOverallIndex((index) => index + 1);
              }}
            />
          </S.DropdownWrapper>
        ) : (
          <S.ButtonGroup>
            {buttonLabels.map((label, i) => {
              let bcolor;
              {
                buttonSelection === label ? (bcolor = true) : (bcolor = false);
              }
              return (
                <S.StyledButton
                  key={i}
                  theme={{
                    bcolor: bcolor,
                    width: "98px",
                    padding: "1rem",
                    margin: "1rem"
                  }}
                  kind="tertiary"
                  onClick={() => {
                    // setAllowContinue(false);
                    setTransportationResponse((state) => ({
                      ...state,
                      q3: {
                        text: "3 How much do you spend on transportation a month, including vehicle maintenance?",
                        answer: label
                      }
                    }));
                    setButtonSelection(label);
                    // setFeedbackIndex((index) => index + 1);
                    // setOverallIndex((index) => index + 1);
                  }}
                >
                  {label}
                </S.StyledButton>
              );
            })}
          </S.ButtonGroup>
        )}
      </S.Wrapper>
    </S.TextContainer>
  );
};

export default Transportation3;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  @media screen and (min-width: 1800px) {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
  @media screen and (max-width: 768px) {
    height: 60%;
    overflow-y: scroll;
  }
`;

export const InputWrapper = styled.div`
  margin-top: 5%;
  display: flex;
  width: 40%;
  justify-content: center;
  h5 {
    margin-right: 0.25rem;
  }
  @media screen and (min-width: 1800px) {
    width: 35%;
  }
  @media screen and (max-width: 768px) {
    margin: 1rem 0rem 2rem 0rem;
    width: 50%;
    align-items: center;
  }
`;
