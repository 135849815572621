import React, { useState, useContext, useEffect } from "react";
import { Button, Checkbox, Dropdown, TextInput } from "carbon-components-react";
import kit from "ich-ui-kit";
import { useHistory } from "react-router-dom";
import AllClients from "../../../../../pages/new-landing-page/components/AllClients";
import { CallLogContext } from "../../../../../store/CallLogContext";
import { formatToPhone, validate } from "./helpers";
import * as S from "../callLogPanel.styled";
import { shouldAutoComplete } from "../../../../../utils";

const dropdownItems = ["Email", "Phone"];
const dropdownLanguages = ["English", "Spanish"];

const AccountTab = ({ setIndex, allClients, loading }) => {
  // History
  const history = useHistory();
  // Error Management
  const [isPhoneError, setIsPhoneError] = useState(false);
  const [isEmailError, setIsEmailError] = useState(false);
  const [preferredLanguage, setPreferredLanguage] = useState(dropdownLanguages[0]);
  // Context
  const {
    client,
    setClient,
    inputVal,
    setInputVal,
    inputType,
    setInputType,
    indvHasAccount,
    setIndvHasAccount,
    shouldNotSendResources,
    setShouldNotSendResources,
    setOpenCallLog
  } = useContext(CallLogContext);

  const handleInputChange = (e) => {
    if (inputType == "Phone") {
      formatToPhone(e);
      validate(e.target.value, inputType, setIsPhoneError, setIsEmailError);
      setInputVal(e.target.value);
    } else {
      validate(e.target.value, inputType, setIsPhoneError, setIsEmailError);
      setInputVal(e.target.value);
    }
  };

  const determineErrorText = () => {
    if (isEmailError && inputType == "Email")
      return "Please enter a valid email address: example@example.com";
    if (isPhoneError && inputType == "Phone")
      return "Please enter a valid phone number: (585) 555-5555";
  };

  const isButtonDisabled = () => {
    if (indvHasAccount) {
      return client === null;
    } else if(shouldNotSendResources) {
      return false;
    }
    else {
      return inputVal.length <= 5 || isPhoneError || isEmailError;
    }
  };
  useEffect(() => {
    window.localStorage.setItem("sip-call-log-preferred-language", preferredLanguage);
  }, [preferredLanguage])
  return (
    <S.AccountContainer>
      {indvHasAccount ? (
        <AllClients
          clientSelect
          loading={loading}
          clients={allClients}
          setClient={setClient}
          containerStyle={{ minHeight: "auto" }}
        />
      ) : (
        <div>
          <Dropdown
            id="contact-language-dropdown"
            titleText="Preferred Contact Language"
            label="Choose an option"
            items={dropdownLanguages}
            onChange={(e) => {
              const selectedLanguage = e.selectedItem || dropdownLanguages[0];
              setPreferredLanguage(selectedLanguage);
              window.localStorage.setItem("sip-call-log-preferred-language", e.selectedItem);
            }}
            initialSelectedItem={preferredLanguage || dropdownLanguages[0]}
          />
          <Dropdown
            id="contact-method-dropdown"
            titleText="Preferred Contact Method"
            label="Choose an option"
            items={dropdownItems}
            onChange={(e) => {
              setIsEmailError(false);
              setIsPhoneError(false);
              setInputVal("");
              setInputType(e.selectedItem);
            }}
            initialSelectedItem={inputType || dropdownItems[0]}
          />
          <S.TextEmailInputWrapper>
            <TextInput
              id="indv-contact"
              labelText="Enter email or phone"
              placeholder={
                inputType == "Email" ? "example@example.com" : "(585) 555-5555"
              }
              autocomplete={shouldAutoComplete}
              invalidText={determineErrorText()}
              invalid={isEmailError || isPhoneError}
              onChange={handleInputChange}
              value={inputVal}
            />
          </S.TextEmailInputWrapper>
        </div>
      )}
      <div>
        <S.InputWrapper>
          {indvHasAccount ? (
            <Checkbox
              id="has-mwf-account-checkbox"
              labelText="Individual has a MWF account"
              checked={indvHasAccount}
              onChange={(e) => {
                setClient(null);
                setIndvHasAccount(e);
              }}
            />
          ) : (
            <Checkbox
              id="has-mwf-account-checkbox"
              labelText="Individual does not want resources sent to them"
              checked={shouldNotSendResources}
              onChange={setShouldNotSendResources}
            />
          )}
        </S.InputWrapper>
        <Button
          onClick={(e) => {
            e.stopPropagation();
            setIndex(2);
            setOpenCallLog(false);
            history.push({
              pathname: "/service-directory",
              state: {
                activeCallLog: true
                //   clientId: location.state.patientId || client.id,
              }
            });
          }}
          disabled={isButtonDisabled()}
        >
          Save & Continue
        </Button>
      </div>
    </S.AccountContainer>
  );
};

export default AccountTab;
