import React, { useContext, useState, useEffect } from "react";
import styled from "styled-components";
import Badge from "./Badge";
import { InlineLoading } from "carbon-components-react";
import { useHistory } from "react-router-dom";
import kit from "ich-ui-kit";
import BadgeTracker from "./badgeTracker";
import {
  TitleRow,
  NotificationsGrid,
  SingleColumn,
  AccountTitleContainer,
  AccountTitleRow,
  AccountTitle,
  Card
} from "../../components";

import { Page } from "../dashboard.styled";

// This contains a helper function that maps
// respective `iconBadges` svg files to
// React components in order to render
// When adding new badges, add it to helper function too
import { svgToComponent, tooltipTexts, tooltipRedirects } from "./badgeHelpers";

function ProfileBadges() {
  const { user } = useContext(kit.UserContext);
  const [earnedBadges, setEarnedBages] = useState([]);
  const [availableBadges, setAvailableBages] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      const fetchBadges = fetch(
        user.superAssumedRole?.leadId ? 
        `${process.env.REACT_APP_API_ENDPOINT}/badges/provider?assumedId=${user.superAssumedRole?.leadId || ""}` : 
        `${process.env.REACT_APP_API_ENDPOINT}/badges/provider`,
        {
          method: "get",
          headers: {
            Authorization: `Bearer ${user.accessToken} ${user.idToken}`
          }
        }
      );
      const fetchBadgesJson = fetchBadges.then((res) => {
        return res.json();
      });
      fetchBadgesJson.then((data) => {
        setEarnedBages(data.badges.earned || []);
        setAvailableBages(data.badges.available || []);
        setLoading(false);
      });
    }
    return () => {
      isMounted = false;
    };
  }, []);

  return (
    <Page style={{paddingTop: "0"}}>
        <TitleRow>Badges</TitleRow>
          <SingleColumn>
            <div>
              <BadgeTracker
                completed={parseInt(((earnedBadges?.length || 0) / ((earnedBadges?.length || 0) + (availableBadges?.length || 0))) * 100)}
                bgColor="#72a94e"
                isLoading={loading}
              />
            </div>
            <StyledCard>
              <CardTitleContainer>
                <AccountTitleRow>
                  <AccountTitle>Earned Badges</AccountTitle>
                </AccountTitleRow>
              </CardTitleContainer>
              <BadgesContainer>
                {!loading ? (
                  earnedBadges.map((badge) => (
                    <Badge
                      src={svgToComponent(badge.imgUrl, true)}
                      label={badge.caption}
                      tooltipBody={tooltipTexts[badge.type]}
                      earned
                    />
                  ))
                ) : (
                  <InlineLoading description="Loading..." />
                )}
              </BadgesContainer>
            </StyledCard>

            <StyledCard>
              <CardTitleContainer>
                <AccountTitleRow>
                  <AccountTitle>Available Badges</AccountTitle>
                </AccountTitleRow>
              </CardTitleContainer>
              <BadgesContainer>
                {!loading ? (
                  availableBadges.map((badge) => (
                    <Badge
                      src={svgToComponent(badge.imgUrl)}
                      label={badge.caption}
                      tooltipBody={tooltipTexts[badge.type]}
                      redirectLink={tooltipRedirects[badge.type]}
                    />
                  ))
                ) : (
                  <InlineLoading description="Loading..." />
                )}
              </BadgesContainer>
            </StyledCard>
          </SingleColumn>
    </Page>
  );
}

const StyledCard = styled.div`
  background-color: #fff;
  width: 95%;
  flex: 0 0 auto;
  border: 1px solid rgba(0, 0, 0, 0.1);
  margin-right: 1rem;
  margin-top: 1rem;
  padding: 0 1rem 2rem;

  overflow: auto;
  @media (max-width: 900px) {
    width: 99%;
    margin: auto;
    margin-bottom: 1rem;
    height: auto;
  }
`;

const CardTitleContainer = styled(AccountTitleContainer)`
  position: sticky;
  top: 0;
  background-color: #fff;
`;

const BadgesContainer = styled.div`
  padding: 0.5rem;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 1rem;
  @media screen and (min-width: 767px) {
    padding: 0.5rem 1rem;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 1.5rem;
  }
  @media screen and (min-width: 900px) {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  }
  @media screen and (min-width: 1024px) {
    padding: 1rem 2rem;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    gap: 2rem;
  }
  @media screen and (min-width: 1600px) {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    gap: 3rem;
  }
`;

const StyledNotificationsGrid = styled(NotificationsGrid)`
  display: flex;
  flex-flow: column nowrap;
`;

// <div>
//   <div className="headerDiv">
//     <TitleRow>Badges</TitleRow>
//   </div>
{
  /* <div className="cardsDiv">
        <div>
          <StyledCard>
            <p>Earned Badges</p>
            <div className="allBadges">
              <div className="badgeBox">

                <p className="badgeText">New Member</p>
              </div>
            </div>
          </StyledCard>
        </div>
        <StyledCard>
          <p>Available Badges</p>
          <div className="allBadges">
            <div className="badgeBox">
              <img src={AcceptedReferral} style={{ cursor: "pointer" }} />
              <p className="badgeText">Shared Status 2x</p>
            </div>
            <div className="badgeBox">
              <img src={CareTeam} style={{ cursor: "pointer" }} />
              <p className="badgeText">Building a Care Team</p>
            </div>
            <div className="badgeBox">
              <img src={CheckIn} style={{ cursor: "pointer" }} />
              <p className="badgeText">Checked In</p>
            </div>
            <div className="badgeBox">
              <img
                src={CompletedProfile}
                onClick={() => history.push("/complete-profile")}
                style={{ cursor: "pointer" }}
              />
              <p className="badgeText">Completed Profile</p>
            </div>
            <div className="badgeBox">
              <img src={NeedsRequest} style={{ cursor: "pointer" }} />
              <p className="badgeText">Needs Request</p>
            </div>
          </div>
        </StyledCard>
  </div> */
}
// {/* </div> */}

export default ProfileBadges;
