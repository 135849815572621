import styled from "styled-components";
import { Link as Lk } from "react-router-dom";

export const Container = styled.div`
  position: relative;
  background-color: ${(props) => props.theme.colors.primary.darkBlue};
  display: flex;
  width: 100%;
  flex-flow: column nowrap;
  align-items: center;
  @media ${(props) => props.theme.breakpoints.sm} {
    flex-flow: row nowrap;
  }
`;
export const Img = styled.img`
  width: 43px;
  padding: 0.5rem;
`;

export const Logo = styled.h1`
  font-weight: 600;
  font-size: 1rem;
`;
export const Link = styled(Lk)`
  padding: 1.25rem 1rem;
  color: #ffffff;
  text-decoration: none;
  &:hover {
    color: ${(props) => props.theme.colors.accent.mediumBlue};
  }
`;

export const Link2 = styled.a`
  padding: 1.25rem 1rem;
  color: #ffffff;
  text-decoration: none;
  &:hover {
    color: ${(props) => props.theme.colors.accent.mediumBlue};
  }
`;

export const Text = styled.p`
  padding: 1.25rem 1rem;
  color: #ffffff;
  font-size: 0.9rem;
`;

export const LogoContent = styled.div`
  // cursor: pointer;
  display: flex;
  padding: 0.75rem 0.5rem;
  color: #ffffff;
  align-items: center;
`;