import styled from "styled-components";
import {
  Button,
  InlineNotification,
  Link,
  Select,
} from "carbon-components-react";
import {
  ArrowLeft32,
  EarthFilled16,
  Location16,
  Person16,
  PhoneFilled16,
  Time16,
} from "@carbon/icons-react";

export const ProviderInfoPageContainer = styled.div`
  background-color: #d8d8d8;
  position: relative;
  color: black;
  width: 100%;
  height: auto;
  min-height: 100vh;
  overflow: auto;
  padding: 1rem;
  z-index: 2;
`;

export const ProviderInfoContainer = styled.div`
  background-color: white;
  height: 100%;
  min-height: calc(100vh - 5rem);
  overflow: auto;
  /* margin: 1rem 1rem 1rem 1rem; */
  display: grid;
  grid-template-rows: 3rem auto auto;
  grid-template-columns: 65% 35%;
  grid-template-areas:
    "header header"
    "left-column right-column";
  padding: 3rem;
  @media (max-width: 900px) {
    padding: 0.5rem 0.5rem 13rem 0.5rem;
    display: grid;
    margin-bottom: 0rem;
    grid-template-rows: auto auto auto auto;
    grid-template-columns: 100%;
    grid-template-areas:
      "header"
      "left-column"
      "right-column";
  }
`;

export const BlueBackLink = styled(ArrowLeft32)`
  color: #0f62fe;
  font-size: 1rem;
  display: inline-block;
  padding-left: 0;
  cursor: pointer;
  background-color: white;
  border: none;
`;

export const StyledLink = styled(Link)`
  color: black;
  margin-top: 0.5rem;
`;

export const StyledButton = styled(Button)`
  /* width: 50%; */
  align-self: flex-end;
`;

export const LeftColumn = styled.div`
  grid-area: left-column;
  display: flex;
  flex-direction: column;
  height: 98%;
  width: 90%;
  margin: auto;
  @media (max-width: 900px) {
    width: 98%;
    height: auto;
    margin: auto;
  }
`;

export const RightColumn = styled.div`
  grid-area: right-column;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  height: 98%;
  margin-top: 2rem;
  @media (max-width: 900px) {
    width: 100%;
    height: auto;
    margin: auto;
    margin-top: 1rem;
  }
`;

export const BackLinkContainer = styled.div`
  grid-area: header;
  margin-top: 0.5rem;
  margin-left: 1rem;
`;

export const StyledInlineNotification = styled(InlineNotification)`
  position: absolute;
  margin-top: -0.5rem;
  color: black;
  margin-left: 24vw;
  z-index: 16;
  @media (max-width: 800px) {
    margin-left: 12vw;
  }
  @media (max-width: 416px) {
    .bx--inline-notification__title {
      font-weight: 450;
    }
  }
`;

export const ActionContainer = styled.div`
  width: 95%;
  height: 18rem;
  background-color: #eef9e6;
  /* background-color: #e5e5e5; */
  padding: 2rem;
  margin-top: 1rem;
  @media (max-width: 900px) {
    margin: auto;
    /* margin-top: 2rem; */
  }
`;

export const StyledSelect = styled(Select)`
  margin: 2rem 0 3rem 0;
`;

export const ProviderInfoHeader = styled.div`
  display: flex;
  @media (max-width: 900px) {
    flex-direction: column;
    margin: auto;
  }
`;

export const ProviderInfoHeaderContent = styled.div`
  /* margin-left: 1rem; */
  // margin-top: 1rem;
  // @media (max-width: 500px) {
  //   width: 95%;
  //   margin: auto;
  //   margin-top: 2rem;
  // }
  margin-top: 1rem;
  display: flex;
  svg {
    fill: #4c82ff;
  }
`;

export const ImageContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  width: 8rem;
  height: 8rem;
  border: 1px solid black;
  border-radius: 50%;
  overflow: auto;

  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;

  > svg {
    width: 85%;
    height: auto;
    border: 1px solid black;
    border-radius: 50%;
    fill: white;
  }
  @media (max-width: 900px) {
    margin: auto;
  }
`;
// export const BorderDiv = styled.div`
//   grid-area: body;
//   display: flex;
//   width: 100%;

//   /* border-top: 1pxs solid black; */
//   /* border-bottom: 1px solid black; */
//   margin: auto;
//   margin-top: 3rem;

//   padding-top: 1rem;
//   /* padding-left: 1rem; */
//   justify-items: center;
//   @media (max-width: 900px) {
//     width: 100%;
//   }
// `;

export const StyledHeader = styled.h5`
  margin-top: 1rem;
  margin-bottom: 0.3rem;
`;

export const InfoWrapper = styled.div`
  margin-bottom: 1rem;
`;
export const StyledLocation16 = styled(Location16)`
  color: blue;
  margin-right: 8px;
`;

export const StyledTime16 = styled(Time16)`
  color: blue;
  margin-right: 8px;
`;

export const StyledEarthFilled16 = styled(EarthFilled16)`
  color: blue;
  margin-right: 8px;
`;

export const StyledPhoneFilled16 = styled(PhoneFilled16)`
  color: blue;
  margin-right: 8px;
`;

export const StyledPerson16 = styled(Person16)`
  color: blue;
  margin-right: 8px;
`;

export const MapDiv = styled.div`
  margin-top: 4rem;
  width: 100%;
  height: 100%;
  @media screen and (min-width: 900px){
    width: 95%
  }
`;

export const Services = styled.div`
  margin-top: 2rem;
  padding-bottom: 1rem;
  overflow: auto;
  height: auto;
  width: 99%;
  max-height: 75vh;
`;
