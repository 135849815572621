import { useEffect, useState, useContext } from "react";
import styled from "styled-components";
import { Flex } from "../index.styled";
import { Filter20, FilterRemove20 } from "@carbon/icons-react";
import kit from "ich-ui-kit";
import { getCompletedReferrals, updateDate } from "../helpers/filter-helpers";
import { Information16 } from "@carbon/icons-react";
import { Tooltip } from "carbon-components-react";

const OverviewCards = ({
  totalReferrals,
  myAssignments,
  unassigned,
  averageAge,
  completed,
  displayedItems,
  setDisplayedItems,
  mockData,
  referrals,
  dateStart,
  dateEnd,
  selectServices,
  assignStatuses,
  referStatuses,
  filterBookmarked,
  parentRefers
}) => {
  const originalData = referrals;
  const { user } = useContext(kit.UserContext);
  const [myAssignmentsActive, setMyAssignmentsActive] = useState(false);
  const [completedActive, setCompletedActive] = useState(false);
  const [unassignedActive, setUnassignedActive] = useState(false);

  const [openCompletedTip, setOpenCompletedTip] = useState(false);
  const [openAvgDaysTip, setOpenAvgDaysTip] = useState(false);

  // Filtering Functions
  const displayMyAssignments = (user) => {
    if (myAssignmentsActive) {
      updateDate(
        dateStart,
        dateEnd,
        setDisplayedItems,
        originalData,
        selectServices,
        assignStatuses,
        referStatuses,
        filterBookmarked,
        parentRefers
      );
    } else {
      let filtered;
      if (user.superAssumedRole?.leadId) {
        filtered = originalData.filter(
          (item) => item && item.assignedTo === user.name);
      } else {
        filtered = originalData.filter(
          (item) => item && item.assignedTo === user.fullName);
      }
      updateDate(
        dateStart,
        dateEnd,
        setDisplayedItems,
        filtered,
        selectServices,
        assignStatuses,
        referStatuses,
        filterBookmarked,
        parentRefers
      );
      setCompletedActive(false);
      setUnassignedActive(false);
    }
  };
  const displayCompleted = () => {
    if (completedActive) {
      updateDate(
        dateStart,
        dateEnd,
        setDisplayedItems,
        originalData,
        selectServices,
        assignStatuses,
        referStatuses,
        filterBookmarked,
        parentRefers
      );
    } else {
      const filtered = getCompletedReferrals(originalData);
      updateDate(
        dateStart,
        dateEnd,
        setDisplayedItems,
        filtered,
        selectServices,
        assignStatuses,
        referStatuses,
        filterBookmarked,
        parentRefers
      );
      setMyAssignmentsActive(false);
      setUnassignedActive(false);
    }
  };
  const displayUnassigned = () => {
    if (unassignedActive) {
      updateDate(
        dateStart,
        dateEnd,
        setDisplayedItems,
        originalData,
        selectServices,
        assignStatuses,
        referStatuses,
        filterBookmarked,
        parentRefers
      );
    } else {
      let filtered;
      filtered = originalData.filter((item) => {
        return item && !item.assignedTo;
      });
      updateDate(
        dateStart,
        dateEnd,
        setDisplayedItems,
        filtered,
        selectServices,
        assignStatuses,
        referStatuses,
        filterBookmarked,
        parentRefers
      );
      setMyAssignmentsActive(false);
      setCompletedActive(false);
    }
  };

  return (
    <>
      <CardContainer>
        <FlatCard>
          <CardTitle>Total Received</CardTitle>
          <CardValue>{totalReferrals || 0}</CardValue>
        </FlatCard>
        <FlatCard
          onClick={(e) => {
            e.preventDefault();
            displayCompleted();
            setCompletedActive(completedActive ? false : true);
          }}
        >
          <div style={{ display: "flex" }}>
            <CardTitle>Total Completed</CardTitle>
            <div
              onMouseEnter={() => setOpenCompletedTip(true)}
              onMouseLeave={() => setOpenCompletedTip(false)}
            >
              <Tooltip open={openCompletedTip} direction="right">
                A completed referral is a referral that has been closed by a provider.
                <br /> <br />
                Completed referrals statuses are Canceled by Client,
                Closed, Expired, or Provider Cannot Accept.
              </Tooltip>
            </div>
          </div>
          <Flex
            style={{ alignItems: "baseline", justifyContent: "space-between" }}
          >
            <CardValue>{completed || 0}</CardValue>
            {completedActive ? <FilterRemove20 /> : <Filter20 />}
          </Flex>
        </FlatCard>
        <FlatCard
          onClick={(e) => {
            e.preventDefault();
            displayUnassigned();
            setUnassignedActive(unassignedActive ? false : true);
          }}
        >
          <CardTitle>Unassigned</CardTitle>
          <Flex
            style={{ alignItems: "baseline", justifyContent: "space-between" }}
          >
            <CardValue>{unassigned || 0}</CardValue>
            {unassignedActive ? <FilterRemove20 /> : <Filter20 />}
          </Flex>
        </FlatCard>
        <FlatCard>
          <div style={{display: 'flex'}}>
          <CardTitle>Avg. Days to Completion</CardTitle>
            <div
                onMouseEnter={() => setOpenAvgDaysTip(true)}
                onMouseLeave={() => setOpenAvgDaysTip(false)}
              >
                <Tooltip open={openAvgDaysTip} direction="right">
                 Avg. days to completion calculates the average number of days between the start date and close date for all completed referrals.
                </Tooltip>
              </div>
            </div>
          <Flex style={{ alignItems: "baseline" }}>
            <CardValue>{averageAge || 0}</CardValue>
            <span>days</span>
          </Flex>
        </FlatCard>
        {!user.isSuper && !user.is211Admin && (
          <FlatCard
            onClick={(e) => {
              e.preventDefault();
              displayMyAssignments(user);
              setMyAssignmentsActive(myAssignmentsActive ? false : true);
            }}
          >
            <CardTitle>My Assignments</CardTitle>
            <Flex
              style={{
                alignItems: "baseline",
                justifyContent: "space-between"
              }}
            >
              <CardValue>{myAssignments || 0}</CardValue>
              {myAssignmentsActive ? <FilterRemove20 /> : <Filter20 />}
            </Flex>
          </FlatCard>
        )}
      </CardContainer>
    </>
  );
};

export const CardContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 6px;
  margin-bottom: 1rem;
  @media screen and (min-width: 900px) {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    gap: 12px;
    margin-bottom: 1.5rem;
  }
`;

export const FlatCard = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 6rem;
  margin: auto;
  background-color: white;
  padding: 0.5rem;
  border-radius: 2px; /* 2px rounded corners */
  -webkit-box-shadow: 2px 2px 5px 2px rgba(0, 0, 0, 0.15);
  box-shadow: 2px 2px 5px 2px rgba(0, 0, 0, 0.15);
  transition: 0.2s;
  @media screen and (min-width: 900px) {
    height: 8rem;
    padding: 1rem;
  }
  ${(props) => !props.noHover && `
    &:hover {
      background-color: #f5f5f5;
      cursor: pointer;
    }
  `}
`;

export const CardTitle = styled.p`
  font-size: 12px;
  @media screen and (min-width: 500px) {
    font-size: 14px;
  }
  @media screen and (min-width: 900px) {
    font-size: 16px;
  }
`;

export const CardValue = styled.h2`
  font-weight: 500;
  margin-right: 4px;
`;

export default OverviewCards;
