import React, { useEffect, useState } from "react";

export const NavContext = React.createContext({});

export const NavProvider = ({ children }) => {
  const [backButton, setBackButton] = useState(false);
  const [mobileNavOpen, setMobileNavOpen] = useState(false);
  const [providerImageChange, setProviderImageChange] = useState(false);
  return (
    <NavContext.Provider
      value={{
        backButton,
        setBackButton,
        mobileNavOpen,
        setMobileNavOpen,
        providerImageChange,
        setProviderImageChange,
      }}
    >
      {children}
    </NavContext.Provider>
  );
};

export const NavConsumer = NavContext.Consumer;
