import React, { useState } from "react";
import styled from "styled-components";
import { Toggle } from "carbon-components-react";

const BusStopToggle = ({ value, setValue }) => {
  const [backgroundColor, setBackgroundColor] = useState("#ffff");
  const [color, setColor] = useState(false);
  return (
    <ToggleWrapper>
      <Button
        data-id={`map-bus-stop-toggle-btn`}
        style={{ backgroundColor: backgroundColor, color: color }}
        onClick={() => {
          setValue((toggled) => !toggled);
          !value ? setBackgroundColor("#3887be") : setBackgroundColor("#ffff");
          !value ? setColor("#ffff") : setColor("#404040");
        }}
      >
        Show Bus Stops
      </Button>
    </ToggleWrapper>
  );
};

const ToggleWrapper = styled.div`
  position: absolute;
  inset: 10px auto auto 45px;
`;
const Button = styled.div`
  width: 100px;
  border: 1px solid rgba(0, 0, 0, 0.4);
  text-align: center;
  padding-top: 5px;
  color: #404040;
  background: #ffff;
  cursor: pointer;
  height: 2rem;
  border-radius: 3px;
`;
export default BusStopToggle;
