import { ChevronRight32 } from "@carbon/icons-react";
import kit from "ich-ui-kit";
import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { NavContext } from "../../store/NavContext";
import * as S from "./account.styled";
import { PageContainer } from "./components";
import Invite from "./components/Invite";
import LongitudinalRecord from "./components/Longitudinal-Record.jsx";
import { Menu } from "./components/Menu";
import DesktopMenu from "./components/Menu/DesktopMenu";
import Notifications from "./components/Notifications.jsx";
import Profile from "./components/Profile.jsx";
import ProfileBadges from "./components/ProfileBadges/ProfileBadges";
import ProviderInfo from "./components/ProviderInfo.jsx";
import ProviderManagement from "./components/ProviderManagement";
import UserManagement from "./components/UserManagement";

const AccountPage = ({channel}) => {
  const { user, updateUser, clearUser } = useContext(kit.UserContext);
  const { apiService } = useContext(kit.ApiContext);
  const [currentPage, setCurrentPage] = useState();
  const [profile, setProfile] = useState({});
  const [clients, setClients] = useState([]);
  const [mfaToggle, setMfaToggle] = useState(false);
  const [emailNotificationToggle, setEmailNotificationToggle] = useState(true);
  const [phoneNotificationToggle, setPhoneNotificationToggle] = useState(false);
  const [loadingProfile, setLoadingProfile] = useState(true);
  const [isLoadingClients, setIsLoadingClients] = useState(true);
  const [loading, setLoading] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const { mobileNavOpen, setMobileNavOpen } = useContext(NavContext);
  const [isScrolling, setIsScrolling] = useState(false);

  const history = useHistory();

  // For Responsiveness
  const [width, setWidth] = useState(window.innerWidth);
  const isMobile = 900;
  useEffect(() => {
    const handleResizeWindow = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleResizeWindow);
    return () => {
      window.removeEventListener("resize", handleResizeWindow);
    };
  }, []);

  useEffect(() => {
    const getClientRecord = async () => {
      try {
        const data = await apiService.get(
          `/providerstaff/account?userId=${
            user.superAssumedRole?.leadId || user.id
          }`
        );
        setProfile(data);
        if (data.mfaMethod === "email") {
          setMfaToggle(true);
        } else {
          setMfaToggle(false);
        }
        if (data.notifyByEmail) {
          setEmailNotificationToggle(true);
        } else {
          setEmailNotificationToggle(false);
        }
        if (data.notifyByPhone) {
          setPhoneNotificationToggle(true);
        } else {
          setPhoneNotificationToggle(false);
        }
      } catch (err) {
        console.log(err);
      }
      setLoadingProfile(false);
    };
    getClientRecord();
  }, [apiService]);

  useEffect(() => {
    const fetchClients = async () => {
      if (user.superAssumedRole?.leadId) {
        let patientsData = await apiService.get(
          `/clients?assumedId=${user.superAssumedRole.leadId}`
        );
        if (patientsData) {
          setClients(patientsData || []);
          setIsLoadingClients(false);
        }
      } else {
        let patients = await apiService.get(`/clients`);
        setClients(patients || []);
        setIsLoadingClients(false);
      }
    };

    // Only attempt to pull patients list
    if (user.isViewOnly) {
      if (window.location.pathname === "/account") {
        setCurrentPage("Profile");
      } else {
        setCurrentPage("ProviderInfo");
      }
    } else if (!user.firstLogin) {
      setCurrentPage("Profile");
      fetchClients();
    }
  }, [apiService]);

  // If routed from elsewhere
  useEffect(() => {
    if (history?.location?.state?.fromFeed) {
      switch (history.location.state.fromFeed) {
        case "view-services":
          setCurrentPage("ProviderInfo");
          break;
        case "provider-event-history":
          setCurrentPage("LongitudinalRecord");
          break;
        default:
          setCurrentPage("Profile");
      }
    }
  }, [history?.location?.state?.fromFeed]);

  const toggle = async () => {
    let mfa = !mfaToggle ? "email" : "text";
    setLoading(true);
    if (!loading) {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_ENDPOINT}/iam/mfa/update-preferences`,
          {
            method: "post",
            headers: {
              Authorization: `Bearer ${user.accessToken} ${user.idToken}`,
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              mfaMethod: mfa,
            }),
          }
        );
        if (response.status === 200) {
          const data = response.json;
          if (data.mfaPreference === "email") {
            setMfaToggle(true);
          } else {
            setMfaToggle(false);
          }
          setMfaToggle(!mfaToggle);
        }
      } catch (error) {
        console.log(error);
      }
      setLoading(false);
    }
  };

  const toggleEmailNotifications = async () => {
    setLoading2(true);
    if (!loading2) {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_ENDPOINT}/account/notification-preferences`,
          {
            method: "put",
            headers: {
              Authorization: `Bearer ${user.accessToken} ${user.idToken}`,
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              email: !emailNotificationToggle
            }),
          }
        );
        if (response.status === 200) {
          const data = response.json;
          if (data.notifyByEmail == true) {
            setEmailNotificationToggle(false);
          } else if (data.notifyByEmail == false) {
            setEmailNotificationToggle(true);
          }
        }
        setEmailNotificationToggle(!emailNotificationToggle);
      } catch (error) {
        console.log(error);
      }
      setLoading2(false);
    }
  };


  return (
    <S.AccountWrapper>
      {width < isMobile && (
        <>
          <S.MobileBtn
            hasIconOnly
            renderIcon={ChevronRight32}
            iconDescription="Open Profile Menu"
            onClick={() => {
              if (mobileNavOpen) {
                setMobileNavOpen(false);
              }
              setIsOpen((state) => !state);
            }}
          />
          <S.SideNav theme={{ isOpen: isOpen }}>
            <Menu
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              setIsOpen={setIsOpen}
              user={user}
            />
          </S.SideNav>
          <S.Overlay
            theme={{ isOpen: isOpen }}
            onClick={() => {
              setIsOpen((state) => !state);
            }}
          />
        </>
      )}
      <PageContainer onScroll={() => setIsScrolling(true)}>
        {width > isMobile && (
          <DesktopMenu user={user} setCurrentPage={setCurrentPage} />
        )}
        {currentPage === "Profile" ? (
          <Profile
            profile={profile}
            setProfile={setProfile}
            updateUser={updateUser}
            clearUser={clearUser}
            user={user}
            loading={loadingProfile}
            channel={channel}
          />
        ) : currentPage === "ProviderInfo" ? (
          <ProviderInfo profile={profile} user={user} />
        ) : currentPage === "ProviderManagement" ? (
          <ProviderManagement />
        ) : currentPage === "UserManagement" ? (
          <UserManagement
            orgId={
              !user.superAssumedRole?.orgId
                ? profile.orgId
                : user.superAssumedRole?.orgId
            }
            scrolling={isScrolling}
            setScrolling={setIsScrolling}
            channel={channel}
          />
        ) : currentPage === "LongitudinalRecord" ? (
          <LongitudinalRecord
            orgId={
              !user.superAssumedRole?.orgId
                ? profile.orgId
                : user.superAssumedRole?.orgId
            }
            clients={clients}
            isLoadingClients={isLoadingClients}
          />
        ) : currentPage === "Invite" ? (
          <Invite
            orgId={
              !user.superAssumedRole?.orgId
                ? profile.orgId
                : user.superAssumedRole?.orgId
            }
          />
        ) : currentPage === "Badges" ? (
          <ProfileBadges />
        ) : (
          <Notifications
            mfaToggle={mfaToggle}
            toggle={toggle}
            toggleEmailNotifications={toggleEmailNotifications}
            emailNotificationToggle={emailNotificationToggle}
            hasPhone={!!profile.phone}
            hasEmail={!!profile.email}
            notificationsLoading={loading2}
            mfaLoading={loading}
          />
        )}
      </PageContainer>
    </S.AccountWrapper>
  );
};
export default AccountPage;
