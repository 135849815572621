import styled from "styled-components";

export const Link = styled.div`
  background-color: ${(props) => {
    if (props.theme.isCurrent) {
      return props.theme.colors.primary.mediumBlue;
    }
  }};
  color: ${(props) => (props.theme.isCurrent ? "#ffffff" : "#000000")};
  text-decoration: none;
  font-size: 1rem;
  font-weight: 600;
  padding: 1rem 2rem;
  transition-timing-function: ${(props) => props.theme.motion};
  transition-duration: 110ms;
  &:not(:last-child) {
    margin-bottom: 0.25rem;
  }
  &:hover {
    color: #ffffff;
    background-color: ${(props) => props.theme.colors.accent.mediumBlue};
  }
`;
