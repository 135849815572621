import { Button } from 'carbon-components-react';
import { useState } from 'react';
import InlineLoadingCompact from '../inlineLoadingCompact';

const ButtonLoading = ({
  type = 'button',
  label: { baseText = '', loadingText = '' },
  disabled,
  onClick = undefined,
  loading: externalLoading,
  ...props
}) => {
  const [loading, setLoading] = useState(false);
  const clickEvent = async () => {
    setLoading(true);
    await onClick();

    setLoading(false);
  };

  return (
    <Button
      type={type}
      disabled={loading || disabled || externalLoading}
      {...props}
      {...(typeof onClick === 'function' && { onClick: clickEvent })}
    >
      {loading || externalLoading ? (
        <InlineLoadingCompact description={loadingText} />
      ) : (
        baseText
      )}
    </Button>
  );
};

export default ButtonLoading;
