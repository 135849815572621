import styled from "styled-components";
import { TextInput as TI, Button as Btn } from "carbon-components-react";
import { Search32, Location32 } from "@carbon/icons-react";

export const TextboxContainer = styled.div`
  position: relative;
  width: 100%;
  @media ${(props) => props.theme.breakpoints.xs} {
    display: grid;
    grid-template-columns: 70% 30%;
  }
`;

export const Form = styled.form`
  position: relative;
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100%;

  @media ${(props) => props.theme.breakpoints.xs} {
    height: 3rem;
    margin: 1rem 0;
    flex-direction: row;
    outline: 1px solid #ccc;
  }
`;

export const FieldSet = styled.div`
  position: relative;
  z-index: 1;
  margin-bottom: 0.5rem;
  @media ${(props) => props.theme.breakpoints.xs} {
    width: 100%;
    /* & ~ &::after {
      display: block;
      content: "";
      position: absolute;
      background-color: rgba(0, 0, 0, 0.25);
      height: 75%;
      width: 2px;
      top: 15%;
      left: 2px;
      z-index: 2;
    } */
  }
`;

export const TextInput = styled(TI)`
  outline: none;
  height: 3rem;
  background: #f6f2f2;
  padding-left: 3rem;
  border: none;
  z-index: 2;
  margin: 0 auto 0;
  @media (min-width: 100px) {
  }
  @media only screen and (min-width: 768px) {
    height: 3rem;
    background: #f6f2f2;
    padding-left: 3rem;
    border: none;
    z-index: 2;
    margin: 0 auto 0;
    width: 100%;
  }
`;
export const Search = styled(Search32)`
  width: 2rem;
  position: absolute;
  margin: 0.5rem 0 0 0.5rem;
  z-index: 3;
`;
export const Location = styled(Location32)`
  position: absolute;
  margin: 0.5rem 0 0 0.5rem;
  z-index: 3;
`;

export const BtnSmallVW = styled(Btn)`
  display: inline-flex !important;
  outline: 1px solid #ccc;
  max-width: 8.5rem;
  @media ${(props) => props.theme.breakpoints.xs} {
    display: none !important;
  }
`;

export const BtnMedVW = styled(Btn)`
  display: none !important;
  outline: 1px solid #ccc;
  @media ${(props) => props.theme.breakpoints.xs} {
    display: inline-flex !important;
    height: auto;
  }
`;

export const FilterBtnMedVW = styled(Btn)`
  display: none !important;
  outline: 1px solid #ccc;
  @media ${(props) => props.theme.breakpoints.xs} {
    display: inline-flex !important;
    height: auto;
  }

  @media ${(props) => props.theme.breakpoints.sm} {
    display: none !important;
    height: auto;
  }
`;

export const Overlay = styled.div`
  position: absolute;
  z-index: 16;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  visibility: ${(props) => {
    if (props.theme.isOpen) {
      return "visible;";
    }
    return "hidden;";
  }};
  opacity: ${(props) => {
    if (props.theme.isOpen) {
      return "1;";
    }
    return "0;";
  }};
  transition-timing-function: ${(props) => props.theme.motion};
  transition-duration: 700ms;
  transition-property: opacity;
  z-index: ${(props) => {
    if (props.theme.isOpen) {
      return "11;";
    }
    return "0;";
  }};
  @media (min-width: 1280px) {
    display: none;
  }
`;

export const CloseBtn = styled(Btn)`
  position: absolute !important;
  z-index: 9999 !important;
  right: 0;
  top: 0;
  @media ${(props) => props.theme.breakpoints.lg} {
    display: none !important;
  }
`;

export const NavBtn = styled.div`
  position: fixed;
  box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.25);
  z-index: 1000;
  top: 5rem;
  align-self: flex-start;
  background: ${(props) => props.theme.colors.primary.darkBlue};
  color: #fff;
  padding: 0.25rem;
  width: 42px;
  height: 42px;
  display: none;
  @media ${(props) => props.theme.breakpoints.md} {
    display: block;
  }
  @media ${(props) => props.theme.breakpoints.lg} {
    display: none;
  }
`;

export const MapBtn = styled.div`
  position: fixed;
  box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.25);
  z-index: 1000;
  top: 5rem;
  right: 0;
  align-self: flex-start;
  background: ${(props) => props.theme.colors.primary.lightBlue};
  color: #fff;
  padding: 0.25rem;
  width: 42px;
  height: 42px;
  @media ${(props) => props.theme.breakpoints.lg} {
    display: none;
  }
`;

export const MapHeader = styled.div`
  position: sticky;
  top: 0;
  z-index: 9999;
  background: #ffffff;
  padding: 0 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media ${(props) => props.theme.breakpoints.md} {
    display: none;
  }
`;

export const MobileButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;

  @media ${(props) => props.theme.breakpoints.xs} {
    display: none !important;
  }
`;

export const SaveSearchButton = styled(Btn)`
  color: white;
  padding: 0.25rem;
  min-height: auto;
`;

export const SavedSearchesButton = styled(Btn)`
display: none;
@media ${(props) => props.theme.breakpoints.md} {
  display: block;
}
`;

export const SavedSearchesButtonMobile = styled(Btn)`
display: block;
@media ${(props) => props.theme.breakpoints.md} {
  display: none;
}
`;

export const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: #f6f2f2;
`;