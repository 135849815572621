import { useState, useContext, useRef, Suspense, lazy, useEffect } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import useQuery from "../../../hooks/useQuery";
import * as S from "./checkin.styled";
import kit from "ich-ui-kit";
// Helpers & Hooks
import { fetchWithRetry, lazyRetry } from "../../../utils";
import { useCheckinCMS } from "./cms";
import { useDimension } from "../../../hooks/useDimension";
import { Fallback } from "../../loading-screen";
import { Interuption, quizQuestionsInit } from "./utils";
// Components
import Start from "./pages/start";
const loadQuizComponent = () => lazyRetry(() => import("./pages/quiz"), 'quizComponent');
const Quiz = lazy(loadQuizComponent);
const End = lazy(() => lazyRetry(() => import("./pages/end"), 'endQuizComponent'));
const Anonymous = lazy(() => lazyRetry(() => import("./pages/anonymous"), 'anonymousQuizComponent'));

const CheckIn = ({ passClientInfo, passSetClientInfo, setCompletedStatus }) => {
  // Contexts
  const { apiService } = useContext(kit.ApiContext);
  const { user } = useContext(kit.UserContext);
  // Refs
  const qnRef = useRef(null);
  // Hooks
  let history = useHistory();
  let location = useLocation();
  const query = useQuery();

  const { windowSize } = useDimension();
  const { checkin, wellbeing, domain } = useCheckinCMS();
  // States
  const {id: clientIdDirectlyFromURL} = useParams();
  const clientIdFromURL = query.get("client") || clientIdDirectlyFromURL 
 
  let clientId =
    passClientInfo?.id ||
    location?.state?.client ||
    location?.state?.clientID ||
    clientIdFromURL;

  const numQuestions = 11;
  const [clientID, setClientID] = useState(clientId);
  const [page, setPage] = useState(0);
  const [ctr, setCtr] = useState(1);
  const [isValid, setIsValid] = useState(false);
  const [zip, setZip] = useState("");
  const [loading, setLoading] = useState(false);
  const [answer, setAnswer] = useState(quizQuestionsInit);
  const [interuption, setInteruption] = useState({
    isOpened: false,
    hasOpened: false,
  });

  const validateZipcode = (value) => {
    if (typeof value === "number") {
      value = value.toString();
    }
    const re = /^[0-9\b]+$/;
    if (value?.length === 5 && re.test(value) ) {
      setIsValid(true);
    } else {
      setIsValid(false);
    }
  }

    // useEffect
    useEffect(() => {
      const getClientRecord = async () => {
        setLoading(true);
        try {
          if(!passClientInfo.id) return;
          const response = await fetchWithRetry(
            `${
              process.env.REACT_APP_API_ENDPOINT
            }/clients/${passClientInfo.id}?assumedId=${
              user.superAssumedRole?.leadId || ""
            }`,
            {
              method: "get",
              headers: {
                Authorization: `Bearer ${user.accessToken} ${user.idToken}`,
              },
            }
          );
          if (response.status === 200) {
            const data = await response.json();
            setClientID(data.id)
            setZip(data.address?.postalCode);
            validateZipcode(data.address?.postalCode)
          }
        } catch (err) {
          console.log(err);
        }
        setLoading(false);
      };
  
      // We need zip code, so will repeat API call
      // If client info has not already been set, but we have a client ID from the URL, only then make API call
      // let hasClientInfo = !!clientInfo?.id;
      // if (!hasClientInfo && clientID) {
        getClientRecord();
      // }
    }, [passClientInfo]);

  // useEffect
  useEffect(() => {
    if (
      answer[ctr - 2]?.selected === "1" &&
      (answer[ctr - 2]?.domain === "social connections" ||
        answer[ctr - 2]?.domain === "health" ||
        answer[ctr - 2]?.domain === "safety") &&
      interuption?.hasOpened === false
    ) {
      setInteruption({ isOpened: true, hasOpened: true });
    }

  }, [ctr, interuption]);
  
  // Utils
  const handleChangeNumbersOnly = (e) => {
    setZip(e.target.value);
    validateZipcode(e.target.value);
  };

  const submitHandler = async () => {
    setLoading(true);
    const url = `/assessments/client?id=${passClientInfo.id}`;

    try {
      const cleanedAnswers = answer.map((item) => ({
        text: item.text,
        domain: item.domain,
        answer: item.selected === "skipped" ? 0 : parseInt(item.selected),
      }));
      const data = await apiService.post(url, {
        zipcode: zip,
        responses: cleanedAnswers,
        locale: 'en',
        searchIndex: process.env.REACT_APP_SEARCH_INDEX || 'resources-dev'
      });
      if (data && data.ok) {
        if (data.id) {
          // history.push({
          //   pathname: "/360dashboard",
          //   state: {
          //     client: clientID,
          //   },
          // });
          history.push({
            pathname: `/client-enrollment/${passClientInfo.id}/checkin/view`,
            state: {
              id: data.id,
              zip: zip,
              interuption: interuption
            }
          });
        } else {
          // history.push({
          //   pathname: "/360dashboard",
          //   state: {
          //     client: clientID,
          //   },
          // });
          history.push({
            pathname: `/client-enrollment/${passClientInfo.id}/checkin/view`,
            state: {
              providers: data.providers,
              zip: zip,
              score: data.score,
              responses: cleanedAnswers,
              interuption: interuption
            }
          });
        }
      }
    } catch (err) {
      console.log(err);
      setLoading(false);
    } 
  };

  const addAnswer = (ans, pos) => {
    setAnswer((state) =>
      state.map((el) => {
        if (el.qn === ctr) {
          return { ...el, answer: ans, selected: pos };
        }
        return el;
      })
    );
  };

  const startAssessment = () => {
    setPage(1);
  };

  const scrollToTop = () => {
    if (windowSize.height > 700) return;
    qnRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
  };

  const skipQuestion = () => {
    scrollToTop();
    addAnswer("Skipped", "skipped");
    nextQuestion();
  };

  const nextQuestion = () => {
    if (ctr >= numQuestions) {
      setPage(2);
    } else {
      scrollToTop();
      setCtr((state) => state < numQuestions ? state + 1: state);
    }
  };

  const prevQuestion = () => {
    if (page === 2) {
      setPage(1);
    } else {
      scrollToTop();
      setCtr((state) => state - 1);
    }
  };

  const end = user.id ? (
    <Suspense fallback={<Fallback />}>
      <End
        cmsData={checkin}
        handleChangeNumbersOnly={handleChangeNumbersOnly}
        zip={zip}
        prevQuestion={prevQuestion}
        submitHandler={submitHandler}
        isValid={isValid}
        loading={loading}
        clientInfo={passClientInfo}
        setCompletedStatus={setCompletedStatus}
      />
    </Suspense>
  ) : (
    <Suspense fallback={<Fallback />}>
      <Anonymous
        cmsData={checkin}
        handleChangeNumbersOnly={handleChangeNumbersOnly}
        zip={zip}
        prevQuestion={prevQuestion}
        submitHandler={submitHandler}
        isValid={isValid}
        loading={loading}
      />
    </Suspense>
  );

  return (
    <S.Container>
      {page === 0 && (
        <Start
          start={loadQuizComponent}
          cmsData={checkin}
          startAssessment={startAssessment}
          clientInfo={passClientInfo}
        />
      )}
      {page === 1 && (
        <Suspense fallback={<Fallback />}>
          {interuption.isOpened ? (
            <Interuption
              action={() => setInteruption({ ...interuption, isOpened: false })}
            />
          ) : (
            <Quiz
              ctr={ctr}
              cmsData={checkin}
              wellbeing={wellbeing}
              domain={domain}
              qnRef={qnRef}
              answer={answer}
              addAnswer={addAnswer}
              prevQuestion={prevQuestion}
              nextQuestion={nextQuestion}
              skipQuestion={skipQuestion}
            />
          )}
        </Suspense>
      )}
      {page === 2 && end}
    </S.Container>
  );
};

export default CheckIn;
