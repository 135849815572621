import styled from "styled-components";

export const Container = styled.div`
  z-index: 3;
  position: absolute;
  padding: 0.5rem 0;
  width: 100%;
  background-color: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.25);
  @media ${(props) => props.theme.breakpoints.sm} {
    display: grid;
    grid-template-columns: ${(props) => {
      return props.theme.show ? "50% 50%;" : "1fr;";
    }};
  }
`;

export const Fieldset = styled.div`
  display: flex;
  padding: 0.25rem 0.5rem;
  width: 100%;
  align-items: center;
  cursor: pointer;
  &:hover {
    background-color: gray;
  }
`;

export const Img = styled.img`
  width: 100%;
  height: 100%;
`;

export const Text = styled.p`
  padding-left: 0.25rem;
`;

export const CloseBtn = styled.div`
  position: absolute;
  outline: none;
  top: 21rem;
  padding: 1rem 2rem;
  background: #f4f4f4;
  border: 1px solid rgba(0, 0, 0, 0.25);
  display: none;
  @media (pointer: coarse) {
    display: block;
    display: flex;
    justify-content: center;
  }
`;

export const Circle = styled.div`
  background-color: ${(props) => props.theme.color};
  border-radius: 100%;
  margin: 0.15rem;
  padding: 0.75rem;
  width: 50px;
  height: 50px;
`;

export const Sub = styled.div`
  display: none;
  @media ${(props) => props.theme.breakpoints.sm} {
    display: ${(props) => (props.theme.show ? "block" : "none")};
  }
`;

export const ItemContainer = styled.div`
  position: relative;
  height: auto;
  max-height: 19.5rem;
  overflow-y: scroll;
`;

export const SubCategContainer = styled.div`
  position: relative;
  height: auto;
  max-height: 19.5rem;
  overflow-y: scroll;
`;

export const SubCateg = styled.div`
  padding: 1rem 0.25rem;
  cursor: pointer;
  &:hover {
    background: #e0e0e0;
  }
`;
