import styled from "styled-components";
import { Tile } from "carbon-components-react";
import { ArrowLeft32 } from "@carbon/icons-react";
import { Card } from "../../../../components/Card/card";

export const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ResponsiveH5 = styled.div`
  font-weight: 500;
  margin: 0.5rem 0;
  @media screen and (max-width: 520px) {
    margin-top: 2rem;
  }
`;

export const BorderedContainer = styled.div`
  width: 100%;
  border: 1px solid #8d8d8d;
`;

export const FlexBaseline = styled.div`
  display: flex;
  align-items: baseline;
`;

export const StyledCard = styled(Card)`
  width: 100%;
  min-height: calc(100vh - 3rem);
  position: relative;
  top: 0;
  bottom: 0;
  padding: 1rem;
  margin-top: 0;
  border: 1px solid rgba(0, 0, 0, 0.15);
  background: #ffffff;
  border-radius: 7px;
  box-shadow: 2px 4px 5px rgba(0, 0, 0, 0.05), -2px -2px 5px rgba(0, 0, 0, 0.05);
  :hover {
    background-color: white;
  }
  @media screen and (max-width: 500px) {
    padding: 1rem;
  }
`;

export const View = styled.div`
  color: blue;
`;

export const BackLinkContainer = styled.div`
  grid-area: button;
  height: 2rem;
`;

export const BlueBackLink = styled(ArrowLeft32)`
  position: absolute;
  color: #0f62fe;
  font-size: 1rem;
  display: inline-block;
  padding-left: 0;
  cursor: pointer;
  border: none;
  top: 1rem;
  left: 1rem;
`;

export const HeaderDiv = styled.div`
  // grid-area: header;
  display: flex;
  justify-content: flex-start;
  padding-top: 2rem;
  padding-bottom: 0;
  flex-direction: column;
`;

export const StyledTile = styled(Tile)`
  padding: 0.5rem 1rem;
  min-height: auto;
  background-color: #fff;
`;

export const SectionWrapper = styled.div`
  margin-bottom: 1rem;
  max-height: 60vh;
  overflow-y: auto;
`;

export const Timestamp = styled.p`
  color: gray;
  font-size: 0.75rem;
  margin-left: 1rem;
`;

export const UpdateStatusContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: end;
  flex-direction: column;
  text-align: end;
`;

export const HorizontalRule = styled.hr`
  margin: 2rem 0;
  width: 100%;
`;

export const CloseBtn = styled.div`
  position: absolute;
  top: 1rem;
  right: 1rem;
  cursor: pointer;
`;
