import React from "react";
import Domain from "./icon/domain.icon";
import {
  DataTable,
  Table,
  TableHead,
  TableRow,
  TableHeader,
  TableBody,
  TableCell,
  Button,
} from "carbon-components-react";
import styled from "styled-components";

const convertDate = (dateStr) => {
  if (!!!dateStr) {
    return "";
  }
  try {
    let date = new Date(dateStr);
    let month = date.getMonth() + 1;
    let day = date.getDate();
    let year = date.getFullYear();
    return month + "/" + day + "/" + year;
  } catch (e) {
    console.log(e);
    return "";
  }
};

const AssessmentList = ({ assessments, selectAssessment }) => {
  return (
    <div style={{ margin: "2rem 0", overflow: "auto" }}>
      {/* <DataTable> */}
      <Table>
        <TableHead>
          <TableRow>
            <TableHeader>Date</TableHeader>
            <TableHeader>Action</TableHeader>
            <TableHeader>Status</TableHeader>
          </TableRow>
        </TableHead>
        <TableBody>
          {assessments.map((a) => (
            <TableRow key={a.id}>
              <TableCell>{convertDate(a.date)}</TableCell>
              <TableCell>
                <ListButton kind="ghost" onClick={() => selectAssessment(a)}>
                  View Check-in
                </ListButton>
              </TableCell>
              <TableCell>
                  <ResultContainer>
                  {a.responses.sort((c,d) => c.text > d.text ? 1:-1).map((r) => {
                    return (
                        <Domain 
                          key={r.id} 
                          name={r.text} 
                          status={r.answer} 
                          viewAllPage={true} 
                        />
                    );
                  })}
                </ResultContainer>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      {/* </DataTable> */}
    </div>
  );
};

const ListButton = styled(Button)`
  padding-left: 0rem;
`;

const ResultContainer = styled.div`
  padding-left: 0rem;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  flex-flow: row wrap;
  @media only screen and (min-width: 1024px) {
    align-items: flex-start;
  }
`;

export default AssessmentList;
