import { useEffect, useState } from "react";
import { useStrapi } from "../../../hooks/useStrapi";

export const useCheckinCMS = () => {
  const domainInit = {
    dependent_care: "Dependent Care",
    education: "Education",
    employment: "Employment",
    financial: "Financial",
    food: "Food",
    health: "Health",
    housing: "Housing",
    income: "Income",
    safety: "Safety",
    social_connections: "Social Connections",
    transportation: "Transportation"
  };
  const { data: domainData, loading: domainLoading } = useStrapi({
    cms: `list-domain`
  });
  const [domain, setDomain] = useState(domainInit);
  useEffect(() => {
    if (domainLoading) {
      setDomain(domainInit);
      return;
    }
    setDomain((state) => ({
      food: domainData.food || state.food,
      health: domainData.health || state.health,
      housing: domainData.housing || state.housing,
      education: domainData.education || state.education,
      social_connections:
        domainData.social_connections || state.social_connections,
      safety: domainData.safety || state.safety,
      income: domainData.income || state.income,
      transportation: domainData.transportation || state.transportation,
      dependent_care: domainData.dependent_care || state.dependent_care,
      financial: domainData.financial || state.financial,
      employment: domainData.employment || state.employment
    }));
  }, [domainData]);

  const wellbeingInit = {
    excellent: "Excellent",
    great: "Great",
    good: "Good",
    okay: "Okay",
    not_okay: "Not Okay"
  };
  const { data: wellbeingData, loading: wellbeingLoading } = useStrapi({
    cms: `list-wellbeing`
  });
  const [wellbeing, setWellbeing] = useState(wellbeingInit);
  useEffect(() => {
    if (wellbeingLoading) {
      setWellbeing(wellbeingInit);
      return;
    }
    setWellbeing((state) => ({
      excellent: wellbeingData.items.excellent || state.excellent,
      great: wellbeingData.items.great || state.great,
      good: wellbeingData.items.good || state.good,
      okay: wellbeingData.items.okay || state.okay,
      not_okay: wellbeingData.items.not_okay || state.not_okay
    }));
  }, [wellbeingData]);

  const checkinInit = {
    heading: "Check-in: Take the first step to better well-being",

    text_question: "Question",
    text_choose_face:
      "Choose the face that shows how you currently feel about your",
    btn_next_question: "Next",
    tooltip_prev_question: "Go to previous question",
    tooltip_next_question: "Go to next question",
    btn_start: "Start",
    heading_disclaimer:
      "When you submit an Anonymous Check-in MyWayfinder will recommend resources based on your responses.",
    content_disclaimer:
      "We do not store anonymous Check-ins. Once you close the Check-in all information related to that Check-in will be deleted.\n  \nIf you'd like to save your Check-ins and find specific resources based on your location preference please [sign up for a MyWayfinder Account](https://sip-client-portal.mybluemix.net/create-account).",
    label_zipcode: "Enter Zip Code",
    placeholder_zipcode: "Zip Code (00000)",
    helper_zipcode: "For the best search results, enter your zip code!",
    btn_submit: "Submit",
    btn_submit_loading: "Submitting...",
    btn_back: "Back",
    link_video: "https://www.youtube.com/embed/eA0ERjAgMo4",
    heading_identified_disclaimer:
      "Based on your responses we can recommend local service providers who can help you.",
    content_identified_disclaimer:
      "Enter your zip code for local recommendations",
    btn_skip: "Skip Question",
    asl_video_link: "https://www.youtube.com/embed/bX_nJpokZAA",
    asl_btn: "View video in American Sign Language",
    asl_original_btn: "View video in English"
  };
  const { data: checkinData, loading: checkinLoading } = useStrapi({
    cms: `page-check-in`
  });
  const [checkin, setCheckin] = useState(checkinInit);
  useEffect(() => {
    if (checkinLoading) {
      setCheckin(checkinInit);
      return;
    }
    setCheckin((state) => ({
      heading: checkinData.heading || state.heading,
      text_question: checkinData.text_question || state.text_question,
      text_choose_face: checkinData.text_choose_face || state.text_choose_face,
      btn_next_question:
        checkinData.btn_next_question || state.btn_next_question,
      tooltip_prev_question:
        checkinData.tooltip_prev_question || state.tooltip_prev_question,
      tooltip_next_question:
        checkinData.tooltip_next_question || state.tooltip_next_question,
      btn_start: checkinData.btn_start || state.btn_start,
      heading_disclaimer:
        checkinData.heading_disclaimer || state.heading_disclaimer,
      content_disclaimer:
        checkinData.content_disclaimer || state.content_disclaimer,
      heading_identified_disclaimer:
        checkinData.heading_identified_disclaimer ||
        state.heading_identified_disclaimer,
      content_identified_disclaimer:
        checkinData.content_identified_disclaimer ||
        state.content_identified_disclaimer,
      label_zipcode: checkinData.label_zipcode || state.label_zipcode,
      placeholder_zipcode:
        checkinData.placeholder_zipcode || state.placeholder_zipcode,
      helper_zipcode: checkinData.helper_zipcode || state.helper_zipcode,
      btn_submit: checkinData.btn_submit || state.btn_submit,
      btn_submit_loading:
        checkinData.btn_submit_loading || state.btn_submit_loading,
      btn_skip: checkinData.btn_skip || state.btn_skip,
      btn_back: checkinData.btn_back || state.btn_back,
      btn_skip: checkinData.btn_skip || state.btn_skip,
      link_video: checkinData.link_video || state.link_video,
      asl_video_link: checkinData.asl_video_link || state.asl_video_link,
      asl_btn: checkinData.asl_btn || state.asl_btn,
      asl_original_btn: checkinData.asl_original_btn || state.asl_original_btn
    }));
  }, [checkinData]);

  return { checkin, wellbeing, domain };
};
