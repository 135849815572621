import { useContext } from "react";
import styled from "styled-components";
import { Button } from "carbon-components-react";
import { ChevronRight24 } from "@carbon/icons-react";
import { useCompleteProfile } from "../../cms";
import { ProgressIndicatorWrapper } from "./AboutMe/AboutMe.styled";
import { ProgressIndicatorBar } from "../GuidedExperience";

const Step3of3 = ({ setControllerIndex, setAboutMeIndex, setOverallIndex }) => {
  const { stepsCMS: cms } = useCompleteProfile();
  return (
    <Wrapper>
      <ProgressIndicatorWrapper>
        <ProgressIndicatorBar currentIndex={9} setAboutMeIndex={setAboutMeIndex} />
      </ProgressIndicatorWrapper>
      <TextContainer>
        <TextWrapper style={{ width: "75%" }}>
          <h4>{cms.step_3_subheading_lets_capture}</h4>
        </TextWrapper>
        <TextWrapper>
          <p>{cms.step_3_text}</p>
        </TextWrapper>
      </TextContainer>
      <ButtonWrapper>
        <StyledButton
          onClick={() => {
            setAboutMeIndex((prev) => prev + 1);
            setControllerIndex((prev) => prev + 1);
            setOverallIndex((prev) => prev + 1);
          }}
        >
          {cms.btn_continue}
          <StyledChevronIcon />
        </StyledButton>
      </ButtonWrapper>
    </Wrapper>
  );
};

export default Step3of3;

const Wrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  padding-top: 2.5rem;
  padding-left: 0.5rem;
  padding-right: 10rem;
  @media screen and (min-height: 880px) and (min-width: 1800px) {
    padding-top: 4rem;
  }
  @media screen and (max-width: 600px) {
    padding: 0;
  }
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-left: 0.8rem;
  height: 35%;
  @media screen and (min-height: 880px) and (min-width: 1800px) {
    height: 30%;
    margin-top: 1rem;
  }
`;

const TextWrapper = styled.div`
  p {
    font-size: 18px;
    font-weight: 400;
  }
  h4 {
    font-weight: 500;
    line-height: 1.3;
  }
  @media screen and (min-height: 880px) and (min-width: 1600px) {
    p {
      font-weight: 400;
      font-size: 20px;
    }
  }
`;

const ButtonWrapper = styled.div`
  width: fit-content;
  margin-top: 5rem;
  margin-left: 0.8rem;
  @media screen and (min-height: 880px) {
    margin-top: 7rem;
  }
`;
const StyledButton = styled(Button)`
  padding-right: 12px;
`;

const StyledChevronIcon = styled(ChevronRight24)`
  margin-left: 2rem;
  height: 20px;
`;
