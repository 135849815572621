export const formatToPhone = (event) => {
  const input = event.target.value.replace(/\D/g, "").substring(0, 10); // First ten digits of input only
  const areaCode = input.substring(0, 3);
  const middle = input.substring(3, 6);
  const last = input.substring(6, 10);

  if (input.length > 6) {
    event.target.value = `(${areaCode}) ${middle} - ${last}`;
  } else if (input.length > 3) {
    event.target.value = `(${areaCode}) ${middle}`;
  } else if (input.length > 0) {
    event.target.value = `(${areaCode}`;
  }
};

export const validate = (
  value,
  inputType,
  setIsPhoneError,
  setIsEmailError
) => {
  if (value) {
    if (value.length <= 4) {
      setIsPhoneError(false);
      setIsEmailError(false);
    } else {
      if (inputType == "Phone") {
        if (value.length != 16) {
          setIsPhoneError(true);
          setIsEmailError(false);
        } else {
          setIsPhoneError(false);
          setIsEmailError(false);
          return true;
        }
      } else {
        if (!/\S+@\S+\.\S+/.test(value)) {
          setIsEmailError(true);
          setIsPhoneError(false);
        } else {
          setIsEmailError(false);
          setIsPhoneError(false);
          return true;
        }
      }
    }
  }
};
