import React, { useState, useContext, useEffect } from 'react';
import {
  ProviderInfoPageContainer,
  ProviderInfoContainer,
  ProviderInfoHeader,
  ProviderInfoHeaderContent,
  BlueBackLink,
  BackLinkContainer,
  LeftColumn,
  RightColumn,
  StyledHeader,
  Services,
  MapDiv
} from './components/static.jsx';
import useQuery from '../../hooks/useQuery';
import { Loading, Tooltip } from 'carbon-components-react';
import { CheckmarkOutline16 } from "@carbon/icons-react";
import kit from 'ich-ui-kit';
import { parseHtml, parseHiddenText } from '../../utils';
import { ProviderCard } from '../../components/ServiceCard/v2';
import { Map } from '../elasticsearch/components';
import NeedsAssesmentModal from '../../components/NeedsAssessmentModal';
import { SearchProvider } from '../resources/context/SearchResultsContext.context';
import { CallLogContext } from '../../store/CallLogContext.js';

const ProviderInfoPage = () => {
  const query = useQuery();
  const { apiService } = useContext(kit.ApiContext);
  const id = query.get('id');
  const serviceId = query.get('serviceId');
  const locationId = query.get('locationId');
  const [loading, setLoading] = useState();
  const [provider, setProvider] = useState({});
  const [services, setServices] = useState([]);
  const [selectedService, setSelectedService] = useState([]);
  const [needsModal, setNeedsModal] = useState(false);
  const [patientID, setPatientID] = useState();
  const [bookmarked, setBookmarked] = useState(false);
  const { user } = useContext(kit.UserContext);
  const { isInCart, updateResources, activeLog } = useContext(CallLogContext);

  useEffect(() => {
    if (localStorage.getItem('patientId')) {
      setPatientID(JSON.parse(localStorage.getItem('patientId')));
      localStorage.removeItem('patientId');
    }
  }, []);

  useEffect(() => {
    const getProviderInfo = async () => {
      setLoading(true);
      const orgId = id;
      try {
        if (orgId !== '') {
          const { providers } = await apiService.post(
            `/search/provider?org=${orgId}&userId=${
              user?.superAssumedRole?.leadId || user?.id || ''
            }`
          );
          if (providers) {
            let services = providers.services;
            setProvider(providers);
            setServices(services.map(s => ({ ...s, overview: parseHtml(s.overview) })));
          }
          if (serviceId && locationId) {
            let service = providers.services?.find(
              (s) => s.serviceId === serviceId && s.locationId === locationId
            );
            service && setSelectedService(service);
          } else if (serviceId) {
            let service = providers.services?.find(
              (s) => s.serviceId === serviceId
            );
            service && setSelectedService(service);
          }
        } else {
          throw new Error('orgID is empty');
        }
        setLoading(false);
      } catch (err) {
        console.log(err);
      }
    };
    getProviderInfo();
  }, [apiService]);

  let provURL = provider.url ? provider.url.toLowerCase() : null;

  if (provURL && provURL.indexOf('.') > 0 && provURL.indexOf('.') < 4) {
    provURL = 'https://' + provURL;
  }

  const handleClose = () => {
    window.close();
  };

  // useEffect(() => {
  //   if (
  //     selectedService &&
  //     serviceId &&
  //     Object.keys(selectedService)?.length !== 0 &&
  //     !needsModal
  //   ) {
  //     setModal(true);
  //   }
  // }, [selectedService]);

  return (
    <SearchProvider>
      {loading && <Loading />}
      {needsModal && selectedService &&
        <NeedsAssesmentModal
          open={needsModal}
          setOpen={setNeedsModal}
          modalHeading={`Needs Request for ` + selectedService?.name}
          modalLabel={"Do you want someone to contact you?"}
          providerName={selectedService?.name || ""}
          serviceName={selectedService?.name || ""}
          orgEmail={provider?.email}
          serviceId={selectedService?.serviceId}
          orgSource={provider?.orgSource}
          locationId={selectedService?.locationId}
          isConnected={provider?.network}
        />
      }
      <ProviderInfoPageContainer>
        <ProviderInfoContainer>
          <BackLinkContainer>
            <BlueBackLink onClick={() => handleClose()}>Back</BlueBackLink>
          </BackLinkContainer>
          <LeftColumn>
            <ProviderInfoHeader>
              <ProviderInfoHeaderContent>
                <h2>
                  {provider.name}
                </h2>
                {provider?.network && provider?.network === '1' && (
                  <Tooltip direction='top' aria-label="Connected" renderIcon={CheckmarkOutline16}>Connected</Tooltip>
                )}
                <br />
              </ProviderInfoHeaderContent>
            </ProviderInfoHeader>

            <StyledHeader>Overview:</StyledHeader>
            <p>
              {parseHiddenText(parseHtml(provider.description)) ||
                'This location has not provided this information yet.'}
            </p>
            <StyledHeader>Services:</StyledHeader>
            <Services>
              {services.map((provider) => {
                return (
                  <ProviderCard 
                    key={provider.serviceId}
                    provider={provider}
                    onTeam={false}
                    eligable={false}
                    isLoggedIn={true}
                  />
                );
              })}
            </Services>
          </LeftColumn>
          <RightColumn>
            <MapDiv>
              <Map providers={services} />
            </MapDiv>
          </RightColumn>
        </ProviderInfoContainer>
      </ProviderInfoPageContainer>
    </SearchProvider>
  );
};

export default ProviderInfoPage;
