import React, { useState, useContext, useEffect } from "react";
import kit from "ich-ui-kit";

import { useHistory, useLocation } from "react-router";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import useQuery from "../../../hooks/useQuery";

import { Loading } from "carbon-components-react";
import DDGuidedExperience from "./components/DDGuidedExperience";
import * as S from "./dd-assessment.styled";
import { fetchWithRetry } from "../../../utils";

import { useDDCMS } from "./cms";

const DataDrivenAssessment = ({ passClientInfo, setCompletedStatus }) => {
  // Contexts
  const { apiService } = useContext(kit.ApiContext);
  const { user } = useContext(kit.UserContext);

  // Hooks
  const history = useHistory();
  const location = useLocation();
  const { cms } = useDDCMS();
  const query = useQuery();

  // States
  const [percentComplete, setPercentComplete] = useState(0);
  const [overallIndex, setOverallIndex] = useState(0);
  const [domain, setDomain] = useState("Employment");
  const [domains, setDomains] = useState("");
  const [loading, setLoading] = useState(false);

  const [clientInfo, setClientInfo] = useState(passClientInfo);

  const {id: clientIdDirectlyFromURL} = useParams();
  const clientIdFromURL = query.get("client") || clientIdDirectlyFromURL 
 
  let clientId =
    passClientInfo?.id ||
    location?.state?.client ||
    location?.state?.clientID ||
    clientIdFromURL;

  const [clientID, setClientID] = useState(passClientInfo);

  useEffect(() => {
    const getDomains = async () => {
      try {
        const data = await apiService.get(`/domains?id=${passClientInfo.id}`);
        if (data.ok) {
          setDomains(data.domains);
          // setDomain(domains[0])
        }
      } catch (err) {
        console.log(err);
      }
    }
    // if(clientID){
    getDomains();

    if (history.location.state?.domain) {
      setDomain(history.location.state.domain);
    }
  }, [passClientInfo,location]);

  return (
    <S.PageContainer id="top">
      <S.HeaderContainer>{cms.heading}</S.HeaderContainer>
      <S.ContentContainer>
        <DDGuidedExperience
          cms={cms}
          domain={domain}
          domains={domains}
          passClientInfo={passClientInfo}
          setCompletedStatus={setCompletedStatus}
        ></DDGuidedExperience>
      </S.ContentContainer>
    </S.PageContainer>
  );
};

export default DataDrivenAssessment;
