import React, { useContext, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import {
  RadioButtonGroup,
  RadioButton,
  Button,
  Checkbox
} from "carbon-components-react";
import styled from "styled-components";
import kit from "ich-ui-kit";
import AllClients from "../../../new-landing-page/components/AllClients";

const ClientInformation = ({
  client,
  setClient,
  incrementTab,
  doesActiveReferralExist,
  hasConsent,
  setHasConsent,
  clients,
  loading
}) => {
  const history = useHistory();
  const location = useLocation();
  const { user } = useContext(kit.UserContext);
  const [isClicked, setIsClicked] = useState(false);

  const isDisabled = () => {
    if (client) {
      return false;
    } else {
      return true;
    }
  };

  const handleClick = async () => {
    const hasActiveReferral = await doesActiveReferralExist();
    if (!hasActiveReferral) {
      incrementTab();
    }
  };

  return (
    <>
      <SectionWrapper style={{ marginTop: "0" }}>
        <SectionHeading style={{textAlign: "center"}}>
          Who will be seeing the provider?
        </SectionHeading>
        {location.state && location.state.patientId ? (
          <BorderContainer>
            <RadioButtonGroup
              name="selected-client-radio"
              valueSelected="default"
              data-id={`create-referral-client-radio-grp`}
            >
              <RadioButton
                id="selected-client"
                labelText={`${client?.givenName || client?.firstName} ${client?.familyName || client?.lastName} (On my Care Team)`}
                value="default"
              />
            </RadioButtonGroup>
          </BorderContainer>
        ) : (
          <AllClients 
            clientSelect
            clients={clients}
            loading={loading}
            setClient={setClient}
            containerStyle={{ minHeight: "auto" }}
          />
        )}
        <br />
        {user.is211Admin && client && (
          <>
            <Consent211TextBox>
              <p style={{fontWeight: "bold"}}>
                Proposed verbiage to get consent:
              </p>
              <p>
                To connect you with the organization, I can create a referral in MyWayfinder.
                If you agree, then your dashboard will be shared with the organization and they will be added to your Care Team.
                You can turn access on and off so that you are in complete control of who sees your dashboard.
                Giving the organization access to your Dashboard helps them know more about you so they can serve you better. 
                They will see your contact information and how you are doing in different areas of your life.
              </p>
            </Consent211TextBox>
            <StyledCheckboxWrapper>
              <Checkbox
                data-id={`create-referral-consent-checkbox`}
                id="referral-checkbox"
                labelText={`I confirm I have ${
                  client?.givenName ||
                  client?.firstName || (
                    client?.name.includes(" ") ? 
                    client?.name.split(" ")[0] : 
                    client?.name
                  )
                }'s consent to create this referral.`}
                checked={isClicked}
                onClick={() => {
                  setHasConsent(!hasConsent);
                  setIsClicked(!isClicked);
                }}
              />
            </StyledCheckboxWrapper>
          </>
        )}
      </SectionWrapper>

      <SectionWrapper 
        style={{
          margin: "0 auto",
          maxWidth: "300px",
        }}
      >
        <StyledButton
          data-id={`create-referral-continue-btn`}
          disabled={isDisabled()}
          onClick={() => {
            handleClick();
          }}
        >
          Continue
        </StyledButton>
        <StyledButtonCentered 
          kind="ghost" 
          onClick={() => history.goBack()}
          data-id={`create-referral-cancel-btn`}
        >
          Cancel
        </StyledButtonCentered>
      </SectionWrapper>
    </>
  );
};

export default ClientInformation;

const BorderContainer = styled.div`
  padding: 2rem 0;
  border: 1px #e0e0e0 solid;
  display: flex;
  justify-content: space-around;
  align-items: center;

  && .bx--form-item {
    flex: none;
  }
`;

const StyledButton = styled(Button)`
  max-width: 100%;
  width: 100%;
`;

const StyledButtonCentered = styled(StyledButton)`
  justify-content: center;
`;

const SectionHeading = styled.h5`
  margin: 1rem 0;
`;

const SectionWrapper = styled.div`
  margin: 1rem 0;
  && .bx--select {
    max-width: 100%;
    width: 45%;
  }

  @media (max-width: 480px) {
    && .bx--select {
      width: 100%;
    }
  }
`;

const StyledCheckboxWrapper = styled.div`
  .bx--form-item {
    width: max-content;
    margin: 0 auto;
  }
  @media (max-width: 500px) {
    && .bx--form-item {
      width: auto;
    }
  }
`;

const Consent211TextBox = styled.div`
  margin-bottom: 1rem;
  @media screen and (min-width: 600px){
    padding: 0 1rem;
  }
  @media screen and (min-width: 900px){
    padding: 0 2rem;
  }
  @media screen and (min-width: 1200px){
    padding: 0 5rem;
  }
`;
