import styled from "styled-components";
import {
  Button,
  Link,
  TextInput,
  PasswordInput,
  InlineNotification,
  FluidForm
} from "carbon-components-react";
import { ArrowRight16, ArrowLeft16 } from "@carbon/icons-react";
export const LoginContent = styled.div`
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  z-index: -1;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 0;
`;

export const LoginContainer = styled.div`
  display: flex;
  z-index: 2;
  min-height: 100%;
  background-color: #161616;
  width: 100%;
  justify-content: center;
  @media (max-width: 768px) {
    flex-direction: column;
    margin: 0rem;
  }
`;

export const LoginTitleBox = styled.div`
  background-image: url(${props => props.bgImage || "login.jpg"});
  background-repeat: no-repeat;
  background-size: cover;
  color: #c69214;
  width: 100%;
  padding: 2em;
  font-family: IBMPlexSans-Bold;
  position: relative;

  @media (max-width: 768px) {
    display: none;
  }
`;

export const LoginFormBox = styled.div`
  order: 0;
  // background-color: #161616;
  color: black;
  width: 100%;
  min-height: 100%;
  padding: 2em;
  @media (max-width: 768px) {
    height: 100%;
    width: auto;
  }
`;

export const LoginFormContainer = styled.div`
  margin: 1em 0.5em;
  min-width: 250px;
  display: flex;
  flex-direction: column;
  padding-top: 3rem;
  & h2 {
    color: white;
    font-weight: 500;
    margin-bottom: 2rem;
  }
  & p {
    color: #8d8d8d;
    font-size: 0.8rem;
    margin-bottom: 4px;
  }
  & a {
    color: ${(props) => props.theme.colors.accent.mediumBlue};
  }
`;

export const StyledFluidForm = styled(FluidForm)`
  overflow: hidden;
  width: 500px;
  @media screen and (max-width: 550px) {
    width: auto;
  }
`;

export const InputDiv = styled.div`
  width: 58%;
  margin-top: 4rem;
  margin-bottom: 5rem;
  @media screen and (max-width: 550px) {
    max-width: 320px;
    width: 100%;
  }
`;

export const StyledLink = styled(Link)`
  margin-left: 0.5rem;
  font-size: 1rem;
  color: ${(props) => props.theme.colors.primary.mediumBlue} !important;
  cursor: pointer;
  &:hover {
    color: ${(props) => props.theme.colors.accent.mediumBlue} !important;
  }
`;

export const NoMargLink = styled(StyledLink)`
  margin-left: 0;
`;

export const StyledTextInput = styled(TextInput)`
  margin-bottom: 0rem;
  width: 100%;
`;

export const StyledPasswordInput = styled(PasswordInput)`
  margin-bottom: 0rem;
  width: 100%;
`;

export const LoginButton = styled(Button)`
  width: 100%;
  margin-top: 0.3px;
  padding-right: 0.5rem;
  &:disabled {
    background-color: #8d8d8d;
    color: white;
  }
`;

export const BackArrow = styled(ArrowLeft16)`
  margin-right: 0.5rem;
  margin-bottom: -0.25rem;
`;

export const ButtonArrow = styled(ArrowRight16)`
  float: right;
  margin-left: 0.5rem;
`;

export const StyledInlineNotification = styled(InlineNotification)`
  /* width: 100%; */
  position: absolute;
  right: 0;
`;
