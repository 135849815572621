import { Send16 } from "@carbon/icons-react";
import {
  Button,
  DataTable,
  DataTableSkeleton,
  Modal,
  Search,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableHeader,
  TableRow,
  TableSelectRow,
  TextInput,
  Tile
} from "carbon-components-react";
import kit from "ich-ui-kit";
import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { updateDate } from "../../helpers/filter-helpers";
import { referralModalHeaders } from "../../utils";
import { shouldAutoComplete } from "../../../../utils";

const AssignReferralModal = ({
  reassigned,
  modalOpen,
  setModalOpen,
  referrals,
  setReferrals,
  setDisplayedItems,
  referralAssigned,
  updateAssignment,
  dateStart,
  dateEnd,
  selectServices,
  assignStatuses,
  referStatuses,
  filterBookmarked,
  parentRefers
}) => {
  const { user } = useContext(kit.UserContext);
  const { apiService } = useContext(kit.ApiContext);

  const [selected, setSelected] = useState("");
  const [selectedId, setSelectedId] = useState("");
  const [staff, setStaff] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingStaff, setLoadingStaff] = useState(true);
  const [displayStaff, setDisplayStaff] = useState([]);
  const [displayedNotes, setDisplayedNotes] = useState([]);
  const [noteText, setNoteText] = useState("");
  const headers = referralModalHeaders;
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    if (modalOpen) {
      const fetchStaff = async () => {
        setLoadingStaff(true);
        let serviceId = referralAssigned.serviceId;
        let locationId = referralAssigned.locationId;
        try {
          const providerInfo = await apiService.get(
            `/providerstaff/servicelocation?serviceId=${serviceId}&locationId=${locationId}`
          );
          if (providerInfo.ok) {
            setStaff(providerInfo.staff);
            setDisplayStaff(providerInfo.staff);
            setLoadingStaff(false);
          }
        } catch (err) {
          console.log(err);
        }
      };
      fetchStaff();
    }
  }, [modalOpen, referralAssigned]);

  const updateSelected = (selectionprops, row) => {
    if (selectionprops.checked) {
      setSelected(row[0].value);
      setSelectedId(row[0].id.split(":")[0]);
    }
  };

  const determinePrimaryButtonText = (index) => {
    if (index == 0) {
      return "Next";
    } else {
      return "Reassign";
    }
  };

  const determinePrimaryButtonDisabled = (index) => {
    if (index == 0) {
      return !selected;
    } else {
      return displayedNotes.length === 0;
    }
  };

  const handlePrimarySubmit = async () => {
    setModalOpen(false);
    const Mdata = [...referrals];
    Mdata.map((item) => {
      if (item?.id === referralAssigned?.id) {
        item.assignedTo = selected;
        item.assignedToId = selectedId;
      }
    });
    setReferrals(Mdata);
    updateDate(
      dateStart,
      dateEnd,
      setDisplayedItems,
      Mdata,
      selectServices,
      assignStatuses,
      referStatuses,
      filterBookmarked,
      parentRefers
    );
    if (displayedNotes[0]) {
      try {
        setLoading(true);
        const data = await apiService.post(
          `/referrals/note?assumedId=${user.superAssumedRole?.leadId || ""}`,
          {
            patientId: referralAssigned.referreeId,
            text: displayedNotes[0] ? displayedNotes[0].text : "",
            referralId: referralAssigned.id,
            serviceId: referralAssigned.serviceId,
            originatorId: displayedNotes[0].authorString,
            locationId: referralAssigned.locationId,
            orgSource: referralAssigned?.orgSource
          }
        );
      } catch (err) {
        console.log(err);
      }
      setLoading(false);
    }
    setDisplayedNotes([]);
    setCurrentIndex(0);
    updateAssignment(selected, selectedId, referralAssigned);
  };

  const handleRequestSubmit = (index) => {
    switch (index) {
      case 0:
        setCurrentIndex(index + 1);
        return;
      case 1:
        setNoteText("");
        handlePrimarySubmit();
        setSelected("");
        setDisplayedNotes([]);
        return;
    }
  };

  const handleEnter = (e) => {
    if (e.key === "Enter" && displayedNotes.length === 0) {
      addNote();
    }
  };

  const cleanDateTime = (str) => {
    let end = str.slice(-3);
    str = str.substring(0, str.lastIndexOf(":"));
    return str + end;
  };

  const addNote = async () => {
    if (noteText === "") {
      return;
    } else {
      try {
        setNoteText("");
        let dateTime = new Date();
        dateTime = dateTime.toLocaleString();
        let updatedNotes = displayedNotes;
        updatedNotes.push({
          text: "Reassignment reason: " + noteText,
          authorString: user.aliasName || user.fullName,
          timestamp: dateTime
        });
        setDisplayedNotes(displayedNotes.concat());
      } catch (err) {
        console.log(err);
      }
    }
    setLoading(false);
  };

  const renderContent = (index) => {
    switch (index) {
      case 0:
        return (
          <>
            {!loadingStaff && displayStaff ? (
              <DataTable rows={displayStaff} headers={headers} radio>
                {({
                  rows,
                  headers,
                  getHeaderProps,
                  getRowProps,
                  getSelectionProps,
                  getTableProps,
                  getTableContainerProps,
                  onInputChange
                }) => (
                  <TableContainer {...getTableContainerProps()}>
                    <StyledSearch
                      placeholder="Search"
                      onChange={onInputChange}
                    />
                    <Table {...getTableProps()}>
                      <TableHead>
                        <TableRow>
                          <th scope="col" />
                          {headers.map((header, i) => (
                            <TableHeader
                              key={header.header}
                              {...getHeaderProps({ header })}
                            >
                              {header.header}
                            </TableHeader>
                          ))}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {rows.map((row, i) => (
                          <TableRow key={i} {...getRowProps({ row })}>
                            <TableSelectRow
                              {...getSelectionProps({ row })}
                              onChange={updateSelected(
                                { ...getSelectionProps({ row }) },
                                row.cells
                              )}
                            />
                            {row.cells.map((cell) => (
                              <TableCell key={cell.value}>
                                {cell.value}
                              </TableCell>
                            ))}
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                )}
              </DataTable>
            ) : (
              <DataTableSkeleton />
            )}
          </>
        );
      case 1:
        return (
          <>
            <BorderedContainer>
              <NotesContainer>
                {(displayedNotes || []).map(
                  ({ authorString, timestamp, text }) => {
                    let dateTime = new Date(timestamp);
                    dateTime = dateTime.toLocaleString();
                    return (
                      <StyledTile key={timestamp}>
                        <FlexBaseline key={timestamp}>
                          <h5>{authorString}</h5>
                          <Timestamp>{cleanDateTime(dateTime)}</Timestamp>
                        </FlexBaseline>
                        <NoteText>{text}</NoteText>
                      </StyledTile>
                    );
                  }
                )}
              </NotesContainer>
              <NotesInputContainer>
                <TextInput
                  id="note-input"
                  labelText="Add Note"
                  hideLabel
                  placeholder="Add reason for reassignment..."
                  value={noteText}
                  onChange={(e) => setNoteText(e.target.value)}
                  onKeyPress={handleEnter}
                  style={{ borderBottom: "none" }}
                  disabled={displayedNotes.length !== 0}
                  light
                  autoComplete={shouldAutoComplete}
                />
                <Button
                  style={{ height: "100%", minHeight: 0 }}
                  hasIconOnly
                  renderIcon={Send16}
                  iconDescription="Add"
                  onClick={() => addNote()}
                  disabled={displayedNotes.length !== 0 || noteText === ""}
                />
              </NotesInputContainer>
            </BorderedContainer>
          </>
        );
    default:
      break;
  }
  };

  if (reassigned) {
    return (
      <StyledModal
        open={modalOpen}
        shouldSubmitOnEnter={false}
        size="md"
        primaryButtonText={determinePrimaryButtonText(currentIndex)}
        onRequestSubmit={() => handleRequestSubmit(currentIndex)}
        secondaryButtonText="Cancel"
        primaryButtonDisabled={determinePrimaryButtonDisabled(currentIndex)}
        onRequestClose={() => {
          setModalOpen(false);
          setSelected("");
          setCurrentIndex(0);
          setDisplayedNotes([]);
          setNoteText("");
        }}
        onSecondarySubmit={() => {
          setModalOpen(false);
          setSelected("");
          setCurrentIndex(0);
          setDisplayedNotes([]);
          setNoteText("");
        }}
      >
        <ModalWrapper>
          <TitleWrapper>
            <Title>Reassign Referral</Title>
            <Subtitle>
              {currentIndex === 0
                ? "Enter the Provider Staff name you would like to filter for"
                : "Provide a reason for reassigning to " + selected}
            </Subtitle>
          </TitleWrapper>
          <>{renderContent(currentIndex)}</>
          {/* <Subtitle>{referralAssigned.referralType} Referral to {referralAssigned.name} on behalf of {referralAssigned.referree} reassgined to {selected}</Subtitle> */}
        </ModalWrapper>
      </StyledModal>
    );
  } else {
    return (
      <StyledModal
        open={modalOpen}
        shouldSubmitOnEnter={false}
        size="md"
        primaryButtonText={"Assign"}
        secondaryButtonText={"Cancel"}
        primaryButtonDisabled={!selected}
        onRequestClose={() => {
          setModalOpen(false);
          setSelected("");
        }}
        onSecondarySubmit={() => {
          setModalOpen(false);
          setSelected("");
        }}
        onRequestSubmit={() => {
          handlePrimarySubmit();
          setSelected("");
        }}
      >
        <ModalWrapper>
          <TitleWrapper>
            <Title>Assign Referral</Title>
            <Subtitle>
              Enter the Provider Staff name you would like to filter for
            </Subtitle>
          </TitleWrapper>
          {!loadingStaff && displayStaff && displayStaff.length >= 0 ? (
            <DataTable rows={displayStaff} headers={headers} radio>
              {({
                rows,
                headers,
                getHeaderProps,
                getRowProps,
                getSelectionProps,
                getTableProps,
                getTableContainerProps,
                onInputChange
              }) => (
                <TableContainer {...getTableContainerProps()}>
                  <StyledSearch placeholder="Search" onChange={onInputChange} />
                  <Table {...getTableProps()}>
                    <TableHead>
                      <TableRow>
                        <th scope="col" />
                        {headers.map((header, i) => (
                          <TableHeader key={i} {...getHeaderProps({ header })}>
                            {header.header}
                          </TableHeader>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {rows.map((row, i) => (
                        <TableRow key={i} {...getRowProps({ row })}>
                          <TableSelectRow
                            {...getSelectionProps({ row })}
                            onChange={updateSelected(
                              { ...getSelectionProps({ row }) },
                              row.cells
                            )}
                          />
                          {row.cells.map((cell) => (
                            <TableCell>{cell.value}</TableCell>
                          ))}
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              )}
            </DataTable>
          ) : (
            <DataTableSkeleton />
          )}
        </ModalWrapper>
      </StyledModal>
    );
  }
};

const StyledModal = styled(Modal)`
  margin-right: 0;
  .bx--btn--primary {
    background-color: #0f62fe;
    :hover {
      background-color: #0f62fe90 !important;
    }
  }
  .bx--btn--secondary {
    background-color: #393939 !important;
    :hover {
      background-color: #39393990 !important;
    }
  }
  .bx--modal-content {
    @media screen and (max-width: 376px) {
      height: auto;
    }
  }
  .bx--modal-container {
    @media screen and (width: 375px) {
      height: 90%;
    }
    @media screen and (max-width: 416px) {
      height: 80%;
      width: 95%;
      top: 10%;
    }
  }
`;

export const StyledSearch = styled(Search)`
  margin-bottom: 1rem;
`;

const ModalWrapper = styled.div`
  margin-right: 0;
  height: 500px;
  width: 100%;
`;

const TitleWrapper = styled.div`
  justify-content: left;
  margin-bottom: 2rem;
`;

const Title = styled.h4``;

const Subtitle = styled.p``;

const BorderedContainer = styled.div`
  width: 100%;
  border: 1px solid #8d8d8d;
  margin-bottom: 1rem;
`;

const NotesInputContainer = styled.div`
  border-top: 1px solid #8d8d8d;
  display: flex;
  align-items: center;
  height: 3rem;
`;

const StyledTile = styled(Tile)`
  padding: 0.5rem 1rem;
  min-height: auto;
  background-color: #fff;
`;

const Timestamp = styled.p`
  color: gray;
  font-size: 0.75rem;
  margin-left: 1rem;
`;

const NotesSubheader = styled.p`
  font-size: 0.75rem;
  color: gray;
  margin-bottom: 0.5rem;
`;

const NoteText = styled.p`
  margin: 0.5rem 0;
`;

const FlexBaseline = styled.div`
  display: flex;
  align-items: baseline;
`;

const NotesContainer = styled.div`
  display: flex;
  flex-direction: column-reverse;
  max-height: 15rem;
  min-height: 15rem;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
`;

export default AssignReferralModal;
