import { useState, useEffect } from "react";
import AutocompleteAddressInput from "../../../../../../../components/AutocompleteAddress";
import { useCompleteProfile } from "../../../../cms";
import { StyledTextInput, StyledWrapper, TextInputWrapper, TextWrapper, TextInputRowAddressLine, TextInputRowCityStateZip } from "../AboutMe.styled";
import { shouldAutoComplete } from "../../../../../../../utils";

const Address = ({ setAboutMeResponse, setDisableButton }) => {
  const { stepsCMS: cms } = useCompleteProfile();
  const [addressValue, setAddressValue] = useState("");
  const [unitValue, setUnitValue] = useState("");
  const [lineList, setLineList] = useState(["", ""]);
  // SIPD-3943 | Breakout address into separate fields
  const [line1, setLine1] = useState("");
  const [line2, setLine2] = useState("");
  const [cityValue, setCityValue] = useState("");
  const [stateValue, setStateValue] = useState("");
  const [postalCode, setPostalCode] = useState("");

  const handleAddressChange = (val) => {
    setAddressValue(val);
    const parts = val.split(", ");
    setLine1(parts[0]);
    if (parts.length > 3) {
      setLine2(parts[1]);
    }
    setDisableButton(val === "");
    if (parts.length >= 3) { 
      setCityValue(parts[parts.length - 2]);
      const stateAndPostal = parts[parts.length - 1].split(" ");
      setPostalCode(stateAndPostal.pop().match(/\d+/g)?.toString());
      setStateValue(stateAndPostal.join(' '));
    }
  };
  const handleAutocompleteSelect = (selectedAddress) => {
    handleAddressChange(selectedAddress);
  };
  // SIPD-3943 | END
  useEffect(() => {
    if (addressValue != "") {
      setAboutMeResponse((state) => ({
        ...state,
        address: {
          use: "home",
          line: [line1, line2],
          city: cityValue,
          state: stateValue,
          postalCode: postalCode
        }
      }));
    }
  }, [line1, line2, cityValue, stateValue, postalCode, addressValue]);

  return (
    <StyledWrapper style={{flexDirection: 'column'}}>
      <TextWrapper>
        <p>{cms.address_text}</p>
      </TextWrapper>
      <TextInputWrapper>
        <TextInputRowAddressLine>
          <AutocompleteAddressInput
            id="line1"
            placeholder="Address Line 1"
            labelText="Address Line 1"
            value={line1}
            onChange={handleAddressChange}
            onSelect={handleAutocompleteSelect}
            autoComplete={true}
          />
          <StyledTextInput
            id="line2"
            labelText="Address Line 2"
            placeholder="Address Line 2"
            value={line2}
            onChange={(e) => setLine2(e.target.value)}
          />
        </TextInputRowAddressLine>
        <TextInputRowCityStateZip>
          <StyledTextInput
            id="city"
            labelText="City"
            placeholder="City"
            value={cityValue}
            onChange={(e) => setCityValue(e.target.value)}
          />
          <StyledTextInput
            id="state"
            labelText="State"
            placeholder="State"
            value={stateValue}
            onChange={(e) => setStateValue(e.target.value)}
          />
          <StyledTextInput
            id="postalCode"
            labelText="Zip Code"
            placeholder="Zip Code"
            value={postalCode}
            onChange={(e) => setPostalCode(e.target.value)}
          />
        </TextInputRowCityStateZip>
      </TextInputWrapper>
    </StyledWrapper>
  );
};

export default Address;
