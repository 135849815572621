import styled from "styled-components";
import { Button, Modal } from "carbon-components-react";

export const StyledModal = styled(Modal)`
  .bx--modal-container {
    max-width: 600px;
    @media screen and (min-width: 1024px) {
      margin-left: 15rem;
    }
  }
`;

export const InnerContainer = styled.div`
  width: 100%;
  max-width: 1500px;
  padding: 0 1rem;
  height: auto;
`;

export const Header = styled.div`
  margin: 1.5rem 0 2rem;
  display: flex;
  // flex-direction: row;
  flex-direction: column;
  margin: 1rem auto;
  align-items: center;
  justify-content: space-between;

  padding: 0 1rem;
  @media ${(props) => props.theme.breakpoints.md} {
    padding: 0;
    flex-direction: row;
  }
`;

export const CreateReferralButton = styled(Button)`
  margin: 1rem;

  @media ${(props) => props.theme.breakpoints.md} {
    margin: 0;
  }
`;

export const Container = styled.div`
  width: 100%;
  position: relative;
  padding: 1rem;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
`;

export const TableContent = styled.div`
  height: 70vh;
  width: 100%;
  overflow-y: auto;
`;
