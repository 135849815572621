import styled from "styled-components";
import { Dropdown, TextInput, Button } from "carbon-components-react";

export const Container = styled.div`
  width: 100%;
  height: 100%;
  height: auto;
  overflow-y: scroll;
  position: relative;
  /* background: paleturquoise; */
  padding: 1rem;

  && .bx--modal-content {
    overflow-y: visible;
    margin: 1rem;
    padding: 1rem;
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  && .bx--modal-container {
    overflow: visible;
    margin: 3rem;
    padding: 0rem;
    height: auto;
    @media only screen and (min-width: 760px) {
      max-width: 1260px;
    }
    @media only screen and (max-width: 768px) {
      margin: 3rem 0rem 0rem 0rem;
    }
  }
`;

export const Header = styled.div`
  margin: 1.5rem 0;
`;

export const Content = styled.div`
  /* padding: 0 0 */
`;

export const StyledDropdown = styled(Dropdown)`
  max-width: 20rem;
`;

export const StyledTextInput = styled(TextInput)`
  max-width: 20rem;
`;

export const ButtonContainer = styled.div`
  display: flex;
  gap: 1rem;
  margin-bottom: 2rem;
`;

export const StyledButton = styled(Button)`
  border: 1px solid ${(props) => props.theme.colors.accent.mediumBlue};
`;
