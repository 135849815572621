import React, { useState, useContext, useEffect } from "react";
import MyClients from "./components/MyClients.jsx";
import { Modal } from "carbon-components-react";
import WelcomeModal from "./components/WelcomeModal";
import {
  HomepageContainer,
  HomepageGrid,
  StyledToastNotification,
} from "./components/static.jsx";
import { useHistory } from "react-router-dom";
import kit from "ich-ui-kit";
import useModal from "../../hooks/useModal";
import SelectServiceModal from "./components/SelectServiceModal";
import AllClients from "./components/AllClients.jsx";
import NewClientSearch from "./components/NewClientSearch.jsx";
import PasswordResetModal from "../../components/PasswordWarningModal/index.js";
import RightSideBar from "../../components/right-sidebar-form/index.jsx";
import RegistrationForm from "../../components/registration-form/index.jsx";

import { useFeedbackCMS } from '../feedback/cms';

const showPasswordExpiry = false;

const ProviderHomePage = () => {
  const { apiService } = useContext(kit.ApiContext);
  const { user, updateUser } = useContext(kit.UserContext);
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [clients, setClients] = useState([]);
  const [bookmarks, setBookmarks] = useState([]);
  const [requestId, setRequestId] = useState("");
  const [requestedClientName, setRequestedClientName] = useState("");
  const [requestClientPhone, setRequestClientPhone] = useState("");
  const [requestClientDOB, setRequestClientDOB] = useState("");
  const [requestedClientLast, setRequestedClientLast] = useState("");
  const [requestedClientFirst, setRequestedClientFirst] = useState("");
  const [requestedMultipleClients, setRequestedMultipleClients] = useState(null);
  const [passiveModalContent, setPassiveModalContent] = useState("");
  const [selectedCareTeam, setSelectedCareTeam] = useState(null);
  const [showModal, toggle] = useModal();
  const [passiveModalOpen, togglePassiveModal] = useModal();
  const [flag, setFlag] = useState(false);
  const [hasCompletedAttestation, setHasCompletedAttestation] = useState(false);
  const [enrollUser, setEnrollUser] = useState(false);

  const { content: feedbackCms } = useFeedbackCMS();

  useEffect(() => {
    if (hasCompletedAttestation) {
      window.location.reload(true);
    }
  }, [hasCompletedAttestation]);

  useEffect(() => {
    const fetchClients = async () => {
      setLoading(true);
      let patientsData;
      if (user.superAssumedRole?.leadId && !user.is211Admin) {
        patientsData = await apiService.get(
          `/clients?assumedId=${user.superAssumedRole.leadId}`
        );
        if (patientsData && Array.isArray(patientsData)) {
          setClients(patientsData || []);
        }
      } else if (!user.is211Admin && !user.isSuper) {
        patientsData = await apiService.get(
          `/clients?assumedId=${user.superAssumedRole?.leadId || ""}`
        );
        if (patientsData && Array.isArray(patientsData)) {
          setClients(patientsData || []);
        }
        //  else {
        // TODO: pull into global error component
        //   setPassiveModalContent(
        //     "Error retrieving Connected Clients."
        //   );
        //   togglePassiveModal();
        // }
      } else {
        patientsData = await apiService.get(
          `/account/users?assumedId=${user.superAssumedRole?.leadId || ""}`
        );
        if (
          patientsData &&
          patientsData.clients &&
          Array.isArray(patientsData.clients)
        ) {
          setClients(patientsData.clients);
        }
      }
      setLoading(false);
    };
    let isMounted = true;
    // Only attempt to pull patients list
    if (!user.firstLogin) {
      if (isMounted) {
        fetchClients();
      }
    }

    return () => {
      isMounted = false;
    };
  }, []);

  useEffect(() => {
    const shouldRequestFeedback = async () => {
      const response = await apiService.get(
        '/providerstaff/feedback/request'
      );

      if (response && response.requestFeedback) {
        updateUser({ ...user, requestFeedback: true});
      } else {
        updateUser({ ...user, requestFeedback: false});
      }
    };

    let isMounted = true;
    if (isMounted && user && user?.isSuper && feedbackCms?.enabled) {
      updateUser({ ...user, requestFeedback: true });
    } else if (isMounted && user && user?.id && feedbackCms?.enabled) {
      shouldRequestFeedback();
    } else {
      updateUser({ ...user, requestFeedback: false });
    }

    return () => {
      isMounted = false;
    };
  }, [apiService, feedbackCms]);

  const renderToast = () => {
    setFlag(true);
    setTimeout(() => {
      setFlag(false);
    }, 5000);
  };

  const clearSearch = async () => {
    const fetchClients = async () => {
      setLoading(true);
      let clients = await apiService.get(
        `/clients?assumedId=${user.superAssumedRole?.leadId || ""}`
      );
      if (clients && Array.isArray(clients)) {
        setClients(clients);
      }
      setLoading(false);
    };
    // Only attempt to pull patients list
    if (!user.firstLogin) {
      fetchClients();
    }
  };

  const handleSearch = async (searchInput) => {
    try {
      setLoading(true);
      const response = await fetch(
        `${process.env.REACT_APP_API_ENDPOINT}/search/clients`,
        {
          method: "post",
          headers: {
            Authorization: `Bearer ${user.accessToken} ${user.idToken}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(searchInput),
        }
      );

      const { clients, id, name, phone, found } = await response.json();
      if (response.status === 200) {
        setClients(clients);
      } else if (response.status === 409) {
        setClients([]);
        if (id) {
          setRequestId(id);
          setRequestedClientName(
            name || searchInput.first + " " + searchInput.last
          );
        } else if (found) {
          setRequestedMultipleClients(found);
          setRequestedClientName(
            name || searchInput.first?.trim() + " " + searchInput.last?.trim()
          );
        }
        toggle();
      } else if (response.status === 400) {
        setPassiveModalContent(
          "A required field may be missing. Please check your search input and try again."
        );
        togglePassiveModal();
      } else if (response.status === 404) {
        setRequestId(null);
        setRequestClientPhone(searchInput.phone);
        setRequestedClientFirst(searchInput.first);
        setRequestedClientLast(searchInput.last);
        setRequestClientDOB(searchInput.dob);
        toggle();
      } else {
        setPassiveModalContent(
          "An issue was encountered while searching. Please try again."
        );
        togglePassiveModal();
      }
    } catch (err) {
      console.log(err);
      setPassiveModalContent(
        "An issue was encountered while searching. Please try again."
      );
      togglePassiveModal();
    }
    setLoading(false);
  };

  const clientDashboard = (id) => {
    history.push({
      pathname: "/360dashboard",
      state: {
        client: id,
      },
    });
  };

  const createReferral = (id) => {
    history.push({
      pathname: "/service-directory",
      state: {
        patientId: id,
      },
    });
  };

  return (
    <>
      <HomepageContainer>
        {flag && (
          <StyledToastNotification
            kind="success"
            lowContrast={true}
            title="Request Sent!"
            subtitle=""
            role="alert"
          />
        )}
        <WelcomeModal setIsComplete={setHasCompletedAttestation} />
        <SelectServiceModal
          showModal={showModal}
          toggle={toggle}
          selectedCareTeam={selectedCareTeam}
          setSelectedCareTeam={setSelectedCareTeam}
          staffId={user.superAssumedRole?.leadId || user.id}
          clientId={requestId}
          clientName={requestedClientName}
          clientPhone={requestClientPhone}
          renderToast={renderToast}
          setEnrollUser={setEnrollUser}
          setClientId={setRequestId}
          requestedMultipleClients={requestedMultipleClients}
          setRequestedMultipleClients={setRequestedMultipleClients}
        />
        <Modal
          open={passiveModalOpen}
          modalHeading="Client Search"
          size="sm"
          passiveModal={true}
          onRequestClose={togglePassiveModal}
        >
          {passiveModalContent}
        </Modal>
        {user.is211Admin || user.isSuper ? (
          <AllClients
            loading={loading}
            clients={clients}
            clientDashboard={clientDashboard}
            createReferral={createReferral}
          />
        ) : (
          <HomepageGrid>
            <NewClientSearch
              handleSearch={handleSearch}
              clearSearch={clearSearch}
            />
            <MyClients
              loading={loading}
              clients={clients}
              clientDashboard={clientDashboard}
              createReferral={createReferral}
              setClients={setClients}
              isSuper={user.superAssumedRole}
            />
          </HomepageGrid>
        )}
      </HomepageContainer>
      {enrollUser && (
        <RightSideBar>
          <RegistrationForm
            header="Read the Account Guidelines and Consent information to be sure that your client agrees to be enrolled in MyWayfinder."
            setEnrollUser={setEnrollUser}
            selectedCareTeam={selectedCareTeam}
            requestClientDOB={requestClientDOB}
            requestedClientFirst={requestedClientFirst}
            requestedClientLast={requestedClientLast}
            requestClientPhone={requestClientPhone}
          ></RegistrationForm>
        </RightSideBar>
      )}
      {/* <PasswordResetModal /> */}
      {showPasswordExpiry && user.isPasswordExpirationWarned === false && <PasswordResetModal />}
    </>
  );
};

export default ProviderHomePage;
