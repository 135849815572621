// import { Button } from 'carbon-components';
import { useHistory, useLocation } from "react-router-dom";
import S from "./clientExitBanner.module.scss";
import CustomProgressBar from "../../../../components/CustomProgressBar";

const ClientExitBanner = ({ clientInfo, numItems, totalNumItems }) => {
  let history = useHistory();

  return (
    <div className={S.container}>
      <CustomProgressBar
        numItems={numItems}
        totalNumItems={totalNumItems}
        showPercentageText={false}
      />
      <div className={S.bannerPrompt}>
        Completing Client Enrollment for:
        {history.location?.state?.clientFirstName && ` for: `}
        <strong className={S.clientName}>
          {clientInfo?.firstName || history.location?.state?.clientFirstName}{" "}
          {clientInfo?.lastName || history.location?.state?.clientLastName}
        </strong>
        <button
          kind="ghost"
          className={S.button}
          // TODO: Add API Call for removing user
          onClick={() => {
            history.push({
              pathname: "/",
              state: {},
            });
          }}
        >
          Cancel Enrollment
        </button>
      </div>
    </div>
  );
};

export default ClientExitBanner;
