import React, { useContext, useEffect, useState } from "react";
import {
  Card,
  Container,
  NotificationsGrid,
  SingleColumn,
  TitleRow,
  ModalContentContainer,
  StyledTableContainer
} from "../../components";
import {
  Modal,
  OverflowMenu,
  OverflowMenuItem,
  DataTable,
  DataTableSkeleton,
  Table,
  TableToolbar,
  TableToolbarContent,
  TableToolbarSearch,
  TableHead,
  TableRow,
  TableExpandRow,
  TableExpandedRow,
  TableExpandHeader,
  TableHeader,
  TableBody,
  TableCell
} from "carbon-components-react";
import { OverflowMenuVertical32 } from "@carbon/icons-react";
import kit from "ich-ui-kit";
import ProviderStaffManagement from "../ProviderStaffManagement";

const headers = [
  {
    key: "name",
    header: "Organization Name",
    isSortable: true,
  },
  // {
  //   key: "lastName",
  //   header: "Last Name"
  // },
  // {
  //   key: "email",
  //   header: "Email"
  // },
  // {
  //   key: "completedFirstLogin",
  //   header: "First Login",
  //   hidden: true
  // },
  // {
  //   key: "created",
  //   header: "Creation Date",
  //   hidden: true
  // },
  // {
  //   key: "lastLogin",
  //   header: "Last Login",
  //   hidden: true
  // },
  {
    key: "action",
    header: "Actions",
    isSortable: false,
  }
];

const deactivateOrgText = `
By clicking "Deactivate" you are agreeing to remove access to MyWayfinder for all staff accounts under this organization.
Deactivating will remove this organization from all client care teams and they will need to be added back
should they choose to return. This will not erase any client health data, but staff members will no longer have access to it.
`;

const deactivateStaffText = `By clicking "Deactivate" you are agreeing to remove access
to MyWayfinder for this community member.`;

const deactivateStaffSuccessText = `Account deactivated successfully.`;

const ProviderManagement = () => {
  const { apiService } = useContext(kit.ApiContext);
  const { user } = useContext(kit.UserContext);
  const [users, setUsers] = useState([]);
  const [orgToDeactivate, setProvToDeactivate] = useState(null);
  const [loading, setLoading] = useState(true);
  const [deactivateOpen, setDeactivateOpen] = useState(false);
  const [deactivateLoading, setDeactivateLoading] = useState(false);
  const [deactivateModalText, setDeactivateModalText] =
    useState(deactivateOrgText);
  const [showStaffList, setShowStaffList] = useState(false);
  const [selectedProvider, setSelectedProvider] = useState();

  useEffect(() => {
    const getConnectedProviders = async () => {
      try {
        const data = await apiService.get(
          `/providers`
        );
        const communityUser = data?.providers?.map((prov) => ({
          ...prov,
          action: (
            <OverflowMenu
              ariaLabel="Options"
              flipped
              renderIcon={OverflowMenuVertical32}
            >
              <OverflowMenuItem
                itemText="Deactivate Provider"
                onClick={() => {
                  openModal(prov.id);
                }}
              />
              <OverflowMenuItem
                itemText="View Staff"
                onClick={() => {
                  setSelectedProvider(prov.id);
                  setShowStaffList(true);
                }}
              />
            </OverflowMenu>
          )
        }));
        setUsers(communityUser);
      } catch (err) {
        console.log(err);
      }
      setLoading(false);
    };
    getConnectedProviders();
  }, [apiService]);

  const fetchConnectedProviders = async () => {
    try {
      const data = await apiService.get(`/providers`);
      const communityUser = data?.providers?.map((prov) => ({
        ...prov,
        action: (
          <OverflowMenu
            ariaLabel="User Options"
            flipped
            renderIcon={OverflowMenuVertical32}
          >
            <OverflowMenuItem
              itemText="Deactivate Provider"
              onClick={() => {
                openModal(prov.id);
              }}
            />
            <OverflowMenuItem
              itemText="View Staff"
              onClick={() => {
                selectedProvider(prov.id);
                setShowStaffList(true);
              }}
            />
          </OverflowMenu>
        )
      }));
      setUsers(communityUser);
    } catch (err) {
      console.log(err);
    }
    setLoading(false);
  };

  const deactivateOrg = async () => {
    try {
      setDeactivateLoading(true);
      const data = await apiService.post(`/providers/${user.id}/deactivate?assumedId=${user.superAssumedRole?.leadId || ""}`, {
        orgId: orgToDeactivate,
        sourceSystem: user.orgSource || '211'
      });
      if (data.ok) {
        setDeactivateModalText(deactivateStaffSuccessText);
        window.setTimeout(async () => {
          await fetchConnectedProviders();
          closeModal();
        }, 2000);
      }
    } catch (err) {
      console.log(err);
    }
    setDeactivateLoading(false);
  };

  const openModal = (id) => {
    if (id) {
      setProvToDeactivate(id);
      setDeactivateModalText(deactivateOrgText);
      setDeactivateOpen(true);
    }
  };

  const closeModal = () => {
    setProvToDeactivate(null);
    setDeactivateOpen(false);
  };

  const handleRequestSubmit = async () => {
    if (orgToDeactivate) {
      await deactivateOrg();
    }
  };

  return showStaffList ? (
    <ProviderStaffManagement
      orgId={selectedProvider}
      goBack={() => setShowStaffList(false)}
    />
  ) : (
    <NotificationsGrid>
      <Modal
        open={deactivateOpen}
        danger
        onRequestSubmit={handleRequestSubmit}
        onRequestClose={() => closeModal()}
        primaryButtonDisabled={deactivateLoading}
        modalHeading="Are you sure?"
        modalLabel=""
        primaryButtonText="Deactivate"
        secondaryButtonText="Cancel"
      >
        <ModalContentContainer>
          <p>{deactivateModalText}</p>
        </ModalContentContainer>
      </Modal>
      <SingleColumn>
        <TitleRow>Provider Management</TitleRow>
        <>
          <Card style={{ backgroundColor: "inherit", boxShadow: "none" }}>
            <Container>
              {loading ? (
                <DataTableSkeleton />
              ) : (
                <DataTable rows={users} headers={headers} isSortable="true">
                  {({
                    rows,
                    headers,
                    getTableProps,
                    getHeaderProps,
                    getRowProps,
                    onInputChange,
                    getTableContainerProps
                  }) => (
                    <StyledTableContainer
                      title="Connected Providers"
                      {...getTableContainerProps()}
                    >
                      <TableToolbar>
                        <TableToolbarContent
                          style={{
                            justifyContent: "left",
                            maxWidth: "30rem"
                          }}
                        >
                          <TableToolbarSearch
                            onChange={onInputChange}
                            placeholder="Filter"
                            persistent="true"
                            style={{ border: "1px solid blue" }}
                          />
                        </TableToolbarContent>
                      </TableToolbar>
                      <Table {...getTableProps()}>
                        <TableHead>
                          <TableRow>
                            {/* <TableExpandHeader /> */}
                            {headers.map((header) => (
                              <TableHeader {...getHeaderProps({ header })}
                                isSortable={header.isSortable}
                                isSortHeader={header.isSortHeader}
                                sortDirection={header.sortDirection}
                              >
                                {header.header}
                              </TableHeader>
                            ))}
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {rows.map((row) => (
                            <TableRow {...getRowProps({ row })}>
                              {row.cells.map((cell) => (
                                <TableCell key={cell.id}>
                                  {cell.value || "Not Provided"}
                                </TableCell>
                              ))}
                            </TableRow>
                            // <React.Fragment key={row.id}>
                            //   <TableExpandRow {...getRowProps({ row })}>
                            //     {row.cells.map((cell) => (
                            //       <TableCell key={cell.id}>
                            //         {cell.value || "Not Provided"}
                            //       </TableCell>
                            //     ))}
                            //   </TableExpandRow>
                            //   <TableExpandedRow colSpan={headers.length + 1}>
                            //     <h5>Account Creation Date:</h5>
                            //     <p>{row.cells[4].value}</p>
                            //     <h5>Last Login:</h5>
                            //     <p>{row.cells[5].value}</p>
                            //     <h5>Completed First Login Flow:</h5>
                            //     <p>{!!row.cells[3].value ? "True" : "False"}</p>
                            //   </TableExpandedRow>
                            // </React.Fragment>
                          ))}
                        </TableBody>
                      </Table>
                    </StyledTableContainer>
                  )}
                </DataTable>
              )}
            </Container>
          </Card>
        </>
      </SingleColumn>
    </NotificationsGrid>
  );
};
export default ProviderManagement;
