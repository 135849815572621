import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router";
import { CSVLink } from 'react-csv'
import kit from "ich-ui-kit";
import {
  DataTable,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableHeader,
  TableBody,
  TableCell,
  TableToolbar,
  TableToolbarContent,
  TableToolbarSearch,
  Pagination,
  StructuredListSkeleton,
  Button,
} from "carbon-components-react";
import { Page, HeaderContainer, FlexCenter, NoDataText } from "./dashboard.styled";
import { Renew20, Download32 } from "@carbon/icons-react";
import { Loading } from "carbon-components-react";
import { TitleRow } from "../components";

function LongitudinalRecord({ orgId, clients, isLoadingClients }) {
  const [data, setData] = useState([]);
  const { apiService } = useContext(kit.ApiContext);
  const { user } = useContext(kit.UserContext);
  const [isLoading, setIsLoading] = useState(false);
  const [firstRowIndex, setFirstRowIndex] = useState(0);
  const history = useHistory();

  useEffect(() => {
    getLongitudinalRecord();
  }, [clients]);

  const getLongitudinalRecord = async () => {
    setIsLoading(true);
    let response;
    if(!user.is211Admin){
      let clientIds = clients?.map((client) => client.id);
      response = await apiService.get(
        `/eventhistory/provider?assumedId=${user.superAssumedRole?.leadId || ""}`,
        {
          orgId: orgId,
          clientIds: clientIds
        }
      );
    } else {
      response = await apiService.get(
        "/eventhistory");
    }
    if (response.ok) {
      let rows = response.eventhistory.map((r) => ({
        ...r,
        createdAt: new Date(r.createdAt).toLocaleString() // converting from UTC to local time
      }));
      setData((state) => [...rows]);
      setIsLoading(false);
    } else {
      console.log(response.message);
      setIsLoading(false);
    }
  };

  const formattedRows = data.map((el, i) => ({ ...el, id: String(i) }));

  const headers = [
    {
      key: "createdAt",
      header: "Date/Time",
      isSortable: true,
      isSortHeader: true
    },
    {
      key: "eventName",
      header: "Event Name",
      isSortable: false
    },
    {
      key: "eventSummary",
      header: "Event Summary",
      isSortable: false
    }
  ];

  return (
    <Page style={{padding: "0 1rem"}}>
      <HeaderContainer style={{ justifyContent: "space-between", margin: "0" }}>
        <TitleRow>Event History</TitleRow>
        {(isLoading || isLoadingClients) ? (
          <Loading withOverlay={false} small />
        ) : (
          <Renew20 onClick={getLongitudinalRecord} cursor="pointer" />
        )}
      </HeaderContainer>
      {(isLoading || isLoadingClients) ? (
        <StructuredListSkeleton style={{ marginTop: "0.5rem" }} />
      ) : (
        <>
          {data.length > 0 ? (
            <>
              <DataTable
                rows={formattedRows.slice(firstRowIndex, firstRowIndex + 50)}
                headers={headers}
                isSortable
              >
                {({
                  rows,
                  headers,
                  getTableProps,
                  getHeaderProps,
                  getRowProps,
                  onInputChange
                }) => (
                  <TableContainer>
                    <TableToolbar>
                      <TableToolbarContent
                        style={{
                          justifyContent: "space-between",
                        }}
                      >
                        <TableToolbarSearch
                          onChange={onInputChange}
                          placeholder="Filter"
                          persistent={true}
                          style={{ border: "1px solid blue", maxWidth: "30rem" }}
                        />
                        <CSVLink 
                          data={formattedRows} 
                          headers={headers} 
                          filename={`event-history-${new Date().toISOString().split("T")[0]}`}
                        >
                          <Button iconDescription='Download' hasIconOnly renderIcon={Download32} />
                        </CSVLink>
                      </TableToolbarContent>
                    </TableToolbar>
                    <Table {...getTableProps()}>
                      <TableHead>
                        <TableRow>
                          {headers.map((header) => (
                            <TableHeader
                              {...getHeaderProps({
                                header
                              })}
                              isSortable={header.isSortable}
                              isSortHeader={header.isSortHeader}
                            >
                              {header.header}
                            </TableHeader>
                          ))}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {rows.map((row) => {
                          return (
                            <TableRow key={row.id} {...getRowProps({ row })}>
                              {row.cells.map((cell) => {
                                let entireRow = formattedRows.find((f) => f.id == row.id);
                                if (cell.id.includes("eventSummary") && entireRow.relatedObjectId?.includes("Referral")) {
                                  let splitvalue = cell.value.split(".")
                                  let refid = entireRow.relatedObjectId.split(": ")[1]
                                  let addition = "Referral " + refid + ".";
                                  if (entireRow.relatedClientId && refid !== "undefined") {
                                    return <TableCell key={Math.random()} style={{ cursor: "pointer" }}
                                    onClick={(e) => {
                                      history.push({
                                        pathname: "/referral-dashboard/referral",
                                        state: {
                                          clientId: entireRow.relatedClientId,
                                          referralId: refid,
                                          cameFrom: "provider-event-history",
                                          fromFeed: "provider-event-history",
                                          onCareTeam: clients?.find((c) => c?.id === entireRow?.relatedClientId) ? true : false
                                        }
                                      });
                                  }}>{splitvalue[0] + ": "}<a>{addition}</a></TableCell>
                                  } else {
                                    return <TableCell key={Math.random()} style={{ cursor: "pointer" }}>{splitvalue[0] + ": "+addition}</TableCell>
                                  }
                                } else {
                                  return <TableCell key={Math.random()}>{cell.value}</TableCell>
                                }
                             })}
                            </TableRow>
                          );
                        })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                )}
              </DataTable>
              <Pagination
                style={{
                  overflowX: "hidden"
                }}
                backwardText="Previous page"
                forwardText="Next page"
                itemsPerPageText="Items per page:"
                pageNumberText="Page Number"
                pageSize={50}
                pageSizes={[50]}
                totalItems={formattedRows.length}
                pageSizeInputDisabled={true}
                onChange={({ page }) => {
                  setFirstRowIndex(50 * (page - 1));
                }}
              />
            </>
          ):(
            <FlexCenter>
              <NoDataText>No Data to display. Please refresh or try again later.</NoDataText>
            </FlexCenter>
          )}
        </>
      ) }
    </Page>
  );
}
export default LongitudinalRecord;
