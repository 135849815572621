export const languageItems = (cms) => {
  const init = [
    {
      id: 11,
      show: "Select Language",
      text: "Select Language",
      key: "default"
    },
    {
      id: 13,
      show: "English",
      text: "English",
      key: "english"
    },
    {
      id: 14,
      show: "Spanish",
      text: "Spanish",
      key: "spanish"
    },
    {
      id: 15,
      show: "American Sign Language",
      text: "American Sign Language",
      key: "sign_language"
    },
    {
      id: 49,
      show: "Swahili",
      text: "Swahili",
      key: "swahili"
    },
    {
      id: 50,
      show: "Somali",
      text: "Somali",
      key: "somali"
    },
    {
      id: 51,
      show: "French",
      text: "French",
      key: "french"
    },
    {
      id: 52,
      show: "Arabic",
      text: "Arabic",
      key: "arabic"
    },
    {
      id: 53,
      show: "Karen",
      text: "Karen",
      key: "karen"
    },
    {
      id: 54,
      show: "Kareni",
      text: "Kareni",
      key: "kareni"
    },
    {
      id: 55,
      show: "Nepal",
      text: "Nepal",
      key: "nepal"
    },
    {
      id: 56,
      show: "Burmese",
      text: "Burmese",
      key: "burmese"
    },
    {
      id: 12,
      text: "Not Listed(Specify)",
      show: "Not Listed(Specify)",
      key: "not_listed"
    }
  ];
  return init;
};
