// import { useNavDispatch, useNavState } from '../../context/nav.context';
import S from './right-sidebar.module.scss';

const RightSideBar = ({ children }) => {
  // const navState = useNavState();

  return (
    // <div className={S.container} hidden={!navState.right}>
    <div className={S.container} >
      {/* {!navState.right ? undefined : children} */}
      {children}
    </div>
  );
};
export default RightSideBar;
