import React, { useState, useContext } from "react";
import styled from "styled-components";

import AboutMe from "./steps/AboutMe/AboutMe";
import Complete from "./steps/Complete";
import kit from "ich-ui-kit";
import Step1of3 from "./steps/Step1of3";
import Step2of3 from "./steps/Step2of3";
import Step3of3 from "./steps/Step3of3";
import { ProgressIndicator, ProgressStep } from "carbon-components-react";

export const ProgressIndicatorBar = ({ currentIndex }) => {
  const populateProgressBar = () => {
    const barList = [];
    for (let i = 0; i < 11; i++) {
      barList.push(
        <ProgressStep key={i} label="" secondaryLabel="" description="" />
      );
    }
    return barList;
  };
  return (
    <>
      <ProgressIndicator currentIndex={currentIndex} spaceEqually={true}>
        {populateProgressBar()}
      </ProgressIndicator>
    </>
  );
};

const GuidedExperience = ({
  setOverallIndex,
  clientID,
  clientInfo,
  setCompletedStatus,
}) => {
  const extractLastName = (str) => {
    if (str !== undefined) {
      let res = str.split(" ");
      return res[res.length - 1];
    } // incase of middle name return last index
    return "";
  };
  const { user } = useContext(kit.UserContext);
  const [controllerIndex, setControllerIndex] = useState(0);
  const [aboutMeIndex, setAboutMeIndex] = useState(0);
  const [emergencyContactInfo, setEmergencyContactInfo] = useState({
    firstname: "",
    lastname: "",
    phone: "",
    relationship: "",
    email: "",
  });
  const [aboutMeResponse, setAboutMeResponse] = useState({
    givenName: clientInfo?.firstName || clientInfo?.givenName,
    familyName: clientInfo?.lastName || extractLastName(clientInfo?.fullName),
    email: clientInfo?.username,
    phone: clientInfo?.phone || "",
    dob: "",
    ssn: "",
    nickName: "",
    maritalStatus: "",
    genderIdentity: "",
    gender: "",
    ethnicity: "",
    hispanicOrigin: "",
    race: "",
    language: "",
    address: {
      use: "",
      line: ["", ""],
      city: "",
      state: "",
      postalCode: "",
    },
    eContact: emergencyContactInfo,
  });
  const [housingOccupancy, setHousingOccupancy] = useState(0);

  const ContentController = () => {
    if (controllerIndex === 0) {
      return (
        <Step1of3
          setControllerIndex={setControllerIndex}
          setOverallIndex={setOverallIndex}
          setAboutMeIndex={setAboutMeIndex}
          clientInfo={clientInfo}
          clientID={clientID}
        />
      );
    }
    if (controllerIndex % 2 !== 0) {
      return (
        <AboutMe
          setControllerIndex={setControllerIndex}
          setAboutMeResponse={setAboutMeResponse}
          aboutMeResponse={aboutMeResponse}
          setEmergencyContactInfo={setEmergencyContactInfo}
          emergencyContactInfo={emergencyContactInfo}
          aboutMeIndex={aboutMeIndex}
          setAboutMeIndex={setAboutMeIndex}
          index={controllerIndex}
          setOverallIndex={setOverallIndex}
          housingOccupancy={housingOccupancy}
          setHousingOccupancy={setHousingOccupancy}
          clientInfo={clientInfo}
          clientID={clientID}
        />
      );
    }
    if (controllerIndex === 2) {
      return (
        <Step2of3
          setControllerIndex={setControllerIndex}
          setOverallIndex={setOverallIndex}
          setAboutMeIndex={setAboutMeIndex}
          clientInfo={clientInfo}
          clientID={clientID}
        />
      );
    }
    if (controllerIndex === 4) {
      return (
        <Step3of3
          setControllerIndex={setControllerIndex}
          setAboutMeIndex={setAboutMeIndex}
          setOverallIndex={setOverallIndex}
          clientInfo={clientInfo}
          clientID={clientID}
        />
      );
    }
    if (controllerIndex === 6) {
      return (
        <Complete
          aboutMeResponse={aboutMeResponse}
          clientInfo={clientInfo}
          clientID={clientID}
          setCompletedStatus={setCompletedStatus}
        />
      );
    }
  };

  return (
    <ContentContainer>
      <InnerContentWrapper>{ContentController()}</InnerContentWrapper>
    </ContentContainer>
  );
};

export default GuidedExperience;

const ContentContainer = styled.div`
  background-color: white;
  z-index: 10;
  min-height: 100%;
  height: fit-content;
  max-width: 800px;
  margin: auto;
  border: 1px solid lightgrey;
  @media screen and (min-width: 1800px) {
    max-width: 900px;
  }
  @media screen and (max-height: 768px) {
    min-height: 400px;
    height: fit-content;
    background-color: white;
    border: none;
    max-width: 100%;
  }
`;
const InnerContentWrapper = styled.div`
  min-height: 100%;
  height: fit-content;
  background-color: white;
  width: 100%;
  padding-right: 8%;
  padding-left: 8%;
  /* padding-top: 0.5%; */
  @media screen and (max-width: 800px) {
    padding-left: 1%;
    padding-right: 1%;
  }
`;
