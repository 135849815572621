import React, { useState, useEffect, useContext } from "react";
import { useNavigate, useHistory } from "react-router-dom";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import kit from "ich-ui-kit";
import LoginPage from "./pages/login";
import CreateAccountPage from "./pages/create-account";
import AccountPage from "./pages/account";
// import ResourcesPage from "./pages/resources";
import ElasticSearch from "./pages/elasticsearch";
import UIShell from "./components/UIShell";
import { ActivityFeedProvider } from "./store/ActivityFeedContext";
import { CallLogProvider } from "./store/CallLogContext";
import { NavProvider } from "./store/NavContext";
import { FeedbackProvider } from "./store/FeedbackContext";
import { GeolocationProvider } from "./store/GeolocationContext";
import { BusStopsProvider } from "./store/BusStopsContext";
import "./App.css";
import ProviderHomePage from "./pages/new-landing-page";
import ResetPasswordPage from "./pages/reset-password";
import AssessmentPage from "./pages/assessments";
import AboutPage from "./pages/about";
import FeedPage from "./pages/feed";
import ProviderInfoPage from "./pages/provider-info";
import ServiceInfoPage from "./pages/service-info";
import ReferralDashboardPage from "./pages/referral-dashboard";
import PatientDashboard from "./pages/PatientDashboard";
import CreateReferralPage from "./pages/create-referral";
import ReferralConfirmationPage from "./pages/confirm-referral";
import FeedbackPage from "./pages/feedback";
import { ThemeProvider } from "styled-components";
import Faq from "./pages/faq";

import ClientEnrollment from "./pages/client-enrollment";

//Idle Timer
import { IdleTimer } from "./components/IdleTimer";

//styling
import { theme } from "./theme/theme";
import { GlobalStyle } from "./theme/globalStyle";
import ReferralDetails from "./pages/referral-details";
import PoliciesProcedures from "./pages/policies-and-procedures";
import TermsConditions from "./pages/terms-and-conditions";
import ImpactPage from "./pages/impact";
import ErrorPage from "./pages/errorpage";
import ContactPage from "./pages/contact-us";

function App() {
  const urlOrigin = window.location.href;
  const [valid, setValid] = useState(true);
  const channel = new BroadcastChannel("monroe-sip-provider");

  useEffect(() => {
    let currentUser = sessionStorage.getItem("penn-provider")
        ? JSON.parse(sessionStorage.getItem("penn-provider"))
        : null;
    let haveCurrentUser =
      currentUser &&
      Object.keys(currentUser).filter((key) => key !== "notifications").length >
        0;
    if (!haveCurrentUser) {
      channel.postMessage({ key: "get-info" });
    }
  }, []);

  function PrivateRoute({ children, ...rest }) {
    return (
      <Route
        {...rest}
        render={() => {
          return valid === true ? children : <Redirect to="/login" />;
        }}
      />
    );
  }

  const renderRoutes = ({ user, updateUser, clearUser }) => {
    channel.onmessage = function (e) {
      let currentUser = sessionStorage.getItem("penn-provider")
        ? JSON.parse(sessionStorage.getItem("penn-provider"))
        : null;
      let haveCurrentUser =
        currentUser &&
        Object.keys(currentUser).filter((key) => key !== "notifications")
          .length > 0;
      if (e.data?.key === "user" && e.data?.user && !haveCurrentUser) {
        let userData = JSON.parse(e.data.user);
        if (
          Object.keys(user).filter((key) => key !== "notifications").length ===
            0 &&
          userData &&
          Object.keys(userData).filter((key) => key !== "notifications")
            .length > 0 &&
          !haveCurrentUser
        ) {
          sessionStorage.setItem("penn-provider", JSON.stringify(userData));
          updateUser(userData);
          if (urlOrigin !== window.location.href) {
            window.location.href = urlOrigin;
          }
        }
      }
      if (e.data?.key === "get-info") {
        if (
          user &&
          Object.keys(user).filter((key) => key !== "notifications").length > 0
        ) {
          channel.postMessage({ user: JSON.stringify(user), key: "user" });
        }
      }
      if (e.data?.key === "logout") {
        clearUser();
        localStorage.clear();
        sessionStorage.removeItem("penn-provider");
        window.location.reload();
      }
    };

    let authenticated =
      Object.keys(user).filter((key) => key !== "notifications").length !== 0;
    if (authenticated) {
      if (
        user.isViewOnly ||
        (user.superAssumedRole?.role &&
          user.superAssumedRole.role === "view_only")
      ) {
        // Authenticated Routes - View Only Role
        return (
          <NavProvider>
            <ActivityFeedProvider>
              <FeedbackProvider>
                <Router id="router">
                  <UIShell
                    id="uishell"
                    style={{ width: "100%" }}
                    channel={channel}
                  >
                    <Switch>
                      <PrivateRoute exact path="/">
                        <AccountPage channel={channel} />
                      </PrivateRoute>
                      <PrivateRoute path="/create-account" exact>
                        <Redirect to="/" />
                      </PrivateRoute>
                      <PrivateRoute exact path="/referral-dashboard">
                        {user.firstLogin ? (
                          <Redirect to="/" />
                        ) : (
                          <ReferralDashboardPage />
                        )}
                      </PrivateRoute>
                      <PrivateRoute exact path="/referral-dashboard/view-call">
                        {user.firstLogin || !user.is211Admin ? (
                          <Redirect to="/" />
                        ) : (
                          <ReferralDashboardPage />
                        )}
                      </PrivateRoute>
                      <PrivateRoute path="/provider-information">
                        {user.firstLogin ? (
                          <Redirect to="/" />
                        ) : (
                          <ProviderInfoPage />
                        )}
                      </PrivateRoute>
                      <PrivateRoute path="/service-information">
                        {user.firstLogin ? (
                          <Redirect to="/" />
                        ) : (
                          <ServiceInfoPage />
                        )}
                      </PrivateRoute>
                      <PrivateRoute path="/impact">
                        {user.firstLogin ? <Redirect to="/" /> : <ImpactPage />}
                      </PrivateRoute>
                      <PrivateRoute path="/account">
                        {user.firstLogin ? (
                          <Redirect to="/" />
                        ) : (
                          <AccountPage channel={channel} />
                        )}
                      </PrivateRoute>
                      <PrivateRoute path="/about">
                        {user.firstLogin ? <Redirect to="/" /> : <AboutPage />}
                      </PrivateRoute>
                      <PrivateRoute path="/faq">
                        {user.firstLogin ? <Redirect to="/" /> : <Faq />}
                      </PrivateRoute>
                      <PrivateRoute path="/contact-us">
                        {user.firstLogin ? (
                          <Redirect to="/" />
                        ) : (
                          <ContactPage />
                        )}
                      </PrivateRoute>
                      <PrivateRoute path="/feedback">
                        {user.firstLogin || !user?.requestFeedback ? (
                          <Redirect to="/" />
                        ) : (
                          <FeedbackPage />
                        )}
                      </PrivateRoute>
                      <Route exact path="/login">
                        <Redirect to="/" />
                      </Route>
                      <Route path="/policies-and-procedures">
                        <PoliciesProcedures />
                      </Route>
                      <Route path="/terms-and-conditions">
                        <TermsConditions />
                      </Route>
                      <Route path="*">
                        <h1>Not found</h1>
                      </Route>
                    </Switch>
                  </UIShell>
                </Router>
              </FeedbackProvider>
            </ActivityFeedProvider>
          </NavProvider>
        );
      }
      // Authenticated Routes - All Other Roles
      return (
        <NavProvider>
          <ActivityFeedProvider>
            <FeedbackProvider>
              <Router id="router">
                <UIShell
                  id="uishell"
                  style={{ width: "100%" }}
                  channel={channel}
                >
                  <Switch>
                    <PrivateRoute exact path="/">
                      {user.viewOnly ||
                      (user.superAssumedRole?.role &&
                        user.superAssumedRole.role === "view_only") ? (
                        <AccountPage channel={channel} />
                      ) : (
                        <ProviderHomePage />
                      )}
                    </PrivateRoute>
                    <PrivateRoute path="/create-account" exact>
                      <Redirect to="/" />
                    </PrivateRoute>
                    <PrivateRoute path="/service-directory">
                      {user.firstLogin ? (
                        <Redirect to="/" />
                      ) : (
                        <ElasticSearch />
                      )}
                    </PrivateRoute>
                    <PrivateRoute exact path="/referral-dashboard">
                      {user.firstLogin ? (
                        <Redirect to="/" />
                      ) : (
                        <ReferralDashboardPage />
                      )}
                    </PrivateRoute>
                    {/* <PrivateRoute exact path="/referral-dashboard/referral"> */}
                    <PrivateRoute path="/referral-dashboard/referral">
                      {user.firstLogin ? (
                        <Redirect to="/" />
                      ) : (
                        <ReferralDetails />
                      )}
                    </PrivateRoute>
                    <PrivateRoute exact path="/referral-dashboard/view-call">
                      {user.firstLogin || !user.is211Admin ? (
                        <Redirect to="/" />
                      ) : (
                        <ReferralDashboardPage />
                      )}
                    </PrivateRoute>
                    <PrivateRoute exact path="/referral">
                      {user.firstLogin ? (
                        <Redirect to="/" />
                      ) : (
                        <CreateReferralPage />
                      )}
                    </PrivateRoute>
                    <PrivateRoute path="/referral/confirmation">
                      {user.firstLogin ? (
                        <Redirect to="/" />
                      ) : (
                        <ReferralConfirmationPage />
                      )}
                    </PrivateRoute>
                    <PrivateRoute path="/provider-information">
                      {user.firstLogin ? (
                        <Redirect to="/" />
                      ) : (
                        <ProviderInfoPage />
                      )}
                    </PrivateRoute>
                    <PrivateRoute path="/service-information">
                      {user.firstLogin ? (
                        <Redirect to="/" />
                      ) : (
                        <ServiceInfoPage />
                      )}
                    </PrivateRoute>
                    <PrivateRoute path="/impact">
                      {user.firstLogin ? <Redirect to="/" /> : <ImpactPage />}
                    </PrivateRoute>
                    <PrivateRoute path="/account">
                      {user.firstLogin ? (
                        <Redirect to="/" />
                      ) : (
                        <AccountPage channel={channel} />
                      )}
                    </PrivateRoute>

                    <PrivateRoute path="/360dashboard">
                      {user.firstLogin ? (
                        <Redirect to="/" />
                      ) : (
                        <PatientDashboard />
                      )}
                    </PrivateRoute>
                    <PrivateRoute path="/client-enrollment/:id">
                      {user.firstLogin ? (
                        <Redirect to="/" />
                      ) : (
                        <ClientEnrollment />
                      )}
                    </PrivateRoute>
                    <PrivateRoute path="/assessments">
                      {user.firstLogin ? (
                        <Redirect to="/" />
                      ) : (
                        <AssessmentPage />
                      )}
                    </PrivateRoute>
                    <PrivateRoute path="/about">
                      {user.firstLogin ? <Redirect to="/" /> : <AboutPage />}
                    </PrivateRoute>
                    <PrivateRoute path="/faq">
                      {user.firstLogin ? <Redirect to="/" /> : <Faq />}
                    </PrivateRoute>
                    <PrivateRoute path="/contact-us">
                      {user.firstLogin ? <Redirect to="/" /> : <ContactPage />}
                    </PrivateRoute>
                    <PrivateRoute path="/feed">
                      {user.firstLogin ? <Redirect to="/" /> : <FeedPage />}
                    </PrivateRoute>
                    <PrivateRoute path="/feedback">
                      {user.firstLogin || !user?.requestFeedback ? (
                        <Redirect to="/" />
                      ) : (
                        <FeedbackPage />
                      )}
                    </PrivateRoute>
                    <Route exact path="/login">
                      <Redirect to="/" />
                    </Route>
                    <Route path="/policies-and-procedures">
                      <PoliciesProcedures />
                    </Route>
                    <Route path="/terms-and-conditions">
                      <TermsConditions />
                    </Route>
                    <Route>
                      <ErrorPage />
                    </Route>
                  </Switch>
                </UIShell>
              </Router>
            </FeedbackProvider>
          </ActivityFeedProvider>
        </NavProvider>
      );
    }
    // Public routes
    return (
      <NavProvider>
        <Router>
          <UIShell id="uishell" channel={channel}>
            <Switch>
              <Route exact path="/">
                <LoginPage channel={channel} />
              </Route>
              <Route exact path="/login">
                <LoginPage channel={channel} />
              </Route>
              <Route path="/create-account" exact>
                <CreateAccountPage />
              </Route>
              <Route path="/reset-password">
                <ResetPasswordPage />
              </Route>
              <Route path="/policies-and-procedures">
                <PoliciesProcedures />
              </Route>
              <Route path="/terms-and-conditions">
                <TermsConditions />
              </Route>
              <Route path="/about">
                <AboutPage />
              </Route>
              <Route path="/faq">
                <Faq />
              </Route>
              <Route path="/contact-us">
                <ContactPage />
              </Route>
              <Route>
                {/* <ErrorPage /> */}
                <Redirect to="/" />
              </Route>
            </Switch>
          </UIShell>
        </Router>
      </NavProvider>
    );
  };

  return (
    <Router>
      <ThemeProvider theme={theme}>
        <GlobalStyle theme={theme} />
        <GeolocationProvider>
          <kit.ApiProvider id="asdf" sessionStorageKey="penn-provider">
            <kit.UserProvider
              id="userprovider"
              sessionStorageKey="penn-provider"
            >
              <CallLogProvider>
                <BusStopsProvider>
                  <IdleTimer channel={channel} />
                  <kit.UserConsumer id="userconsumer">
                    {renderRoutes}
                  </kit.UserConsumer>
                </BusStopsProvider>
              </CallLogProvider>
            </kit.UserProvider>
          </kit.ApiProvider>
        </GeolocationProvider>
      </ThemeProvider>
    </Router>
  );
}

export default App;
