import styled from "styled-components";
export const InnerContainer = styled.div`
  width: 100%;
  max-width: 1600px;
  /* padding: 0 1.5rem; */
  height: auto;
`;

export const Header = styled.div`
  margin: 1.5rem 0 2rem;
  padding: 0 1rem;
  @media ${(props) => props.theme.breakpoints.md} {
    padding: 0;
  }
`;

export const Container = styled.div`
  width: 100%;
  position: relative;
  /* padding: 1rem; */
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
`;

export const TableContent = styled.div`
  height: 75vh;
  width: 100%;
  overflow-y: scroll;
`;
