import styled from "styled-components";
import { Button } from "carbon-components-react";

export const Container = styled.div`
  position: absolute;
  padding: 0.75rem 1rem 0;
  // border: 1px solid #ccc;
  // box-shadow: 2px 0px 2px rgba(0, 0, 0, 0.1), 3px 0px 5px rgba(0, 0, 0, 0.05);
  background: #ffffff;
  overflow-y: auto;
  overflow-x: hidden;
  height: calc(100vh - 3rem);
  left: ${(props) => {
    if (props.theme.isOpen) {
      return "0;";
    }
    return "-16rem;";
  }};
  top: 0;
  transition-timing-function: ${(props) => props.theme.motion};
  transition-duration: 240ms;
  width: 15rem;
  z-index: 4;
  @media (min-width: 1280px) {
    position: relative;
    width: 18.75rem;
    /* height: fit-content; */
  }
  /* @media (min-height: 900px) {
    overflow-y: hidden;
  } */
`;

export const Header = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: start;
  /* border-bottom: 1px solid #ccc; */
  margin-bottom: 2rem;
`;

export const Clear = styled.h6`
  cursor: pointer;
  color: ${(props) => props.theme.colors.primary.mediumBlue};
  &:hover {
    color: ${(props) => props.theme.colors.accent.mediumBlue};
  }
`;

export const SectionContainer = styled.div`
  margin-bottom: 2rem;
`;

export const SectionContent = styled.div`
  height: auto;
  max-height: 15rem;
  overflow-y: auto;
  border: 1px solid #ccc;
  padding: 0.5rem;
`;

export const SectionFieldset = styled.fieldset`
  & + fieldset {
    margin-bottom: 1.75rem;
  }
`;

export const SectionHeading = styled.h4`
  margin-bottom: 0.75rem;
  font-weight: 600;
`;

export const SectionSubHeading = styled.h5`
  size: 1.25rem;
  font-weight: 500;
  margin-bottom: 0.75rem;
`;

export const CloseBtn = styled(Button)`
  @media (min-width: 1024px) {
    display: none !important;
  }
`;
