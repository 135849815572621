import { createGlobalStyle } from "styled-components";

export const GlobalStyle = createGlobalStyle`

button:disabled{
  border-color: #c6c6c6;
  background: #c6c6c6;
  box-shadow: none;
  color: #8d8d8d;
  cursor: not-allowed;
}

.bx--btn--primary {
  background-color:${(props) => props.theme.colors.primary.mossGreen};
}
.bx--btn--primary:hover:enabled {
  background-color:${(props) => props.theme.colors.accent.mossGreen} !important;
}


.bx--btn--primary {
  background-color:${(props) => props.theme.colors.primary.mossGreen};
}
a.bx--btn--primary:hover:enabled {
  background-color:${(props) => props.theme.colors.accent.mossGreen} !important;
}

a.bx--btn--primary:hover {
  background-color:${(props) => props.theme.colors.accent.mossGreen} !important;
}

.bx--btn--secondary {
  color:#ffffff;
  background-color:${(props) =>
    props.theme.colors.primary.mediumBlue};
}
.bx--btn--secondary:active {
  background-color:${(props) =>
    props.theme.colors.primary.mediumBlue} !important;
}
.bx--btn--secondary:hover:enabled {
  background-color: ${(props) => props.theme.colors.accent.mediumBlue} ;
}

.bx--btn--menu {
  background-color: #ffffff;
}
.bx--btn--menu:hover:enabled {
  color: #ffffff;
  background-color: ${(props) => props.theme.colors.accent.mediumBlue};
}

.bx--btn--cancel {
  color: ${(props) => props.theme.button.cancel.color};
  background-color: ${(props) => props.theme.button.cancel.bg};
}
.bx--btn--cancel:hover {
  background-color: ${(props) => props.theme.button.cancel.hover} ;
}

.bx--modal-footer > .bx--btn--secondary {
  color: ${(props) => props.theme.button.cancel.color};
  background-color: ${(props) => props.theme.button.cancel.bg};
}
.bx--modal-footer > .bx--btn--secondary:hover {
  background-color: ${(props) => props.theme.button.cancel.hover} ;
}

.bx--btn--tertiary {
  color:  ${(props) => props.theme.colors.primary.mediumBlue};
  border-color: ${(props) => props.theme.colors.primary.mediumBlue};
}
.bx--btn--tertiary:hover {
  background-color: ${(props) => props.theme.colors.accent.mediumBlue} ;
}

.bx--btn--ghost {
  color:  ${(props) => props.theme.colors.primary.mediumBlue};
}

.bx--btn--ghost:hover:enabled {
  color:  ${(props) => props.theme.colors.primary.mediumBlue};
}

a.bx--header__menu-item:hover {
  background-color: ${(props) => props.theme.colors.primary.darkBlue};
}

.bx--select-input:disabled, .bx--select-input:hover:disabled {
  background-color: #ebebeb;
  color: #a6a6a6;
}
`;
