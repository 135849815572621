import styled from "styled-components";
import * as S from "../PatientDashboard/components/static.jsx";
import Sc from "./clientEnrollment.module.scss";

import React, { useState, useContext, useEffect } from "react";
import {
  Switch,
  Route,
  useLocation,
  useHistory,
  useParams,
} from "react-router-dom";

import { useDimension } from "../../hooks/useDimension";
import useQuery from "../../hooks/useQuery";
import { NavContext } from "../../store/NavContext";

import { fetchWithRetry } from "../../utils";

import kit from "ich-ui-kit";

import {
  ProgressIndicator,
  ProgressStep,
  InlineNotification,
  Button,
} from "carbon-components-react";
import CompleteProfile from "./client-complete-profile-flow/index.jsx";
import Checkin from "./checkin";
import CheckinSummary from "./checkin-summary";
import WellBeingAssessment from "./well-being-assessment";
import ClientExitBanner from "./components/ClientExitBanner/index.jsx";
import { Redirect } from "react-router-dom/cjs/react-router-dom.js";

// This can be tweaked in future to indicate Client Enrollment Progress
export const ProgressIndicatorBar = ({ currentIndex }) => {
  const populateProgressBar = () => {
    const barList = [];
    for (let i = 0; i < 3; i++) {
      barList.push(
        <ProgressStep key={i} label="" secondaryLabel="" description="" />
      );
    }
    return barList;
  };
  return (
    <>
      <ProgressIndicator currentIndex={currentIndex} spaceEqually={true}>
        {populateProgressBar()}
      </ProgressIndicator>
    </>
  );
};

const ClientEnrollment = () => {
  const [, updateState] = React.useState();
  const forceUpdate = React.useCallback(() => updateState({}), []);
  // Contexts
  const { user } = useContext(kit.UserContext);
  const { apiService } = useContext(kit.ApiContext);
  //Hooks
  let location = useLocation();
  const history = useHistory();
  const query = useQuery();
  // States
  const [controllerIndex, setControllerIndex] = useState(0);
  const [current, setCurrent] = useState("complete-profile-flow");
  const [clientInfo, setClientInfo] = useState({});
  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState(0);
  const totalNumFlowItems = 4;
  const [clientNotFound, setClientNotFound] = useState(false);
  const { windowSize } = useDimension();
  const { mobileNavOpen, setMobileNavOpen } = useContext(NavContext);

  const [enrollmentSuccess, setEnrollmentSuccess] = useState("");
  const [isCheckinComplete, setIsCheckinComplete] = useState(false);
  const [isCheckinSummaryComplete, setIsCheckinSummaryComplete] =
    useState(false);
  const [isWellBeingComplete, setIsWellBeingComplete] = useState(false);
  const [isProfileFlowComplete, setIsProfileFlowComplete] = useState(false);

  const { id: clientIdDirectlyFromURL } = useParams();
  const clientIdFromURL = query.get("client") || clientIdDirectlyFromURL;

  let clientID =
    clientIdFromURL ||
    clientInfo?.id ||
    location?.state?.client ||
    location?.state?.clientID;

  useEffect(() => {
    const getClientRecord = async (clientID) => {
      setLoading(true);
      try {
        if(!clientID) return;
        const response = await fetchWithRetry(
          `${
            process.env.REACT_APP_API_ENDPOINT
          }/clients/${clientID}?assumedId=${
            user.superAssumedRole?.leadId || ""
          }`,
          {
            method: "get",
            headers: {
              Authorization: `Bearer ${user.accessToken} ${user.idToken}`,
            },
          }
        );
        if (response.status === 200) {
          const data = await response.json();
          setClientInfo({ ...data });
        } else {
          setLoading(false);
          setClientNotFound(true);
        }
      } catch (err) {
        setClientNotFound(true);
        console.log(err);
      } finally {
        setLoading(false);
      }
    };

    if (clientID) {
      getClientRecord(clientID);
    } else {
      setClientNotFound(true);
    }
  }, [
    clientID,
    clientIdFromURL,
    clientInfo?.id,
    location?.state?.client,
    location?.state?.clientID,
  ]);

  useEffect(() => {
    if (isProfileFlowComplete) {
      setProgress(1);
    }
    if (isCheckinComplete) {
      setProgress(2);
    }
    if (isCheckinSummaryComplete) {
      setProgress(3);
    }
    if (isWellBeingComplete) {
      setProgress(4);
      setEnrollmentSuccess(true);
    }
  }, [
    isCheckinComplete,
    isCheckinSummaryComplete,
    isWellBeingComplete,
    isProfileFlowComplete,
  ]);

  const setCurrentHandler = (path) => {
    const cleanPath = path.toLowerCase();
    switch (cleanPath) {
      case "/client-enrollment/complete-profile":
        return "complete profile flow";
      case "/client-enrollment/checkin":
        return "checkin";
      case "/client-enrollment/checkin/view":
        return "checkin summary";
      case "/client-enrollment/well-being-assessment":
        return "well being assessment";
      default:
        return "complete profile flow";
    }
  };

  useEffect(() => {
    setCurrent(setCurrentHandler(location.pathname));
  }, [location]);

  const extractLastName = (str) => {
    if (str !== undefined) {
      let res = str.split(" ");
      return res[res.length - 1];
    } // incase of middle name return last index
    return "";
  };

  return (
    <S.PageContainer>
      <S.Content>
        <ClientExitBanner
          clientInfo={clientInfo}
          numItems={progress}
          totalNumItems={totalNumFlowItems}
        />
        {clientNotFound && (
          <>
            <InlineNotification
              title={"Client Not Found"}
              subtitle={
                "Return to your homepage to enroll a client."
              }
              kind="error"
              role="status"
            />
            <Button
              kind="secondary"
              onClick={() => {
                history.push({
                  pathname: "/",
                  state: {},
                });
              }}
            >
              Return to Client Search Directory
            </Button>
          </>
        )}
        {enrollmentSuccess && (
          <span className={Sc.toast}>
            <InlineNotification
              title={"Enrollment Success"}
              subtitle={
                clientInfo?.firstName
                  ? `You have successfully enrolled ${clientInfo.firstName} ${clientInfo?.lastName}. You can now view ${clientInfo.firstName}'s Well-Being Dashboard`
                  : ""
              }
              kind="success"
              role="status"
            />
          </span>
        )}
        <Switch>
          <Route path="/client-enrollment/:id" exact>
              <Redirect to={`/client-enrollment/${clientID}/complete-profile`} from="/" />
          </Route>
          <Route path="/client-enrollment/:id/complete-profile" exact>
            {!clientNotFound && (
              <CompleteProfile
                passClientInfo={clientInfo}
                setCompletedStatus={setIsProfileFlowComplete}
              />
            )}
          </Route>
          <Route path="/client-enrollment/:id/checkin" exact>
            {!clientNotFound && (
              <Checkin
                passClientInfo={clientInfo}
                passSetClientInfo={setClientInfo}
                setCompletedStatus={setIsCheckinComplete}
              />
            )}
          </Route>
          <Route path="/client-enrollment/:id/checkin/view" exact>
            <CheckinSummary
              passClientInfo={clientInfo}
              setCompletedStatus={setIsCheckinSummaryComplete}
            />
          </Route>
          <Route path="/client-enrollment/:id/well-being-assessment" exact>
            {!clientNotFound && (
              <WellBeingAssessment
                passClientInfo={clientInfo}
                setCompletedStatus={setIsWellBeingComplete}
              />
            )}
          </Route>
        </Switch>
      </S.Content>
    </S.PageContainer>
  );
};

export default ClientEnrollment;