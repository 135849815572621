import React from "react";
import { useLocation, useHistory } from "react-router-dom";
import { Grid, Row, Column, Button } from "carbon-components-react";
import styled from "styled-components";

const ReferralConfirmationPage = () => {
  const location = useLocation();
  const {
    clientId = "",
    firstName = "",
    lastName = "",
    providerName = "",
    serviceName = "",
    city = "",
    state = "",
    referralId
  } = location.state || {};
  const history = useHistory();

  return (
    <StyledGrid>
      <Row>
        <Column lg={2}></Column>
        <Column lg={12}>
          <ContentWrapper>
            <Header>New Referral Created</Header>
            <SubHeaderWrapper>
              <SubHeader>Your referral has</SubHeader>
              <SubHeader>been sent to {firstName || "client"} for</SubHeader>
              <SubHeader>confirmation</SubHeader>
            </SubHeaderWrapper>
            <BorderContainer>
              <ReferralFor>Referral for:</ReferralFor>
              <ClientName>{`${firstName} ${lastName}`}</ClientName>
              <ProviderName>{providerName}</ProviderName>
              <ServiceName>{serviceName}</ServiceName>
              <Address>
                {city}, {state}
              </Address>
              <Status>Status: Created</Status>
              <StyledButton
                onClick={() =>
                  history.push({
                    pathname: "/360dashboard/service-requests/view",
                    state: {
                      client: clientId,
                      clientID: clientId,
                      referralId,
                      cameFrom: "patient",
                      onCareTeam: true
                    }
                  })
                }
              >
                View your Referral
              </StyledButton>
              <StyledButton
                kind="ghost"
                onClick={() =>
                  history.push({
                    pathname: "/360dashboard",
                    state: {
                      client: clientId
                    }
                  })
                }
              >
                {" "}
                View 360 Dashboard
              </StyledButton>
            </BorderContainer>
          </ContentWrapper>
        </Column>
        <Column lg={2}></Column>
      </Row>
    </StyledGrid>
  );
};

export default ReferralConfirmationPage;

const StyledGrid = styled(Grid)`
  height: 100%;
  overflow-y: auto;
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
`;

const Header = styled.h1`
  font-size: 1.5rem;
  font-weight: 600;
  margin: 2rem 0 1rem 0;
`;

const SubHeader = styled.p`
  font-size: 2rem;
  color: #525252;
  line-height: 1.25;
`;

const SubHeaderWrapper = styled.div`
  margin-bottom: 1rem;
`;

const BorderContainer = styled.div`
  min-width: 320px;
  max-width: 500px;
  border: 1px #e0e0e0 solid;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  padding: 1rem;
`;

const BoldInnerText = styled.p`
  font-weight: 600;
`;

const ReferralFor = styled(BoldInnerText)``;
const ClientName = styled(BoldInnerText)``;
const ProviderName = styled(BoldInnerText)`
  font-size: 1.25rem;
  margin-top: 2rem;
`;
const ServiceName = styled.p`
  color: #525252;
`;
const Address = styled.p`
  font-size: 0.9rem;
  color: #a8a8a8;
`;
const Status = styled(BoldInnerText)`
  margin: 2rem;
`;

const StyledButton = styled(Button)`
  width: 90%;
`;
