import styled from "styled-components";
import { Button } from "carbon-components-react";

export const Card = styled.div`
  position: relative;
  width: 100%;
  padding: 1rem 1rem .5rem 1rem;
  margin: 0.5rem 0;
  // border-radius: 7px;
  border: 1px solid rgba(0, 0, 0, 0.15);
  background: #ffffff;
  box-shadow: 2px 4px 5px rgba(0, 0, 0, 0.05), -2px -2px 5px rgba(0, 0, 0, 0.05);
  display: flex;

  &:first-child {
    margin-top: 0;
  }
  &:last-child {
    margin-bottom: 0;
  }
  @media ${(props) => props.theme.breakpoints.sm} {
    margin: 0.5rem 0rem;
  }
  @media ${(props) => props.theme.breakpoints.lg} {
    height: auto;
  }
`;
export const CardInfoWrapperReferral = styled.div`
  display: flex;
  flex-flow: column nowrap;
  margin-left: 0.5rem;
  width: 100%;
`;
export const CardInfoWrapper = styled.div`
  display: flex;
  flex-flow: column nowrap;
  padding-left: 0rem;
`;
export const CardHeader = styled.div`
  display: flex;
  margin-bottom: 0.75rem;
`;
export const Header = styled.div``;
export const Heading = styled.h1`
  font-weight: 500;
  font-size: clamp(1.25rem, 3vw, 1.5rem);
  margin-bottom: 0.25rem;
`;
export const TextSM = styled.p`
  color: #525252;
  font-size: 0.75rem;
  margin-bottom: 0.25rem;
`;
export const TimeStamp = styled.p`
  color: #6f6f6f;
  font-size: 0.8rem;
`;

export const CardBody = styled.div`
  // min-height: 175px;
  // @media ${(props) => props.theme.breakpoints.lg} {
  //   min-height: 225px;
  // }
`;
export const Body = styled.div``;

export const CloseBtn = styled.div`
  cursor: pointer;
  position: absolute;
  right: 1rem;
  top: 1rem;
`;

export const ButtonGroup = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;
  flex-wrap: wrap;
`;
export const ButtonLeft = styled.div`
  display: flex;
  margin-bottom: 0.5rem;
`;
export const ButtonRight = styled.div`
  display: flex;
  margin-bottom: 0.5rem;
`;

export const ViewButton = styled(Button)`
  min-height: 2rem;
  height: 2rem;
  white-space: nowrap;
  &:not(:last-child) {
    margin-right: 0.5rem;
  }
`;

export const ViewReferralButton = styled(Button)`
  min-height: 2rem;
  height: 2rem;
  padding: 0 1rem 0 1rem;
  white-space: nowrap;
  &:not(:last-child) {
    margin-right: 0.5rem;
  }
`;

export const Description = styled.p`
  font-size: 14px;
  margin-bottom: 1.25rem;
  text-align: left;
`;
