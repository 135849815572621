import React, { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
import { Filters } from "./components/filter";
import {
  Button,
  Table,
  TableHead,
  TableRow,
  TableHeader,
  TableBody,
  TableCell,
  StructuredListSkeleton,
  TableContainer,
  TableToolbar,
  TableToolbarContent,
  TableToolbarSearch,
  Tooltip,
} from "carbon-components-react";
import * as S from "./referrals-insert.styled";
import * as Subpage from "../PatientDashboard/subpages/subpage.styled";
import Empty from "../../components/Empty";
import { getDisplayedStatus, getLocalTime, validateUrl } from "../../utils";
import { WarningAltFilled16 } from "@carbon/icons-react";
import kit from "ich-ui-kit";

const ReferralsList = ({ clientID }) => {
  const { apiService } = useContext(kit.ApiContext);
  const [loading, setLoading] = useState(true);
  const [referrals, setReferrals] = useState([]);
  const [activeFilters, setActiveFilters] = useState({
    open: false,
    status: "",
    display: "",
  });
  const [width, setWidth] = useState(window.innerWidth);
  const [openExpireTooltip, setOpenExpireTooltip] = useState("");

  const isMobile = 500;

  const history = useHistory();

  useEffect(() => {
    if (clientID) {
      const getServiceRequests = async () => {
        const data = await apiService.get(
          `/referrals?clientId=${clientID}&allRequests=true`
        );
        if (data && data?.requests) {
          const refs = getLocalTime(data?.requests);
          setReferrals(refs);
        }

        setLoading(false);
      };
      getServiceRequests();
    } else {
      setLoading(false);
    }
  }, [clientID]);

  useEffect(() => {
    const handleResizeWindow = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleResizeWindow);
    return () => {
      window.removeEventListener("resize", handleResizeWindow);
    };
  }, []);

  const handleFilterClick = (filter) => {
    if (filter.value === "open") {
      setActiveFilters((prevFilters) => ({
        status: "",
        open: !prevFilters.open,
        display: filter.text,
      }));
    } else if (filter.value === "closed") {
      setActiveFilters((prevFilters) => ({
        status: prevFilters.status === "closed" ? "" : "closed",
        open: false,
        display: prevFilters.status === "closed" ? "" : filter.text,
      }));
    } else {
      setActiveFilters({
        status: filter.value,
        open: false,
        display: filter.text,
      });
    }
  };

  const handleFilter = (referral) => {
    const status = referral.status.toLowerCase();
    const closedStatuses = ["closed", "rejected", "expired", "cancel", "complete", "provider responded", "request not sent", "provider cannot accept"];
    if (activeFilters.open === false && activeFilters.status === "") {
      return true;
    } else if (activeFilters.open === true) {
      if (closedStatuses.some((closedStatus) => status.includes(closedStatus))) {
        return false;
      } else {
        return true;
      }
    } else if (activeFilters?.status === "closed") {
      if (closedStatuses.some((closedStatus) => status.includes(closedStatus))) {
        return true;
      } else {
        return false;
      }
    }
  };

  const handleCreate = () => {
    history.push({
      pathname: "/service-directory",
      state: {
        patientId: clientID,
      },
    });
  };

  const handleView = (referralId) => {
    history.push({
      pathname: "/360dashboard/service-requests/view",
      state: {
        clientID,
        referralId,
        cameFrom: "patient",
        onCareTeam: true,
      },
    });
  };

  const handle211CallView = (id) => {
    history.push({
      pathname: "/360dashboard/211-call-details/view",
      state: {
        referralId: id,
        patientId: clientID,
      },
    });
  };

  const handleNeedsRequestView = (referral) => {
    history.push({
      pathname: "/360Dashboard/needs-request/view",
      state: {
        clientID,
        referral,
      },
    });
  };

  const handleCNRequestView = (referral) => {
    history.push({
      pathname: "/360Dashboard/cn-request/view",
      state: {
        clientID,
        referral,
      },
    });
  }

  const viewProvider = (id, serviceId, orgSource, locationId) => {
    window.open(
      validateUrl(
        `/provider-information?id=${id}&serviceId=${serviceId}&locationId=${locationId}&orgSource=${
          orgSource || "211"
        }`
      )
    );
  };

  /**
   * SIPD-3376 | Scenario 4
   * Unable to search by Creation Date; update the loop range to include Creation Date.
   */
  function searchFilter() {
    var input, filter, table, tr, td, i, j;
    input = document.getElementById("search-bar");
    filter = input.value?.toUpperCase();
    table = document.getElementById("table");
    tr = table.getElementsByTagName("tr");
    for (i = 1; i < tr.length; i++) {
      td = tr[i].getElementsByTagName("td");
      let txtValue = "";
      if (td) {
        for (j = 0; j < td.length; j++) {
          txtValue = txtValue.concat(" ", td[j].textContent || td[j].innerText);
        }
        if (txtValue?.toUpperCase().indexOf(filter) > -1) {
          tr[i].style.display = "";
        } else {
          tr[i].style.display = "none";
        }
      }
    }
  }

  const handleIDClick = (r) => {
    const objIsEmpty = Object.keys(r).length === 0;
    if (objIsEmpty) return;
    // SIPD-3376 | Scenario 5 - Add condition for Provider Responded and Provider Never Responded condition.
    if (r.requestType === "Email") {
      handleNeedsRequestView(r);
      return;
    }
    if (r?.servName.includes("211 Recommended")) {
      handle211CallView(r.details);
      return;
    }
    if (r.requestType === "Assistance Request") {
      handleCNRequestView(r);
      return;
    }
    handleView(r.id);
  };

  return (
    <Subpage.Container>
      <Subpage.InnerContainer>
        <Subpage.Header>
          <h3>
            <strong>Service Requests</strong>
          </h3>
          <S.CreateReferralButton onClick={handleCreate}>
            Provide Support
          </S.CreateReferralButton>
        </Subpage.Header>
        <S.FilterContainer>
          <Filters
            handleFilterClick={handleFilterClick}
            activeFilters={activeFilters}
          />
        </S.FilterContainer>
        {loading ? (
          <StructuredListSkeleton style={{ height: "35rem" }} />
        ) : referrals && referrals.length > 0 ? (
          <S.CardContainer>
            <TableContainer>
              <TableToolbar>
                <TableToolbarContent>
                  <TableToolbarSearch
                    id="search-bar"
                    onChange={searchFilter}
                    placeholder="Service Request Search"
                    persistent="true"
                    style={{ border: "1px solid #c6c6c6", maxWidth: "30rem" }}
                  />
                </TableToolbarContent>
              </TableToolbar>
              <Table id="table">
                <TableHead>
                  <TableRow>
                    <TableHeader></TableHeader>
                    <TableHeader>ID</TableHeader>
                    {width > isMobile ? (
                      <TableHeader>Service Name</TableHeader>
                    ) : (
                      <TableHeader>Service</TableHeader>
                    )}
                    {width > isMobile && (
                      <TableHeader>Provider Name</TableHeader>
                    )}
                    <TableHeader>Type</TableHeader>
                    <TableHeader>Status</TableHeader>
                    {width > isMobile && (
                      <TableHeader>Date Created</TableHeader>
                    )}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {referrals.filter(handleFilter).map((r) => (
                    <TableRow key={Math.random()}>
                      <TableCell style={{ paddingRight: "0rem" }}>
                        {r?.status.includes("About to Expire") && (
                          <div
                            onMouseEnter={() => setOpenExpireTooltip(r.id)}
                            onMouseLeave={() => setOpenExpireTooltip("")}
                          >
                            <WarningAltFilled16 style={{ color: "red" }} />
                            <Tooltip
                              open={openExpireTooltip === r.id}
                              direction="bottom"
                              showIcon={false}
                              align="start"
                            >
                              <p>
                                About to expire. Referrals expire in 30 days if
                                not accepted by client and provider.
                              </p>
                            </Tooltip>
                          </div>
                        )}
                        {r?.status.includes("pending closure") && (
                          <div
                            onMouseEnter={() => setOpenExpireTooltip(r.id)}
                            onMouseLeave={() => setOpenExpireTooltip("")}
                          >
                            <WarningAltFilled16 style={{ color: "red" }} />
                            <Tooltip
                              open={openExpireTooltip === r.id}
                              direction="bottom"
                              showIcon={false}
                              align="start"
                            >
                              <p>
                                Pending closure. This referral will close in 24
                                hours if not reopened by the provider.
                              </p>
                            </Tooltip>
                          </div>
                        )}
                      </TableCell>
                      <TableCell>
                        <a
                          style={{ cursor: "pointer" }}
                          onClick={() => handleIDClick(r)}
                        >
                          {r?.id}
                        </a>
                      </TableCell>
                      <TableCell>
                        {r.requestType === "211" ? (
                          "Click ID to View Details"
                        ) : (
                          <a
                            style={{
                              cursor: "pointer",
                            }}
                            onClick={() =>
                              viewProvider(
                                r.orgKey,
                                r.serviceId,
                                r.orgSource,
                                r.locationId
                              )
                            }
                          >
                            {r.servName}
                          </a>
                        )}
                      </TableCell>
                      {width > isMobile && (
                        <TableCell>
                          {r.requestType === "211"
                            ? "Click ID to View Details"
                            : r.orgName}
                        </TableCell>
                      )}
                      <TableCell>{r.requestType || "Referral"}</TableCell>
                      <TableCell>
                        {getDisplayedStatus(r.status, r.referree, null, r.requestType)}
                      </TableCell>
                      {width > isMobile && (
                        <TableCell>{r.authoredOn}</TableCell>
                      )}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </S.CardContainer>
        ) : (
          <>
            <Empty
              src="/icons/high--five.svg"
              text={`No Services Requests.`}
              style={{ minHeight: "70vh" }}
            />
            <br />
          </>
        )}
      </Subpage.InnerContainer>
    </Subpage.Container>
  );
};

export default ReferralsList;
