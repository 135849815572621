export default function validate(values) {
  const errors = {
    password: ["lower", "upper", "count", "number", "symbol", "nospace"],
    confirmPassword: ""
  };
  if (values.password) {
    if (/.*?[A-Z]/.test(values.password)) {
      errors.password = errors.password.filter((e) => e !== "upper");
    }
    if (/.*?[a-z]/.test(values.password) === true) {
      errors.password = errors.password.filter((e) => e !== "lower");
    }
    if (/.*?[0-9]/.test(values.password) === true) {
      errors.password = errors.password.filter((e) => e !== "number");
    }
    if (/.*?[#?!@$%^&*-]/.test(values.password) === true) {
      errors.password = errors.password.filter((e) => e !== "symbol");
    }
    if (/.{10,}/.test(values.password) === true) {
      errors.password = errors.password.filter((e) => e !== "count");
    }
    if (
      /\s/.test(values.password) === false &&
      /.*?[[{}\\;:'"/.>,<_=+`~|\]]/.test(values.password) === false
    ) {
      errors.password = errors.password.filter((e) => e !== "nospace");
    }
  }
  if (values.confirmPassword) {
    if (values.confirmPassword !== values.password) {
      errors.confirmPassword = "Passwords must match and meet requirements";
    }
    if (/.*?[A-Z]/.test(values.confirmPassword) === false) {
      errors.confirmPassword = "Passwords must match and meet requirements";
    }
    if (/.*?[a-z]/.test(values.confirmPassword) === false) {
      errors.confirmPassword = "Passwords must match and meet requirements";
    }
    if (/.*?[0-9]/.test(values.confirmPassword) === false) {
      errors.confirmPassword = "Passwords must match and meet requirements";
    }
    if (/.*?[#?!@$%^&*-]/.test(values.confirmPassword) === false) {
      errors.confirmPassword = "Passwords must match and meet requirements";
    }
    if (/.{10,}/.test(values.confirmPassword) === false) {
      errors.confirmPassword = "Passwords must match and meet requirements";
    }
    if (
      /\s/.test(values.password) === false &&
      /.*?[[{}\\;:'"/.>,<_=+`~|\]]/.test(values.password) === true
    ) {
      errors.confirmPassword = "Passwords must match and meet requirements";
    }
  }
  return errors;
}
