import { TooltipIcon } from "carbon-components-react";
import React, { useState, useEffect } from "react";
import * as S from "./icon.styled";

const Icon = ({ name, status, viewAllPage = false, overviewPage = false, wellbeingSection = false }) => {
  const [logo, setLogo] = useState({});

  const colors = {
    5: "#95c93d",
    4: "#4ac1e0",
    3: "#f4db5f",
    2: "#faa41a",
    1: "#ec5c29",
    0: "#c6c6c6"
  };

  const domains = {
    housing: "/domains/housing.svg",
    health: "/domains/Health.svg",
    financial: "/domains/financial.svg",
    employment: "/domains/employment.svg",
    income: "/domains/legal.svg",
    education: "/domains/education.svg",
    food: "/domains/food.svg",
    transportation: "/domains/transportation.svg",
    safety: "/domains/safety.svg",
    socialconnections: "/domains/socialconnection.svg",
    dependentcare: "/domains/dependentcare.svg",
  };

  useEffect(() => {
    const color = colors[status.toString()];
    setLogo({
      icon: domains[name.toLowerCase().split(" ").join("")],
      text: name,
      color: color,
    });
  }, [name, status]);

  if (viewAllPage) {
    return (
      <S.ViewAllContainer>
        <TooltipIcon
          direction="right"
          // triggerClassName="domain-name"
          tooltipText={name}
          >
      <S.ColorBlindCircle theme={{ color: logo.color }}>
        <span>{status}</span>
          <img src={logo.icon} alt={logo.text} />
        </S.ColorBlindCircle>
        </TooltipIcon>
      </S.ViewAllContainer>
    );
  } else if (overviewPage) {
    return (
      <S.Container>
        <S.OCircle theme={{ color: logo.color }}>
          <img src={logo.icon} />
        </S.OCircle>
        <S.Text>{logo.text}</S.Text>
      </S.Container>
    );
  } else if (wellbeingSection) {
    return (
      <S.WellbeingContainer>
        <S.WellbeingCircle theme={{ color: logo.color}}>
          <img src={logo.icon} />
        </S.WellbeingCircle>
        <S.WText>{logo.text}</S.WText>
      </S.WellbeingContainer>
    );
  } else {
  return (
    <S.Container style={{margin: "0.5rem 0"}}>
      <S.ColorBlindCircle theme={{ color: logo.color }}>
        <span>{status}</span>
        <S.DomainIcon src={logo.icon} alt={logo.text} />
      </S.ColorBlindCircle>
      <S.Text>{logo.text}</S.Text>
    </S.Container>
  );
  }
};

export default Icon;
