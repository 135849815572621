import {
  Close24,
  IntentRequestScaleIn20,
  IntentRequestScaleOut20
} from "@carbon/icons-react";
import { Button, InlineLoading } from "carbon-components-react";
import kit from "ich-ui-kit";
import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { getDisplayedStatus } from "../../../../../utils";
import * as S from "./card.styled";
import { isFeedCardActionDisabled } from '../../../utils';

const convertTimestamp = (timestamp) => {
  try {
    let authoredLocalTime = timestamp;
    if (timestamp?.includes("T")) {
      authoredLocalTime = timestamp;
    } else {
      authoredLocalTime = timestamp?.replace(" ", "T") + "Z";
    }
    authoredLocalTime = new Date(authoredLocalTime).toLocaleString();
    return authoredLocalTime;
  } catch (e) {
    console.log(e);
    return "";
  }
};

const convertTimestamp10Days = (timestamp) => {
  try {
    let authoredLocalTime = timestamp;
    if (timestamp?.includes("T")) {
      authoredLocalTime = timestamp;
    } else {
      authoredLocalTime = timestamp?.replace(" ", "T") + "Z";
    }
    authoredLocalTime = new Date(authoredLocalTime);
    authoredLocalTime.setDate(authoredLocalTime.getDate() + 10);
    return authoredLocalTime.toLocaleDateString();
  } catch (e) {
    console.log(e);
    return "";
  }
};

export const ReferralCard = ({
  cardData,
  clearActivity,
  setActivityInactive,
  setClient,
  inbound,
  setOnCareTeam,
  viewLoading,
  setViewLoading,
  clearReferralActivity,
  setReferralActivityInactive,
  setReferralActivitiesInactive,
  currentReferralLoading,
  setCurrentReferralLoading
}) => {
  const { apiService } = useContext(kit.ApiContext);
  const { user } = useContext(kit.UserContext);
  const [ view, setView] = useState(false);
  const history = useHistory();
  const [disabledButtons, setDisabledButtons] = useState(
    history.location.state?.isDecline
  );

  useEffect(() => {
    try {
      setDisabledButtons(history.location.state?.isDecline);
    } catch (err) {
      console.log(err);
    }
  }, [history.location.state]);

  const getReferralData = async (cardData, updateStatus = "", set1ActivityInactive=true) => {
    setCurrentReferralLoading(cardData.referralId);
    let onCareTeamPatients = [];
    try {
      // setView(true);
      setViewLoading(true);
      if (updateStatus) {
        await apiService.put(`/referrals/status?assumedId=${user.superAssumedRole?.leadId || ""}`, {
          patientId: cardData?.patientId,
          status: updateStatus,
          referralId: cardData.referralId,
          serviceId: cardData.serviceId,
          assignedTo: cardData.assignedTo,
          originatorId: cardData.senderId
        });
        if(set1ActivityInactive) {
          await setActivityInactive(cardData.id, cardData.type);
        } else {
          await setReferralActivitiesInactive(
            cardData.patientId,
            cardData.referralId
          ); 
        }
        // clearActivity(cardData.id, cardData.type);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setViewLoading(false);
      setCurrentReferralLoading("");
    }

    let currClient = "";
    if (cardData?.patientId) {
      currClient = cardData?.patientId;
    }
    if (
      onCareTeamPatients &&
      onCareTeamPatients.find((p) => p.id === currClient)
    ) {
      setOnCareTeam(true);
    } else {
      setOnCareTeam(false);
    }
  };

  const card = (data) => {
    const d = new Date(data.timestamp);
    d.setSeconds(d.getSeconds() + 99);
    return (
      <S.Card data-id={`feed-card-${data?.referralId}`}>
        {inbound ? <IntentRequestScaleIn20 /> : <IntentRequestScaleOut20 />}
        <S.CardInfoWrapperReferral>
          <S.CardHeader>
            <S.Header>
              <S.TimeStamp>{d.toLocaleString()}</S.TimeStamp>
              {inbound ? (
                <S.TextSM>
                  Referral #{data.referralId}: Service Request You Received
                </S.TextSM>
              ) : (
                <S.TextSM>
                  Referral #{data.referralId}: Service Request You Sent
                </S.TextSM>
              )}
            </S.Header>
            {data.closeBtn && (
              <S.CloseBtn onClick={data.close} data-id={`feed-card-close-btn`}>
                <Close24 />
              </S.CloseBtn>
            )}
          </S.CardHeader>
          <S.CardBody>
            <S.Description
              dangerouslySetInnerHTML={{ __html: data.description }}
            />
          </S.CardBody>
          {data.btnGroup}
        </S.CardInfoWrapperReferral>
      </S.Card>
    );
  };

  switch (cardData.subType) {
    case "closed":
      return card({
        id: cardData.id,
        referralId: cardData.referralId,
        title: "Referral Closed",
        timestamp: convertTimestamp(cardData.date),
        description:
          cardData.patientId === cardData.senderId
            ? `<strong>${cardData.providerStaffName}</strong> declined their referral to <strong>${cardData.serviceName}</strong>.`
            : `<strong>${cardData.providerStaffName}</strong> from <strong>${cardData.orgName}</strong> closed a referral to <strong>${cardData.serviceName}</strong>.`,
        close: () => {
          clearActivity(cardData.id, cardData.type);
        },
        closeBtn: true,
        disabled: cardData.active === 0 ? true : false,
        btnGroup: (
          <S.ButtonGroup>
            <S.ButtonLeft>
              <S.ViewReferralButton
                data-id={`feed-card-view-btn`}
                kind="tertiary"
                onClick={() => {
                  history.push({
                    pathname: "/feed/referrals",
                    state: {
                      referralId: cardData.referralId,
                      client: cardData.patientId
                    }
                  });
                }}
                disabled={view}
              >
                {view ? <InlineLoading /> : "View Referral"}
              </S.ViewReferralButton>
            </S.ButtonLeft>
          </S.ButtonGroup>
        )
      });
    case "note":
      return card({
        id: cardData.id,
        referralId: cardData.referralId,
        title: "New Referral Note",
        timestamp: convertTimestamp(cardData.date),
        description: `<strong>Referral note from ${cardData.providerStaffName}</strong`,
        close: () => {
          clearActivity(cardData.id, cardData.type);
        },
        closeBtn: true,
        btnGroup: (
          <S.ButtonGroup>
            <S.ButtonLeft>
              <S.ViewReferralButton
                data-id={`feed-card-view-btn`}
                kind="tertiary"
                onClick={() => {
                  history.push({
                    pathname: "/feed/referrals",
                    state: {
                      referralId: cardData.referralId,
                      client: cardData.patientId,
                      cardId: cardData.id
                    }
                  });
                }}
                disabled={view}
              >
                {view ? <InlineLoading /> : "View Referral Note"}
              </S.ViewReferralButton>
            </S.ButtonLeft>
          </S.ButtonGroup>
        )
      });
    case "update":
      if (
        cardData.referralStatus &&
        cardData.referralStatus.toLowerCase().includes("rejected")
      ) {
        return card({
          id: cardData.id,
          referralId: cardData.referralId,
          title: "Referral Rejected",
          timestamp: convertTimestamp(cardData.date),
          description:
            cardData.patientId === cardData.senderId
              ? `<strong>${cardData.providerStaffName}</strong> declined their referral to <strong>${cardData.serviceName}</strong>.`
              : `<strong>${cardData.providerStaffName}</strong> has rejected <strong>${cardData.patientName}'s</strong> referral to <strong>${cardData.serviceName}</strong>`,
          close: () => {
            clearActivity(cardData.id, cardData.type);
          },
          closeBtn: true,
          disabled: cardData.active === 0 ? true : false,
          btnGroup: (
            <S.ButtonGroup>
              <S.ButtonLeft>
                <S.ViewReferralButton
                  data-id={`feed-card-view-btn`}
                  kind="tertiary"
                  onClick={ async () => {
                    setClient(cardData?.patientId);
                    await getReferralData(cardData);
                    history.push({
                      state: {
                        referralId: cardData.referralId,
                        client: cardData?.patientId,
                        cardId: cardData.id
                      }
                    });
                  }}
                  disabled={view}
                >
                  {view ? <InlineLoading /> : "View Referral"}
                </S.ViewReferralButton>
              </S.ButtonLeft>
            </S.ButtonGroup>
          )
        });
      } else {
        return card({
          id: cardData.id,
          referralId: cardData.referralId,
          title: "Referral Status Updated",
          timestamp: convertTimestamp(cardData.date),
          description: `Referral status updated to <strong>${getDisplayedStatus(
            cardData.referralStatus,
            "",
            true
          )}</strong>`,
          close: () => {
            clearActivity(cardData.id, cardData.type);
          },
          closeBtn: true,
          disabled: cardData.active === 0 ? true : false,
          btnGroup: (
            <S.ButtonGroup>
              <S.ButtonLeft>
                <S.ViewReferralButton
                  data-id={`feed-card-view-btn`}
                  kind="tertiary"
                  onClick={ async () => {
                    setClient(cardData?.patientId);
                    await getReferralData(cardData);
                    history.push({
                      state: {
                        referralId: cardData.referralId,
                        client: cardData?.patientId,
                        cardId: cardData.id
                      }
                    });
                  }}
                  disabled={view}
                >
                  {view ? <InlineLoading /> : "View Referral"}
                </S.ViewReferralButton>
              </S.ButtonLeft>
            </S.ButtonGroup>
          )
        });
      }
    case "assigned":
      return card({
        id: cardData.id,
        referralId: cardData.referralId,
        title: "Referral Assignment Updated",
        timestamp: convertTimestamp(cardData.date),
        description: `Referral to <strong>${
          cardData.serviceName
        }</strong> was assigned${
          cardData.assignedToName
            ? " to <strong>" + cardData.assignedToName + "</strong>."
            : "."
        }`,
        close: () => {
          clearActivity(cardData.id, cardData.type);
        },
        closeBtn: true,
        disabled: cardData.active === 0 ? true : false,
        btnGroup: (
          <S.ButtonGroup>
            <S.ButtonLeft>
              <S.ViewReferralButton
                data-id={`feed-card-view-btn`}
                kind="tertiary"
                onClick={async () => {
                  setClient(cardData?.patientId);
                  await getReferralData(cardData);
                  history.push({
                    state: {
                      referralId: cardData.referralId,
                      client: cardData?.patientId,
                      cardId: cardData.id
                    }
                  });
                }}
                disabled={view}
              >
                {view ? <InlineLoading /> : "View Referral"}
              </S.ViewReferralButton>
            </S.ButtonLeft>
          </S.ButtonGroup>
        )
      });
    case "created":
      return card({
        id: cardData.id,
        referralId: cardData.referralId,
        title: 'New Referral Created',
        timestamp: convertTimestamp(cardData.date),
        description: inbound ? `<strong>Referral received to ${cardData.serviceName}</strong>` : `<strong>Referral sent to ${cardData.serviceName}</strong>`,
        close: () => {
          clearActivity(cardData.id, cardData.type);
        },
        disabled: cardData.active === 0 ? true : false,
        closeBtn: isFeedCardActionDisabled(inbound, cardData, disabledButtons),
        btnGroup: 
          currentReferralLoading === cardData.referralId ? (
            <InlineLoading
              status="active"
              iconDescription="Loading"
              description="Loading..." 
              /> 
          ) : (
          <S.ButtonGroup>
            <S.ButtonLeft>
              <S.ViewReferralButton
                kind="tertiary"
                onClick={ async () => {
                  setClient(cardData?.patientId);
                  await getReferralData(cardData);
                  history.push({
                    state: {
                      referralId: cardData.referralId,
                      client: cardData?.patientId,
                      cardId: cardData.id
                    }
                  });
                }}
                disabled={view}
              >
                {view ? <InlineLoading /> : 'View Referral'}
              </S.ViewReferralButton>
            </S.ButtonLeft>
            <S.ButtonRight>
              <S.ViewButton
                data-id={`feed-card-accept-btn`}
                kind="primary"
                disabled={isFeedCardActionDisabled(
                  inbound,
                  cardData,
                  disabledButtons
                )}
                onClick={async () => {
                  setDisabledButtons(true);
                  await getReferralData(cardData, 'Waiting for Client', false);
                  setClient(cardData?.patientId);
                  history.push({
                    state: {
                      referralId: cardData.referralId,
                      client: cardData?.patientId,
                      cardId: cardData.id
                    }
                });
                }}
              >
                Accept
              </S.ViewButton>
              <S.ViewButton
                data-id={`feed-card-decline-btn`}
                kind="tertiary"
                disabled={isFeedCardActionDisabled(
                  inbound,
                  cardData,
                  disabledButtons
                )}
                onClick={async () => {
                  setClient(cardData?.patientId);
                  await getReferralData(cardData, "Rejected by Receiver", false);
                  history.push({
                    state: {
                      referralId: cardData.referralId,
                      client: cardData?.patientId,
                      isDecline: true,
                      cardId: cardData.id
                    }
                  });
                  setDisabledButtons(true);
                }}
              >
                Reject
              </S.ViewButton>
            </S.ButtonRight>
          </S.ButtonGroup>
        )
      });
    case "expired":
      return card({
        id: cardData.id,
        referralId: cardData.referralId,
        title: "Referral Expired",
        timestamp: convertTimestamp(cardData.date),
        description: `Referral to <strong>${cardData.serviceName}</strong> has expired.`,
        close: () => {
          clearActivity(cardData.id, cardData.type);
        },
        closeBtn: true,
        btnGroup: (
          <S.ButtonGroup>
            <S.ButtonLeft>
              <S.ViewReferralButton
                data-id={`feed-card-view-btn`}
                kind="tertiary"
                onClick={async () => {
                  setClient(cardData?.patientId);
                  await getReferralData(cardData);
                  history.push({
                    state: {
                      referralId: cardData.referralId,
                      client: cardData?.patientId
                    }
                  });
                }}
                disabled={view}
              >
                {view ? <InlineLoading /> : "View Referral"}
              </S.ViewReferralButton>
            </S.ButtonLeft>
          </S.ButtonGroup>
        )
      });
    case "expire-warning":
      return card({
        id: cardData.id,
        referralId: cardData.referralId,
        title: 'New Referral Created',
        timestamp: convertTimestamp(cardData.date),
        description: `Referral ${inbound ? 'received' : 'sent'} to <strong>${
          cardData.serviceName
        }</strong> is about to expire. If no action is taken this referral will expire on ${convertTimestamp10Days(
          cardData.date
        )}.`,
        close: () => {
          clearActivity(cardData.id, cardData.type);
        },
        disabled: cardData.active === 0 || disabledButtons ? true : false,
        closeBtn: isFeedCardActionDisabled(inbound, cardData, disabledButtons),
        btnGroup: 
          currentReferralLoading === cardData.referralId ? (
            <InlineLoading
              status="active"
              iconDescription="Loading"
              description="Loading..." 
              /> 
          ) : (
          <S.ButtonGroup>
            <S.ButtonLeft>
              <S.ViewReferralButton
                data-id={`feed-referrals-card-view-btn`}
                kind="tertiary"
                onClick={async () => {
                  setClient(cardData?.patientId);
                  await getReferralData(cardData);
                  history.push({
                    state: {
                      referralId: cardData.referralId,
                      client: cardData?.patientId
                    }
                  });
                }}
                disabled={view}
              >
                {view ? <InlineLoading /> : 'View Referral'}
              </S.ViewReferralButton>
            </S.ButtonLeft>
            <S.ButtonRight>
              <S.ViewButton
                data-id={`feed-referrals-accept-btn`}
                kind="primary"
                disabled={isFeedCardActionDisabled(
                  inbound,
                  cardData,
                  disabledButtons
                )}
                onClick={async () => {
                  setDisabledButtons(true);
                  await getReferralData(cardData, 'Waiting for Client', false);
                  setClient(cardData?.patientId);
                  history.push({
                    state: {
                      referralId: cardData.referralId,
                      client: cardData?.patientId
                    }
                  });

                }}
              >
                Accept
              </S.ViewButton>
              <S.ViewButton
                data-id={`feed-card-referrals-decline-btn`}
                kind="tertiary"
                disabled={isFeedCardActionDisabled(
                  inbound,
                  cardData,
                  disabledButtons
                )}
                onClick={async () => {
                  setDisabledButtons(true);
                  await getReferralData(cardData, "Rejected by Receiver", false);
                  setClient(cardData?.patientId);
                  history.push({
                    state: {
                      referralId: cardData.referralId,
                      client: cardData?.patientId,
                      isDecline: true,
                      cardId: cardData.id
                    }
                  });
                }}
              >
                Reject
              </S.ViewButton>
            </S.ButtonRight>
            
          </S.ButtonGroup>
        )
      });
    default:
      return null;
  }
};

// Demo for possible max height
export const Template = () => (
  <S.Card>
    <S.CloseBtn>
      <Close24 />
    </S.CloseBtn>
    <S.CardHeader>
      <S.Header>
        <S.Heading>Heading</S.Heading>
        <S.TextSM>Referral ID: 117</S.TextSM>
        <S.TimeStamp>11/18/2021, 11:15:18 AM</S.TimeStamp>
      </S.Header>
    </S.CardHeader>
    <S.CardBody>
      <S.Description>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam vitae
        sagittis purus. Phasellus sit amet sagittis neque, volutpat tempor est.
        Aenean convallis mauris sed massa vulputate elementum. Praesent faucibus
        tortor eu magna volutpat maximus.
      </S.Description>
      <S.Description>
        <strong>Provider Staff Name</strong> has referred you to{" "}
        <strong>Organization Name's</strong> service{" "}
        <strong>Service Name</strong>. By accepting you will add this service to
        your care team.
      </S.Description>
    </S.CardBody>
    <S.ButtonGroup>
      <S.ButtonLeft>
        <Button kind="primary">Accept</Button>
        <Button kind="tertiary">Reject</Button>
      </S.ButtonLeft>
      <Button kind="secondary">View</Button>
    </S.ButtonGroup>
  </S.Card>
);