import styled from "styled-components";
import { ClickableTile, Button } from "carbon-components-react";
import { WarningAlt24 } from "@carbon/icons-react";

export const PageContainer = styled.div`
  /* height: 100%; */
  width: 100%;
  position: relative;
  @media (min-width: 1024px) {
    display: flex;
    flex-flow: column nowrap;
  }
  /* overflow-x: scroll;
  overflow-y: scroll;
  .bx--content-switcher__label {
    margin-left: auto;
    margin-right: auto;
  }
  .bx--content {
    width: calc(100vw - 18rem);
    position: fixed;
  }
  @media (max-width: 900px) {
    width: calc(100% - 2rem);
    overflow: auto;
    margin: auto;
    margin-top: 2rem;
    padding-bottom: 4rem;
  } */
`;

export const Grid = styled.div`
  display: flex;
  flex-flow: column nowrap;
  justify-items: center;
  align-items: flex-start;
`;

export const GridColumn = styled.div`
  display: flex;
`;

export const ProfilePic = styled.div`
  width: 100%;
`;

export const Name = styled(GridColumn)`
  justify-content: space-evenly;
  padding: 0 2rem;
`;

export const GenderAge = styled(GridColumn)`
  grid-area: age;
  justify-content: space-between;
`;

export const Alert = styled(GridColumn)`
  grid-area: alert;
  justify-content: flex-start;
  padding: 1rem 1rem;
  color: red;
`;

export const Email = styled(GridColumn)`
  grid-area: email;
  justify-content: flex-start;
  padding: 0rem 1rem;
`;

export const Phone = styled(GridColumn)`
  grid-area: phone;
  justify-content: flex-start;
  padding: 0rem 1rem;
`;

export const ViewMore = styled(GridColumn)`
  justify-content: flex-start;
  padding: 0rem 1rem;
  color: ${(props) => props.theme.colors.accent.mediumBlue};
`;

export const AlertIcon = styled(WarningAlt24)`
  margin-right: 1rem;
`;

export const BoldText = styled.p`
  font-weight: 800;
  text-align: center;
`;

export const MenuContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 40%;
`;

export const StyledClickableTile = styled(ClickableTile)`
  background-color: white;
  font-weight: 800;
  font-size: 1rem;
  line-height: 50%;
  min-height: 0;

  &&:hover {
    background-color: #cedefa;
  }

  &&.current {
    background-color: #cedefa;
  }
`;

export const Page = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

export const HeaderContainer = styled.div`
  grid-area: header;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 1rem 0;
`;

export const Overlay = styled.div`
  position: absolute;
  z-index: 2;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  visibility: ${(props) => {
    if (props.theme.isOpen) {
      return "visible;";
    }
    return "hidden;";
  }};
  opacity: ${(props) => {
    if (props.theme.isOpen) {
      return "1;";
    }
    return "0;";
  }};
  transition-timing-function: ${(props) => props.theme.motion};
  transition-duration: 700ms;
  transition-property: opacity;
  z-index: ${(props) => {
    if (props.theme.isOpen) {
      return "11;";
    }
    return "0;";
  }};
  @media (min-width: 1024px) {
    display: none;
  }
`;

export const CloseBtn = styled(Button)`
  position: absolute !important;
  z-index: 9999 !important;
  right: 0;
  top: 0;
  @media (min-width: 1024px) {
    display: none !important;
  }
`;

export const SideNav = styled.nav`
  position: absolute;
  box-shadow: 2px 0px 2px rgba(0, 0, 0, 0.1), 3px 0px 5px rgba(0, 0, 0, 0.05);
  background: #ffffff;
  overflow-y: scroll;
  overflow-x: hidden;
  height: 100%;
  left: ${(props) => {
    if (props.theme.isOpen) {
      return "0;";
    }
    return "-16rem;";
  }};
  top: 0;
  transition-timing-function: ${(props) => props.theme.motion};
  transition-duration: 240ms;
  width: 15rem;
  z-index: 999;
  @media (min-width: 1024px) {
    position: relative;
    width: 18.75rem;
    height: auto;
  }
  @media (min-height: 900px) {
    overflow-y: hidden;
  }
`;

export const Content = styled.div`
  z-index: 10;
  width: 100%;
  display: flex;
  flex-flow: column nowrap;
  justify-items: center;
  align-items: center;
  overflow: hidden;
  position: relative;
`;

export const NavBtn = styled.div`
  position: fixed;
  box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.25);
  z-index: 999;
  top: 5rem;
  align-self: flex-start;
  background: ${(props) => props.theme.colors.primary.mossGreen};
  color: #fff;
  padding: 0.25rem;
  width: 48px;
  height: 48px;
  @media (min-width: 1024px) {
    display: none;
  }
`;

export const InnerContainer = styled.div`
  position: relative;
  width: 100%;
  height: auto;
  display: flex;
  min-height: 100vh;
`;
