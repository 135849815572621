import styled from "styled-components";
import { Select, SelectItem } from "carbon-components-react";
import { StyledDropdownWrapper, StyledSelect, StyledWrapper } from "../AboutMe.styled";

const Race = ({ setAboutMeResponse, setDisableButton }) => {
  const items = [
    "White",
    "Black or African American",
    "American Indian or Alaskan Native",
    "Asian",
    "Native Hawaiian or Pacific Islander",
    "Two or More Races",
  ];

  const handleSelectChange = (e) => {
    if (e.target.value === "default") {
      setDisableButton(true);
      return;
    }
    setDisableButton(false);
    setAboutMeResponse((state) => ({
      ...state,
      race: e.target.value,
    }));
  };

  return (
    <StyledWrapper>
      <StyledDropdownWrapper>
        <StyledSelect
          id="Race"
          labelText=" "
          defaultValue="default"
          onChange={(e) => {
            handleSelectChange(e);
          }}
        >
          <SelectItem text="Select Race" value="default" id="default" />
          {items.map((item, index) => {
            return <SelectItem key={index} text={item} value={item} id={index} />;
          })}
        </StyledSelect>
      </StyledDropdownWrapper>
    </StyledWrapper>
  );
};

export default Race;
