import styled from "styled-components";

export const Container = styled.div`
  z-index: 3;
  position: absolute;
  padding: 0.5rem 0;
  width: 100%;
  height: 18rem;
  background-color: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.25);
  overflow-y: scroll;
  text-align: left;
`;

export const Fieldset = styled.div`
  display: flex;
  padding: 0.25rem 0.5rem;
  align-items: center;
  cursor: pointer;
  &:hover {
    background-color: #f4f4f4;
  }
`;

export const Text = styled.p``;

export const CloseBtn = styled.div`
  position: absolute;
  outline: none;
  top: 21rem;
  padding: 1rem 2rem;
  background: #f4f4f4;
  border: 1px solid rgba(0, 0, 0, 0.25);
  display: none;
  @media (pointer: coarse) {
    display: block;
    display: flex;
    justify-content: center;
  }
`;
