import React, { useState } from "react";
import styled from "styled-components";
import {
  ClientSearchDiv,
  DivHeader,
  StyledSearchIcon,
  StyledDatePickerInput,
  ToggleSearchBtn
} from "./static.jsx";
import {
  TextInput,
  Button,
  DatePickerInput,
  TooltipIcon
} from "carbon-components-react";
import useForm from "../../../hooks/useForm";
import validate from "../validator.js";
import { Information16 } from "@carbon/icons-react";
import { formatDate, shouldAutoComplete } from "../../../utils";
const NewClientSearch = ({ handleSearch, clearSearch }) => {
  const {
    values,
    setValues,
    handleChange,
    handlePhoneChange,
    handleDatePicker,
    resetForm
  } = useForm(validate);

  const [searchIsToggled, setSearchIsToggled] = useState(true);
  const toggleSearch = () => {
    searchIsToggled ? setSearchIsToggled(false) : setSearchIsToggled(true);
  };

  return (
    <ClientSearchDiv>
      <DivHeader>
        <h5>
          System Search
          <TooltipIcon
            tooltipText="Search for individuals not already on your care team and invite them to connect."
            renderIcon={Information16}
            style={{ position: "relative", top: "2px", marginLeft: "4px" }}
            direction="right"
          />
        </h5>
        <ToggleSearchBtn kind="ghost" onClick={() => toggleSearch()}>
          {searchIsToggled ? "Close Search" : "Open Search"}
        </ToggleSearchBtn>
      </DivHeader>
      {searchIsToggled && (
        <FormContainer>
          <InputContainer>
            <TextInput
              id="first"
              data-id={`client-search-first-name-input`}
              autoComplete={shouldAutoComplete}
              style={{ marginBottom: "1rem", marginTop: "0" }}
              labelText="First name*"
              placeholder="First Name"
              onChange={handleChange}
              value={values.first}
            />
            <TextInput
              id="last"
              data-id={`client-search-last-name-input`}
              autoComplete={shouldAutoComplete}
              style={{ marginBottom: "1rem", marginTop: "0" }}
              labelText="Last name*"
              placeholder="Last Name"
              onChange={handleChange}
              value={values.last}
            />
            <TextInput
              id="phone"
              data-id={`client-search-number-input`}
              autoComplete={shouldAutoComplete}
              style={{ marginBottom: "1rem", marginTop: "0" }}
              labelText="Phone number"
              placeholder="xxx-xxx-xxxx"
              onChange={handlePhoneChange}
              value={values.phone}
            />

            <StyledDatePickerInput
              style={{ marginBottom: "1rem", marginTop: "0", width: "100%" }}
              datePickerType="single"
              onChange={handleDatePicker}
              value={values.dob}
            >
              <DatePickerInput
                id="client-search-dob"
                data-id={`client-search-date-input`}
                size="md"
                placeholder="mm/dd/yyyy"
                autoComplete={shouldAutoComplete}
                labelText="Date of birth*"
                style={{ minWidth: "100%" }}
                onChange={(e) => {
                  e.target.value = formatDate(e.target.value);
                }}
              />
            </StyledDatePickerInput>
          </InputContainer>
          <ButtonContainer>
            <Button
              data-id={`client-search-submit-btn`}
              onClick={() => handleSearch(values)}
              disabled={!values.first || !values.last || !values.dob}
              style={{ paddingRight: "1rem" }}
            >
              Search
              <StyledSearchIcon />
            </Button>
            <Button
              data-id={`client-search-clear-btn`}
              onClick={() => {
                resetForm();
                clearSearch();
                setValues({ first: "", last: "", phone: "", dob: "" });
              }}
              disabled={!values.first || !values.last || !values.dob}
            >
              Clear
            </Button>
          </ButtonContainer>
        </FormContainer>
      )}
    </ClientSearchDiv>
  );
};

const FormContainer = styled.div`
  margin: 0 2rem 1rem 2rem;
`;

const InputContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr 1fr 1fr 1fr;
  gap: 0rem 1rem;
  @media screen and (min-width: 600px) {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
  }
  @media screen and (min-width: 900px) {
    gap: 0rem 2rem;
  }
  @media screen and (min-width: 1400px) {
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows: 1fr;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  gap: 1rem;
`;

export default NewClientSearch;
