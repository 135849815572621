import React, { useContext, useEffect } from 'react';
import { ProviderCard } from '../../../components/ServiceCard/v2';
import { parseProviderData } from '../view-helpers';
import { SearchContext } from "../../resources/context/SearchResultsContext.context";
import { useClient } from "../../../store/CallLogContext";

const CustomResultView = ({
  result,
  patient
}) => {
  const {
    bookmarkEvent,
    setBookmarkEvent,
    bookmarkedIds,
    setBookmarkedIds,
    activeReferralIds,
  } = useContext(SearchContext);
  const { client } = useClient();


  let provider = parseProviderData(result);

  // check if provider is bookmarked
  if (bookmarkedIds && bookmarkedIds.length > 0) {
    const isBookmarked = bookmarkedIds?.findIndex(id => id === `${result?.idService?.raw}|${result?.idLocation?.raw}`) > -1;
    provider = { ...provider, bookmarked: isBookmarked }
  }

  // check if active referral exists
  if (activeReferralIds && activeReferralIds.length > 0) {
    const activeReferral = activeReferralIds?.find(ref => ref?.serviceId === `${result?.idService?.raw}|${result?.idLocation?.raw}` );
    provider = { ...provider, activeReferralId: activeReferral?.id || null }
  }


  useEffect(() => {
    const updateBookmarkIcon = () => {
      if (bookmarkEvent.eventType === "add") {
        let temp = [];
        const bookmarkEventServiceID = bookmarkEvent.serviceId || bookmarkEvent.provider.serviceId;
        const bookmarkEventLocationID = bookmarkEvent.locationId || bookmarkEvent.provider.locationId;
        if (bookmarkedIds?.includes(`${bookmarkEventServiceID}|${bookmarkEventLocationID}`)) {
          return;
        }
        if (bookmarkedIds && !bookmarkedIds.includes(`${bookmarkEventServiceID}|${bookmarkEventLocationID}`)) {
          temp = [...bookmarkedIds, `${bookmarkEventServiceID}|${bookmarkEventLocationID}`];
        } else if (!bookmarkedIds) {
          temp.push(`${bookmarkEventServiceID}|${bookmarkEventLocationID}`);
        }
        setBookmarkedIds(temp);
      } else if (bookmarkEvent.eventType === "remove") {
        const bookmarkEventServiceID = bookmarkEvent.serviceId || bookmarkEvent.provider.serviceId;
        const bookmarkEventLocationID = bookmarkEvent.locationId || bookmarkEvent.provider.locationId;
        const updatedList = bookmarkedIds.filter((servAndLocId) => {
          return servAndLocId !== `${bookmarkEventServiceID}|${bookmarkEventLocationID}`;
        });
        setBookmarkedIds(updatedList);
      }
    };
    if (bookmarkEvent) {
      updateBookmarkIcon();
      setBookmarkEvent();
    }
  }, [bookmarkEvent]);


  return (
    <ProviderCard 
      provider={provider}
      patient={patient || client}
      isCNResource={!!provider.cnResource}
      showHours={provider?.isNavigationCenter}
      showPhone={provider?.isNavigationCenter}
    />
)};

export default CustomResultView;
