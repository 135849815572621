import styled from "styled-components";

export const PrintablePage = styled.div`
  width: 100vw;
  height: 100vh;
  background-color: white;
`;

export const DateContainer = styled.div`
  margin-left: 0.5rem;
  p {
    margin-top: 0.5rem;
  }
`;

export const PageContent = styled.div`
  margin-top: 0.5rem;
  margin-left: 0.5rem;
  overflow: visible;
`;

export const Summary = styled.div`
  ul {
    margin-left: 1rem;
  }
  li {
    margin-top: 0.5rem;
    display: list-item;
    list-style-type: circle;
    font-size: 12px;
  }
  h4 {
    margin-top: 0.2rem;
  }
`;

export const RecommendedResources = styled.div`
  

  ul {
    margin-left: 1rem;
    margin-top: 0.3rem;
  }
  li {
    margin-top: 0.3rem;
    display: list-item;
    list-style-type: circle;
    font-size: 12px;
  }
  p {
    margin-top: 0.5rem;
    font-size: 12px;
  }
`;

export const Questions = styled.div`
  margin-top: 1rem;
  ol {
    margin-left: 1.5rem;
  }
  li {
    margin-top: 1rem;
    display: list-item;
    list-style-type: decimal;
    font-size: 12px;
  }
`;

export const Footer = styled.div`
  position: absolute;
  z-index: 10000;
  bottom: 0px;
  right: 10px;
`;
