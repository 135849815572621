import styled from "styled-components";

export const Container = styled.div`
  height: 50vh;
  width: 50vh;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  border: 1px solid rgba(0, 0, 0, 0.15);
  background: #ffffff;
  border-radius: 7px;
  box-shadow: 2px 4px 5px rgba(0, 0, 0, 0.05), -2px -2px 5px rgba(0, 0, 0, 0.05);
`;
export const Content = styled.div`
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
`;

export const SubHeading = styled.h1`
  font-size: 1.25rem;
  font-weight: 400;
`;

export const Img = styled.img`
  width: 12rem;
  height: 12rem;
  margin-bottom: 2rem;
`;
