import React from "react";
import { Tabs, Tab } from "carbon-components-react";
import styled from "styled-components";
import ClientInformation from "./tabs/ClientInformation";
import ProviderInformation from "./tabs/ProviderInformation";
import ReviewAndSubmit from "./tabs/ReviewAndSubmit";
const NewReferralForm = ({
  client,
  setClient,
  service,
  tabIndex,
  setTabIndex,
  incrementTab,
  submitReferral,
  hasConsent,
  setHasConsent,
  user,
  doesActiveReferralExist,
  domains,
  loading,
  clients
}) => {
  return (
    <StyledTabs selected={tabIndex}>
      <Tab id="tab-1" label="Client Information"
        onClick={() => setTabIndex(0)}>
        <ClientInformation
          client={client}
          setClient={setClient}
          incrementTab={incrementTab}
          doesActiveReferralExist={doesActiveReferralExist}
          hasConsent={hasConsent}
          setHasConsent={setHasConsent}
          clients={clients}
          loading={loading}
        />
      </Tab>
      <Tab id="tab-2" label="Provider Information" disabled={tabIndex === 0} onClick={() => setTabIndex(1)}>
        <ProviderInformation service={service} incrementTab={incrementTab} />
      </Tab>
      <Tab id="tab-3" label="Review and Submit" disabled={tabIndex <= 1} onClick={() => setTabIndex(2)}>
        <ReviewAndSubmit
          submitReferral={submitReferral}
          client={client}
          service={service}
          user={user}
          domains={domains}
        />
      </Tab>
    </StyledTabs>
  );
};

export default NewReferralForm;

const StyledTabs = styled(Tabs)`
  justify-content: center;

  && .bx--tabs--scrollable__nav-link {
    width: 100%;
  }
`;
