import { useContext, useEffect, useState } from "react";
import {
  FavoriteFilled16,
  Favorite16,
  CheckmarkOutline16,
  ChevronRight32,
  Subtract16,
  Add16
} from "@carbon/icons-react";
import { Tooltip, Button } from "carbon-components-react";
import kit from "ich-ui-kit";
import { useHistory } from "react-router-dom";
import { CallLogContext } from "../../../store/CallLogContext";
import { CapacityBadge, CNResourceBadge } from "../../Badges/v2";
import NeedsAssesmentModal from "../../NeedsAssessmentModal";
import {AssistanceRequestModal} from "../../AssistanceRequestModal";
import useQuery from "../../../hooks/useQuery";
import { SearchContext } from "../../../pages/resources/context/SearchResultsContext.context";
import {
  formatProviderAddress,
  static_confidential_address_text,
  truncateText,
  constructOrgOverviewUrl,
  constructServiceOverviewUrl,
  generateClickToCallLink
 } from "../../../utils";
import { GetSupport } from "../../GetSupport";

import S from "./servicecard.module.scss";


// const BookmarkToggle = ({ isLoggedIn = false, bookmarked = false, toggleBookmarked }) => 
//   (
//     isLoggedIn &&
//     (bookmarked ? (
//       <FavoriteFilled16
//         className={S.favorite}
//         onClick={toggleBookmarked}
//       />
//     ) : (
//       <Favorite16
//         className={S.favorite}
//         onClick={toggleBookmarked}
//       />
//     ))
//   );


export const ProviderCard = ({
  provider,
  patient,
  eligable,
  isCNResource,
  showHours = false,
  showPhone = false
}) => {
  const { setIconHoverColor, setBookmarkEvent, setNewCareTeam, bookmarkedIds } =
    useContext(SearchContext);
  const { apiService } = useContext(kit.ApiContext);
  const { user } = useContext(kit.UserContext);
  const { isInCart, updateResources: updateCart, activeLog } = useContext(CallLogContext);
  const query = useQuery();
  const addToCareTeam = query.get("addToCareTeam");
  const senderId = query.get("sender");
  const [anonymousModal, setAnonymousModal] = useState(false);
  const [anonymousModalSelection, setAnonymousModalSelection] = useState(false);
  const [careTeamModal, setCareTeamModal] = useState(false || addToCareTeam);
  const [careTeamAdded, setCareTeamAdded] = useState(false);
  const [careTeamRemoved, setCareTeamRemoved] = useState(false);
  const [bookmarkLoading, setBookmarkLoading] = useState(false);
  const [providerBookmarked, setProviderBookmarked] = useState(provider?.bookmarked || false);
  const [needsModal, setNeedsModal] = useState(false);
  const [isCNResourceModalOpen,setIsCNResourceModalOpen] = useState(false);
  const [openRemoveFromCareTeam, setOpenRemoveFromCareTeam] = useState(false);
  const [width, setWidth] = useState(window.innerWidth);
  const history = useHistory();

  const isMobile = 500;

  window.onstorage = function (e) {
    if (e.key === "bookmarkEvent") {
      const event = JSON.parse(e.newValue);
      if (event) {
        event.provider.bookmarked = event.eventType === "add" ? true : false;
        setBookmarkEvent({
          from: "service-page",
          provider: event.provider,
          eventType: event.eventType,
        });
      }
    } else if (e.key === "newCareTeam") {
      const event = JSON.parse(e.newValue);
      if (event) {
        setNewCareTeam({
          provider: event.provider,
          eventType: event.eventType,
        });
      }
    }
    return;
  };
  useEffect(() => {
    if (careTeamAdded) {
      setNewCareTeam({ provider: provider, eventType: "add" });
      setCareTeamAdded(false);
    }
  }, [careTeamAdded, provider, setNewCareTeam]);

  useEffect(() => {
    if (careTeamRemoved) {
      setNewCareTeam({ provider: provider, eventType: "remove" });
      setCareTeamRemoved(false);
      setCareTeamAdded(false);
    }
  }, [careTeamRemoved, provider, setNewCareTeam]);

  useEffect(() => {
    const handleResizeWindow = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleResizeWindow);
    return () => {
      window.removeEventListener("resize", handleResizeWindow);
    };
  }, []);


  // Bookmark add and remove functions
  const addBookmark = async () => {
    try {
      setBookmarkLoading(true);
      const { orgSource } = await apiService.post(`/bookmarks/providers`, {
        serviceId: provider.serviceId,
        orgSource: provider.orgSource,
        locId: provider.locationId
      });
      if (orgSource) {
        setProviderBookmarked(true);
      }
      setBookmarkLoading(false);
    } catch (err) {
      console.log(err);
      setBookmarkLoading(false);
    }
  };

  const removeBookmark = async () => {
    try {
      setBookmarkLoading(true);
      const { orgSource } = await apiService.delete(`/bookmarks/providers`, {
        serviceId: provider.serviceId,
        orgSource: provider.orgSource,
        locId: provider.locationId
      });
      if (orgSource) {
        setProviderBookmarked(false);
      }
      setBookmarkLoading(false);
    } catch (err) {
      console.log(err);
      setBookmarkLoading(false);
    }
  };

  const handleNeedsRequest = (_type) => {
    setNeedsModal(true);
  };
  const handleCNResource = (_type) => {
    setIsCNResourceModalOpen(true);
  };
  const handleCNResourceModalClose= (_type) => {
    setIsCNResourceModalOpen(false);
  }

  const handleCreateReferral = () => {
    history.push({
      pathname: '/referral',
      state: {
        orgId: provider.orgId,
        serviceId: provider.serviceId,
        patientId:
          history?.location.state?.patientId ||
          history.state?.patientId ||
          patient?.id,
        referralType: 'Referral Type',
        // referralType: dom || 'Referral Type',
        orgSource: provider.orgSource,
        locationId: provider.locationId,
        c360_orgId: provider.c360_orgId,
        domains: provider.domains,
        serviceAtLocationKey: provider.serviceAtLocationKey
      }
    });
    return;
  };

  const handleViewReferral = () => {
    history.push({
      pathname: '/360dashboard/service-requests/view',
      state: {
        client:
          history?.location.state?.patientId ||
          history.state?.patientId ||
          patient?.id,
        referralId: provider?.activeReferralId, 
        cameFrom: 'patient',
        onCareTeam: true
      }
    });
    return;
  };

  const getProviderAddress = (prov) => {
    return formatProviderAddress(prov, static_confidential_address_text);
  }

  const addBookmarkClick = (e, _serviceId, _locationId) => {
    e.stopPropagation();
    if (!providerBookmarked) {
      setBookmarkEvent({
        from: "resource-page",
        serviceId: _serviceId,
        locationId: _locationId,
        eventType: "add",
      });
      addBookmark();
    } else {
      setBookmarkEvent({
        from: "resource-page",
        serviceId: _serviceId,
        locationId: _locationId,
        eventType: "remove",
      });
      removeBookmark();
    }
  };

  return (
    <>
      <AssistanceRequestModal
        open={isCNResourceModalOpen}
        onClose={handleCNResourceModalClose}
        serviceName={provider?.name || ''}
        providerName={provider?.orgName || ''}
        orgEmail={provider.orgEmail}
        serviceId={provider?.serviceId}
        orgSource={provider?.orgSource}
        orgKey={provider?.orgId}
        locationId={provider?.locationId}
        patient={patient}
      />
      <NeedsAssesmentModal
        cms={{}}
        open={needsModal}
        setOpen={setNeedsModal}
        modalHeading={`Needs Request for ` + provider?.name}
        modalLabel={'Do you want someone to contact you?'}
        serviceName={provider?.name || ''}
        providerName={provider?.orgName || ''}
        orgEmail={provider.orgEmail}
        serviceId={provider?.serviceId}
        orgSource={provider?.orgSource}
        orgKey={provider?.orgId}
        isConnected={provider?.connected}
        locationId={provider?.locationId}
        patient={patient}
      />
      <div className={S.cardSpacing}>
        <div
          className={S.container}
          data-id={`sal-${provider?.serviceAtLocationId}`}
          data-cy-pk={`sal-${
            provider?.serviceAtLocationId || provider?.serviceAtLocationKey
          }`}
          data-cy-bk={`sal-${provider?.serviceAtLocationBusinessId}`}
          data-cy-contact-email={provider?.orgEmail || 'undefined'}
          aria-label={provider?.name}
          onMouseEnter={() => {
            setIconHoverColor(provider?.locationId);
          }}
          onMouseLeave={() => {
            setIconHoverColor(0);
          }}
        >
          {/* <div className={S.favoriteDesktopContainer}>
            <BookmarkToggle isLoggedIn={isLoggedIn} bookmarked={providerBookmarked} toggleBookmarked={(e) => addBookmarkClick(e, provider?.serviceId, provider?.locationId)} />
          </div> */}
          <div className={S.left}>
            <div>
              <div className={S.header}>
                <div
                  data-id={`service-${provider?.serviceId}`}
                  data-cy-bk={`service-${provider?.serviceId}`}
                  className={S.serviceNameConnectedContainer}
                >
                  {/* <div className={S.favoriteMobileContainer}>
                    <BookmarkToggle isLoggedIn={isLoggedIn} bookmarked={providerBookmarked} toggleBookmarked={(e) => addBookmarkClick(e, provider?.serviceId, provider?.locationId)} />
                  </div> */}
                  <p className={S.serviceName}>{provider.name}</p>
                  <div className={S.connected}>
                    {provider.connected && (
                      <Tooltip
                        direction="top"
                        aria-label="Connected"
                        renderIcon={CheckmarkOutline16}
                      >
                        Connected
                      </Tooltip>
                    )}
                  </div>
                </div>
                <div>
                  <a
                    className={S.org}
                    target="_blank"
                    rel="noreferrer noopener"
                    href={constructOrgOverviewUrl(
                      provider?.orgId,
                      provider?.orgSource
                    )}
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                  >
                    {provider.orgName}
                  </a>
                </div>
              </div>
              <p className={S.content}>{getProviderAddress(provider)}</p>
              {showPhone && provider?.phone &&
                (generateClickToCallLink(provider?.phone) ? (
                  <a className={S.content} href={generateClickToCallLink(provider?.phone)}>
                    {provider?.phone}
                  </a>
                ) : (
                  <p className={S.content}>{provider?.phone}</p>
                ))}
              {showHours && provider?.hours && (
                <p className={S.content}>{provider?.hours}</p>
              )}
              {provider.connected && provider.capacity && (
                <CapacityBadge capacity={provider?.capacity} />
              )}
              {Boolean(
                provider?.cnResource && provider?.cnResource === '1'
              ) && <CNResourceBadge />}
            </div>
            <div className={S.dropdownMobileContainer}>
              {activeLog && (
                <div className={S.addToCallLogWrapper}>
                  <Button
                    className={S.addToCallLogButton}
                    kind="primary"
                    hasIconOnly
                    iconDescription="Add to Call Log"
                    onClick={(e) => {
                      e.stopPropagation();
                      updateCart(provider);
                    }}
                    renderIcon={isInCart(provider) ? Subtract16 : Add16}
                    tooltipPosition="left"
                  />
                </div>
              )}
              <GetSupport
                connected={provider?.connected}
                providerEmail={provider?.orgEmail}
                activeReferralId={provider?.activeReferralId}
                onNeedsRequest={handleNeedsRequest}
                onCreateReferral={handleCreateReferral}
                onViewReferral={handleViewReferral}
                isCNResource={Boolean(
                  provider?.cnResource && provider?.cnResource === '1'
                )}
                handleCNResource={handleCNResource}
                hasCapacity={provider?.capacity !== "RED"}
              />
            </div>
          </div>
          <div className={S.middle}>
            <h5 className={S.subheader}>Description</h5>
            <div className={S.description}>
              {truncateText(provider.overview, 200)}
            </div>
            <div className={S.viewMoreContainer}>
              <button
                role="link"
                className={S.viewMore}
                target="_blank"
                rel="noreferrer noopener"
                tabIndex={0}
                onClick={(e) => {
                  let href = constructServiceOverviewUrl(
                    provider?.serviceAtLocationId ||
                      provider?.serviceAtLocationKey,
                    provider?.orgSource
                  );
                  e.stopPropagation();
                  window.open(href);
                }}
              >
                View More <ChevronRight32 />
              </button>
            </div>
          </div>
          <div className={S.right}>
            {activeLog && (
              <div className={S.addToCallLogWrapper}>
                <Button
                  className={S.addToCallLogButton}
                  kind="primary"
                  hasIconOnly
                  iconDescription="Add to Call Log"
                  onClick={(e) => {
                    e.stopPropagation();
                    updateCart(provider);
                  }}
                  renderIcon={isInCart(provider) ? Subtract16 : Add16}
                  tooltipPosition="left"
                />
              </div>
            )}
            <GetSupport
              connected={provider?.connected}
              providerEmail={provider?.orgEmail}
              activeReferralId={provider?.activeReferralId}
              onNeedsRequest={handleNeedsRequest}
              onCreateReferral={handleCreateReferral}
              onViewReferral={handleViewReferral}
              isCNResource={Boolean(
                provider?.cnResource && provider?.cnResource === '1'
              )}
              handleCNResource={handleCNResource}
              hasCapacity={provider?.capacity !== "RED"}
            />
          </div>
        </div>
      </div>
    </>
  );
};
