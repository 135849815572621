import React, { useContext, useEffect, useState } from "react";
import { CSVLink } from 'react-csv';
import { useHistory } from "react-router";
import kit from "ich-ui-kit";
import {
  DataTable,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableHeader,
  TableBody,
  TableCell,
  TableToolbar,
  TableToolbarContent,
  TableToolbarSearch,
  Pagination,
  Button
} from "carbon-components-react";
import { Download32, Renew20 } from "@carbon/icons-react";
import { Loading, StructuredListSkeleton } from "carbon-components-react";
import * as Subpage from "../subpage.styled";

function LongitudinalRecord({ clientID }) {
  const [data, setData] = useState([]);
  const { apiService } = useContext(kit.ApiContext);
  const { user } = useContext(kit.UserContext);
  const history = useHistory();
  const [firstRowIndex, setFirstRowIndex] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if(clientID) {
    getLongitudinalRecord();
  }
  }, [apiService, clientID]);
  
  const getLongitudinalRecord = async () => {
    setIsLoading(true);
    const response = await apiService.get(
      `/eventhistory/${clientID ? clientID : history?.location?.state?.clientID}?assumedId=${user.superAssumedRole?.leadId || ""}`
    );
    if (response.ok) {
      let rows = response.eventhistory.map((r) => ({
        ...r,
        createdAt: new Date(r.createdAt).toLocaleString() // converting from UTC to local time
      }));
      setData((state) => [...rows]);
      setIsLoading(false);
    } else {
      console.log(response.message);
      setIsLoading(false);
    }
  };

  const formattedRows = data.map((el, i) => ({ ...el, id: i }));

  const headers = [
    {
      key: "createdAt",
      header: "Date/Time",
      isSortable: true,
      isSortHeader: true
    },
    {
      key: "eventName",
      header: "Event Name",
      isSortable: false
    },
    {
      key: "eventSummary",
      header: "Event Summary",
      isSortable: false
    }
  ];
  
  return (
    <Subpage.Container>
      <Subpage.InnerContainer>
        <Subpage.Header style={{ justifyContent: "space-between" }}>
          <h3>
            <strong>Event History</strong>
          </h3>
          {!isLoading ? (
            <Renew20 onClick={getLongitudinalRecord} cursor="pointer" />
          ) : (
            <Loading withOverlay={false} small />
          )}
        </Subpage.Header>
        {!isLoading ? (
          <>
            <DataTable
              rows={formattedRows.slice(firstRowIndex, firstRowIndex + 50)}
              headers={headers}
              isSortable
            >
              {({
                rows,
                headers,
                getTableProps,
                getHeaderProps,
                getRowProps,
                onInputChange
              }) => (
                <TableContainer>
                  <TableToolbar>
                    <TableToolbarContent
                      style={{
                        justifyContent: "space-between",
                      }}
                    >
                      <TableToolbarSearch
                        onChange={onInputChange}
                        placeholder="Filter"
                        persistent="true"
                        style={{ border: "1px solid blue", maxWidth: "30rem" }}
                      />
                      <CSVLink 
                        data={formattedRows} 
                        headers={headers} 
                        filename={`event-history-${new Date().toISOString().split("T")[0]}`}
                      >
                        <Button renderIcon={Download32} />
                      </CSVLink>
                    </TableToolbarContent>
                  </TableToolbar>
                  <Table {...getTableProps()}>
                    <TableHead>
                      <TableRow>
                        {headers.map((header) => (
                          <TableHeader
                            {...getHeaderProps({
                              header
                            })}
                            isSortable={header.isSortable}
                            isSortHeader={header.isSortHeader}
                          >
                            {header.header}
                          </TableHeader>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {rows.map((row) => {
                          return (
                            <TableRow key={row.id} {...getRowProps({ row })}>
                              {row.cells.map((cell) => {
                                let entireRow = formattedRows.find((f) => f.id == row.id);
                                if (cell.id.includes("eventSummary") && entireRow?.relatedObjectId?.includes("Referral")) {
                                  let lastPeriod = cell.value.lastIndexOf('.');
                                  let splitvalue = cell.value.substring(0, lastPeriod)
                                  let refid = entireRow.relatedObjectId.split(": ")[1]
                                  let addition = "Referral " + refid + "."
                                  return <TableCell key={cell.id} style={{ cursor: "pointer" }}
                                    onClick={(e) => {
                                      history.push({
                                        pathname: `/referral-dashboard/referral`, 
                                        state: {
                                          clientID: clientID,
                                          referralId: refid,
                                          cameFrom: "event-history",
                                          onCareTeam: true
                                        }
                                      });
                                  }}>{splitvalue + ": "}<a>{addition}</a></TableCell>
                                } else {
                                  return <TableCell key={cell.id}>{cell.value}</TableCell>
                                }
                             })}
                            </TableRow>
                          );
                        })}
                    </TableBody>
                  </Table>
                </TableContainer>
              )}
            </DataTable>
            <Pagination
              style={{
                overflowX: "hidden"
              }}
              backwardText="Previous page"
              forwardText="Next page"
              itemsPerPageText="Items per page:"
              //page={1}
              pageNumberText="Page Number"
              pageSize={50}
              pageSizes={[50]}
              totalItems={formattedRows.length}
              pageSizeInputDisabled={true}
              onChange={({ page }) => {
                setFirstRowIndex(50 * (page - 1));
              }}
            />
          </>
        ) : (
          <StructuredListSkeleton style={{ marginTop: "0.5rem" }} />
        )}
      </Subpage.InnerContainer>
    </Subpage.Container>
  );
}
export default LongitudinalRecord;
