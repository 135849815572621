import {
  checkEmailRegex,
  dobMMDDYYYYFormatRegex,
  validateDateOfBirth,
  checkOptionalPhoneNumberRegex,
} from "../../utils";


// Makes code more accessible by hiding asterisk from screen reader
export const RequiredLabelWrapper = ({ labelText }) => (
  <>
    {labelText}
    <span aria-hidden="true">*</span>
  </>
);

// If additional validations need to be directly added to form, can do so using spread operator in the form, otherwise, add custom validatation functions here with parameters and then pass in paramter values from form 
export const registrationValidations = {
  firstName: () => ({
    required: {
      value: true,
      message: "Please enter first name",
    },
    maxLength: {
      value: 30,
      message: "Name can not exceed 30 characters",
    },
    minLength: {
      value: 1,
      message: "Empty Field not accepted",
    },
  }),
  lastName: () => ({
    required: {
      value: true,
      message: "Please enter last name",
    },
    maxLength: {
      value: 30,
      message: "Name can not exceed 30 characters",
    },
    minLength: {
      value: 1,
      message: "Empty Field not accepted",
    },
  }),
  // validates both using regex pattern and custom validate function
  dateOfBirth: (customErrorMessages, setCustomErrorMessages) => ({
    required: {
      value: true,
      message: "Date of birth required",
    },
    pattern: {
      value: dobMMDDYYYYFormatRegex,
      message: "Enter a valid date in format MM/DD/YYYY"
    },
    validate: (e) => {
      let { isValid, message } = validateDateOfBirth(e);
      setCustomErrorMessages({ ...customErrorMessages, dateOfBirth: message });
      return isValid;
    },
  }),
  email: () => ({
    required: {
      value: true,
      message: "Please enter an email",
    },
    pattern: {
      value: checkEmailRegex,
      message: "Please enter valid email",
    },
  }),
  phone: () => ({
    required: false,
    pattern: {
      message: "(Optional) Enter valid phone number or clear",
      value: checkOptionalPhoneNumberRegex,
    },
  }),
};
