import styled from "styled-components";

export const Card = styled.div`
    background-color: ${(props) => (props.floating ? "#fff" : "transparent")};
    position: ${(props) => (props.floating ? "absolute" : "relative")};
    top: ${(props) => (props.floating && "0.5rem")};
    right: ${(props) => (props.floating && "1rem")};
    box-shadow: ${(props) => (props.floating && props.withShadow && "0 4px 8px 0 rgb(0 0 0 / 20%)")};
    padding: ${(props) => (props.floating && props.noPadding ? "0rem" : "1rem")};
    z-index: ${(props) => (props.floating && "999")};
`;

export const FlexContainer = styled.div`
    margin-top: 0.3rem;
    display: flex;
    align-items: center;
    gap: 0.5rem;
`;

export const GrayText = styled.span`
    font-size: 12px;
    color: gray;
`;

export const ResponsiveAvatar = styled.img`
    align-self: center;
    border-radius: 50%;
    border: 1px solid #8d8d8d;
    height: 2rem;
    width: 2rem;
`;