import { useEffect, useState } from "react";
import { useStrapi } from "../../../../hooks/useStrapi";

export const useDemographicCMS = () => {
  const init = {
    personal: {
      heading: "Personal Information",
      label_firstname: "First Name",
      label_lastname: "Last Name",
      label_nickname: "Preferred name",
      label_dob: "Date of Birth",
      label_age: "Age",
      label_language: "Language",
      label_marital_status: "Marital Status",
      label_ssn: "Social Security Number",
      label_ethnicity: "Ethnicity",
      label_gender: "Gender",
      label_race: "Race",
      label_hispanic: "Hispanic Origin",
      label_occupancy: "Number of People in Household",
      label_occupancy_adults: "Number of Adults in Household",
      label_occupancy_children: "Number of Children (under age 18) in Household",
      label_special_circumstances: "Special Circumstances",
      item_sc_parent: "The parent of a child or pregnant",
      item_sc_married: "Married",
      item_sc_emancipated: "Legally emancipated under New York law"
    },
    contact: {
      heading_contact: "Contact Information",
      label_mobile: "Mobile",
      label_email: "Email",
      label_address_line_1: "Address Line 1",
      label_address_line_2: "Address line 2",
      label_city: "City",
      label_state: "State",
      label_zipcode: "Zip Code",
      label_housing_situation: "Housing Situation",
      label_contact_method: "Preferred Contact Method",
      radio_email: "Email",
      radio_phone: "Mobile",
      radio_text: "Text"
    },
    eContact: {
      heading_emergency_contact: "Emergency Contact",
      label_firstname: "First Name",
      label_lastname: "Last Name",
      label_email: "Email",
      label_relationship: "Relationship"
    },
    btn_edit: "Edit",
    btn_cancel: "Cancel",
    btn_submit: "Submit",
    error_invalid_input: "Please enter valid input",
    error_invalid_email: "Please enter a valid email",
    btn_permanent: "Permanent",
    btn_temporary: "Temporary",
    btn_no_current: "No current housing",
    btn_yes: "Yes",
    btn_no: "No",
    upload_btn: "Upload Image",
    upload_icon_desc: "Clear file",
    genderIdentity_text:
      "You can enter your gender identity on the next question.",
    nickname: {
      id: 7,
      heading: null,
      label: "Enter your preferred name",
      placeholder: "Preferred name",
      error: "Please enter a valid input"
    },
    language: {
      id: 8,
      heading: null,
      label: "Enter other language",
      placeholder: "Enter language here",
      error: "Please enter a valid input"
    }
  };
  const { data, loading } = useStrapi({
    cms: `section-demographic`
  });

  const [demographic, setDemographic] = useState(init);

  useEffect(() => {
    if (loading) {
      setDemographic(init);
      return;
    }
    setDemographic((state) => ({
      personal: {
        heading: data.heading || state.heading,
        label_firstname: data.label_firstname || state.personal.label_firstname,
        label_lastname: data.label_lastname || state.personal.label_lastname,
        label_nickname: data.label_nickname || state.personal.label_nickname,
        label_dob: data.label_dob || state.personal.label_dob,
        label_age: data.label_age || state.personal.label_age,
        label_language: data.label_language || state.personal.label_language,
        label_marital_status:
          data.label_marital_status || state.personal.label_marital_status,
        label_ssn: data.label_ssn || state.personal.label_ssn,
        label_ethnicity: data.label_ethnicity || state.personal.label_ethnicity,
        label_gender: data.label_gender || state.personal.label_gender,
        label_race: data.label_race || state.personal.label_race,
        label_hispanic: data.label_hispanic || state.personal.label_hispanic,
        label_occupancy: data.label_occupancy || state.personal.label_occupancy,
        label_occupancy_adults: data.label_occupancy_adults || state.personal.label_occupancy_adults,
        label_occupancy_children: data.label_occupancy_children || state.personal.label_occupancy_children,
        label_special_circumstances:
          data.label_special_circumstances || state.label_special_circumstances,
        item_sc_parent: data.item_sc_parent || state.item_sc_parent,
        item_sc_married: data.item_sc_married || state.item_sc_married,
        item_sc_emancipated:
          data.item_sc_emancipated || state.item_sc_emancipated
      },
      contact: {
        heading_contact: data.heading_contact || state.contact.heading_contact,
        label_mobile: data.label_mobile || state.contact.label_mobile,
        label_email: data.label_email || state.contact.label_email,
        label_address_line_1:
          data.label_address_line_1 || state.contact.label_address_line_1,
        label_address_line_2:
          data.label_address_line_2 || state.contact.label_address_line_2,
        label_city: data.label_city || state.contact.label_city,
        label_state: data.label_state || state.contact.label_state,
        label_zipcode: data.label_zipcode || state.contact.label_zipcode,
        label_housing_situation: data.label_housing_situation || state.contact.label_housing_situation,
        label_contact_method:
          data.label_contact_method || state.contact.label_contact_method,
        radio_email: data.radio_email || state.contact.radio_email,
        radio_phone: data.radio_phone || state.contact.radio_phone,
        radio_text: data.radio_text || state.contact.radio_text
      },
      eContact: {
        heading_emergency_contact:
          data.heading_emergency_contact ||
          state.eContact.heading_emergency_contact,
        label_firstname: data.label_firstname || state.eContact.label_firstname,
        label_lastname: data.label_lastname || state.eContact.label_lastname,
        label_email: data.label_email || state.eContact.label_email,
        label_mobile: data.label_mobile || state.eContact.label_mobile,
        label_relationship:
          data.label_relationship || state.eContact.label_relationship
      },
      btn_edit: data.btn_edit || state.btn_edit,
      btn_cancel: data.btn_cancel || state.btn_cancel,
      btn_submit: data.btn_submit || state.btn_submit,
      error_invalid_input:
        data.error_invalid_input || state.error_invalid_input,
      error_invalid_email:
        data.error_invalid_email || state.error_invalid_email,
      btn_permanent: data.btn_permanent || state.btn_permanent,
      btn_temporary: data.btn_temporary || state.btn_temporary,
      btn_no_current: data.btn_no_current || state.btn_no_current,
      btn_yes: data.btn_yes || state.btn_yes,
      btn_no: data.btn_no || state.btn_no,
      upload_btn: data.upload_btn || state.upload_btn,
      upload_icon_desc: data.upload_icon_desc || state.upload_icon_desc,
      genderIdentity_text:
        data.genderIdentity_text || state.genderIdentity_text,
      nickname: data.nickname || state.nickname,
      language: data.language || state.language
    }));
  }, [data]);

  return { demographic };
};
