import styled from "styled-components";
import { SkeletonPlaceholder } from "carbon-components-react";

export const Container = styled.div`
  padding: 1rem 1rem;
  position: relative;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  margin-bottom: 1rem;
  width: 100%;
  @media (max-width: 1024px) {
    margin-top: 3rem;
  }
`;
export const ProfilePic = styled.img`
  width: 10rem;
  height: 10rem;
  border-radius: 50%;
`;
export const ProfilePicContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  margin-bottom: 1rem;
`;

export const FieldSet = styled.div`
  width: 100%;
  text-align: start;
  margin-bottom: 1rem;
`;
export const Heading = styled.h1`
  font-weight: 600;
  font-size: 18px;
  margin-bottom: 0.5rem;
  align-self: flex-start;
`;
export const Text = styled.p`
  font-size: 16px;
  margin-bottom: 0.5rem;
  overflow-wrap: break-word;
`;

export const Link = styled.a`
  font-size: 16px;
  margin-bottom: 0.5rem;
  overflow-wrap: break-word;
  text-decoration: none;
`;

export const SkeletonImg = styled(SkeletonPlaceholder)`
  width: 10rem;
  height: 10rem;
`;
