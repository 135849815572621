export default function baseSearchConfig({ searchTerm = '' }) {
  return {
    function_score: {
      query: {
        bool: {
          should: [
            {
              constant_score: {
                filter: {
                  multi_match: {
                    query: searchTerm,
                    fields: [
                      'nameLocation',
                      'nameOrganization',
                      'nameService',
                      'taxonomyTerm',
                      'taxonomyTermSynonyms',
                      'coverageNotes',
                      'alternateServiceName',
                      'alternateOrgName',
                      'address1PhysicalAddress',
                      'cityPhysicalAddress',
                      'regionPhysicalAddress',
                      'zipcodePhysicalAddress',
                      'coverage.zipPostalCode'
                    ],
                    type: 'cross_fields',
                    minimum_should_match: '6<90%'
                  }
                },
                boost: 2
              }
            }
          ]
        }
      },
      score_mode: 'min'
    }
  };
}