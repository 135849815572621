import React, { useState, useEffect, useContext } from "react";
import * as S from './overview.styled';
import Domain from '../check-in/icon/domain.icon';
import kit from "ich-ui-kit";
import { useHistory } from "react-router";
import socket from "../../../../socket";
import { InlineLoading, Tooltip } from "carbon-components-react";
import { Information16 } from "@carbon/icons-react";
import { getLocalTime } from "../../../../utils";

const imageMapping =
  {
    'housing': '/domains/housing.svg',
    'health': '/domains/Health.svg',
    'financial': '/domains/financial.svg',
    'employment': '/domains/employment.svg', 
    'income': '/domains/legal.svg',
    'education':'/domains/education.svg',
    'food':'/domains/food.svg',
    'transportation':'/domains/transportation.svg',
    'safety':'/domains/safety.svg',
    'social-connection':'/domains/social connection.svg',
    'dependent-care':'/domains/dependent care.svg',
  }
function convertToISOString(dateString) {
  const [datePart, timePart] = dateString.split(' ');
  const [secondsPart, millisecondsPart] = timePart.split('.');
  const truncatedMilliseconds = millisecondsPart.slice(0, 3);
  const paddedMilliseconds = truncatedMilliseconds.padEnd(3, '0');
  const isoDate = `${datePart}T${secondsPart}.${paddedMilliseconds}Z`;
  return isoDate;
}
function formatDateWithoutOffset(originalDateString) {
  var originalDate = new Date(originalDateString);
  var year = originalDate.getFullYear();
  var month = String(originalDate.getMonth() + 1).padStart(2, '0');
  var day = String(originalDate.getDate()).padStart(2, '0');
  var hours = String(originalDate.getHours()).padStart(2, '0');
  var minutes = String(originalDate.getMinutes()).padStart(2, '0');
  var seconds = String(originalDate.getSeconds()).padStart(2, '0');
  var milliseconds = String(originalDate.getMilliseconds()).padStart(3, '0');
  var formattedDate = `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.${milliseconds}Z`;
  return formattedDate;
}

function isDST() {
  var now = new Date();
  var jan = new Date(now.getFullYear(), 0, 1);
  var jul = new Date(now.getFullYear(), 6, 1);
  var stdTimezoneOffset = Math.max(jan.getTimezoneOffset(), jul.getTimezoneOffset());
  var isDST = now.getTimezoneOffset() < stdTimezoneOffset;
  return isDST;
}

function DragAndDrop({ latestAssessment, domainPriorities, latestDDAssessment, client, responseMapping}) {
  const { user } = useContext(kit.UserContext);
  const { apiService } = useContext(kit.ApiContext);
  const responses = latestAssessment?.responses;
  const [domains, updateDomains] = useState(domainPriorities);
  const [referrals, setReferrals] = useState([]);
  const [checkinResponses, setCheckinResponses] = useState(latestAssessment?.responses);
  const [estimatedResponses, setEstimatedResponses] = useState(latestDDAssessment?.scoreSummary);
  const hasDDAssessment = ["Employment", "Income", "Transportation"];
  const history = useHistory();
  const [loading, setLoading] = useState(true);

  // SIPD-3690 Convert clientReorderDomainsDate and checkinSubmitDate to UTC time and compare
  const parsedClientReorderDomainsDate = new Date(domains[0].updateAt); // This is an Center time
  /**
   * SIPD-3691 Debug: Handling Timestamps in Different Time Zones
   * 
   * Problem:
   * The 'domains[0].updatedAt' timestamp is stored in Central Time (CST) in the DEV environment,
   * but in UTC in UAT and PROD environments.
   * 
   * Solution:
   * 1. Check the current URL to determine the environment:
   *    - If the URL contains 'localhost' or 'dev', treat the timestamp as CST and convert it to UTC.
   *    - If it's not 'localhost' or 'dev', treat the timestamp as UTC.
   * 
   * 2. Adjust for Daylight Saving Time (DST):
   *    - If DST is currently in effect, add 5 hours to the timestamp (CST to UTC).
   *    - If DST is not in effect, add 6 hours to the timestamp (CST to UTC).
   * 
   * 3. Convert the adjusted timestamp to the desired format.
   * 
   * Usage:
   * - 'parsedClientReorderDomainsDate': Input timestamp to be adjusted and formatted.
   * - 'utcClientReorderDomainsDate': Adjusted and formatted UTC timestamp.
   */
  const currentUrl = window.location.href;
  if (currentUrl.includes('localhost') || currentUrl.includes('dev') ){
    // Check if Daylight Saving Time (DST) is in effect
    if (isDST()){
      parsedClientReorderDomainsDate.setHours(parsedClientReorderDomainsDate?.getHours() + 5);
    } else {
      parsedClientReorderDomainsDate.setHours(parsedClientReorderDomainsDate?.getHours() + 6);
    }
  }
  // Convert the adjusted timestamp to the desired format without adding a time offset
  const utcClientReorderDomainsDate = formatDateWithoutOffset(parsedClientReorderDomainsDate);

  const parsedCheckinSubmitDate = latestAssessment.date; // This is an UTC time
  // Format parsedCheckinSubmitDate to ISOString
  const utcCheckinSubmitDate = convertToISOString(parsedCheckinSubmitDate);

  function sortByPriority(l) {
    const result = [];
    l.forEach((item) => {
      const correspondingAnswer = responses.find((answerItem) => answerItem.text === item.name);
      if (correspondingAnswer) {
        const combinedItem = { ...item, answer: correspondingAnswer.answer };
        result.push(combinedItem);
      }
    });
    const sortedResult = result.sort(
      (c, d) => {
        if (c.answer !== d.answer) {
          return c.answer > d.answer ? 1 : -1;
        } else {
          return c.name.localeCompare(d.name);
        }
      }
    );
    return sortedResult;
  }
  
  useEffect(() => {
    if (utcClientReorderDomainsDate < utcCheckinSubmitDate) {
      const sortedDomainPriorities = sortByPriority(domainPriorities);
      updateDomains(sortedDomainPriorities);
    } else {
      updateDomains(domains.sort((c,d) => (c.priority > d.priority) ? 1:-1))
    }
  }, []); 

  const wellbeingMapping = [
    {text: "No Response", color: "#c6c6c6", answer: 0},
    {text: "Not OK", color: "#ec5c29", answer: 1},
    {text: "OK", color: "#faa41a", answer: 2},
    {text: "Good", color: "#f4db5f", answer: 3},
    {text: "Great", color: "#4ac1e0", answer: 4},
    {text: "Excellent", color: "#95c93d", answer: 5},
  ]

  const getCheckinColor = (domain) => {
    const resp = checkinResponses.find((item) => item.text === domain);
    return wellbeingMapping.find((item) => item.answer.toString() === resp.answer);
  }

  const getEstimatedColor = (domain) => {
    if (estimatedResponses) {
      const resp = estimatedResponses[domain.toLowerCase()];
      if (resp) {
        return wellbeingMapping.find((item) => item.answer === resp);
      } else {
        return {text: "No Data", color: "#c6c6c6", answer: 0}
      }
    } else {
      return {text: "No Data", color: "#c6c6c6", answer: 0}
    }
    
  }

  const handleView = (referralId) => {
    history.push({
      pathname: "/360dashboard/service-requests/view",
      state: {
        clientID: client,
        referralId,
        cameFrom: "patient",
        onCareTeam: true
      }
    });
  };

  useEffect(() => {
    setEstimatedResponses(latestDDAssessment?.scoreSummary); 
  }, [latestDDAssessment]);

  useEffect(() => {
    setLoading(true);
    const getReferrals = async () => {
      if (client) {
        const data = await apiService.get(`/referrals?clientId=${client}`);
        if (data && data?.requests) {
          let refs = getLocalTime(data?.requests);
          setReferrals(
            refs.filter(
              (r) =>
                r?.status?.toLowerCase() === "working"
            )
          );
        }
      }
    }
    getReferrals();
    
    setLoading(false);
  }, [user]);

  const colorBlindLegend = (status) => {
    if (status) {
      switch (status.toLowerCase()) {
        case "not ok":
          return <>1</>;
        case "ok":
          return <>2</>;
        case "good":
          return <>3</>;
        case "great":
          return <>4</>;
        case "excellent":
          return <>5</>;
        default:
          return <></>;
      }
    }
  };
  
  return (
    <div>
      <S.HeaderCard className="domains-thumb">
        {/* within this card, we want to have a "row" of information */}
        <S.RowBlockHeader theme={{width: "4rem"}}><strong>Priority</strong></S.RowBlockHeader>
        <S.RowBlockHeaderDomain theme={{width: "7rem"}}><strong>Domain</strong></S.RowBlockHeaderDomain>
        <S.RowBlockResourcesHeader theme={{width: "20rem"}}><strong>Open Referrals</strong></S.RowBlockResourcesHeader>
        <S.RowBlockHeaderCheckin theme={{width: "6rem"}}><strong>Check-in</strong>
          <Tooltip iconName={Information16} style={{marginLeft: "0rem"}}>
            <p style={{marginLeft: "0rem"}}>Check-In Well-being is calculated from the latest check-in responses.</p>
          </Tooltip>
        </S.RowBlockHeaderCheckin>
        <S.RowBlockHeaderCheckin theme={{width: "7rem"}}><strong>Estimated</strong>
          <Tooltip iconName={Information16}>
            <p>Estimated Well-being is calculated from the latest self-report questionnaire responses.</p>
          </Tooltip>
        </S.RowBlockHeaderCheckin>
      </S.HeaderCard>
      <S.DropContainer>
            {domains.map(({id, name}, index) => {
                const ans = responses.find(e => e.text === name)?.answer || 0;
                let hasBorder = false
                if (index <= 4) {
                  hasBorder = true
                }
                let resources = referrals?.filter(
                  (e) => e.referralType === name
                );
                if (resources && resources.length > 0) {
                  resources = [... new Set(resources.map((r) => r.servName))];
                  resources = referrals?.filter((ref) => resources.includes(ref.servName))
                }
                return (
                  // <S.CardContainer theme={{ border: hasBorder }}>
                    <S.Card key={id} className="domains-thumb">
                      <S.RowBlock theme={{width: "4rem"}}>{index + 1}</S.RowBlock>
                      <S.RowBlockDomain theme={{width: "7rem"}}>{name}</S.RowBlockDomain>
                      <S.RowBlockResources theme={{width: "20rem"}}>
                      {loading ? 
                        <InlineLoading/> : 
                        resources && resources.length > 0 ? 
                        resources.map((item) => 
                          {return (
                            <a style={{cursor: "pointer"}} onClick={() => {
                              handleView(item.id)
                            }}>{item.servName}</a>
                            )
                          })
                          : 
                        "No Related Resources"
                      }
                      </S.RowBlockResources>
                      <S.RowBlockCheckin theme={{width: "6rem"}}>    
                        <S.LegendCircle theme={{ bcolor: getCheckinColor(name).color }}>
                          {colorBlindLegend(getCheckinColor(name).text)}
                        </S.LegendCircle>    
                        {getCheckinColor(name).text} 
                      </S.RowBlockCheckin>
                      <S.RowBlockEstimated theme={{width: "6rem"}}>
                        <S.FlexBox>
                          <S.LegendCircle theme={{ bcolor: getEstimatedColor(name).color }}>
                            {colorBlindLegend(getEstimatedColor(name)?.text)}
                          </S.LegendCircle>    
                          {getEstimatedColor(name)?.text}
                        </S.FlexBox>
                      </S.RowBlockEstimated>
                    </S.Card>
                  // </S.CardContainer>
                )
              }
          )}
        </S.DropContainer>

    </div>
  );
}

export default DragAndDrop;