import { ChevronLeft24, ChevronRight24, ErrorFilled32 } from "@carbon/icons-react";
import { Button, Loading } from "carbon-components-react";
import kit from "ich-ui-kit";
import { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { getNewBadge } from "../../../../../../utils";
import { useCompleteProfile } from "../../../cms";
import { ProgressIndicatorBar } from "../../GuidedExperience";
import { ProgressIndicatorWrapper } from "./AboutMe.styled";
import VariableInterface from "./UIcomponents/VariableInterface";
const AboutMe = ({
  setControllerIndex,
  setOverallIndex,
  setAboutMeIndex,
  aboutMeIndex,
  setAboutMeResponse,
  aboutMeResponse,
  emergencyContactInfo,
  setEmergencyContactInfo,
  housingOccupancy,
  setHousingOccupancy, 
  clientID,
  clientInfo
}) => {
  const { stepsCMS: cms } = useCompleteProfile();
  const { user } = useContext(kit.UserContext);
  const [file, setFile] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);

  const submitData = async (data) => {
    if (data !== undefined) {
      try {
        setIsLoading(true);
        const response = await fetch(
          `${process.env.REACT_APP_API_ENDPOINT}/account/client`,
          {
            method: "put",
            headers: {
              Authorization: `Bearer ${user.accessToken} ${user.idToken}`,
              "Content-Type": "application/json"
            },
            body: JSON.stringify({
              ...data,
              userId: clientID
            })
          }
        );
        setIsLoading(false);
        return response.status;
      } catch (e) {
        setIsLoading(false);
        setIsError(true);
        console.log(e);
      }
    }
  };
  useEffect(() => {
    setFile(file);
  }, [file]);

  const [disableButton, setDisableButton] = useState(false);
  const [housingSituation, setHousingSituation] = useState(true);
  const [housingSkipped, setHousingSkipped] = useState(false);
  // Filtering out "Upload a Profile Question" from the CMS
  const questions = cms.about_me_questions.map((q) => q.question).filter(question => !question.includes("profile"));
  const questionTitles = {
    0: 'Housing Situation',
    1: 'Address',
    2: 'Household Size',
    3: 'Marital Status',
    4: 'Language',
    5: 'Preferred name',
    6: 'Ethnicity',
    7: 'Race',
    8: 'Gender',
    9: 'Emergency Contact',
    10: 'Emergency Contact'
  }
  const images = [
    <StyledImg
      src="/icons/House.svg"
      alt="Home Address"
      height="150"
      width="150"
    />,
    <StyledImg
      src="/icons/House.svg"
      alt="Home Address"
      height="150"
      width="150"
    />,
    <StyledImg
      src="/icons/House.svg"
      alt="Home Address"
      height="150"
      width="150"
    />,
    <StyledImg
      src="/icons/MaritalStatus.svg"
      alt="Marital Status"
      height="150"
      width="150"
    />,
    <StyledImg
      src="/icons/Language.svg"
      alt="Language Spoken"
      height="150"
      width="150"
    />,
    <StyledImg
      src="/icons/NameTag.svg"
      alt="Preferred Name"
      height="150"
      width="150"
    />,
    <StyledImg
      src="/icons/Person.svg"
      alt="Ethnicity"
      height="150"
      width="150"
    />,
    <StyledImg src="/icons/race.png" alt="Race" height="150" width="150" />,
    <StyledImg
      src="/icons/GenderIdentity.svg"
      alt="Gender Identity"
      height="150"
      width="150"
    />,
    <StyledImg
      src="/icons/Ambulance.svg"
      alt="Emergency contact"
      height="150"
      width="150"
    />,
    <StyledImg
      src="/icons/Ambulance.svg"
      alt="Emergency contact"
      height="150"
      width="150"
    />
  ];

  useEffect(() => {
    setDisableButton(
      aboutMeIndex === 0 ||
        aboutMeIndex === 1 ||
        aboutMeIndex === 3 ||
        aboutMeIndex === 4 ||
        aboutMeIndex === 5 ||
        aboutMeIndex === 6 ||
        aboutMeIndex === 7 ||
        aboutMeIndex === 8 ||
        aboutMeIndex === 9 ||
        aboutMeIndex === 10 ||
        aboutMeIndex === 2
        ? true
        : false
    );
  }, [aboutMeIndex]);

  const aboutMeIndexController = (skip = false) => {
    if (aboutMeIndex === 0 && (!housingSituation || skip)) {
      setAboutMeIndex((index) => index + 2);
      setOverallIndex((index) => index + 2);
      setHousingSkipped(skip ? true : false);
      return;
    }
    if (aboutMeIndex < 11) {
      if (aboutMeIndex === 10) {
        setControllerIndex(6);
        setOverallIndex((prev) => prev + 1);
      } else {
        setAboutMeIndex((prev) => prev + 1);
        setOverallIndex((prev) => prev + 1);
      }
      if (!skip) {
        submitData({
          ...aboutMeResponse,
          eContact: emergencyContactInfo,
          housingOccupancy: housingOccupancy
        });
      }
      return;
    }
  };

  const backButtonController = () => {
    if (aboutMeIndex > 0) {
      if (aboutMeIndex === 2 && (!housingSituation || housingSkipped)) {
        setAboutMeIndex((index) => index - 2);
        setOverallIndex((index) => index - 2);
      } else {
        setAboutMeIndex((prev) => prev - 1);
        setOverallIndex((prev) => prev - 1);
      }
    }
  };
  return (
    <Wrapper>
      {isLoading && <Loading withOverlay={true} />}
      {isError ? (
        <ErrorContainer>
          <div>
            <ErrorFilled32
              style={{ height: "100px", width: "100px" }}
              color="red"
            />
            <br />
            <br />
            <br />
            <span>{cms.error_text}</span>
            <br />
            <br />
            <Button size="sm" onClick={() => window.location.reload(true)}>
              {cms.btn_restart}
            </Button>
          </div>
        </ErrorContainer>
      ) : (
        <>
        <div style={{display: 'flex', alignItems: 'center'}}>
          <GoBackButton
            disabled={aboutMeIndex === 0}
            kind={"ghost"}
            onClick={() => {
              backButtonController();
            }}
          >
            <ChevronLeft24 />
          </GoBackButton>
          <ProgressIndicatorWrapper>
            <ProgressIndicatorBar currentIndex={aboutMeIndex} setAboutMeIndex={setAboutMeIndex}/>
          </ProgressIndicatorWrapper>
            <GoForwardButton
              disabled={aboutMeIndex === 10}
              kind={"ghost"}
              onClick={() => {
                aboutMeIndexController(true);
              }}
            >
              <ChevronRight24 />
            </GoForwardButton>
          </div>
          <HeaderWrapper>
            <TextWrapper>
              <h4>{questionTitles[aboutMeIndex]}</h4>
              <p>{questions[aboutMeIndex]}</p>
            </TextWrapper>
            <ImageWrapper>{images[aboutMeIndex]}</ImageWrapper>
          </HeaderWrapper>
          <InterfaceContainer aboutMeIndex={aboutMeIndex}>
            <VariableInterface
              index={aboutMeIndex}
              setEmergencyContactInfo={setEmergencyContactInfo}
              setAboutMeResponse={setAboutMeResponse}
              setDisableButton={setDisableButton}
              setFile={setFile}
              setHousingSituation={setHousingSituation}
              housingOccupancy={housingOccupancy}
              setHousingOccupancy={setHousingOccupancy}
            />
          </InterfaceContainer>
          <ButtonWrapper aboutMeIndex={aboutMeIndex}>
            <StyledButton
              disabled={disableButton}
              onClick={() => {
                aboutMeIndexController();
              }}
            >
              Continue
            </StyledButton>
            <StyledButton
              kind={"ghost"}
              onClick={() => {
                aboutMeIndexController(true);
              }}
            >
              Skip
            </StyledButton>
          </ButtonWrapper>
        </>
      )}
    </Wrapper>
  );
};

export default AboutMe;

const ErrorContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  text-align: center;
  padding-right: 0.5rem;
  padding-left: 8rem;
  @media screen and (max-width: 600px) and (max-height: 900px) {
    padding: 0 3rem;
  }
`;

const HeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media screen and (max-width: 768px) {
    justify-content: center;
  }
`;

const Wrapper = styled.div`
  height: fit-content;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  padding-top: 1rem;
  padding-left: 0.5rem;
  padding-right: 8rem;
  @media screen and (min-height: 880px) and (min-width: 1800px) {
    padding-top: 2.5rem;
  }
  @media screen and (max-width: 600px) {
    padding: 0;
    margin-top: 1rem;
  }
`;

const ImageWrapper = styled.div`
  margin-top: 1rem;
  width: fit-content;
  height: fit-content;
`;

const TextWrapper = styled.div`
  p {
    font-size: 18px;
    font-weight: 400;
  }
  h4 {
    font-weight: 500;
    line-height: 1.3;
    margin-bottom: 0.5rem;
  }
  @media screen and (min-height: 880px) and (min-width: 1600px) {
    p {
      font-weight: 400;
      font-size: 20px;
    }
  }
  margin-right: 2rem;
  @media screen and (max-width: 768px) {
    margin-top: 1rem;
    margin-left: 1rem;
    margin-right: 0.5rem;
    width: 60%;
  }
`;

const GoBackButton = styled(Button)`
  padding: 0;
  width: 2rem;
  color: #0f62fe;
`;

const GoForwardButton = styled(Button)`
  padding: 0;
  width: 2rem;
  color: #0f62fe;
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: fit-content;
  margin-bottom: 2rem;
  margin-top: 2rem;
  position: relative;
  @media screen and (max-width: 768px) {
    width: 100%;
    flex-direction: column;
    align-items: center;
  }
`;

const StyledButton = styled(Button)`
  padding-right: 12px;
  width: 15rem;
  @media screen and (max-width: 768px) {
    margin-left: 0;
    width: 20rem;
    justify-content: center;
  }
`;

const InterfaceContainer = styled.div`
  display: flex;
  height: 300px;
  width: 600px;
  @media screen and (min-height: 880px) and (min-width: 1800px) {
    /* height: 50%; */
  }
  @media screen and (max-width: 800px){
    justify-content: center;
    height: ${props => props.aboutMeIndex === 1 ? 'fit-content' : '200px'};
    width: 100%;
    margin-top: 1rem;
  }
  @media screen and (max-height: 768px){
    height: ${props => props.aboutMeIndex === 1 ? 'fit-content' : '200px'};
  }
`;

const StyledImg = styled.img`
  @media screen and (max-width: 600px) {
    width: 100px;
    height: 100px;
  }
`;
