import OrgOverviewCards from "./org-overview-cards";
import kit from "ich-ui-kit";
import { CSVLink } from 'react-csv';
import { 
  CardDiv, 
  Flex, 
  FlexCenter,
  ToolbarInner,
  SearchWrapper,
  StyledTableContainer,
  StyledTableRow,
  DownloadWrapper,
  DownloadButton,
} from "../../index.styled";
import {
  DataTable,
  Table,
  TableHead,
  TableRow,
  TableHeader,
  TableBody,
  TableCell,
  TableToolbar,
  TableToolbarContent,
  Search,
  PaginationNav
} from "carbon-components-react";
import { Download16 } from "@carbon/icons-react";
import { useState, useEffect, useContext } from "react";

export const headers = [
  {
      key: "name",
      header: "Service Name",
      label: "Service Name",
      isSortable: true,
      isSortHeader: true
  },
  {
    key: "totalReferrals",
    header: "Total Referrals",
    label: "Total Referrals",
    isSortable: false
  },
  {
      key: "complete",
      header: "Completed Referrals",
      label: "Completed Referrals",
      isSortable: false
  },
  {
      key: "nps",
      header: "Avg. NPS Score",
      label: "Avg. NPS Score",
      isSortable: true
  },
  {
      key: "completion",
      header: "Avg. Days to Completion",
      label: "Avg. Days to Completion",
      isSortable: true
  }
]

export const OrganizationStats = ({referrals, width, loadingOrg, allServices, orgStats}) => {
  const { apiService } = useContext(kit.ApiContext);
  const { user } = useContext(kit.UserContext);
  const [currentPage, setCurrentPage] = useState(0);
  const [loading, setLoading] = useState(true);
  const [allServicesArray, setAllServicesArray] = useState(orgStats);
  const [displayedServices, setDisplayedServices] = useState(orgStats);
  const [displayedServicesDownload, setDisplayedServicesDownload] = useState(orgStats);

  useEffect(() => {
    setDisplayedServices(orgStats);
    setAllServicesArray(orgStats);
    setLoading(false);
  }, [apiService, orgStats]);

  const getOverviewData = () => {
    let highestNPS = 0;
    let highestNPSService = '';
    let mostReferrals = 0;
    let mostReferralsService = '';
    let lowestCompletion = Infinity;
    let lowestCompletionService = '';
    if (allServicesArray) {
      allServicesArray.map((service) => {
        let nps = service.nps;
        if (nps > highestNPS) {
          highestNPS = service.nps;
          highestNPSService = service.name;
        }
        if (service.totalReferrals > mostReferrals) {
          mostReferrals = service.totalReferrals;
          mostReferralsService = service.name;
        }
        if (service.completion < lowestCompletion) {
          lowestCompletion = service.completion;
          lowestCompletionService = service.name;
        }
      })
      return [highestNPSService, mostReferralsService, lowestCompletionService]
    } else {
      return ['--', '--', '--']
    }
    
  }

  const searchOrg = (e) => {
    let items = [...allServicesArray];
    items = items.filter((item) => (
        item.name?.toLowerCase().includes(e.toLowerCase())
    ))
    setDisplayedServices(items);
  }
  return (<>{
    !loading && (
      <>
    <OrgOverviewCards 
      referrals={allServices}
      highestNPS={getOverviewData()[0]}
      mostReferrals={getOverviewData()[1]}
      lowestcompleted={getOverviewData()[2]}
    />
    <CardDiv>
        <DataTable
            rows={displayedServices}
            headers={headers}
            // isSortable
        >
            {({
                headers,
                rows,
                getRowProps,
                getTableProps,
                getHeaderProps,
            }) => (
            <StyledTableContainer>
                <TableToolbar style={{
                    marginBottom: "1rem",
                    backgroundColor: "white",
                }}>
                    <TableToolbarContent style={{height: "100%"}}>
                        <ToolbarInner>
                            <SearchWrapper>
                                <Search
                                    onChange={(e) => searchOrg(e.target.value)}
                                    placeholder="Search for a service"
                                    style={{backgroundColor: "#f4f4f4"}}
                                    labelText=""
                                />
                            </SearchWrapper>
                            {!user.isViewOnly &&
                            <DownloadWrapper>
                              <Flex>
                                <CSVLink 
                                  data={displayedServicesDownload} 
                                  headers={headers} 
                                  filename={`my-organization-${new Date().toISOString().split("T")[0]}`}
                                  label="none"
                                >
                                  <DownloadButton><Download16 /></DownloadButton>
                                </CSVLink>
                              </Flex>
                            </DownloadWrapper>
                            }
                        </ToolbarInner>
                    </TableToolbarContent>
                </TableToolbar>
                <Table {...getTableProps()}>
                    <TableHead>
                        <TableRow>
                            {headers.map((header) => (
                                <TableHeader
                                    {...getHeaderProps({
                                        header
                                    })}
                                >
                                    {header.header}
                                </TableHeader>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                    {displayedServices
                        .reduce((uniqueRows, row) => {
                          const isDuplicate = uniqueRows.some(
                            (r) => r.serviceLocationId === row.serviceLocationId
                          );
                          if (!isDuplicate) {
                            uniqueRows.push(row);
                          }
                          return uniqueRows;
                        }, [])
                        .map((row, i) => {
                          return (
                        <StyledTableRow key={row.key} {...getRowProps({ row })}>
                        
                          <TableCell key={0}>{row.name}</TableCell>
                          <TableCell key={1}>{row.totalReferrals}</TableCell>
                          <TableCell key={2}>{row.complete}</TableCell>
                          <TableCell key={3}>{row.nps?.toFixed(2)}</TableCell>
                          <TableCell key={4}>{isNaN(row.completion) ? '--' : row.completion}</TableCell>
                      </StyledTableRow>
                        )}
                      )}
                    </TableBody>
                </Table>
            </StyledTableContainer>
            )}
        </DataTable>
        <FlexCenter>
            <PaginationNav
                page={currentPage}
                itemsShown={width < 900 ? 4 : 8}
                totalItems={displayedServices? Math.round(displayedServices.length / 20) : 0}
                onChange={(e) => setCurrentPage(e)}
            />
        </FlexCenter>
    </CardDiv>
    </>
    )}
    </>
  )
}

export const OrganizationStats211 = ({referrals, width, loadingOrg, allServices, orgStats}) => {
  const { apiService } = useContext(kit.ApiContext);
  const [currentPage, setCurrentPage] = useState(0);
  const [loading, setLoading] = useState(true);
  const [allServicesArray, setAllServicesArray] = useState([]);
  const [displayedServices, setDisplayedServices] = useState([]);

  useEffect(() => {
    setLoading(true);
    setAllServicesArray(orgStats)
    setDisplayedServices(orgStats)
    setLoading(false);
  }, [apiService, orgStats]);

  const getOverviewData = () => {
    let highestNPS = 0;
    let highestNPSService = '';
    let mostReferrals = 0;
    let mostReferralsService = '';
    let lowestCompletion = Infinity;
    let lowestCompletionService = '';
    if (allServicesArray) {
      allServicesArray.map((service) => {
        let nps = service.nps;
        if (nps > highestNPS) {
          highestNPS = service.nps;
          highestNPSService = service.name;
        }
        if (service.totalReferrals > mostReferrals) {
          mostReferrals = service.totalReferrals;
          mostReferralsService = service.name;
        }
        if (service.completion < lowestCompletion) {
          lowestCompletion = service.completion;
          lowestCompletionService = service.name;
        }
      })
      return [highestNPSService, mostReferralsService, lowestCompletionService]
    } else {
      return ['--', '--', '--']
    }
    
  }

  const searchOrg = (e) => {
    let items = [...allServicesArray];
    items = items.filter((item) => (
        item.name?.toLowerCase().includes(e.toLowerCase())
    ))
    setDisplayedServices(items);
  }
  return (<>{
    !loading && (
      <>
    <OrgOverviewCards 
      referrals={allServices}
      highestNPS={getOverviewData()[0]}
      mostReferrals={getOverviewData()[1]}
      lowestcompleted={getOverviewData()[2]}
    />
    <CardDiv>
        <DataTable
            rows={displayedServices}
            headers={headers}
            // isSortable
        >
            {({
                headers,
                rows,
                getRowProps,
                getTableProps,
                getHeaderProps,
            }) => (
            <StyledTableContainer>
                <TableToolbar style={{
                    height: width >= 900 ? "8rem" : "12rem", 
                    marginBottom: "1rem",
                    backgroundColor: "white",
                }}>
                    <TableToolbarContent style={{height: "100%"}}>
                        <ToolbarInner>
                            <SearchWrapper>
                                <p>Search</p>
                                <Search
                                    onChange={(e) => searchOrg(e.target.value)}
                                    placeholder="Search for a service"
                                    style={{backgroundColor: "#f4f4f4"}}
                                    labelText=""
                                />
                            </SearchWrapper>
                        </ToolbarInner>
                    </TableToolbarContent>
                </TableToolbar>
                <Table {...getTableProps()}>
                    <TableHead>
                        <TableRow>
                            {headers.map((header) => (
                                <TableHeader
                                    {...getHeaderProps({
                                        header
                                    })}
                                >
                                    {header.header}
                                </TableHeader>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                      {displayedServices.map((row,i) => {
                        return(
                        <StyledTableRow key={row.key} {...getRowProps({ row })}>
                        
                          <TableCell key={0}>{row.name}</TableCell>
                          <TableCell key={1}>{row.totalReferrals}</TableCell>
                          <TableCell key={2}>{row.complete}</TableCell>
                          <TableCell key={3}>{row.nps}</TableCell>
                          <TableCell key={4}>{isNaN(row.completion) ? '--' : row.completion}</TableCell>
                      </StyledTableRow>
                        )}
                      )}
                    </TableBody>
                </Table>
            </StyledTableContainer>
            )}
        </DataTable>
        <FlexCenter>
            <PaginationNav
                page={currentPage}
                itemsShown={width < 900 ? 4 : 8}
                totalItems={displayedServices? Math.round(displayedServices.length / 20) : 0}
                onChange={(e) => setCurrentPage(e)}
            />
        </FlexCenter>
    </CardDiv>
    </>
    )}
    </>
  )
}