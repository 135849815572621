import S from './badges.module.scss';

const capacityColorValues = {
  AVAILABLE: 'GREEN',
  ALMOST_AT_CAPACITY: 'YELLOW',
  NO_CAPACITY: 'RED'
};

const buttonColors = {
  AVAILABLE: '#45dc8a',
  ALMOST_AT_CAPACITY: '#ffd015',
  NO_CAPACITY: '#c83532'
};

const capacities = [
  {
    text: 'Available',
    capacityValue: capacityColorValues.AVAILABLE,
    color: buttonColors.AVAILABLE,
    value: capacityColorValues.AVAILABLE
  },
  {
    text: 'Almost At Capacity',
    capacityValue: capacityColorValues.ALMOST_AT_CAPACITY,
    color: buttonColors.ALMOST_AT_CAPACITY,
    value: capacityColorValues.ALMOST_AT_CAPACITY
  },
  {
    text: 'No Capacity',
    capacityValue: capacityColorValues.NO_CAPACITY,
    color: buttonColors.NO_CAPACITY,
    value: capacityColorValues.NO_CAPACITY
  }
];

export const CapacityBadge = ({ capacity }) => {
  const cap = capacities.find(c => c.capacityValue === capacity);
  return (
    <div className={S.pillcontainer}>
      <div className={S.pill} style={{ backgroundColor: cap.color }}>
        <div className={S.pilltext}>{cap.text}</div>
      </div>
    </div>
  );
};

export const CNResourceBadge = () => {
  return (
    <div className={S.pillcontainer}>
      <div className={S.pill}>
        <div className={S.pilltext}>360 CN Resource</div>
      </div>
    </div>
  );
};