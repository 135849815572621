import React, { useState, useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";
import kit from "ich-ui-kit";

import {
  Button,
  OverflowMenu,
  OverflowMenuItem,
  TableCell,
} from "carbon-components-react";

import {
  OverflowMenuHorizontal20,
} from "@carbon/icons-react";


const CreateReferralRow = ({ client, clientId, createReferral }) => {
  const [width, setWidth] = useState(window.innerWidth);
  const isMobile = 500;
  useEffect(() => {
    const handleResizeWindow = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleResizeWindow);
    return () => {
      window.removeEventListener("resize", handleResizeWindow);
    };
  }, []);

  let history = useHistory();

  const { apiService } = useContext(kit.ApiContext);

  return (
    <TableCell>
      {width > isMobile && (
        <Button
          kind="ghost"
          style={{ margin: ".5rem" }}
          onClick={() => createReferral(clientId)}
        >
          Provide Support
        </Button>
      )}
      {width < isMobile && (
        <OverflowMenu
          flipped={true}
          renderIcon={OverflowMenuHorizontal20}
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          {/* <OverflowMenuItem itemText="Take Assessment" /> */}
          <OverflowMenuItem
            style={{ color: "rgba(0,145,179,1)", backgroundColor: "#fff" }}
            itemText="Provide Support"
            onClick={() => createReferral(clientId)}
          />
          {/* <OverflowMenuItem
            style={{ color: "rgba(0,145,179,1)", backgroundColor: "#fff" }}
            itemText="Send Needs Request"
            onClick={() => createReferral(client.id)}
          /> */}
          {/* <Button style={{ margin: ".5rem" }}>TAKE_ASSESSMENT_TODO</Button>
        <Button style={{ margin: ".5rem" }}>CREATE_REFERRAL_TODO</Button> */}
        </OverflowMenu>
      )}
    </TableCell>
  );
};

export default CreateReferralRow;
