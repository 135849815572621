export default function recommendedResourceSearchConfig({ recommendedResourceIds = [] }) {
  return {
    bool: {
      filter: [
        {
          bool: {
            should: recommendedResourceIds?.map((id) => ({
                term: {
                  'keyServiceLocation': id
                }
            }))
          }
        }
      ]
    }
  };
}
