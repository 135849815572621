import styled from "styled-components";
import { Tile } from "carbon-components-react";
import { ArrowLeft32 } from "@carbon/icons-react";
import { Card } from "../../../../components/Card/card";

export const StyledTile = styled(Tile)`
  padding: 0.5rem 1rem;
  min-height: auto;
  background-color: #fff;
`;

export const SectionWrapper = styled.div`
  margin-bottom: 1rem;
`;

export const Timestamp = styled.p`
  color: gray;
  font-size: 0.75rem;
  margin-left: 1rem;
`;

export const NotesSubheader = styled.p`
  font-size: 0.75rem;
  color: gray;
  margin-bottom: 0.5rem;
`;

export const NoteText = styled.p`
  margin: 0.5rem 0;
`;

export const BorderedContainer = styled.div`
  width: 100%;
  border: 1px solid #8d8d8d;
  background-color: #fff;
`;

export const NotesInputContainer = styled.div`
  background-color: #fff;
  border-top: 1px solid #8d8d8d;
  display: flex;
  align-items: center;
  height: 2.75rem;
`;

export const FlexBaseline = styled.div`
  display: flex;
  align-items: baseline;
`;

export const NotesContainer = styled.div`
  display: flex;
  flex-direction: column-reverse;
  max-height: 15rem;
  min-height: 15rem;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
`;