import styled from "styled-components";
import React, { useState } from "react";
import { Button } from "carbon-components-react";
import * as S from "../checkin.styled";

const Start = ({ start, startAssessment, cmsData, clientInfo }) => {

  return (
    <S.Content>
      <S.Heading>{cmsData.heading}</S.Heading>
      <S.Text></S.Text>
      <TextWrapper style={{ width: "70%" }}>
        <h4>
          {clientInfo?.firstName &&
            `Complete check-in for 
              ${clientInfo.firstName}`}
        </h4>
      </TextWrapper>

      <Button
        data-id="check-in-start-btn"
        onMouseEnter={start}
        onFocus={start}
        onTouchEnd={(e) => {
          startAssessment();
          e.preventDefault();
        }}
        onClick={startAssessment}
      >
        {cmsData?.startButton || "Start"}
      </Button>
    </S.Content>
  );
};

export default Start;


const TextWrapper = styled.div`
  margin-top: 1rem;
  padding-bottom: 1rem;
  p {
    font-size: 18px;
    font-weight: 400;
  }
  h4 {
    font-weight: 500;
    line-height: 1.3;
  }
  @media screen and (min-height: 880px) and (min-width: 1600px) {
    p {
      font-weight: 400;
      font-size: 20px;
    }
  }
  @media screen and (max-width: 768px) {
    width: 100%;
    align-items: center;
    margin-left: 1.25rem;
    p {
      font-weight: 400;
      font-size: 14px;
    }
  }
`;