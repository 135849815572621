import { useState, useContext, useEffect } from "react";
import VariableInterface from "./steps/VariableInterface";
import * as S from "./Assessment.styled";
import kit from "ich-ui-kit";
import { useHistory } from "react-router";
import { ArrowLeft32 } from "@carbon/icons-react";

const Assessment = ({
  passClientInfo,
  cms,
  domain,
  setControllerIndex,
  setOverallIndex,
  setFeedbackIndex,
  feedbackIndex,
  employmentResponse,
  incomeResponse,
  transportationResponse,
  setEmploymentResponse,
  setIncomeResponse,
  setTransportationResponse
}) => {
  const { user, updateUser } = useContext(kit.UserContext);
  const { apiService } = useContext(kit.ApiContext);
  const submitData = async () => {
    let url = `/assessments/client/self`;
    try {
      const cleanedE = Object.entries(employmentResponse).map(([k, v]) => ({
        text: v.text,
        domain: "Employment",
        answer: v.answer === "skipped" ? 0 : v.answer
      }));
      const cleanedI = Object.entries(incomeResponse).map(([k, v]) => ({
        text: v.text,
        domain: "Income",
        answer: v.answer === "skipped" ? 0 : v.answer
      }));
      const cleanedT = Object.entries(transportationResponse).map(([k, v]) => ({
        text: v.text,
        domain: "Transportation",
        answer: v.answer === "skipped" ? 0 : v.answer
      }));
      let cleanedAnswers = cleanedE.concat(cleanedT, cleanedI);

      const data = await apiService.post(url, {
        patientid: passClientInfo.id,
        responses: cleanedAnswers,
        zipcode: passClientInfo.address?.postalCode || "14410"
      });
    } catch (err) {
      console.log(err);
    }
  };

  const history = useHistory();
  const [displayGoBack, setDisplayGoBack] = useState(true);
  const [disableButton, setDisableButton] = useState(true);
  const [allowContinue, setAllowContinue] = useState(true);

  const numQuestions = {
    Employment: 12,
    Income: 12,
    Transportation: 12
  };

  const [width, setWidth] = useState(window.innerWidth);
  useEffect(() => {
    const handleResizeWindow = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleResizeWindow);
    return () => {
      window.removeEventListener("resize", handleResizeWindow);
    };
  }, []);

  const feedbackIndexController = () => {
    if (feedbackIndex < numQuestions[domain] - 1) {
      setFeedbackIndex((index) => index + 1);
      setOverallIndex((index) => index + 1);
    } else {
      submitData();
      setControllerIndex((prev) => prev + 1);
      setOverallIndex((prev) => prev + 1);
    }
  };
  const backButtonController = () => {
    if (feedbackIndex > 0) {
      if (
        feedbackIndex === 2 &&
        employmentResponse.q1.answer === "Not Employed"
      ) {
        setFeedbackIndex((prev) => prev - 2);
        setOverallIndex((prev) => prev - 2);
      } else if (
        feedbackIndex === 5 &&
        employmentResponse.q1.answer === "Not Employed"
      ) {
        setFeedbackIndex((prev) => prev - 3);
        setOverallIndex((prev) => prev - 3);
      } else if (
        feedbackIndex === 8 &&
        transportationResponse.q1.answer === false
      ) {
        setFeedbackIndex((prev) => prev - 2);
        setOverallIndex((prev) => prev - 2);
      } else {
        setFeedbackIndex((prev) => prev - 1);
        setOverallIndex((prev) => prev - 1);
      }
    } else if (feedbackIndex == 0) {
      history.goBack(); // SIPD-4043 | When the index is 0, ensure returning to the previous page.
    } else {
      history.push("/360Dashboard/overview");
    }
  };

  return (
    <S.Wrapper>
      {/* <S.TextTitleContainer>Experience Survey: {providerName}</S.TextTitleContainer> */}
      {/* <S.ProgressBarWrapper>
        <S.StyledImg
          src={`../../../icons/progress-bar-survey-${feedbackIndex + 1}.svg`}
        />
      </S.ProgressBarWrapper> */}
      {displayGoBack && (
        <S.GoBackButton
          overflow="visible"
          onClick={() => {
            backButtonController();
          }}
        >
          <ArrowLeft32 />
        </S.GoBackButton>
      )}
      <S.InterfaceContainer>
        <VariableInterface
          cms={cms}
          width={width}
          index={feedbackIndex}
          domain={domain}
          setFeedbackIndex={setFeedbackIndex}
          setOverallIndex={setOverallIndex}
          employmentResponse={employmentResponse}
          incomeResponse={incomeResponse}
          transportationResponse={transportationResponse}
          setEmploymentResponse={setEmploymentResponse}
          setIncomeResponse={setIncomeResponse}
          setTransportationResponse={setTransportationResponse}
          setAllowContinue={setAllowContinue}
        />
      </S.InterfaceContainer>
      <S.ButtonWrapper>
        <S.StyledButton
          disabled={allowContinue && feedbackIndex < numQuestions[domain] - 1}
          kind="secondary"
          onClick={() => {
            setAllowContinue(true);
            feedbackIndexController();
          }}
        >
          {feedbackIndex < numQuestions[domain] - 1
            ? cms.btn_continue
            : cms.btn_complete}
          <S.StyledChevronIcon />
        </S.StyledButton>
        {feedbackIndex < numQuestions[domain] - 1 &&
          <S.StyledButton
            // disabled={allowContinue}
            kind='ghost'
            onClick={() => {
              feedbackIndexController();
            }}
          >
            Skip
          </S.StyledButton>}
      </S.ButtonWrapper>
    </S.Wrapper>
  );
};

export default Assessment;
