import { Tooltip } from "carbon-components-react";
import { CheckmarkOutline16 } from "@carbon/icons-react";
const TipLine = ({ message, valid }) => {
  return (
    <div style={{ display: "flex" }}>
      <CheckmarkOutline16
        style={
          !valid
            ? { marginRight: "0.5rem", fill: "green" }
            : { marginRight: "0.5rem" }
        }
      />
      <p>{message}</p>
    </div>
  );
};

export const PasswordTooltip = ({ errors, open, showIcon, align }) => {
  return (
    <>
      <Tooltip open={open} showIcon={showIcon} align={align || "end"}>
        <TipLine
          key={1}
          valid={errors.includes("count")}
          message={"10-31 characters"}
        />
        <TipLine
          key={2}
          valid={errors.includes("upper")}
          message={"An uppercase letter"}
        />
        <TipLine
          key={3}
          valid={errors.includes("lower")}
          message={"A lowercase letter"}
        />
        <TipLine
          key={4}
          valid={errors.includes("number")}
          message={"A Number"}
        />
        <TipLine
          key={5}
          valid={errors.includes("symbol")}
          message={`One valid symbol: #?!@$%^&*-`}
        />
        <TipLine
          key={6}
          valid={errors.includes("nospace")}
          message={`No spaces or other special chars`}
        />
      </Tooltip>
    </>
  );
};
