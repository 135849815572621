export default function navigationCenterSearchConfig() {
  return {
    bool: {
      filter: [
        {
          bool: {
            filter: [
              {
                term: {
                  'nameService.keyword': 'Neighborhood Navigation Center'
                }
              }
            ]
          }
        }
      ]
    }
  };
}
