import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

import {
  Button,
  OverflowMenu,
  OverflowMenuItem,
  TableCell,
} from "carbon-components-react";

import {
  OverflowMenuHorizontal20,
} from "@carbon/icons-react";


const ViewDashboardRow = ({ client, clientId, createReferral }) => {
  const [width, setWidth] = useState(window.innerWidth);
  const isMobile = 500;
  useEffect(() => {
    const handleResizeWindow = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleResizeWindow);
    return () => {
      window.removeEventListener("resize", handleResizeWindow);
    };
  }, []);

  let history = useHistory();

  return (
    <TableCell>
      {width > isMobile && (
        <Button
          kind="ghost"
          style={{ margin: ".5rem" }}
          onClick={() => {
            history.push({
              pathname: "/360dashboard",
              state: {
                client: clientId,
              },
            });
          }}
        >
          View Dashboard
        </Button>
      )}
      {width < isMobile && (
        <OverflowMenu
          flipped={true}
          renderIcon={OverflowMenuHorizontal20}
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <OverflowMenuItem
            style={{ color: "rgba(0,145,179,1)", backgroundColor: "#fff" }}
            itemText="View Dashboard"
            onClick={() => {
              history.push({
                pathname: "/360dashboard",
                state: {
                  client: clientId,
                },
              });
            }}
          />
        </OverflowMenu>
      )}
    </TableCell>
  );
};

export default ViewDashboardRow;
