import { useState, useEffect } from "react";
import { TextInput } from "carbon-components-react";
import styled from "styled-components";
import xhr from "xhr";
import "./styles.css";
import { shouldAutoComplete } from "../../utils";

const AutocompleteAddressInput = (props) => {
  const [results, setResults] = useState([]);
  const [inputVal, setInputVal] = useState("");
  const [valid, setValid] = useState(true);
  const [userLocation, setUserLocation] = useState(null);

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          setUserLocation({ latitude, longitude });
        },
        (error) => {
          console.error("Error getting user location:", error);
          setUserLocation(null);
        }
      );
    }
  }, []);
  // SIPD-3943 | display addressLine1 instead of entire address.
  const [addressLine1, setAddressLine1] = useState("");

  // mapbox search
  const autocompleteAndValidateAddress = (e) => {
    // Constants when making the request to mapbox api
    const endpoint = "https://api.mapbox.com";
    const geoEndpoint = "/geocoding/v5/";
    const source = "mapbox.places";
    const country = "country=US";
    const accessToken = process.env.REACT_APP_MAPBOX_GL_TOKEN;
    var text = e.target.value;
    const defaultLongitude = -77.5880526464834;
    const defaultLatitude = 43.159071484454635;
    const proximity = userLocation
    ? `&proximity=${userLocation.longitude},${userLocation.latitude}`
    : `&proximity=${defaultLongitude},${defaultLatitude}`;

    // Building the request uri
    var uri =
    `${endpoint}${geoEndpoint}${source}/${encodeURIComponent(text)}.json?${country}&access_token=${accessToken}${proximity}`;

    // Making/sending the request
    if (text.length >= 2) {
      xhr(
        {
          uri: uri,
          json: true,
        },
        function (err, res, body) {
          setResults(body.features);
        }
      );
    } else {
      setResults([]);
    }
  };

  const handleClick = (address) => {
    const addressCheck = address.split(" ");
    if (props.streetAddress === true) {
      if (!isNaN(addressCheck[0]) && isNaN(addressCheck[1])) {
        setInputVal(address);
        setResults([]);
        props.onChange(address);
        setValid(true);
      } else {
        setValid(false);
        setResults([]);
      }
    } else {
      setInputVal(address);
      setResults([]);
      props.onChange(address);
    }
    // SIPD-3943 | display addressLine1 instead of entire address.
    const firstPart = address.split(",")[0];
    setAddressLine1(firstPart);
  };

  const handleChange = (val) => {
    // SIPD-3943 | display addressLine1 instead of entire address.
    const firstPart = val.split(",")[0];
    setAddressLine1(firstPart);
    setInputVal(val);
    setResults([]);
  };

  const cleanAddress = (address) => {
    return address.replace(/,[^,]+$/, "");
  };

  return (
    <AutocompleteAddressWrapper>
      <StyledTextInput
        id={props.id}
        className={props.className}
        placeholder={props.placeholder}
        labelText={props.labelText}
        onKeyPress={props.onKeyPress}
        onKeyDown={autocompleteAndValidateAddress}
        value={addressLine1}
        onChange={(e) => {
          handleChange(e.target.value);
        }}
        onInput={props.onInput}
        style={({ borderBottomColor: valid ? "inherit" : "red"}, props.style)}
        autoComplete={shouldAutoComplete}
      />

      {!valid && (
        <ErrorMessage>Please enter a valid street address</ErrorMessage>
      )}

      {results.length > 0 && inputVal !== "" && (
        <DropdownList>
          {results.map((result) => (
            <DropdownListItem
              key={result.place_name}
              onClick={() => handleClick(cleanAddress(result.place_name))}
            >
              {cleanAddress(result.place_name)}
            </DropdownListItem>
          ))}
        </DropdownList>
      )}
    </AutocompleteAddressWrapper>
  );
};

export default AutocompleteAddressInput;

const AutocompleteAddressWrapper = styled.div`
  position: relative;
  width: 20rem;
`;
const DropdownList = styled.ul`
  position: absolute;
  top: calc(100% - 1rem);
  background-color: #fff;
  z-index: 4;
  padding: 0.5rem 0;
  min-width: 180px;
  width: 100%;
  border: 1px solid #000;
  max-height: 6.5rem;
  overflow-y: auto;
  @media screen and (max-width: 768px) {
    max-height: 10rem;
  }
`;

const DropdownListItem = styled.li`
  padding: 6px 1rem;
  :hover {
    cursor: pointer;
    background-color: #f4f4f4;
  }
`;

const ErrorMessage = styled.span`
  position: absolute;
  color: red;
`;

const StyledTextInput = styled(TextInput)`
  width: 20rem;
  margin: 0 0 1rem 0;
  .bx--label {
    margin-bottom: 0;
  }
`;
