import React, { useState, useEffect, useContext } from "react";
import kit from "ich-ui-kit";
import socket from "../socket";
import { Switch, Route, useLocation } from "react-router-dom";
import useQuery from "../hooks/useQuery";


export const OverviewContext = React.createContext([]);

export const OverviewProvider = ({ children }) => {
  let location = useLocation();
  const query = useQuery();
  let clientId;
  if (location.state) {
    clientId = location.state.client;
  } else {
    clientId = query.get("id");
  }
  
  const { user } = useContext(kit.UserContext);
  const [domains, setDomains] = useState(
    user?.domains ? [...user?.domains] : []
  );

  useEffect(() => {
    socket.emit("domains:subscribe", { id: clientId }, (data) => {
      updateDomains(data.domains);
    });
  }, []);

  const getLatestDomains = () => {
    // socket.emit("domains:latest", { id: user.id }, (data) => {
    //   setDomains(data.domains);
    // });
  };

  const updateDomains = (updatedDomains) => {
    // Preserves activity feed in user context as well as localstorage
    setDomains(updatedDomains);
    // updateUser({ ...user, domains: updatedDomains });
  };


  return (
    <OverviewContext.Provider value={{ domains, getLatestDomains }}>
      {children}
    </OverviewContext.Provider>
  );
}

export const OverviewConsumer = OverviewContext.Consumer;