import { AddAlt16, AddAlt24 } from '@carbon/icons-react';
import {
  Button,
  Dropdown,
  DropdownSkeleton,
  FilterableMultiSelect,
  InlineLoading,
  InlineNotification,
  Modal,
  OverflowMenu,
  OverflowMenuItem,
  SkeletonText,
  StructuredListBody,
  StructuredListCell,
  StructuredListHead,
  StructuredListRow,
  StructuredListWrapper,
  TextInput
} from 'carbon-components-react';
import React, { useContext, useEffect, useState } from 'react';
import {
  AccountButtonContainer,
  AccountRow,
  AccountTitle,
  AccountTitleContainer,
  AccountTitleRow,
  ButtonRow,
  ButtonWrapperCancel,
  ButtonWrapperSave,
  ButtonWrapperSmall,
  Card,
  Container,
  ModalContentContainer,
  NotificationsGrid,
  RemoveRowDiv,
  SingleColumn,
  SubtitleRow,
  SubtitleWrapper,
  TextAreaDiv,
  TitleRow,
  UserMgmtTextInputDiv,
  UserMgmtTextInputWrapper
} from '../../components';
import Services from './Services';

import kit from 'ich-ui-kit';
import styled from 'styled-components';
import { capitalizeFirstLetter, shouldAutoComplete, isValidName} from '../../../../utils';
const initialInputs = {
  'input-0': {
    fName: '',
    lName: '',
    email: '',
    phone: '',
    services: [],
    serviceLead: [],
    fNameError: '',
    lNameError: '',
    emailError: '',
    phoneError: ''
  }
};

const deactivateOrgText = `
By clicking "Deactivate" you are agreeing to remove access to MyWayfinder for all staff accounts under this organization.
Deactivating will remove this organization from all client care teams and you will need to be added back
should you choose to return. This will not erase any client health data, but your staff will no longer have access to it.
`;

const deactivateOrgSuccessText = `Thank you for trusting MyWayfinder! Your provider organization
has been deactivated. You will be redirected to login shortly.`;

const deactivateStaffText = `By clicking "Deactivate" you are agreeing to remove access
to MyWayfinder for this staff member.`;

const deactivateStaffSuccessText = `Account deactivated successfully.`;

// SIPD-2515 Display text for the "Resend Invite"/"Cancel Invite" confirmation box.
const resendInviteStaffText = `By selecting 'Resend Invite', you are confirming your intent to re-send the welcome email to this staff member at the email address: `;
const resendInviteStaffSuccessText = `E-mail has been resent to the user.`;
const cancelInviteStaffText = `By selecting 'Cancel Invite', you are agreeing to remove access to MyWayfinder for this staff member.`;
const cancelInviteStaffSuccessText = `User’s invite to create account has been canceled.`;


// TODO pull table into different component

const UserManagement = ({ orgId, scrolling, setScrolling, channel }) => {
  const { clearUser, user } = useContext(kit.UserContext);
  const { apiService } = useContext(kit.ApiContext);
  // Loading States
  const [loading, setLoading] = useState(true);
  const [inviteLoading, setInviteLoading] = useState(false);
  const [updateRoleLoading, setUpdateRoleLoading] = useState(false);
  // API States
  const [isSuccess, setIsSuccess] = useState(false);
  const [isError, setIsError] = useState(false);
  const [deactivateLoading, setDeactivateLoading] = useState(false);
  const [leadsLoading, setLeadsLoading] = useState(false);
  const [updateRoleSuccess, setUpdateRoleSuccess] = useState(false);
  const [updateRoleError, setUpdateRoleError] = useState('');
  // Component States
  const [isEditable, setIsEditable] = useState(false);
  const [staff, setStaff] = useState([]);
  const [services, setServices] = useState([]);
  const [serviceIdsForLeads, setServiceIdsForLeads] = useState('');
  const [currentLeads, setCurrentLeads] = useState([]);
  const [staffToDeactivate, setStaffToDeactivate] = useState(null);
  const [staffToAssign, setStaffToAssign] = useState(null);
  const [updateRoleId, setUpdateRoleId] = useState();
  const [deactivateOpen, setDeactivateOpen] = useState(false);
  const [deactivateModalText, setDeactivateModalText] =
    useState(deactivateOrgText);
  const [view, setView] = useState('UserTable');
  const [inputs, setInputs] = useState(initialInputs);
  const [accountType, setAccountType] = useState('Provider');
  const [selectedServices, setSelectedServices] = useState([]);

  // SIPD-2515 State variables for managing the Resend Invite/Cancel Invite (Deactivate) modal.
  const [resendInviteOpen, setResendInviteOpen] = useState(false);
  const [resendInviteLoading, setResendInviteLoading] = useState(false);
  const [staffToResendInvite, setStaffToResendInvite] = useState(null);
  const [resendInviteModalText, setResendInviteModalText] = useState(resendInviteStaffText);
  const [emailVerification, setEmailVerification] = useState("");
  const [cancelInviteOpen, setCancelInviteOpen] = useState(false);
  const [cancelInviteLoading, setCancelInviteLoading] = useState(false);
  const [staffToCancelInvite, setStaffToCancelInvite] = useState(null);
  const [cancelInviteModalText, setCancelInviteModalText] = useState(cancelInviteStaffText);
  // SIPD-2515 State variables for managing the Reactivate function.
  const [reactivateRoleId, setReactivateRoleId] = useState();
  const [reactivateRoleLoading, setReactivateRoleLoading] = useState(false);
  const [reactivateRoleSuccess, setReactivateRoleSuccess] = useState(false);

  const getProviderInfo = async () => {
    try {
      let providerInfo = await apiService.post(
        `/search/provider?org=${orgId}&userId=${user.id}`
      );
      if (providerInfo?.providers?.services) {
        let allServices = [];
        providerInfo?.providers?.services.map((serv) => {
          allServices.push({
            id: serv?.serviceAtLocationKey,
            label: serv.name,
            serviceId: serv?.serviceId,
            locationId: serv?.locationId
          });
        });
        setServices(allServices);
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    setLoading(true);
    fetchStaff();
    if (user && !user.is211Admin) {
      getProviderInfo();
    }
    setLoading(false);
  }, [apiService, orgId]);

  useEffect(() => {
    if (serviceIdsForLeads && serviceIdsForLeads.length > 0) {
      fetchServiceLeads(serviceIdsForLeads);
    }
  }, [serviceIdsForLeads]);

  const fetchServiceLeads = async (serviceIdsForLeads) => {
    try {
      if (user.id && services) {
        setLeadsLoading(true);
        let lineLeads = await apiService.get(
          `/account/service-line-leads?serviceLocationIds=${serviceIdsForLeads}` // TODO
        );
        if (lineLeads.serviceLineLeads && lineLeads.serviceLineLeads.length > 0) {
          const filteredForLeads = [];
          lineLeads = lineLeads.serviceLineLeads;
          lineLeads = lineLeads.map((obj) => {
            if (obj && obj.lead && obj.lead === 'true') {
              filteredForLeads.push(obj);
            }
          });
          setCurrentLeads(filteredForLeads);
        }
      } else {
        throw new Error('services are empty');
      }
      setLeadsLoading(false);
    } catch (err) {
      console.log(err);
    }
  };


  const fetchStaff = async (cb) => {
    try {
      let data;
      if (user.superAssumedRole?.orgId !== '') {
        data = await apiService.get(
          `/providerstaff?orgId=${
            user.superAssumedRole?.orgId || orgId
          }&acceptedOnly=false`
        );
      } else {
        data = await apiService.get(`/providerstaff?acceptedOnly=false`);
      }
      if (data.staff) {
        setStaff(data.staff);
        if (cb) {
          cb();
        }
      } else {
        setStaff([]);
      }
    } catch (err) {
      console.log(err);
      setIsError(true);
      if (cb) {
        cb();
      }
    }
    setTimeout(() => {
      setIsSuccess(false);
      if (isError) {
        setTimeout(() => {
          setIsError(false);
        }, 2000);
      }
    }, 2000);
  };

  const handleSubmit = async () => {
    if (isInputValid()) {
      setInviteLoading(true);
      try {
        setLoading(true);
        const data = await apiService.post(`/providerstaff/register`, {
          staffMembers: Object.values(inputs),
          orgId: orgId || user.superAssumedRole?.orgId
        });
        if (data.ok) {
          setIsSuccess(true);
          toggleEdit();
          fetchStaff();
        } else if (data.exists && data.exists.length > 0) {
          let temp = inputs;
          Object.keys(temp).forEach((x) => {
            data.exists.forEach((y) => {
              if (temp[x].email === y.email) {
                temp[x].emailError = 'User Exists';
              }
            });
          });
          setInputs(temp);
        }
      } catch (err) {
        console.log(err);
      }
      setInviteLoading(false);
      setLoading(false);
    }
  };

  const disabledLineLead = (serviceId) => {
    if (
      currentLeads.filter(function (e) {
        return e.servicelead === serviceId;
      }).length > 0
    ) {
      return true;
    } else {
      return false;
    }
  };

  const noMoreThanOne = (serviceId, isLead) => {
    const arr = Object.values(inputs);
    const uniqueVals = new Set(
      arr.map((v) => v.service === serviceId && v.lead === 'Yes')
    );

    if (uniqueVals.size < arr.length) {
      return true;
    } else {
      return false;
    }
  };

  const deactivateProviderOrg = async () => {
    try {
      setDeactivateLoading(true);
      let data;
      if (user.superAssumedRole?.orgId) {
        data = await apiService.post(
          `/providers/${user.superAssumedRole.leadId}/deactivate?id=${user.superAssumedRole?.orgId}`,
          {
            orgId: user.superAssumedRole?.orgId,
            sourceSystem: user.orgSource || '211'
          }
        );
      } else {
        data = await apiService.post(`/providers/${user.id}/deactivate`, {
          orgId: orgId,
          sourceSystem: user.orgSource || '211'
        });
      }
      if (data.ok) {
        setDeactivateModalText(deactivateOrgSuccessText);
        setDeactivateLoading(false);
        window.setTimeout(() => {
          clearUser();
          channel?.postMessage({ key: "logout" }); // Notify other tabs
          sessionStorage.removeItem("penn-provider");
          window.location.href = '/';
          localStorage.clear();
        }, 7000);
      } else {
        setDeactivateLoading(false);
      }
    } catch (err) {
      console.log(err);
      setDeactivateLoading(false);
    }
  };

  const deactivateStaffAccount = async () => {
    try {
      setDeactivateLoading(true);
      const data = await apiService.post(
        `/providerstaff/account/deactivate?&assumedId=${
          user.superAssumedRole?.leadId || ''
        }`,
        {
          userId: staffToDeactivate
        }
      );
      if (data.ok) {
        setDeactivateModalText(deactivateStaffSuccessText);
        setDeactivateLoading(false);
        window.setTimeout(async () => {
          await fetchStaff();
          closeModal();
        }, 3000);
      } else {
        setDeactivateLoading(false);
      }
    } catch (err) {
      console.log(err);
      setDeactivateLoading(false);
    }
  };

  // SIPD-2515 Function to reactivate a provider member.
  const reactivateStaffAccount = async (userId) => {
    try {
      setReactivateRoleLoading(true);
      setReactivateRoleId(userId);
      const data = await apiService.post(
        `/providerstaff/account/reactivate?&assumedId=${user.superAssumedRole?.leadId || ''}`,
        {
          userId: userId
        }
      );
      if (data.ok) {
        setReactivateRoleSuccess(true);
        await fetchStaff(() => {
          setReactivateRoleId(null);
          setReactivateRoleLoading(false);
        });
        setTimeout(() => setReactivateRoleSuccess(false), 2000);
      } else {
        setReactivateRoleLoading(false);
        setReactivateRoleId(null);
      }
    } catch (err) {
      console.log(err);
      setReactivateRoleLoading(false);
      setReactivateRoleId(null);
    }
  };

  // SIPD-2515 Function to resend the welcome email to a provider member.
  const resendInviteStaffAccount = async () => {
    try {
      setResendInviteLoading(true);
      const data = await apiService.post(
        `/providerstaff/account/resendInvite?assumedId=${user.superAssumedRole?.leadId || ""}`, {
          userId: staffToResendInvite
        }
      );
      if (data.ok) {
        setResendInviteModalText(resendInviteStaffSuccessText);
        setResendInviteLoading(false);
        window.setTimeout(async () => {
          await fetchStaff();
          closeModal();
        }, 3000);
      }else {
        setResendInviteLoading(false);
      }
    } catch (err) {
      console.log(err);
      setResendInviteLoading(false);
    }
  };

  // SIPD-2515 Function to cancel (deactivate) the account access of a provider member.
  const cancelInviteStaffAccount = async () => {
    try {
      setCancelInviteLoading(true);
      const data = await apiService.post(
        `/providerstaff/account/deactivate?&assumedId=${
          user.superAssumedRole?.leadId || ''
        }`, {
          userId: staffToCancelInvite
        }
      );
      if (data.ok) {
        setCancelInviteModalText(cancelInviteStaffSuccessText);
        setCancelInviteLoading(false);
        window.setTimeout(async () => {
          await fetchStaff();
          closeModal();
        }, 3000);
      }else {
        setCancelInviteLoading(false);
      }
    } catch (err) {
      console.log(err);
      setCancelInviteLoading(false);
    }
  };

  const demoteUserToStaff = async (userId) => {
    try {
      setUpdateRoleLoading(true);
      setUpdateRoleId(userId);
      const data = await apiService.post(
        `/providerstaff/unassign-admin?assumedId=${
          user.superAssumedRole?.leadId || ''
        }`,
        {
          userId: userId
        }
      );
      if (data.ok) {
        setUpdateRoleSuccess(true);
        await fetchStaff(() => {
          setUpdateRoleId(null);
          setUpdateRoleLoading(false);
        });
        setTimeout(() => setUpdateRoleSuccess(false), 2000);
      } else {
        setUpdateRoleLoading(false);
        setUpdateRoleId(null);
        if (data.message) {
          setUpdateRoleError(data.message);
        } else {
          setUpdateRoleError('Error updating user role');
        }
        setTimeout(() => setUpdateRoleError(''), 3000);
      }
    } catch (err) {
      console.log(err);
      setUpdateRoleLoading(false);
      setUpdateRoleId(null);
      setUpdateRoleError('Error updating user role');
      setTimeout(() => setUpdateRoleError(''), 3000);
    }
  };

  const promoteUserToAdmin = async (userId) => {
    try {
      setUpdateRoleLoading(true);
      setUpdateRoleId(userId);
      const data = await apiService.post(
        `/providerstaff/assign-admin?assumedId=${
          user.superAssumedRole?.leadId || ''
        }`,
        {
          userId: userId
        }
      );
      if (data.ok) {
        setUpdateRoleSuccess(true);
        await fetchStaff(() => {
          setUpdateRoleId(null);
          setUpdateRoleLoading(false);
        });
        setTimeout(() => setUpdateRoleSuccess(false), 2000);
      } else {
        setUpdateRoleLoading(false);
        setUpdateRoleId(null);

        if (data.message) {
          setUpdateRoleError(data.message);
        } else {
          setUpdateRoleError('Error updating user role');
        }
        setTimeout(() => setUpdateRoleError(''), 3000);
      }
    } catch (err) {
      console.log(err);
      setUpdateRoleLoading(false);
      setUpdateRoleId(null);
      setUpdateRoleError('Error updating user role');
      setTimeout(() => setUpdateRoleError(''), 3000);
    }
  };

  const toggleEdit = () => {
    setInputs(initialInputs);
    setIsEditable(!isEditable);
  };

  const appendInput = () => {
    let newInputObject = {
      [`input-${Math.random()}`]: {
        fName: '',
        lName: '',
        email: '',
        phone: '',
        services: [],
        serviceLead: [],
        fNameError: '',
        lNameError: '',
        emailError: '',
        phoneError: ''
      }
    };
    setInputs({ ...inputs, ...newInputObject });
  };

  const handleNameChange = (e, inputId) => {
    e.preventDefault();
    e.target.value = e.target.value.replace( / +/g, ' ' );
    if(e.target.value === ' ') {
      e.target.value = ''
    }

    setInputs((values) => ({
      ...values,
      [inputId]: { ...values[inputId], [e.target.name]: e.target.value}
    }));
  };

  const handleEmailChange = (e, inputId) => {
    e.preventDefault();
    setInputs((values) => ({
      ...values,
      [inputId]: { ...values[inputId], email: e.target.value.trim() }
    }));
  };

  const handlePhoneChange = (e, inputId) => {
    e.preventDefault();

    const x = e.target.value
      .replace(/\D/g, '')
      .match(/(\d{0,3})(\d{0,3})(\d{0,4})/);

    setInputs((values) => ({
      ...values,
      [inputId]: {
        ...values[inputId],
        phone: (e.target.value = !x[2]
          ? x[1]
          : x[1] + '-' + x[2] + (x[3] ? '-' + x[3] : ''))
      }
    }));
  };

  const removeInput = (inputId) => {
    let temp = Object.assign({}, inputs);
    delete temp[inputId];
    setInputs(temp);
  };

  const validateEmail = (e, inputId) => {
    e.target.value =  e.target.value.trim();
    const isValid = /\S+@\S+\.\S+/.test(inputs[inputId].email);
    if (isValid) {
      if (!!inputs[inputId].emailError) {
        setInputs((values) => ({
          ...values,
          [inputId]: {
            ...values[inputId],
            emailError: ''
          }
        }));
      }
    } else {
      setInputs((values) => ({
        ...values,
        [inputId]: {
          ...values[inputId],
          emailError: 'Please enter a valid email address'
        }
      }));
    }
  };

  const validatePhone = (e, inputId) => {
    const isValid =
      (!isNaN(inputs[inputId].phone) && inputs[inputId].phone.length === 10) ||
      inputs[inputId].phone === '';
    if (isValid) {
      if (!!inputs[inputId].phoneError) {
        setInputs((values) => ({
          ...values,
          [inputId]: {
            ...values[inputId],
            phoneError: ''
          }
        }));
      }
    } else {
      setInputs((values) => ({
        ...values,
        [inputId]: {
          ...values[inputId],
          phoneError: 'Please enter a valid phone number'
        }
      }));
    }
  };

  const validateFirstName = (e, inputId) => {
    e.target.value =  e.target.value.trim();
    const isValid = inputs[inputId]["fName"] !== "" && isValidName(inputs[inputId]["fName"]);

    if (isValid) {
      if (!!inputs[inputId].fNameError) {
        setInputs((values) => ({
          ...values,
          [inputId]: {
            ...values[inputId],
            fNameError: ''
          }
        }));
      }
    } else {
      setInputs((values) => ({
        ...values,
        [inputId]: {
          ...values[inputId],
          fNameError: 'Please enter a valid name'
        }
      }));
    }
  };

  const validateLastName = (e, inputId) => {
    e.target.value =  e.target.value.trim();
    const isValid = inputs[inputId]["lName"] !== "" && isValidName(inputs[inputId]["lName"]);

    if (isValid) {
      if (!!inputs[inputId].lNameError) {
        setInputs((values) => ({
          ...values,
          [inputId]: {
            ...values[inputId],
            lNameError: ''
          }
        }));
      }
    } else {
      setInputs((values) => ({
        ...values,
        [inputId]: {
          ...values[inputId],
          lNameError: 'Please enter a valid name'
        }
      }));
    }
  };

  const isInputValid = () => {
    let isValid = true;
    Object.keys(inputs).forEach((x) => {
      if (
        inputs[x].emailError !== '' ||
        inputs[x].fNameError !== '' ||
        inputs[x].lNameError !== '' ||
        inputs[x].phoneError !== ''
      ) {
        isValid = false;
      // } else if (inputs[x].fName === '' || inputs[x].lName === '') {
      } else if (inputs[x].fName === '') {
        setInputs((values) => ({
          ...values,
          [x]: {
            ...values[x],
            fNameError: 'Please enter a valid name'
          }
        }));
        isValid = false;
      } else if (inputs[x].lName === '') {
        setInputs((values) => ({
          ...values,
          [x]: {
            ...values[x],
            lNameError: 'Please enter a valid name'
          }
        }));
        isValid = false;
      } else if (inputs[x].email === '') {
        setInputs((values) => ({
          ...values,
          [x]: {
            ...values[x],
            emailError: 'Please enter a valid email address'
          }
        }));
        isValid = false;
      }
    });
    return isValid;
  };

  const openModal = (staffId) => {
    if (staffId) {
      setStaffToDeactivate(staffId);
      setDeactivateModalText(deactivateStaffText);
      setDeactivateOpen(true);
    } else {
      setDeactivateModalText(deactivateOrgText);
      setDeactivateOpen(true);
    }
  };

  const closeModal = () => {
    setStaffToDeactivate(null);
    setDeactivateOpen(false);
    setStaffToCancelInvite(null);
    setCancelInviteOpen(false);
    setStaffToResendInvite(null);
    setResendInviteOpen(false);
  };

  const handleRequestSubmit = async () => {
    if (staffToDeactivate) {
      await deactivateStaffAccount();
    } else {
      await deactivateProviderOrg();
    }
    setDeactivateOpen(false);
    setStaffToDeactivate(null);
  };

  // SIPD-2515 Helper function to open the Resend Invite modal with the provider member's ID.
  const openResendInviteModal = (staffId, staffEmail) => {
    setStaffToResendInvite(staffId);
    setResendInviteOpen(true);
    setEmailVerification(staffEmail);
    setResendInviteModalText(resendInviteStaffText + staffEmail + ".");
  };

  const handleResendInviteRequestSubmit = async () => {
    if(staffToResendInvite){
      await resendInviteStaffAccount();
    }
    setResendInviteLoading(true);
    setTimeout(() => {
      setStaffToResendInvite(null);
      setResendInviteOpen(false);
      setResendInviteLoading(false);
    }, 3000); // Wait 3 seconds before running the next actions
  };

  // SIPD-2515 Helper function to open the Cancel Invite (Deactivate) modal with the provider member's ID.
  const openCancelInviteModal = (staffId) => {
    setStaffToCancelInvite(staffId);
    setCancelInviteOpen(true);
    setCancelInviteLoading(false);
  };

  const handleCancelInviteRequestSubmit = async () => {
    if(staffToCancelInvite){
      await cancelInviteStaffAccount();
    } 
    setTimeout(() => {
      setStaffToCancelInvite(null);
      setCancelInviteOpen(false);
      setCancelInviteModalText(cancelInviteStaffText);
      
    }, 3000); // Wait 3 seconds before running the next actions
    setCancelInviteLoading(true);
  };

  const viewUserTable = () => {
    setView('UserTable');
    setStaffToAssign(null);
  };

  const viewServices = (id, name) => {
    setView('Services');
    setStaffToAssign({ id, name });
  };

  useEffect(() => {
    if (scrolling) {
      document.getElementById('notification-grid-container').click();
      setScrolling(false);
    }
  }, [scrolling]);

  return (
    <NotificationsGrid id="notification-grid-container">
      <Modal
        open={deactivateOpen}
        danger
        onRequestSubmit={handleRequestSubmit}
        onRequestClose={() => closeModal()}
        primaryButtonDisabled={deactivateLoading}
        modalHeading="Are you sure?"
        modalLabel=""
        primaryButtonText={deactivateLoading ? 'Deactivating...' : 'Deactivate'}
        secondaryButtonText="Cancel"
      >
        <ModalContentContainer>
          <p>{deactivateModalText}</p>
        </ModalContentContainer>
      </Modal>
      {/** SIPD-2515 Components for Resend Invite and Cancel Invite Popup Windows */}
      <Modal
        open={cancelInviteOpen}
        danger
        onRequestSubmit={handleCancelInviteRequestSubmit}
        onRequestClose={() => closeModal()}
        primaryButtonDisabled={cancelInviteLoading}
        modalHeading="Are you sure?"
        modalLabel=""
        primaryButtonText={cancelInviteLoading ? 'Canceling Invite...' : 'Cancel Invite'}
        secondaryButtonText="Cancel"
      >
        <ModalContentContainer>
          <p>{cancelInviteModalText}</p>
        </ModalContentContainer>
      </Modal>
      <Modal
        open={resendInviteOpen}
        onRequestSubmit={handleResendInviteRequestSubmit}
        onRequestClose={() => closeModal()}
        primaryButtonDisabled={resendInviteLoading}
        modalHeading="Resend user invite email"
        modalLabel=""
        primaryButtonText={resendInviteLoading ? 'Resending Invite...' : 'Resend Invite'}
        secondaryButtonText="Cancel"
      >
        <ModalContentContainer>
          <p>{resendInviteModalText}</p>
        </ModalContentContainer>
      </Modal>
      {/** SIPD-2515 Components for Resend Invite and Cancel Invite Popup Windows -- END */}
      <SingleColumn>
        <TitleRow>Provider User Set Up</TitleRow>
        {isSuccess && (
          <InlineNotification
            kind="success"
            title="User has been added successfully."
            style={{ position: 'absolute' }}
          />
        )}
        {isError && (
          <InlineNotification
            kind="error"
            title="There was an error retrieving your data."
            style={{ position: 'absolute' }}
          />
        )}
        {updateRoleSuccess && (
          <InlineNotification
            kind="success"
            title="User role updated successfully."
            style={{ position: 'absolute', top: '3rem', zIndex: 9999 }}
            onCloseButtonClick={() => setUpdateRoleSuccess(false)}
          />
        )}
        {reactivateRoleSuccess && (
          <InlineNotification
            kind="success"
            title="User reactivated successfully."
            style={{ position: 'absolute', top: '3rem', zIndex: 9999 }}
            onCloseButtonClick={() => setReactivateRoleSuccess(false)}
          />
        )}
        {updateRoleError && (
          <InlineNotification
            kind="error"
            title={updateRoleError}
            style={{ position: 'absolute', top: '3rem', zIndex: 9999 }}
            onCloseButtonClick={() => setUpdateRoleError('')}
          />
        )}
        {view === 'UserTable' ? (
          <>
            <SubtitleWrapper>
              <SubtitleRow>
                View, manage, or add users with access to your provider
                organization.
              </SubtitleRow>
              {!user.is211Admin && (
                <ButtonWrapperSmall>
                  <Button
                    kind="danger"
                    size="field"
                    onClick={() => openModal()}
                  >
                    Deactivate Provider Org Account
                  </Button>
                </ButtonWrapperSmall>
              )}
            </SubtitleWrapper>
            <Card moreWidth>
              <AccountTitleContainer>
                <AccountTitleRow>
                  <AccountTitle>Organization Users</AccountTitle>
                  {!user.isViewOnly && (
                    <Button
                      renderIcon={isEditable ? null : AddAlt16}
                      kind="ghost"
                      iconDescription="Invite User"
                      onClick={toggleEdit}
                    >
                      {isEditable ? 'Cancel' : 'Invite User'}
                    </Button>
                  )}
                </AccountTitleRow>
              </AccountTitleContainer>
              {isEditable ? (
                <Container style={{ overflowX: 'visible' }}>
                  {Object.keys(inputs).map((inputId, i) => (
                    <AccountRow
                      key={i}
                      style={{ borderBottom: '1px solid #e0e0e0' }}
                      id="asdf"
                    >
                      <TextAreaDiv>
                        {/* Name, Email, and Phone Input */}
                        <UserMgmtTextInputWrapper>
                          <UserMgmtTextInputDiv>
                            <TextInput
                              id={inputId + '-first-name'}
                              name="fName"
                              value={inputs[inputId].fName}
                              labelText="First Name *"
                              invalid={!!inputs[inputId].fNameError}
                              invalidText={inputs[inputId].fNameError}
                              onBlur={(e) => validateFirstName(e, inputId)}
                              onChange={(e) => handleNameChange(e, inputId)}
                              autoComplete={shouldAutoComplete}
                            />
                          </UserMgmtTextInputDiv>
                          {/* <UserMgmtTextInputDiv style={{width: "100%"}}> */}
                          <UserMgmtTextInputDiv>
                            <TextInput
                              id={inputId + '-last-name'}
                              name="lName"
                              value={inputs[inputId].lName}
                              labelText="Last Name *"
                              invalid={!!inputs[inputId].lNameError}
                              invalidText={inputs[inputId].lNameError}
                              onBlur={(e) => validateLastName(e, inputId)}
                              onChange={(e) => handleNameChange(e, inputId)}
                              autoComplete={shouldAutoComplete}
                            />
                          </UserMgmtTextInputDiv>
                          {/* <UserMgmtTextInputDiv style={{width: "100%"}}> */}
                          <UserMgmtTextInputDiv>
                            <TextInput
                              id={inputId + '-email'}
                              value={inputs[inputId].email.toLowerCase()}
                              labelText="Email *"
                              invalid={!!inputs[inputId].emailError}
                              invalidText={inputs[inputId].emailError}
                              onBlur={(e) => validateEmail(e, inputId)}
                              onChange={(e) => handleEmailChange(e, inputId)}
                              autoComplete={shouldAutoComplete}
                            />
                          </UserMgmtTextInputDiv>
                          <UserMgmtTextInputDiv>
                            <TextInput
                              id={inputId + '-phone'}
                              value={inputs[inputId].phone}
                              labelText="Phone"
                              onChange={(e) => handlePhoneChange(e, inputId)}
                              autoComplete={shouldAutoComplete}
                            />
                          </UserMgmtTextInputDiv>
                        </UserMgmtTextInputWrapper>

                        {/* Service, Service Line Lead, and Account Type Dropdowns */}
                        <UserMgmtTextInputWrapper>
                          <UserMgmtTextInputDiv>
                            {loading ? (
                              <DropdownSkeleton
                                style={{ marginTop: '1.5rem' }}
                              />
                            ) : (
                              <FilterableMultiSelect
                                items={services}
                                itemToString={(item) =>
                                  item ? item.label : ''
                                }
                                titleText="Assign to service(s)"
                                ariaLabel="Assign to service(s)"
                                placeholder="Assign to service(s)"
                                label={'Services'}
                                disabled={services.length === 0}
                                initialSelectedItems={[]}
                                id={inputId + '-services'}
                                onChange={(e) => {
                                  setInputs((values) => ({
                                    ...values,
                                    [inputId]: {
                                      ...values[inputId],
                                      services: e.selectedItems
                                    }
                                  }));
                                  const selectedServs = e.selectedItems.map(
                                    (m) => m.id
                                  );
                                  const selectedServLeads = inputs[
                                    inputId
                                  ].serviceLead.map((m) => m.id);
                                  const found = selectedServLeads.every((r) =>
                                    selectedServs.includes(r)
                                  );
                                  if (!found) {
                                    document
                                      ?.getElementById(inputId + '-service-lead')
                                      ?.querySelectorAll('.bx--tag__close-icon')
                                      ?.forEach((e) => e.click());
                                  }
                                }}
                                selectionFeedback="top-after-reopen"
                                useTitleInItem={true}
                              />
                            )}
                          </UserMgmtTextInputDiv>
                          <UserMgmtTextInputDiv>
                            {loading ? (
                              <DropdownSkeleton
                                style={{ marginTop: '1.5rem' }}
                              />
                            ) : (
                              <FilterableMultiSelect
                                id={inputId + '-service-lead'}
                                ariaLabel="Set as Service Lead"
                                placeholder="Set as Service Lead"
                                items={inputs[inputId].services}
                                disabled={
                                  inputs[inputId].services.length === 0 ||
                                  services.length === 0
                                }
                                itemToString={(item) =>
                                  item ? item.label : ''
                                }
                                titleText="Set as Service Lead"
                                label={'Service Lead'}
                                selectedItems={inputs[inputId].serviceLead}
                                initialSelectedItems={[]}
                                onChange={(e) => {
                                  setInputs((values) => ({
                                    ...values,
                                    [inputId]: {
                                      ...values[inputId],
                                      serviceLead: e.selectedItems
                                    }
                                  }));
                                }}
                                selectionFeedback="top-after-reopen"
                                useTitleInItem={true}
                              />
                            )}
                          </UserMgmtTextInputDiv>
                        </UserMgmtTextInputWrapper>
                      </TextAreaDiv>
                      {user.is211Admin && (
                        <Dropdown
                          style={{
                            marginTop: '0.5rem',
                            marginLeft: '0.5rem',
                            minWidth: '120px'
                          }}
                          aria-label="account-type-dropdown"
                          id={inputId + '-account-type'}
                          titleText="Account Type"
                          // This will become an option to create 211 Staff or 211 Admins - for now only giving the 211 staff option
                          // items={['Standard', '211']}
                          // initialSelectedItem='Standard'
                          disabled
                          items={['Standard', '211 Staff']}
                          initialSelectedItem="211 Staff"
                          onChange={(e) => {
                            setInputs((values) => ({
                              ...values,
                              [inputId]: {
                                ...values[inputId],
                                accountType: e.selectedItem
                              }
                            }));
                          }}
                        />
                      )}
                      {Object.keys(inputs).length > 1 && (
                        <RemoveRowDiv>
                          <Button
                            onClick={() => removeInput(inputId)}
                            kind="ghost"
                          >
                            Remove
                          </Button>
                        </RemoveRowDiv>
                      )}
                    </AccountRow>
                  ))}

                  <AccountRow>
                    <Button
                      kind="ghost"
                      renderIcon={AddAlt24}
                      iconDescription="Add a new User"
                      onClick={() => appendInput()}
                    >
                      Add Another User
                    </Button>
                  </AccountRow>
                  <AccountButtonContainer>
                    <ButtonRow>
                      <ButtonWrapperCancel>
                        <Button kind="cancel" size="field" onClick={toggleEdit}>
                          Cancel
                        </Button>
                      </ButtonWrapperCancel>
                      <ButtonWrapperSave>
                        <Button
                          kind="primary"
                          size="field"
                          onClick={handleSubmit}
                          disabled={inviteLoading}
                        >
                          {inviteLoading ? (
                            <InlineLoading
                              description="Inviting..."
                              style={{ minHeight: 'auto' }}
                            />
                          ) : (
                            'Invite'
                          )}
                        </Button>
                      </ButtonWrapperSave>
                    </ButtonRow>
                  </AccountButtonContainer>
                </Container>
              ) : (
                <Container>
                  <StructuredListWrapper
                    id="org-staff"
                    style={{
                      marginBottom: '1rem',
                      display:
                        staff.length === 0 && !loading ? 'block' : 'table'
                    }}
                  >
                    <StructuredListHead
                      head="true"
                      style={{
                        display:
                          staff.length === 0 && !loading
                            ? 'block'
                            : 'table-header-group'
                      }}
                    >
                      <StructuredListRow
                        style={{
                          display:
                            staff.length === 0 && !loading
                              ? 'flex'
                              : 'table-row',
                          width:
                            staff.length === 0 && !loading ? '100%' : 'auto',
                          justifyContent:
                            staff.length === 0 && !loading
                              ? 'space-around'
                              : 'auto'
                        }}
                      >
                        <StructuredListCell head={true}>
                          Name
                        </StructuredListCell>
                        <StructuredListCell head={true}>
                          Email
                        </StructuredListCell>
                        <StructuredListCell head={true}>
                          Role
                        </StructuredListCell>
                        <StructuredListCell head={true}>
                          Invite Status
                        </StructuredListCell>
                        <StructuredListCell head={true}>
                          Action
                        </StructuredListCell>
                      </StructuredListRow>
                    </StructuredListHead>
                    {loading ? (
                      <StructuredListBody>
                        <StructuredListRow>
                          <StructuredListCell>
                            <SkeletonText heading paragraph lineCount={6} />
                          </StructuredListCell>
                          <StructuredListCell>
                            <SkeletonText heading paragraph lineCount={6} />
                          </StructuredListCell>
                          <StructuredListCell>
                            <SkeletonText heading paragraph lineCount={6} />
                          </StructuredListCell>
                          <StructuredListCell>
                            <SkeletonText heading paragraph lineCount={6} />
                          </StructuredListCell>
                          <StructuredListCell>
                            <SkeletonText heading paragraph lineCount={6} />
                          </StructuredListCell>
                        </StructuredListRow>
                      </StructuredListBody>
                    ) : staff.length > 0 && !loading ? (
                      staff.map((member) => (
                        <StructuredListBody key={Math.random()}>
                          <StructuredListRow>
                            <StructuredListCell>
                              {member.firstName} {member.lastName}
                            </StructuredListCell>
                            <StructuredListCell>
                              {member.email}
                            </StructuredListCell>
                            <StructuredListCell>
                              {capitalizeFirstLetter(member.role) || 'Staff'}
                            </StructuredListCell>
                            <StructuredListCell>
                              {member.isDeactivated ? "Deactivated" : member.status}
                            </StructuredListCell>
                            <StructuredListCell>
                              {(updateRoleLoading &&
                              updateRoleId === member.userId) || (reactivateRoleLoading && reactivateRoleId === member.userId) ? (
                                <InlineLoading />
                              ) : user.isViewOnly ? (
                                <OverflowMenu flipped={true}>
                                  <StyledOverflowMenuItem
                                    itemText="View Service"
                                    onClick={() =>
                                      viewServices(
                                        member.userId,
                                        `${member.firstName} ${member.lastName}`
                                      )
                                    }
                                  />
                                </OverflowMenu>
                              ) : (
                                <OverflowMenu flipped={true}>
                                  {!user.is211Admin && (
                                    <OverflowMenuItem
                                      itemText="Edit Service"
                                      onClick={() =>
                                        viewServices(
                                          member.userId,
                                          `${member.firstName} ${member.lastName}`
                                        )
                                      }
                                    />
                                  )}
                                  {member.status?.toLowerCase() ===
                                  'accepted' ? (
                                    <>
                                      {member.isDeactivated ? (
                                        <StyledOverflowMenuItem
                                          itemText="Reactivate"
                                          onClick={() => reactivateStaffAccount(member.userId)}
                                        />
                                      ) : (
                                        <OverflowMenuItem
                                          itemText="Deactivate"
                                          onClick={() =>
                                            openModal(member.userId)
                                          }
                                        />
                                      )}
                                      {user.isAdmin &&
                                        (member.isAdmin ? (
                                          <StyledOverflowMenuItem
                                            disabled={user.id === member.userId}
                                            itemText="Make Staff"
                                            onClick={() =>
                                              demoteUserToStaff(member.userId)
                                            }
                                          />
                                        ) : (
                                          <OverflowMenuItem
                                            itemText="Make Admin"
                                            onClick={() =>
                                              promoteUserToAdmin(member.userId)
                                            }
                                          />
                                        ))}
                                    </>
                                  ) : null}
                                  {member.status?.toLowerCase() ===
                                    'invited' && (
                                    <>
                                      <StyledOverflowMenuItem
                                        itemText="Resend Invite"
                                        onClick={() => openResendInviteModal(member.userId, member.email)}
                                      />
                                      {
                                        member.isDeactivated ? (<></>) : (
                                          <StyledOverflowMenuItem
                                            itemText="Cancel Invite"
                                            onClick={() => openCancelInviteModal(member.userId)}
                                          />
                                        )
                                      }
                                      {user.isAdmin &&
                                        (member.isAdmin ? (
                                          <StyledOverflowMenuItem
                                            disabled={user.id === member.userId}
                                            itemText="Make Staff"
                                            onClick={() =>
                                              demoteUserToStaff(member.userId)
                                            }
                                          />
                                        ) : (
                                          <OverflowMenuItem
                                            itemText="Make Admin"
                                            onClick={() =>
                                              promoteUserToAdmin(member.userId)
                                            }
                                          />
                                        ))}
                                    </>
                                  )}
                                </OverflowMenu>
                              )}
                            </StructuredListCell>
                          </StructuredListRow>
                        </StructuredListBody>
                      ))
                    ) : (
                      <NoStaffMsgContainer>
                        <NoStaffMsg>
                          Click <strong>Invite User</strong> to add your first
                          organization user.
                        </NoStaffMsg>
                      </NoStaffMsgContainer>
                    )}
                  </StructuredListWrapper>
                </Container>
              )}
            </Card>
          </>
        ) : (
          <Services
            user={user}
            staffToAssign={staffToAssign}
            goBack={viewUserTable}
          />
        )}
      </SingleColumn>
    </NotificationsGrid>
  );
};

const NoStaffMsgContainer = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
`;

const StyledOverflowMenuItem = styled(OverflowMenuItem)`
  :disabled {
    color: #8d8d8d;
  }
`;

const NoStaffMsg = styled.span``;

export default UserManagement;
