import {
  Loading,
  Modal,
  RadioTile,
  RadioButtonGroup,
  RadioButton,
  StructuredListSkeleton,
  Table,
  TableHead,
  TableRow,
  TableSelectRow,
  TableHeader,
  TableBody,
  DataTable,
  TableCell,
  TableToolbar,
  TableToolbarContent,
  TableToolbarSearch,
  Button,
  TooltipIcon
} from "carbon-components-react";
import kit from "ich-ui-kit";
import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { Badges } from "../../../components/Badges/badges.jsx";
import InviteInput from "../../../components/Inputs/Invite";

const showFacilitatedEnrollment = process.env.REACT_APP_SHOW_FACILITATED_ENROLLMENT || true;

const SelectServiceModal = ({
  showModal,
  toggle,
  staffId,
  clientId,
  clientName,
  clientPhone,
  renderToast,
  setEnrollUser,
  selectedCareTeam,
  setSelectedCareTeam,
  setClientId,
  requestedMultipleClients,
  setRequestedMultipleClients
}) => {
  // Contexts
  const { apiService } = useContext(kit.ApiContext);
  const { user } = useContext(kit.UserContext);
  // States
  const [inputVal, setInputVal] = useState(clientPhone || "");
  const [inputType, setInputType] = useState("Phone");
  const [services, setServices] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedService, setSelectedService] = useState(null);
  const [step, setStep] = useState("");
  const [isPhoneError, setIsPhoneError] = useState(false);
  const [isEmailError, setIsEmailError] = useState(false);
  const [isPhoneNumberInvalid, setIsPhoneNumberInvalid] = useState(false);
  const [inviteType, setInviteType] = useState("invite");

  // useEffects
  useEffect(() => {
    if (!!selectedService) {
      setStep("select-service");
    } else if (!!clientId && !requestedMultipleClients) {
      setStep("found");
    } else if (!!requestedMultipleClients && requestedMultipleClients.length) {
      setStep("multiple-found");
    } else {
      setStep("invite");
    }
  }, [toggle, clientId]);

  useEffect(() => {
    const getProviderInfo = async () => {
      try {
        const providerServices = await apiService.get(
          `/providerstaff/services?userId=${
            user.superAssumedRole?.leadId || staffId
          }&assignedOnly=true`
        );
        if (providerServices.ok) {
          const uniqueArray = [...new Set(providerServices.services)];
          setServices(uniqueArray);
        }
      } catch (err) {
        console.log(err);
      }
    };

    if (!!staffId && !user.is211Admin) {
      getProviderInfo();
    }
  }, [staffId]);

  // Functions
  const inviteToJoin = async () => {
    if (inputVal) {
      setLoading(true);
      try {
        const data = await apiService.post(
          "/account/invite/provider/friends-and-family",
          {
            userId: user.id,
            userName: user?.aliasName || user.name || user.givenName,
            inputVal,
            inputType,
            orgId: user.orgId,
          }
        );
        if (data.ok) {
          toggle();
          renderToast();
          resetModal();
        } else if (data?.message === "Invalid Phone" || data?.message?.includes('not a valid phone number')) {
          setIsPhoneNumberInvalid(true);
        }
      } catch (err) {
        console.log(err);
      } finally {
        setLoading(false);
      }
    }
  };

  const handleRequest = async () => {
    if (step === "found" || step === "multiple-found") {
      setStep("select-service");
    } else if (step === "invite" && inviteType === "invite") {
      await inviteToJoin();
    } else if (step === "invite" && inviteType === "enroll") {
      setEnrollUser(true);
      toggle();
      resetModal();
    } else if (!!selectedService) {
      setLoading(true);
      try {
        const { ok } = await apiService.post(
          `/careteam/request?clientId=${clientId}&serviceAtLocationId=${
            selectedService?.serviceLocationId
          }&serviceName=${selectedService?.name}&orgId=${
            selectedService?.orgId
          }&assumedId=${user.superAssumedRole?.leadId || ""}`
        );
        if (ok) {
          toggle();
          setStep("");
          setSelectedService(null);
          renderToast();
          resetModal();
        }
      } catch (err) {
        console.log(err);
      }
      setLoading(false);
    }
  };

  // Helpers

  const resetInputs = () => {
    // Invite
    setIsEmailError(false);
    setIsPhoneError(false);
    setIsPhoneNumberInvalid(false);
    setInputVal("");
  };

  const resetModal = () => {
    setTimeout(() => {
      setStep("");
      setSelectedService(null);
      setInviteType("invite");
      setRequestedMultipleClients(null);
      setClientId('');
    }, 500);
    resetInputs();
  };

  const handleCancel = () => {
    setStep("");
    toggle();
    resetModal();
  };

  const isDisabled = () => {
    if (step === "found") {
      return false;
    } else if (step === "multiple-found" && clientId) {
      return false;
    } else if (step === "invite" && inviteType === "enroll") {
      if (!!staffId && !!selectedCareTeam && !loading) {
        return false;
      } else {
        return true;
      }
    } else if (
      step === "invite" &&
      !isPhoneError &&
      !isEmailError &&
      !loading
    ) {
      if (inputType === "Email" && inputVal !== "") return false;
      if (inputType === "Phone" && inputVal.length === 14) return false;
      return true;
    } else if (!!staffId && !!selectedService && !loading) {
      return false;
    } else {
      return true;
    }
  };

  const determinePrimaryText = () => {
    if (step === "found") {
      return "Yes";
    } else if (step === "invite" && !loading) {
      if (inviteType === 'invite') {
        return "Invite";
      } else {
        return "Enroll";
      }
    } else if (step === "invite" && loading) {
      return (
        <>
          <Loading withOverlay={false} small={true} description={"Loading"} />
          Inviting...
        </>
      );
    } else if (loading) {
      return (
        <>
          <Loading withOverlay={false} small={true} description={"Loading"} />
          Requesting...
        </>
      );
    } else if (step === "multiple-found") {
      return "Next"
    } else {
      return "Request";
    }
  };

  const determineSecondaryText = () => {
    if (step === "found") {
      return "No";
    }
    return "Cancel";
  };

  // Renderer
  const renderContent = (step) => {
    
    switch (step) {
      case "found":
        return (
          <p style={{ marginBottom: "1rem" }}>
            {clientName} was found, but you are not connected. Would you like to
            request this person add you to their Care Team?
          </p>
        );
      case "multiple-found":
        const headers = [
          {
            key: "firstName",
            header: "First Name",
            label: "First Name",
            isSortable: false,
          },
          {
            key: "lastName",
            header: "Last Name",
            label: "Last Name",
            isSortable: false,
          },
          {
            key: "nickname",
            header: "Preferred Name",
            label: "Preferred Name",
            isSortable: false,
          },
          {
            key: "email",
            header: "Email",
            label: "Email",
            isSortable: false,
          },
          {
            key: "phone",
            header: "Phone Number",
            label: "Phone Number",
            isSortable: false,
          },
          {
            key: "zipcode",
            header: "Zip Code",
            label: "Zip Code",
            isSortable: false,
          }
        ];

        return (
          <div>
            <p style={{ marginBottom: "1rem" }}>
              We found multiple clients named {clientName}, but you aren't connected. 
              Please choose the correct person below to send a Care Team request.
            </p>
            <div style={{marginBottom: '2rem', maxWidth: "calc(100vw - 2rem)"}}>
              <DataTable
                rows={requestedMultipleClients}
                headers={headers}
                isSortable
                radio
              >
                {({
                  rows,
                  headers,
                  getTableProps,
                  getHeaderProps,
                  getRowProps,
                  getSelectionProps,
                }) => (
                  <div>
                    <Table {...getTableProps()}>
                      <TableHead>
                        <TableRow>
                          <th scope="col" />
                          {headers.map((header) => (
                            <TableHeader
                            {...getHeaderProps({ header })}
                            isSortable={header.isSortable}
                            >
                              {header.header}
                            </TableHeader>
                          ))}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {rows.map((row, i)=> {
                          return(
                            <TableRow key={`${i}:${row.id}`} {...getRowProps({ row })} onChange={() => { 
                                setClientId(row.id)
                              }}
                            >
                              <TableSelectRow {...getSelectionProps({row})} />
                              {row.cells.map((cell)=> (
                                <TableCell key={Math.random()}>{cell.value || 'N/A'}</TableCell>
                              ))}
                            </TableRow>
                          )
                        })}
                      </TableBody>
                    </Table>
                  </div>
                )}
              </DataTable>
            </div>
          </div>
        )
      case "select-service":
        return (
          <div>
            <p style={{ marginBottom: "1rem" }}>Please select a service:</p>
            {services.length > 0 ? (
              services.map((serv) => (
                <RadioTile
                  key={serv?.serviceLocationId}
                  id={String(serv?.serviceLocationId)}
                  value={serv?.serviceLocationId}
                  checked={
                    serv?.serviceLocationId ===
                    selectedService?.serviceLocationId
                  }
                  onClick={() => {
                    setSelectedCareTeam(serv)
                    setSelectedService(serv)
                  }}
                >
                  {serv.name}
                  <Badges capacity={serv.capacity} />
                </RadioTile>
              ))
            ) : (
              <RadioTile key="0" id="default-tile" value="none">
                No services assigned, please contact your admin
              </RadioTile>
            )}
          </div>
        );
      case "invite":
        return (
          <div>
            <p style={{ marginBottom: "1rem" }}>
              This person is not in our system. You can invite them to join
              MyWayfinder, or continue with enrolling this new user.
            </p>
            {showFacilitatedEnrollment &&
            <RadioButtonGroup
              // legendText="Group label"
              aria-label="Invite-radio-button-group"
              name="radio-button-group"
              defaultSelected="invite"
              style={{height: '5rem'}}
            >
              <RadioButton
                labelText="Invite"
                aria-label="Invite"
                value="invite"
                id="invite"
                onClick={() => setInviteType("invite")}
              />
              <RadioButton
                labelText="Enroll user"
                aria-label="Enroll"
                value="enroll"
                id="enroll"
                onClick={() => setInviteType("enroll")}
              />
            </RadioButtonGroup>}
            {inviteType === "invite" ? (
              <InviteInput
                setInputType={setInputType}
                setInputVal={setInputVal}
                inputType={inputType}
                inputVal={inputVal}
                setIsEmailError={setIsEmailError}
                setIsPhoneError={setIsPhoneError}
                isEmailError={isEmailError}
                isPhoneError={isPhoneError}
                resetInputs={resetInputs}
                isPhoneNumberInvalid={isPhoneNumberInvalid}
                setIsPhoneNumberInvalid={setIsPhoneNumberInvalid}
                source={"modal"}
              />
            ) : (
              <div>
              <br />
             <p style={{ marginBottom: "1rem" }}>Please select a service to add to this person's Care Team:</p>
             {services.length > 0 ? (
               services.map((serv) => (
                 <RadioTile
                   key={serv?.serviceLocationId}
                   id={String(serv?.serviceLocationId)}
                   value={serv?.serviceLocationId}
                   checked={
                     serv?.serviceLocationId ===
                     selectedCareTeam?.serviceLocationId
                   }
                   onClick={() => setSelectedCareTeam(serv)}
                 >
                   {serv.name}
                   <Badges capacity={serv.capacity} />
                 </RadioTile>
               ))
             ) : (
               <RadioTile key="0" id="default-tile" value="none">
                 No services assigned, please contact your admin
               </RadioTile>
             )}
           </div>
            )}
          </div>
        );
      default:
        return;
    }
  };

  return (
    <StyledModal
      open={showModal}
      onRequestClose={handleCancel}
      onRequestSubmit={handleRequest}
      modalHeading={step === "found" || step === "multiple-found" ? "Care Team Request" : "Invite Request"}
      primaryButtonText={determinePrimaryText()}
      primaryButtonDisabled={isDisabled()}
      secondaryButtonText={determineSecondaryText()}
    >
      {renderContent(step)}
    </StyledModal>
  );
};

const StyledModal = styled(Modal)`
  .bx--modal-content {
    min-height: 180px;
  }
`;

export default SelectServiceModal;
