import React, { useState, useContext, useEffect } from "react";
import {
  Select,
  SelectItem,
  TextInput,
  Button,
  Checkbox,
  TextArea,
  Form
} from "carbon-components-react";
import { CallLogContext } from "../../../../../store/CallLogContext";
import * as options from "./options";
import * as S from "../callLogPanel.styled";
import { shouldAutoComplete } from "../../../../../utils";

// TODO does any linking to a referral need to happen? What is relationship b/w a call report and referral

/*
TODO - capture but not in UI
 Date
 Start Time
 End Time
 */

const IntakeTab = ({ setIndex }) => {
  const {
    values,
    setValues,
    handleChange,
    indvHasAccount,
    setIndvHasAccount,
    errors,
    isIntakeSubmitted,
    setIsIntakeSubmitted,
    clearForm,
    setClearForm
  } = useContext(CallLogContext);

  useEffect(() => {
    if(clearForm){
      const form = document.getElementById("call-log-form");
      form.reset();
      document.getElementById("describeProblem").value = ''; // one-off case that won't reset
      //document.getElementById("ageOptions").value = ''; // one-off case that won't reset
    }
    setClearForm(false);
  }, [clearForm]);

  useEffect(() => {
    setValues({
      ...values,
      lineDesignation: "211/LIFE LINE Phone",
      callType: "Transactional Call"
    });
  }, []);

  useEffect(() => {
    if (Object.keys(errors).length > 0) {
      setIsIntakeSubmitted(false);
    }
  }, [errors]);

  const handleButtonClick = (e) => {
    e.stopPropagation();
    setIsIntakeSubmitted(true);
    if (Object.keys(errors).length === 0) {
      setIndex(1);
    }
  };

  return (
    <Form id="call-log-form">
      <S.InputWrapper>
        <Select
          id="lineDesignation"
          defaultValue="211/LIFE LINE Phone"
          autocomplete="new-password"
          value={values.lineDesignation}
          labelText="Line Designation *"
          onChange={handleChange}
          required
          invalid={isIntakeSubmitted && errors.lineDesignation}
          invalidText={errors.lineDesignation}
        >
          {options.lineDesignation.map(({ text, value }, i) => (
            <SelectItem key={i} text={text.toString()} value={value} />
          ))}
          </Select>
        {/* <TextInput
          id="lineDesignation"
          labelText="Line Designation *"
          placeholder="MyWayFinder"
          value={values.lineDesignation}
          onChange={handleChange}
          required
          invalid={isIntakeSubmitted && errors.lineDesignation}
          invalidText={errors.lineDesignation}
        /> */}
        <Select
          id="referralSource"
          defaultValue=""
          autocomplete="new-password"
          value={values.referralSource}
          labelText="Referral Source *"
          onChange={handleChange}
          required
          invalid={isIntakeSubmitted && errors.referralSource}
          invalidText={errors.referralSource}
        >
          {options.referralSource.map(({ text, value }, i) => (
            <SelectItem key={i} text={text.toString()} value={value} />
          ))}
        </Select>
      </S.InputWrapper>
      <S.InputWrapper>
      {values?.referralSource && values?.referralSource === "Other" && (
          <TextInput
            id="referralSourceOther"
            labelText="Specify Referral Source *"
            placeholder=""
            autocomplete={shouldAutoComplete}
            value={values.referralSourceOther}
            onChange={handleChange}
            required
            invalid={isIntakeSubmitted && errors.referralSourceOther}
            invalidText={errors.referralSourceOther}
          />
        )}
      </S.InputWrapper>
      <S.InputWrapper>
        <Select
          id="inquiryType"
          defaultValue=""
          autocomplete="new-password"
          value={values.inquiryType}
          labelText="Inquiry Type *"
          onChange={handleChange}
          required
          invalid={isIntakeSubmitted && errors.inquiryType}
          invalidText={errors.inquiryType}
        >
          {options.inquiryTypeOptions.map(({ text, value }, i) => (
            <SelectItem key={i} text={text.toString()} value={value} />
          ))}
        </Select>
        <Select
          id="callType"
          defaultValue="Transactional Call"
          value={values.callType}
          labelText="Call Type *"
          autocomplete="new-password"
          onChange={handleChange}
          required
          invalid={isIntakeSubmitted && errors.callType}
          invalidText={errors.callType}
        >
          {options.callTypeOptions.map(({ text, value }, i) => (
            <SelectItem key={i} text={text.toString()} value={value} />
          ))}
        </Select>
      </S.InputWrapper>
      <S.InputWrapper>
      <Select
          id="ageOptions"
          defaultValue="Choose an option"
          value={values.ageOptions}
          labelText="Age *"
          autocomplete="new-password"
          onChange={handleChange}
          required
          invalid={isIntakeSubmitted && errors.ageOptions}
          invalidText={errors.ageOptions}
        >
          {options.ageOptions.map(({ text, value }, i) => (
            <SelectItem key={i} text={text.toString()} value={value} />
          ))}
        </Select>
        {/* <TextInput
          id="age"
          labelText="Age *"
          autocomplete="new-password"
          placeholder=""
          value={values.age}
          onChange={handleChange}
          required
          invalid={isIntakeSubmitted && errors.age}
          invalidText={errors.age}
        /> */}
        <Select
          id="gender"
          value={values.gender}
          labelText="Gender *"
          autocomplete="new-password"
          onChange={handleChange}
          required
          invalid={isIntakeSubmitted && errors.gender}
          invalidText={errors.gender}
        >
          {options.genderOptions.map(({ text, value }, i) => (
            <SelectItem key={i} text={text.toString()} value={value} />
          ))}
        </Select>
      </S.InputWrapper>
      <S.InputWrapper>
        <Select
          id="race"
          defaultValue=""
          autocomplete="new-password"
          value={values.race}
          labelText="Race/Ethnicity *"
          onChange={handleChange}
          required
          invalid={isIntakeSubmitted && errors.race}
          invalidText={errors.race}
        >
          {options.raceOptions.map(({ text, value }, i) => (
            <SelectItem key={i} text={text.toString()} value={value} />
          ))}
        </Select>
        <Select
          id="ethnicity"
          defaultValue=""
          autocomplete="new-password"
          value={values.ethnicity}
          labelText="Are you of Hispanic, Latino or Spanish Origin? *"
          onChange={handleChange}
          required
          invalid={isIntakeSubmitted && errors.ethnicity}
          invalidText={errors.ethnicity}
        >
          {options.hispanicOriginsOptions.map(({ text, value }, i) => (
            <SelectItem key={i} text={text.toString()} value={value} />
          ))}
        </Select>
      </S.InputWrapper>

      <S.InputWrapper>
        <Select
          id="callerType"
          defaultValue=""
          autocomplete="new-password"
          value={values.callerType}
          labelText="Caller Type *"
          onChange={handleChange}
          required
          invalid={isIntakeSubmitted && errors.callerType}
          invalidText={errors.callerType}
        >
          {options.callerTypeOptions.map(({ text, value }, i) => (
            <SelectItem key={i} text={text.toString()} value={value} />
          ))}
        </Select>
        <Select
          id="callingFor"
          defaultValue=""
          autocomplete="new-password"
          value={values.callingFor}
          labelText="Calling For *"
          onChange={handleChange}
          required
          invalid={isIntakeSubmitted && errors.callingFor}
          invalidText={errors.callingFor}
        >
          {options.callingForOptions.map(({ text, value }, i) => (
            <SelectItem key={i} text={text.toString()} value={value} />
          ))}
        </Select>
      </S.InputWrapper>

      <S.InputWrapper>
      <Select
          id="adultsInHousehold"
          defaultValue="Choose an option"
          autocomplete="new-password"
          value={values.adultsInHouse}
          labelText="# of Adults in Household *"
          onChange={handleChange}
          required
          invalid={isIntakeSubmitted && errors.adultsInHousehold}
          invalidText={errors.adultsInHousehold}
        >
          {options.adultsInHousehold.map(({ text, value }, i) => (
            <SelectItem key={i} text={text.toString()} value={value} />
          ))}
        </Select>
        <Select
          id="childrenInHousehold"
          defaultValue="Choose an option"
          autocomplete="new-password"
          value={values.childrenInHousehold}
          labelText="# of Children in Household *"
          onChange={handleChange}
          required
          invalid={isIntakeSubmitted && errors.childrenInHousehold}
          invalidText={errors.childrenInHousehold}
        >
          {options.childrenInHousehold.map(({ text, value }, i) => (
            <SelectItem key={i} text={text.toString()} value={value} />
          ))}
        </Select>
        {/* <TextInput
          id="adultsInHousehold"
          labelText="# of Adults in Household *"
          placeholder=""
          autocomplete="new-password"
          value={values.adultsInHousehold}
          onChange={handleChange}
          required
          invalid={isIntakeSubmitted && errors.adultsInHousehold}
          invalidText={errors.adultsInHousehold}
        />
        <TextInput
          id="childrenInHousehold"
          labelText="# of Children in Household *"
          placeholder=""
          autocomplete="new-password"
          value={values.childrenInHousehold}
          onChange={handleChange}
          required
          invalid={isIntakeSubmitted && errors.childrenInHousehold}
          invalidText={errors.childrenInHousehold}
        /> */}
      </S.InputWrapper>
      <S.InputWrapper>
        <Select
          id="expectingChild"
          defaultValue="Choose an option"
          autocomplete="new-password"
          value={values.expectingChild}
          labelText="Expecting Children/Pregnant *"
          onChange={handleChange}
          required
          invalid={isIntakeSubmitted && errors.expectingChild}
          invalidText={errors.expectingChild}
        >
          {options.expectingChild.map(({ text, value }, i) => (
            <SelectItem key={i} text={text.toString()} value={value} />
          ))}
        </Select>
      </S.InputWrapper>
      <S.InputWrapper>
        <Select
          id="language"
          defaultValue=""
          autocomplete="new-password"
          value={values.language}
          labelText="Caller Language *"
          onChange={handleChange}
          required
          invalid={isIntakeSubmitted && errors.language}
          invalidText={errors.language}
        >
          {options.languageOptions.map(({ text, value }, i) => (
            <SelectItem key={i} text={text.toString()} value={value} />
          ))}
        </Select>
        {values?.language && values?.language === "Other" && (
          <TextInput
            id="languageOther"
            labelText="Specify language *"
            placeholder=""
            autocomplete={shouldAutoComplete}
            value={values.languageOther}
            onChange={handleChange}
            required
            invalid={isIntakeSubmitted && errors.languageOther}
            invalidText={errors.languageOther}
          />
        )}
      </S.InputWrapper>
      <S.InputWrapper>
        <TextArea
          id="describeProblem"
          cols={25}
          maxLength={256}
          labelText="Describe problem"
          autocomplete="new-password"
          rows={2}
          value={values.describeProblem}
          onChange={handleChange}
        />
      </S.InputWrapper>

      <S.InputWrapper>
        <Select
          id="covid19"
          defaultValue=""
          value={values.covid19}
          labelText="COVID-19 *"
          onChange={handleChange}
          autocomplete="new-password"
          required
          invalid={isIntakeSubmitted && errors.covid19}
          invalidText={errors.covid19}
        >
          {options.covid19Options.map(({ text, value }, i) => (
            <SelectItem key={i} text={text.toString()} value={value} />
          ))}
        </Select>
      </S.InputWrapper>

      <S.InputWrapper>
        <Checkbox
          id="has-mwf-account-checkbox"
          labelText="Individual has a MWF account"
          checked={indvHasAccount}
          onChange={setIndvHasAccount}
        />
      </S.InputWrapper>
      <Button onClick={handleButtonClick}>Save & Continue</Button>
    </Form>
  );
};

export default IntakeTab;
