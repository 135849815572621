import React, { useEffect, useState } from "react";
import { useStrapi } from "../../../../../hooks/useStrapi";

export const genderItems = () => {
  const init = [
    {
      id: 71,
      show: "Select Gender",
      text: "Select Gender",
      key: "default_gender"
    },
    {
      id: 38,
      show: "Male",
      text: "Male",
      key: "male"
    },
    {
      id: 5,
      show: "Female",
      text: "Female",
      key: "female"
    },
    {
      id: 7,
      show: "Non-Binary",
      text: "Non-Binary",
      key: "non_binary"
    },
    {
      id: 8,
      show: "Trans Man",
      text: "Trans Man",
      key: "trans_man"
    },
    {
      id: 9,
      show: "Trans Woman",
      text: "Trans Woman",
      key: "trans_woman"
    },
    {
      id: 10,
      show: "Prefer not to Answer/ Data Not Collected",
      text: "Not answer",
      key: "not_answer"
    },
    {
      id: 3,
      text: "Prefer to Self-Identify",
      show: "Prefer to Self-Identify",
      key: "not_listed"
    }
  ];

  return init;
};
