import { CheckmarkFilled32 } from "@carbon/icons-react";
import {
  HeaderPanel,
  ProgressIndicator,
  Button
} from "carbon-components-react";
import styled from "styled-components";

// Parent
export const PanelTitle = styled.h3`
`;

export const PanelContainer = styled(HeaderPanel)`
  max-width: 100vw;
  width: 35rem;
  padding: 1rem;
  background-color: white;
  color: black;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  right: ${(props) => {
    if (props.theme.isOpen) {
      return "0;";
    }
    return "-35rem;";
  }};
  transition-timing-function: ${(props) => props.theme.motion};
  transition-duration: 240ms;
`;

export const StyledProgressIndicator = styled(ProgressIndicator)`
  margin-bottom: 1rem;
`;

export const CloseButton = styled(Button)`
  padding: 0.6rem;
  min-height: auto;
`;
// ***

// Common
export const InputWrapper = styled.div`
  display: flex;
  gap: 1rem;
  margin: 0.25rem 0;
  @media screen and (min-width: 900px) {
    margin: 0.5rem 0;
  }

  .bx--form-item {
    flex: 1 1;
  }
`;
// ***

// AccountTab
export const AccountContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const TextEmailInputWrapper = styled.div`
  margin: 3rem;
`;
// ***

// LocateTab
export const CardsContainer = styled.div`
  height: 78%;
  overflow: auto;
  margin-bottom: 1rem;
`;

export const TopRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  height: fit-content;
  padding-top: 8px;
`;

export const MiddleRow = styled.div`
  width: fit-content;
`;

export const BottomRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 0.7rem;
  margin-left: 1rem;
  flex-direction: column;
  @media screen and (max-width: 769px) {
    flex-direction: column;
  }
`;

export const ServiceNameWrapper = styled.div`
  display: flex;
  width: fit-content;
`;

export const ProviderText = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  margin-left: 1rem;
  max-width: 80%;

  h4 {
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 0.2rem;
  }
  h5 {
    font-size: 18px;
    font-weight: 500;
  }
`;

export const BadgeDiv = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-left: 0.5rem;
`;

export const DescriptionText = styled.div`
  display: flex;
  flex-direction: column;
  p {
    font-size: 16px;
    font-weight: 400;
  }
  @media screen and (max-width: 769px) {
    width: 90%;
  }
`;

export const AddressText = styled(DescriptionText)`
  width: fit-content;
  margin-top: 0.5rem;
  margin-bottom: 1rem;
  p {
    color: #7d7d7d;
  }
`;

export const Eligiblity = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
  margin-bottom: 1rem;
  color: rgba(0, 93, 131, 1);
  justify-content: center;
  p {
    margin-right: 0.5rem;
  }
  width: fit-content;
`;

export const EligiblityText = styled.div`
  display: flex;
  color: #161616;
`;

export const ViewEligibility = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 0.2rem;
  width: fit-content;
  color: #0f62fe;
`;

export const SuccessContainer = styled.div`
  margin-top: 3rem;
  margin-left: 1rem;
  display: flex;
  flex-direction: column;
`;

export const SuccessCheckmark = styled(CheckmarkFilled32)`
  margin-top: 1rem;
  color: rgba(114,169,78,1);
  height: 36px;
  width: 36px;
`;
// ***

// Util
export const Flex = styled.div`
  margin-bottom: 0.5rem;
  display: flex;
  flex-direction: ${(props) => props.row ? "row" : "column"};
  align-items: flex-start;
  justify-content:  ${(props) => props.spaceBetween ? "space-between" : "flex-start"};
  gap:  ${(props) => props.gap ? "1rem" : "0"};
`;

export const SelectedClient = styled.div`
  margin-top: 6px;
  display: flex;
  align-items: center;
  gap: 1rem;
  cursor: pointer;
  background-color: lightgray;
  padding: 0.3rem 0.6rem;
  border-radius: 3px;
`;
