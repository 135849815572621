import React, { useState, useEffect, useContext } from "react";

import { Switch, Route, useLocation } from "react-router-dom";
import { motion } from "@carbon/motion";
import { UserAvatar32, ChevronLeft32 } from "@carbon/icons-react";

import ReferralDetails from "../referral-details";
import * as S from "./components/static";
import { useDimension } from "../../hooks/useDimension";

import { NavContext } from "../../store/NavContext";
import { OverviewProvider } from "../../store/OverviewContext";

import { Menu } from "./components/menu";
import { Profile } from "./components/profile";

import kit from "ich-ui-kit";

//Sub Pages
import Demographic from "./subpages/demographic";
import CareTeam from "./subpages/care-team";
import ReferralsList from "../referrals-insert";
import CheckIn from "./subpages/check-in";
import Overview from "./subpages/overview";
import LongitudinalRecord from "./subpages/longitudinal-record/longitudinal-record";
import UnderConstruction from "../under-construction";
import Call211Details from "./subpages/call-log-details";
import NeedsRequestDetails from "./subpages/needs-request-details/index.jsx"
import CNRequestDetails from "./subpages/cn-request-details/index.jsx"

import useQuery from "../../hooks/useQuery";

const PatientDashboard = () => {
  const { user } = useContext(kit.UserContext);
  const { apiService } = useContext(kit.ApiContext);
  let location = useLocation();
  const [isOpen, setIsOpen] = useState(false);
  const [current, setCurrent] = useState("overview");
  const [client, setClient] = useState("");
  const [loading, setLoading] = useState(false);

  const { windowSize } = useDimension();
  const { mobileNavOpen, setMobileNavOpen } = useContext(NavContext);

  const query = useQuery();
  const clientIdFromURL = query.get("patientId");

  // Log when provider views patient dashboard
  useEffect(() => {
    const recordViewingDashboard = async () => {
      await apiService.post(`/eventhistory/provider/patient?assumedId=${user.superAssumedRole?.leadId || ""}`, {
        patientId: client
      });
    }
    if(client){
      recordViewingDashboard()
    }
  }, [client]);

  const setCurrentHandler = (path) => {
    const cleanPath = path.toLowerCase();
    switch (cleanPath) {
      case "/360dashboard/demographic-data":
        return "demographic data";
      case "/360dashboard/care-team":
        return "care team";
      case "/360dashboard/service-requests":
        return "service requests";
      case "/360dashboard/service-requests/view":
        return "service requests";
      case "/360dashboard/needs-request/view":
        return "service requests";
      case "/360dashboard/check-ins":
        return "check-ins";
      case "/360dashboard/longitudinal-record":
        return "event history";
      case "/360dashboard/messages":
        return "messages";
      default:
        return "overview";
    }
  };

  useEffect(() => {
    setCurrent(setCurrentHandler(location.pathname));
  }, [location]);

  useEffect(() => {
    const getClientRecord = async () => {
      setLoading(true);
      try {
        const clientId = location?.state?.client || location?.state?.clientID || clientIdFromURL;
        setClient(clientId);
      } catch (err) {
        console.log(err);
      }
      setLoading(false);
    };
    getClientRecord();
  }, [location.state]);

  useEffect(() => {
    if (windowSize.width >= 1024) {
      return;
    }
    setIsOpen(false);
  }, [location]);

  useEffect(() => {
    if (windowSize.width >= 1024) {
      setIsOpen(true);
      setMobileNavOpen(false);
      return;
    }
    setIsOpen(false);
  }, [windowSize]);

  useEffect(() => {
    if (mobileNavOpen === true && isOpen === true) {
      setIsOpen(false);
    }
  }, [mobileNavOpen]);

  return (
    <S.PageContainer>
      <S.SideNav
        theme={{ motion: motion("entrance", "productive"), isOpen: isOpen }}
      >
        <S.CloseBtn
          hasIconOnly
          renderIcon={ChevronLeft32}
          onClick={() => {
            setIsOpen((state) => !state);
          }}
        />
        <Profile client={client} isMobile={windowSize?.width < 500} />
        <Menu client={client} current={current} setCurrent={setCurrent} />
      </S.SideNav>
      <S.Overlay
        theme={{ motion: motion("entrance", "productive"), isOpen: isOpen }}
        onClick={() => {
          setIsOpen((state) => !state);
        }}
      ></S.Overlay>
      <S.Content>
        <S.NavBtn
          aria-label="Patient Navigation"
          onClick={() => {
            if (mobileNavOpen) {
              setMobileNavOpen(false);
            }
            setIsOpen((state) => !state);
          }}
          hasIconOnly
        >
          <UserAvatar32 style={{ width: "100%", height: "100%" }} />
        </S.NavBtn>

        <Switch>
          <Route path="/360dashboard/care-team" exact>
            <CareTeam clientID={client} />
          </Route>
          <Route path="/360dashboard/demographic-data" exact>
            <Demographic clientID={client} isMobile={windowSize?.width < 500} />
          </Route>
          <Route path="/360dashboard/service-requests" exact>
            <ReferralsList clientID={client} />
          </Route>
          <Route path="/360Dashboard/needs-request/view" exact>
            <NeedsRequestDetails clientID={client} />
          </Route>
          <Route path="/360Dashboard/cn-request/view" exact>
            <CNRequestDetails clientID={client} />
          </Route>
          <Route path="/360dashboard/service-requests/view" exact>
            <ReferralDetails clientID={client} />
          </Route>
          <Route path="/360dashboard/211-call-details/view" exact>
            <Call211Details />
          </Route>
          <Route path="/360dashboard/check-ins" exact>
            <CheckIn clientID={client} />
          </Route>
          <Route path="/360dashboard/longitudinal-record" exact>
            <LongitudinalRecord clientID={client} />
          </Route>
          <Route path={"/360dashboard/messages"} exact>
            <UnderConstruction />
          </Route>
          <OverviewProvider>
            <Route path="/360dashboard">
              <Overview clientID={client} />
            </Route>
          </OverviewProvider>
        </Switch>
      </S.Content>
    </S.PageContainer>
  );
};

export default PatientDashboard;
