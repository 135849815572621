import styled from "styled-components";
import { Accordion, AccordionItem } from 'carbon-components-react';

export const Container = styled.div`
  padding: 2rem 1rem;
  background-color: #ffffff;
  margin: 1rem;
  @media (min-width: 1280px) {
    width: 1000px;
    padding: 3rem;
    margin: 0 0 4rem 0;
  }
`;
export const Heading = styled.h1`
  font-size: clamp(1.5rem, 7vw, 2.25rem);
  font-weight: 500;
`;
export const Text = styled.div`
  margin-top: 1.75rem;
  font-size: clamp(1rem, 3vw, 1.25rem);
  
  ul, li {
    list-style-type: disc
  }
`;
// SIPD-4017 | This styled component customizes the Accordion & AccordionItem from Carbon Design System.
export const StyledAccordionItem = styled(AccordionItem)`
.bx--accordion__title {
  font-size: clamp(1.5rem, 7vw, 2.25rem);
  font-weight: 500;
  margin: 0;
}
.bx--accordion__heading {
  align-items: center;
}
`;
export const StyledAccordion = styled(Accordion)`
.bx--accordion__item {
  border: none;
}
.bx--accordion__content {
  padding-right: 0;
  padding-left: 0;
}
`;
