import React, { useContext, useState, useEffect } from "react";
import kit from "ich-ui-kit";
import * as S from "./profile.styled";
import { SkeletonProfile } from "./skeleton";
import { generateClickToCallLink } from '../../../../utils';

export const Profile = ({ client, isMobile }) => {
  const { apiService } = useContext(kit.ApiContext);
  const [loading, setLoading] = useState(true);
  const [picLoading, setPicLoading] = useState(true);
  const [clientInfo, setClientInfo] = useState({});
  const [image, setImage] = useState(null);
  const { user } = useContext(kit.UserContext);

  useEffect(() => {
    const getProfileImage = async () => {
      try {
        const data = await apiService.get(
          `/profileimages?id=${client}`
        );
        if (data.ok) {
          setImage(data.image);
        }
      } catch (e) {
        console.log(e);
      } finally {
        setPicLoading(false);
      }
    };

    const getClientRecord = async () => {
      try {
        // TODO should profile image be seperate call?
        const data = await apiService.get(`/clients/${client}?assumedId=${user.superAssumedRole?.leadId || ""}
        `);
        setClientInfo(data);
      } catch (err) {
        console.log(err);
      } finally {
        setLoading(false);
      }
    };
    if (client && Object.keys(clientInfo).length === 0) {
      getClientRecord();
      getProfileImage();
    }
  }, [apiService, client]);

  return (
    <>
      {loading || picLoading ? (
        <SkeletonProfile />
      ) : (
        <S.Container>
          <S.ProfilePicContainer>
            <S.ProfilePic
              alt="Profile Picture"
              src={image || "/blank.png"}
            />
          </S.ProfilePicContainer>
          <S.Heading>Name:</S.Heading>
          <S.FieldSet>
            <S.Text>
              {clientInfo.firstName} {clientInfo.lastName}
            </S.Text>
          </S.FieldSet>
          <S.Heading>Email:</S.Heading>
          <S.FieldSet>
            <S.Text>{clientInfo.email}</S.Text>
          </S.FieldSet>
          {clientInfo.phone && (
            <>
              <S.Heading>Phone:</S.Heading>
              <S.FieldSet>
                {generateClickToCallLink(clientInfo.phone) ? 
                  <S.Link href={generateClickToCallLink(clientInfo.phone)}>{clientInfo.phone}</S.Link>
                  :
                  <S.Text>{clientInfo.phone}</S.Text>
                }
              </S.FieldSet>
            </>
          )}
        </S.Container>
      )}
    </>
  );
};
