import { useEffect, useState } from "react";
import { useStrapi } from "../../hooks/useStrapi";

export const useLoginCMS = () => {
  const init = {
    // heading: "Login to MyWayfinder",
    // subheading_no_account: "No Account Yet?",
    // subtext_create_mywayfinder: "Create a MyWayfinder Account",
    // text_forgot: "Forgot Password?",
    // text_checking_email: "Checking Email",
    // text_sending: "Sending",
    // text_provider_portal: "Continue to Provider Portal",
    // text_verifying: "Verifying",
    // btn_send_code: "Send Access Code",
    // btn_resend_code: "Resend Access Code",
    // btn_continue: "Continue",
    // btn_login: "Login",
    // text_service_provider: "Are you a Service Provider?",
    // email: {
    //   id: 1,
    //   heading: "Enter your email",
    //   label: "Email",
    //   placeholder: "example@gmail.com",
    // },
    // password: {
    //   id: 3,
    //   heading: "Password",
    //   label: "Password",
    //   placeholder: "Password",
    // },
    // mfa_method: {
    //   id: 1,
    //   heading:
    //     "Select where you would like MyWayfinder to send your access code",
    //   label: "Select MFA Method",
    //   item: [
    //     {
    //       id: 1,
    //       text: "Send an Email",
    //       value: "email",
    //       key: null,
    //     },
    //     {
    //       id: 2,
    //       text: "Send a Text",
    //       value: "text",
    //       key: null,
    //     },
    //   ],
    // },
    // access_code: {
    //   id: 2,
    //   heading: "Access Code",
    //   label: "Access Code",
    //   placeholder: "Enter Access Code",
    // },
    // toast: {
    //   reset: {
    //     id: 1,
    //     title: "Reset Email",
    //     caption:
    //       "Please check your email for further instructions. Email may have been sent to Spam/Promotions folder.",
    //     subtitle: null,
    //   },
    //   code_sent: {
    //     id: 2,
    //     title: "Access Code Sent",
    //     caption:
    //       "Code sent to [email]. Please check your spam inbox for Access Code.",
    //     subtitle: "Bypassing MFA. Please enter any value.",
    //   },
    //   resend_code: {
    //     id: 3,
    //     title: "Access Code Resent",
    //     caption: null,
    //     subtitle: null,
    //   },
    //   error: {
    //     id: 4,
    //     title: "Error",
    //     caption: "The email or password that you entered is incorrect.",
    //     subtitle: null,
    //   },
    //   portal_error: {
    //     id: 5,
    //     title: "You are trying to login to the wrong portal",
    //     caption: null,
    //     subtitle: null,
    //   },
    //   success: {
    //     id: 6,
    //     title: "Account created",
    //     caption: "Please check your email for confirmation",
    //     subtitle: null,
    //   },
    // },
    // label_show_password: "Show password",
    // label_hide_password: "Hide password",
    // text_password_expired:
    //   "Password expired. Check your email for a link to reset your password.",
    // text_invalid_token: "Invalid Token!",
    // btn_submitting: "Submitting",
    // btn_submitted: "Submitted",
    // text_reset_link_expired: "Reset link has expired",
    // text_password_reset: "Password Reset",
    // text_redirected_login: "You will now be redirected to the Login page",
    // text_reset_password: "Reset Password",
    // text_password: "Password",
    // text_confirm_password: "Confirm Password",
    background_image: {small: {url:""}, medium: {url: ""}, large: {url: ""}}
  };

  const { data, loading } = useStrapi({
    cms: `page-login`
  });

  const [login, setLogin] = useState(init);
  useEffect(() => {
    if (loading) {
      setLogin(init);
      return;
    }
    setLogin((state) => ({
    //   heading: data.heading || state.heading,
    //   subheading_no_account:
    //     data.subheading_no_account || state.subheading_no_account,
    //   subtext_create_mywayfinder:
    //     data.subtext_create_mywayfinder || state.subtext_create_mywayfinder,
    //   text_forgot: data.text_forgot || state.text_forgot,
    //   text_checking_email:
    //     data.text_checking_email || state.text_checking_email,
    //   text_sending: data.text_sending || state.text_sending,
    //   text_provider_portal:
    //     data.text_provider_portal || state.text_provider_portal,
    //   text_verifying: data.text_verifying || state.text_verifying,
    //   btn_send_code: data.btn_send_code || state.btn_send_code,
    //   btn_resend_code: data.btn_resend_code || state.btn_resend_code,
    //   btn_continue: data.btn_continue || state.btn_continue,
    //   btn_login: data.btn_login || state.btn_login,
    //   email: data.email || state.email,
    //   password: data.password || state.password,
    //   access_code: data.access_code || state.access_code,
    //   mfa_method: data.mfa_method || state.mfa_method,
    //   text_service_provider:
    //     data.text_service_provider || state.text_service_provider,
    //   toast: {
    //     reset: data.toast_reset || state.toast.reset,
    //     code_sent: data.toast_sent || state.toast.code_sent,
    //     resend_code: data.toast_resend || state.toast.resend_code,
    //     error: data.toast_error || state.toast.error,
    //     portal_error: data.error_portal || state.toast.portal_error,
    //     success: data.toast_success || state.toast.success
    //   },
    //   label_show_password: data.label_show_password || state.label_show_password,
    //   label_hide_password: data.label_hide_password || state.label_hide_password,
    //   text_password_expired: data.text_password_expired || state.text_password_expired,
    //   text_invalid_token: data.text_invalid_token || state.text_invalid_token,
    //   btn_submitting: data.btn_submitting || state.btn_submitting,
    //   btn_submitted: data.btn_submitted || state.btn_submitted,
    //   text_reset_link_expired: data.text_reset_link_expired || state.text_reset_link_expired,
    //   text_password_reset: data.text_password_reset || state.text_password_reset,
    //   text_redirected_login: data.text_redirected_login || state.text_redirected_login,
    //   text_reset_password: data.text_reset_password || state.text_reset_password,
    //   text_password: data.text_password || state.text_password,
    //   text_confirm_password: data.text_confirm_password || state.text_confirm_password,
      background_image: data.images?.[0].background_image?.data?.attributes?.formats || state.background_image
    }));
  }, [data]);
  return { login };
};
