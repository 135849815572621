import React, { useState, useEffect, useContext } from "react";
import styled from "styled-components";
import { CloseOutline20 } from "@carbon/icons-react";
import ReactMapboxGL, {
  Layer,
  Feature,
  Image,
  ZoomControl,
  ScaleControl,
  Popup,
} from "react-mapbox-gl";
import mapboxgl from "!mapbox-gl"; // eslint-disable-line import/no-webpack-loader-syntax
import BusStopToggle from "../BusStopToggle";
import { SearchContext } from "../../pages/resources/context/SearchResultsContext.context";
import { BusStopsContext } from "../../store/BusStopsContext";

const Map = ReactMapboxGL({
  accessToken: process.env.REACT_APP_MAPBOX_GL_TOKEN,
});
// const createSearchResultsFeatures = (results) => {
//   const features = [];
//   try {
//     console.log(results);
//     if (Object.keys(results).length) {
//       results.forEach((result) => {
//         const feature = (
//           <Feature
//             key={result.name}
//             properties={{ servId: result.locationKey }}
//             coordinates={[
//               result.longitude || process.env.REACT_APP_MAP_CENTER_LONG,
//               result.latitude || process.env.REACT_APP_MAP_CENTER_LAT,
//             ]}
//             style={{ cursor: "pointer" }}
//             onMouseEnter={(e) => {
//               e.map.getCanvas().style.cursor = "pointer";
//             }}
//             onMouseLeave={(e) => {
//               e.map.getCanvas().style.cursor = "";
//             }}
//           />
//         );
//         features.push(feature);
//       });
//       console.log(features);
//       return features;
//     }
//   } catch (error) {}

//   return features;
// };

const LocationIcons = ({
  services,
  selectedProvider,
  selectProvider,
  setIconHovered,
}) => {
  const { iconHoverColor } = useContext(SearchContext);
  const [display, setDisplay] = useState(null);

  const select = (prov) => {
    selectProvider(prov);
    setIconHovered();
  };

  const openPopUp = (e, prov) => {
    e.map.getCanvas().style.cursor = "pointer";
    if (!selectedProvider) setIconHovered(prov);
  };

  const closePopUp = (e) => {
    e.map.getCanvas().style.cursor = "pointer";
    if (!selectedProvider) setIconHovered();
  };

  useEffect(() => {
    const createSearchResultsFeatures = (results) => {
      const features = [];
      try {
        if (Object.keys(results).length) {
          if (!selectedProvider) {
            results.forEach((result) => {
              if (result.loc_confidential_address === false && result.longitude && result.latitude) {
                const feature = (
                  <Feature
                    key={result.name}
                    properties={{ servId: result.locationKey }}
                    coordinates={[
                      result.longitude,
                      result.latitude,
                    ]}
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      select(result);
                    }}
                    onMouseEnter={(e) => {
                      openPopUp(e, result);
                    }}
                    onMouseLeave={(e) => {
                      closePopUp(e);
                    }}
                  />
                );
                features.push(feature);
              }
            })
          } else { 
            if (selectedProvider.loc_confidential_address === false && selectedProvider.longitude && selectedProvider.latitude) {
              features.push(
                <Feature
                  properties={{ servId: selectedProvider.serviceId }}
                  coordinates={[
                    selectedProvider.longitude,
                    selectedProvider.latitude,
                  ]}
                  style={{ cursor: "pointer" }}
                  onMouseEnter={(e) => {
                    openPopUp(e, null);
                  }}
                  onMouseLeave={(e) => {
                    closePopUp(e);
                  }}
                />
              );
            }
          }

          return features;
        }
      } catch (error) {}
      return features;
    };
    setDisplay(createSearchResultsFeatures(services));
  }, [services, selectedProvider]);
  return (
    <>
      <Image
        id={`Location-icon`}
        url={`./icons/Location-icon.png`}
        options={{ sdf: true }}
      />
      <Layer
        type="symbol"
        id={`Location-marker`}
        layout={{
          "icon-image": `Location-icon`,
          "icon-size": [
            "case",
            ["==", ["get", "servId"], iconHoverColor],
            1.4,
            0.9,
          ],
          "icon-allow-overlap": true,
        }}
        style={{ cursor: "pointer" }}
        paint={{
          "icon-color": [
            "case",
            ["==", ["get", "servId"], iconHoverColor],
            "red",
            "#005d83",
          ],
        }}
      >
        {display}
      </Layer>
    </>
  );
};

const BusStopsIcons = ({ busStops = [], setSelectedStop }) => {
  const [display, setDisplay] = useState();
  useEffect(() => {
    const createBusStopFeatures = () => {
      let features = [];
      busStops.forEach((stop, index) => {
        const feature = (
          <Feature
            key={index}
            coordinates={[stop.stop_lon, stop.stop_lat]}
            properties={{
              stopName: stop.stop_name,
              stopDesc: stop.stop_desc,
            }}
            onClick={() => {
              setSelectedStop(stop);
            }}
            onMouseEnter={(e) => {
              e.map.getCanvas().style.cursor = "pointer";
            }}
            onMouseLeave={(e) => {
              e.map.getCanvas().style.cursor = "";
            }}
          />
        );
        features.push(feature);
      });
      return features;
    };
    setDisplay(createBusStopFeatures());
  }, []);

  return (
    <>
      <Image id={`Bus-icon`} url={`./icons/bus-icon.png`} />
      <Layer
        type="symbol"
        id={`Bus-markers`}
        layout={{
          "icon-image": `Bus-icon`,
          "icon-size": 0.4,
          "icon-allow-overlap": false,
        }}
        style={{ cursor: "pointer" }}
      >
        {display}
      </Layer>
    </>
  );
};

const MapBox = ({ services }) => {
  const { busStops } = useContext(BusStopsContext);
  let LAT = process.env.REACT_APP_MAP_CENTER_LAT || 42.6703025;
  let LONG = process.env.REACT_APP_MAP_CENTER_LONG || -77.5919;
  if (services.length === 1) {
    LONG = services[0].longitude || LONG;
    LAT = services[0].latitude || LAT;
  }
  const initialMapState = {
    center: [LONG, LAT],
    zoom: [9],
  };
  const [mapState, setMapState] = useState(initialMapState);
  const [selectedStop, setSelectedStop] = useState();
  const [showStops, setShowStops] = useState(false);
  const [selectedProvider, setSelectedProvider] = useState(false);
  const [iconHovered, setIconHovered] = useState(null);
  const selectProvider = (provider) => {
    setSelectedProvider(provider);
    let long = provider.longitude || LONG;
    let lat = provider.latitude || LAT;
    setMapState({
      zoom: [14],
      center: [long, lat],
    });
  };
  const deselectProvider = () => {
    setSelectedProvider();
  };
  const handleBack = (e) => {
    e.stopPropagation();
    deselectProvider();
  };
  return (
    <Map
      style="mapbox://styles/mapbox/streets-v9"
      className="mapbox"
      containerStyle={{
        width: "100%",
        height: "35rem",
        marginTop: "0",
      }}
      center={mapState.center}
      zoom={mapState.zoom}
      onStyleLoad={(map) => {
        map.addControl(new mapboxgl.FullscreenControl());
      }}
    >
      <ZoomControl position="top-left" />
      <ScaleControl measurement="mi" />
      {showStops && <BusStopsIcons busStops={busStops} setSelectedStop={setSelectedStop} />}
      <LocationIcons
        services={services}
        selectedProvider={selectedProvider}
        setSelectedProvider={setSelectedProvider}
        selectProvider={selectProvider}
        iconHovered={iconHovered}
        setIconHovered={setIconHovered}
        // setMapState={setMapState}
      />
      {(selectedProvider || iconHovered) && (
        <StyledPopup
          coordinates={[
            selectedProvider
              ? selectedProvider.longitude
              : iconHovered
              ? iconHovered.longitude
              : null,
            selectedProvider
              ? selectedProvider.latitude
              : iconHovered
              ? iconHovered.latitude
              : null,
          ]}
          anchor="bottom"
          style={{ top: "-10px" }}
        >
          <PopUpContent>
            <PopUpTopRow>
              <PopUpTextWrapper>
                <h4>
                  {selectedProvider
                    ? selectedProvider.name
                    : iconHovered
                    ? iconHovered.name
                    : ""}
                </h4>
                <h5>
                  {selectedProvider
                    ? selectedProvider.orgName
                    : iconHovered
                    ? iconHovered.orgName
                    : ""}
                </h5>
              </PopUpTextWrapper>
              {selectedProvider && (
                <IconWrapper>
                  <CloseOutline20
                    style={{ cursor: "pointer" }}
                    onClick={(e) => handleBack(e)}
                  />
                </IconWrapper>
              )}
            </PopUpTopRow>
            <PopUpBottomRow>
              <PopUpAddressWrapper>
                <p>
                  {selectedProvider && (
                    <strong>
                      {selectedProvider.address
                        ? selectedProvider.address + ", "
                        : ""}
                      {selectedProvider.city
                        ? selectedProvider.city + ", "
                        : ""}
                      {selectedProvider.state
                        ? selectedProvider.state + ", "
                        : ""}
                      {selectedProvider.zip ? selectedProvider.zip : ""}
                    </strong>
                  )}
                  {iconHovered && (
                    <strong>
                      {iconHovered.address ? iconHovered.address + ", " : ""}
                      {iconHovered.city ? iconHovered.city + ", " : ""}
                      {iconHovered.state ? iconHovered.state + ", " : ""}
                      {iconHovered.zip ? iconHovered.zip : ""}
                    </strong>
                  )}
                </p>
              </PopUpAddressWrapper>
            </PopUpBottomRow>
          </PopUpContent>
        </StyledPopup>
      )}
      <ScaleControl measurement="mi" />
      <BusStopToggle value={showStops} setValue={setShowStops} />
      {showStops && selectedStop && (
        <StyledPopup
          coordinates={[selectedStop.stop_lon, selectedStop.stop_lat]}
        >
          <PopUpContent>
            <PopUpTopRow>
              <PopUpTextWrapper style={{ width: "100%" }}>
                <p>
                  <strong>Stop Information:</strong>
                  <br /> Name: {selectedStop.stop_name}
                  <br /> Location: {selectedStop.stop_desc}
                </p>
              </PopUpTextWrapper>
              <IconWrapper>
                <CloseOutline20
                  style={{ cursor: "pointer" }}
                  onClick={() => setSelectedStop()}
                />
              </IconWrapper>
            </PopUpTopRow>
          </PopUpContent>
        </StyledPopup>
      )}
    </Map>
  );
};

export default MapBox;

const PopUpContent = styled.div`
  display: flex;
  flex-direction: column;
`;
const PopUpTopRow = styled.div`
  display: flex;
  justify-content: space-between;
`;

const PopUpTextWrapper = styled.div`
  width: 90%;
  h4 {
    font-size: 18px;
    font-weight: 600;
  }
  h5 {
    font-size: 16px;
    font-weight: 500;
  }
`;
const IconWrapper = styled.div`
  width: fit-content;
  height: fit-content;
`;
const StyledPopup = styled(Popup)`
  max-width: 400px;
`;

const PopUpBottomRow = styled(PopUpTopRow)`
  display: flex;
  flex-direction: column;
  margin-top: 0.5rem;
`;
const PopUpAddressWrapper = styled.div`
  width: 80%;
  height: fit-content;
  /* height: 100%; */ /* align-self: center; */
  p {
    font-size: 16px;
    font-weight: 400;
    color: #7d7d7d;
  }
`;
