import React, { useState, useContext } from 'react';
import {
  ProviderInfoHeader,
  ProviderInfoHeaderContent,
  LeftColumn,
  InfoWrapper,
  StyledLocation16,
  ProviderInfoCard
} from '../../../../components/ServiceCard/static';
import styled from 'styled-components';
import { Button, Tag, InlineLoading } from 'carbon-components-react';
import kit from 'ich-ui-kit';
import { displayAddress } from '../../../../components/ServiceCard/utils';

const AssignServiceCard = ({ provider, user, staffToAssign }) => {
  const { apiService } = useContext(kit.ApiContext);
  const [updating, setUpdating] = useState(false);
  const [assigned, setAssigned] = useState(provider.assigned);

  const assignService = async () => {
    try {
      if (!!provider.serviceLocationId && assigned === false) {
        setUpdating(true);
        const resp = await apiService.post(
          `/providerstaff/service/assign?assumedId=${
            user.superAssumedRole?.leadId || ''
          }`,
          {
            userId: staffToAssign.id,
            serviceLocationId: provider.serviceLocationId
          }
        );
        if (resp.ok) {
          setAssigned(true);
        }
      } else {
        throw new Error('staffToAssign is empty');
      }
    } catch (err) {
      console.log(err);
    }
    setUpdating(false);
  };

  const unassignService = async () => {
    try {
      if (!!provider.serviceLocationId && assigned === true) {
        setUpdating(true);
        const resp = await apiService.post(
          `/providerstaff/service/unassign?assumedId=${
            user.superAssumedRole?.leadId || ''
          }`,
          {
            userId: staffToAssign.id,
            serviceLocationId: provider.serviceLocationId
          }
        );
        if (resp.ok) {
          setAssigned(false);
        }
      } else {
        throw new Error('staffToAssign is empty');
      }
    } catch (err) {
      console.log(err);
    }
    setUpdating(false);
  };

  const determineButtonText = () => {
    return assigned ? 'Un-Assign' : 'Assign';
  };

  const determineTagText = () => {
    return assigned ? 'Assigned' : 'Not Assigned';
  };

  const determineTagType = () => {
    return assigned ? 'green' : 'red';
  };

  const determineButtonOnClick = () => {
    return assigned ? unassignService() : assignService();
  };

  return (
    <>
      <ProviderInfoCard>
        <LeftColumn>
          <ProviderInfoHeader>
            <ProviderInfoHeaderContent>
              <h2>
                <strong>{provider.orgName}</strong>
              </h2>
              <h5>{provider.name}</h5>
              <InfoWrapper>
                <StyledLocation16 />
                <span>{displayAddress(provider)}</span>
              </InfoWrapper>
            </ProviderInfoHeaderContent>
          </ProviderInfoHeader>
        </LeftColumn>
        <RightColumn>
          <Tag
            style={{ width: '9rem', height: '3rem', marginRight: '8px' }}
            type={determineTagType()}
          >
            {determineTagText()}
          </Tag>
          {!user.isViewOnly && (
            <Button
              style={{ width: '10rem', height: '3rem' }}
              kind="tertiary"
              size={'sm'}
              onClick={determineButtonOnClick}
              disabled={updating}
            >
              {updating ? (
                <InlineLoading description="Updating" />
              ) : (
                determineButtonText()
              )}
            </Button>
          )}
        </RightColumn>
      </ProviderInfoCard>
    </>
  );
};

const RightColumn = styled.div`
  display: flex;
  width: 280px;
  flex-direction: row;
  height: 98%;
  @media (max-width: 900px) {
    width: auto;
    margin: auto;
    margin-top: 1rem;
  }
`;

export default AssignServiceCard;
