import S from "./registration-form.module.scss";
import {
  Button,
  Checkbox,
  ComboBox,
  DatePicker,
  DatePickerInput,
  Modal,
  MultiSelect,
  Select,
  SelectItem,
  TextInput,
} from "carbon-components-react";
import { useContext, useEffect, useState } from "react";

import { Asterisk16 } from "@carbon/icons-react";

import { useForm } from "react-hook-form";
import { registrationValidations as validations, RequiredLabelWrapper } from './registrationValidations'
import PhoneInput from "../form/PhoneInput";

import kit from "ich-ui-kit";

import ButtonLoading from "../interactions/buttonLoading";
import useModal from "../../hooks/useModal";
import { useHistory } from "react-router-dom";
import { shouldAutoComplete } from "../../utils";

const RegistrationForm = ({
  header,
  selectedCareTeam,
  setEnrollUser,
  requestClientDOB,
  requestedClientFirst,
  requestedClientLast,
  requestClientPhone,
}) => {
  const { user } = useContext(kit.UserContext);
  const { apiService } = useContext(kit.ApiContext);

  // State
  const [loading, setLoading] = useState(false);
  
  // Can use for customized error messages from validation functions
  const [errorMessages, setErrorMessages] = useState({
    firstName: "",
    lastName: "",
    phone: "",
    dateOfBirth: "Enter a valid date in format MM/DD/YYYY.",
  });

  const [isConsentCaptured, setIsConsentCaptured] = useState(false);
  const [modalContent, setModalContent] = useState("");
  const [showModal, toggle] = useModal();
  const history = useHistory();

  // Form items
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
    getValues,
    setValue,
    values,
  } = useForm();
  const phone = register("phone");

  useEffect(() => {
    if (requestedClientFirst) {
      setValue("firstName", requestedClientFirst);
    }
    if (requestedClientLast) {
      setValue("lastName", requestedClientLast);
    }
    if (requestClientDOB) {
      setValue("dateOfBirth", requestClientDOB);
    }
    if (requestClientPhone) {
      setValue("phone", requestClientPhone);
    }
  }, [
    requestClientDOB,
    requestedClientFirst,
    requestedClientLast,
    requestClientPhone,
    setValue,
    values,
  ]);

  const onSubmit = async (data) => {
    setLoading(true);

    for (const prop in data) {
      if (data.hasOwnProperty(prop) && data[prop] === "placeholder-item") {
        data[prop] = "";
      }
    }

    if(!data.phone) {
      data.phone = "";
      data.phoneType = "";
    }

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_ENDPOINT}/account/client`,
        {
          method: "post",
          headers: {
            Authorization: `Bearer ${user.accessToken} ${user.idToken}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            ...data,
            serviceId: selectedCareTeam.serviceId,
            locationId: selectedCareTeam.locationId,
            orgId: selectedCareTeam.orgId,
            orgSource: selectedCareTeam.orgSource,
            domain: selectedCareTeam.domains ? selectedCareTeam.domains[0] : "",
            createdFrom: `provider-enrollment-${selectedCareTeam.orgId}-${selectedCareTeam.orgSource}`,
          }),
        }
      );
      const { client } = await response.json();
      if (response.status === 404 || response.status === 409) {
        setLoading(false);
        setModalContent(`An account already exists for email: ${data.email}`);
        toggle();
      } else
      if (response.status !== 200) {
        setLoading(false);
        setModalContent(`Failed to create client. Please check if information you entered is correct.`);
        toggle();
      } else {
        setLoading(false);
        setEnrollUser(false);
        history.push({
          pathname: `/client-enrollment/${client.id}/complete-profile/`,
          state: {
            client: client.id,
            clientFirstName: client.firstName,
            clientLastName: client.lastName
          },
        });
        reset();
      }
    } catch(err) {
      console.log(err)
    } finally {
      setLoading(false);
    }
  };

  function onConsentCaptured() {
    setIsConsentCaptured(!isConsentCaptured);
    document
      .getElementById("registration-form")
      .scrollIntoView({ behavior: "smooth" });
    const values = getValues(); // { test: "test-input", test1: "test1-input" }
    const singleValue = getValues("firstName");
    console.log(values, singleValue);
    // will scroll to 4th h3 element
  }

  return (
    <div className={S.container}>
      <Modal
        open={showModal}
        modalHeading="Client Enrollment"
        size="sm"
        passiveModal={true}
        onRequestClose={toggle}
      >
        {modalContent}
      </Modal>
      <div className={S.header}>{header}</div>
      <br />
      <hr />
      <br />
      <div className={S.header}>Account Guidelines</div>
      <p>
        Joining MyWayfinder lets you find and connect with service providers who
        can help you improve your wellbeing. If you join, you’ll get your own
        MyWayfinder Dashboard to keep track of how you are doing and make it
        easier to connect with your service providers.
      </p>
      <br />
      <p>
        First, you will need to provide a little information about yourself.
      </p>
      <br />
      <p>
        Next, you can tell MyWayfinder how you are doing in different areas of
        your life. You control how much or how little information you share. The
        information you share will be used to recommend services for you.
      </p>
      <br />
      <p>
        Your MyWayfinder Dashboard is accessible only by you and anyone that you
        choose to share it with. You can share your information with
        organizations and individuals that provide services by adding them to
        your MyWayfinder Care Team.
      </p>
      <br />
      <div className={S.header}>About Consent</div>
      <p>
        You can share your MyWayfinder Dashboard with service providers by
        adding them to your Care Team.
      </p>
      <br />
      <p>
        Giving them access to your Dashboard helps your Care Team know more
        about you so they can serve you better. They will see your information
        such as the other service providers you are working with and service
        requests that are being made on your behalf.
      </p>
      <br />
      <p>
        You can add or remove service providers from your Care Team at any time
        for any reason.
      </p>
      <br />
      <p>
        Access to your information is limited to only those individuals who
        provide the service listed. If you want to connect with other service
        providers at the same organization, you’ll need to invite them to your
        Care Team as well.
      </p>
      <br />
      <p>
        Check the box if you agree to add the service provider below to your
        Care Team and give them permission to view your MyWayfinder Dashboard.
      </p>
      <br />
      <Checkbox
        data-id={`create-referral-consent-checkbox`}
        id="referral-checkbox"
        labelText={`I agree to add the above Care Provider to my Care Team. I understand that the information on my Dashboard will be shared with the Care Provider's registered users`}
        checked={isConsentCaptured}
        onClick={onConsentCaptured}
      />
      <br />
      <hr />
      <br />
      <form id="registration-form" onSubmit={handleSubmit(onSubmit)}>
        <div className={S.grid}>
          <TextInput
            id="register-first"
            placeholder="First name"
            labelText={<RequiredLabelWrapper labelText="First name "/>}
            defaultValue={requestedClientFirst}
            disabled={!isConsentCaptured}
            invalid={errors.firstName}
            invalidText={errors.firstName?.message || errorMessages.firstName}
            {...register("firstName", validations.firstName(),
            )}
            autoComplete={shouldAutoComplete}
          />
          <TextInput
            id="register-last"
            placeholder="Last name"
            labelText={<RequiredLabelWrapper labelText="Last name "/>}
            defaultValue={requestedClientLast}
            disabled={!isConsentCaptured}
            invalid={errors.lastName}
            invalidText={errors.lastName?.message}
            {...register("lastName", validations.lastName(),
            )}
            autoComplete={shouldAutoComplete}
          />

          <TextInput
            id="register-dob"
            disabled={!isConsentCaptured}
            placeholder="MM/DD/YYYY"
            labelText={<RequiredLabelWrapper labelText="Date of birth "/>}
            defaultValue={requestClientDOB}
            invalid={errors.dateOfBirth}
            // Error messages either from mismatching regex pattern or validateDateOfBirth method
            invalidText={
              errors.dateOfBirth?.message || errorMessages.dateOfBirth
            }
            size="md"
            className={S.textInput}
            {...register("dateOfBirth", validations.dateOfBirth(
                errorMessages,
                setErrorMessages
              ),
            )}
            autoComplete={shouldAutoComplete}
          />
        </div>
        <div>
          <div className={S.grid}>
            <TextInput
              id="register-email"
              placeholder="example@example.com"
              labelText={<RequiredLabelWrapper labelText="Email address "/>}
              invalid={errors.email}
              invalidText={errors.email?.message}
              disabled={!isConsentCaptured}
              {...register("email", validations.email())}
              autoComplete={shouldAutoComplete}
            />
            <PhoneInput
              errors={errors}
              register={phone}
              isConsentCaptured={isConsentCaptured}
              requestClientPhone={requestClientPhone}
              invalid={errors.phone}
              invalidText={errors.phone?.message}
              {...register("phone", validations.phone())}
            />
            <Select
              id="PhoneType"
              labelText="Phone type"
              key="phone-type"
              defaultValue="placeholder-item"
              invalid={errors.phoneType}
              disabled={!isConsentCaptured}
              {...register("phoneType")}
            >
              <SelectItem
                value="placeholder-item"
                text="Choose Phone type"
                hidden
                disabled
              />
              <SelectItem value="home" text="Home" key="phone-type-1" />
              <SelectItem value="mobile" text="Mobile" key="phone-type-2" />
              <SelectItem value="work" text="Work" key="phone-type-3" />
              <SelectItem value="other" text="Other" key="phone-type-4" />
            </Select>
          </div>
        </div>
        <br />
        <div className={S.buttonGroup}>
          <ButtonLoading
            type="submit"
            label={{
              baseText: "Enroll",
              loadingText: "Enrolling",
            }}
            loading={loading}
            disabled={!isConsentCaptured}
          >
            Enroll
          </ButtonLoading>
          <Button
            kind="tertiary"
            onClick={() => {
              reset();
              setEnrollUser(false);
            }}
          >
            Cancel
          </Button>
        </div>
      </form>
      <br />
      <hr />
      <br />
    </div>
  );
};

export default RegistrationForm;
