import React, { useState, useEffect } from "react";
import * as S from "./category.styled";

export const Category = ({
  setInputType,
  setCategory,
  category,
  current,
  subCategory
}) => {
  return (
    <S.Fieldset
      aria-label={category.domain}
      key={Math.random()}
      id="Test"
      theme={{
        hover: category.domain === current.isCurrent
      }}
      onClick={() => {
        current.selected(category.domain);
        setInputType("domain");
        setCategory({
          inputType: "domain",
          domain: category.domain,
          subCategory: category.subdomains.map((sub) => ({
            ...sub,
            checked: true
          })),
          isSubCategory: false
        });
      }}
      onMouseEnter={() => {
        subCategory.setShow(true);
        current.setCurrent(category.domain);
        subCategory.set({
          domain: category.domain,
          subCategory: category.subdomains.map((sub) => ({
            ...sub,
            checked: false
          }))
        });
      }}
    >
      <S.Circle
        aria-label={category.domain}
        theme={{
          color: category.color
        }}
      >
        <S.Img src={category.link} alt={category.domain} />
      </S.Circle>
      <S.Text>{category.domain}</S.Text>
    </S.Fieldset>
  );
};
