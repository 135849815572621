import React, { useState, useEffect, useContext } from "react";
import kit from "ich-ui-kit";
import socket from "../socket";

export const FeedbackContext = React.createContext([]);

export const FeedbackProvider = ({ children }) => {
  const { user, updateUser } = useContext(kit.UserContext);
  const [feedback, setFeedback] = useState();

  const getFeedback = (referralId, clientId) => {
    socket.emit("feedback:response", { id: clientId, referralId: referralId, isProvider: true }, (data) => {
      setFeedback(data.feedback);
      return data.feedback;
    });
  };

  const getAllFeedback = (orgId) => {
    socket.emit("feedback:responses", { orgId: orgId }, (data) => {
      setFeedback(data.feedback);
      return data.feedback;
    });
  };

  const getAllFeedback211 = (serviceIds) => {
    socket.emit("feedback:responses211", { serviceIds: serviceIds }, (data) => {
      setFeedback(data.feedback);
      return data.feedback;
    });
  };

  return (
    <FeedbackContext.Provider value={{ feedback, getFeedback, getAllFeedback, getAllFeedback211 }}>
      {children}
    </FeedbackContext.Provider>
  );
}

export const FeedbackConsumer = FeedbackContext.Consumer;