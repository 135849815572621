export const inquiryTypeOptions = [
  {
    text: "Choose an option",
    value: ""
  },
  {
    text: "Advocacy",
    value: "Advocacy"
  },
  {
    text: "Counseling & I&R",
    value: "Counseling & I&R"
  },
  {
    text: "Counseling Only",
    value: "Counseling Only"
  },
  {
    text: "Information Only",
    value: "Information Only"
  },
  {
    text: "Referral Call",
    value: "Referral Call"
  }
];

export const callTypeOptions = [
  {
    text: "211 Phantom",
    value: "211 Phantom"
  },
  {
    text: "Administrative Call",
    value: "Administrative Call"
  },
  {
    text: "Hang Up",
    value: "Hang Up"
  },
  {
    text: "Prank Call",
    value: "Prank Call"
  },
  {
    text: "Resource (QS Department)",
    value: "Resource (QS Department)"
  },
  {
    text: "Telemarketer",
    value: "Telemarketer"
  },
  {
    text: "Wrong Number",
    value: "Wrong Number"
  },
  {
    text: "I&R Call Back-Answered",
    value: "I&R Call Back-Answered"
  },
  {
    text: "I&R Call Back-Not Answered",
    value: "I&R Call Back-Not Answered"
  },
  {
    text: "I&R Call Back-Duplicate/Already Served",
    value: "I&R Call Back-Duplicate/Already Served"
  },
  {
    text: "Transactional Call",
    value: "Transactional Call"
  },
];

export let ageOptions = [];

ageOptions[0] = {
  text: "Choose an option",
  value: ""
}
ageOptions[1] = {
  text: "Declined to Answer",
  value: "Declined to Answer"
}
ageOptions[2] = {
  text: "0-11 months",
  value: "0-11 months"
}
for(let i = 3; i < 102; i++ )
{
  ageOptions[i] = {
    text: (i-2),
    value: (i-2)
  }
}
ageOptions[103] = {
  text: "100 years and older",
  value: "100 years and older"
}


  
    
  
  

export const callerTypeOptions = [
  {
    text: "Choose an option",
    value: ""
  },
  {
    text: "Adult (NOS)",
    value: "Adult (NOS)",
  },
  {
    text: "Agency/Professional",
    value: "Agency/Professional",
  },
  {
    text: "Child (Under 18)",
    value: "Child (Under 18)"
  },
  {
    text: "Government Agency",
    value: "Government Agency"
  },
  {
    text: "Medical Professional",
    value: "Medical Professional"
  },
  {
    text: "Parent",
    value: "Parent"
  },
  {
    text: "Landlord",
    value: "Landlord"
  },
];

export const lineDesignation = [
  {
    text: "211/LIFE LINE Phone",
    value: "211/LIFE LINE Phone",
  },
  {
    text: "911",
    value: "911",
  },
  {
    text: "MCDPH Covid Hotline",
    value: "MCDPH Covid Hotline",
  },
  {
    text: "Agrability",
    value: "Agrability",
  },
  {
    text: "Email Contacts/Referrals",
    value: "Email Contacts/Referrals",
  },
  {
    text: "SMS/Text",
    value: "SMS/Text",
  },
];

export const genderOptions = [
  {
    text: "Choose an option",
    value: ""
  },
  {
    text: "Female",
    value: "Female"
  },
  {
    text: "Male",
    value: "Male"
  },
  {
    text: "Intersex",
    value: "Intersex"
  },
  {
    text: "Transgender Female",
    value: "Transgender Female"
  },
  {
    text: "Transgender Male",
    value: "Transgender Male"
  },
  {
    text: "Gender Queer/Non-Binary",
    value: "Gender Queer/Non-Binary"
  },
  {
    text: "Prefer to Self-Identify",
    value: "Prefer to Self-Identify"
  },
  {
    text: "Declined to Answer",
    value: "Declined to Answer"
  }
];

export const callingForOptions = [
  {
    text: "Choose an option",
    value: ""
  },
  {
    text: "Self (and Immediate Family)",
    value: "Self (and Immediate Family)"
  },
  {
    text: "Self Individual",
    value: "Self Individual"
  },
  {
    text: "Friend",
    value: "Friend"
  },
  {
    text: "Parent",
    value: "Parent"
  },
  {
    text: "Patient/Client",
    value: "Patient/Client"
  },
  {
    text: "Sibling",
    value: "Sibling"
  },
  {
    text: "Son/Daughter",
    value: "Son/Daughter"
  },
  {
    text: "Spouse/Significant Other",
    value: "Spouse/Significant Other"
  },
  {
    text: "Other Relative",
    value: "Other Relative"
  },
  {
    text: "Other",
    value: "Other"
  },
  {
    text: "Tenant",
    value: "Tenant"
  },
];

// TODO required
export const languageOptions = [
  {
    text: "Choose an option",
    value: ""
  },
  {
    text: "English",
    value: "English"
  },
  {
    text: "Spanish",
    value: "Spanish"
  },
  {
    text: "ASL",
    value: "ASL"
  },
  {
    text: "Arabic",
    value: "Arabic"
  },
  {
    text: "Burmese",
    value: "Burmese"
  },
  {
    text: "Cantonese",
    value: "Cantonese"
  },
  {
    text: "Dari",
    value: "Dari"
  },
  {
    text: "Farsi",
    value: "Farsi"
  },
  {
    text: "French",
    value: "French"
  },
  {
    text: "Japanese",
    value: "Japanese"
  },
  {
    text: "Karen",
    value: "Karen"
  },
  {
    text: "Kareni",
    value: "Kareni"
  },
  {
    text: "Korean",
    value: "Korean"
  },
  
  {
    text: "Mandarin",
    value: "Mandarin"
  },
  {
    text: "Nepali",
    value: "Nepali"
  },
  {
    text: "Portuguese",
    value: "Portuguese"
  },
  {
    text: "Russian",
    value: "Russian"
  },
  {
    text: "Serbo-Croatian",
    value: "Serbo-Croatian"
  },
  {
    text: "Somali",
    value: "Somali"
  },
  {
    text: "Swahili",
    value: "Swahili"
  },
  {
    text: "Ukrainian",
    value: "Ukrainian"
  },
  {
    text: "Vietnamese",
    value: "Vietnamese"
  },
  {
    text: "Other",
    value: "Other"
  }
];

export const expectingChild = [
  {
    text: "Choose an option",
    value: "",
  },
  {
    text: "Yes",
    value: "Yes",
  },
  {
    text: "No",
    value: "No",
  },
  {
    text: "Decline to Answer",
    value: "Decline to Answer",
  },
]

export const childrenInHousehold = [
  {
    text: "Choose an option",
    value: "",
  },
  {
    text: "0-5",
    value: "0-5",
  },
  {
    text: "6+",
    value: "6+",
  },
]

export const adultsInHousehold = [
  {
    text: "Choose an option",
    value: "",
  },
  {
    text: "1-5",
    value: "1-5",
  },
  {
    text: "6+",
    value: "6+",
  },
]

export const covid19Options = [
  {
    text: "Choose an option",
    value: ""
  },
  {
    text: "Direct",
    value: "Direct"
  },
  {
    text: "Related",
    value: "Related"
  },
  {
    text: "Non",
    value: "Non"
  }
];

export const hispanicOriginsOptions = [
  {
    text: "Choose an option",
    value: ""
  },
  {
    text: "Yes",
    value: "Yes"
  },
  {
    text: "No",
    value: "No"
  },
  {
    text: "Declined to Answer",
    value: "Declined to Answer"
  },
];

export const raceOptions = [
  {
    text: "Choose an option",
    value: ""
  },
  {
    text: "American Indian or Alaskan Native",
    value: "American Indian or Alaskan Native"
  },
  {
    text: "Asian",
    value: "Asian"
  },
  {
    text: "Black or African American",
    value: "Black or African American"
  },
  {
    text: "White or Caucasian",
    value: "White or Caucasian"
  },
  {
    text: "Native Hawaiian or Pacific Islander",
    value: "Native Hawaiian or Pacific Islander"
  },
  {
    text: "Two or More Races",
    value: "Two or More Races"
  },
  {
    text: "Declined to Answer",
    value: "Declined to Answer"
  },
  
];

export const needsStatus = [
  {
    text: "Choose an option",
    value: ""
  },
  {
    text: "Need was met",
    value: "Need was met"
  },
  {
    text: "Incomplete call/Hang Up",
    value: "Incomplete call/Hang Up"
  },
  {
    text: "Service is unavailable",
    value: "Service is unavailable"
  },
  {
    text: "Service referral is refused",
    value: "Service referral is refused"
  },
  {
    text: "Service hours are inappropriate/inconvenient",
    value: "Service hours are inappropriate/inconvenient"
  },
  {
    text: "Exhausted suggested referrals",
    value: "Exhausted suggested referrals"
  },
  {
    text: "Funding is exhausted",
    value: "Funding is exhausted"
  },
  {
    text: "Service is too far away/Transportation is unavailable",
    value: "Service is too far away/Transportation is unavailable"
  },
  {
    text: "Ineligible for available services",
    value: "Ineligible for available services"
  },
  {
    text: "Frequent caller - Enforced policy",
    value: "Frequent caller - Enforced policy"
  }
];

export const referralSource = [
  {
    text: "Choose an option",
    value: ""
  },
  {
    text: "311",
    value: "311"
  },
  {
    text: "411/Information/Phone Book",
    value: "411/Information/Phone Book"
  },
  {
    text: "911/Police",
    value: "911/Police"
  },
  {
    text: "Always Known",
    value: "Always Known"
  },
  {
    text: "Billboard",
    value: "Billboard"
  },
  {
    text: "Brochure/Flyer/Letter",
    value: "Brochure/Flyer/Letter"
  },
  {
    text: "Bus/Train/Subway",
    value: "Bus/Train/Subway"
  },
  {
    text: "Business/Landlord/Workplace",
    value: "Business/Landlord/Workplace"
  },
  {
    text: "Caller Hung Up/Inappropriate to Ask/Refused",
    value: "Caller Hung Up/Inappropriate to Ask/Refused"
  },
  {
    text: "Cell Phone Applications",
    value: "Cell Phone Applications"
  },
  {
    text: "College/University/School",
    value: "College/University/School"
  },
  {
    text: "Community Organization/Neighborhood Community Center/Community Information Session/United Way",
    value: "Community Organization/Neighborhood Community Center/Community Information Session/United Way"
  },
  {
    text: "Church/Clergy",
    value: "Church/Clergy"
  },
  {
    text: "FEMA",
    value: "FEMA"
  },
  {
    text: "Friend/Relative/Neighbor",
    value: "Friend/Relative/Neighbor"
  },
  {
    text: "Government Agency/DMV/DSS",
    value: "Government Agency/DMV/DSS"
  },
  {
    text: "Internet/Social Media",
    value: "Internet/Social Media"
  },
  {
    text: "Library",
    value: "Library"
  },
  {
    text: "Newspaper/Magazine",
    value: "Newspaper/Magazine"
  },
  {
    text: "Other",
    value: "Other"
  },
  {
    text: "Previous Caller",
    value: "Previous Caller"
  },
  {
    text: "Professional (Doctor, Hospital, Social Worker/Liason, Mental Health Professional)",
    value: "Professional (Doctor, Hospital, Social Worker/Liason, Mental Health Professional)"
  },
  {
    text: "Quality Service Follow Up Call",
    value: "Quality Service Follow Up Call"
  },
  {
    text: "Radio Promotion",
    value: "Radio Promotion"
  },
  {
    text: "Television Promotion",
    value: "Television Promotion"
  },
  {
    text: "Union",
    value: "Union"
  },
];