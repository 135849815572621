import React, { useContext } from "react";
import { Button, InlineLoading } from "carbon-components-react";
import { CallLogContext } from "../../../../../store/CallLogContext";
import ResourceCard from "./ResourceCard";
import Empty from "./Empty";
import * as S from "../callLogPanel.styled";

const LocateTab = () => {
  const {
    resources,
    submitCallLog,
    loading,
    updateResourceNote,
    updateNeedsStatus,
    isSuccess,
    updateResources
  } = useContext(CallLogContext);

  if(isSuccess){
    return (
      <S.SuccessContainer>
        <h4>Call recorded successfully.</h4>
        <S.SuccessCheckmark />
      </S.SuccessContainer>
    )
  } else {
    return (
      <>
        <S.CardsContainer>
          {resources && resources.length > 0 ? (
            resources.map((service) => (
              <ResourceCard
                key={`${service.c360_orgId}|${service.locationId}|${service.serviceId}`}
                service={service}
                updateResourceNote={updateResourceNote}
                updateNeedsStatus={updateNeedsStatus}
                updateResources={updateResources}
              />
            ))
          ) : (
            <Empty />
          )}
        </S.CardsContainer>
        <Button onClick={submitCallLog} disabled={loading}>
          {loading ? (
            <InlineLoading description="Submitting..." />
          ) : (
            "Save & Continue"
          )}
        </Button>
      </>
    );
  }
};

export default LocateTab;
