import styled from "styled-components";
import { Button } from "carbon-components-react";

export const Container = styled.div`
  position: relative;
  width: 100%;
  box-sizing: border-box !important;
`;

export const InnerContainer = styled.div`
  position: relative;
  width: 100%;
  height: auto;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  min-height: 100vh;
`;

export const Content = styled.div`
  z-index: 10;
  // width: 100%;
  display: flex;
  flex-flow: column nowrap;
  justify-items: center;
  align-items: center;
  overflow: hidden;
  position: relative;
  padding: 0 1rem;
`;

export const Menu = styled.div`
  max-width: 700px;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(175px, 1fr));
`;
export const MenuItem = styled(Button)`
  border: 1px solid rgba(0, 0, 0, 0.25);
`;
