import React from "react";

const MWFSearchContext = React.createContext({});

const MWFSearchActions = {
  START_GET_SAVED_SEARCHES: 'START_GET_SAVED_SEARCHES',
  FINISH_GET_SAVED_SEARCHES: 'FINISH_GET_SAVED_SEARCHES',
  FAIL_GET_SAVED_SEARCHES: 'FAIL_GET_SAVED_SEARCHES',
  START_ADD_SAVED_SEARCH: 'START_ADD_SAVED_SEARCH',
  FINISH_ADD_SAVED_SEARCH: 'FINISH_ADD_SAVED_SEARCH',
  FAIL_ADD_SAVED_SEARCH: 'FAIL_ADD_SAVED_SEARCH',
  UPDATE_SAVE_SEARCH_UPDATED: 'UPDATE_SAVE_SEARCH_UPDATED',
  START_DELETE_SAVED_SEARCH: 'START_DELETE_SAVED_SEARCH',
  FINISH_DELETE_SAVED_SEARCH: 'FINISH_DELETE_SAVED_SEARCH',
  FAIL_DELETE_SAVED_SEARCH: 'FAIL_DELETE_SAVED_SEARCH',
}

function MWFSearchReducer(state, action) {
  switch (action.type) {
    case MWFSearchActions.START_GET_SAVED_SEARCHES: {
      return {
        ...state,
        savedSearches: null,
        savedSearchesIsLoading: true,
        savedSearchesError: null,
      }
    }
    case MWFSearchActions.FINISH_GET_SAVED_SEARCHES: {
      return {
        ...state,
        savedSearches: action.savedSearches.data.map((x) => ({ ...x, id: String(x.id) })),
        savedSearchesIsLoading: false,
        savedSearchesError: null,
      }
    }
    case MWFSearchActions.FAIL_GET_SAVED_SEARCHES: {
      return {
        ...state,
        savedSearches: null,
        savedSearchesIsLoading: false,
        savedSearchesError: action.error,
      }
    }
    case MWFSearchActions.START_ADD_SAVED_SEARCH: {
      return {
        ...state,
        addSavedSearchesIsLoading: true,
        addSavedSearchesError: null,
      }
    }
    case MWFSearchActions.FINISH_ADD_SAVED_SEARCH: {
      return {
        ...state,
        addSavedSearchesIsLoading: false,
        addSavedSearchesError: null,
        savedSearchesUpdated: true,
      }
    }
    case MWFSearchActions.FAIL_ADD_SAVED_SEARCH: {
      return {
        ...state,
        addSavedSearchesIsLoading: false,
        addSavedSearchesError: action.error,
      }
    }
    case MWFSearchActions.UPDATE_SAVE_SEARCH_UPDATED: {
      return {
        ...state,
        savedSearchesUpdated: action.savedSearchesUpdated,
      }
    }
    case MWFSearchActions.START_DELETE_SAVED_SEARCH: {
      return {
        ...state,
        deleteSavedSearchIsLoading: true,
        deleteSavedSearchError: null,
      }
    }
    case MWFSearchActions.FINISH_DELETE_SAVED_SEARCH: {
      return {
        ...state,
        deleteSavedSearchIsLoading: false,
        deleteSavedSearchError: null,
      }
    }
    case MWFSearchActions.FAIL_DELETE_SAVED_SEARCH: {
      return {
        ...state,
        deleteSavedSearchIsLoading: false,
        deleteSavedSearchError: action.error,
      }
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`)
    }
  }
}

const MWFSearchProvider = ({ children }) => {
  const [state, dispatch] = React.useReducer(MWFSearchReducer, {
    savedSearches: null,
    savedSearchesIsLoading: false,
    savedSearchesError: null,
    addSavedSearchesIsLoading: false,
    addSavedSearchesError: null,
    savedSearchesUpdated: false,
    deleteSavedSearchIsLoading: false,
    deleteSavedSearchError: null,
  })
  const value = [state, dispatch];
  return (
    <MWFSearchContext.Provider value={value}>
      {children}
    </MWFSearchContext.Provider>
  );
};

function useMWFSearch() {
  const context = React.useContext(MWFSearchContext)
  if (context === undefined) {
    throw new Error('useMWFSearch must be used within a MWFSearchProvider')
  }
  return context
}

async function getSavedSearches(dispatch, params) {
  
  if (params.savedSearches !== null) return;
  if (params.savedSearchesIsLoading === true) return;

  await getSavedSearchesAPICall(dispatch, params);
}

async function addSavedSearch(dispatch, params) {
  
  if (params.addSavedSearchesIsLoading === true) return;

  dispatch({type: MWFSearchActions.START_ADD_SAVED_SEARCH})
  try {
    const { ok } = await params.apiService.post(`/search?assumedId=${params.user.superAssumedRole?.leadId || ""}`, {
      term: params.term
    });
    if (ok) {
      dispatch({type: MWFSearchActions.FINISH_ADD_SAVED_SEARCH})
      const savedSearchesUpdated = false;
      setTimeout(() => dispatch({type: MWFSearchActions.UPDATE_SAVE_SEARCH_UPDATED, savedSearchesUpdated}), 2000);
      // get saved searches again after successful save
      await getSavedSearchesAPICall(dispatch, params);
    } else {
      const error = new Error('Add Search API did not return OK');
      dispatch({type: MWFSearchActions.FAIL_ADD_SAVED_SEARCH, error})  
    }
  } catch (error) {
    dispatch({type: MWFSearchActions.FAIL_ADD_SAVED_SEARCH, error})
  }
}

async function deleteSavedSearch(dispatch, params) {
  
  if (params.deleteSavedSearchIsLoading === true) return;

  dispatch({type: MWFSearchActions.START_DELETE_SAVED_SEARCH})
  try {
    const deleted = await params.apiService.delete(`/search?id=${params.id}&assumedId=${params.user.superAssumedRole?.leadId || ""}`);
    if(deleted.ok){
      dispatch({type: MWFSearchActions.FINISH_DELETE_SAVED_SEARCH})
      // get saved searches again after successful delete
      await getSavedSearchesAPICall(dispatch, params);
    } else {
      const error = new Error('Delete saved search API did not return OK');
      dispatch({type: MWFSearchActions.FAIL_DELETE_SAVED_SEARCH, error})  
    }
  } catch (error) {
    dispatch({type: MWFSearchActions.FAIL_DELETE_SAVED_SEARCH, error})
  }
}

async function getSavedSearchesAPICall(dispatch, params) {
  dispatch({type: MWFSearchActions.START_GET_SAVED_SEARCHES})
  try {
    const savedSearches = await params.apiService.get(`/search?assumedId=${params.user.superAssumedRole?.leadId || ""}`);
    dispatch({type: MWFSearchActions.FINISH_GET_SAVED_SEARCHES, savedSearches})
  } catch (error) {
    dispatch({type: MWFSearchActions.FAIL_GET_SAVED_SEARCHES, error})
  }
}

export {
  MWFSearchProvider, 
  useMWFSearch,
  getSavedSearches,
  addSavedSearch,
  deleteSavedSearch,
}