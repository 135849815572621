import React, { useState, useEffect, useContext } from "react";
import Empty from "./Empty";
import DragAndDrop from "./DragAndDrop";
import Wellbeing from "./Wellbeing";
import { Loading } from "carbon-components-react";
import { useHistory, useLocation } from "react-router-dom";
import useQuery from "../../../../hooks/useQuery";
import kit from "ich-ui-kit";
import { fetchWithRetry } from "../../../../utils";
import * as S from "./overview.styled";

function formatDateWithoutOffset(originalDateString) {
  var originalDate = new Date(originalDateString);
  var year = originalDate.getFullYear();
  var month = String(originalDate.getMonth() + 1).padStart(2, '0');
  var day = String(originalDate.getDate()).padStart(2, '0');
  var hours = String(originalDate.getHours()).padStart(2, '0');
  var minutes = String(originalDate.getMinutes()).padStart(2, '0');
  var seconds = String(originalDate.getSeconds()).padStart(2, '0');
  var milliseconds = String(originalDate.getMilliseconds()).padStart(3, '0');
  var formattedDate = `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.${milliseconds}Z`;
  return formattedDate;
}

function isDST() {
  var now = new Date();
  var jan = new Date(now.getFullYear(), 0, 1);
  var jul = new Date(now.getFullYear(), 6, 1);
  var stdTimezoneOffset = Math.max(jan.getTimezoneOffset(), jul.getTimezoneOffset());
  var isDST = now.getTimezoneOffset() < stdTimezoneOffset;
  return isDST;
}
const startingDomains = [
  {
    id: "housing",
    name: "Housing",
    thumb: "/domains/housing.svg",
  },
  {
    id: "health",
    name: "Health",
    thumb: "/domains/Health.svg",
  },
  {
    id: "financial",
    name: "Financial",
    thumb: "/domains/financial.svg",
  },
  {
    id: "employment",
    name: "Employment",
    thumb: "/domains/employment.svg",
  },
  {
    id: "income",
    name: "Income",
    thumb: "/domains/legal.svg",
  },
  {
    id: "education",
    name: "Education",
    thumb: "/domains/education.svg",
  },
  {
    id: "food",
    name: "Food",
    thumb: "/domains/food.svg",
  },
  {
    id: "transportation",
    name: "Transportation",
    thumb: "/domains/transportation.svg",
  },
  {
    id: "safety",
    name: "Safety",
    thumb: "/domains/safety.svg",
  },
  {
    id: "socialconnection",
    name: "Social Connection",
    thumb: "/domains/socialconnection.svg",
  },
  {
    id: "dependentcare",
    name: "Dependent Care",
    thumb: "/domains/dependentcare.svg",
  },
];

const responseMapping = {
  0: ["No response", "#c6c6c6"],
  1: ["Not OK", "#ec5c29"],
  2: ["OK", "#faa41a"],
  3: ["Good", "#f4db5f"],
  4: ["Great", "#4ac1e0"],
  5: ["Excellent", "#95c93d"],
};

const Overview = ({ clientID }) => {
  const query = useQuery();
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const { apiService } = useContext(kit.ApiContext);
  const [latestAssessment, setLatestAssessment] = useState(null);
  const [latestDDAssessment, setLatestDDAssessment] = useState(null);
  const [domains, setDomains] = useState(null);
  const [name, setName] = useState("");
  const { user } = useContext(kit.UserContext);
  const [width, setWidth] = useState(window.innerWidth);
  const [scrollWidth, setScrollWidth] = useState(1125);
  const [scrollPos, setScrollPos] = useState(0);

  const id = query.get("id");
  if (id) {
    history.push({
      state: {
        client: id,
      },
    });
  }

  useEffect(() => {
    const handleResizeWindow = () => setWidth(window.innerWidth);
    if (width > 768) {
      setScrollWidth(1125);
    } else {
      setScrollWidth(1000);
    }
    window.addEventListener("resize", handleResizeWindow);
    return () => {
      window.removeEventListener("resize", handleResizeWindow);
    };
  }, []);

  useEffect(() => {
    const getAssessments = async () => {
      try {
        const data = await apiService.get(
          `/assessments/client/${
            history.location.state.client
          }?assumedId=${user.superAssumedRole?.leadId || ""}
          `
        );
        if (data.ok) {
          data.assessments.length > 0 &&
            setLatestAssessment(data.assessments[0]);
        }
      } catch (err) {
        console.log(err);
      }
      try {
        const dataDD = await apiService.get(
          `/assessments/client/self?id=${
            history.location.state.client
          }&assumedId=${user.superAssumedRole?.leadId || ""}
          `
        );
        if (dataDD && dataDD.id) {
          setLatestDDAssessment(dataDD);
        }
      } catch (err) {
        setLatestAssessment([]);
        setLatestDDAssessment([]);
        console.log(err);
      }
    };
    const getDomains = async () => {
      try {
        const data = await apiService.get(
          `/domains?id=${
            history.location.state.client
          }&assumedId=${user.superAssumedRole?.leadId || ""}
          `
        );
        if (data.ok) {
          setDomains(data.domains);
        }
      } catch (err) {
        console.log(err);
      }
    };
    setLoading(true);
    async function fetchData() {
      await getAssessments();
      await getDomains();
      setLoading(false);
    }
    fetchData();
  }, [apiService, location.state]);

  useEffect(() => {
    try {
      setLoading(true);
      const getUserInfo = async () => {
        const response = await fetchWithRetry(`${process.env.REACT_APP_API_ENDPOINT}/clients/${history.location.state.client}?assumedId=${user.superAssumedRole?.leadId || ""}`,
          {
            method: "get",
            headers: {
              Authorization: `Bearer ${user.accessToken} ${user.idToken}`,
            },
          }
        );

        if (response.status === 200) {
          const data = await response.json();
          setName(data.givenName, () => {
            setLoading(false);
          });
        } else {
          console.log(response);
        }
      };
      getUserInfo();
    } catch (err) {
      console.log(err);
    }
  }, [location.state]);

  const colorBlindLegend = (status) => {
    if (status) {
      switch (status.toLowerCase()) {
        case "not ok":
          return <>1</>;
        case "ok":
          return <>2</>;
        case "good":
          return <>3</>;
        case "great":
          return <>4</>;
        case "excellent":
          return <>5</>;
        default:
          return <></>;
      }
    }
  };


  const renderPriorityContent = () => {
    if (latestAssessment && domains) {
      return (
        <>
          <S.DomainContainer
            id="domaincontainer"
            onScroll={(e) => setScrollPos(e.target.scrollLeft)}
          >
            <DragAndDrop
              latestAssessment={latestAssessment}
              beginDomains={startingDomains}
              domainPriorities={domains}
              latestDDAssessment={latestDDAssessment}
              client={clientID}
              responseMapping={responseMapping}
            ></DragAndDrop>
          </S.DomainContainer>
        </>
      );
    } else {
      return <Empty />;
    }
  };

  function isValidDate(dateString){
    const date = new Date(dateString);
    return !isNaN(date.getTime()); 
  }

  function formatDate(dateString) {
    if(dateString == "Invalid Date"){
      return "Not updated yet";
    }
    const date = new Date(dateString);
    const month = date.getMonth() + 1;
    const day = date.getDate();
    const year = date.getFullYear();
    const formattedDate = `${month.toString().padStart(2, '0')}/${day.toString().padStart(2, '0')}/${year}`;
    return formattedDate;
  }
  
  function getLatestDate(){
    if (!isValidDate(domains[0].updateAt)) {
      return ("Invalid Date");
    } 
    else {
      // SIPD-3690 Convert clientReorderDomainsDate and checkinSubmitDate to UTC time and compare
      const parsedClientReorderDomainsDate = new Date(domains[0].updateAt); // This is an Center time
      /**
       * SIPD-3691 Debug: Handling Timestamps in Different Time Zones
       * 
       * Problem:
       * The 'domains[0].updatedAt' timestamp is stored in Central Time (CST) in the DEV environment,
       * but in UTC in UAT and PROD environments.
       * 
       * Solution:
       * 1. Check the current URL to determine the environment:
       *    - If the URL contains 'localhost' or 'dev', treat the timestamp as CST and convert it to UTC.
       *    - If it's not 'localhost' or 'dev', treat the timestamp as UTC.
       * 
       * 2. Adjust for Daylight Saving Time (DST):
       *    - If DST is currently in effect, add 5 hours to the timestamp (CST to UTC).
       *    - If DST is not in effect, add 6 hours to the timestamp (CST to UTC).
       * 
       * 3. Convert the adjusted timestamp to the desired format.
       * 
       * Usage:
       * - 'parsedClientReorderDomainsDate': Input timestamp to be adjusted and formatted.
       * - 'utcClientReorderDomainsDate': Adjusted and formatted UTC timestamp.
       */
      const currentUrl = window.location.href;
      if (currentUrl.includes('localhost') || currentUrl.includes('dev') ){
        // Check if Daylight Saving Time (DST) is in effect
        if (isDST()){
          parsedClientReorderDomainsDate.setHours(parsedClientReorderDomainsDate?.getHours() + 5);
        } else {
          parsedClientReorderDomainsDate.setHours(parsedClientReorderDomainsDate?.getHours() + 6);
        }
      }
      // Convert the adjusted timestamp to the desired format without adding a time offset
      const utcClientReorderDomainsDate = formatDateWithoutOffset(parsedClientReorderDomainsDate);

      const parsedCheckinSubmitDate = new Date(latestAssessment.date); // This is an UTC time
      // Format parsedCheckinSubmitDate to ISOString
      const year = parsedCheckinSubmitDate.getFullYear();
      const month = String(parsedCheckinSubmitDate.getMonth() + 1).padStart(2, '0');
      const day = String(parsedCheckinSubmitDate.getDate()).padStart(2, '0');
      const hours = String(parsedCheckinSubmitDate.getHours()).padStart(2, '0');
      const minutes = String(parsedCheckinSubmitDate.getMinutes()).padStart(2, '0');
      const seconds = String(parsedCheckinSubmitDate.getSeconds()).padStart(2, '0');
      const utcCheckinSubmitDate = `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.000Z`;

      let latestDate;
      if (utcClientReorderDomainsDate && utcCheckinSubmitDate) {
        latestDate = utcClientReorderDomainsDate > utcCheckinSubmitDate ? new Date(utcClientReorderDomainsDate) : new Date(utcCheckinSubmitDate);
      } else if (utcClientReorderDomainsDate) {
        latestDate = new Date(utcClientReorderDomainsDate);
      } else if (utcCheckinSubmitDate) {
        latestDate = new Date(utcCheckinSubmitDate);
      }
      return latestDate;
    }
  }
  
  return (
    <S.ScrollContainer>
      <S.Header>
        <h3>
          {name ? (
            <strong>{name}'s Well-being</strong>
          ) : (
            <strong>Well-being</strong>
          )}
        </h3>
      </S.Header>
      <S.LegendWrapper>
        <S.LegendCircle theme={{ bcolor: responseMapping[1][1] }}>
          {colorBlindLegend("Not OK")}
        </S.LegendCircle>
        <S.LegendText>Not OK</S.LegendText>
        <S.LegendCircle theme={{ bcolor: responseMapping[2][1] }}>
          {colorBlindLegend("OK")}
        </S.LegendCircle>
        <S.LegendText>OK</S.LegendText>
        <S.LegendCircle theme={{ bcolor: responseMapping[3][1] }}>
          {colorBlindLegend("Good")}
        </S.LegendCircle>
        <S.LegendText>Good</S.LegendText>
        <S.LegendCircle theme={{ bcolor: responseMapping[4][1] }}>
          {colorBlindLegend("Great")}
        </S.LegendCircle>
        <S.LegendText>Great</S.LegendText>
        <S.LegendCircle theme={{ bcolor: responseMapping[5][1] }}>
          {colorBlindLegend("Excellent")}
        </S.LegendCircle>
        <S.LegendText>Excellent</S.LegendText>
      </S.LegendWrapper>
      {latestAssessment && domains ? (
        <>
          <S.PriorityUpdatedWrapper>Priority updated 
            <S.PriorityUpdatedText>{formatDate(getLatestDate())}</S.PriorityUpdatedText>
          </S.PriorityUpdatedWrapper>
          <S.PContent>{renderPriorityContent()}</S.PContent>
          {/* <S.Header>
            <h3>
              {name ? (
                <strong>{name}'s Well-being</strong>
              ) : (
                <strong>Well-being</strong>
              )}
            </h3>
          </S.Header> */}
          {/* <S.WContent>{renderWellbeingContent()}</S.WContent> */}
        </>
      ) : !loading ? (
        <>
          <S.PriorityUpdatedWrapper>Priority updated 
            <S.PriorityUpdatedText>Not updated yet</S.PriorityUpdatedText>
          </S.PriorityUpdatedWrapper>
          <S.CheckinEmpty>
            <p>{name || 'Client'} has not checked in yet.</p>
          </S.CheckinEmpty>
        </>
      ) : (
        <Loading />
      )}
    </S.ScrollContainer>
  );
};

export default Overview;
