import React from "react";
import * as S from "./menu.styled";
import { Item } from "./item";

export const Menu = ({ client, current, setCurrent }) => {
  const openPage = (current) => {
    setCurrent(current);
  };

  const items = [
    {
      id: Math.random(),
      text: "Well-being",
      path: "/360dashboard/overview"
    },
    {
      id: Math.random(),
      text: "Demographic Data",
      path: "/360dashboard/demographic-data"
    },
    {
      id: Math.random(),
      text: "Care Team",
      path: "/360dashboard/care-team"
    },
    {
      id: Math.random(),
      text: "Service Requests",
      path: "/360dashboard/service-requests"
    },
    {
      id: Math.random(),
      text: "Check-ins",
      path: "/360dashboard/check-ins"
    },
    // {
    //   id: Math.random(),
    //   text: "Messages",
    //   path: "/360dashboard/messages"
    // },
    {
      id: Math.random(),
      text: "Event History",
      path: "/360dashboard/longitudinal-record"
    }
  ];
  return (
    <S.Container>
      {items.map((item) => (
        <Item
          path={item.path}
          key={item.id}
          text={item.text}
          current={current}
          handleClick={openPage}
          client={client}
        />
      ))}
    </S.Container>
  );
};
