import styled from "styled-components";

export const PageContainer = styled.div`
  height: 75vh;
  width: 100vw;
  margin-top: 2rem;
  background-color: #f4f4f4;
  z-index: 1;
  display: grid;
  grid-template-rows: 8% 87% 5%;
  grid-template-areas: "mwf-header" "content" "progress";
  @media screen and (max-height: 700px) {
    grid-template-rows: 6% 87% 7%;
  }
  @media screen and (max-height: 768px) {
    grid-template-rows: 6% 87% 7%;
    // background-color: white;
    margin-top: 1.5rem;
  }
`;

export const HeaderContainer = styled.div`
  font-size: 22px;
  font-weight: 600;
  grid-area: "mwf-header";
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 1rem;

  @media screen and (max-width: 768px) {
    font-size: 18px;
    margin: 0rem 0.5rem;
    text-align: center;
  }
`;

export const ContentContainer = styled.div`
  grid-area: "content";
  height: 100%;
  width: 100%;
  @media screen and (max-width: 768px) {
    margin-top: 2rem;
    height: 100%;
  }
`;