import React from "react";
import * as S from "./under-construction.styled";
const UnderConstruction = () => {
  return (
    <S.Container>
      <S.Content>
        <S.Img src="/under-construction.svg" />
        <S.Heading>This page is currently</S.Heading>
        <S.HeadingMain>Under Construction</S.HeadingMain>
        <S.SubHeading>Please come back later</S.SubHeading>
      </S.Content>
    </S.Container>
  );
};

export default UnderConstruction;
