import { useEffect, useState } from "react";
import { useStrapi } from "../../hooks/useStrapi";

export const usePrivacyPolicyCMS = () => {
  const init = {
    heading: "Privacy Policy",
    effective_date: "2022-03-02",
    text_effective_date: "Effective Date:",
    text_updated_as_of: "Updated as of: ",
    heading_we_updated: "We Updated our Privacy Policy",
    btn_view: "View updated policy",
    btn_close: "Close",
    items: [
      {
        title: "Overview",
        content:
          "The Systems Integration Project (“SIP”), a community project at United Way of Greater Rochester (together, “us,” “our,” or “we”), works across a diverse network of committed providers and community members to put people at the center of care in an interconnected system of education, health, and human services.  Through a first-of-its-kind collaborative community program, SIP receives health, education, social, economic and other information from participating agencies.  Protection of your personal information and privacy is of the highest priority.  This privacy policy (“Privacy Policy” or “Policy”) outlines our policies and practices with respect to how we collect, use, share, maintain, and protect your personal information.\n\n\nThis Privacy Policy applies to all personal information collected from participating agencies and partners, as well as from you through your use of this website or personal information you may provide to us when you interact with us offline (“Personal Information”).  By visiting this website, you are agreeing to the practices or policies described below, and you are agreeing to be bound by its terms in relation to your use of the website.  SIP obtains separate, informed consent from individual participants in its program."
      },
      {
        title: "Why do we collect Personal Information?",
        content:
          "We collect and use (and, where specified, share) your Personal Information for the following purposes:\n\n**To provide support or other services:**  We may use your Personal Information to provide you with support or other services that you have requested.  We may also use your Personal Information to respond directly to your requests.\n\nSubmission of Personal Information for an employment application is used for the exclusive purpose of processing your employment application.\n\n**To select content, improve quality, and facilitate use of the website:**  We may use your Personal Information, including the information gathered from cookies to help create and personalize website content, improve website quality, track communication responsiveness, evaluate page response rates, conduct usability testing, and facilitate your use of the website (for example, to facilitate navigation and the login process, avoid duplicate data entry, enhance security).\n\n**To protect our content and services:**  We may use your Personal Information to prevent potentially illegal activities.\n\n**To get feedback or input from you:**  We may use your Personal Information to optimize our internal processes.\n"
      },
      {
        title: "Where do we collect Personal Information from?",
        content:
          "We currently collect Personal Information directly from participants who consent to create an account."
      },
      {
        title: "What Personal Information is disclosed?",
        content:
          "We collect certain Personal Information for certain purposes described above and may disclose certain categories of Personal Information to third parties.\n\nWhile Personal Information received by the SIP regarding participants from various agencies and organizations will be identifiable, individual-specific data, in most cases the information being shared by the SIP with its participants will not be granular, identifiable data.  Rather, the majority of the information shared with participants will be available through a dashboard that gathers and displays the information.\n\nWe believe that you should have control over who receives your Personal Information.Therefore, we do not trade, sell, or share your Personal Information with third parties unless they are carrying out business activities and functions on our behalf."
      },
      {
        title: "Participant control and transparency",
        content:
          "Participants in the SIP will have the ultimate control over their Personal Information.  They will be able to request access to all Personal Information held regarding themselves, receive a portable copy of such data in a commonly-used format, correct any errors, request removal or deletion, and otherwise manage their Personal Information."
      },
      {
        title: "Cookies",
        content:
          "Our website uses cookies, which are small text files that are stored on the device you use to navigate our website.  Cookies may collect information such as the pages your visit on our website, your web address, the type of browser, device or hardware you use, search terms, advertising identifier, mobile network information, and IP-based geography location.  This information helps us recognize you the next time you visit our website, so we can customize your next experience with us.  You can set your browser to reject and/or remove cookies at any time.  Our services can still be used if cookies are disabled, rejected, or removed.  Some features of the services may be impacted.  For example, a common cookie enables your browser to remember a login username for future logins.  Disabling cookies will prevent you from being able to do so.  To learn more about your ability to manage cookies, please consult the privacy features in your web browser."
      },
      {
        title: "Analytics",
        content:
          "We may use third party tracking tools provided by third-party service providers to help us collect and analyze information about use of our website.  We use this information to improve our website and your experience while on our website.  Most third-party analytics tools collect only your Internet-Protocol (“IP”) address assigned to you on the date you visit our website, rather than your name and other identifying information.  Although third-party analytics tools store a cookie on your machine to identify you as a unique user the next time you visit our website, the cookie typically cannot be used by anyone but that third party.  Some of these third-party service providers may collect information from our website for retargeting and interest-based advertising.  Some third-party service providers may also build a profile of users’ online browsing activities.  For more information about these forms of targeted advertising and to understand your right to opt-out from these practices, please visit: [YourAdChoices](https://optout.aboutads.info/?c=2&lang=EN)\n\n**Google Analytics:** We may use Google Analytics to develop website content and to collect information regarding visitor behavior and demographics on our website.  This analytics data collected by Google is not tied to any personally identifiable data.  For more information about Google Analytics, visit: [Partner Sites](https://policies.google.com/technologies/partner-sites).  You may opt out of Google’s collection and processing of data generated by your use of our website by visiting: [Google Analytics Opt-out](https://tools.google.com/dlpage/gaoptout)."
      },
      {
        title: "Third Party Practices",
        content:
          "Our website may contain links to other websites that have their own separate terms and conditions and privacy policies.  We have no control over, and assume no responsibility for, the content, policies, and practices of any third-party website.  The terms and conditions and privacy policies of such websites govern your use of the services offered on those websites."
      },
      {
        title: "Security",
        content:
          "A secure data hub designed by IBM will facilitate sharing of information by and with participants in the SIP program.  We make every effort to ensure that Personal Information is not lost, misused or altered inappropriately by administering security measures.  We will maintain security measures in order to protect and safeguard your information, including data minimization, data life-cycle management, secure destruction and disposal of data that is no longer needed, multi-factor authentication, and secure cloud computing.  However, information transferred over the Internet is not absolutely secure.  As a result, we cannot guarantee that your information will not be misused or disclosed to third parties."
      },
      {
        title: "Children Under 13",
        content:
          "Our website and My Wayfinder are not created to be used or independently accessed by children under the age of 13. We do not plan to collect information from children under the age of 13 and will delete the information if we learn we have collected such information from the child. More information about the Children’s Online Privacy Protection Act (COPPA) and how it protects children who use the Internet can be found at [Federal Trade Commission](www.ftc.gov).\n\nIf a parent or guardian of a child under 13 years of age lets us know that the child’s Personal Information has been submitted to us through the website without the parent’s or guardian’s permission, we will use all reasonable efforts to remove the information from the website. To request the removal of Personal Information for a child, the parent or guardian should contact us and help us identify the information that should be removed. \n\nParents or guardians will have the ability to create or manage a profile on behalf of a child.  This activity is appropriate and not covered by COPPA, which only applies to personal information collected online from children."
      },
      {
        title: "How will you know if the Privacy Policy is changed?",
        content:
          "We may update this Privacy Policy at any time.  If we materially alter this policy, we will notify you of such changes by posting a notice on our website.  Your continued use of our website will be deemed your agreement that your information may be used in accordance with the new policy.  If you do not agree with the changes, then you should stop using the website and you should notify us that you do not want your information used in accordance with the changes."
      },
      {
        title: "Contact Information",
        content:
          "If you have any questions about our Privacy Policy, please contact us at [systemsintegrationproject@systemsintegration.org](mailto:systemsintegrationproject@systemsintegration.org)."
      }
    ]
  };
  const { data, loading } = useStrapi({
    cms: `page-privacy-policy`
  });

  const [ppCMS, setPPCMS] = useState(init);

  useEffect(() => {
    if (loading) {
      setPPCMS(init);
      return;
    }
    setPPCMS((state) => ({
      heading: data.heading || state.heading,
      effective_date: data.effective_date || state.effective_date,
      text_effective_date:
        data.text_effective_date || state.text_effective_date,
      text_updated_as_of: data.text_updated_as_of || state.text_updated_as_of,
      heading_we_updated: data.heading_we_updated || state.heading_we_updated,
      btn_view: data.btn_view || state.btn_view,
      btn_close: data.btn_close || state.btn_close,
      items: data.items || state.items
    }));
  }, [data]);

  return { ppCMS };
};
