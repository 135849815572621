import {
  Bookmark16,
  BookmarkFilled16,
  Download16,
  Filter16,
  WarningAltFilled16,
} from "@carbon/icons-react";
import {
  DataTable,
  DatePicker,
  DatePickerInput,
  Dropdown,
  InlineLoading,
  Loading,
  OverflowMenu,
  OverflowMenuItem,
  PaginationNav,
  Search,
  Select,
  SelectItem,
  Table,
  TableBody,
  TableCell,
  TableHeader,
  TableToolbar,
  TableToolbarContent,
  Tooltip,
} from "carbon-components-react";
import { default as kit } from "ich-ui-kit";
import {
  default as React,
  useContext,
  useEffect,
  useLayoutEffect,
  useState,
} from "react";
import { CSVLink } from "react-csv";
import { useHistory } from "react-router";
import { getDisplayedStatus, getLocalTime } from "../../utils";
import { default as CallLogTab } from "./components/call-log-tab/call-log-tab";
import { default as DashboardHeaderContainer } from "./components/header";
import { default as AssignReferralModal } from "./components/modals/assign-referral-modal.jsx";
import { default as CloseReasonModal } from "./components/modals/close-reason-modal.jsx";
import { default as ReferralFilterModal } from "./components/modals/filter-modal.jsx";
import {
  OrganizationStats,
  OrganizationStats211,
} from "./components/my-org/org-stats-tab";
import { default as OutboundReferrals } from "./components/outbound/outbound-tab";
import { default as OverviewCards } from "./components/overview-cards";
import { renderOverflowOptions } from "./components/update-status-overflow";
import {
  ageColor,
  aStatuses,
  avgAge,
  rOpenStatuses,
  rStatuses,
  searchFor,
  sortHeader,
  updateDate,
} from "./helpers/filter-helpers";
import {
  BookmarkButton,
  CardDiv,
  DownloadButton,
  DownloadWrapper,
  FilterButton,
  FilterButtonWrapper,
  FilterWrapper,
  Flex,
  FlexCenter,
  PageContainer,
  SearchWrapper,
  StyledAdd,
  StyledCircle,
  StyledDropdown,
  StyledEdit,
  StyledFlag,
  StyledMultiSelectService,
  StyledOverflowMenu,
  StyledTableContainer,
  StyledTableHead,
  StyledTableRow,
  TableSubLabel,
  ToolbarInner,
} from "./index.styled";
import { downloadHeaders, headers, TobiData } from "./utils";
import {
  getCompletedReferrals,
  updateReferralFilter,
} from "./helpers/filter-helpers";
import { ActivityFeedContext } from "../../store/ActivityFeedContext";
import DashboardFilters from "./components/filters";

import { useFeedbackCMS } from "../feedback/cms";

const ReferralDashboardPage = () => {
  // Table States
  const [currentPage, setCurrentPage] = useState(0);
  const [pageMin, setPageMin] = useState(0);
  const [pageMax, setPageMax] = useState(20);
  const [displayedItems, setDisplayedItems] = useState([]);
  const [displayedItemsDownload, setDisplayedItemsDownload] = useState([]);
  const [outboundItems, setOutboundItems] = useState([]);
  const [outboundItemsDownload, setOutboundItemsDownload] = useState([]);
  const [outboundItemsAll, setOutboundItemsAll] = useState([]);
  const [mockDataState, setMockDataState] = useState([]);
  
  const history = useHistory();
  const currentTab = history?.location?.state?.cameFromTab ?? "";
  const [tab, setTab] = useState(currentTab);
  const paginationItemsPerPage = 20;

  // Tooltips
  const [openTooltip, setOpenTooltip] = useState(false);
  const [openExpireTooltip, setOpenExpireTooltip] = useState("");

  // Modal States
  const [openFilterModal, setFilterModalOpen] = useState(false);
  const [referralModalOpen, setReferralModalOpen] = useState(false);
  const [tempStatus, setTempStatus] = useState("");
  const [noteModalOpen, setNoteModalOpen] = useState(false);
  const [assignStatuses, setAssignStatuses] = useState([]);
  const [referStatuses, setReferStatuses] = useState([]);
  const [parentRefers, setParentRefers] = useState({ id: "parent-open", label: "Open" });
  const [selectServices, setSelectServices] = useState([]);
  const [filterBookmarked, setFilterBookmarked] = useState(false);

  const [referralClosed, setReferralClosed] = useState("");
  const [referralAssigned, setReferralAssigned] = useState("");
  const [reassigned, setReassigned] = useState(false);
  const { user, updateUser } = useContext(kit.UserContext);
  const { setReferralActivityInactive, setReferralActivitiesInactive } =
    useContext(ActivityFeedContext);

  // Filter and search
  const [sortHeaderState, setSortHeaderState] = useState("");
  const [sortHeaderDirection, setSortHeaderDirection] = useState(0);
  const [dateStart, setDateStart] = useState("");
  const [dateEnd, setDateEnd] = useState("");

  // load services for all services tab
  const [services, setServices] = useState([]);
  const [allServices, setAllServices] = useState([]);
  const [serviceLeadArray, setServiceLeadArray] = useState([]);
  const [orgStats, setOrgStats] = useState([]);

  // load clients and referrals
  const { apiService } = useContext(kit.ApiContext);
  const [loading, setLoading] = useState(false);
  const [referrals, setReferrals] = useState([]);
  const [labels, setLabels] = useState([]);
  const [loadingInline, setLoadingInline] = useState("");

  // CMS
  const { content: feedbackCms } = useFeedbackCMS();

  useLayoutEffect(() => {
    const fetchLabels = async () => {
      let labels = await apiService.get(
        `/labels/referrals?assumedId=${user.superAssumedRole?.leadId || ""}`
      );
      setLabels(labels);
    };

    fetchLabels();
  }, [apiService]);

  useEffect(() => {
    const addLabelsToArrayForDownload = async () => {
      let downloadArray = [...displayedItems];
      downloadArray = downloadArray.map((referral) => {
        referral.assignedTo = formatAssignedTo(referral);
        referral.status = referral?.status?.toLowerCase();
        if (labels?.find((l) => l.id === referral.id)) {
          return {
            ...referral,
            label: labels?.find((l) => l.id === referral.id).label,
          };
        } else {
          return { ...referral, label: "" };
        }
      });
      setDisplayedItemsDownload(downloadArray);
    };

    addLabelsToArrayForDownload();
  }, [labels, displayedItems]);

  useEffect(() => {
    const addLabelsToArrayForDownload = async () => {
      let downloadArray = [...outboundItems];
      downloadArray = downloadArray.map((referral) => {
        referral.assignedTo = formatAssignedTo(referral);
        referral.status = referral?.status?.toLowerCase();
        if (labels?.find((l) => l.id === referral.id)) {
          return {
            ...referral,
            label: labels?.find((l) => l.id === referral.id).label,
          };
        } else {
          return { ...referral, label: "" };
        }
      });
      setOutboundItemsDownload(downloadArray);
    };

    addLabelsToArrayForDownload();
  }, [labels, outboundItems]);

  useLayoutEffect(() => {
    if (user.isSuper) {
      getAllReferrals();
    } else if (user.is211Admin) {
      get211ProviderInfo();
    } else {
      getProviderInfo();
    }
  }, [apiService, mockDataState]);

  useLayoutEffect(() => {
    const getOrgStats = async () => {
      try {
        const response = await apiService.get(`/referrals/orgstats?assumedId=${user.superAssumedRole?.leadId || ""}`);
        const { stats } = response;
        setOrgStats(stats);
      } catch (err) {
        console.log(err);
      }
    };
    getOrgStats();
  }, [apiService]);

  useEffect(() => {
    const getProviderOrgInfo = async () => {
      let tempallservices;
      const providerInfo2 = await apiService.post(
        `/search/provider?user=${user.superAssumedRole?.leadId || user.id}`
      );
      const { providers } = providerInfo2;
      if (providers) {
        const orgId = providers.id;
        if (orgId) {
          setAllServices(providers.services);
          tempallservices = providers.services;
          if (
            (user.isSuper && !user.superAssumedRole) ||
            user.isAdmin ||
            user.superAssumedRole?.role === "admin"
          ) {
            setServices(providers.services);
          } else {
            const providerServices2 = await apiService.get(
              `/providerstaff/service/dashboard?assumedUserId=${user.superAssumedRole?.leadId}`
            );
            if (providerServices2.ok) {
              setServiceLeadArray(providerServices2.services);
              setServices(
                tempallservices.filter(
                  (f) =>
                    providerServices2.servIds.includes(f.serviceId) &&
                    providerServices2.locationIds.includes(f.locationId)
                )
              );
            }
          }
        }
      } else {
        // throw new Error("orgID is empty");
        console.log("orgID is empty");
      }
    };
    if (!user.is211Admin) {
      getProviderOrgInfo();
    }
  }, [outboundItems]);

  useEffect(() => {
    const shouldRequestFeedback = async () => {
      const response = await apiService.get("/providerstaff/feedback/request");

      if (response && response.requestFeedback) {
        updateUser({ ...user, requestFeedback: true });
      } else {
        updateUser({ ...user, requestFeedback: false });
      }
    };

    let isMounted = true;
    if (isMounted && user && user?.isSuper && feedbackCms?.enabled) {
      updateUser({ ...user, requestFeedback: true });
    } else if (isMounted && user && user?.id && feedbackCms?.enabled) {
      shouldRequestFeedback();
    } else {
      updateUser({ ...user, requestFeedback: false });
    }

    return () => {
      isMounted = false;
    };
  }, [apiService, feedbackCms]);

  const formatAssignedTo = (referral) => {
    if (
      referral?.assignedTo &&
      referral?.assignedTo.toLowerCase() !== "undefined" &&
      referral?.assignedTo.toLowerCase() !== "unassigned"
    ) {
      return referral?.assignedTo;
    } else {
      return "";
    }
  };

  const get211ProviderInfo = async () => {
    setLoading(true);

    try {
      let requesterRefs = await apiService.get(
        `/referrals?assumedId=${user.superAssumedRole?.leadId || ""}`
      );
      if (requesterRefs.referrals) {
        requesterRefs = getLocalTime(requesterRefs.referrals);
        let oItems = requesterRefs;
        const open = rOpenStatuses.map((s) => s.id);
        oItems = oItems.filter((item) =>
          open.includes(item?.status?.toLowerCase())
        );
        setOutboundItems(
          oItems?.sort((a, b) => {
            return b.id - a.id;
          })
        );
        setLoading(false);
        setOutboundItemsAll(
          requesterRefs.sort((a, b) => {
            return b.id - a.id;
          })
        );
        setReferrals(
          requesterRefs.sort((a, b) => {
            return b.id - a.id;
          })
        );
        setReferrals(
          requesterRefs.sort((a, b) => {
            return b.id - a.id;
          })
        );
        const servicesArray = [
          ...new Set(requesterRefs.map((s) => s.serviceId)),
        ];
        setAllServices(servicesArray);
      }
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  };

  const getProviderInfo = async () => {
    setLoading(true);
    try {
      let receivedAllRefs = await apiService.get(
        `/referrals?assumedId=${user.superAssumedRole?.leadId || ""}`
      );
      let receivedRefs = receivedAllRefs.referralsByService;
      let requesterRefs = receivedAllRefs.referralsByRequester;
      receivedRefs = receivedRefs?.sort((a, b) => {
        return b.id - a.id;
      });
      //update to local time
      receivedRefs = getLocalTime(receivedRefs);
      requesterRefs = getLocalTime(requesterRefs);

      setReferrals(receivedRefs);

      // initial state is filtered for OPEN referrals only
      let dItems = receivedRefs;
      const open = rOpenStatuses.map((s) => s.id);
      dItems = dItems.filter((item) =>
        open.includes(item?.status?.toLowerCase())
      );
      setDisplayedItems(dItems);
      if (requesterRefs) {
        let oItems = requesterRefs;
        const open = rOpenStatuses.map((s) => s.id);
        oItems = oItems.filter((item) =>
          open.includes(item?.status?.toLowerCase())
        );
        setOutboundItems(
          oItems?.sort((a, b) => {
            return b.id - a.id;
          })
        );
        setOutboundItemsAll(
          requesterRefs?.sort((a, b) => {
            return b.id - a.id;
          })
        );
      }
      setLoading(false);
    } catch (err) {
      console.log(err);
    }
  };

  const getAllReferrals = async () => {
    setLoading(true);
    try {
      // Pulling all referrals in the system
      let providerRefs = await apiService.get(`/referrals`);
      if (providerRefs.referrals) {
        let allRefs = getLocalTime(providerRefs.referrals);
        // TODO potentially complete on backend
        allRefs.sort((a, b) => {
          return b.id - a.id;
        });

        setDisplayedItems(allRefs);
        setReferrals(allRefs);
      }
      setLoading(false);
    } catch (err) {
      console.log(err);
    }
  };

  // For Responsiveness
  const [width, setWidth] = useState(window.innerWidth);
  const isMobile = 900;
  const filterOnly = 500;
  useEffect(() => {
    const handleResizeWindow = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleResizeWindow);
    return () => {
      window.removeEventListener("resize", handleResizeWindow);
    };
  }, []);

  const addLabel = (
    color,
    refId,
    clientId,
    setMockDataState,
    setDisplayedItems,
    referrals
  ) => {
    const currLabels = [...labels];
    currLabels.push({ id: refId, clientId: clientId, label: color });
    setLabels(currLabels);
    addLabelToDb(clientId, refId, color);
  };

  const updateLabel = (
    color,
    refId,
    clientId,
    setMockDataState,
    setDisplayedItems,
    referrals
  ) => {
    const currLabels = [...labels];
    currLabels.map((item) => {
      if (item.id === refId) {
        item.label = color;
      }
    });
    setLabels(currLabels);
    changeLabelToDb(clientId, refId, color);
  };

  const removeLabel = (
    color,
    refId,
    clientId,
    setMockDataState,
    setDisplayedItems,
    referrals
  ) => {
    let currLabels = [...labels];
    currLabels = currLabels.filter((item) => item.id !== refId);
    setLabels(currLabels);
    removeLabelFromDb(clientId, refId, color);
  };

  const addLabelToDb = async (clientId, referralId, status) => {
    await apiService.post(
      `/labels/referrals?assumedId=${user.superAssumedRole?.leadId || ""}`,
      {
        clientId: clientId,
        referralId: referralId,
        status: status,
      }
    );
  };

  const changeLabelToDb = async (clientId, referralId, status) => {
    await apiService.put(
      `/labels/referrals?assumedId=${user.superAssumedRole?.leadId || ""}`,
      {
        clientId: clientId,
        referralId: referralId,
        status: status,
      }
    );
  };

  const removeLabelFromDb = async (clientId, referralId, status) => {
    await apiService.delete(
      `/labels/referrals?referralId=${referralId}&clientId=${clientId}&assumedId=${
        user.superAssumedRole?.leadId || ""
      }`
    );
  };

  const updateAssignment = async (assignedTo, assignedToId, referral) => {
    try {
      const data = await apiService.put(`/referrals/assignment?assumedId=${user.superAssumedRole?.leadId || ""}`, {
        patientId: referral?.referreeId,
        serviceId: referral?.serviceId,
        originatorId: referral?.requesterId,
        assignedTo,
        assignedToId,
        status: referral.status,
        referralId: referral?.id,
      });
      if (data.ok) {
        let Mdata = [...referrals];
        Mdata = Mdata.map((item) => {
          if (item.id === referral?.id) {
            return { ...item, assignedTo: assignedTo };
          } else {
            return item;
          }
        });
        updateDate(
          dateStart,
          dateEnd,
          setDisplayedItems,
          Mdata,
          selectServices,
          assignStatuses,
          referStatuses,
          filterBookmarked,
          parentRefers
        );
      }
    } catch (err) {
      console.log(err);
    }
  };

  const renderStatus = (row) => {
    if (
      row?.status.toLowerCase().includes("close ") &&
      row?.status.toLowerCase().includes("client")
    ) {
      if (row?.referree && !user.isViewOnly) {
        return "Closed - " + row?.referree + " Declined";
      }
      return "Client Declined";
    } else if (row?.status.toLowerCase().includes("rejected by receiver")) {
      return "Reciever Rejected";
    } else if (row?.status.toLowerCase().includes("waiting for client")) {
      return "Waiting for " + row?.referree;
    } else if (row?.status.toLowerCase().includes("rejected")) {
      if (row?.referree && !user.isViewOnly) {
        return row?.referree + " Rejected";
      }
      return "Client Rejected";
    } else if (row?.status.toLowerCase().includes("canceled")) {
      if (row?.requester && !user.isViewOnly) {
        return row.requester + " Canceled";
      }
      return "Referrer Canceled";
    } else if (row?.status.toLowerCase().includes("pending closure")) {
      return "Pending Closure";
    } else if (row?.status.toLowerCase().includes("accepted")) {
      if (row?.referree && !user.isViewOnly) {
        return row?.referree + " Accepted";
      }
      return "Client Accepted";
    } else if (
      row?.status.toLowerCase().includes("working") ||
      row?.status.toLowerCase().includes("reopen")
    ) {
      return "Working";
    } else {
      return row.status;
    }
  };

  const getPaginationTotalItems = (displayedItemsLength, itemsPerPage) => {
    if (displayedItemsLength % itemsPerPage === 0) {
      return (displayedItemsLength / itemsPerPage);
    } else {
      return Math.floor(displayedItemsLength / itemsPerPage) + 1;
    }
  }

  const renderAssignmentCell = (incomingRow) => {
    const row = incomingRow?.item ? incomingRow.item : incomingRow;
    let isServiceLead = false;
    if (
      serviceLeadArray.length > 0 &&
      serviceLeadArray.find(
        (s) =>
          String(s.serviceId) === String(row.serviceId) &&
          String(s.locationId) === String(row.locationId)
      )?.isLead === true
    ) {
      isServiceLead = true;
    }

    if (
      row?.assignedTo &&
      row?.assignedTo?.toLowerCase() !== "undefined" &&
      row?.assignedTo?.toLowerCase() !== "unassigned"
    ) {
      if (user.isViewOnly) {
        return (
          <TableCell style={{ cursor: "default" }}>
            Assigned
            <br />
            <TableSubLabel style={{ cursor: "default" }}>
              {row?.assignedTo}
            </TableSubLabel>
          </TableCell>
        );
      } else if (user.isAdmin || isServiceLead || user.isSuper) {
        // only open modal for reassignment on org's services
        if (
          row?.status?.toLowerCase()?.includes("working") ||
          row?.status?.toLowerCase()?.includes("reopen") ||
          row?.status?.toLowerCase()?.includes("waiting for client")
        ) {
          return (
            <TableCell style={{ cursor: "pointer" }}>
              Assigned
              <br />
              <TableSubLabel
                onClick={() => {
                  setReassigned(true);
                  setReferralModalOpen(true);
                  setReferralAssigned(row);
                }}
              >
                {row?.assignedTo}
              </TableSubLabel>
            </TableCell>
          );
        } else {
          return (
            <TableCell>
              Assigned
              <br />
              <TableSubLabel>{row?.assignedTo}</TableSubLabel>
            </TableCell>
          );
        }
      } else {
        return (
          <TableCell style={{ cursor: "default" }}>
            Assigned
            <br />
            <TableSubLabel style={{ cursor: "default" }}>
              {row?.assignedTo}
            </TableSubLabel>
          </TableCell>
        );
      }
    } else {
      if (user.isViewOnly) {
        return <TableCell>Unassigned</TableCell>;
      } else if (user.isAdmin || isServiceLead || user.isSuper) {
        if (
          row?.status?.toLowerCase()?.includes("working") ||
          row?.status?.toLowerCase()?.includes("reopen") ||
          row?.status?.toLowerCase()?.includes("waiting for client")
        ) {
          return (
            <TableCell style={{ cursor: "pointer" }}>
              Unassigned
              <br />
              <TableSubLabel
                onClick={() => {
                  setReassigned(false);
                  setReferralModalOpen(true);
                  setReferralAssigned(row);
                }}
              >
                Assign referral
                <StyledFlag />
              </TableSubLabel>
            </TableCell>
          );
        } else {
          return <TableCell>Unassigned</TableCell>;
        }
      } else {
        if (
          row?.status?.toLowerCase()?.includes("working") ||
          row?.status?.toLowerCase()?.includes("reopen") ||
          row?.status?.toLowerCase()?.includes("waiting for client")
        ) {
          return (
            <TableCell style={{ cursor: "pointer" }}>
              Unassigned
              <br />
              <TableSubLabel
                onClick={() => {
                  const Mdata = [...referrals];
                  Mdata.map((item) => {
                    if (item?.id === referralAssigned?.id) {
                      item.assignedTo = user.aliasName || user.fullName;
                      item.assignedToId = user.id;
                    }
                  });
                  setReferrals(Mdata);
                  updateDate(
                    dateStart,
                    dateEnd,
                    setDisplayedItems,
                    Mdata,
                    selectServices,
                    assignStatuses,
                    referStatuses,
                    filterBookmarked,
                    parentRefers
                  );
                  setReassigned(false);
                  setReferralAssigned(row);
                  let userId = user.superAssumedRole?.leadId || user.id;
                  let userName =
                    user.superAssumedRole?.leadName || user.fullName;
                  updateAssignment(userName, userId, row);
                }}
              >
                Claim referral
                <StyledFlag />
              </TableSubLabel>
            </TableCell>
          );
        } else {
          return <TableCell>Unassigned</TableCell>;
        }
      }
    }
  };

  const renderTab = (tab) => {
    if (user.isSuper) {
      tab = "My Services";
    }
    switch (tab) {
      case "My Services":
        return (
          <PageContainer>
            <OverviewCards
              referrals={referrals}
              mockData={displayedItems}
              displayedItems={displayedItems}
              setDisplayedItems={setDisplayedItems}
              totalReferrals={referrals.length}
              myAssignments={
                user.superAssumedRole?.leadId ? 
                (referrals.filter((f) => f?.assignedTo === user.name).length)
                : (referrals.filter((f) => f?.assignedTo === user.fullName).length)
              }
              unassigned={
                referrals.filter(
                  (f) =>
                    !f?.assignedTo ||
                    f?.assignedTo === "undefined" ||
                    f?.assignedTo === "Undefined" ||
                    f?.assignedTo === " " ||
                    f?.assignedTo === "" ||
                    f?.assignedTo?.toLowerCase() === "unassigned"
                ).length
              }
              averageAge={
                avgAge(referrals) === Infinity ? "--" : avgAge(referrals)
              }
              completed={getCompletedReferrals(referrals)?.length}
              dateStart={dateStart}
              dateEnd={dateEnd}
              selectServices={selectServices}
              assignStatuses={assignStatuses}
              referStatuses={referStatuses}
              filterBookmarked={filterBookmarked}
              parentRefers={parentRefers}
            />
            <CardDiv>
              <DataTable
                rows={TobiData}
                headers={headers}
                // isSortable
              >
                {({
                  headers,
                  rows,
                  getRowProps,
                  getTableProps,
                  getHeaderProps,
                  onInputChange,
                }) => (
                  <StyledTableContainer loading={loading} referrals={referrals}>
                    <TableToolbar
                      style={{
                        marginBottom: "1rem",
                        backgroundColor: "white",
                      }}
                    >
                      <TableToolbarContent style={{ height: "100%" }}>
                        <ToolbarInner>
                          <DashboardFilters
                            user={user}
                            allServices={allServices}
                            setSelectServices={setSelectServices}
                            displayedItems={displayedItems}
                            setDisplayedItems={setDisplayedItems}
                            referrals={referrals}
                            selectServices={selectServices}
                            assignStatuses={assignStatuses}
                            setAssignStatuses={setAssignStatuses}
                            referStatuses={referStatuses}
                            filterBookmarked={filterBookmarked}
                            setFilterBookmarked={setFilterBookmarked}
                            astatuses={aStatuses}
                            rstatuses={rStatuses}
                            downloadHeaders={downloadHeaders}
                            displayedItemsDownload={displayedItemsDownload}
                            width={width}
                            filterOnly={filterOnly}
                            parentRefers={parentRefers}
                            setParentRefers={setParentRefers}
                            dateStart={dateStart}
                            setDateStart={setDateStart}
                            dateEnd={dateEnd}
                            setDateEnd={setDateEnd}
                            paginationSetCurrentPage={setCurrentPage}
                            setPageMin={setPageMin}
                            setPageMax={setPageMax}
                            paginationItemsPerPage={paginationItemsPerPage}
                            tab={tab}
                          />
                        </ToolbarInner>
                      </TableToolbarContent>
                    </TableToolbar>
                    <Table {...getTableProps()}>
                      <StyledTableHead>
                        {myHeaders.map((header) => {
                          return (
                            <TableHeader
                              style={{ display: "visible" }}
                              {...getHeaderProps({
                                header,
                              })}
                              onMouseDown={(e) => {
                                e.preventDefault();
                                sortHeader(
                                  header,
                                  setDisplayedItems,
                                  sortHeaderState,
                                  sortHeaderDirection,
                                  setSortHeaderDirection,
                                  setSortHeaderState,
                                  referrals,
                                  labels,
                                  selectServices,
                                  assignStatuses,
                                  referStatuses,
                                  filterBookmarked,
                                  parentRefers,
                                  dateStart,
                                  dateEnd
                                );
                              }}
                              isSortable={header.isSortable}
                            >
                              {header.header === "Client" ? (
                                <div
                                  onMouseEnter={() => setOpenTooltip(true)}
                                  onMouseLeave={() => setOpenTooltip(false)}
                                >
                                  {header.header}
                                  <Tooltip
                                    open={openTooltip}
                                    direction="bottom"
                                  >
                                    <p>
                                      Client data is not available until the
                                      client is on your care team and the
                                      referral is in working status.
                                    </p>
                                  </Tooltip>
                                </div>
                              ) : (
                                <>{header.header}</>
                              )}
                            </TableHeader>
                          );
                        })}
                      </StyledTableHead>
                      <TableBody>
                        {displayedItems
                          ?.slice(pageMin, pageMax)
                          .map((row, i) => {
                            if (row) {
                              return (
                                <StyledTableRow
                                  key={i}
                                  {...getRowProps({ row })}
                                  theme={{
                                    bcolor: ageColor({
                                      rowDate: row.authoredOn,
                                      rowStatus: row.status,
                                    }),
                                  }}
                                  style={{ cursor: "default" }}
                                >
                                  <TableCell style={{ paddingRight: "0rem" }}>
                                    {row?.status?.includes(
                                      "pending closure"
                                    ) && (
                                      <div
                                        onMouseEnter={() =>
                                          setOpenExpireTooltip(row.id)
                                        }
                                        onMouseLeave={() =>
                                          setOpenExpireTooltip("")
                                        }
                                      >
                                        <WarningAltFilled16
                                          style={{ color: "red" }}
                                        />
                                        <Tooltip
                                          open={openExpireTooltip === row.id}
                                          direction="bottom"
                                          showIcon={false}
                                          align="start"
                                        >
                                          <p>
                                            Pending closure. This referral will
                                            close in 24 hours if not reopened by
                                            the provider.
                                          </p>
                                        </Tooltip>
                                      </div>
                                    )}
                                    {row?.status?.toLowerCase()?.includes("about to expire") && (
                                      <div
                                        onMouseEnter={() =>
                                          setOpenExpireTooltip(row.id)
                                        }
                                        onMouseLeave={() =>
                                          setOpenExpireTooltip("")
                                        }
                                      >
                                        <WarningAltFilled16
                                          style={{ color: "red" }}
                                        />
                                        <Tooltip
                                          open={openExpireTooltip === row.id}
                                          direction="bottom"
                                          showIcon={false}
                                          align="start"
                                        >
                                          <p>
                                            About to expire. Referrals expire in
                                            30 days if not accepted by client
                                            and provider.
                                          </p>
                                        </Tooltip>
                                      </div>
                                    )}
                                  </TableCell>
                                  {user?.isViewOnly ? (
                                    <TableCell style={{ cursor: "pointer" }}>
                                      {row?.id}
                                    </TableCell>
                                  ) : (
                                    <TableCell
                                      style={{ cursor: "pointer" }}
                                      onClick={(e) => {
                                        const clientID = row?.referreeId;
                                        history.push({
                                          pathname:
                                            "/referral-dashboard/referral",
                                          state: {
                                            clientID,
                                            referralId: row?.id,
                                            referral: row,
                                            cameFrom: "referral-dashboard",
                                            cameFromTab: "My Services",
                                            onCareTeam: row?.isOnTeam,
                                          },
                                        });
                                      }}
                                    >
                                      <a>{row?.id}</a>
                                    </TableCell>
                                  )}
                                  <TableCell>{row?.authoredOn}</TableCell>
                                  {row?.isOnTeam || user.isSuper ? (
                                    <TableCell
                                      style={{ cursor: "pointer" }}
                                      onClick={(e) => {
                                        const clientID = row?.referreeId;
                                        history.push({
                                          pathname: "/360dashboard",
                                          state: {
                                            client: clientID,
                                            clientId: clientID,
                                            onCareTeam: true,
                                          },
                                        });
                                      }}
                                    >
                                      <a>{row?.referree}</a>
                                    </TableCell>
                                  ) : (
                                    <TableCell>{row?.referree}</TableCell>
                                  )}
                                  <TableCell style={{ cursor: "default" }}>
                                    {row?.name}
                                    <br />
                                    <TableSubLabel
                                      style={{ cursor: "default" }}
                                    >
                                      {row?.orgName}
                                    </TableSubLabel>
                                  </TableCell>
                                  {renderAssignmentCell(row)}
                                  <TableCell>
                                    {loadingInline === row.id ? (
                                      <InlineLoading />
                                    ) : (
                                      getDisplayedStatus(
                                        row.status,
                                        row.referree,
                                        user.isViewOnly
                                      )
                                    )}
                                    <br />
                                    {!user.isViewOnly && (
                                      <TableSubLabel
                                        onClick={(e) => e.preventDefault()}
                                      >
                                        <Flex style={{ alignItems: "center" }}>
                                          {renderOverflowOptions(
                                            row,
                                            user,
                                            setMockDataState,
                                            displayedItems,
                                            setDisplayedItems,
                                            apiService,
                                            serviceLeadArray,
                                            referrals,
                                            loadingInline,
                                            setLoadingInline,
                                            setNoteModalOpen,
                                            setTempStatus,
                                            setReferralClosed,
                                            setReferralActivityInactive,
                                            setReferralActivitiesInactive,
                                            dateStart,
                                            dateEnd,
                                            selectServices,
                                            assignStatuses,
                                            referStatuses,
                                            filterBookmarked,
                                            parentRefers
                                          )}
                                        </Flex>
                                      </TableSubLabel>
                                    )}
                                  </TableCell>
                                  <TableCell style={{ cursor: "default" }}>
                                    {row?.requester}
                                    <br />
                                    <TableSubLabel
                                      style={{ cursor: "default" }}
                                    >
                                      {row?.requesterOrg}
                                    </TableSubLabel>
                                  </TableCell>
                                  {labels?.find((l) => l.id === row.id) ? (
                                    <TableCell>
                                      <div
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                          marginTop: "5px",
                                        }}
                                      >
                                        {
                                          labels.find((l) => l.id === row.id)
                                            .label
                                        }
                                        <StyledCircle
                                          theme={{
                                            color: labels
                                              .find((l) => l.id === row.id)
                                              .label.toLowerCase(),
                                          }}
                                        />
                                      </div>
                                      {/* <br /> */}
                                      <TableSubLabel>
                                        <Flex style={{ alignItems: "center" }}>
                                          {width > isMobile
                                            ? "Edit Label"
                                            : "Edit"}
                                          <StyledOverflowMenu
                                            renderIcon={StyledEdit}
                                            flipped
                                            size="sm"
                                            data-floating-menu-container
                                          >
                                            <OverflowMenuItem
                                              onClick={(e) =>
                                                updateLabel(
                                                  "Blue",
                                                  row.id,
                                                  row?.referreeId,
                                                  setMockDataState,
                                                  setDisplayedItems,
                                                  referrals
                                                )
                                              }
                                              itemText="Blue"
                                            />
                                            <OverflowMenuItem
                                              onClick={(e) =>
                                                updateLabel(
                                                  "Orange",
                                                  row.id,
                                                  row?.referreeId,
                                                  setMockDataState,
                                                  setDisplayedItems,
                                                  referrals
                                                )
                                              }
                                              itemText="Orange"
                                            />
                                            <OverflowMenuItem
                                              onClick={(e) =>
                                                updateLabel(
                                                  "Purple",
                                                  row.id,
                                                  row?.referreeId,
                                                  setMockDataState,
                                                  setDisplayedItems,
                                                  referrals
                                                )
                                              }
                                              itemText="Purple"
                                            />
                                            <OverflowMenuItem
                                              onClick={(e) =>
                                                removeLabel(
                                                  "Purple",
                                                  row.id,
                                                  row?.referreeId,
                                                  setMockDataState,
                                                  setDisplayedItems,
                                                  referrals
                                                )
                                              }
                                              itemText="Remove label"
                                            />
                                          </StyledOverflowMenu>
                                        </Flex>
                                      </TableSubLabel>
                                    </TableCell>
                                  ) : (
                                    <TableCell>
                                      <Flex style={{ alignItems: "center" }}>
                                        Add a label
                                        <OverflowMenu
                                          renderIcon={StyledAdd}
                                          flipped
                                          size="sm"
                                          data-floating-menu-container
                                        >
                                          <OverflowMenuItem
                                            onClick={(e) =>
                                              addLabel(
                                                "Blue",
                                                row.id,
                                                row?.referreeId,
                                                setMockDataState,
                                                setDisplayedItems,
                                                referrals
                                              )
                                            }
                                            itemText="Blue"
                                          />
                                          <OverflowMenuItem
                                            onClick={(e) =>
                                              addLabel(
                                                "Orange",
                                                row.id,
                                                row?.referreeId,
                                                setMockDataState,
                                                setDisplayedItems,
                                                referrals
                                              )
                                            }
                                            itemText="Orange"
                                          />
                                          <OverflowMenuItem
                                            onClick={(e) =>
                                              addLabel(
                                                "Purple",
                                                row.id,
                                                row?.referreeId,
                                                setMockDataState,
                                                setDisplayedItems,
                                                referrals
                                              )
                                            }
                                            itemText="Purple"
                                          />
                                        </OverflowMenu>
                                      </Flex>
                                    </TableCell>
                                  )}
                                </StyledTableRow>
                              );
                            }
                          })}
                      </TableBody>
                    </Table>
                  </StyledTableContainer>
                )}
              </DataTable>
              <FlexCenter>
                <PaginationNav
                  page={currentPage}
                  itemsShown={width < isMobile ? 4 : 10}
                  totalItems={getPaginationTotalItems(displayedItems.length, paginationItemsPerPage)}
                  onChange={(e) => {
                    setPageMin(e * paginationItemsPerPage);
                    setPageMax(e * paginationItemsPerPage + paginationItemsPerPage);
                    setCurrentPage(e);
                  }}
                />
              </FlexCenter>
            </CardDiv>

            <ReferralFilterModal
              referrals={referrals}
              allServices={services}
              modalOpen={openFilterModal}
              setModalOpen={setFilterModalOpen}
              displayedItems={displayedItems}
              setDisplayedItems={setDisplayedItems}
              mockData={mockDataState}
              assignStatuses={assignStatuses}
              setAssignStatuses={setAssignStatuses}
              referStatuses={referStatuses}
              setReferStatuses={setReferStatuses}
              selectServices={selectServices}
              setSelectServices={setSelectServices}
              filterBookmarked={filterBookmarked}
            />
            <AssignReferralModal
              reassigned={reassigned}
              modalOpen={referralModalOpen}
              setModalOpen={setReferralModalOpen}
              displayedItems={displayedItems}
              setDisplayedItems={setDisplayedItems}
              referrals={referrals}
              setReferrals={setReferrals}
              referralAssigned={referralAssigned}
              updateAssignment={updateAssignment}
              dateStart={dateStart}
              dateEnd={dateEnd}
              selectServices={selectServices}
              assignStatuses={assignStatuses}
              referStatuses={referStatuses}
              filterBookmarked={filterBookmarked}
              parentRefers={parentRefers}
            />
            <CloseReasonModal
              modalOpen={noteModalOpen}
              setModalOpen={setNoteModalOpen}
              setDisplayedItems={setDisplayedItems}
              referrals={referrals}
              tempStatus={tempStatus}
              setTempStatus={setTempStatus}
              referralClosed={referralClosed}
              setLoadingInline={setLoadingInline}
              dateStart={dateStart}
              dateEnd={dateEnd}
              selectServices={selectServices}
              assignStatuses={assignStatuses}
              referStatuses={referStatuses}
              filterBookmarked={filterBookmarked}
              parentRefers={parentRefers}
            />
          </PageContainer>
        );
      case "Outbound":
        return (
          <PageContainer>
            <OutboundReferrals
              renderStatus={renderStatus}
              outboundItems={outboundItems}
              outboundItemsAll={outboundItemsAll}
              outboundItemsDownload={outboundItemsDownload}
              setOutboundItems={setOutboundItems}
              setOutboundItemsAll={setOutboundItemsAll}
              width={width}
              tab={tab}
            />
          </PageContainer>
        );
      case "My Organization":
        if (user.is211Admin) {
          return (
            <PageContainer>
              <OrganizationStats211
                referrals={referrals}
                loadingOrg={loading}
                orgStats={orgStats}
                width={width}
                allServices={allServices}
              />
            </PageContainer>
          );
        } else {
          return (
            <PageContainer>
              <OrganizationStats
                referrals={referrals}
                loadingOrg={loading}
                orgStats={orgStats}
                width={width}
                allServices={allServices}
              />
            </PageContainer>
          );
        }
      case "Call Logs":
        return (
          <PageContainer>
            <CallLogTab />
          </PageContainer>
        );
      default:
        return <PageContainer></PageContainer>;
    }
  };
  const renderViewOnlyTab = (tab) => {
    switch (tab) {
      case "My Services":
        return (
          <PageContainer>
            <OutboundReferrals
              renderStatus={renderStatus}
              outboundItems={displayedItems}
              outboundItemsAll={referrals}
              outboundItemsDownload={outboundItemsDownload}
              setOutboundItems={setDisplayedItems}
              setOutboundItemsAll={setReferrals}
              width={width}
              tab={tab}
            />
          </PageContainer>
        );
      case "Outbound":
        return (
          <PageContainer>
            <OutboundReferrals
              renderStatus={renderStatus}
              outboundItems={outboundItems}
              outboundItemsAll={outboundItemsAll}
              outboundItemsDownload={outboundItemsDownload}
              setOutboundItems={setOutboundItems}
              setOutboundItemsAll={setOutboundItemsAll}
              width={width}
              tab={tab}
            />
          </PageContainer>
        );
      case "My Organization":
        if (user.is211Admin) {
          return (
            <PageContainer>
              <OrganizationStats211
                referrals={referrals}
                loadingOrg={loading}
                orgStats={orgStats}
                width={width}
                allServices={allServices}
              />
            </PageContainer>
          );
        } else {
          return (
            <PageContainer>
              <OrganizationStats
                referrals={referrals}
                loadingOrg={loading}
                orgStats={orgStats}
                width={width}
                allServices={allServices}
              />
            </PageContainer>
          );
        }
      default:
        return <PageContainer />;
    }
  };

  let myHeaders = headers;
  return (
    <>
      {loading && (
        <Loading description="Active loading indicator" withOverlay={true} />
      )}
      <DashboardHeaderContainer
        user={user}
        tab={tab}
        setTab={setTab}
        title={user.is211Admin ? "Dashboard" : "Referral Dashboard"}
        backgroundColor="white"
      />
      {!user.isViewOnly ? renderTab(tab) : renderViewOnlyTab(tab)}
    </>
  );
};

export default ReferralDashboardPage;
