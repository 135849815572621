import React, { useState, useEffect, useContext } from "react";
import ReactMapboxGL, {
  ZoomControl,
  ScaleControl,
  Feature,
  Image,
  Layer,
  Popup,
} from "react-mapbox-gl";
import mapboxgl from "!mapbox-gl"; // eslint-disable-line import/no-webpack-loader-syntax
import { CheckmarkOutline16 } from '@carbon/icons-react';
import { Tooltip } from 'carbon-components-react';
import BusStopToggle from '../../../components/BusStopToggle';
import { CapacityBadge } from '../../../components/Badges/v2';
import { SearchContext } from '../../resources/context/SearchResultsContext.context';
import { constructOrgOverviewUrl, constructServiceOverviewUrl } from '../../../utils';
import { BusStopsContext } from "../../../store/BusStopsContext";

import S from "../elasticsearch.module.scss";

const LONG = process.env.REACT_APP_MAP_CENTER_LONG && -77.6202131;
const LAT = process.env.REACT_APP_MAP_CENTER_LAT && 42.9179801;

const MapComponent = React.memo(ReactMapboxGL({
  accessToken: process.env.REACT_APP_MAPBOX_GL_TOKEN
}));

const LocationIcons = ({ providers = [] }) => {
  const {
    selectProvider,
    selectedProvider,
    iconHoverColor,
    setIconHovered,
    setIconHoverColor
  } = useContext(SearchContext);
  const [display, setDisplay] = useState(null);

  //mouse events
  const select = (prov) => {
    selectProvider(prov);
    setIconHovered();
  };

  const handleMouseEnter = (e, prov) => {
    e.map.getCanvas().style.cursor = "pointer";
    if (!selectedProvider) setIconHoverColor(prov?.locationId);
  };

  const handleMouseLeave = (e) => {
    e.map.getCanvas().style.cursor = "grab";
    if (!selectedProvider) setIconHoverColor(0);
  };

  //create features
  useEffect(() => {
    const searchResultFeatures = () => {
      const features = [];
      try {
        if (providers && providers?.length > 0) {
          providers.forEach((provider, index) => {
            // only add feature if not a confidential address
            if (!provider.loc_confidential_address && provider.longitude && provider.latitude) {
              const feature = (
                <Feature
                  key={Math.random()}
                  id={provider?.serviceAtLocationId + '-icon'}
                  properties={{ locId: provider.locationId }}
                  coordinates={[
                    provider.longitude,
                    provider.latitude,
                  ]}
                  style={{ cursor: "pointer" }}
                  onClick={() => select(provider)}
                  onMouseEnter={(e) => {
                    handleMouseEnter(e, provider);
                  }}
                  onMouseLeave={(e) => {
                    handleMouseLeave(e);
                  }}
                />
              );
              features.push(feature);
            }
          });
        } else if (selectedProvider) {
          if (!selectedProvider.loc_confidential_address && selectedProvider.longitude && selectedProvider.latitude) {
            features.push(
              <Feature
                key={Math.random()}
                properties={{ locId: selectedProvider.locationId }}
                coordinates={[
                  selectedProvider.longitude,
                  selectedProvider.latitude,
                ]}
                style={{ cursor: "pointer" }}
                onMouseEnter={(e) => {
                  handleMouseEnter(e, selectedProvider);
                }}
                onMouseLeave={(e) => {
                  handleMouseLeave(e);
                }}
              />
            );
          }
        }
      } catch (err) {
        console.log(err);
      }
      return features;
    };

    setDisplay(searchResultFeatures());
  }, [providers, selectedProvider]);
  return (
    <>
      <Image
        id={`Location-icon`}
        url={`/icons/Location-icon.png`}
        options={{ sdf: true }}
      />
      <Layer
        type="symbol"
        id={`Location-marker`}
        layout={{
          "icon-image": `Location-icon`,
          "icon-size": [
            "case",
            ["==", ["get", "locId"], iconHoverColor],
            1.4,
            0.9,
          ],
          "icon-allow-overlap": true,
        }}
        style={{ cursor: "pointer" }}
        paint={{
          "icon-color": [
            "case",
            ["==", ["get", "locId"], iconHoverColor],
            "red",
            "#005d83",
          ],
        }}
      >
        {display}
      </Layer>
    </>
  );
};

const BusStopsIcons = ({ busStops = [], setSelectedStop }) => {
  const [display, setDisplay] = useState();
  useEffect(() => {
    const createBusStopFeatures = () => {
      let features = [];
      busStops.forEach((stop, index) => {
        const feature = (
          <Feature
            key={Math.random()}
            coordinates={[stop.stop_lon, stop.stop_lat]}
            properties={{
              stopName: stop.stop_name,
              stopDesc: stop.stop_desc,
            }}
            onClick={() => {
              setSelectedStop(stop);
            }}
            onMouseEnter={(e) => {
              e.map.getCanvas().style.cursor = "pointer";
            }}
            onMouseLeave={(e) => {
              e.map.getCanvas().style.cursor = "";
            }}
          />
        );
        features.push(feature);
      });
      return features;
    };
    setDisplay(createBusStopFeatures());
  }, []);

  return (
    <>
      <Image id={`Bus-icon`} url={`/icons/bus-icon.png`} />
      <Layer
        type="symbol"
        id={`Bus-markers`}
        layout={{
          "icon-image": `Bus-icon`,
          "icon-size": 0.4,
          "icon-allow-overlap": false,
        }}
        style={{ cursor: "pointer" }}
      >
        {display}
      </Layer>
    </>
  );
};

const Map = ({ showMap = true, providers = [] }) => {
  const {
    mapState,
    selectedProvider,
    deselectProvider,
    showStops,
    setShowStops,
    selectedStop,
    setSelectedStop,
    iconHovered,
    setIconHoverColor
  } = useContext(SearchContext);
  const { busStops } = useContext(BusStopsContext);

  return (
    (showMap) && (
      <div className={S.mapContainer}>
        <MapComponent
          style="mapbox://styles/mapbox/streets-v9"
          className="mapbox"
          center={mapState.center}
          zoom={mapState.zoom}
          containerStyle={{
            width: "100%",
            height: "100%",
            margin: "0"
          }}
          onStyleLoad={(map) => {
            map.addControl(new mapboxgl.FullscreenControl());
            map.resize();
          }}
          onClick={(e) => {
            if (selectedProvider) {
              deselectProvider();
              setIconHoverColor(0);
            };
            if (selectedStop) setSelectedStop();
          }}
        >
          <ZoomControl position="top-left" />
          <ScaleControl measurement="mi" />
          {showStops && <BusStopsIcons busStops={busStops} setSelectedStop={setSelectedStop} />}
          <BusStopToggle value={showStops} setValue={setShowStops} />
          <LocationIcons providers={providers} />

          {(selectedProvider) && (
            <Popup
              coordinates={[
                selectedProvider
                  ? selectedProvider.longitude || LONG
                  : iconHovered
                  ? iconHovered.longitude || LONG
                  : LONG,
                selectedProvider
                  ? selectedProvider.latitude || LAT
                  : iconHovered
                  ? iconHovered.latitude || LAT
                  : LAT,
              ]}
              anchor="bottom"
              style={{ top: "-10px", maxWidth: '90%' }}
            >
              <div className={S.popupContent}>
                <div className={S.popupTopRow}>
                  <div className={S.popupTextWrapper}>
                    <div style={{ display: 'inline-flex', alignItems: 'center' }}>
                      <h4>
                        {selectedProvider
                          ? selectedProvider.name
                          : iconHovered
                          ? iconHovered.name
                          : ""}
                      </h4>
                      {selectedProvider.connected && (
                      <Tooltip direction='top' aria-label="Connected" renderIcon={CheckmarkOutline16}>Connected</Tooltip>
                    )}
                    </div>
                    <a
                      target="_blank"
                      rel="noreferrer noopener"
                      href={constructOrgOverviewUrl(selectedProvider?.orgId, selectedProvider?.orgSource)}
                      onClick={(e) => {
                        e.stopPropagation();
                      }}
                      >{selectedProvider
                        ? selectedProvider.orgName
                        : iconHovered
                        ? iconHovered.orgName
                        : ""}
                      </a>
                  </div>
                </div>
                <div className={S.popupBottomRow}>
                  <div className={S.popupAddressWrapper}>
                    <p>
                      {selectedProvider && (
                        <strong>
                          {selectedProvider.address
                            ? selectedProvider.address + ", "
                            : ""}
                          {selectedProvider.city
                            ? selectedProvider.city + ", "
                            : ""}
                          {selectedProvider.state
                            ? selectedProvider.state + ", "
                            : ""}
                          {selectedProvider.zip ? selectedProvider.zip : ""}
                        </strong>
                      )}
                      {iconHovered && (
                        <strong>
                          {iconHovered.address ? iconHovered.address + ", " : ""}
                          {iconHovered.city ? iconHovered.city + ", " : ""}
                          {iconHovered.state ? iconHovered.state + ", " : ""}
                          {iconHovered.zip ? iconHovered.zip : ""}
                        </strong>
                      )}
                    </p>
                  </div>
                  <div>
                    {selectedProvider.connected && selectedProvider.capacity && (
                      <CapacityBadge capacity={selectedProvider?.capacity} />
                    )}
                  </div>
                  <div className={S.popupViewMore}>
                    <a
                      target="_blank"
                      rel="noreferrer noopener"
                      href={constructServiceOverviewUrl(selectedProvider?.serviceAtLocationId || selectedProvider?.serviceAtLocationKey, selectedProvider?.orgSource)}
                      onClick={(e) => {
                        e.stopPropagation();
                      }}
                    >
                      View More
                    </a>
                  </div>
                </div>
              </div>
            </Popup>
          )}
          {showStops && selectedStop && (
            <Popup
              coordinates={[
                selectedStop.stop_lon || LONG,
                selectedStop.stop_lat || LAT,
              ]}
              anchor="bottom"
              style={{ top: "-10px" }}
            >
              <div className={S.popupContent}>
                <div className={S.popupTopRow}>
                <div className={S.popupTextWrapper}>
                    <p>
                      <strong>Stop Information:</strong>
                      <br /> Name: {selectedStop.stop_name}
                      <br /> Location: {selectedStop.stop_desc}
                    </p>
                  </div>
                </div>
              </div>
            </Popup>
          )}
        </MapComponent>
      </div>
    )
  );
};

export default React.memo(Map);
