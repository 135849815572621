import React, { useState, useEffect, useRef } from "react";
import { useReactToPrint } from "react-to-print";
import PrintSummary from "./print"; // ComponentToPrint
import { Button, TooltipDefinition } from "carbon-components-react";
import { Link } from "react-router-dom";
import ShareAssessmentModal from "./shareAssessment";
import Domain from "./icon/domain.icon";
import ProviderIcon from "./icon/provider.icon";
import * as S from "./summary.styled";
import { ChevronRight32 } from "@carbon/icons-react";
import { constructServiceOverviewUrl } from "../../../../utils";

const Summary = ({ assessment }) => {
  const [resources, setResources] = useState([]);
  const [good, setGood] = useState([]);
  const [bad, setBad] = useState([]);
  const [goodDomain, setGoodDomain] = useState([]);
  const [badDomain, setBadDomain] = useState([]);
  const [shareAssessment, setShareAssessments] = useState(false);
  const [summary, setSummary] = useState([]);
  const [questions, setQuestions] = useState([]);
  const [provColor, setProvColor] = useState([]);
  const [ans, setAns] = useState(0);

  // Reference used for react-to-print.
  const componentRef = useRef(null);
  // Handles printing for react-to-print.
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  useEffect(() => {
    setResources(assessment.recommendedResources.slice(0, 8));
    sortScore(assessment.scoreSummary);
    setSummary(assessment.scoreSummary);
    setQuestions(assessment.responses);
    sortDomains(assessment.responses);
  }, []);

  const sortScore = (summary) => {
    const items = ["overall", "health", "education", "economic", "safety"];
    items.forEach((item) => {
      if (summary[item] === undefined) {
        return;
      }
      if (summary[item] === "LIGHT_GREEN" || summary[item] === "GREEN") {
        setGood((state) => [...state, { icon: item, state: summary[item] }]);
      } else {
        setBad((state) => [...state, { icon: item, state: summary[item] }]);
      }
    });
  };

  const sortDomains = (responses) => {
    responses.forEach((response) => {
      setProvColor((state) => [...state, response]);
      if (response.answer <= 3 && response.answer != 0) {
        setBadDomain((state) => [...state, response]);
        setAns((state) => state + 1);
        return;
      }
      if (response.answer != 0) {
        setGoodDomain((state) => [...state, response]);
        setAns((state) => state + 1);
      }
    });
  };

  const colorBlindLegend = (status) => {
    if (status) {
      switch (status.toLowerCase()) {
        case "not ok":
          return <>1</>;
        case "ok":
          return <>2</>;
        case "good":
          return <>3</>;
        case "great":
          return <>4</>;
        case "excellent":
          return <>5</>;
        default:
          return <></>;
      }
    }
  };

  const legends = [
    {
      color: "#ec5c29",
      text: "Not OK"
    },
    {
      color: "#faa41a",
      text: "OK"
    },
    {
      color: "#f4db5f",
      text: "Good"
    },
    {
      color: "#4ac1e0",
      text: "Great"
    },
    {
      color: "#95c93d",
      text: "Excellent"
    }
  ];

  return (
    <S.ScrollContainer>
      <ShareAssessmentModal
        modalOpen={shareAssessment}
        setModalOpen={setShareAssessments}
        page={
          <PrintSummary
            summary={summary}
            recommendedResources={resources}
            questions={questions}
          />
        }
      />
      <PrintSummary
        ref={componentRef}
        summary={summary}
        recommendedResources={resources}
        questions={questions}
      />
      <S.Container>
      <S.LeftPanel>
          <S.LegendsContainer>
            <S.LegendsHeading>Legend</S.LegendsHeading>
            <S.LegendsContent>
              {legends.map((legend) => (
                <S.Legends>
                  <S.LegendCircle theme={{ bcolor: legend.color }}>
                    {colorBlindLegend(legend.text)}
                  </S.LegendCircle>                  
                  <S.LegendsText>{legend.text}</S.LegendsText>
                </S.Legends>
              ))}
            </S.LegendsContent>
          </S.LegendsContainer>
          {/* <S.Summary>
            <S.Heading>Check-in Summary</S.Heading>
            {good.length === 0 ? undefined : (
              <>
                <S.SummaryText>
                  You are doing really well in these areas:
                </S.SummaryText>
                <S.ResultContainer>
                  {good.map((el) => {
                    return <Icon name={el.icon} status={el.state} />;
                  })}
                </S.ResultContainer>
              </>
            )}
            {bad.length === 0 ? undefined : (
              <>
                <S.SummaryText>
                  It looks like you could use some support with:
                </S.SummaryText>
                <S.ResultContainer>
                  {bad.map((el) => {
                    return <Icon name={el.icon} status={el.state} />;
                  })}
                </S.ResultContainer>
              </>
            )}
            {(bad.length === 0) & (good.length === 0) ? (
              <h4 style={{ margin: "2rem 0" }}>No questions answered</h4>
            ) : undefined}
            <S.SummaryBtnGroup>
              <Button kind="ghost" onClick={handlePrint}>
                Print Results
              </Button>
              <Button
                kind="ghost"
                onClick={() => {
                  setShareAssessments(true);
                }}
              >
                Email Results
              </Button>
            </S.SummaryBtnGroup>
          </S.Summary> */}
          <S.Summary>
            <S.Heading>Check-in Domains</S.Heading>
            {goodDomain.length === 0 ? undefined : (
              <>
                <S.SummaryText>
                  You are doing really well in these domains:
                </S.SummaryText>
                <S.ResultContainer>
                  {goodDomain.filter(({ text }, index) =>
                    !goodDomain.map((o) => o.text).includes(text, index + 1)
                    ).map((el) => {
                      return <Domain name={el.text} status={el.answer} />;
                  })}
                </S.ResultContainer>
              </>
            )}
            {badDomain.length === 0 ? undefined : (
              <>
                <S.SummaryText>
                  It looks like you could use some support with:
                </S.SummaryText>
                <S.ResultContainer>
                {badDomain.filter(({ text }, index) =>
                  !badDomain.map((o) => o.text).includes(text, index + 1)
                  ).map((el) => {
                    return <Domain name={el.text} status={el.answer} />;
                  })}
                </S.ResultContainer>
              </>
            )}
            {(badDomain.length === 0) & (goodDomain.length === 0) ? (
              <h4 style={{ margin: "2rem 0" }}>No questions answered</h4>
            ) : undefined}
          </S.Summary>
        </S.LeftPanel>
        <S.Resources>
          <S.Header>
            <S.Heading>Recommendations</S.Heading>
            <S.Text>
              Based on your responses we found local service providers who can
              help you.
            </S.Text>
          </S.Header>
          <S.Content>
            {resources.map((provider) => {
              const getProv = provColor.find(
                (prov) =>
                  prov.text.split("").join("").toLowerCase() ===
                  provider.category.split("").join("").toLowerCase()
              );
              if (!legends[5 - getProv.answer]) return;
              return (
                <>
                  <S.Card>
                    <S.CardBody>
                      <S.TextContainer>
                        <TooltipDefinition
                          direction="bottom"
                          triggerClassName="provider-name"
                          tooltipText={provider.name}
                        >
                          <S.ProviderHeading className="provider-name">
                            {provider.name}
                          </S.ProviderHeading>
                        </TooltipDefinition>

                        {/* <p>{translatedDomain}</p> */}
                        <S.CardAddressWrapper>
                          <S.CardStreetText>
                            {provider.address || ""}
                          </S.CardStreetText>
                          <br />
                          <S.CardCityStateText>
                            {provider.city + ", " + provider.state}
                          </S.CardCityStateText>
                        </S.CardAddressWrapper>
                      </S.TextContainer>
                      <ProviderIcon name={provider.category} provider={getProv} withDomain />
                    </S.CardBody>
                    <Button
                      kind="tertiary"
                      as={Link}
                      to={constructServiceOverviewUrl(provider.serviceLocationId, provider.orgSource || "211")}
                      style={{ width: "fit-content" }}
                    >
                      {"View Provider"}
                    </Button>
                  </S.Card>
                </>
              );
            })}
          </S.Content>
          <Button
            style={{ margin: "2rem 0 0" }}
            as={Link}
            to={{
              pathname: `/service-directory`,
              state: { resources: resources }
            }}
            renderIcon={ChevronRight32}
          >
            {"View All Resources"}
          </Button>
        </S.Resources>
      </S.Container>
    </S.ScrollContainer>
  );
};

export default Summary;
