import styled from "styled-components";
import { Button, TextInput } from "carbon-components-react";

export const ResetContainer = styled.div`
  display: flex;
  z-index: 2;
  height: 100%;
  width: 100%;
  justify-content: center;
  @media (max-width: 768px) {
    flex-direction: column;
    margin: 0rem;
    width: 100%;
    height: 100%;
    justify-content: center;
  }
`;

export const ResetTitleBox = styled.div`
  background-image: url(${props => props.bgImage || "login.jpg"});
  background-repeat: no-repeat;
  background-size: cover;
  color: #c69214;
  width: 100%;
  padding: 2em;
  font-family: IBMPlexSans-Bold;
  position: relative;
  @media (max-width: 768px) {
    display: none;
  }
`;

export const ResetFormBox = styled.div`
  order: 0;
  background-color: #161616;
  color: black;
  width: 100%;
  height: 100%;
  padding: 2em;
  @media (max-width: 768px) {
    height: 100%;
  }
`;

export const ResetFormContainer = styled.div`
  margin: 1em 0.5em;
  min-width: 250px;
  display: flex;
  flex-direction: column;
  overflow: auto;
  padding-top: 3rem;
  & h2 {
    color: white;
    font-weight: 500;
    margin-bottom: 2rem;
  }
  & p {
    color: #8d8d8d;
    font-size: 0.8rem;
    margin-bottom: 4px;
  }
  & a {
    color: #78a9ff;
  }
`;

export const InputDiv = styled.div`
  width: 58%;
  margin-top: 4rem;
  margin-bottom: 5rem;
`;

export const StyledTextInput = styled(TextInput)`
  width: 100%;
`;
export const ResetButton = styled(Button)`
  width: 100%;
  margin-top: 0.3px;
  padding-right: 0.5rem;
  &:disabled {
    background-color: #8d8d8d;
    color: white;
  }
`;
