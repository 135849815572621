import React, { useState, useContext } from "react";
import styled from "styled-components";
import Assessment from "./Assessment";
import Complete from "./Complete";
import kit from "ich-ui-kit";
// import SurveyBegin from "./steps/SurveyBegin";

const DDGuidedExperience = ({ cms, domain, passClientInfo, setCompletedStatus }) => {
  const { user } = useContext(kit.UserContext);
  const [controllerIndex, setControllerIndex] = useState(0);
  const [overallIndex, setOverallIndex] = useState(0);
  const [feedbackIndex, setFeedbackIndex] = useState(0);

  const [employmentResponse, setEmploymentResponse] = useState({
    q1: { text: "What is your current employment status?", answer: " " },
    q2: {
      text: "Does your employment provide benefits, such as health insurance or retirement plans?",
      answer: " "
    },
    q3: { text: "Are you currently looking for employment?", answer: " " },
    q4: {
      text: "How likely do you feel it is that you will be promoted at work?",
      answer: " "
    },
    q5: {
      text: "Does your employment interfere with school attendance?",
      answer: " "
    }
  });
  const [incomeResponse, setIncomeResponse] = useState({
    q1: {
      text: "What is your personal monthly income, from all sources (e.g. Work, VA Benefits, Disability)?",
      answer: " "
    },
    q2: {
      text: "How many individuals do you support with this income?",
      answer: " "
    }
  });
  const [transportationResponse, setTransportationResponse] = useState({
    q1: { text: "Do you or your household own a motor vehicle?", answer: " " },
    q2: { text: "Is your vehicle reliable?", answer: " " },
    q3: {
      text: "How much do you spend on transportation a month, including vehicle maintenance?",
      answer: " "
    },
    q4: {
      text: "Does public transportation (e.g. RTS) meet your needs?",
      answer: " "
    },
    q5: {
      text: "Does ride sharing (e.g. Uber, Lyft) or taxi service meet your needs?",
      answer: " "
    }
  });

  const ContentController = () => {
    if (controllerIndex === 0) {
      return (
        <Assessment
          passClientInfo={passClientInfo}
          cms={cms}
          domain={domain}
          setControllerIndex={setControllerIndex}
          feedbackIndex={feedbackIndex}
          setFeedbackIndex={setFeedbackIndex}
          index={controllerIndex}
          setOverallIndex={setOverallIndex}
          employmentResponse={employmentResponse}
          incomeResponse={incomeResponse}
          transportationResponse={transportationResponse}
          setEmploymentResponse={setEmploymentResponse}
          setIncomeResponse={setIncomeResponse}
          setTransportationResponse={setTransportationResponse}
        />
      );
    }
    if (controllerIndex === 1) {
      return (
        <Complete
          cms={cms}
          employmentResponse={employmentResponse}
          incomeResponse={incomeResponse}
          transportationResponse={transportationResponse}
          passClientInfo={passClientInfo}
          setCompletedStatus={setCompletedStatus}
        />
      );
    }
  };

  return (
    <ContentContainer>
      <InnerContentWrapper>{ContentController()}</InnerContentWrapper>
    </ContentContainer>
  );
};

export default DDGuidedExperience;

const ContentContainer = styled.div`
  background-color: white;
  z-index: 10;
  height: 100%;
  max-width: 800px;
  margin: auto;
  border: 1px solid lightgrey;
  @media screen and (min-width: 1800px) {
    max-width: 900px;
  }
  @media screen and (max-width: 768px) {
    padding-bottom: 2rem;
    height: auto;
    border: 1px solid lightgrey;
    background-color: white;
  }
`;
const InnerContentWrapper = styled.div`
  height: 100%;
  width: 100%;
  padding-right: 8%;
  padding-left: 8%;
  /* padding-top: 0.5%; */
  @media screen and (max-width: 800px) {
    padding-left: 1%;
    padding-right: 1%;
  }
`;
