import styled from "styled-components";

export const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 100px;
  margin: 0 0.75rem;
`;

export const Circle = styled.div`
  background-color: ${(props) => props.theme.color};
  border-radius: 100%;
  margin: 0.75rem;
  padding: 0.75rem;
  width: 50px;
  height: 50px;
  &:hover {
    ${(props) => {
      if (props.theme.isBad) {
        return "cursor: pointer;filter: brightness(110%);";
      }
    }};
  }
`;

export const ViewAllCircle = styled.div`
  background-color: ${(props) => props.theme.color};
  border-radius: 12px;
  padding: 0.25rem;
  width: 55px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  color: white;
`;

export const DomainIcon = styled.img`
  width: initial;
`;

export const Text = styled.p`
  font-weight: 500;
  font-size: 0.75rem;
  word-wrap: break-word;
`;

export const Profile = styled.div`
  border-radius: 100%;
  border: 1px solid rgba(0, 0, 0, 0.09);
  margin-right: 1rem;
  flex-shrink: 0;
  padding: 1.25rem;
  background: ${(props) => props.theme.color};
  width: 80px;
  height: 80px;
  @media only screen and (min-width: 540px) and (max-width: 767px) {
    margin-right: 2rem;
  }
`;
