import { useState, useEffect } from "react";
import styled from "styled-components";
import { TextInput } from "carbon-components-react";
import { useCompleteProfile } from "../../../../cms";
import { useDimension } from "../../../../../../../hooks/useDimension";
import { eContactWrapper, eContactTextInputWrapper, eContactTextInputRow, StyledTextInput } from "../AboutMe.styled";
import { isValidName, shouldAutoComplete } from "../../../../../../../utils";

const ContactInfo = ({ setEmergencyContactInfo, setDisableButton }) => {
  const { uiCMS: cms } = useCompleteProfile();
  const { windowSize } = useDimension();
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");

  const [relationship, setRelationship] = useState("");

  const [isInvalid, setIsInvalid] = useState({
    firstName: false,
    lastName: false,
    email: false,
    relationship: false
  });

  if (windowSize.width < 768) {
    return (
      <div>
        <StyledTextInput
          id="firstname"
          labelText={cms.eContact.label_firstname}
          placeholder="First Name"
          value={firstName}
          invalid={isInvalid.firstName}
          invalidText={cms.error_invalid_input}
          onChange={(e) => {
            e.target.value = e.target.value.replace( / +/g, ' ' );
            setFirstName(e.target.value);
          }}
          onBlur={(e) => {
            setDisableButton(false);
            setEmergencyContactInfo((state) => ({
              ...state,
              firstname: e.target.value
            }));
            setIsInvalid((state) => ({
              ...state,
              firstName:
               e.target.value !== "" && !isValidName(e.target.value)
            }));
          }}
          autoComplete={shouldAutoComplete}
        />
        <StyledTextInput
          id="lastname"
          labelText={cms.eContact.label_lastname}
          placeholder="Last Name"
          value={lastName}
          invalid={isInvalid.lastName}
          invalidText={cms.error_invalid_input}
          onChange={(e) => {
            e.target.value = e.target.value.replace( / +/g, ' ' );
            setLastName(e.target.value);
          }}
          onBlur={(e) => {
            setDisableButton(false);
            setEmergencyContactInfo((state) => ({
              ...state,
              lastname: e.target.value
            }));
            setIsInvalid((state) => ({
              ...state,
              lastName:
               e.target.value !== "" && !isValidName(e.target.value)
            }));
          }}
          autoComplete={shouldAutoComplete}
        />
        <StyledTextInput
          labelText={cms.eContact.label_relationship}
          placeholder={cms.eContact.label_relationship}
          id="relationship"
          value={relationship}
          invalid={isInvalid.relationship}
          invalidText={cms.error_invalid_input}
          onChange={(e) => {
            e.target.value = e.target.value.replace( / +/g, ' ' );
            setDisableButton(false);
            setRelationship(e.target.value);
            setEmergencyContactInfo((state) => ({
              ...state,
              relationship: e.target.value
            }));
          }}
          autoComplete={shouldAutoComplete}
        />
      </div>
    )
  } else {
    return (
      <div>
          <div style={{display: 'flex'}}>
            <StyledTextInput
              style={{marginRight: '1rem'}}
              id="firstname"
              labelText={cms.eContact.label_firstname}
              placeholder="First Name"
              value={firstName}
              invalid={isInvalid.firstName}
              invalidText={cms.error_invalid_input}
              onChange={(e) => {
                e.target.value = e.target.value.replace( / +/g, ' ' );
                setFirstName(e.target.value);
              }}
              onBlur={(e) => {
                setDisableButton(false);
                setEmergencyContactInfo((state) => ({
                  ...state,
                  firstname: e.target.value
                }));
                setIsInvalid((state) => ({
                  ...state,
                  firstName:
                   e.target.value !== "" && !isValidName(e.target.value)
                }));
              }}
              autoComplete={shouldAutoComplete}
            />
            <StyledTextInput
              id="lastname"
              labelText={cms.eContact.label_lastname}
              placeholder="Last Name"
              value={lastName}
              invalid={isInvalid.lastName}
              invalidText={cms.error_invalid_input}
              onChange={(e) => {
                e.target.value = e.target.value.replace( / +/g, ' ' );
                setLastName(e.target.value);
              }}
              onBlur={(e) => {
                setDisableButton(false);
                setEmergencyContactInfo((state) => ({
                  ...state,
                  lastname: e.target.value
                }));
                setIsInvalid((state) => ({
                  ...state,
                  lastName:
                   e.target.value !== "" && !isValidName(e.target.value)
                }));
              }}
              autoComplete={shouldAutoComplete}
            />
          </div>
          <div style={{ marginTop: "15px" }}>
            <StyledTextInput
              labelText={cms.eContact.label_relationship}
              placeholder={cms.eContact.label_relationship}
              id="relationship"
              value={relationship}
              invalid={isInvalid.relationship}
              invalidText={cms.error_invalid_input}
              onChange={(e) => {
                e.target.value = e.target.value.replace( / +/g, ' ' );
                setDisableButton(false);
                setRelationship(e.target.value);
                setEmergencyContactInfo((state) => ({
                  ...state,
                  relationship: e.target.value
                }));
              }}
              autoComplete={shouldAutoComplete}
            />
          </div>
      </div>
    );
  }
};
export default ContactInfo;

