import { OverflowMenu, OverflowMenuItem } from "carbon-components-react";
import React from "react";
import styled from "styled-components";
import { updateDate } from "../helpers/filter-helpers";
import { StyledEdit } from "../index.styled";

export const renderOverflowOptions = (
  referral,
  user,
  setMockDataState,
  displayedItems,
  setDisplayedItems,
  apiService,
  serviceLeadArray,
  referrals,
  loadingInline,
  setLoadingInline,
  setNoteModalOpen,
  setTempStatus,
  setReferralClosed,
  setReferralActivityInactive,
  setReferralActivitiesInactive,
  dateStart,
  dateEnd,
  selectServices,
  assignStatuses,
  referStatuses,
  filterBookmarked,
  parentRefers
) => {
  let isServiceLead = false;
  if (
    serviceLeadArray.length > 0 &&
    serviceLeadArray.find(
      (s) =>
        String(s.serviceId) === String(referral.serviceId) &&
        String(s.locationId) === String(referral.locationId)
    )?.isLead === true
  ) {
    isServiceLead = true;
  }
  const updateStatus = async (status) => {
    setLoadingInline(referral?.id);
    try {
      const data = await apiService.put(
        `/referrals/status?assumedId=${user.superAssumedRole?.leadId || ""}`,
        {
          patientId: referral?.referreeId,
          serviceId: referral?.serviceId,
          originatorId: referral?.requesterId,
          assignedTo: referral?.assignedTo,
          status,
          referralId: referral?.id,
        }
      );
      if (data && data.history) {
        let Mdata = [...displayedItems];
        let showStatus =
          referral?.status?.toLowerCase()?.includes("waiting for provider") &&
          status?.toLowerCase()?.includes("waiting for client")
            ? "Working"
            : status;
        Mdata = Mdata.map((item) => {
          if (item.id === referral?.id) {
            return { ...item, status: showStatus };
          } else {
            return item;
          }
        });
        updateDate(
          dateStart,
          dateEnd,
          setDisplayedItems,
          Mdata,
          selectServices,
          assignStatuses,
          referStatuses,
          filterBookmarked,
          parentRefers
        );
        await setReferralActivitiesInactive(
          referral.referreeId,
          referral.id
        );
      }
    } catch (err) {
      console.log(err);
    }
    setLoadingInline("");
  };

  if (user.isSuper) {
    if (
      referral.status.includes("Close Request") ||
      referral.status.includes("Pending Closure")
    ) {
      return (
        <>
          Update Status
          <StyledOverflowMenu
            renderIcon={StyledEdit}
            flipped
            size="md"
            data-floating-menu-container
          >
            <OverflowMenuItem
              onClick={(e) => updateStatus("Reopened")}
              itemText="Reopen"
            />
          </StyledOverflowMenu>
        </>
      );
    }
  } else if (
    user.isAdmin ||
    isServiceLead ||
    user.name !== referral.requester
  ) {
    if (
      referral.status.toLowerCase().includes("waiting for provider") ||
      referral.status.toLowerCase() === "pending" ||
      referral.status.toLowerCase() === "about to expire - pending"
    ) {
      return (
        <>
          Update Status
          <StyledOverflowMenu
            renderIcon={StyledEdit}
            flipped
            size="md"
            data-floating-menu-container
          >
            <OverflowMenuItem
              onClick={(e) => updateStatus("Waiting for Client")}
              itemText="Accept"
            />
            <OverflowMenuItem
              onClick={(e) => {
                setReferralClosed(referral);
                setNoteModalOpen(true);
                setTempStatus("Rejected By Receiver");
              }}
              itemText="Reject"
            />
          </StyledOverflowMenu>
        </>
      );
    } else if (
      referral.status.toLowerCase() === "working" ||
      referral.status.toLowerCase().includes("reopen")
    ) {
      return (
        <>
          Update Status
          <StyledOverflowMenu
            renderIcon={StyledEdit}
            flipped
            size="lg"
            data-floating-menu-container
          >
            <StyledOverflowMenuItem
              onClick={(e) => {
                setReferralClosed(referral);
                setNoteModalOpen(true);
                setTempStatus("Pending Closure - Resolved");
              }}
              itemText="Closed - Resolved"
            />
            <StyledOverflowMenuItem
              onClick={(e) => {
                setReferralClosed(referral);
                setNoteModalOpen(true);
                setTempStatus("Pending Closure - Unable to Contact");
              }}
              itemText="Closed - Unable to Contact"
            />
            <StyledOverflowMenuItem
              onClick={(e) => {
                setReferralClosed(referral);
                setNoteModalOpen(true);
                setTempStatus("Pending Closure - Unable to Resolve");
              }}
              itemText="Closed - Unresolved"
            />
          </StyledOverflowMenu>
        </>
      );
    } else if (
      referral.status.toLowerCase().includes("close request") ||
      referral.status.toLowerCase().includes("pending closure")
    ) {
      return (
        <>
          Update Status
          <StyledOverflowMenu
            renderIcon={StyledEdit}
            flipped
            size="md"
            data-floating-menu-container
          >
            <OverflowMenuItem
              onClick={(e) => updateStatus("Reopened")}
              itemText="Reopen"
            />
          </StyledOverflowMenu>
        </>
      );
    }
  } else if (user.name === referral.requester) {
    if (
      referral.status.toLowerCase().includes("waiting for provider") ||
      referral.status.toLowerCase() === "pending" ||
      referral.status.toLowerCase() === "about to expire - pending"
    ) {
      return (
        <>
          Update Status
          <StyledOverflowMenu
            renderIcon={StyledEdit}
            flipped
            size="md"
            data-floating-menu-container
          >
            <OverflowMenuItem
              onClick={(e) => updateStatus("Waiting for Client")}
              itemText="Accept"
            />
            <OverflowMenuItem
              onClick={(e) => {
                setReferralClosed(referral);
                setNoteModalOpen(true);
                setTempStatus("Rejected By Receiver");
              }}
              itemText="Reject"
            />
          </StyledOverflowMenu>
        </>
      );
    } else if (
      referral.status.toLowerCase() === "working" ||
      referral.status.toLowerCase().includes("reopen")
    ) {
      return (
        <>
          Update Status
          <StyledOverflowMenu
            renderIcon={StyledEdit}
            flipped
            size="lg"
            data-floating-menu-container
          >
            <StyledOverflowMenuItem
              onClick={(e) => {
                setReferralClosed(referral);
                setNoteModalOpen(true);
                setTempStatus("Pending Closure - Resolved");
              }}
              itemText="Closed - Resolved"
            />
            <StyledOverflowMenuItem
              onClick={(e) => {
                setReferralClosed(referral);
                setNoteModalOpen(true);
                setTempStatus("Pending Closure - Unable to Contact");
              }}
              itemText="Closed - Unable to Contact"
            />
            <StyledOverflowMenuItem
              onClick={(e) => {
                setReferralClosed(referral);
                setNoteModalOpen(true);
                setTempStatus("Pending Closure - Unable to Resolve");
              }}
              itemText="Closed - Unresolved"
            />
          </StyledOverflowMenu>
        </>
      );
    } else if (
      referral.status.toLowerCase().includes("close request") ||
      referral.status.toLowerCase().includes("pending closure")
    ) {
      return (
        <>
          Update Status
          <StyledOverflowMenu
            renderIcon={StyledEdit}
            flipped
            size="md"
            data-floating-menu-container
          >
            <OverflowMenuItem
              onClick={(e) => updateStatus("Reopened")}
              itemText="Reopen"
            />
          </StyledOverflowMenu>
        </>
      );
    }
  } else {
    return <></>;
  }
};

const StyledOverflowMenu = styled(OverflowMenu)`
  height: 1rem;
  ul {
    width: 15rem;
  }
  button {
    max-width: 100rem;
  }
`;

const StyledOverflowMenuItem = styled(OverflowMenuItem)`
  // // padding: 0.5rem;
  // // height: 1rem
  // font-size: 12px;
  // // overflow: visible;
  // width: 50rem;
  // .bx--overflow-menu-options__btn {
  //     width: 20rem;
  //     text-overflow: visible;
  // }
`;
