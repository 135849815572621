import styled from "styled-components";
import { Button, Tooltip } from "carbon-components-react";
import { useHistory } from "react-router-dom";
import { useState } from "react";

const Badge = (props) => {
    let history = useHistory();
    const [isHover, setIsHover] = useState(false);

    return (
        <BadgeWrapper
            style={!props.redirectLink || props.earned ? { cursor: 'default' } : null}
            onMouseEnter={() => {
                if(!props.earned){
                    setIsHover(true);
                }
            }}
            onMouseLeave={() => setIsHover(false)}
            onClick={() => {
                if(!props.earned || !props.redirectLink){
                    history.push(props.redirectLink)
                }
            }}
        >
            <BadgeImage
                src={props.src}
                alt="Badge"
            />
            {props.label === 'Positive Feedback' || props.label === 'Feedback' ? (
               <BadgeLabel style={!props.redirectLink || props.earned ? { cursor: 'default' } : null}>
                <Tooltip
                showIcon={false}
                triggerText={props.label}
                open={isHover}
                direction="bottom"
                >
                <p className=".bx--tooltip__body">
                    {props.tooltipBody}
                </p>
                <div className="bx--tooltip__footer">
                </div>
             </Tooltip>
            </BadgeLabel>
            ): (
            <BadgeLabel>
                <Tooltip
                    showIcon={false}
                    triggerText={props.label}
                    open={isHover}
                    direction="bottom"
                >
                <p className=".bx--tooltip__body">
                    {props.tooltipBody}
                </p>
                <div className="bx--tooltip__footer">
                    {props.redirectLink && <Button
                        size="small"
                        onClick={() => {
                        history.push(props.redirectLink)
                        }}
                    >
                    Get Started
                    </Button>}
                 </div>
                </Tooltip>
            </BadgeLabel>
            )}
        </BadgeWrapper>
    )
}
const BadgeWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-self: flex-start;
    align-items: center;
    cursor: pointer;
`;

const BadgeImage = styled.img`
  width: 90%;
  height: 90%;
`;

const BadgeLabel = styled.p`
    text-align: center;
    cursor: pointer;
`;

const BadgeTooltipWrapper = styled.div`
    background-color: #393939;
    color: #fff;
    padding: 1rem;
    position: relative;
    display: inline-block
`;

export default Badge;