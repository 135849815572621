import { validateEmail } from "../../utils";

export const multipleEmailRegex =
  /^\s*[\w.%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}\s*(,\s*[\w.%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}\s*){0,2}$/;
  
export default function validate(values) {
    const errors = {};
    if (values.email) {
      const emailList = values.email.split(',').map(email => email.trim());

      if (!multipleEmailRegex.test(values.email)) {
        errors.email = "Invalid email address(es)"
      }
      
      if (emailList.length > 1) {
        errors.email = "Maximum of 1 email address";
      }
    }
    if (values.cc) {
      const emailList = values.cc.split(',').map(email => email.trim());

      if (!multipleEmailRegex.test(values.cc)) {
        errors.cc = "Invalid: Enter valid email addresses separated by commas: example@example.com, example2@example.com"
      }

      if (emailList.length > 3) {
        errors.cc = "Maximum of 3 email addresses";
      }
    }
    if (values.phone === "") {
    errors.phone = "Contact number is required";
    } else if (!/[0-9]{3}-[0-9]{3}-[0-9]{4}$/.test(values.phone)) {
    errors.phone = "Contact number should be in format 123-456-7890";
    }

    if (values.message === "") {
    errors.message = "Please enter a message";
    }
    return errors;
  }
  