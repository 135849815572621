import React, { useContext, useEffect, useState } from "react";
import { Switch, Route, useLocation, useHistory } from "react-router-dom";
import styled from "styled-components";
import kit from "ich-ui-kit";

import { CompleteProfileProvider } from "./cms";
import useQuery from "../../../hooks/useQuery";
import { fetchWithRetry } from "../../../utils";

import { Loading } from "carbon-components-react";
import GuidedExperienceContent from "./components/GuidedExperience";


const CompleteProfile = ({passClientInfo, setCompletedStatus}) => {
  const [, setOverallIndex] = useState(0);
  // Contexts
  const { apiService } = useContext(kit.ApiContext);
  const { user } = useContext(kit.UserContext);
  //Hooks
  let location = useLocation();
  const history = useHistory();
  const query = useQuery();
  // States
  const [clientInfo, setClientInfo] = useState(passClientInfo)
  const clientIdFromURL = query.get("client") || query.get("id");
  let clientId = passClientInfo?.id || location?.state?.client || location?.state?.clientID || clientIdFromURL;

  const [clientID, setClientID] = useState(clientId);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const getClientRecord = async () => {
      setLoading(true);
      try {
        if(!clientId) return;
        const response = await fetchWithRetry(
          `${process.env.REACT_APP_API_ENDPOINT}/clients/${clientId}?assumedId=${user.superAssumedRole?.leadId || ""}`,
          {
            method: "get",
            headers: {
              Authorization: `Bearer ${user.accessToken} ${user.idToken}`,
            },
          }
        );
        if (response.status === 200) {
          const data = await response.json();
          setClientInfo(data)
          setClientID(data.id)
        }
      } catch (err) {
        console.log(err);
      }
      setLoading(false);
    };
    // If client info has not already been passed, and we have a client ID from the URL, only then make API call
    let hasClientInfo = !! passClientInfo?.id
    if(!hasClientInfo && clientID) {
      getClientRecord();
    }
  }, [location.state, clientId, clientIdFromURL]);

  return (
    <CompleteProfileProvider>
      <PageContainer>
        <ContentContainer>
          {(loading || !passClientInfo?.firstName) ? (
            <Loading />
          ) : (
            <GuidedExperienceContent
              setOverallIndex={setOverallIndex}
              clientID={clientID}
              clientInfo={passClientInfo}
              setCompletedStatus={setCompletedStatus}
            />
          )}
        </ContentContainer>
        <ProgressContainer></ProgressContainer>
      </PageContainer>
    </CompleteProfileProvider>
  );
};

const PageContainer = styled.div`
  height: 80vh;
  width: 100vw;
  background-color: #f4f4f4;
  z-index: 1;
  margin: 3rem 0;
  @media screen and (max-width: 768px) {
    height: 100vh;
    margin: 0rem 0;
    background-color: white;
  }
`;

const ContentContainer = styled.div`
  grid-area: "content";
  margin: auto;
  height: 100%;
  width: 100%;
  margin-bottom: 0.5rem;
  @media screen and (max-width: 768px) {
    height: 100vh;
    margin: 0;
  }
`;

const ProgressContainer = styled.div`
  grid-area: "progress";
  display: flex;
  justify-content: center;
  align-items: center;
`;

export default CompleteProfile;
