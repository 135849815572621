import { Column, Loading, Row } from "carbon-components-react";
import kit from "ich-ui-kit";
import React, { useContext, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Badges } from "../../components/Badges/badges";
import HasActiveReferral from "./HasActiveReferral";
import NewReferralForm from "./NewReferralForm";
import { generateClickToCallLink } from "../../utils";

import * as S from "./create-referral.styles.js";

const CreateReferralPage = () => {
  const [service, setService] = useState();
  const [client, setClient] = useState();
  const [tabIndex, setTabIndex] = useState(0);
  const [loadingService, setLoadingService] = useState(false);
  const [loadingClient, setLoadingClient] = useState(false);
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [activeReferralId, setActiveReferralId] = useState(null);
  const [activeReferralCanReopen, setActiveReferralCanReopen] = useState(false);
  const [activeReferralStatus, setActiveReferralStatus] = useState("");
  const [hasConsent, setHasConsent] = useState(false);
  const [width, setWidth] = useState(window.innerWidth);
  const { apiService } = useContext(kit.ApiContext);
  const { user } = useContext(kit.UserContext);
  const location = useLocation();
  const history = useHistory();

  const isMobile = 500;

  useEffect(() => {
    const handleResizeWindow = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleResizeWindow);
    return () => {
      window.removeEventListener("resize", handleResizeWindow);
    };
  }, []);

  useEffect(() => {
    const fetchService = async () => {
      setLoadingService(true);
      try {
        let data = await apiService.post(
          `/search/service?serviceId=${location?.state?.serviceId}&locationId=${location?.state?.locationId}&orgSource=${location?.state?.orgSource}`
        );
        setService(data);
      } catch (err) {
        console.log(err);
      }
      setLoadingService(false);
    };

    const fetchClientRecord = async () => {
      setLoadingClient(true);
      try {
        const data = await apiService.get(
          `/clients/${location.state.patientId}?assumedId=${
            user.superAssumedRole?.leadId || ""
          }`
        );
        setClient(data);
      } catch (err) {
        console.log(err);
      }
      setLoadingClient(false);
    };

    fetchService();
    if (location.state.patientId) {
      fetchClientRecord();
      doesActiveReferralExist();
    }
  }, [apiService]);

  // Pull care team
  const [clients, setClients] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchClients = async () => {
      setLoading(true);
      let patientsData;
      if (user.superAssumedRole?.leadId) {
        patientsData = await apiService.get(
          `/clients?assumedId=${user.superAssumedRole.leadId}`
        );
        if (patientsData) {
          setClients(patientsData || []);
        }
      } else if (!user.is211Admin && !user.isSuper) {
        patientsData = await apiService.get(
          `/clients?assumedId=${user.superAssumedRole?.leadId || ""}`
        );
        if (patientsData) {
          setClients(patientsData || []);
        }
      } else {
        patientsData = await apiService.get(
          `/account/users?assumedId=${
            user.superAssumedRole?.leadId || ""
          }`
        );
        if (patientsData && patientsData.clients) {
          setClients(patientsData.clients.filter((c) => !c.firstLogin));
        }
      }
      setLoading(false);
    };
    let isMounted = true;
    // Only attempt to pull patients list
    if (!user.firstLogin) {
      if (isMounted) {
        fetchClients();
      }
    }

    return () => {
      isMounted = false;
    };
  }, []);

  const doesActiveReferralExist = async () => {
    if (location.state && location.state.serviceId) {
      try {
        let data = await apiService.get(`/referrals/exists?patientId=${location.state.patientId || client?.id}&serviceId=${location.state.serviceId}&locationId=${location.state.locationId}&orgSource=${location.state.orgSource}`)
        const { referralId, canReopen, status } = data;
        if (referralId) {
          setActiveReferralId(referralId);
          setActiveReferralCanReopen(canReopen);
          setActiveReferralStatus(status);
          return true;
        } else {
          return false;
        }
      } catch (err) {
        console.log(err);
      }
    }
  };

  const submitReferral = async (displayedNotes = '', domain) => {
    setLoadingSubmit(true);
    try {
      let data = await apiService.post(`/referrals?assumedId=${user.superAssumedRole?.leadId || ""}`, {
        patientId: location.state.patientId || client.id,
        serviceId: location.state.serviceId,
        orgId: location.state.orgId,
        c360_orgId: location.state.c360_orgId,
        referralType: domain || "domain",
        author: user.superAssumedRole?.leadName || user.aliasName || user.fullName,
        hasConsent: hasConsent,
        orgSource: location.state.orgSource,
        locationId: location.state.locationId,
        text: displayedNotes
      });
      if (data && data.referralId) {
        history.push({
          pathname: "/referral/confirmation",
          state: {
            clientId: location.state.patientId || client.id,
            firstName: client.givenName || client?.firstName,
            lastName: client.familyName || client?.lastName,
            providerName: service.orgName,
            serviceName: service.name,
            city: service.city,
            state: service.state,
            referralId: data.referralId,
            referralType: domain || "domain"
          }
        });
      }
    } catch (err) {
      console.log(err);
    }
    setLoadingSubmit(false);
  };

  const incrementTab = () => {
    setTabIndex(tabIndex + 1);
  };

  const navigateTo360Dashboard = () => {
    history.push({
      pathname: "/360dashboard",
      state: {
        client: location.state.patientId
      }
    });
  };

  const isLoading = () => {
    if (loadingService || loadingClient || loadingSubmit) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <S.StyledGrid>
      {isLoading() ? (
        <Loading />
      ) : (
        <>
          <Row>
            <Column>
              <S.ProviderInfoHeader>
                <S.ProviderInfoHeaderContent>
                  <h2>
                    <strong>{service?.orgName}</strong>
                  </h2>
                  <h2>{service?.name}</h2>
                  {service?.address && service?.city && service?.state && (
                    <p style={{ color: 'grey' }}>
                      {service?.address} {service?.city}, {service?.state}
                    </p>
                  )}
                  {service?.phone &&
                    (generateClickToCallLink(service?.phone) ? (
                      <a href={generateClickToCallLink(service?.phone)}>{service?.phone}</a>
                    ) : (
                      <p style={{ color: 'grey' }}>{service?.phone}</p>
                    ))}
                  <br />
                </S.ProviderInfoHeaderContent>
              </S.ProviderInfoHeader>
              <S.BadgesDiv>
                <Badges
                  capacity={
                    service
                      ? service.capacity === ''
                        ? null
                        : service.capacity
                      : null
                  }
                  eligable={0}
                  onTeam={false}
                  style={{
                    justifyContent: 'center',
                    alignItems: 'flex-start'
                  }}
                />
              </S.BadgesDiv>
            </Column>
          </Row>
          <Row>
            <Column>
              <hr />
            </Column>
          </Row>
          <Row>
            <S.StyledColumn>
              <h1>Create a Referral</h1>
            </S.StyledColumn>
          </Row>
          <Row>
            <Column lg={2}></Column>
            <Column lg={12}>
              {activeReferralId &&
              !activeReferralStatus.toLowerCase().includes('closed') ? (
                <HasActiveReferral
                  patientId={location.state.patientId || client.id}
                  activeReferralId={activeReferralId}
                  canReopen={activeReferralCanReopen}
                  activeReferralStatus={activeReferralStatus}
                />
              ) : (
                <NewReferralForm
                  client={client}
                  setClient={setClient}
                  service={service}
                  tabIndex={tabIndex}
                  setTabIndex={setTabIndex}
                  incrementTab={incrementTab}
                  user={user}
                  submitReferral={submitReferral}
                  navigateTo360Dashboard={navigateTo360Dashboard}
                  doesActiveReferralExist={doesActiveReferralExist}
                  hasConsent={hasConsent}
                  setHasConsent={setHasConsent}
                  domains={location.state?.domains}
                  clients={clients}
                  loading={loading}
                />
              )}
            </Column>
            <Column lg={2}></Column>
          </Row>
        </>
      )}
    </S.StyledGrid>
  );
};

export default CreateReferralPage;
