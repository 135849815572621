import { useState } from "react";
import { Button, TextInput } from "carbon-components-react";
import styled from "styled-components";
import { useCompleteProfile } from "../../../../cms";
import { BinaryButtonWrapper, BinaryWrapper, ResponseWrapper, StyledButtonGroup, StyledTextInput, TextInputWrapper } from "../AboutMe.styled";
import { isValidName, shouldAutoComplete } from "../../../../../../../utils";

const Nickname = ({ setAboutMeResponse, setDisableButton }) => {
  const { uiCMS: cms } = useCompleteProfile();
  const [nickName, setNickName] = useState("");
  const [hasPreferredName, setHasPreferredName] = useState(null);
  const [isInvalid, setIsInvalid] = useState(false);
  const handleTextChange = (e) => {
    setIsInvalid(e.target.value !== "" && !isValidName(e.target.value));
    setNickName(e.target.value);
    setDisableButton(!isValidName(e.target.value));
    setAboutMeResponse((state) => ({
      ...state,
      hasPreferredName: false,
      nickName: e.target.value
    }));
  };
  return (
    <BinaryWrapper>
      <BinaryButtonWrapper style={{justifyContent: 'left'}}>
        <StyledLeftButton
          theme={{ bcolor: hasPreferredName === "yes" }}
          kind="tertiary"
          onMouseDown={() => {
            setHasPreferredName("yes");
          }}
        >
          {cms.btn_yes}
          </StyledLeftButton>
          <StyledButton
            theme={{ bcolor: hasPreferredName === "no" }}
            kind="tertiary"
            onMouseDown={() => {
              setHasPreferredName("no");
              setDisableButton(false);
              setAboutMeResponse((state) => ({
                ...state,
                hasPreferredName: false
              }));
            }}
          >
            {cms.btn_no}
          </StyledButton>
      </BinaryButtonWrapper>
        {hasPreferredName === "yes" && (
        <TextInputWrapper>
          <StyledTextInput
            id="nickname"
            labelText={cms.nickname.label}
            placeholder={cms.nickname.placeholder}
            value={nickName}
            invalid={isInvalid}
            invalidText={cms.nickname.error}
            onChange={(e) => handleTextChange(e)}
            autoComplete={shouldAutoComplete}
          />
        </TextInputWrapper>
      )}
    </BinaryWrapper>
  );
};

export default Nickname;

const StyledButton = styled(Button)`

  @media screen and (max-width: 768px) {
    margin-bottom: 1rem;
    width: 20rem;
    height: 3rem;
  }

  @media screen and (min-width: 768px) {
    color: ${(props) => (props.theme.bcolor ? "white" : "rgba(0,145,179,1)")};
    background-color: ${(props) => (props.theme.bcolor ? "#0f62fe" : "white")};
    :hover {
      color: ${(props) => (props.theme.bcolor ? "rgba(0,145,179,1)" : "white")};
      background-color: ${(props) =>
        props.theme.bcolor ? "white" : "rgba(0,145,179,1)"};
    }
  
    :focus {
      color: ${(props) => (props.theme.bcolor ? "white" : "rgba(0,145,179,1)")};
      background-color: ${(props) => (props.theme.bcolor ? "#0f62fe" : "white")};
      :active {
        color: "white" !important;
        background-color: "#0f62fe";
      }
    }
  }
`;



const StyledLeftButton = styled(StyledButton)`
  margin-right: 2rem;
  @media screen and (max-width: 768px) { 
    margin-right: 0;
  }
`
