import {
  CardDiv,
  CardHeader,
  CardSubHeader,
  ToggleHeaderContainer,
  StyledToggle
} from "../index.styled";
import { Loading } from "carbon-components-react";
import { DonutChart } from "@carbon/charts-react";
import { ChartBar20 } from "@carbon/icons-react";

const DoughnutChart = ({
  title,
  subtitle,
  doughnutData,
  loading,
  label,
  toggle
}) => {
  const donutData = [
    {
      group: "Error",
      value: 0
    }
  ];

  const options = {
    animations: true,
    resizable: true,
    height: "275px",
    legend: {
      enabled: true,
      position: "top"
    },
    donut: {
      alignment: "center",
      center: {
        label: label
      }
    },
    toolbar: {
      enabled: false
    }
  };

  return (
    <CardDiv style={{ minHeight: "376px" }}>
      <ToggleHeaderContainer>
        <CardHeader>{title}</CardHeader>
        {toggle && (
          <StyledToggle
            onClick={toggle}
            size="sm"
            renderIcon={ChartBar20}
            hasIconOnly
            iconDescription="Description"
            tooltipPosition="left"
          />
        )}
      </ToggleHeaderContainer>
      {subtitle && <CardSubHeader>{subtitle}</CardSubHeader>}
      <div
        style={{
          margin: "1rem 0.5rem 0rem 0.5rem",
          height: loading ? "70%" : "auto"
        }}
      >
        {loading ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%"
            }}
          >
            <Loading withOverlay={false} />
          </div>
        ) : (
          <DonutChart data={doughnutData || donutData} options={options} />
        )}
      </div>
    </CardDiv>
  );
};

export default DoughnutChart;
