import styled from "styled-components";
import { Button } from "carbon-components-react";
export const Page = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
`;
export const CreateReferralButton = styled(Button)`
  margin: 1rem;
  @media ${(props) => props.theme.breakpoints.md} {
    margin: 0;
  }
`;

export const List = styled.div`
  display: grid;
  height: 100%;
  width: 100%;
  padding: 2rem;
  // grid-template-columns: auto;
  grid-template-columns: 100%;
  // grid-template-rows: 3rem 5rem auto;
  grid-template-rows: auto auto auto;
  grid-template-areas:
    "header"
    "filter"
    "body";
`;

export const HeaderContainer = styled.div`
  grid-area: header;
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media (max-width: 500px) {
    flex-direction: column;
    margin: auto;
  }
`;
export const FilterContainer = styled.div`
  grid-area: filter;
  display: flex;
  margin-bottom: 2rem;
`;

export const CardContainer = styled.div`
  margin-top: 3rem;
  overflow: auto;
  height: 35rem;
`;
