import styled from "styled-components";
import { CardTitle, CardValue } from "./overview-cards";
import { CardHeader, ToggleHeaderContainer } from "../index.styled";

function WellBeingImprovements({ cms, data, loading, toggle }) {
  return (
    <FlatCard>
      <ToggleHeaderContainer>
        <CardHeader>Connected Individual State of Well-Being Improvements</CardHeader>
      </ToggleHeaderContainer>
      <ResponsiveGrid>
        {data.map((el, i) => (
          <DomainStat
            src={el.link}
            text={el.domain.includes(" ") ? el.domain.split(" ")[0] : el.domain}
            bgColor={el.color}
            total={el.value || 0}
            loading={loading}
            isPercent={false}
            key={i}
          />
        ))}
      </ResponsiveGrid>
    </FlatCard>
  );
}

// Sub Component
function DomainStat({ src, text, total, bgColor, loading, isPercent }) {
  return (
    <StatContainer>
      <ImgCircleBg style={{ backgroundColor: bgColor }}>
        <img src={src} />
      </ImgCircleBg>
      <TextContainer>
        <CardTitle>{text}</CardTitle>
      </TextContainer>
      <TextContainer>
        <CardValue>{!loading ? total : "0"}</CardValue>
      </TextContainer>
    </StatContainer>
  );
}

// Main Component CSS
const ResponsiveGrid = styled.div`
  padding-top: 1rem;
  display: grid;
  gap: 1rem 0.5rem;
  grid-template-columns: 1fr 1fr 1fr;
  justify-items: center;
  @media screen and (min-width: 600px) {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
  @media screen and (min-width: 900px) {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  }
  @media screen and (min-width: 1400px) {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  }
`;

const FlatCard = styled.div`
  margin-bottom: 1rem;
  width: 100%;
  background-color: white;
  padding: 0.5rem;
  border-radius: 2px; /* 2px rounded corners */
  @media screen and (min-width: 900px) {
    padding: 1rem;
  }
`;

// Subcomponent CSS
const StatContainer = styled.div`
  display: flex;
  flex-direction: column;
  // justify-content: space-between;
  align-items: center;
  justify-content: space-between;
  gap: 0.5rem;
  width: 100px;
`;

const ImgCircleBg = styled.div`
  display: flex;
  padding: 1rem;
  justify-content: center;
  align-items: center;
  height: 50px;
  width: 50px;
  border-radius: 50%;
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
`;

export default WellBeingImprovements;
