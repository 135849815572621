import React from "react";
import * as S from "./empty.styled";
export const Empty = ({ src, text, btn = undefined, ...otherProps }) => {
  return (
    <S.Container {...otherProps}>
      <S.Content>
        <S.Img src={src} />
        <S.SubHeading>{text}</S.SubHeading>
        {btn !== undefined ? btn : undefined}
      </S.Content>
    </S.Container>
  );
};

export default Empty;
