import { useEffect, useState } from "react";
import { useStrapi } from "../../hooks/useStrapi";

export const useAboutCMS = () => {
  const init = {
    heading: "About MyWayfinder",
    items: [
      {
        id: 1,
        title: "About MyWayfinder",
        content:
          "The greater Rochester community is working across a diverse network of committed providers and community members to put people at the center of care by better connecting education, health, and human services. \n\nWith MyWayfinder, you can build your own path to reach your personal goals. With access to tools and services, you can feel empowered to connect with resources that meet your needs.\n\nImagine having your own care team! It's easy to find local providers and programs using the free MyWayfinder community resource directory.\n\nWith MyWayfinder, you can view your own private dashboard to manage your relationships or track your progress. The power is yours. You can control who sees your personal information by adding or removing relationships as your needs change.\n\nBy using MyWayfinder, you can find the support you need on your journey to better health and well-being."
      },
      {
        id: 2,
        title: "About the Systems Integration Project",
        content:
          "The Systems Integration Project is part of a community-wide effort to empower individuals and families by giving them access to a person-centered system.\n\nBy using what is already working well, identifying and removing barriers, and helping individuals better connect with education, health, and human services, the Systems Integration Project and its partners are working with community members, nonprofits, and government agencies to build a more supportive community where the focus is on putting people at the center - no matter their level of need."
      },
      {
        id: 3,
        title: "What does “systems integration” mean to you?",
        content:
          "Think of “systems integration” as building blocks or Legos. You can’t do too much when you use them one at a time. If you take the blocks, stack them, and link them together, you can create amazing things. Imagine what you could build!\n\nWhat if you gather all of our community’s resources, services, and opportunities in one place and link them together? You can build a system that works together—this is systems integration on a community level.\n\nBy integrating supports like healthcare, transportation, and employment resources in the same system, you can benefit from accessing a variety of services that work together. Imagine what you could build!"
      }
    ],
    background_image: {small: {url:""}, medium: {url: ""}, large: {url: ""}}
  };
  const { data, loading } = useStrapi({
    cms: `page-about`
  });

  const [about, setAbout] = useState(init);

  useEffect(() => {
    if (loading) {
      setAbout(init);
      return;
    }
    setAbout((state) => ({
      heading: data.heading || state.heading,
      items: data.items || state.items,
      background_image: data.background_image?.data?.attributes?.formats || state.background_image
    }));
  }, [data]);

  return { about };
};
