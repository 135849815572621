import React, { useEffect, useRef } from "react";
import ReactMarkdown from "react-markdown";
import { useTermsCMS } from "./cms";
import * as S from "./styled";

const TermsOfUsePage = () => {
  const { cms } = useTermsCMS();
  const windowRef = useRef(null);

  useEffect(() => {
    windowRef.current.scrollIntoView();
  }, []);

  return (
    <S.PageContainer>
      <S.TermsAndConditionsContainer ref={windowRef}>
        <S.Item>
          <S.TermsAndConditionsHeader>{cms.heading}</S.TermsAndConditionsHeader>
          <ReactMarkdown>{cms.text_please_read}</ReactMarkdown>
        </S.Item>
        <S.Item>
          <span>
            <h5>{cms.heading_using_accessing}</h5>
          </span>
          <br />
          {cms.text_using_and_accessing.split("\n").map((text, i) => (
            <div key={`using-and-accessing-${i}`}>
              <ReactMarkdown>{text}</ReactMarkdown>
              <br />
            </div>
          ))}
        </S.Item>
        {cms.items.map((el, idx) => (
          <S.Item key={idx}>
            <S.Header>{el.title}</S.Header>
            <ReactMarkdown className="line-break">{el.content}</ReactMarkdown>
          </S.Item>
        ))}
      </S.TermsAndConditionsContainer>
    </S.PageContainer>
  );
};

export default TermsOfUsePage;
