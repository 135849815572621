import React, { useState, useContext } from "react";
import ReactDOMServer from "react-dom/server";
import styled from "styled-components";
import { Modal, TextArea, InlineLoading } from "carbon-components-react";
import kit from "ich-ui-kit";
import { TextInput } from "carbon-components-react";
import { shouldAutoComplete } from "../../../../utils";

const ShareAssessmentModal = ({ modalOpen, setModalOpen, page }) => {
  const [textAreaValue, setTextAreaValue] = useState("");
  const [subjectAreaValue, setSubjectAreaInput] = useState("");
  const [invalidTextInput, setInvalidTxtInput] = useState(false);
  const [disableSubmit, setDisableSubmit] = useState(false);
  const [status, setStatus] = useState("");
  const [currentIndex, setIndex] = useState(0);
  const { apiService } = useContext(kit.ApiContext);
  const { user } = useContext(kit.UserContext);
  let _index = currentIndex;

  const renderContent = (_currentIndex) => {
    switch (_currentIndex) {
      case 0:
        return (
          <TextAreaWrapper>
            <div>
              <StyledTextInput
                disabled
                labelText="Subject"
                id="subjectInput"
                value="MyWayFinder Assessment Results"
                autoComplete={shouldAutoComplete}
              />

              <TextArea
                id="textAreaInput"
                onChange={handleChange}
                cols={50}
                value={textAreaValue}
                invalidText="A valid value is required"
                invalid={invalidTextInput}
                helperText="Add a comma after each email address to share to multiple emails"
                labelText="Enter email address"
                placeholder="example: JohnDoe@email.com, JaneDoe@email.com"
                rows={6}
                autoComplete={shouldAutoComplete}
              />
            </div>
          </TextAreaWrapper>
        );
      case 1:
        return (
          <SmallModalDiv>
            <InlineLoading
              status={status}
              description={
                status === "active"
                  ? "Submitting"
                  : status === "finished"
                  ? "Email successfully sent! Email may have been sent to Spam/Promotions folder."
                  : "Oops.. looks like something went wrong."
              }
            />
          </SmallModalDiv>
        );
      default:
        setStatus("error");
        break;
    }
  };

  const handleChange = (e) => {
    if (invalidTextInput) {
      setInvalidTxtInput(false);
    }
    setTextAreaValue(e.target.value);
  };

  const handleSubmit = (_page) => {
    let emails = textAreaValue.trim().split(",");
    let validEmails;
    emails.every((value) => {
      if (!/\S+@\S+\.\S+/.test(value) || value === "") {
        setInvalidTxtInput(true);
        return (validEmails = false);
      }
      return (validEmails = true);
    });
    if (validEmails) {
      _index += 1;
      setIndex(_index);
      setStatus("active");
      sendEmails(emails, _page);
      setDisableSubmit(true);
    }
  };

  const sendEmails = async (_emails, _page) => {
    const staticMarkup = ReactDOMServer.renderToStaticMarkup(_page);
    var data = JSON.stringify(staticMarkup);
    const response = await apiService.post(`/assessments/share?assumedId=${user.superAssumedRole?.leadId || ""}`, {
      emails: _emails,
      value: data,
    });
    if (response.ok) {
      setStatus("finished");
    } else {
      setStatus("error");
    }
  };

  const handleClose = () => {
    if (invalidTextInput) {
      setInvalidTxtInput(false);
    }
    setTextAreaValue("");
    setModalOpen(false);
    setIndex(0);
    setDisableSubmit(false);
  };

  return (
    <StyledModal
      open={modalOpen}
      modalHeading="Share your Assesment Results"
      size="sm"
      primaryButtonText="Share Assessment"
      secondaryButtonText="Cancel"
      hasScrollingContent={false}
      hasForm={true}
      onRequestClose={handleClose}
      onRequestSubmit={() => handleSubmit(page)}
      primaryButtonDisabled={disableSubmit}
    >
      {renderContent(currentIndex)}
    </StyledModal>
  );
};
export default ShareAssessmentModal;

export const TextAreaWrapper = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  width: 80%;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`;

const StyledModal = styled(Modal)`
  .bx--modal-content {
    margin-bottom: 1rem;
    height: 25rem;
    @media screen and (max-width: 376px) {
      height: auto;
    }
  }
  .bx--modal-container {
    @media screen and (width: 375px) {
      height: 90%;
    }
    @media screen and (max-width: 416px) {
      height: 80%;
      width: 95%;
      top: 10%;
    }
  }
`;

export const SmallModalDiv = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`;

const StyledTextInput = styled(TextInput)`
  margin-bottom: 1rem;
`;
