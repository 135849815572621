import { TextInput } from 'carbon-components-react';
import { shouldAutoComplete } from '../../utils';

// eslint-disable-next-line react/display-name
const PhoneInput = ({
  register,
  errors,
  requestClientPhone,
  isConsentCaptured,
  invalidText,
}) => {
  return (
    <TextInput
      id="phone-field"
      labelText="Contact number"
      invalid={errors.phone}
      invalidText={invalidText}
      disabled={!isConsentCaptured}
      placeholder="000-000-0000"
      defaultValue={requestClientPhone}
      {...register}
      onChange={(e) => {
        const rawValue = e.target.value
          .replace(/\D/g, "")
          .match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
        const parsedValue = !rawValue[2]
          ? rawValue[1]
          : rawValue[1] +
            "-" +
            rawValue[2] +
            (rawValue[3] ? "-" + rawValue[3] : "");
        e.target.value = parsedValue;
        register.onChange(e);
      }}
      autoComplete={shouldAutoComplete}
    />
  );
};

export default PhoneInput;
