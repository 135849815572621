import {
  Bookmark20,
  BookmarkFilled20,
  CircleFilled16
} from "@carbon/icons-react";
import {
  DataTable,
  DataTableSkeleton,
  OverflowMenu,
  OverflowMenuItem,
  Search,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableHeader,
  TableRow,
  TableSelectRow,
  TableToolbar,
  TableToolbarContent,
  TableToolbarSearch,
  ToastNotification
} from "carbon-components-react";
import kit from "ich-ui-kit";
import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import LabelDropdown from "./AllClientsSubComponents/LabelDropdown.jsx";
import { AllClientsDiv, DivHeader } from "./static.jsx";
import { modifyDoB } from "../../../utils";

const AllClients = ({
  loading,
  clients,
  clientDashboard,
  createReferral,
  clientSelect,
  setClient,
  containerStyle
}) => {
  const { user } = useContext(kit.UserContext);
  const { apiService } = useContext(kit.ApiContext);
  const [finalClients, setFinalClients] = useState(clients);
  const [bookmarkedClients, setBookmarkedClients] = useState([]);
  const [bookmarkLoading, setBookmarkLoading] = useState(false);
  const [resetPasswordSent, setResetPasswordSent] = useState("");
  const [resetPasswordLoading, setResetPasswordLoading] = useState(false);

  useEffect(() => {
    if (clients) {
      let cleanedClients = clients;

      cleanedClients = cleanedClients.map((client) => ({
        bookmarked: client.isBookmarked || false,
        id: client.id,
        lastLogin: client.lastLogin ? client.lastLogin : "Profile Incomplete",
        fullName: determineNameProp(client),
        email: client.email,
        phone: client.phone && client.phone !== 'Not found' ? client.phone : "Profile Incomplete",
        dob: modifyDoB(client?.dateOfBirth || client?.dob) || "Profile Incomplete",
        action: client.labelStatus,
        nickName: client.aliasName,
      }));
      setFinalClients(cleanedClients);
    }
  }, [clients, bookmarkedClients]);

  function determineNameProp(client) {
    if (
      client.firstName &&
      client.firstName !== "Not Found" &&
      client.lastName !== "Not Found"
    ) {
      return client.firstName + " " + client.lastName;
    } else {
      return "Profile Incomplete";
    }
  }

  const addBookmark = async (_id) => {
    try {
      setBookmarkLoading(true);
      const { id } = await apiService.post(
        `/bookmarks/clients?id=${_id}&assumedId=${
          user.superAssumedRole?.leadId || ""
        }`
      );
      if (id) {
        let currentClients = finalClients.map((c) => {
          if (c.id === id) {
            return { ...c, bookmarked: true };
          }
          return c;
        });

        currentClients = currentClients.sort((a, b) =>
          a.bookmarked > b.bookmarked ? -1 : 1
        );

        setFinalClients(currentClients);
      }
    } catch (err) {
      console.log(err);
    }
    setBookmarkLoading(false);
  };

  const removeBookmark = async (_id) => {
    try {
      setBookmarkLoading(true);
      const { id } = await apiService.delete(
        `/bookmarks/clients?id=${_id}&assumedId=${
          user.superAssumedRole?.leadId || ""
        }`
      );
      if (id) {
        let currentClients = finalClients.map((c) => {
          if (c.id === id) {
            return { ...c, bookmarked: false };
          }
          return c;
        });
        setFinalClients(currentClients);
      }
    } catch (err) {
      console.log(err);
    }
    setBookmarkLoading(false);
  };

  const displayBookmark = (isBookmarked) => {
    if (isBookmarked) {
      return true;
    }
    return false;
  };

  // Reset Passwordd
  const passwordReset = async (email) => {
    setResetPasswordLoading(true);
    if (email.includes("@")) {
      let response = await fetch(
        process.env.REACT_APP_API_ENDPOINT + "/iam/reset-password",
        {
          method: "post",
          headers: {
            "Content-Type": "application/json"
          },
          body: JSON.stringify({ email })
        }
      );
      if (response.status === 200) {
        setResetPasswordSent("success");
      } else {
        setResetPasswordSent("error");
      }
    } else {
      setResetPasswordSent("error");
    }
    setResetPasswordLoading(false);
    setTimeout(() => {
      setResetPasswordSent("");
    }, 4000);
  };

  // Add/Remove/Change labels
  const addLabelToDb = async (clientId, status) => {
    await apiService.post(
      `/labels/clients?assumedId=${user.superAssumedRole?.leadId || ""}`,
      {
        clientId: clientId,
        status: status
      }
    );
  };

  const changeLabelToDb = async (clientId, status) => {
    await apiService.put(
      `/labels/clients?assumedId=${user.superAssumedRole?.leadId || ""}`,
      {
        clientId: clientId,
        status: status
      }
    );
  };

  const removeLabelFromDb = async (clientId, rowNum) => {
    let list = [...finalClients];
    let changedStatus = { ...list[rowNum] };
    await apiService.delete(
      `/labels/clients?assumedId=${user.superAssumedRole?.leadId || ""}`,
      {
        clientId: clientId
      }
    );
    changedStatus.action = "";
    list[rowNum] = changedStatus;
    setFinalClients(list);
  };

  const changeLabel = (color, clientId, rowNum) => {
    let list = [...finalClients];
    let changedStatus = { ...list[rowNum] };
    if (!finalClients[rowNum].action) {
      addLabelToDb(clientId, color);
    } else {
      changeLabelToDb(clientId, color);
    }
    changedStatus.action = color;
    list[rowNum] = changedStatus;
    setFinalClients(list);
  };

  // Determine label color
  const determineColor = (color) => {
    switch (color.toLowerCase()) {
      case "red":
        return "#CB0000";
      case "yellow":
        return "#DAD600";
      case "green":
        return "#1C7B00";
    }
  };

  if (clientSelect) {
    const headers = [
      {
        key: "id"
      },
      {
        key: "fullName",
        header: "Full Name"
      },
      {
        key: "dob",
        header: "Birthday"
      },
      {
        key: "phone",
        header: "Phone"
      },
      {
        key: "email"
      }
    ];

    return (
      <AllClientsDiv
        style={{
          ...containerStyle,
          margin: "0",
          padding: "1rem"
        }}
      >
        {loading ? (
          <>
            <Search
              labelText="Search"
              placeholder="Search for a client here..."
              disabled
              style={{
                borderBottom: "1px solid black"
              }}
            />
            <DataTableSkeleton
              showToolbar={false}
              showHeader={false}
              headers={headers.slice(1)}
              columnCount={3}
              rowCount={6}
              style={{
                position: "relative",
                bottom: "0.125rem"
              }}
            />
          </>
        ) : (
          <DataTable rows={finalClients} headers={headers} isSortable radio>
            {({
              rows,
              headers,
              getTableProps,
              getHeaderProps,
              getRowProps,
              onInputChange,
              getSelectionProps,
              getTableContainerProps
            }) => (
              <>
                <TableToolbar style={{ borderBottom: "1px solid #000" }}>
                  <TableToolbarContent>
                    <TableToolbarSearch
                      onChange={onInputChange}
                      placeholder="Search for a client here..."
                      persistent={true}
                    />
                  </TableToolbarContent>
                </TableToolbar>
                <TableContainer
                  {...getTableContainerProps()}
                  style={{
                    maxHeight: "360px",
                    overflow: "scroll",
                    paddingTop: "0"
                  }}
                >
                  <Table {...getTableProps()}>
                    <TableHead>
                      <TableRow>
                        {headers.map((header) => (
                          <TableHeader
                            {...getHeaderProps({
                              header
                            })}
                            isSortable={header.isSortable}
                            isSortHeader={header.isSortHeader}
                            sortDirection={header.sortDirection}
                          >
                            {header.header}
                          </TableHeader>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {rows.map((row, i) => {
                        return (
                          <TableRow
                            {...getRowProps({ row })}
                            key={`${i}:${row.id}`}
                            onClick={() =>
                              setClient({
                                id: row.id,
                                givenName:
                                  row.cells[1].value.split(" ")[0] ||
                                  "First Name",
                                familyName:
                                  row.cells[1].value.split(" ")[1] ||
                                  "Last Name"
                              })
                            }
                          >
                            <TableSelectRow {...getSelectionProps({ row })} />
                            <TableCell key={`${i}:${row.id}:fullName`}>
                              {row.cells[1].value}
                            </TableCell>
                            <TableCell key={`${i}:${row.id}:dob`}>
                              {row.cells[2].value}
                            </TableCell>
                            <TableCell key={`${i}:${row.id}:phone`}>
                              {row.cells[3].value}
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
                {finalClients.length === 0 && (
                  <div
                    style={{
                      position: "relative",
                      bottom: "15rem",
                      textAlign: "center"
                    }}
                  >
                    <p>
                      Error retrieving data. <br />
                      Please refresh or try again later.
                    </p>
                  </div>
                )}
              </>
            )}
          </DataTable>
        )}
      </AllClientsDiv>
    );
  } else {
    const headers = [
      {
        key: "id"
      },
      {
        key: "bookmarked"
      },
      {
        key: "fullName",
        header: "Full Name"
      },
      {
        key: "nickName",
        header: "Preferred Name"
      },
      {
        key: "dob",
        header: "Date of Birth"
      },
      {
        key: "email",
        header: "Email"
      },
      {
        key: "phone",
        header: "Phone"
      },
      {
        key: "action",
        header: "Actions"
      }
    ];

    return (
      <>
        <AllClientsDiv>
          <DivHeader style={{ marginTop: "1rem" }}>
            <h5>All Clients</h5>
          </DivHeader>
          {loading ? (
            <>
              <Search
                labelText="Search"
                placeholder="Search for a client here..."
                disabled
              />
              <DataTableSkeleton
                showToolbar={false}
                showHeader={false}
                headers={headers.slice(2)}
                columnCount={6}
                style={{
                  position: "relative",
                  bottom: "0.125rem"
                }}
              />
            </>
          ) : (
            <DataTable rows={finalClients} headers={headers} isSortable>
              {({
                rows,
                headers,
                getTableProps,
                getHeaderProps,
                getRowProps,
                onInputChange
              }) => (
                <TableContainer>
                  <TableToolbar>
                    <TableToolbarContent>
                      <TableToolbarSearch
                        onChange={onInputChange}
                        placeholder="Search for a client here..."
                        persistent={true}
                      />
                    </TableToolbarContent>
                  </TableToolbar>
                  <Table {...getTableProps()}>
                    <TableHead>
                      <TableRow>
                        {headers.slice(2).map((header) => (
                          <TableHeader
                            {...getHeaderProps({
                              header
                            })}
                            isSortable={header.isSortable}
                            isSortHeader={header.isSortHeader}
                            sortDirection={header.sortDirection}
                          >
                            {header.header}
                          </TableHeader>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {rows.map((row, i) => {
                        return (
                          <TableRow
                            {...getRowProps({ row })}
                            key={`${i}:${row.id}`}
                          >
                            <TableCell key={`${i}:${row.id}:fullName`}>
                              {displayBookmark(row.cells[1].value) ? (
                                <StyledBookmarkFilled
                                  disabled={bookmarkLoading}
                                  onClick={() =>
                                    removeBookmark(row.cells[0].value)
                                  }
                                />
                              ) : (
                                <StyledBookmark
                                  disabled={bookmarkLoading}
                                  onClick={() =>
                                    addBookmark(row.cells[0].value)
                                  }
                                />
                              )}
                              {row.cells[2].value === "Not Available" || 
                              row.cells[2].value === "Profile Incomplete"  ? (
                                <StyledTableCellText>
                                  {row.cells[2].value}
                                </StyledTableCellText>
                              ) : (
                                <a
                                  style={{ cursor: "pointer" }}
                                  onClick={() => clientDashboard(row.id)}
                                >
                                  <StyledTableCellText>
                                    {row.cells[2].value}
                                  </StyledTableCellText>
                                </a>
                              )}
                            </TableCell>
                            <TableCell key={`${i}:${row.id}:nickName`}>
                              {row.cells[3].value}
                            </TableCell>
                            <TableCell key={`${i}:${row.id}:dob`}>
                              {row.cells[4].value}
                            </TableCell>
                            <TableCell key={`${i}:${row.id}:email`}>
                              {row.cells[5].value}
                            </TableCell>
                            <TableCell key={`${i}:${row.id}:phone`}>
                              {row.cells[6].value}
                            </TableCell>
                            <TableCell key={`${i}:${row.id}:action`}>
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center"
                                }}
                              >
                                <OverflowMenu
                                  data-floating-menu-container
                                  flipped={true}
                                >
                                  <OverflowMenuItem
                                    itemText="Reset Password"
                                    style={{
                                      color: "#fff",
                                      backgroundColor: "rgb(203, 0, 0)"
                                    }}
                                    onClick={() =>
                                      passwordReset(row.cells[5].value)
                                    }
                                  />
                                  {(row.cells[2].value !== 'Profile Incomplete' 
                                  || row.cells[6].value !== 'Profile Incomplete') &&
                                    <>
                                    <OverflowMenuItem
                                      style={{
                                        color: "rgba(0,145,179,1)",
                                        backgroundColor: "#fff"
                                      }}
                                      itemText="Create Service Request"
                                      onClick={() => createReferral(row.id)}
                                    />
                                    <OverflowMenuItem
                                      style={{
                                        color: "rgba(0,145,179,1)",
                                        backgroundColor: "#fff"
                                      }}
                                      itemText="View Dashboard"
                                      onClick={() => clientDashboard(row.id)}
                                    />
                                    </>
                                  }
                                  <LabelDropdown
                                    onClick={changeLabel}
                                    removeLabel={removeLabelFromDb}
                                    clientId={row.id}
                                    rowNum={i}
                                    text={
                                      row.cells[7].value
                                        ? "Edit Label"
                                        : "Add Label"
                                    }
                                  />
                                </OverflowMenu>
                                {row.cells[7].value && (
                                  <CircleFilled16
                                    color={determineColor(row.cells[7].value)}
                                  />
                                )}
                              </div>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              )}
            </DataTable>
          )}
        </AllClientsDiv>

        {resetPasswordLoading && (
          <div
            style={{
              position: "absolute",
              top: "3rem",
              width: "100%",
              display: "flex",
              justifyContent: "center"
            }}
          >
            <ToastNotification kind="info" title="Sending email to client..." />
          </div>
        )}
        {resetPasswordSent === "success" && (
          <div
            style={{
              position: "absolute",
              top: "3rem",
              width: "100%",
              display: "flex",
              justifyContent: "center"
            }}
          >
            <ToastNotification
              kind="success"
              title="Success"
              subtitle="An email was successfully sent to the client."
            />
          </div>
        )}
        {resetPasswordSent === "error" && (
          <div
            style={{
              position: "absolute",
              top: "3rem",
              width: "100%",
              display: "flex",
              justifyContent: "center"
            }}
          >
            <ToastNotification
              kind="error"
              title="Error"
              subtitle="There was an error in sending the reset password email to the client."
            />
          </div>
        )}
      </>
    );
  }
};

const StyledBookmark = styled(Bookmark20)`
  cursor: pointer;
  position: relative;
  top: 2px;
  margin-right: 12px;
  transition: 0.2s;
  &:hover {
    color: rgba(0, 0, 0, 0.5);
  }
`;

const StyledBookmarkFilled = styled(BookmarkFilled20)`
  cursor: pointer;
  position: relative;
  top: 2px;
  margin-right: 12px;
  transition: 0.2s;
  &:hover {
    color: rgba(0, 0, 0, 0.5);
  }
`;

const StyledTableCellText = styled.span`
  position: relative;
  bottom: 2px;
`;

export default AllClients;
