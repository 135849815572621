import {
  Dropdown,
  InlineLoading,
  Link,
  Loading,
  ToastNotification
} from "carbon-components-react";
import kit from "ich-ui-kit";
import React, { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import DOMPurify from "dompurify";
import useForm from "../../hooks/useForm";
import {
  BackArrow,
  ButtonArrow,
  InputDiv,
  LoginButton,
  LoginContainer,
  LoginFormBox,
  LoginFormContainer,
  LoginTitleBox,
  StyledFluidForm,
  StyledLink,
  StyledPasswordInput,
  StyledTextInput
} from "./components";
import validate from "./validator";
import { useLoginCMS } from "./cms";
import { shouldAutoComplete, validateUrl } from "../../utils";

let clientPortalUrl = validateUrl(process.env.REACT_APP_CLIENT_URL) || "#";
clientPortalUrl = DOMPurify.sanitize(clientPortalUrl)


const LoginPage = ({channel}) => {
  const { login: loginCMS } = useLoginCMS();
  const mfaTextbox = useRef(null);
  const passwordInputRef = useRef();
  const [type, setType] = useState("password");
  const [error, setError] = useState(null);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [success, setSuccess] = useState(false);
  const [isPassSubmitted, setIsPassSubmitted] = useState(false);
  const [description, setDescription] = useState("Checking Username");
  const [codeSent, setCodeSent] = useState(false);
  const [resendCode, setResendCode] = useState(false);
  const [errorToast, setErrorToast] = useState(false);
  const [ariaLive, setAriaLive] = useState("off");
  const [reset, setReset] = useState(false);
  const [step, setStep] = useState(0);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [portalError, setPortalError] = useState();
  const [userId, setUserId] = useState();
  const [options, setOptions] = useState({
    user: {
      expanded: "block"
    },
    password: {
      expanded: "none"
    },
    verification: {
      expanded: "none"
    }
  });

  const [method, setMethod] = useState(
    {
      id: 1,
      text: "Send an Email",
      value: "email"
    }
  );
  const [methodItems, setMethodItems] = useState([
    { id: 1, text: "Send an Email", value: "email" }
  ]);

  const [sending, setSending] = useState(false);
  const [loggingIn, setLogginIn] = useState(false);
  const [isMfaSent, SetIsMfaSent] = useState(false);
  let history = useHistory();

  // For accepting/rejecting referrals from email (Awaiting confirmation from Eric - please do not delete)
  // TODO - accept originatorId, serviceId, and patientId from backend in URL search params
  const [actionFromEmailObj, setActionFromEmailObj] = useState();
  useEffect(() => {
    if (history.location.search.includes("actionFromEmail")) {
      const searchParams = history.location.search.split("&");
      setActionFromEmailObj({
        clientId: searchParams[1].split("=")[1],
        referralId: searchParams[2].split("=")[1]
      });
    }
  }, [history]);

  const {
    values,
    setValues,
    errors,
    isDisabled,
    setIsDisabled,
    handleChange,
    handleSubmit
  } = useForm(validate);

  useEffect(() => {
    passwordInputRef.current.removeAttribute("readonly");
  }, []);

  useEffect(() => {
    setValues({
      username: "",
      password: "",
      verification: ""
    });

    setIsDisabled({
      username: true,
      password: true,
      verification: true
    });
  }, [setValues, setIsDisabled]);
  useEffect(() => {
    switch (step) {
      case 1:
        setOptions({
          key: "",
          user: {
            expanded: "none"
          },
          password: {
            expanded: "block"
          },
          verification: {
            expanded: "none"
          }
        });
        break;
      case 2:
        setOptions({
          user: {
            expanded: "none"
          },
          password: {
            expanded: "none"
          },
          verification: {
            expanded: "block"
          }
        });
        break;
      default:
        setOptions({
          user: {
            expanded: "block"
          },
          password: {
            expanded: "none"
          },
          verification: {
            expanded: "none"
          }
        });

        break;
    }
  }, [step]);

  const togglePasswordVisibility = () => {
    setType(type === "password" ? "text" : "password");
  };

  const renderLoginPage = ({ user, updateUser }, apiService) => {
    const handleLogin = async (e) => {
      setMethod(
        {
          id: 1,
          text: "Send an Email",
          value: "email"
        }
      );
      let username = values.username.trim();
      let password = values.password.trim();
      setIsPassSubmitted(true);
      setAriaLive("assertive");
      try {
        let res = await fetch(
          process.env.REACT_APP_API_ENDPOINT + "/iam/provider/login",
          {
            credentials: "include",
            method: "POST",
            credentials: "include",
            headers: {
              "Content-Type": "application/json"
            },
            body: JSON.stringify({ username, password })
          }
        );

        let data = await res.json();
        if (res.status === 200) {
          if (step !== 2) {
            handleSteps();
          }

          if (data.phone !== "" && data.phone !== null) {
            setMethodItems((state) => [
              {
                id: 2,
                text: "Send an Email",
                value: "email"
              },
              {
                id: 1,
                text: "Send a Text",
                value: "text"
              }
            ]);
          }
          const method =
            data.mfaMethod === "text"
              ? { id: 2, text: "Send a Text", value: "text" }
              : { id: 1, text: "Send an Email", value: "email" };
          setMethod(method);
          setUserId(data.userId);
        } else if (
          data.message.toLowerCase().includes("expired")
        ) {
            setError('Password expired. Check your email for a link to reset your password.');
            toast(setErrorToast);
            passwordReset('expired');
        } else if (
          res.status === 403 &&
          data.message.toLowerCase().includes("deactivated")
        ) {
          setError(data.message);
          toast(setErrorToast);
        } else if (res.status === 403) {
          setPortalError(true);
        } else if (res.status === 400 && data.stack) {
          setError(data.message);
          toast(setErrorToast);
        } else if (res.status === 400) {
          setError(data.message);
          toast(setErrorToast);
        } else {
          setError(data.message);
          toast(setErrorToast);
        }
        setIsPassSubmitted(false);
      } catch (err) {
        setIsPassSubmitted(false);
        setError(err.message);
        toast(setErrorToast);
      }
    };

    const handleEnterLogin = async (e) => {
      if (e.key === "Enter" && !isDisabled.password) {
        handleLogin();
      }
    };

    const handleSendMfaCode = async () => {
      setSending(true);
      try {
        let username = values.username.trim();
        let password = values.password.trim();
        let response = await fetch(
          process.env.REACT_APP_API_ENDPOINT + "/iam/login/set-mfa",
          {
            credentials: "include",
            method: "POST",
            headers: {
              "Content-Type": "application/json"
            },
            Connection: "keep-alive",
            body: JSON.stringify({
              userId: userId,
              username: username,
              password: password,
              mfaMethod: method.value
            })
          }
        );
        const data = await response.json();
        if (response.status === 202) {
          setMessage(data.message);
          toast(setCodeSent);
          SetIsMfaSent(true);
          mfaTextbox.current.focus();
        } else {
          setError(data.message);
          toast(setErrorToast);
        }
      } catch (error) {
        console.log(error);
      } finally {
        setSending(false);
      }
    };

    const handleVerification = async (e) => {
      try {
        setLogginIn(true);
        let token = values.verification;
        let response = await fetch(
          process.env.REACT_APP_API_ENDPOINT + "/iam/login/verify-token",
          {
            method: "POST",
            credentials: "include",
            headers: {
              "Content-Type": "application/json"
            },
            body: JSON.stringify({ mfaCode: token, userId: userId })
          }
        );
        let data = await response.json();
        if (response.status === 200) {
          if (data.accessToken && data.idToken) {
            let userData = {
              username: values.username,
              ...data
            }
            await updateUser(userData);
            // Set user info in sessionStorage
            sessionStorage.setItem("penn-provider", JSON.stringify(userData));
            // Notify other tabs that user is logged in
            channel.postMessage({ user: JSON.stringify(userData), key: "user" });
            // updateuserdb -- lastlogin
            if (actionFromEmailObj) {
              // history.push({
              //   pathname: "/360dashboard/service-requests/view",
              //   state: actionFromEmailObj
              // });
              window.location.href = `/360dashboard/service-requests/view?patientId=${actionFromEmailObj.clientId}&referralId=${actionFromEmailObj.referralId}`;
            } else {
              window.location.href = "/";
            }
          }
        } else if (data.stack && data.stack.toLowerCase().includes("invalid")) {
          setError("Invalid Token!");
          toast(setErrorToast);
        } else {
          setError(data.message);
          toast(setErrorToast);
        }
      } catch (err) {
        console.log(err);
        setError(err.status);
        toast(setErrorToast);
      } finally {
        setLogginIn(false);
      }
    };

    const handleEnterVerification = async (e) => {
      if (e.key === "Enter" && !isDisabled.verification) {
        handleVerification();
      }
    };

    const handleUserNameSubmit = (e) => {
      handleSubmit();
      if (!errors.username) {
        setIsSubmitted(true);
        setAriaLive("assertive");
        setTimeout(() => {
          setIsSubmitted(false);
          setSuccess(true);
          setDescription("Submitted!");
          setTimeout(() => {
            setSuccess(false);
            setDescription("Submitting...");
            setAriaLive("off");
          }, 1500);
        }, 2000);

        setTimeout(() => {
          handleSteps();
        }, 2000);
      }
    };

    const handleEnterUserNameSubmit = (e) => {
      handleSubmit();
      if (!errors.username && e.key === "Enter" && !isDisabled.username) {
        handleUserNameSubmit();
      }
    };

    const passwordReset = async (type) => {
      let email = values.username;
      let response = await fetch(
        process.env.REACT_APP_API_ENDPOINT + "/iam/reset-password",
        {
          method: "post",
          headers: {
            "Content-Type": "application/json"
          },
          body: JSON.stringify({ email })
        }
      );
      if (response.status === 200) {
        toast(setReset);
      }
    };

    const emailEdit = () => {
      setStep(0);

      setIsDisabled({
        username: false,
        password: true,
        verification: true
      });
    };

    const handleSteps = () => {
      step <= 2 ? setStep(step + 1) : setStep(0);
    };

    const handleResendCode = () => {
      toast(setResendCode);
      handleLogin();
    };

    const handleMethodChange = (e) => {
      setMethod(e.selectedItem);
    };

    const toast = (set) => {
      set(true);
      setTimeout(() => {
        set(false);
      }, 10000);
    };

    return (
      <LoginContainer>
        <Loading withOverlay={true} active={loading} />
        <LoginFormBox>
          <LoginFormContainer>
            <h2>
              Log in to <strong>MyWayfinder</strong>
            </h2>
            <div>
              {reset && (
                <ToastNotification
                  kind="success"
                  title="Reset Email Sent"
                  caption="Please check your email for further instructions. Email may have been sent to Spam/Promotions folder."
                />
              )}
              {codeSent && (
                <ToastNotification
                  kind="success"
                  title="Access Code Sent"
                  caption={message}
                />
              )}
              {resendCode && (
                <ToastNotification
                  kind="info"
                  title="Access Code Resent"
                  caption={message}
                />
              )}
              {errorToast && (
                <ToastNotification
                  kind="error"
                  title="Error"
                  caption=""
                  subtitle={error}
                  onCloseButtonClick={() => setError(null)}
                />
              )}
              {portalError && (
                <ToastNotification
                  kind="error"
                  title="You are trying to login to the wrong portal"
                  caption=""
                  subtitle={
                    <Link href={clientPortalUrl}>
                      Continue To Client Portal
                    </Link>
                  }
                  onCloseButtonClick={() => setPortalError(null)}
                />
              )}
            </div>
            <StyledFluidForm autoComplete="new-password">
              <div
                id="username-div"
                style={{ display: `${options.user.expanded}` }}
              >
                <InputDiv>
                  <p>Enter your email</p>
                  <StyledTextInput
                    data-id={`login-email-input`}
                    id="username"
                    labelText="Email"
                    onChange={(e) => {
                      handleChange(e);
                      setPortalError(false);
                    }}
                    onKeyPress={handleEnterUserNameSubmit}
                    autoFocus
                    autoComplete={"on"}
                  />
                  {isSubmitted || success ? (
                    <InlineLoading
                      style={{ marginLeft: "1rem" }}
                      description={description}
                      status={success ? "finished" : "active"}
                      aria-live={ariaLive}
                    />
                  ) : (
                    <LoginButton
                      data-id={`login-continue-btn`}
                      disabled={isDisabled.username}
                      onClick={handleUserNameSubmit}
                    >
                      Continue <ButtonArrow />
                    </LoginButton>
                  )}
                </InputDiv>
              </div>
              <div
                id="password-div"
                style={{ display: `${options.password.expanded}` }}
              >
                <StyledLink onClick={() => passwordReset('forgot')}>
                  Forgot password?
                </StyledLink>
                <InputDiv>
                  <p>
                    <BackArrow onClick={emailEdit} />
                    {values.username}
                  </p>
                  <StyledPasswordInput
                    id="password"
                    data-id={`login-password-input`}
                    style={{ marginBottom: 0 }}
                    labelText="Password"
                    onChange={(e) => {
                      handleChange(e);
                      setPortalError(false);
                    }}
                    onKeyPress={handleEnterLogin}
                    type={type}
                    onTogglePasswordVisibility={togglePasswordVisibility}
                    tooltipAlignment="end"
                    autoComplete="new-password"
                    ref={passwordInputRef}
                    readOnly
                    onFocus={() =>
                      passwordInputRef.current.removeAttribute("readonly")
                    }
                    onClick={() =>
                      passwordInputRef.current.removeAttribute("readonly")
                    }
                  />

                  {isPassSubmitted ? (
                    <InlineLoading
                      style={{ marginLeft: "1rem" }}
                      description="Verifying..."
                      status={!isPassSubmitted ? "finished" : "active"}
                      aria-live={ariaLive}
                    />
                  ) : (
                    <LoginButton
                      data-id={`login-continue-btn`}
                      disabled={isDisabled.password}
                      onClick={handleLogin}
                    >
                      Continue <ButtonArrow />
                    </LoginButton>
                  )}
                </InputDiv>
              </div>
              <div
                id="verification-div"
                style={{ display: `${options.verification.expanded}` }}
              >
                {/* <StyledLink onClick={handleResendCode}>
                  Resend Access Code
                </StyledLink> */}
                <InputDiv>
                  <div
                    style={{
                      position: "relative"
                    }}
                  >
                    <p
                      style={{
                        fontSize: "0.8rem"
                      }}
                    >
                      Select where you would like MyWayfinder to send your
                      access code
                    </p>
                    <Dropdown
                      data-id={`login-mfa-dropdown`}
                      id="method-dropdown"
                      label="Select method of MFA"
                      items={methodItems}
                      selectedItem={method}
                      itemToString={(item) => (item ? item.text : "")}
                      onChange={handleMethodChange}
                    />

                    {sending ? (
                      <InlineLoading description="Sending..." />
                    ) : (
                      <LoginButton
                        data-id={`login-continue-btn`}
                        size="sm"
                        onClick={handleSendMfaCode}
                        disabled={sending}
                        renderIcon={ButtonArrow}
                        kind={isMfaSent ? "tertiary" : "primary"}
                        style={{ color: "#ffffff" }}
                      >
                        {!isMfaSent ? "Send Access Code" : "Resend Access Code"}
                      </LoginButton>
                    )}
                  </div>
                  <br />
                  <p>
                    <BackArrow onClick={emailEdit} data-id={`login-back-btn`} />
                    {values.username}
                  </p>
                  {isMfaSent ? (
                    <>
                      <StyledTextInput
                        data-id={`login-mfa-input`}
                        ref={mfaTextbox}
                        id="verification"
                        labelText="Access Code"
                        maxLength={6}
                        type="text"
                        onChange={(e) => {
                          e.target.value = e.target.value.trim();
                          handleChange(e);
                        }}
                        onKeyPress={handleEnterVerification}
                        autoComplete={shouldAutoComplete}
                      />
                      {loggingIn ? (
                        <InlineLoading description="Logging in..." />
                      ) : (
                        <LoginButton
                          data-id={`login-submit-btn`}
                          disabled={isDisabled.verification}
                          onClick={handleVerification}
                        >
                          Login
                        </LoginButton>
                      )}
                    </>
                  ) : undefined}
                </InputDiv>
              </div>
            </StyledFluidForm>
          </LoginFormContainer>
        </LoginFormBox>
        <LoginTitleBox
                  bgImage={
                    loginCMS.background_image
                      ? `${process.env.REACT_APP_STRAPI_ENDPOINT}${
                          window.innerWidth < 400
                            ? loginCMS.background_image?.small?.url
                            : window.innerWidth < 600
                            ? loginCMS.background_image?.medium?.url
                            : loginCMS.background_image?.large?.url
                        }`
                      : ""}
        ></LoginTitleBox>
      </LoginContainer>
    );
  };
  return (
    <kit.ApiConsumer>
      {({ apiService }) => (
        <kit.UserConsumer>
          {(userState) => renderLoginPage(userState, apiService)}
        </kit.UserConsumer>
      )}
    </kit.ApiConsumer>
  );
};

export default LoginPage;
