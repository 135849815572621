import styled from "styled-components";
import React, { useState, useContext, useEffect } from "react";
import kit from "ich-ui-kit";
import S from "./needsAssessmentModal.module.scss"

import {
  TextArea,
  TextInput,
  InlineLoading,
  InlineNotification,
  Checkbox
} from "carbon-components-react";
import {
  FormInputContainer,
  FormEmailContainer,
  FormInputAreaContainer,
  StyledModal as Modal,
  ModalContentWrapper,
  Content,
  DisclaimerWrapper
} from "./static";
import validate from "./validator";
import { createPortal } from "react-dom";
import { shouldAutoComplete } from "../../utils";
const mount = document.getElementById("modal-root");

const NeedsAssesmentModal = (props) => {
  const { apiService } = useContext(kit.ApiContext);
  const { user } = useContext(kit.UserContext);
  const [hasConsent, setHasConsent] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isError, setIsError] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({
    email: "",
    emailcc: "",
    phone: "",
    message: "",
  });
  const [input, setInput] = useState({
    providerName: props.providerName || "",
    serviceName: props.serviceName || "",
    to: props.orgEmail || "",
    cc:  user?.username || "",
    name: user?.aliasName || user?.name || "",
    phone: props.patient?.phone || "",
    email: props.patient?.email || props.patient?.username || "",
    message: "",
    patientId: props.patient?.id || "",
    serviceId: props.serviceId || "",
    orgSource: props.orgSource || "",
    orgKey: props.orgKey,
    locationId: props.locationId || "",
  });

  useEffect(() => {
    setInput({
      ...input,
      providerName: props.providerName,
      cc:  user?.username,
      serviceName: props.serviceName,
      to: props.orgEmail,
      name: user?.aliasName || user?.name || "",
      phone: props.patient?.phone || "",
      email: props.patient?.email || "",
      patientId: props.patient?.id || ""
    });
  }, [props]);

  const changeInput = (id, value) => {
    setInput({ ...input, [id]: value });
  };

  const changePhoneInput = (id, value) => {
    let x = value.replace(/\D/g, "").match(/(\d{0,3})(\d{0,3})(\d{0,4})/);

    setInput((values) => ({
      ...values,
      [id]: (value = !x[2]
        ? x[1]
        : x[1] + "-" + x[2] + (x[3] ? "-" + x[3] : ""))
    }));
  };

  const handleSubmit = () => {
    if (currentIndex === 0) {
      handlePrimarySubmit();
    } else {
      handleRequestClose();
    }
  };

  const handlePrimarySubmit = async () => {
    const errs = validate(input);
    setErrors(errs);
    if (Object.keys(errs).length === 0) {
      setLoading(true);
      try {
        input.cc =  input.cc.split(',').map(email => email.trim()).join(',');
        input.email = input.email.split(',').map(email => email.trim()).join(',');
        const data = await apiService.post(`/needsrequest?assumedId=${user.superAssumedRole?.leadId || ""}`, {
          ...input,
          cc: input.cc || user?.username || "",
          initiatorId: user?.id
        });
        if (data.ok) {
          setCurrentIndex(1);
          setLoading(false);
          setIsSuccess(true);
          setTimeout(() => {
            handleRequestClose();
          }, 4000);
          setInput({
            ...input,
            name: "",
            phone: "",
            email: "",
            cc: "",
            message: ""
          });
        } else setIsError(true);
      } catch (err) {
        console.log(err);
        setIsError(true);
        setHasConsent(false);
      }
      setLoading(false);
    }
  };

  const handleRequestClose = () => {
    props.setOpen(false);
    // Adding slight delay between closing modal and reverting state
    setTimeout(() => {
      setHasConsent(false);
      setCurrentIndex(0);
      setErrors({ email: "", phone: "", message: "" });
      setIsError(false);
      setIsSuccess(false);
      setInput({
        ...input,
        phone: "",
        email: "",
        message: ""
      });
    }, 1000);
  };

  const renderSuccess = () => {
    if (!isError) {
      return (
        <ContentContainer>
          <img src="/high-five.png" />
          <div>
            <InlineLoading
              description="Your needs request was submitted successfully."
              status="finished"
            />
          </div>
        </ContentContainer>
      );
    } else {
      return (
        <ContentContainer>
          <div>
            <InlineLoading
              description="There was error while submitting the needs request. Please try again."
              status="error"
            />
          </div>
        </ContentContainer>
      );
    }
  };

  const renderForm = () => {
    return (
      <Content className={S.container}>
        {!props.isConnected && (
          <DisclaimerWrapper>
            Disclaimer: Since this provider isn't connected to the MyWayfinder network,
            it might take longer for them to respond to your needs request. For the most
            immediate attention, it is advisable to call this provider.
          </DisclaimerWrapper>
        )}
        <FormEmailContainer>
          <TextInput
            data-id={`needs-request-email-to-input`}
            id="to-email"
            labelText="Provider Email"
            value={props.orgEmail || ""}
            disabled
            autoComplete={shouldAutoComplete}
          />
        </FormEmailContainer>
        <FormInputContainer>
          <TextInput
            data-id={`needs-request-email-from-input`}
            id="email"
            labelText="Contact Email"
            title=""
            placeholder="Email"
            value={input.email || ""}
            invalidText={errors.email}
            invalid={!!errors.email}
            onChange={(e) => {
              changeInput(e.target.id, e.target.value)
            }}
            disabled={loading}
            autoComplete={shouldAutoComplete}
          />
        </FormInputContainer>
        <FormInputContainer>
          <TextInput
              data-id={`needs-request-email-cc-from-input`}
              id="cc"
              placeholder="Email(s)"
              labelText="Optional: Send a copy to additional email addresses (max. 3)"
              title=""
              value={input.cc || ""}
              invalidText={errors.cc}
              invalid={!!errors.cc}
              onChange={(e) => {
                changeInput(e.target.id, e.target.value)
              }}
              disabled={loading}
              autoComplete={shouldAutoComplete}
            />
        </FormInputContainer>
        <FormInputContainer>
          <TextInput
            data-id={`needs-request-number-input`}
            id="phone"
            labelText="Client Phone Number"
            title=""
            placeholder="xxx-xxx-xxxx"
            maxLength={12}
            value={input.phone || ""}
            onChange={(e) => changePhoneInput(e.target.id, e.target.value)}
            invalidText={errors.phone}
            invalid={!!errors.phone}
            disabled={loading}
            autoComplete={shouldAutoComplete}
          />
        </FormInputContainer>

        <FormInputAreaContainer>
          <TextArea
            data-id={`needs-request-message-input`}
            id="message"
            labelText="Message"
            placeholder={loading ? "" : "Please enter your message"}
            value={input.message || ""}
            onChange={(e) => changeInput(e.target.id, e.target.value)}
            maxLength="200"
            helperText={`${input.message?.length}/200`}
            invalidText={errors.message}
            invalid={!!errors.message}
            disabled={loading}
            autoComplete={shouldAutoComplete}
          />
        </FormInputAreaContainer>

        {/* <FormInputAreaContainer style={{marginTop: "1rem"}} onClick={() => setHasConsent(!hasConsent)}>
          <Checkbox 
            checked={hasConsent}
            labelText={`I have ${props.patient.givenName && props.patient?.givenName + "'s"} consent to send this Needs Request.`}
          />
        </FormInputAreaContainer> */}
        {isError && (
          <InlineNotification
            kind="error"
            title="Error"
            subtitle="There was error while submitting the needs request"
          />
        )}
      </Content>
    );
  };

  return createPortal(
    <Modal
      data-id={`needs-request-modal`}
      key="needs-assessment-modal"
      aria-label="Needs Request Modal"
      open={props.open}
      size="sm"
      primaryButtonDisabled={
        loading ||
        (!isSuccess && input.phone.length !== 12) ||
        (!isSuccess && input.message.length < 3)
      }
      modalHeading={props.modalHeading}
      modalLabel=""
      shouldSubmitOnEnter={false}
      hasForm
      hasScrollingContent={false}
      primaryButtonText={
        currentIndex === 0 || loading ? (
          loading ? (
            <InlineLoading description="Sending..." />
          ) : (
            "Send"
          )
        ) : (
          "Done"
        )
      }
      secondaryButtonText="Cancel"
      onRequestSubmit={handleSubmit}
      onRequestClose={handleRequestClose}
      isConnected={props.isConnected}
    >
      <ModalContentWrapper>
        {currentIndex === 0 || loading ? renderForm(hasConsent, setHasConsent) : renderSuccess()}
      </ModalContentWrapper>
    </Modal>,
    mount
  );
};

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;

  && img {
    width: 9rem;
    margin: 4rem auto;
  }

  && div {
    display: inline-flex;
    justify-content: center;
  }
`;

export default NeedsAssesmentModal;
