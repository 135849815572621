import React, { useContext, useEffect, useState } from "react";
import {
  Card,
  Container,
  NotificationsGrid,
  SingleColumn,
  TitleRow,
  AccountTitleRow,
  AccountRow,
  AccountTitle,
  AccountTitleContainer,
  AccountButtonContainer,
  ButtonRow,
  ButtonWrapperCancel,
  ButtonWrapperSave,
  TextAreaDiv,
  TextInputDiv,
  SubtitleRow,
  ButtonWrapperSmall,
  SubtitleWrapper,
  ModalContentContainer,
  StyledCheveronLeft32
} from "../../components";
// import Services from "./Services";
import {
  Button,
  TextInput,
  StructuredListWrapper,
  StructuredListHead,
  StructuredListBody,
  StructuredListCell,
  StructuredListRow,
  SkeletonText,
  Modal,
  OverflowMenu,
  OverflowMenuItem,
  InlineLoading,
  InlineNotification,
} from "carbon-components-react";
import { AddAlt24, AddAlt16 } from "@carbon/icons-react";
import styled from "styled-components";

import kit from "ich-ui-kit";
import { shouldAutoComplete } from "../../../../utils";
const initialInputs = {
  "input-0": {
    name: "",
    email: ""
  }
};

const deactivateOrgText = `
By clicking "Deactivate" you are agreeing to remove access to MyWayfinder for all staff accounts under this organization.
Deactivating will remove this organization from all client care teams and you will need to be added back
should you choose to return. This will not erase any client health data, but your staff will no longer have access to it.
`;

const deactivateOrgSuccessText = `Thank you for trusting MyWayfinder! Your provider organization
has been deactivated. You will be redirected to login shortly.`;

const deactivateStaffText = `By clicking "Deactivate" you are agreeing to remove access
to MyWayfinder for this account.`;

const deactivateStaffSuccessText = `Account deactivated successfully.`;

// SIPD-2515 Display text for the "Resend Invite"/"Cancel Invite" confirmation box.
const resendInviteStaffText = `By selecting 'Resend Invite', you are confirming your intent to re-send the welcome email to this staff member at the email address: `;
const resendInviteStaffSuccessText = `E-mail has been resent to the user.`;
const cancelInviteStaffText = `By selecting 'Cancel Invite', you are agreeing to remove access to MyWayfinder for this staff member.`;
const cancelInviteStaffSuccessText = `User’s invite to create account has been canceled.`;


// TODO pull table into different component

const ProviderStaffManagement = ({ orgId, goBack }) => {
  const { clearUser, user } = useContext(kit.UserContext);
  const { apiService } = useContext(kit.ApiContext);
  const [isEditable, setIsEditable] = useState(false);
  const [staff, setStaff] = useState([]);
  const [staffToDeactivate, setStaffToDeactivate] = useState(null);
  const [staffToAssign, setStaffToAssign] = useState(null);
  const [loading, setLoading] = useState(true);
  const [deactivateOpen, setDeactivateOpen] = useState(false);
  const [deactivateLoading, setDeactivateLoading] = useState(false);
  const [deactivateModalText, setDeactivateModalText] =
    useState(deactivateOrgText);
  const [editStaff, setEditStaff] = useState(false);
  const [staffToEdit, setStaffToEdit] = useState({});
  const [values, setValues] = useState({
    firstName: "",
    lastName: "",
    alias: ""
  });

  // SIPD-2515 State variables for managing the Resend Invite/Cancel Invite (Deactivate) modal.
  const [resendInviteOpen, setResendInviteOpen] = useState(false);
  const [resendInviteLoading, setResendInviteLoading] = useState(false);
  const [staffToResendInvite, setStaffToResendInvite] = useState(null);
  const [resendInviteModalText, setResendInviteModalText] = useState(resendInviteStaffText);
  const [emailVerification, setEmailVerification] = useState("");
  const [cancelInviteOpen, setCancelInviteOpen] = useState(false);
  const [cancelInviteLoading, setCancelInviteLoading] = useState(false);
  const [staffToCancelInvite, setStaffToCancelInvite] = useState(null);
  const [cancelInviteModalText, setCancelInviteModalText] = useState(cancelInviteStaffText);
  // SIPD-2515 State variables for managing the Reactivate function.
  const [reactivateRoleId, setReactivateRoleId] = useState();
  const [reactivateRoleLoading, setReactivateRoleLoading] = useState(false);
  const [reactivateRoleSuccess, setReactivateRoleSuccess] = useState(false);

  useEffect(() => {
    const getStaff = async () => {
      try {
        const data = await apiService.get(
          `/providerstaff?orgId=${user.superAssumedRole?.orgId || orgId}&assumedId=${user.superAssumedRole?.leadId || ""}`
        );
        setStaff(data.staff);
      } catch (err) {
        console.log(err);
      }
      setLoading(false);
    };
    getStaff();
  }, [apiService]);

  const fetchStaff = async () => {
    try {
      const data = await apiService.get(
        `/providerstaff?orgId=${user.superAssumedRole?.orgId || orgId}&assumedId=${user.superAssumedRole?.leadId || ""}`
      );
      setStaff(data.staff);
    } catch (err) {
      console.log(err);
    }
    setLoading(false);
  };

  const deactivateStaffAccount = async () => {
    try {
      setDeactivateLoading(true);
      const data = await apiService.post(
        `/providerstaff/account/deactivate?assumedId=${user.superAssumedRole?.leadId || ""}`, {
          userId: staffToDeactivate
        }
      );
      if (data.ok) {
        setDeactivateModalText(deactivateStaffSuccessText);
        window.setTimeout(async () => {
          await fetchStaff();
          closeModal();
          setDeactivateLoading(false);
        }, 2000);
      }
    } catch (err) {
      console.log(err);
      setDeactivateLoading(false);
    }
  };

  // SIPD-2515 Function to reactivate a provider member.
  const reactivateStaffAccount = async (userId) => {
    try {
      setReactivateRoleLoading(true);
      setReactivateRoleId(userId);
      const data = await apiService.post(
        `/providerstaff/account/reactivate?&assumedId=${user.superAssumedRole?.leadId || ''}`,
        {
          userId: userId
        }
      );
      if (data.ok) {
        setReactivateRoleSuccess(true);
        await fetchStaff(() => {
          setReactivateRoleId(null);
          setReactivateRoleLoading(false);
        });
        setTimeout(() => setReactivateRoleSuccess(false), 2000);
      } else {
        setReactivateRoleLoading(false);
        setReactivateRoleId(null);
      }
    } catch (err) {
      console.log(err);
      setReactivateRoleLoading(false);
      setReactivateRoleId(null);
    }
  };

  // SIPD-2515 Function to resend the welcome email to a provider member.
  const resendInviteStaffAccount = async () => {
    try {
      setResendInviteLoading(true);
      const data = await apiService.post(
        `/providerstaff/account/resendInvite?assumedId=${user.superAssumedRole?.leadId || ""}`, {
          userId: staffToResendInvite
        }
      );
      if (data.ok) {
        setResendInviteModalText(resendInviteStaffSuccessText);
        setResendInviteLoading(false);
        window.setTimeout(async () => {
          await fetchStaff();
          closeModal();
        }, 3000);
      }else {
        setResendInviteLoading(false);
      }
    } catch (err) {
      console.log(err);
      setResendInviteLoading(false);
    }
  };

  // SIPD-2515 Function to cancel (deactivate) the account access of a provider member.
  const cancelInviteStaffAccount = async () => {
    try {
      setCancelInviteLoading(true);
      const data = await apiService.post(
        `/providerstaff/account/deactivate?&assumedId=${
          user.superAssumedRole?.leadId || ''
        }`, {
          userId: staffToCancelInvite
        }
      );
      if (data.ok) {
        setCancelInviteModalText(cancelInviteStaffSuccessText);
        setCancelInviteLoading(false);
        window.setTimeout(async () => {
          await fetchStaff();
          closeModal();
        }, 3000);
      }else {
        setCancelInviteLoading(false);
      }
    } catch (err) {
      console.log(err);
      setCancelInviteLoading(false);
    }
  };

  const openModal = (staffId) => {
    if (staffId) {
      setStaffToDeactivate(staffId);
      setDeactivateModalText(deactivateStaffText);
      setDeactivateOpen(true);
    }
  };

  const closeModal = () => {
    setStaffToDeactivate(null);
    setDeactivateOpen(false);
    setStaffToCancelInvite(null);
    setCancelInviteOpen(false);
    setStaffToResendInvite(null);
    setResendInviteOpen(false);
  };

  const handleRequestSubmit = async () => {
    if (staffToDeactivate) {
      await deactivateStaffAccount();
    }
  };

  // SIPD-2515 Helper function to open the Resend Invite modal with the provider member's ID.
  const openResendInviteModal = (staffId, staffEmail) => {
    setStaffToResendInvite(staffId);
    setResendInviteOpen(true);
    setEmailVerification(staffEmail);
    setResendInviteModalText(resendInviteStaffText + staffEmail + ".");
  };

  const handleResendInviteRequestSubmit = async () => {
    if(staffToResendInvite){
      await resendInviteStaffAccount();
    }
    setResendInviteLoading(true);
    setTimeout(() => {
      setStaffToResendInvite(null);
      setResendInviteOpen(false);
      setResendInviteLoading(false);
    }, 3000); // Wait 3 seconds before running the next actions
  };

  // SIPD-2515 Helper function to open the Cancel Invite (Deactivate) modal with the provider member's ID.
  const openCancelInviteModal = (staffId) => {
    setStaffToCancelInvite(staffId);
    setCancelInviteOpen(true);
    setCancelInviteLoading(false);
  };

  const handleCancelInviteRequestSubmit = async () => {
    if(staffToCancelInvite){
      await cancelInviteStaffAccount();
    } 
    setTimeout(() => {
      setStaffToCancelInvite(null);
      setCancelInviteOpen(false);
      setCancelInviteModalText(cancelInviteStaffText);
      
    }, 3000); // Wait 3 seconds before running the next actions
    setCancelInviteLoading(true);
  };

  const openEditModal = (staffName) => {
    if (staffName) {
      setStaffToEdit(staffName);
      setValues({
        firstName: staffName.firstName,
        lastName: staffName.lastName,
        alias: staffName.aliasName
      })
      setEditStaff(true);
    }
  };

  const closeEditModal = () => {
    setStaffToEdit({});
    setEditStaff(false);
    setValues({
      firstName: "",
      lastName: "",
      alias: ""
    })
  };

  const handleEditSubmit = async () => {
    setLoading(true);
    let firstName = values.firstName;
    let lastName = values.lastName;

    try {
      await apiService.put(`/providerstaff/account?assumedId=${user.superAssumedRole?.leadId || ""}`, {
        firstName: firstName,
        lastName: lastName,
        alias: values.alias,
        userId: staffToEdit?.id
      });
    } catch (err) {
      console.log(err);
    }
    await closeEditModal();
    fetchStaff();
    setLoading(false);
  };

  return (
    <NotificationsGrid>
      <Modal
        open={deactivateOpen}
        danger
        onRequestSubmit={handleRequestSubmit}
        onRequestClose={() => closeModal()}
        primaryButtonDisabled={deactivateLoading}
        modalHeading="Are you sure?"
        modalLabel=""
        primaryButtonText={
          deactivateLoading ? (
            <InlineLoading
              style={{ marginLeft: "1rem" }}
              description="Deactivating..."
              // status={success ? "finished" : "active"}
              aria-live="Deactivating"
            />
          ) : (
            "Deactivate"
          )
        }
        secondaryButtonText="Cancel"
      >
        <ModalContentContainer>
          <p>{deactivateModalText}</p>
        </ModalContentContainer>
      </Modal>
      {/** SIPD-2515 Components for Resend Invite and Cancel Invite Popup Windows */}
      <Modal
        open={cancelInviteOpen}
        danger
        onRequestSubmit={handleCancelInviteRequestSubmit}
        onRequestClose={() => closeModal()}
        primaryButtonDisabled={cancelInviteLoading}
        modalHeading="Are you sure?"
        modalLabel=""
        primaryButtonText={cancelInviteLoading ? 'Canceling Invite...' : 'Cancel Invite'}
        secondaryButtonText="Cancel"
      >
        <ModalContentContainer>
          <p>{cancelInviteModalText}</p>
        </ModalContentContainer>
      </Modal>
      <Modal
        open={resendInviteOpen}
        onRequestSubmit={handleResendInviteRequestSubmit}
        onRequestClose={() => closeModal()}
        primaryButtonDisabled={resendInviteLoading}
        modalHeading="Resend user invite email"
        modalLabel=""
        primaryButtonText={resendInviteLoading ? 'Resending Invite...' : 'Resend Invite'}
        secondaryButtonText="Cancel"
      >
        <ModalContentContainer>
          <p>{resendInviteModalText}</p>
        </ModalContentContainer>
      </Modal>
      {/** SIPD-2515 Components for Resend Invite and Cancel Invite Popup Windows -- END */}
      <Modal
        open={editStaff}
        primaryButtonDisabled={(values.firstName === "" && values.lastName === "" && values.alias === "") || loading}
        onRequestSubmit={() => handleEditSubmit()}
        onRequestClose={() => closeEditModal()}
        onSecondarySubmit={() => closeEditModal()}
        modalHeading="Edit Staff Information"
        modalLabel=""
        primaryButtonText={loading ? <InlineLoading></InlineLoading> : "Submit"}
        secondaryButtonText="Cancel"
      >
        <ModalContentContainer>
          <AccountRow>
            <TextInputDiv>
                <TextInput
                  id={"my-name"}
                  labelText="First Name"
                  value={values.firstName}
                  onChange={(e) => setValues((values) => ({
                    ...values,
                    firstName: e.target.value
                  }))}
                  autoComplete={shouldAutoComplete}
                />
            </TextInputDiv>
            <TextInputDiv>
                <TextInput
                  id={"my-email"}
                  labelText="Last Name"
                  value={values.lastName}
                  onChange={(e) => setValues((values) => ({
                    ...values,
                    lastName: e.target.value
                  }))}
                  autoComplete={shouldAutoComplete}
                />
            </TextInputDiv>
            <TextInputDiv>
              <TextInput
                id={"my-alias"}
                value={values?.alias || values.firstName + " " + values.lastName}
                labelText="Alias"
                onChange={(e) => setValues((values) => ({
                  ...values,
                  alias: e.target.value
                }))}
                autoComplete={shouldAutoComplete}
              />
              </TextInputDiv>
          </AccountRow>
        </ModalContentContainer>
      </Modal>
      <SingleColumn>
        <TitleRow>Staff Management</TitleRow>
        {reactivateRoleSuccess && (
          <InlineNotification
            kind="success"
            title="User reactivated successfully."
            style={{ position: 'absolute', top: '3rem', zIndex: 9999 }}
            onCloseButtonClick={() => setReactivateRoleSuccess(false)}
          />
        )}
        <>
          {/* <SubtitleWrapper>
            <SubtitleRow>
              View, manage, or add users with access to your provider
              organization.
            </SubtitleRow>
            <ButtonWrapperSmall>
              <Button kind="danger" size="field" onClick={() => openModal()}>
                Deactivate Provider Account
              </Button>
            </ButtonWrapperSmall>
          </SubtitleWrapper> */}
          <Card>
            <AccountTitleContainer>
              <AccountTitleRow style={{ padding: "0.5rem" }}>
                <StyledCheveronLeft32 onClick={goBack} />
                {/* <AccountTitle>Staff Accounts</AccountTitle> */}

                {/* <Button
                  renderIcon={isEditable ? null : AddAlt16}
                  kind="ghost"
                  iconDescription="Invite User"
                  onClick={toggleEdit}
                >
                  {isEditable ? "Cancel" : "Invite User"}
                </Button> */}
              </AccountTitleRow>
            </AccountTitleContainer>
            {/* {isEditable ? (
              <Container>
                {Object.keys(inputs).map((inputId) => (
                  <AccountRow>
                    <TextAreaDiv>
                      <TextInputDiv>
                        <TextInput
                          id={inputId + "-name"}
                          value={inputs[inputId].name}
                          labelText="Full Name"
                          onChange={(e) => handleNameChange(e, inputId)}
                        />
                      </TextInputDiv>
                      <TextInputDiv>
                        <TextInput
                          id={inputId + "-email"}
                          value={inputs[inputId].email}
                          labelText="Email"
                          onChange={(e) => handleEmailChange(e, inputId)}
                        />
                      </TextInputDiv>
                    </TextAreaDiv>
                  </AccountRow>
                ))}

                <AccountRow>
                  <AddAlt24
                    onClick={() => appendInput()}
                    label="Add a new User"
                  />

                  <h4> Add Another User</h4>
                </AccountRow>
                <AccountButtonContainer>
                  <ButtonRow>
                    <ButtonWrapperCancel>
                      <Button kind="cancel" size="field" onClick={toggleEdit}>
                        Cancel
                      </Button>
                    </ButtonWrapperCancel>
                    <ButtonWrapperSave>
                      <Button
                        kind="primary"
                        size="field"
                        onClick={handleSubmit}
                      >
                        Save
                      </Button>
                    </ButtonWrapperSave>
                  </ButtonRow>
                </AccountButtonContainer>
              </Container>
            ) : ( */}
            <Container>
              <StructuredListWrapper style={{ marginBottom: "1rem" }}>
                <StructuredListHead head={true}>
                  <StructuredListRow>
                    <StructuredListCell head={true}>Name</StructuredListCell>
                    <StructuredListCell head={true}>Alias</StructuredListCell>
                    <StructuredListCell head={true}>Email</StructuredListCell>
                    <StructuredListCell head={true}>
                      Invite Status
                    </StructuredListCell>
                    <StructuredListCell head={true}></StructuredListCell>
                    <StructuredListCell head={true}>Action</StructuredListCell>
                  </StructuredListRow>
                </StructuredListHead>

                {loading ? (
                  <StructuredListBody>
                    <StructuredListRow>
                      <StructuredListCell>
                        <SkeletonText heading paragraph lineCount={6} />
                      </StructuredListCell>
                      <StructuredListCell>
                        <SkeletonText heading paragraph lineCount={6} />
                      </StructuredListCell>
                      <StructuredListCell>
                        <SkeletonText heading paragraph lineCount={6} />
                      </StructuredListCell>
                      <StructuredListCell>
                        <SkeletonText heading paragraph lineCount={6} />
                      </StructuredListCell>
                    </StructuredListRow>
                  </StructuredListBody>
                ) : (staff || []).length > 0 ? (
                  (staff || []).map((member) => (
                    <StructuredListBody>
                      <StructuredListRow>
                        <StructuredListCell>{member.name}</StructuredListCell>
                        <StructuredListCell>{member.aliasName || member.name}</StructuredListCell>
                        <StructuredListCell>{member.email}</StructuredListCell>
                        <StructuredListCell>
                          {member.isDeactivated ? "Deactivated" : member.status}
                        </StructuredListCell>
                        <StructuredListCell></StructuredListCell>
                        <StructuredListCell>
                          <OverflowMenu flipped={true}>
                            {/* <OverflowMenuItem
                                itemText="Edit Service"
                                onClick={() =>
                                  viewServices(member.id, member.name)
                                }
                              /> */}
                            {/* <OverflowMenuItem
                              disabled
                              style={{ color: "black" }}
                              itemText="View History"
                            /> */}
                            {member.status.toLowerCase() === "accepted" ? (
                              member.isDeactivated ? (
                                <StyledOverflowMenuItem
                                  itemText="Reactivate"
                                  onClick={() => reactivateStaffAccount(member.userId)}
                                />
                              ) : (
                                <>
                                <OverflowMenuItem
                                  itemText="Deactivate"
                                  onClick={() => openModal(member.userId)}
                                />
                                {user.isSuper &&
                                  <OverflowMenuItem
                                  itemText="Edit User"
                                  onClick={() => openEditModal(member)}
                                  />
                                }
                                </>
                              )
                            ) : null}
                            {member.status.toLowerCase() === "invited" && (
                              <>
                                <StyledOverflowMenuItem
                                  itemText="Resend Invite"
                                  onClick={() => openResendInviteModal(member.userId, member.email)}
                                />
                                {
                                  member.isDeactivated ? (<></>) : (
                                    <StyledOverflowMenuItem
                                      itemText="Cancel Invite"
                                      onClick={() => openCancelInviteModal(member.userId)}
                                    />
                                  )
                                }
                              </>
                            )}
                          </OverflowMenu>
                        </StructuredListCell>
                      </StructuredListRow>
                    </StructuredListBody>
                  ))
                ) : (
                  <StructuredListBody>
                    <StructuredListRow>
                      <StructuredListCell></StructuredListCell>
                      <StructuredListCell>No accounts found</StructuredListCell>
                      <StructuredListCell></StructuredListCell>
                    </StructuredListRow>
                  </StructuredListBody>
                )}
              </StructuredListWrapper>
            </Container>
            {/* )} */}
          </Card>
        </>
        {/* ) : (
          <Services staffToAssign={staffToAssign} goBack={viewUserTable} />
        )} */}
      </SingleColumn>
    </NotificationsGrid>
  );
};

const StyledOverflowMenuItem = styled(OverflowMenuItem)`
  :disabled {
    color: #8d8d8d;
  }
`;

export default ProviderStaffManagement;
