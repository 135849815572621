import React, { useState, useEffect } from "react";
import { useTheme } from "styled-components";
import * as S from "./icon.styled";

const Icon = ({ name, provider, withDomain }) => {
  const [logo, setLogo] = useState({});
  const colors = {
    5: "#95c93d",
    4: "#4ac1e0",
    3: "#f4db5f",
    2: "#faa41a",
    1: "#ec5c29"
  };

  const theme = useTheme();
  const domains = {
    housing: "/domains/housing.svg",
    health: "/domains/Health.svg",
    financial: "/domains/financial.svg",
    employment: "/domains/employment.svg",
    income: "/domains/legal.svg",
    education: "/domains/education.svg",
    food: "/domains/food.svg",
    transportation: "/domains/transportation.svg",
    safety: "/domains/safety.svg",
    socialconnections: "/domains/socialconnection.svg",
    dependentcare: "/domains/dependentcare.svg"
  };

  useEffect(() => {
    const color = colors[provider.answer];
    setLogo({
      icon: domains[name.toLowerCase().split(" ").join("")],
      text: name,
      color: color ?? "#e0e0e0"
    });
  }, [name]);

  const determineStatus = (status) => {
    switch (status) {
      case "#95c93d":
        return 5;
      case "#4ac1e0":
        return 4;
      case "#f4db5f":
        return 3;
      case "#faa41a":
        return 2;
      case "#ec5c29":
        return 1;
      default:
        return 0;
    }
  };

  if(withDomain){
    return (
      <S.Container>
      <S.ColorBlindCircle theme={{ color: logo.color }}>
        <span>{determineStatus(logo.color)}</span>
          <S.DomainIcon src={logo.icon} alt={logo.text} />
        </S.ColorBlindCircle>
        <S.Text>{logo.text}</S.Text>
      </S.Container>
    )
  } else {
    return (
      <S.Container>
        <S.Profile theme={{ ...theme, color: logo.color }}>
          <img style={{ height: "100%", width: "100%" }} src={logo.icon} />
        </S.Profile>
      </S.Container>
    );
  }
};

export default Icon;
