export default function validate(values) {
  const errors = {};
  if(!values.lineDesignation){
    errors.lineDesignation = "This field is required."
  }
  if(!values.referralSource){
    errors.referralSource = "This field is required."
  }
  if(!values.inquiryType){
    errors.inquiryType = "This field is required."
  }
  if(!values.callType){
    errors.callType = "This field is required."
  }
  if(!values.gender){
    errors.gender = "This field is required."
  }
  if(!values.race){
    errors.race = "This field is required."
  }
  if(!values.ethnicity){
    errors.ethnicity = "This field is required."
  }
  if(!values.callerType){
    errors.callerType = "This field is required."
  }
  if(!values.callingFor){
    errors.callingFor = "This field is required."
  }
  if(!values.language){
    errors.language = "This field is required."
  }
  if(!values.covid19){
    errors.covid19 = "This field is required."
  }
  // Custom Validations
  if(!values.adultsInHousehold){
    errors.adultsInHousehold = "This field is required."
  }
  if(!values.childrenInHousehold){
    errors.childrenInHousehold = "This field is required."
  }
  if(!values.expectingChild){
    errors.expectingChild = "This field is required."
  }
  if(!values.ageOptions){
    errors.ageOptions = "This field is required."
  }
  return errors;
}

