import React, { useEffect, useRef } from "react";
import ReactMarkdown from "react-markdown";
import { usePrivacyPolicyCMS } from "./cms";
import * as S from "./styled";

const PrivacyPolicyPage = () => {
  const { ppCMS } = usePrivacyPolicyCMS();
  const windowRef = useRef(null);

  useEffect(() => {
    windowRef.current.scrollIntoView();
  }, []);

  return (
    <S.PageContainer>
      <S.PolicyContainer ref={windowRef}>
        <S.TitleWrapper>
          <S.Title>{ppCMS.heading}</S.Title>
        </S.TitleWrapper>
        <S.StyledParagraph style={{ marginTop: "0.5rem" }}>
          <h4
            stlye={{
              fontWeight: "500"
            }}
          >
            {ppCMS.text_effective_date} {ppCMS.effective_date}
          </h4>
        </S.StyledParagraph>
        {ppCMS.items.map((item, i) => (
          <div key={`ppCMS-${i}`}>
            <S.Header>{item.title}</S.Header>
            <S.StyledParagraph>
              <ReactMarkdown className="line-break">
                {item.content}
              </ReactMarkdown>
            </S.StyledParagraph>
          </div>
        ))}
      </S.PolicyContainer>
    </S.PageContainer>
  );
};

export default PrivacyPolicyPage;
