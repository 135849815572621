import React from "react";
import { useTheme } from "styled-components";
import { easings, motion } from "@carbon/motion";
import * as S from "./item.styled";

export const Item = ({ text, path, current, handleClick, client }) => {
  const theme = useTheme();
  return (
    <S.Link
      to={{
        pathname: path,
        state: {
          client: client
        }
      }}
      theme={{
        ...theme,
        motion: motion("standard", "expressive"),
        isCurrent: current === text.toLowerCase() ? true : false
      }}
      onClick={(e) => {
        handleClick(text.toLowerCase());
      }}
    >
      {text}
    </S.Link>
  );
};
