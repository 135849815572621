import styled from "styled-components";

export const Container = styled.div`
  padding: 2rem 1rem;
  background-color: #ffffff;
  margin: 1rem;
  @media (min-width: 1280px) {
    width: 1000px;
    padding: 3rem;
    margin: 0 0 4rem 0;
  }
`;
export const Heading = styled.h1`
  font-size: clamp(1.5rem, 7vw, 2.25rem);
  font-weight: 500;
  margin-bottom: 1.75rem;
`;
export const Text = styled.p`
  font-size: clamp(1rem, 3vw, 1.25rem);
`;
