export const renderOptions = () => {
  let options = [
    // {
    //   key: 0,
    //   value: "BLUE",
    //   label: "Select Capacity"
    // },
    {
      key: 1,
      value: "GREEN",
      label: "Available"
    },
    {
      key: 2,
      value: "YELLOW",
      label: "Almost at Capacity"
    },
    {
      key: 3,
      value: "RED",
      label: "No Capacity"
    }
  ];
  return options;
};

export const stateOfWellbeingOptions = () => {
  let options = [
    {
      id: 1,
      key: 1,
      value: "1",
      label: "Not OK",
      src: "/faces/not-okay-red.svg",
      alt:"Red Smiley" 
    },
    {
      id: 2,
      key: 2,
      value: "2",
      label: "OK",
      src: "/faces/ok-orange.svg",
      alt:"OrangeSmiley" 
    },
    {
      id: 3,
      key: 3,
      value: "3",
      label: "Good",
      src: "/faces/good-yellow.svg",
      alt:"Yellow Smiley" 
    },
    {
      id: 4,
      key: 4,
      value: "4",
      label: "Great",
      src: "/faces/fine-blue.svg",
      alt:"Blue Smiley" 
    },
    {
      id: 5,
      key: 5,
      value: "5",
      label: "Excellent",
      src: "/faces/future-fine-green.svg",
      alt:"Green Smiley" 
    }
  ];
  return options;
};

export const displayAddress = (pro, confidential_address_text) => {
  let address = pro.address || "";
  let city = pro.city || "";
  let state = pro.state || "";
  let zip = pro.zip || "";
  if (pro.loc_confidential_address === true) {
    return confidential_address_text;
  }
  if (!address.length && !city.length && !state.length && !zip.length) {
    return "No information available";
  } else if (
    !(!address.length && !city.length && !state.length && zip.length) &&
    address.length
  ) {
    return `${address}, ${city} ${state} ${zip}`;
  } else if (
    !address.length &&
    !(!address.length && !city.length && !state.length && zip.length)
  ) {
    return `${city}, ${state} ${zip}`;
  }
  return "Area postal code: "`${zip}`;
};

export const parseToArray = (str) => {
  return str.split("|");
};
