import styled from "styled-components";
import { Tile, Select } from "carbon-components-react";
import { ArrowLeft32 } from "@carbon/icons-react";
import { Card } from "../../components/Card/card";

export const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ResponsiveH5 = styled.div`
  font-weight: 500;
  margin: 0.5rem 0;
  @media screen and (max-width: 520px){
    margin-top: 2rem;
  }
`;

export const BorderedContainer = styled.div`
  width: 100%;
  border: 1px solid #8d8d8d;
`;

export const NotesInputContainer = styled.div`
  border-top: 1px solid #8d8d8d;
  display: flex;
  align-items: center;
`;

export const FlexBaseline = styled.div`
  display: flex;
  align-items: baseline;
`;

export const StyledCard = styled(Card)`
  height: 98%;
  width: 95%;
  position: relative;
  padding: 2rem;
  overflow: auto;
  :hover {
    background-color: white;
  }
  @media screen and (max-width: 500px){
    padding: 1rem;
  }
`;
export const ImageContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  width: 5rem;
  height: 5rem;
  border: 0.3px solid #0f62fe;
  border-radius: 50%;
  overflow: auto;
  background-image: url("/blank.png");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;

  > svg {
    width: 85%;
    height: auto;
    border: 1px solid #0f62fe;
    border-radius: 50%;
    fill: white;
  }
`;

export const View = styled.div`
  color: blue;
`;

export const BackLinkContainer = styled.div`
  grid-area: button;
  height: 2rem;
`;

export const BlueBackLink = styled(ArrowLeft32)`
  position: absolute;
  color: #0f62fe;
  font-size: 1rem;
  display: inline-block;
  padding-left: 0;
  cursor: pointer;
  border: none;
  top: 1rem;
  left: 1rem;
`;

export const HeaderDiv = styled.div`
  // grid-area: header;
  display: flex;
  justify-content: flex-start;
  padding-top: 2rem;
  padding-bottom: 0;
  flex-direction: column;
  a {
    text-decoration: none;
  }
`;

export const StyledTile = styled(Tile)`
  padding: 0.5rem 1rem;
  min-height: auto;
  background-color: #fff;
`;

export const SectionWrapper = styled.div`
  margin-bottom: 1rem;
`;

export const Timestamp = styled.p`
  color: gray;
  font-size: 0.75rem;
  margin-left: 1rem;
`;

export const NotesSubheader = styled.p`
  font-size: 0.75rem;
  color: gray;
  margin-bottom: 0.5rem;
`;

export const NoteText = styled.p`
  margin: 0.5rem 0;
  white-space: pre-wrap;
`;

export const NotesContainer = styled.div`
  display: flex;
  flex-direction: column-reverse;
  max-height: 15rem;
  min-height: 15rem;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
`;

export const HistoryContainer = styled.div`
  width: 100%;
  margin-top: 2rem;
  max-height: 24rem;
  overflow-y: auto;
`;

export const SurveyContainer = styled.div`
  width: 100%;
  max-height: 20rem;
  display: flex;
  flex-direction: row;
  // background-color: #e6e6e6;
  // justify-content: center;
  @media (max-width: 768px) {
    flex-direction: column;
    max-height: none;
  }
`;

export const StyledSelect = styled(Select)`
  margin-bottom: 10px;
`;


export const UpdateStatusContainer = styled.div`
  // height: 100%;
  width: 100%;
  display: flex;
  justify-content: end;
  flex-direction: column;
  text-align: end;
`;

export const HorizontalRule = styled.hr`
  margin: 2rem 0;
  width: 100%;
`;

export const ErrorText = styled.p`
  color: #da1e28;
  font-size: 0.75rem;
  font-weight: 400;
`;