import React from "react";
import { useHistory } from "react-router";
import * as S from "./HasActiveReferral.styles";

const HasActiveReferral = ({
  patientId,
  activeReferralId,
  canReopen,
  activeReferralStatus
}) => {
  const history = useHistory();

  const navigateToReferral = () => {
    history.push({
      pathname: "/360dashboard/service-requests/view",
      state: {
        client: patientId,
        referralId: activeReferralId,
        cameFrom: "patient",
        onCareTeam: true
      }
    });
  };
  const contactProvider = () => {
    window.alert("In Development");
  };

  if (activeReferralStatus.toLowerCase().includes("closure")) {
    return (
      <S.Container>
        <>
          <h4>
            This individual has a referral that is pending closure for this
            service. Please select a different service or try again in 24 hours.
          </h4>
          <S.ButtonWrapper>
            {/* <S.StyledButton onClick={contactProvider}>
              Contact Provider
            </S.StyledButton> */}
            <S.StyledButton kind="tertiary" onClick={navigateToReferral}>
              View Referral
            </S.StyledButton>
          </S.ButtonWrapper>
        </>
      </S.Container>
    );
  } else {
    return (
      <S.Container>
        <>
          <h4>
            This individual has an open referral for this service. Please select
            a different service or add a referral note to the referral.
          </h4>
          <S.ButtonWrapper>
            {/* <S.StyledButton onClick={contactProvider}>
              Contact Provider
            </S.StyledButton> */}
            <S.StyledButton kind="tertiary" onClick={navigateToReferral}>
              View Referral
            </S.StyledButton>
          </S.ButtonWrapper>
        </>
      </S.Container>
    );
  }
};

export default HasActiveReferral;
