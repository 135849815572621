import { Loading } from "carbon-components-react";
import kit from "ich-ui-kit";
import React, { useContext, useEffect, useState, useLayoutEffect } from "react";
import { useLocation } from "react-router-dom";
import * as Subpage from "../subpage.styled";
import * as S from "./careTeam.styled";
import CurrentCareTeam from "./CurrentCareTeam";
import PastCareTeam from "./PastCareTeam";

const CareTeam = () => {
  let location = useLocation();
  const { user } = useContext(kit.UserContext);
  const { apiService } = useContext(kit.ApiContext);
  const [loadingCurrent, setLoadingCurrent] = useState(false);
  const [loadingPast, setLoadingPast] = useState(false);
  const [referralsLoading, setReferralsLoading] = useState(false);
  const [currentCareTeam, setCurrentCareTeam] = useState([]);
  const [pastCareTeam, setPastCareTeam] = useState([]);
  const [referralCount, setReferralCount] = useState({});
  const [view, setView] = useState("current");

  useLayoutEffect(() => {
    const getReferrals = async () => {
      setReferralsLoading(true);
      try {
        const data = await apiService.get(`/referrals/client/${location.state.client}`);
        if (data && data.referralCount) {
          setReferralCount(data.referralCount);
        }
      } catch (error) {
        console.log(error + "Error getting referrals");
      } finally {
        setReferralsLoading(false);
      }
    };
    getReferrals();
  }, []);

  useLayoutEffect(() => {
    const getCareTeam = async () => {
      try {
        setLoadingCurrent(true);
        const data = await apiService.get(`/careteam?id=${location.state.client}&assumedId=${user.superAssumedRole?.leadId || ""}`);
        let initCareTeam = []
        if (data && data.careteams && data.careteams.length > 0) {
          initCareTeam = data.careteams.map((prov) => ({
            ...prov,
            openReferrals: '',
            closedReferrals: ''
          }));
        }

        setCurrentCareTeam(initCareTeam);
      } catch (err) {
        console.log(err);
      } finally {
        setLoadingCurrent(false);
      }
    };
    getCareTeam();
  }, [user]);

  useLayoutEffect(() => {
    const getRemovedCareTeam = async () => {
      try {
        setLoadingPast(true);
        const data = await apiService.get(`/careteam/inactive?id=${location.state.client}&assumedId=${user.superAssumedRole?.leadId || ""}`);
        if (data && data.careteams) {
          setPastCareTeam(data.careteams);
        } 
      } catch (err) {
        console.log(err);
      } finally {
        setLoadingPast(false);
      }
    };
    getRemovedCareTeam();
  }, []);

  useEffect(() => {
    if (referralsLoading && currentCareTeam && currentCareTeam.length && referralCount && Object.keys(referralCount)?.length) {
      const careTeamWithReferralCount = currentCareTeam.map((ct) => ({
        ...ct,
        openReferrals:
          referralCount[`${ct.serviceId}|${ct.locationId}`]?.count
            .open || 0,
        closedReferrals:
          referralCount[`${ct.serviceId}|${ct.locationId}`]?.count
            .closed || 0
      }));
      setCurrentCareTeam(careTeamWithReferralCount);
    }
  }, [referralsLoading, currentCareTeam, referralCount]);

  const renderContent = (currentOrPast) => {
    switch (currentOrPast) {
      case "current":
        return (
          <Subpage.TableContent>
            <CurrentCareTeam
              currentCareTeam={currentCareTeam?.sort(
                (a, b) => new Date(b.startDate) - new Date(a.startDate)
              )}
            />
          </Subpage.TableContent>
        );
      case "past":
        return (
          <Subpage.TableContent>
            <PastCareTeam
              pastCareTeam={pastCareTeam?.sort(
                (a, b) => new Date(b.endDate) - new Date(a.endDate)
              )}
            />
          </Subpage.TableContent>
        );
      default: <></>;
    }
  };

  return (
    <Subpage.Container>
      <Subpage.InnerContainer>
        <Subpage.Header>
          <h3>
            <strong>Care Team</strong>
          </h3>
        </Subpage.Header>
        <S.ButtonContainer>
          <S.StyledButton
            kind={view === "current" ? "secondary" : "tertiary"}
            onClick={() => setView("current")}
          >
            Current
          </S.StyledButton>
          <S.StyledButton
            kind={view === "past" ? "secondary" : "tertiary"}
            onClick={() => setView("past")}
          >
            Past
          </S.StyledButton>
        </S.ButtonContainer>
        {loadingCurrent || loadingPast ? (
          <Loading />
        ) : (
          <>{currentCareTeam || pastCareTeam ? renderContent(view) : null}</>
        )}
      </Subpage.InnerContainer>
    </Subpage.Container>
  );
};

export default CareTeam;
