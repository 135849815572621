import React, { useState, useEffect, useContext } from "react";
import styled from "styled-components";
import { Loading, Button } from "carbon-components-react";
import { useLocation } from "react-router-dom";
import kit from "ich-ui-kit";
import Pill from "../../../../components/Pill";
import { Page, HeaderContainer } from "../../dashboard.styled";
import Summary from "./Summary";
import AssessmentList from "./AssessmentList";
import Empty from "../../../../components/Empty";
import { ArrowLeft32, ChevronLeft32 } from "@carbon/icons-react";
import * as Subpage from "../subpage.styled";
import * as S from "./summary.styled";
const BackArrow = styled(ArrowLeft32)`
  margin-right: 0.5rem;
  margin-bottom: -0.25rem;
`;

const CheckIn = ({ clientID }) => {
  const [view, setView] = useState("most-recent");
  const [loading, setLoading] = useState(false);
  const [assessmentList, setAssessmentList] = useState([]);
  const [selectedAssessment, setSelectedAssessment] = useState(null);
  const [lastCheckinDateFormatted, setLastCheckinDateFormatted]= useState();
  const { user } = useContext(kit.UserContext);
  const location = useLocation();

  const { apiService } = useContext(kit.ApiContext);

  useEffect(() => {
    const getAssessments = async () => {
      setLoading(true);
      try {
        const data = await apiService.get(
          `/assessments/client/${location.state.client}?assumedId=${user.superAssumedRole?.leadId || ""}
          `
        );
        if (data.ok) {
          setAssessmentList(data.assessments);
          if (data.assessments?.length > 0) {

          }
          let sortedAssessments = data.assessments.sort((a,b) => a.date - b.date)

          let lastCheckinDate = '';
          if(sortedAssessments.length > 0) {
             lastCheckinDate = convertDate(sortedAssessments[0].date);
          }
          setLastCheckinDateFormatted(lastCheckinDate)
        }
      } catch (err) {
        console.log(err);
      }
      setLoading(false);
    };
    getAssessments();
  }, [location.state]);

  const selectAssessment = (a) => {
    setSelectedAssessment(a);
  };

  const deselectAssessment = () => {
    setSelectedAssessment(null);
  };

  const convertDate = (dateStr) => {
    if (!!!dateStr) {
      return "";
    }
    try {
      let date = new Date(dateStr);
      let month = date.getMonth() + 1;
      let day = date.getDate();
      let year = date.getFullYear();
      return month + "/" + day + "/" + year;
    } catch (e) {
      console.log(e);
      return "";
    }
  };

  const renderContent = () => {
    if (view === "most-recent" && assessmentList.length > 0) {
      return <Summary assessment={assessmentList[0]} />;
    } else if (view === "past" && selectedAssessment !== null) {
      return <Summary assessment={selectedAssessment} />;
    } else if (view === "past" && assessmentList.length > 0) {
      return (
        <Subpage.TableContent>
          <LegendWrapper>
            <LegendCircle theme={{ bcolor: '#ec5c29' }}>
              1
            </LegendCircle>
            <LegendText>Not OK</LegendText>
            <LegendCircle theme={{ bcolor: '#faa41a' }}>
              2
            </LegendCircle>
            <LegendText>OK</LegendText>
            <LegendCircle theme={{ bcolor: '#f4db5f' }}>
              3
            </LegendCircle>
            <LegendText>Good</LegendText>
            <LegendCircle theme={{ bcolor: '#4ac1e0' }}>
              4
            </LegendCircle>
            <LegendText>Great</LegendText>
            <LegendCircle theme={{ bcolor: '#95c93d' }}>
              5
            </LegendCircle>
            <LegendText>Excellent</LegendText>
          </LegendWrapper>
          <AssessmentList
            assessments={assessmentList}
            selectAssessment={selectAssessment}
          />
        </Subpage.TableContent>
      );
    } else if (assessmentList.length === 0) {
      return (
        <Empty
          src="/icons/ai--explainability.svg"
          text="No Check-In Available"
          style={{
            minHeight: "70vh"
          }}
        />
      );
    }
  };

  return (
    <Subpage.Container style={{ padding: "0" }}>
      <Subpage.InnerContainer>
        <Subpage.Header>
          <h3>
            <strong>Check-ins</strong>
          </h3>
          {lastCheckinDateFormatted && (
          <h5>Last Check-in: {lastCheckinDateFormatted}</h5>
          )}
        </Subpage.Header>
        {assessmentList.length > 0 && (
          <FilterContainer>
            {!!selectedAssessment ? (
              <StyledCheveronLeft32 onClick={() => deselectAssessment()} />
            ) : (
              <>
                <CheckInButton
                  kind={view === "most-recent" ? "secondary" : "menu"}
                  onClick={() => setView("most-recent")}
                >
                  Most Recent
                </CheckInButton>
                <CheckInButton
                  kind={view === "past" ? "secondary" : "menu"}
                  onClick={() => setView("past")}
                >
                  Past
                </CheckInButton>
              </>
            )}
          </FilterContainer>
        )}
        {loading ? <Loading /> : renderContent()}
      </Subpage.InnerContainer>
    </Subpage.Container>
  );
};

export default CheckIn;

const SPage = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

const FilterContainer = styled.div`
  display: flex;
  width: 100%;
  height: auto;
  margin-bottom: 2rem;
  @media (min-width: 900px) {
    div {
      margin-right: 1rem;
    }
  }
`;

const StyledCheveronLeft32 = styled(ChevronLeft32)`
  color: blue;
  width: 3rem;
  height: 3rem;
  padding: 0.5rem;
  cursor: pointer;
  &:hover {
    background-color: #e5e5e5;
  }
`;

const CheckInButton = styled(Button)`
  margin-right: 1rem;
  border: 1px solid ${(props) => props.theme.colors.accent.mediumBlue};
`;

const StyledPill = styled(Pill)`
  text-align: center;
`;

const LegendCircle = styled.div`
  background-color: ${(props) => props.theme.bcolor};
  border-radius: 100%;
  padding: 0.6rem;
  margin: 0.25rem 0.35rem 0.25rem 0rem;
  width: 10px;
  height: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  @media screen and (max-width: 768px){
    margin: 0rem 0.25rem 0.15rem 0rem;
    width: 7px;
    height: 7px;
  }
`;

const LegendWrapper = styled.div`
  display: flex;
  // justify-content: end;
  flex-direction: row;
  margin-left: 0rem;
  margin-bottom: 1rem;
  @media screen and (max-width: 768px){
    margin-left: 1rem;
  }
`;

const LegendText = styled.p`
  margin-right: 1rem;
  @media screen and (max-width: 768px){
    font-size: 0.60rem;
    margin-right: 0.5rem;
  }
`;