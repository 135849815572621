import { parseHtml } from '../../../utils';

const determineEmail = (provider) => {
  if (!provider || !Object.keys(provider)?.length) {
    return '';
  }
  const { emailLocation, emailService, emailOrganization } = provider;

  return emailService?.raw || emailLocation?.raw || null; // SIPD-4010 | Disregard Org email completely
};

const determinePhone = (provider) => {
  if (!provider || !Object.keys(provider)?.length) {
    return '';
  }
  const { phoneService, phoneLocation, phoneOrganization } = provider;

  return phoneService?.raw || phoneLocation?.raw || phoneOrganization?.raw || null;
};

export default function parseProviderData (provider) {
  return ({
        orgId: provider.keyOrganization?.raw,
        c360_orgId: provider?.idOrganization?.raw || '',
        orgName: provider?.nameOrganization?.raw || '',
        orgDesc: provider?.descriptionOrganization?.raw || '',
        orgReg: provider?.connected?.raw && provider?.connected?.raw?.toLowerCase() === 'connected' ? true : false,
        orgEmail: determineEmail(provider),
        orgSource: provider?.sourceOrganization?.raw || '211',
        phone: determinePhone(provider),
        hours: provider?.hours?.raw || '',
        restricted: false,
        name: provider?.nameService?.raw || '',
        serviceId: provider?.idService?.raw || '',
        serviceAtLocationId: provider?.keyServiceLocation?.raw || '',
        serviceAtLocationBusinessId: provider?.idServiceLocation?.raw || '',
        overview: parseHtml(provider?.descriptionService?.raw) || '',
        address: provider?.address1PhysicalAddress?.raw || '',
        city: provider?.cityPhysicalAddress?.raw || '',
        state: provider?.statePhysicalAddress?.raw || '',
        zip: provider?.zipcodePhysicalAddress?.raw || '',
        latitude: provider?.locationLatLong?.raw?.lat,
        longitude: provider?.locationLatLong?.raw?.lon,
        capacity: provider?.capacity?.raw ?? null,
        language: provider?.language?.raw || '',
        locationId: provider?.idLocation?.raw || '',
        eligibility: provider?.eligibility?.raw || '',
        domains: [],//cleanDomains(provider.domains), // TODO
        connected: String(provider?.connectedService?.raw) === '1' ? true : false,
        bookmarked: false,
        activeReferralId: null,
        cnResource: !!provider?.isCnResource?.raw ? '1' : '0',
        loc_confidential_address: provider?.isConfidentialAddress?.raw || false,
        isNavigationCenter: !!provider?.isNavigationCenter?.raw || false,
  })
};