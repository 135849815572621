export const ethnicityItems = (cms) => {
  const init = [
    {
      id: 1,
      show: "Select Ethnicity",
      text: "Select Ethnicity",
      key: "default"
    },
    {
      id: 67,
      show: "Hispanic or Latino Origin",
      text: "Hispanic Origin",
      key: "hispanic"
    },
    {
      id: 68,
      show: "Not of Hispanic or Latino Origin",
      text: "Not of Hispanic Origin",
      key: "not_hispanic"
    }
  ];
  return init;
};
