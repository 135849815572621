import {
  createContext,
  useContext,
  useReducer,
  useState,
  useEffect
} from "react";
import { useDimension } from "../../../hooks/useDimension";

export const MenuContext = createContext();

export const useMenuContext = () => {
  const context = useContext(MenuContext);
  if (!context) {
    throw new Error("useMenuContext must be used  within the ServiceDirectory");
  }
  return context;
};

export const MapContext = createContext();
export const useMapContext = () => {
  const context = useContext(MapContext);
  if (!context) {
    throw new Error("useMapContext must be used  within the ServiceDirectory");
  }
  return context;
};

const MapContextProvider = ({ children }) => {
  const [showMap, toggleMap] = useReducer((state, action) => {
    return !state;
  }, false);
  return (
    <MapContext.Provider
      value={{
        map: {
          showMap,
          toggleMap
        }
      }}
    >
      {children}
    </MapContext.Provider>
  );
};

export const MenuContextProvider = ({ children }) => {
  const {
    windowSize: { width }
  } = useDimension();

  const [mobileView, setMobileView] = useState(false);
  const [showList, toggleList] = useReducer((state, _) => {
    return !state;
  }, false);

  const [showMap, toggleMap] = useReducer((state, action) => {
    return !state;
  }, false);

  useEffect(() => {
    if (width >= 768) {
      setMobileView(false);
      return;
    }
    setMobileView(true);
  }, [width]);

  return (
    <MapContextProvider>
      <MenuContext.Provider
        value={{
          mobileView,
          list: {
            showList,
            toggleList
          }
        }}
      >
        {children}
      </MenuContext.Provider>
    </MapContextProvider>
  );
};
