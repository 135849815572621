import React, { useState, useEffect, useContext } from "react";
import { useTheme } from "styled-components";
import { useLocation } from "react-router-dom";
import socket from "../../../../socket";
import { ActivityFeedContext } from "../../../../store/ActivityFeedContext";
// import { ReferralCard } from "../../components/ActivityPanes";
import ReferralPage from "./details";
import * as Subpage from "../subpage.styled";
import * as S from "./referral.styled";
import kit from "ich-ui-kit";
import { Loading, SkeletonPlaceholder } from "carbon-components-react";
import { useDimension } from "../../../../hooks/useDimension";
import { ReferralCard } from "./card";
import Empty from "./empty";
import EmptyLoading from "./loading";
import { NeedsRequestCard } from "../../components/ActivityPanes";

export const Referral = ({
  referralDefaultView, 
  careTeam, 
  loadingCareTeam, 
  services, 
  currentReferralLoading, 
  setCurrentReferralLoading,
  userOrgName
}) => {
  const [loading, setLoading] = useState(false);
  const [referral, setReferral] = useState(undefined);
  const [viewLoading, setViewLoading] = useState(false);
  // const [services, setServices] = useState(services);
  const [client, setClient] = useState();
  const [onCareTeam, setOnCareTeam] = useState(false);
  const [onCareTeamPatients, setOnCareTeamPatients] = useState(careTeam);
  const { apiService } = useContext(kit.ApiContext);
  const { user } = useContext(kit.UserContext);
  const {
    activityFeed,
    getLatestFeed,
    clearActivity,
    setActivityInactive,
    setReferralActivitiesInactive,
  } = useContext(ActivityFeedContext);
  const { windowSize } = useDimension();
  const theme = useTheme();
  const location = useLocation();

  const getReferralData = async (id) => {
    try {
      setViewLoading(true);
      const data = await apiService.get(`/providers/referral/${id}?assumedId=${user.superAssumedRole?.leadId || ""}`);
      setReferral(data);
      } catch (err) {
      console.log(err);
    } finally {
      setViewLoading(false);
    }
  };

  useEffect(() => {
     if (location.state !== undefined && location.state.referralId !== referral?.id) {
      (async () => {
        try {
          getReferralData(location.state.referralId)
        } catch (err) {
          console.log(err);
        }
      })();
    }
  }, [location.state?.referralId]);


  const closeReferralPanel = () => {
    setReferral(undefined);
  };
  return (
    <Subpage.Container>
      <Subpage.InnerContainer>
        <S.Container>
          {loading ? (
            <Loading />
          ) : (
            <>
              <S.Feed
                theme={{
                  ...theme,
                  isSM: windowSize.width < 768,
                  referral: referral
                }}
              >
                {activityFeed
                  .filter((card) => card.type === "referral" || card.type === "needs-request")
                  .sort((a,b) => a.date - b.date)
                  .map((cardData) => {
                    let onTeamNeeds;
                    let inbound = false;
                    if (careTeam && careTeam?.find((p) => cardData.patientId === p.id)) {
                      onTeamNeeds = true;
                    }
                    if (services?.find((p) => cardData.serviceId === p.serviceId)) {
                      inbound = true;
                    }
                    if (cardData.type === "referral") {
                      return (
                        <ReferralCard
                          setActivityInactive={setActivityInactive}
                          referral={referral}
                          inbound={inbound}
                          viewLoading={viewLoading}
                          setViewLoading={setViewLoading}
                          setClient={setClient}
                          onCareTeamPatients={onCareTeamPatients}
                          setOnCareTeam={setOnCareTeam}
                          setReferral={setReferral}
                          clearActivity={clearActivity}
                          cardData={cardData}
                          key={`referral-card-${cardData.id}`}
                          setReferralActivitiesInactive={setReferralActivitiesInactive}
                          currentReferralLoading={currentReferralLoading}
                          setCurrentReferralLoading={setCurrentReferralLoading}
                        />
                      );
                    } else if (cardData.type === "needs-request") {
                      return (
                        <NeedsRequestCard
                          loading={loadingCareTeam}
                          careTeam={onTeamNeeds}
                          clearActivity={clearActivity}
                          cardData={cardData}
                          setActivityInactive={setActivityInactive}
                          key={`referral-card-${cardData.id}`}
                        />
                      );
                    }
                  })}
              </S.Feed>
              <S.Referral
                theme={{
                  ...theme,
                  isSM: windowSize.width < 768,
                  referral: referral
                }}
              >
                {referral !== undefined ? (
                  <ReferralPage
                    referralId={referral.id}
                    client={client}
                    viewLoading={viewLoading}
                    referralData={referral}
                    services={services}
                    careTeam={careTeam}
                    close={closeReferralPanel}
                    onCareTeamPatients={onCareTeamPatients}
                    onCareTeam={onCareTeam}
                    setOnCareTeam={setOnCareTeam}
                    currentReferralLoading={currentReferralLoading}
                    setCurrentReferralLoading={setCurrentReferralLoading}
                    userOrgName = {userOrgName}
                  />
                ) : (
                <>
                  {viewLoading ? 
                  <EmptyLoading/> : <Empty />}
                </>
                )}
              </S.Referral>
            </>
          )}
        </S.Container>
      </Subpage.InnerContainer>
    </Subpage.Container>
  );
};
