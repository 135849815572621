import { Close24, Send16 } from "@carbon/icons-react";
import {
  Button,
  Checkbox,
  Column,
  DataTableSkeleton,
  Grid,
  InlineLoading,
  Link,
  Modal,
  PaginationNav,
  Row,
  Select,
  SelectItem,
  SkeletonPlaceholder,
  SkeletonText,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
  TextInput,
  Tile,
  Tooltip
} from "carbon-components-react";
import Loading from "carbon-components-react/lib/components/Loading";
import kit from "ich-ui-kit";
import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router";
import styled from "styled-components";
import { AboutToExpireBadge } from "../../../../../components/Badges/badges";
import { ActivityFeedContext } from "../../../../../store/ActivityFeedContext";
import {
  getDescriptiveHistory,
  getDisplayedStatus,
  validateUrl,
  parseFeedbackBadges,
  isNoteInputTooLong,
  constructServiceOverviewUrl,
  generateClickToCallLink,
  shouldAutoComplete
} from "../../../../../utils";
import * as S from "./ReferralDetails.styles";

const maxNoteChars = 1000;

const refereeStatusOptions = [
  { id: "accept", text: "Accept", value: "Accepted By Referee" },
  { id: "reject", text: "Reject", value: "Rejected By Referee" }
];

const receiverOpenStatusOptions = [
  { id: "accept", text: "Accept", value: "Waiting for Client" },
  { id: "reject", text: "Reject", value: "Rejected By Receiver" }
];

const receiverClosedStatusOptions = [
  {
    id: "close-resolved",
    text: "Closed - Resolved",
    value: "Pending Closure - Resolved"
  },
  {
    id: "close-unable-to-contact",
    text: "Closed - Unable to Contact",
    value: "Pending Closure - Unable to Contact"
  },
  {
    id: "close-unable-to-resolve",
    text: "Closed - Unresolved",
    value: "Pending Closure - Unable to Resolve"
  }
];

const receiverReopenStatusOptions = [
  { id: "reopen", text: "Reopen", value: "Reopened" }
];

const referrerCancelStatusOptions = [
  { id: "cancel", text: "Cancel", value: "Canceled By Referrer" }
  // { id: "close", text: "Close", value: "Closed By Referrer" },
];

const ReferralDetails = ({
  referralId,
  client,
  referralData,
  services,
  onCareTeam,
  setOnCareTeam,
  onCareTeamPatients,
  careTeam,
  close,
  viewLoading,
  userOrgName,
  currentReferralLoading,
  setCurrentReferralLoading
}) => {
  // getNewBadge;
  const [ctr, setCtr] = useState(0);
  const [loading, setLoading] = useState(false);
  const [loadingReferral, setLoadingReferral] = useState(false);
  const [referral, setReferral] = useState(referralData);
  const [noteText, setNoteText] = useState("");
  const [displayedStatus, setDisplayedStatus] = useState("");
  const [displayedNotes, setDisplayedNotes] = useState([]);
  const [displayedAssignment, setDisplayedAssignment] = useState([]);
  // const [services, setServices] = useState([]);
  const [displayedHistory, setDisplayedHistory] = useState([]);
  const [currentHistoryPage, setCurrentHistoryPage] = useState(0);

  const [feedbackData, setFeedbackData] = useState([]);

  const {
    getLatestFeed,
    clearActivity,
    setActivityInactive,
    clearReferralActivity,
    setReferralActivityInactive,
    setReferralActivitiesInactive
  } = useContext(ActivityFeedContext);

  const [staff, setStaff] = useState([]);
  const [staffLoading, setStaffLoading] = useState();
  const [isServiceLead, setIsServiceLead] = useState(false);
  const { user } = useContext(kit.UserContext);
  const { apiService } = useContext(kit.ApiContext);
  const history = useHistory();
  const [assignmentDisabled, setAssignmentDisabled] = useState();
  const [noteModalOpen, setNoteModalOpen] = useState(false);
  const [rejectModalOpen, setRejectModalOpen] = useState(false);
  const [assignModalOpen, setAssignModalOpen] = useState(false);
  const [tempStatus, setTempStatus] = useState("");
  const [tempAssignment, setTempAssignment] = useState("");
  const [cancelClose, setCancelClose] = useState(false);
  const [methods, setMethods] = useState([]);
  const [reasons, setReasons] = useState([]);
  const [openTooltip, setOpenTooltip] = useState(false);

  useEffect(() => {
    setRejectModalOpen(history.location.state?.isDecline);
    setTempStatus(history.location.state?.isDecline ? "Rejected By Receiver" : "")
  }, [history.location.state?.isDecline]);

  const cleanHistory = (history) => {
    if (!history) {
      return [];
    }

    let reversed = history.sort((a, b) => new Date(a.date) - new Date(b.date));
    reversed = reversed.filter((r) => !!r.description);
    if (
      !reversed.find((h) => h.description.includes("Accepted by Provider")) &&
      reversed.find((h) => h.description.includes("Working"))
    ) {
      const firstWorking = reversed.find((h) => {
        if (h?.description && h?.description.includes("Working")) {
          return h;
        }
      });
      const acceptedHistory = {
        description: firstWorking?.description?.replace(
          "is now Working",
          "is now Accepted by Provider"
        ),
        date: firstWorking?.date,
        author: firstWorking?.author
      };
      reversed.push(acceptedHistory);
    }

    // if (reversed.find((h) => h.description.includes("reassigned"))) {
    //   const idx = reversed.findIndex((p) =>
    //     p.description.includes("reassigned")
    //   );
    //   const removed = reversed.splice(idx, 1);
    // }

    reversed.sort((a, b) => {
      return new Date(b.date) - new Date(a.date);
    });
    return reversed;
  };

  const getStaff = async () => {
    setStaffLoading(true);
    try {
      // const serviceInfo = await apiService.get(
      //   `/providerstaff/servicelocation?serviceId=${
      //     referral.serviceId
      //   }&locationId=${referral.locationId}&assumedId=${
      //     user.superAssumedRole?.leadId || ""
      //   }`
      // );
      // if (serviceInfo.ok) {
      //   setStaff(serviceInfo.staff);
      // }
      let currstaffOptions = [];
      if (user.isAdmin || isServiceLead || user.isSuper) {
        const serviceInfo = await apiService.get(
          `/providerstaff/servicelocation?serviceId=${referral.serviceId}&locationId=${
            referral.locationId
          }&assumedId=${user.superAssumedRole?.leadId || ""}`
        );
        if (serviceInfo.ok && serviceInfo.staff) {
          serviceInfo.staff.forEach((s) => {
            currstaffOptions.push({
              id: s.userId,
              text: s.name,
              value: s.name
            });
        });
        setStaff(currstaffOptions);
        }
      } else {
        if (user.superAssumedRole?.leadId) {
          setStaff([
            {
              id: user.superAssumedRole.leadId,
              text: user.superAssumedRole.leadName,
              value: user.superAssumedRole.leadName
            }
          ]);
        } else {
          setStaff([{ id: user.id, text: user.fullName, value: user.fullName }]);
        }
      }
    } catch (err) {
      console.log(err);
    }
    setStaffLoading(false);
  };

  const getReferralData = async (id) => {
    try {
      const data = await apiService.get(
        `/providers/referral/${id}?assumedId=${
          user.superAssumedRole?.leadId || ""
        }`
      );
      setReferral(data);
      setDisplayedHistory(cleanHistory(data.history));
      setDisplayedNotes(data.notes);
      setDisplayedStatus(data.status);
      setDisplayedAssignment(data.assignedTo);
      setFeedbackData(data?.feedback.reverse());
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    const getServiceLineLeads = async () => {
      if (
        referralData?.status?.toLowerCase().includes("working") ||
        referralData?.status?.toLowerCase().includes("reopen") ||
        referralData?.status?.toLowerCase().includes("waiting for client")
      ) {
        await getStaff();
        const current = await apiService.get(
          `/account/service-line-leads/is-lead?serviceLocationId=${referralData?.serviceAtLocationKey}`
        );
        if (current && current.isServiceLineLead) {
          setIsServiceLead(true);
        } else {
          setIsServiceLead(false);
        }
      }
      let currClient = "";
      if (client) {
        currClient = client;
      } else {
        currClient = history.location.state?.client;
      }
      if (
        onCareTeamPatients &&
        onCareTeamPatients.find((p) => p.id === currClient)
      ) {
        setOnCareTeam(true);
      } else {
        setOnCareTeam(false);
      }
    }
    getServiceLineLeads();
  }, [referralId, isServiceLead]);

  useEffect(() => {
    const loadReferralData = async () => {
      setLoadingReferral(true);
      setCtr((state) => state + 1);
      const j = await getReferralData(history.location.state.referralId);
      setLoadingReferral(false);
    }
    if (history.location.state) {
      loadReferralData();
    }
  }, [history.location, referralData]);

  const [width, setWidth] = useState(window.innerWidth);
  const isMobile = 600;
  useEffect(() => {
    const handleResizeWindow = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleResizeWindow);
    return () => {
      window.removeEventListener("resize", handleResizeWindow);
    };
  }, []);

  const updateStatus = async (status, alsoAddNote = false) => {
    if (alsoAddNote && isNoteInputTooLong(noteText, maxNoteChars)) {
      return;
    }

    setLoading(true);
    setDisplayedStatus(status);
    updateStatusDisabled(status);
    if (alsoAddNote) {
      await addNote();
      setDisplayedNotes(displayedNotes.concat());
    }

    const updatingStatus = async (status) => {
      try {
        const data = await apiService.put(`/referrals/status?assumedId=${user.superAssumedRole?.leadId || ""}`, {
          patientId: history.location.state.client,
          status,
          referralId: referral.id,
          serviceId: referral.serviceId,
          assignedTo: referral.assignedTo,
          originatorId: referral.requesterId
        });

        if (data) {          
          await setReferralActivitiesInactive(referral.referreeId, referral.id);
          if (
            referral.status === "Waiting for Provider" &&
            status === "Waiting for Client"
          ) {
            setDisplayedStatus("Working");
            updateStatusDisabled("Working");
          } else {
            setDisplayedStatus(status);
            updateStatusDisabled(status);
          }
          if (data.history) {
            setDisplayedHistory(cleanHistory(data.history));
          }
          history.push({
            state: {
              referralId: referral.id,
              client: history.location.state.client,
              isDecline: false
            }
          });
          // getLatestFeed();
        }
      } catch (err) {
        console.log(err);
      } finally {
        setCurrentReferralLoading("");
      }
      return;
    };
    await updatingStatus(status);
    setLoading(false);
  };
  const updateAssignment = async (assignedTo, alsoAddNote = false) => {
    if (alsoAddNote && isNoteInputTooLong(noteText, maxNoteChars)) {
      return;
    }
    setLoading(true);
    setDisplayedAssignment(assignedTo);
    const updatingAssignment = async (assignedTo) => {
      try {
        let assignedToUser = staff.find((s) => s.value === assignedTo);
        let assignedToId = assignedToUser.id;
        const data = await apiService.put(
          `/referrals/assignment?assumedId=${
            user.superAssumedRole?.leadId || ""
          }`,
          {
            patientId: history.location.state.client,
            serviceId: referral?.serviceId,
            originatorId: referral?.requesterId,
            assignedTo,
            assignedToId,
            status: displayedStatus,
            referralId: referral?.id
          }
        );
        if (data.ok) {
          setReferral(data.referral);
          setDisplayedHistory(cleanHistory(data.referral.history));
          setDisplayedNotes(data.referral.notes);
          setDisplayedStatus(data.referral.status);
          setDisplayedAssignment(assignedToUser);
        }
      } catch (err) {
        console.log(err);
      }
    };
    await updatingAssignment(assignedTo);
    if (alsoAddNote) {
      await addNote(true);
      setDisplayedNotes(displayedNotes.concat());
    }
    setLoading(false);
  };

  const addNote = async (reassigning = false) => {
    if (noteText === "" || isNoteInputTooLong(noteText, maxNoteChars)) {
      return;
    }
    const fullNote = reassigning ? `Reassignment reason: ${noteText}` : 
    tempStatus == "Rejected By Receiver" ? `Referral Rejected. Reason: ${noteText}` : noteText;
    try {
      setLoading(true);
      const data = await apiService.post(`/referrals/note?assumedId=${user.superAssumedRole?.leadId || ""}`, {
        patientId: referral.referreeId,
        text: fullNote,
        referralId: referral.id,
        serviceId: referral.serviceId,
        originatorId: referral.requesterId,
        locationId: referral.locationId,
        orgSource: referral?.orgSource
      });
      if (data.ok) {
        setNoteText("");
        let dateTime = new Date();
        dateTime = dateTime.toLocaleString();
        let updatedNotes = displayedNotes;
        updatedNotes.push({
          authorName: user.superAssumedRole?.leadName || user.aliasName || user.fullName,
          authorOrgName: user.superAssumedRole?.orgName || userOrgName || "",
          text: fullNote,
          timestamp: dateTime
        });
        setDisplayedNotes(displayedNotes.concat());
        setDisplayedHistory(cleanHistory(data.history));
      }
    } catch (err) {
      console.log(err);
    }
    setLoading(false);
    return;
  };

  const determineStatusOptions = () => {
    if (
      user &&
      user.isSuper &&
      displayedStatus.toLowerCase().includes("close ")
    ) {
      return receiverReopenStatusOptions;
    } else if (
      user.id === referral.requesterId ||
      user.superAssumedRole?.leadId === referral.requesterId
    ) {
      // check if referrer is also receiver
      if (
        services?.find(
          (s) =>
            s.serviceId === referral.serviceId &&
            s.locationId === referral.locationId
        )
      ) {
        if (
          displayedStatus.toLowerCase() === "pending" ||
          displayedStatus.toLowerCase().includes("waiting for provider") ||
          displayedStatus.toLowerCase() === "about to expire - pending"
        ) {
          return receiverOpenStatusOptions;
        } else if (
          displayedStatus.toLowerCase().includes("working") ||
          displayedStatus.toLowerCase().includes("reopen")
        ) {
          return receiverClosedStatusOptions;
        } else if (
          displayedStatus.toLowerCase().includes("close ") ||
          displayedStatus.toLowerCase().includes("pending closure")
        ) {
          return receiverReopenStatusOptions;
        }
      }
    } else {
      if (
        displayedStatus.toLowerCase() === "pending" ||
        displayedStatus.toLowerCase().includes("waiting for provider") ||
        displayedStatus.toLowerCase() === "about to expire - pending"
      ) {
        return receiverOpenStatusOptions;
      } else if (
        displayedStatus.toLowerCase().includes("working") ||
        displayedStatus.toLowerCase().includes("reopen")
      ) {
        return receiverClosedStatusOptions;
      } else if (
        displayedStatus.toLowerCase().includes("close ") ||
        displayedStatus.toLowerCase().includes("pending closure")
      ) {
        return receiverReopenStatusOptions;
      }
    }
    return [];
  };

  const updateStatusDisabled = (displayedStatus) => {
    if (
      user &&
      user.isSuper &&
      !displayedStatus.toLowerCase().includes("closed")
    ) {
      return true;
    } else if (
      displayedStatus.toLowerCase().includes("closed") ||
      displayedStatus.toLowerCase().includes("reject") ||
      displayedStatus.toLowerCase().includes("expired")
    ) {
      return true;
    } else if (
      user.id === referral.requesterId ||
      user.superAssumedRole?.leadId === referral.requesterId
    ) {
      if (
        services?.find(
          (s) =>
            s.serviceId === referral.serviceId &&
            s.locationId === referral.locationId
        )
      ) {
        if (displayedStatus.toLowerCase().includes("pending closure")) {
          return false;
        } else if (
          displayedStatus.toLowerCase().includes("rejected ") ||
          displayedStatus.toLowerCase().includes("cancel")
        ) {
          return true;
        } else {
          return false;
        }
      } else {
        return true;
      }
    } else {
      if (
        services?.find(
          (s) =>
            s.serviceId === referral.serviceId &&
            s.locationId === referral.locationId
        )
      ) {
        if (
          displayedStatus.includes("waiting for provider") ||
          displayedStatus.toLowerCase().includes("pending") ||
          displayedStatus.toLowerCase() === "about to expire - pending"
        ) {
          return false;
        } else if (
          displayedStatus.toLowerCase().includes("rejected ") ||
          displayedStatus.toLowerCase().includes("cancel")
        ) {
          return true;
        } else {
          return false;
        }
      } else {
        return true;
      }
    }
  };

  const updateAssignmentDisabled = (status1) => {
    let status;
    if (displayedStatus) {
      status = displayedStatus;
    } else {
      status = referral.status;
    }
    if (
      status?.toLowerCase().includes("working") ||
      status?.toLowerCase().includes("reopen") ||
      status?.toLowerCase().includes("waiting for client")
    ) {
      if (user.isAdmin || isServiceLead || user.isSuper) {
        if (
          services?.find(
            (s) =>
              s.serviceId === referral.serviceId &&
              s.locationId === referral.locationId
          )
        ) {
          // setAssignmentDisabled(false)
          return false;
        } else {
          // setAssignmentDisabled(true)
          return true;
        }
      } else {
        if (
          services?.find(
            (s) =>
              s.serviceId === referral.serviceId &&
              s.locationId === referral.locationId
          )
        ) {
          if (
            referral.assignedTo &&
            referral.assignedTo.toLowerCase() !== "undefined"
          ) {
            // setAssignmentDisabled(true)
            return true;
          } else {
            // setAssignmentDisabled(false)
            return false;
          }
        } else {
          // setAssignmentDisabled(true)
          return true;
        }
      }
    } else {
      // setAssignmentDisabled(true)
      return true;
    }
  };

  const determineAssignmentLabel = () => {
    if (user.isAdmin || isServiceLead || user.isSuper) {
      if (referral.assignedTo) {
        return "Update Assignment";
      } else {
        return "Add Assignment";
      }
    } else {
      if (referral.assignedTo) {
        return "Referral Assignment";
      } else {
        return "Claim Assignment";
      }
    }
  };

  const disableNotes = () => {
    let status = displayedStatus.toLowerCase();
    if (
      status.includes("closed") ||
      status.includes("rejected") ||
      status.includes("canceled")
    ) {
      return true;
    } else {
      return false;
    }
  };

  // const disableStatus = () => {
  //   let status = displayedStatus.toLowerCase();
  //   if (
  //     status.includes("closed") ||
  //     status.includes("rejected") ||
  //     status.includes("canceled")
  //   ) {
  //     return true;
  //   } else {
  //     return false;
  //   }
  // };

  const handleEnter = (e) => {
    if (e.key === "Enter") {
      addNote();
    }
  };

  const cleanDateTime = (str) => {
    let end = str.slice(-3);
    str = str.substring(0, str.lastIndexOf(":"));
    return str + end;
  };

  const questions = [
    "1. Was your issue satisfactorily resolved?",
    "2. How easy was it to find the resources you needed on MyWayfinder?",
    "3. How likely are you to recommend MyWayfinder to a friend?",
    "4. How easy was it for you to work with " + referral.name + "?",
    "5. How likely are you to recommend " + referral.name + " to a friend?",
    "6. Is there anything else you would like to share about your experience?",
    "7. Would you like to nominate " +
      referral.name +
      " for a badge based on their special skills?"
  ];

  const capitalize = (str) => {
    if (str && str[0]) {
      return str[0].toUpperCase() + str.slice(1);
    } else {
      return str;
    }
  };

  const renderReferralSurvey = () => {
    if (feedbackData && feedbackData.length > 0) {
      return (
        <div>
          <S.ResponsiveH5>Referral Survey</S.ResponsiveH5>
          <S.SurveyContainer>
            <SurveyCard>
              <SurveyCardTitle>
                <h5>{questions[0]}</h5>
              </SurveyCardTitle>
              <p>
                {feedbackData[0]?.q1 === "true" ? "Yes" : "No"}{" "}
                {feedbackData[0]?.q1Extended || " "}
              </p>
            </SurveyCard>
            <SurveyCard>
              <SurveyCardTitle>
                <h5>{questions[1]}</h5>
              </SurveyCardTitle>
              <p>{feedbackData[0]?.q2 || "No response"}</p>
            </SurveyCard>
            <SurveyCard>
              <SurveyCardTitle>
                <h5>{questions[2]}</h5>
              </SurveyCardTitle>
              <p>{feedbackData[0]?.q3 || "No response"}</p>
            </SurveyCard>
            <SurveyCard>
              <SurveyCardTitle>
                <h5>{questions[3]}</h5>
              </SurveyCardTitle>
              <p>{feedbackData[0]?.q4 || "No response"}</p>
            </SurveyCard>
          </S.SurveyContainer>
          <S.SurveyContainer>
            <SurveyCard>
              <SurveyCardTitle>
                <h5>{questions[4]}</h5>
              </SurveyCardTitle>
              <p>{feedbackData[0]?.q5 || "No response"}</p>
            </SurveyCard>
            <SurveyCard>
              <SurveyCardTitle>
                <h5>{questions[5]}</h5>
              </SurveyCardTitle>
              <p>{feedbackData[0]?.q7 || "No response"}</p>
            </SurveyCard>
            <SurveyCard>
              <SurveyCardTitle>
                <h5>{questions[6]}</h5>
              </SurveyCardTitle>
              {parseFeedbackBadges(feedbackData[0]?.q6, "No response")}
            </SurveyCard>
            <SurveyCardLL>
              <SurveyCardTitleLL>
                <h5></h5>
              </SurveyCardTitleLL>
              <p></p>
            </SurveyCardLL>
          </S.SurveyContainer>
        </div>
      );
    } else {
      return <></>;
    }
  };

  // Sorting notes in reverse order
  if (displayedNotes) {
    displayedNotes.sort((a, b) => {
      return new Date(b.timestamp) - new Date(a.timestamp);
    });
  }

  // For pagination of history
  const historyPaginated = [];
  if (displayedHistory) {
    let page = 1;
    if (displayedHistory) {
      var i,
        j,
        temporary,
        chunk = 5;
      for (i = 0, j = displayedHistory.length; i < j; i += chunk) {
        temporary = displayedHistory.slice(i, i + chunk);
        historyPaginated.push(temporary);
      }
    }
  }

  const updateMethod = (value) => {
    let tempMethod = [...methods];
    if (tempMethod.includes(value)) {
      tempMethod = tempMethod.filter((a) => a !== value);
      setMethods([...tempMethod]);
    } else {
      setMethods([...tempMethod, value]);
    }
  };

  const updateReason = (value) => {
    let tempReason = [...reasons];
    if (tempReason.includes(value)) {
      tempReason = tempReason.filter((a) => a !== value);
      setReasons([...tempReason]);
    } else {
      setReasons([...tempReason, value]);
    }
  };

  const disablePrimary = () => {
    if (tempStatus === "Pending Closure - Unable to Contact") {
      if (noteText && methods.length > 0) {
        return false;
      } else {
        return true;
      }
    } else if (
      tempStatus === "Pending Closure - Resolved" ||
      tempStatus === "Pending Closure - Cancel"
    ) {
      if (noteText) {
        return false;
      } else {
        return true;
      }
    } else if (tempStatus === "Pending Closure - Unable to Resolve") {
      if (noteText && reasons.length > 0) {
        return false;
      } else {
        return true;
      }
    } else if (tempStatus === "Rejected By Receiver") {
      if (noteText) {
        return false;
      } else {
        return true;
      }
    }
  };

  const renderModalContent = () => { 
    if (
      tempStatus === "Pending Closure - Resolved" ||
      tempStatus === "Pending Closure - Cancel"
    ) {
      return (
        <>
          <TitleWrapper>
            <Title>
              {tempStatus.replace("Pending Closure", "Close Referral")}
            </Title>
            <Subtitle>Provide a reason for closing this referral</Subtitle>
          </TitleWrapper>
          {tempStatus === "Pending Closure - Resolved" ? (
            <Subtitle>
              The Closed - Resolved status should be used when the service has
              been successfully provided.
            </Subtitle>
          ) : (
            <Subtitle>
              The Closed - Canceled status should be used when the client no
              longer needs or wants the service.
            </Subtitle>
          )}
          <BorderedContainer>
            <NotesInputContainer>
              <TextInput
                id="note-input"
                labelText="Add Note"
                hideLabel
                placeholder="Add reason..."
                value={noteText}
                onChange={(e) => setNoteText(e.target.value)}
                style={{ borderBottom: "none" }}
                // disabled={displayedNotes.length >= 1}
                invalid={noteModalOpen && isNoteInputTooLong(noteText, maxNoteChars)}
                invalidText={null}
                light
                autoComplete={shouldAutoComplete}
              />
            </NotesInputContainer>
          </BorderedContainer>
          {noteModalOpen && !rejectModalOpen && !assignModalOpen && isNoteInputTooLong(noteText, maxNoteChars) ? <S.ErrorText>Referral note cannot exceed {maxNoteChars} characters</S.ErrorText> : null}
        </>
      );
    } else if (tempStatus === "Pending Closure - Unable to Contact") {
      return (
        <>
          <TitleWrapper>
            <Title>Close Referral — Unable to Contact</Title>
            <Subtitle>What contact methods were used?</Subtitle>
          </TitleWrapper>
          <legend className="bx--label">Select one or more methods</legend>
          <Checkbox
            onClick={(e) => updateMethod("Email")}
            labelText="Email"
            id="checked"
          />
          <Checkbox
            onClick={(e) => updateMethod("Phone Call")}
            labelText="Phone Call"
            id="checked-2"
          />
          <Checkbox
            onClick={(e) => updateMethod("Text Message")}
            labelText="Text Message"
            id="checked-3"
          />
          <Checkbox
            onClick={(e) => updateMethod("Referral Note")}
            labelText="Referral Note"
            id="checked-4"
          />
          <br />
          <Subtitle>
            The Closed - Unable to Contact status should be used when the client
            has not responded after at least three attempts to contact (please
            indicate methods used to attempt contact).
          </Subtitle>
          <BorderedContainer>
            <NotesInputContainer style={{ backgroundColor: "white" }}>
              <TextInput
                id="note-input"
                labelText="Add Note"
                hideLabel
                placeholder="Add an explanation..."
                value={noteText}
                onChange={(e) => setNoteText(e.target.value)}
                style={{ borderBottom: "none" }}
                // disabled={displayedNotes.length >= 1}
                invalid={noteModalOpen && isNoteInputTooLong(noteText, maxNoteChars)}
                invalidText={null}
                light
                autoComplete={shouldAutoComplete}
              />
            </NotesInputContainer>
          </BorderedContainer>
          {noteModalOpen && !rejectModalOpen && !assignModalOpen && isNoteInputTooLong(noteText, maxNoteChars) ? <S.ErrorText>Referral note cannot exceed {maxNoteChars} characters</S.ErrorText> : null}
        </>
      );
    } else if (tempStatus === "Pending Closure - Unable to Resolve") {
      return (
        <>
          <TitleWrapper>
            <Title>Close Referral — Unresolved</Title>
            <Subtitle>Why was this referral not resolved?</Subtitle>
          </TitleWrapper>
          <legend className="bx--label">Select one or more reasons</legend>
          <Checkbox
            onClick={(e) => updateReason("Client Ineligible for Service")}
            labelText="Client Ineligible for Service"
            id="1"
          />
          <Checkbox
            onClick={(e) => updateReason("Client Declined Service")}
            labelText="Client Declined Service"
            id="2"
          />
          <Checkbox
            onClick={(e) =>
              updateReason("Referred Client to Different Service")
            }
            labelText="Referred Client to Different Service"
            id="3"
          />
          <br />
          <Subtitle>
            The Closed - Unresolved status should be used when the service was
            not provided (please indicate the reason(s) the service was not
            provided).
          </Subtitle>
          <BorderedContainer>
            <NotesInputContainer style={{ backgroundColor: "white" }}>
              <TextInput
                id="note-input"
                labelText="Add Note"
                hideLabel
                placeholder="Add an explanation..."
                value={noteText}
                onChange={(e) => setNoteText(e.target.value)}
                style={{ borderBottom: "none" }}
                // disabled={displayedNotes.length >= 1}
                invalid={noteModalOpen && isNoteInputTooLong(noteText, maxNoteChars)}
                invalidText={null}
                light
                autoComplete={shouldAutoComplete}
              />
            </NotesInputContainer>
          </BorderedContainer>
          {noteModalOpen && !rejectModalOpen && !assignModalOpen && isNoteInputTooLong(noteText, maxNoteChars) ? <S.ErrorText>Referral note cannot exceed {maxNoteChars} characters</S.ErrorText> : null}
        </>
      );
    } else if (tempStatus === "Rejected By Receiver") {
      return (
        <>
          <TitleWrapper>
            <Title>Reject Referral</Title>
            <Subtitle>
              Please indicate the reason(s) for rejecting this referral.
            </Subtitle>
          </TitleWrapper>
          <div
            style={{ width: "100%", display: "flex", justifyContent: "right" }}
          >
            <p style={{ paddingRight: "0" }}>{noteText.length} / 100</p>
          </div>
          <BorderedContainer>
            <NotesInputContainer style={{ backgroundColor: "white" }}>
              <TextInput
                id="note-input"
                labelText="Add Note"
                hideLabel
                maxLength={100}
                minLength={1}
                placeholder="Add an explanation..."
                value={noteText}
                onChange={(e) => setNoteText(e.target.value)}
                style={{ borderBottom: "none" }}
                invalid={rejectModalOpen && isNoteInputTooLong(noteText, maxNoteChars)}
                invalidText={null}
                light
                autoComplete={shouldAutoComplete}
              />
            </NotesInputContainer>
          </BorderedContainer>
          {!noteModalOpen && rejectModalOpen && !assignModalOpen && isNoteInputTooLong(noteText, maxNoteChars) ? <S.ErrorText>Referral note cannot exceed {maxNoteChars} characters</S.ErrorText> : null}
        </>
      );
    }
  };

  return (
    <S.StyledCard>
      <Grid>
        <Row>
          {loadingReferral ? (
            <Column xs={16} lg={8}>
              <div style={{ paddingTop: "2rem" }}>
                <SkeletonText heading paragraph lineCount={3} />
              </div>
            </Column>
          ) : (
            <>
              <Column xs={16} lg={8}>
                <S.HeaderDiv>
                  <>
                    <h4>
                      <strong>{referral.orgName}</strong>
                    </h4>
                    <a
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        let currClient = "";
                        if (client) {
                          currClient = client;
                        } else {
                          currClient = history.location.state.client;
                        }
                        window.open(constructServiceOverviewUrl(referral.serviceAtLocationKey, referral.orgSource || "211"));
                      }}
                    >
                      <p>{referral.name}</p>
                    </a>
                    {referral.phone ? 
                      (generateClickToCallLink(referral.phone)
                        ? <a href={generateClickToCallLink(referral.phone)}>{referral.phone}</a> 
                        :
                        <p>{referral.phone}</p>
                        )
                      :
                      <p>{referral.orgEmail}</p>
                    }
                    {referral.address && referral.city && referral.state && (
                      <p style={{ color: "grey" }}>
                        {referral.address}, {referral.city}, {referral.state},{" "}
                        {referral.zip}
                      </p>
                    )}
                    {referral.status &&
                      referral.status.includes("About to Expire") && (
                        <AboutToExpireBadge />
                      )}
                  </>
                </S.HeaderDiv>
              </Column>
              <Column xs={16} lg={8}>
                <S.UpdateStatusContainer>
                  <h5>
                    <strong>Referral #{referral.id}</strong>
                  </h5>
                  <div>
                    {staffLoading || loading ? (
                      <InlineLoading />
                    ) : (
                      <S.StyledSelect
                        id="update-status"
                        defaultValue="placeholder-item1"
                        labelText="Update Status"
                        disabled={
                          updateStatusDisabled(displayedStatus) ||
                          user.is211Admin
                        }
                        onChange={async (e) => {
                          setCurrentReferralLoading(referralId);
                          if (e.target.value.includes("Closure")) {
                            setNoteModalOpen(true);
                            setTempStatus(e.target.value);
                          } else if (
                            e.target.value.includes("Rejected By Receiver")
                          ) {
                            setRejectModalOpen(true);
                            setTempStatus(e.target.value);
                          } else {
                            await updateStatus(e.target.value);
                          }
                        }}
                      >
                        <SelectItem
                          // disabled
                          value="placeholder-item1"
                          text={getDisplayedStatus(
                            displayedStatus,
                            referral.referree
                          )}
                        />
                        {determineStatusOptions().map((option) => (
                          <SelectItem
                            key={option.id}
                            id={option.id}
                            value={option.value}
                            text={option.text}
                          />
                        ))}
                      </S.StyledSelect>
                    )}
                    {staffLoading || loading ? (
                      <InlineLoading />
                    ) : (
                      <Select
                        id="update-assignment"
                        defaultValue="placeholder-item"
                        labelText={determineAssignmentLabel()}
                        disabled={updateAssignmentDisabled()}
                        onChange={(e) => {
                          if (!referral.assignedTo) {
                            updateAssignment(e.target.value);
                          } else {
                            setTempAssignment(e.target.value);
                            setAssignModalOpen(true);
                          }
                        }}
                      >
                        <SelectItem
                          disabled={
                            !referral.assignedTo ||
                            referral.assignedTo !== "undefined"
                          }
                          value={
                            referral.assignedTo &&
                            referral.assignedTo !== "undefined"
                              ? referral.assignedTo
                              : "Unassigned"
                          }
                          text={
                            referral.assignedTo &&
                            referral.assignedTo !== "undefined"
                              ? referral.assignedTo
                              : "Unassigned"
                          }
                        />
                        {staff.map((option) => {
                          if (option.value !== referral.assignedTo) {
                            return (
                              <SelectItem
                                key={option.id}
                                id={option.id}
                                value={option.value}
                                text={option.text}
                              />
                            );
                          }
                        })}
                      </Select>
                    )}
                  </div>
                </S.UpdateStatusContainer>
              </Column>
            </>
          )}
        </Row>
        <Row>
          <S.HorizontalRule />
        </Row>
        <br />
        <Column style={{ marginBottom: "3rem" }}>
          <Row>
            <h4>Referral Details</h4>
          </Row>
          <br />
          <Row style={{ justifyContent: "center" }}>
            {loadingReferral ? (
              <div style={{ width: "100%" }}>
                <SkeletonText paragraph lineCount={3} />
              </div>
            ) : referral.requesterId === referral.referreeId ? (
              <S.SectionWrapper>
                <p>
                  {referral?.onCareTeam ? (
                    <a
                      onClick={() => {
                        window.open(validateUrl(`/360dashboard?id=${referral.referreeId}`));
                      }}
                    >
                      <strong style={{ cursor: "pointer" }}>
                        {referral.referree || "Individual Preferred Name"}
                      </strong>
                    </a>
                  ) : (
                    <strong
                      onMouseEnter={() => {
                        setOpenTooltip(true);
                      }}
                      onMouseLeave={() => setOpenTooltip(false)}
                    >
                      {referral.referree || "Individual Preferred Name"}
                      <Tooltip
                        showIcon={false}
                        open={openTooltip}
                        direction="bottom"
                      >
                        <p>
                          Client data is not available until the client is on
                          your care team.
                        </p>
                      </Tooltip>
                    </strong>
                  )}{" "}
                  has created a self-referral for{" "}
                  <strong>{referral.referralType || "Referral Type"}</strong> to{" "}
                  <strong>{referral.name || "Service Name"}</strong> provided by{" "}
                  <strong>{referral.orgName || "Provider Org"}</strong>.
                </p>
              </S.SectionWrapper>
            ) : (
              <S.SectionWrapper>
                <p>
                  <strong>{referral.requester || "Requester"}</strong> from{" "}
                  <strong>{referral.requesterOrg || "MyWayfinder"}</strong> has
                  created a{" "}
                  <strong>{referral.referralType || "Referral Type"}</strong>{" "}
                  referral on behalf of{" "}
                  {referral?.onCareTeam ? (
                    <a
                      onClick={() => {
                        let currClient = "";
                        if (client) {
                          currClient = client;
                        } else {
                          currClient = referral.referreeId;
                        }
                        window.open(validateUrl(`/360dashboard?id=${currClient}`));
                      }}
                    >
                      <strong style={{ cursor: "pointer" }}>
                        {referral.referree || "Individual Preferred Name"}
                      </strong>
                    </a>
                  ) : (
                    <strong
                      onMouseEnter={() => {
                        setOpenTooltip(true);
                      }}
                      onMouseLeave={() => setOpenTooltip(false)}
                    >
                      {referral.referree || "Individual Preferred Name"}
                      <Tooltip
                        showIcon={false}
                        open={openTooltip}
                        direction="bottom"
                      >
                        <p>
                          Client data is not available until the client is on
                          your care team and the referral is in working status.
                        </p>
                      </Tooltip>
                    </strong>
                  )}{" "}
                  to <strong>{referral.name || "Service Name"}</strong> provided
                  by <strong>{referral.orgName || "Provider Org"}</strong>.
                </p>
              </S.SectionWrapper>
            )}
            <S.HistoryContainer>{renderReferralSurvey()}</S.HistoryContainer>

            <S.HistoryContainer>
              <S.ResponsiveH5>Referral History</S.ResponsiveH5>
              {loadingReferral ? (
                <DataTableSkeleton
                  showHeader={false}
                  showToolbar={false}
                  columnCount={2}
                  rowCount={2}
                />
              ) : (
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableHeader colSpan="1">Date</TableHeader>
                      <TableHeader colSpan="5">Notes</TableHeader>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {(historyPaginated[currentHistoryPage] || []).map(
                      (r, i) => (
                        <TableRow key={i}>
                          <TableCell>
                            {new Date(r.date).toLocaleString()}
                          </TableCell>
                          <TableCell>
                            {getDescriptiveHistory(r, referral)}
                          </TableCell>
                        </TableRow>
                      )
                    )}
                  </TableBody>
                </Table>
              )}
            </S.HistoryContainer>
            <PaginationNav
              page={currentHistoryPage}
              itemsShown={width < isMobile ? 4 : 8}
              totalItems={
                displayedHistory
                  ? displayedHistory.length <= 5 ||
                    displayedHistory.length % 5 === 0
                    ? Math.round(displayedHistory.length / 5)
                    : Math.round(displayedHistory.length / 5) + 1
                  : 0
              }
              onChange={(e) => setCurrentHistoryPage(e)}
            />
          </Row>
          <Row>
            <S.ResponsiveH5>
              Referral Reason:
              {loadingReferral ? (
                <SkeletonText heading lineCount={1} />
              ) : (
                <strong>{referral.referralType}</strong>
              )}
            </S.ResponsiveH5>
          </Row>
          <Row style={{ marginTop: "1rem" }}>
            <S.FlexColumn>
              <S.ResponsiveH5>Referral Notes</S.ResponsiveH5>
              <S.NotesSubheader>
                Referral Notes are visible to the individual and providers with
                access to view this referral
              </S.NotesSubheader>
            </S.FlexColumn>
            <S.BorderedContainer>
              {loadingReferral || loading ? (
                <div style={{ paddingTop: "0.5rem" }}>
                  <SkeletonText paragraph lineCount={3} />
                </div>
              ) : (
                <S.NotesContainer>
                  {(displayedNotes || []).map(
                    ({ authorName, authorOrgName, timestamp, text }, index) => {
                      let dateTime = new Date(timestamp);
                      dateTime = dateTime.toLocaleString();
                      return (
                        <S.StyledTile key={`displayed-notes-${index}`}>
                        <S.FlexBaseline>
                            <h5>{authorName}</h5>
                            <S.Timestamp>{cleanDateTime(dateTime)}
                            {authorOrgName ? `, ${authorOrgName}` : ''}
                          </S.Timestamp>                          </S.FlexBaseline>
                          <S.NoteText>{text}</S.NoteText>
                        </S.StyledTile>
                      );
                    }
                  )}
                </S.NotesContainer>
              )}
              <S.NotesInputContainer>
                {loading ? (
                  <div style={{ paddingTop: "0.5rem" }}>
                    <SkeletonText paragraph lineCount={3} />
                  </div>
                ) : (
                  <TextInput
                    id="note-input"
                    labelText="Add Note"
                    hideLabel
                    placeholder="Add note to this referral..."
                    value={noteText}
                    onChange={(e) => setNoteText(e.target.value)}
                    onKeyPress={handleEnter}
                    style={{ borderBottom: "none" }}
                    disabled={disableNotes()}
                    invalid={!noteModalOpen && !rejectModalOpen && !assignModalOpen && isNoteInputTooLong(noteText, maxNoteChars)}
                    invalidText={null}
                    light
                    autoComplete={shouldAutoComplete}
                  />
                )}
                <Button
                  style={{ height: "100%", minHeight: 0 }}
                  hasIconOnly
                  renderIcon={Send16}
                  iconDescription="Add"
                  onClick={() => addNote()}
                  disabled={disableNotes() || isNoteInputTooLong(noteText, maxNoteChars)}
                />
              </S.NotesInputContainer>
            </S.BorderedContainer>
            {!noteModalOpen && !rejectModalOpen && !assignModalOpen && isNoteInputTooLong(noteText, maxNoteChars) ? <S.ErrorText>Referral note cannot exceed {maxNoteChars} characters</S.ErrorText> : null}
          </Row>
        </Column>
      </Grid>
      <StyledModal
        open={noteModalOpen}
        primaryButtonText={"Submit"}
        primaryButtonDisabled={disablePrimary() || isNoteInputTooLong(noteText, maxNoteChars)}
        secondaryButtonText={"Cancel"}
        onSecondarySubmit={async () => {
          setCancelClose(!cancelClose);
          setReasons([]);
          setMethods([]);
          await setDisplayedStatus(referral?.status);
          setNoteModalOpen(false);
          setTempStatus("");
          setNoteText("");
          setStaffLoading(true);
          // const j = await getReferralData(history.location.state.referralId)
          document.querySelectorAll(".bx--checkbox").forEach((e) => {
            if (e.checked === true) {
              e.click();
            }
          });
          setStaffLoading(false);
        }}
        onRequestClose={async () => {
          setCancelClose(!cancelClose);
          setReasons([]);
          setMethods([]);
          await setDisplayedStatus(referral?.status);
          setNoteModalOpen(false);
          setTempStatus("");
          setNoteText("");
          setStaffLoading(true);
          // const j = await getReferralData(history.location.state.referralId)
          document.querySelectorAll(".bx--checkbox").forEach((e) => {
            if (e.checked === true) {
              e.click();
            }
          });
          setStaffLoading(false);
        }}
        onRequestSubmit={async () => {
          if (isNoteInputTooLong(noteText, maxNoteChars)) {
            return;
          }
          // update the status first
          setStaffLoading(true);
          setNoteModalOpen(false);
          if (
            tempStatus === "Pending Closure - Resolved" ||
            tempStatus === "Pending Closure - Cancel"
          ) {
            await updateStatus(tempStatus, true);
          } else if (tempStatus === "Pending Closure - Unable to Contact") {
            let newStatus = methods ? ": " + methods.join(", ") : "";
            newStatus = tempStatus + newStatus;
            await updateStatus(newStatus, true);
          } else if (tempStatus === "Pending Closure - Unable to Resolve") {
            let newStatus = reasons ? ": " + reasons.join(", ") : "";
            newStatus = tempStatus + newStatus;
            await updateStatus(newStatus, true);
          }
          document.querySelectorAll(".bx--checkbox").forEach((e) => {
            if (e.checked === true) {
              e.click();
            }
          });
          setStaffLoading(false);
        }}
        onKeyDownCapture={async (e) => {
          if (e.key === "Enter") {
            if (isNoteInputTooLong(noteText, maxNoteChars)) {
              return;
            }
            setStaffLoading(true);
            setNoteModalOpen(false);
            if (
              tempStatus === "Pending Closure - Resolved" ||
              tempStatus === "Pending Closure - Cancel"
            ) {
              await updateStatus(tempStatus, true);
            } else if (tempStatus === "Pending Closure - Unable to Contact") {
              let newStatus = methods ? ": " + methods.join(", ") : "";
              newStatus = tempStatus + newStatus;
              await updateStatus(newStatus, true);
            } else if (tempStatus === "Pending Closure - Unable to Resolve") {
              let newStatus = reasons ? ": " + reasons.join(", ") : "";
              newStatus = tempStatus + newStatus;
              await updateStatus(newStatus, true);
            }
            document.querySelectorAll(".bx--checkbox").forEach((e) => {
              if (e.checked === true) {
                e.click();
              }
            });
            setStaffLoading(false);
          }
        }}
      >
        {renderModalContent()}
      </StyledModal>
      <StyledModal
        open={rejectModalOpen}
        primaryButtonText={"Submit"}
        primaryButtonDisabled={disablePrimary() || (rejectModalOpen && isNoteInputTooLong(noteText, maxNoteChars))}
        secondaryButtonText={"Cancel"}
        onSecondarySubmit={() => {
          setCancelClose(!cancelClose);
          setReasons([]);
          setDisplayedStatus(referral.status);
          setRejectModalOpen(false);
          setTempStatus("");
          setNoteText("");
          setStaffLoading(true);
          setStaffLoading(false);
          history.push({
            state: {
              referralId: referral.id,
              client: history.location.state.client,
              isDecline: false
            }
          });
        }}
        onRequestClose={() => {
          setCancelClose(!cancelClose);
          setReasons([]);
          setDisplayedStatus(referral.status);
          setRejectModalOpen(false);
          setTempStatus("");
          setNoteText("");
          setStaffLoading(true);
          setStaffLoading(false);
          history.push({
            state: {
              referralId: referral.id,
              client: history.location.state.client,
              isDecline: false
            }
          });
        }}
        onKeyDownCapture={(e) => {
          if (e.key === "Enter") {
            if (isNoteInputTooLong(noteText, maxNoteChars)) {
              return;
            }
            updateStatus(tempStatus, true);
            setReasons([]);
            setRejectModalOpen(false);
            setStaffLoading(true);
            setStaffLoading(false);
          }
        }}
        onRequestSubmit={() => {
          if (isNoteInputTooLong(noteText, maxNoteChars)) {
            return;
          }
          // update the status first
          updateStatus(tempStatus, true);
          setReasons([]);
          setRejectModalOpen(false);
          setStaffLoading(true);
          setStaffLoading(false);
        }}
      >
        {renderModalContent()}
      </StyledModal>
      <StyledModal
        open={assignModalOpen}
        primaryButtonText={"Submit"}
        primaryButtonDisabled={!noteText || (assignModalOpen && isNoteInputTooLong(noteText, maxNoteChars))}
        secondaryButtonText={"Cancel"}
        onSecondarySubmit={() => {
          setCancelClose(!cancelClose);
          setReasons([]);
          setDisplayedAssignment(referral.assignedTo);
          setAssignModalOpen(false);
          setNoteText("");
          setTempAssignment("");
          setStaffLoading(true);
          setStaffLoading(false);
          history.push({
            state: {
              referralId: referral.id,
              client: history.location.state.client,
              isDecline: false
            }
          });
        }}
        onRequestClose={() => {
          setCancelClose(!cancelClose);
          setReasons([]);
          setDisplayedAssignment(referral.assignedTo);
          setAssignModalOpen(false);
          setNoteText("");
          setTempAssignment("");
          setStaffLoading(true);
          setStaffLoading(false);
          history.push({
            state: {
              referralId: referral.id,
              client: history.location.state.client,
              isDecline: false
            }
          });
        }}
        onRequestSubmit={() => {
          if (isNoteInputTooLong(noteText, maxNoteChars)) {
            return;
          }
          // update the status first
          updateAssignment(tempAssignment, true);
          setTempAssignment("");
          setReasons([]);
          setAssignModalOpen(false);
          setTempAssignment("");
          setNoteText("");
        }}
        onKeyDownCapture={(e) => {
          if (e.key === "Enter") {
            if (isNoteInputTooLong(noteText, maxNoteChars)) {
              return;
            }
            updateAssignment(tempAssignment, true);
            setTempAssignment("");
            setReasons([]);
            setAssignModalOpen(false);
            setTempAssignment("");
            setNoteText("");
          }
        }}
      >
        <TitleWrapper>
          <Title>Reassign Referral</Title>
          <Subtitle>
            Provide a reason for for reassigning this referral.
          </Subtitle>
        </TitleWrapper>
        <BorderedContainer>
          <NotesInputContainer style={{ backgroundColor: "white" }}>
            <TextInput
              id="note-input"
              labelText="Add Note"
              hideLabel
              placeholder="Add reason for reassignment..."
              value={noteText}
              onChange={(e) => setNoteText(e.target.value)}
              style={{ borderBottom: "none" }}
              invalid={assignModalOpen && isNoteInputTooLong(noteText, maxNoteChars)}
              invalidText={null}
              light
              autoComplete={shouldAutoComplete}
            />
          </NotesInputContainer>
        </BorderedContainer>
        {!noteModalOpen && !rejectModalOpen && assignModalOpen && isNoteInputTooLong(noteText, maxNoteChars) ? <S.ErrorText>Referral note cannot exceed {maxNoteChars} characters</S.ErrorText> : null}
      </StyledModal>
    </S.StyledCard>
  );
};

export default ReferralDetails;

const StyledModal = styled(Modal)`
  margin-right: 0;

  .bx--btn--primary {
    background-color: #0f62fe;
    :hover {
      background-color: #0f62fe90 !important;
    }
  }
  .bx--btn--secondary {
    background-color: #393939 !important;
    :hover {
      background-color: #39393990 !important;
    }
  }
  .bx--modal-content {
    @media screen and (max-width: 376px) {
      height: auto;
    }
  }
  .bx--modal-container {
    @media screen and (width: 375px) {
      height: 90%;
    }
    @media screen and (max-width: 416px) {
      height: 80%;
      width: 95%;
      top: 10%;
    }
  }
`;

const SurveyCard = styled.div`
  width: 40%;
  display: flex;
  height: auto;
  flex-direction: column;
  margin: 0.5rem 1rem 0.5rem 0rem;
  // padding: 0.5rem 0.5rem;
  background-color: #f4f4f4;
  justify-content: flex-start;
  overflow: auto;
  p {
    padding: 0.5rem 0.5rem 0.5rem 0.5rem;
    font-size: 14px;
  }
  @media (max-width: 768px) {
    width: 100%;
    p {
      font-size: 12px;
    }
  }
`;
const SurveyCardLL = styled.div`
  width: 40%;
  display: flex;
  height: auto;
  flex-direction: column;
  margin: 0.5rem 1rem 0.5rem 0rem;
  // padding: 0.5rem 0.5rem;
  // background-color: #f4f4f4;
  justify-content: flex-start;
  p {
    padding: 0.5rem 0.5rem 0.5rem 0.5rem;
    font-size: 14px;
  }
  @media (max-width: 768px) {
    width: 100%;
    p {
      font-size: 12px;
    }
  }
`;

const SurveyCardTitle = styled.div`
  height: fit-content;
  background-color: #e6e6e6;
  padding: 0.5rem 0.5rem;
  h5 {
    font-size: 14px;
    font-weight: 500px;
  }
  @media (max-width: 1000px) {
    h5 {
      font-size: 12px;
    }
    min-height: 0rem;
    height: auto;
  }
  @media (max-width: 768px) {
    min-height: 0rem;
    height: auto;
  }
`;
const SurveyCardTitleLL = styled.div`
  min-height: 4rem;
  // background-color: #e6e6e6;
  padding: 0.5rem 0.5rem;
  h5 {
    font-size: 14px;
    font-weight: 500px;
  }
  @media (max-width: 1000px) {
    h5 {
      font-size: 12px;
    }
    min-height: 0rem;
    height: auto;
  }
  @media (max-width: 768px) {
    min-height: 0rem;
    height: auto;
  }
`;

const BorderedContainer = styled.div`
  width: 100%;
  border: 1px solid #8d8d8d;
  margin-bottom: 1rem;
`;

const NotesInputContainer = styled.div`
  border-top: 1px solid #8d8d8d;
  display: flex;
  align-items: center;
  height: 3rem;
  background-color: #ffffff;

  #note-input-error-msg {
    display: none;
  }
`;

const TitleWrapper = styled.div`
  justify-content: left;
  margin-bottom: 2rem;
`;

const Title = styled.h4``;

const Subtitle = styled.p`
  padding-bottom: 0.5rem;
`;
