import {
  buildAutocompleteQueryConfig,
  buildFacetConfigFromConfig,
  buildSearchOptionsFromConfig
} from "./config-helper";
import connector from './connector';

const config = ({ recommendedResourceIds, geolocation = { lat: null, long: null} }) => ({
  searchQuery: {
    recommendedResourceIds,
    geolocation,
    fuzziness: 'auto',
    facets: buildFacetConfigFromConfig(),
    ...buildSearchOptionsFromConfig()
  },
  autocompleteQuery: buildAutocompleteQueryConfig(),
  apiConnector: connector,
  alwaysSearchOnInitialLoad: true,
  debug: process.env.NODE_ENV === 'production' ? true : false,
  initialState: {
    resultsPerPage: 10
  }
});

export default config;