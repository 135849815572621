import { Button } from "carbon-components-react";
import { InteruptionCard, InteruptionText, InteruptionTitle } from "./checkin.styled";

export const Interuption = ({ action }) => {
  return (
    <>
      <InteruptionCard>
        <InteruptionTitle>It's okay to not be okay - we are here to help</InteruptionTitle>
        <br />
        <InteruptionText>If you need help now, please call 211 at <br />1-877-356-9211</InteruptionText>
        <br />
      </InteruptionCard>
      <Button onClick={action}>Go to next question</Button>
    </>
  )
}

export const quizQuestionsInit = [
    {
      qn: 1,
      text: "Dependent Care",
      domain: "dependent care",
      answer: "",
      selected: "init"
    },
    {
      qn: 2,
      text: "Education",
      domain: "education",
      answer: "",
      selected: "init"
    },
    {
      qn: 3,
      text: "Employment",
      domain: "employment",
      answer: "",
      selected: "init"
    },
    {
      qn: 4,
      text: "Financial",
      domain: "financial",
      answer: "",
      selected: "init"
    },
    {
      qn: 5,
      text: "Food",
      domain: "food",
      answer: "",
      selected: "init"
    },
    {
      qn: 6,
      text: "Health",
      domain: "health",
      answer: "",
      selected: "init"
    },
    {
      qn: 7,
      text: "Housing",
      domain: "housing",
      answer: "",
      selected: "init"
    },
    {
      qn: 8,
      text: "Income",
      domain: "income",
      answer: "",
      selected: "init"
    },
    {
      qn: 9,
      text: "Safety",
      domain: "safety",
      answer: "",
      selected: "init"
    },
    {
      qn: 10,
      text: "Social Connections",
      domain: "social connections",
      answer: "",
      selected: "init"
    },
    {
      qn: 11,
      text: "Transportation",
      domain: "transportation",
      answer: "",
      selected: "init"
    }
];