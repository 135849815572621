import styled from "styled-components";

const DashboardHeaderContainer = ({ backgroundColor }) => {
  return (
    <Wrapper style={{ backgroundColor: backgroundColor }}>
      <FlexCol>
        <Title>My Impact</Title>
        <div>
          <Tab>Statistics</Tab>
        </div>
      </FlexCol>
      <FlexRow></FlexRow>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0.5rem 1rem 0rem 0.5rem;
  height: 10rem;
  @media screen and (min-width: 650px) {
    padding: 1rem 2rem 0rem 2rem;
    height: 8rem;
  }
  @media screen and (min-width: 900px) {
    padding: 1rem 2rem 0rem 2rem;
    height: 10rem;
  }
`;

const FlexCol = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`;

const Title = styled.h1`
  color: #fff;
  font-weight: 400;
  font-size: 32px;
  @media screen and (min-width: 900px) {
    font-size: 36px;
  }
`;

const Tab = styled.div`
  font-weight: 500;
  padding: 0.6rem 1rem;
  width: 8rem;
  background-color: #f4f4f4;
  border-top: 2px solid blue;
  @media screen and (min-width: 500px) {
    padding: 0.8rem 1rem;
    width: 9rem;
  }
  @media screen and (min-width: 900px) {
    padding: 1rem;
    width: 10rem;
  }
`;

const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1rem;
  height: fit-content;
  @media screen and (max-width: 650px) {
    min-width: 7rem;
    flex-direction: column;
    gap: 0.5rem;
  }
`;

const Card = styled.div`
  border: 2px solid blue;
  padding: 1rem;
  background-color: #fff;
  min-width: 150px;
  @media screen and (max-width: 500px) {
    padding: 0.5rem;
    min-width: auto;
  }
`;

export default DashboardHeaderContainer;
