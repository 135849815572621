import { Button, Select, TextInput } from "carbon-components-react";
import styled from "styled-components";

/* UI COMPONENTS */
/* Content Wrappers */
export const StyledWrapper = styled.div`
  display: flex;
  width: 100%;
  @media screen and (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
`;

export const ResponseWrapper = styled.div`
  display: flex;
  @media screen and (min-width: 1800px) {
    padding: 1.5rem 0 1.5rem 0;
    margin-bottom: 0rem;
  }
  @media screen and (min-width: 768px) {
    width: 100%;
    align-items: center;
    padding: 0.5rem 0 0.5rem 0;
    margin-bottom: 0rem;
  }
`;

/* Dropdowns */
export const StyledDropdownWrapper = styled.div`
  display: flex;
  width: 15rem;
  @media screen and (min-height: 880px) and (min-width: 1800px) {
    margin-top: 6rem;
  }
  @media screen (min-width: 768px) {
    width: 20rem;
    margin-top: 4rem;
    justify-content: space-evenly;
  }
  @media screen and (max-width: 768px) {
    margin-top: 1rem;
    width: 20rem;
  }
`;

export const StyledSelect = styled(Select)`
  max-width: 25rem;
  @media screen and (max-width: 768px) {
    width: 20rem;
  }
`;

/* Buttons */
export const StyledButtonGroup = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  @media screen and (max-width: 768px) {
    flex-direction: column;
    justify-content: space-evenly;
  }
`;

export const StyledButton = styled(Button)`
  @media screen and (max-width: 768px) {
    margin-bottom: 1rem;
    width: 20rem;
    height: 3rem;
  }

  @media screen and (min-width: 768px) {
    color: ${(props) => (props.theme.bcolor ? "white" : "rgba(0,145,179,1)")};
    background-color: ${(props) => (props.theme.bcolor ? "#0f62fe" : "white")};
    :hover {
      color: ${(props) => (props.theme.bcolor ? "rgba(0,145,179,1)" : "white")};
      background-color: ${(props) =>
        props.theme.bcolor ? "white" : "rgba(0,145,179,1)"};
    }
  
    :focus {
      color: ${(props) => (props.theme.bcolor ? "white" : "rgba(0,145,179,1)")};
      background-color: ${(props) => (props.theme.bcolor ? "#0f62fe" : "white")};
      :active {
        color: "white" !important;
        background-color: "#0f62fe";
      }
    }
  }
`;

/* Text Input */
export const TextWrapper = styled.div`
  margin: 0 1.5rem 1rem 0;
  p {
    font-size: 18px;
    font-weight: 400;
  }
  @media screen and (min-width: 1600px) {
    p {
      font-weight: 400;
      font-size: 20px;
    }
  }
  @media screen and (max-width: 768px) {
    width: 50%;
    justify-content: center;
  }
  @media screen and (max-width: 600px) {
    margin: 0 1.5rem 1rem 1.5rem;
    justify-content: center;
    width: 20rem;
    p {
      font-weight: 400;
      font-size: 14px;
    }
  }
`;

export const TextInputWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  column-gap: .5rem;
  @media screen and (max-width: 768px) {
    width: 20rem;
    flex-direction: column;
  }
`;

export const TextInputRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 50%;
  @media screen and (max-width: 768px) {
    width: 100%;
    flex-direction: column;
  }
`;

// SIPD-3943 | This CSS styling is specifically added for the Address page to enhance layout.
export const TextInputRowAddressLine = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  column-gap: .5rem;
  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
`;

export const TextInputRowCityStateZip = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  column-gap: .5rem;
  @media screen and (max-width: 768px) {
    flex-direction: row;
  }
`;

export const StyledTextInput = styled(TextInput)`
  width: 100%;
  @media screen and (max-width: 768px) {
    width: 100%;
    margin: 0 0 0.75rem 0;
  }
`;

/* Progress Bar */
export const ProgressIndicatorWrapper = styled.div`
  width: 100%;
  margin: 2rem 0;
  padding-right: 1rem;
  .bx--progress--space-equal .bx--progress-step {
    flex-grow: 4;
    min-width: initial;
  }
  .bx--progress-step {
    min-width: initial;
    width: 4rem;
  }
  .bx--progress--space-equal .bx--progress-line {
    min-width: 4rem;
  }

  @media screen and (max-width: 768px) {
    .bx--progress--space-equal .bx--progress-step {
      flex-grow: 1;
      min-width: initial;
    }
    .bx--progress-step {
      min-width: initial;
      width: 1rem;
    }
    .bx--progress--space-equal .bx--progress-line {
      min-width: 1rem;
    }
    padding-right: 0.5rem;
  }
`;

/* Emergency Contact */
// export const eContactWrapper = styled.div`
//   @media screen and (min-width: 768px) {
//     width: 100%;
//     height: 100%;
//   }
//   @media screen and (max-width: 768px) {
//     width: 100%;
//     height: 100%;
//     align-items: start;
//   }
// `;

// export const eContactTextInputWrapper = styled.div`
//   display: flex;
//   flex-direction: column;
//   width: 100%;
//   height: 94%;
//   padding-top: 0.5rem;
//   padding-bottom: 0.2rem;
//   padding-left: 0.2rem;
//   @media screen and (max-width: 768px) {
//     height: auto;
//     padding: 0;
//   }
// `;

// export const eContactTextInputRow = styled.div`
//   @media screen and (min-width: 768px) {
//     display: flex;
//     flex-wrap: wrap;
//     width: 100%;
//     justify-content: space-between;
//   }
// `;

/* Dropdown and input */
export const BinaryWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding-top: 2.5rem;
  @media screen and (max-width: 768px) {
    padding-top: 0;
    flex-direction: column;
    align-items: center;
  }
`;

export const BinaryButtonWrapper = styled.div`
  display: flex;
  width: 77%;
  justify-content: space-between;
  @media screen and (max-width: 768px) {
    margin-top: 1rem;
    width: 100%;
    flex-direction: column;
    align-items: center;
  }
`;

/* PROFILE IMAGE */
export const ImageContainer = styled.div`
  border: 1px solid #0f62fe;
  border-radius: 50%;
  overflow: auto;
  @media screen and (max-width: 670px) {
    width: 6rem;
    height: 6rem;
  }

  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;

  > svg {
    width: 85%;
    height: auto;
    border: 1px solid #0f62fe;
    border-radius: 50%;
    fill: white;
  }
`;

export const FileUploaderWrapper = styled.div`
  margin-top: 0rem;
  .bx--form-item {
    flex-direction: column;
    align-items: center;
  }
  .bx--file__selected-file {
    align-self: auto;
    margin-left: 0px;
    margin-bottom: 0px;
  }
`;