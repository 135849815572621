import {
    useState,
    useContext,
    useCallback,
    useRef,
    useLayoutEffect
  } from "react";
  import { strapi } from "../axios/strapi.instance";
  import { LocaleContext } from "../store/LocaleContext";
  
  export const useStrapi = ({ cms, getLocale = false, populate = null }) => {
    const mountedRef = useRef(0);
    const { locale } = useContext(LocaleContext);
  
    const [data, setData] = useState(undefined);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState("");
  
    const memoGetRecords = useCallback(async () => {
      let currentLocale = localStorage.getItem("locale");
      if (currentLocale === null) {
        currentLocale = locale;
      } else {
        currentLocale = JSON.parse(currentLocale);
      }
  
      try {
        const res = await strapi.get(
          `/api/${cms}?locale=${currentLocale?.code || 'en'}&${populate ? populate : 'populate=*'}`
        );
        if (getLocale) {
          setData(res);
          return;
        }
        if (!res.data) return;
        if (res.data && res.data?.length > 0) {
          setData(res.data[0].attributes);
        } else {
          setData(res.data.attributes);
        }
      } catch (e) {
        console.log(e);
      }
    }, [locale]);
  
    useLayoutEffect(() => {
      try {
        setLoading(true);
        memoGetRecords();
      } catch (e) {
        console.warn(e);
      } finally {
        setLoading(false);
      }
    }, [cms, strapi, locale]);
  
    return { data, loading, error };
  };
  