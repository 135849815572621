import styled from "styled-components";
import { Button as Btn } from "carbon-components-react";

export const Container = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  overflow-x: none;
  overflow-y: auto;
  z-index: 0;
  align-items: center;
  padding-bottom: 1rem;
  @media only screen and (min-width: 375px) {
    height: 100%;
  }
  @media only screen and (min-width: 540px) {
    height: 100%;
  }

  @media only screen and (min-width: 768px) {
    padding-bottom: 2rem;
    height: 100%;
    overflow-y: scroll;
  }
  @media print {
    display: none;
  }
`;

export const Spacer = styled.div`
  position: relative;
  width: 100%;
  height: 70px;
  clear: both;
  content: "";
  display: block;
  background-color: red;
`;

export const Heading = styled.h1`
  align-self: flex-start;
  font-size: clamp(1.5rem, 6vw, 2.1rem);
  font-weight: 700;
  line-height: 2.5rem;
  margin-bottom: 1.25rem;
`;
export const Text = styled.p`
  align-self: flex-start;
  font-size: clamp(1rem, 4vw, 1.35rem);
  margin-bottom: 2rem;
`;

export const AnonymousContentWrapper = styled.div`
  height: 100%;
  margin-top: 10%;
  margin-bottom:10%;
`;
export const AnonymousContent = styled.div`
  position: relative;
  top: 40%;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  padding: 0 5%;
  @media (min-width: 375px) {
    padding: 0 10%;
    top: 10%;
  }
  @media (min-width: 540px) {
    padding: 0 10%;
    top: 20%;
  }
  @media (min-width: 768px) {
    top: 47px;
    padding: 0;
    width: clamp(600px, 70vw, 900px);
    height: auto;
  }
`;

export const Content = styled.div`
  position: relative;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  padding: 0 5%;
  max-width: 900px;
  width: 100%;
  height: 100%;
  margin-top: 2rem;
  padding: 0 10%;
  margin-top: 10%;
  margin-bottom: 10%;
  padding: 0 10%;
  padding-top: 2rem;

  @media (min-width: 768px) {
    padding: 0;
    flex-flow: column nowrap;
    align-items: center;
    width: clamp(600px, 70vw, 800px);
    height: 100%;
    padding-bottom: 2rem;
    top: 47px;
  }
`;

export const QuizContent = styled.div`
  position: relative;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  padding: 1rem 5% 0;
  max-width: 900px;
  width: 100%;
  height: 100%;
  padding-top: 1rem;
  margin-top: 10%;
  margin-bottom: 10%;

  @media only screen and (min-width: 375px) {
    padding: 0 10% 0;
  }

  @media only screen and (min-width: 768px) {
    padding: 0;
    flex-flow: column nowrap;
    align-items: center;
    width: clamp(600px, 70vw, 800px);
    height: 100%;
    padding-bottom: 2rem;
    top: 47px;
  }
  @media (min-height: 650px) and (max-height: 750px) {
    height: 100%;
    padding-top: 3rem;
  }
`;

export const CircleContainer = styled.div`
  width: 100%;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  @media only screen and (min-width: 1280px) {
    flex-flow: row nowrap;
    justify-content: space-between;
  }
`;

export const Button = styled.button`
  padding: 1.75rem;
  cursor: pointer;
  color: #0f62fe;
  font-size: 1rem;
  background: none;
  border: none;
  &:hover {
    color: #78a9ff;
  }
`;
export const BtnGroup = styled.div`
  margin: 2rem 0;
  width: 100%;
  display: flex;
  max-width: 250px;
  justify-content: center;
  @media only screen and (min-width: 1280px) {
    margin: 2rem 0 3rem;
  }
`;

export const IFrame = styled.div`
  background-color: black;
  margin: 2rem 0 3rem;
  width: 100% !important;
  height: 200px;
  height: auto;
  @media only screen and (min-width: 540px) {
    /* width: 500px; */
    height: 250px;
  }
  @media only screen and (min-width: 768px) {
    /* width: 600px; */
    height: 425px;
    margin: 2rem 0;
  }

  @media only screen and (min-width: 1366px) {
    margin: 0 0 2rem;
    /* width: 600px; */
  }
  @media only screen and (min-width: 1920px) {
    margin: 2rem 0 3rem;
    /* width: 700px; */
  }
`;

export const ASLButton = styled(Btn)`
  margin-bottom: 0.5rem;
  align-self: flex-start;
  @media (min-width: 768px) {
    white-space: nowrap;
  }
`;

export const ASLContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
`;

export const InteruptionCard = styled.div`
  background-color: #fff;
  border: 1px solid #000;
  padding: 2rem;
  margin-bottom: 2rem;
  max-width: 600px;
`;

export const InteruptionTitle = styled.p`
  font-weight: bold;
`;

export const InteruptionText = styled.p`
`;
