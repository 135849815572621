import { useContext, useState, useEffect } from 'react';
import kit from 'ich-ui-kit';
import OverviewCardsCallLogs from "./overview-cards-call-logs";
import CallLogTable from './call-log-table';
import { Switch, Route } from 'react-router-dom';
import Call211Details from '../../../PatientDashboard/subpages/call-log-details';
import { getLocalTimeOnce, getLocalTimeWithHHMMOnce } from '../../../../utils';

function CallLogTab() {
    const { apiService } = useContext(kit.ApiContext);
    const [isLoading, setIsLoading] = useState(false);
    const [tableData, setTableData] = useState([]);
  
    useEffect(() => {
        const getData = async () => {
            setIsLoading(true);
            const res = await apiService.get('/calllogs');
            if(res.data) {
              let rows = res.data.map((row) => ({
                ...row,
                patientName: row.patientName || 'Not a MyWayfinder User',
                startDate: getLocalTimeOnce(row.startDate),
                endDate: getLocalTimeWithHHMMOnce(row.endDate) // converting from UTC to local time
              }));
              setTableData(rows);
            }
            setIsLoading(false);
        }
        getData();
    }, []);

    return (
        <Switch>
            <Route exact path="/referral-dashboard">
                <OverviewCardsCallLogs />
                <CallLogTable 
                    loading={isLoading}
                    data={tableData}
                />
            </Route>
            <Route exact path="/referral-dashboard/view-call">
                <Call211Details />
            </Route>
        </Switch>
    )
}

export default CallLogTab;