import React, { useState, useEffect, useContext } from "react";
import * as S from "./overview.styled";
import * as WS from "./Wellbeing.styled";
import styled from "styled-components";
import {
  determineAssessmentSummaryColor, getLocalTime
} from "../../../../utils";
import {
  HealthCross32,
  Education32,
  Security32,
  PiggyBank32,
} from "@carbon/icons-react";
import kit from "ich-ui-kit";

const summaryMapping = {
  RED: "Not OK",
  ORANGE: "OK",
  YELLOW: "Good",
  LIGHT_GREEN: "Great",
  GREEN: "Excellent"
};

const summaryDDMapping = {
  1: "RED",
  2: "ORANGE",
  3: "YELLOW",
  4: "LIGHT_GREEN",
  5: "GREEN"
};

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height
  };
}

function returnDDResponses(latestDDAssessment) {
  let summaryDDRes;
  let economic;
  if (latestDDAssessment) {
    if (
      latestDDAssessment.scoreSummary.income === "0" &&
      latestDDAssessment.scoreSummary.employment === "0"
    ) {
      economic = latestDDAssessment.scoreSummary.employment;
    } else if (latestDDAssessment.scoreSummary.employment === "0") {
      economic = latestDDAssessment.scoreSummary.income;
    } else if (latestDDAssessment.scoreSummary.income === "0") {
      economic = latestDDAssessment.scoreSummary.employment;
    } else {
      economic = Math.round(
        (latestDDAssessment.scoreSummary.income +
          latestDDAssessment.scoreSummary.employment) /
          2
      );
    }
    summaryDDRes = {
      safety: latestDDAssessment.scoreSummary.transportation,
      health: "0",
      economic: economic,
      education: "0"
    };
  } else {
    summaryDDRes = {
      safety: "0",
      health: "0",
      economic: "0",
      education: "0"
    };
  }
  return summaryDDRes;
}

function returnSelfResponses() {
  let ddresponses = [
    { answer: "0", text: "Financial" },
    { answer: "0", text: "Social Connections" },
    { answer: "0", text: "Dependent Care" },
    { answer: "0", text: "Income" },
    { answer: "0", text: "Employment" },
    { answer: "0", text: "Transportation" },
    { answer: "0", text: "Housing" },
    { answer: "0", text: "Safety" },
    { answer: "0", text: "Food" },
    { answer: "0", text: "Education" },
    { answer: "0", text: "Health" }
  ];
  return ddresponses;
}

// TODO *** confirm if this component still used ***
const Wellbeing = ({
  latestAssessment,
  domains,
  client,
  responseMapping,
  latestDDAssessment
}) => {
  const { apiService } = useContext(kit.ApiContext)
  const [referrals, setReferrals] = useState([]);
  const [view, setView] = useState("selfassessed");

  let responses;
  let summaryRes;
  if (latestAssessment) {
    responses = latestAssessment.responses;
    summaryRes = latestAssessment.scoreSummary;
  } else {
    responses = returnSelfResponses();
    summaryRes = {
      safety: "0",
      health: "0",
      economic: "0",
      education: "0"
    };
  }

  const econResponses = [];
  const healthResponses = [];
  const educationResponses = [];
  const safetyResponses = [];
  let summaryDDRes;
  let ddresponses;
  let incomeDD = "0";
  let employmentDD = "0";
  let transportationDD = "0";
  if (latestDDAssessment?.scoreSummary) {
    incomeDD = latestDDAssessment.scoreSummary.income.toString();
    employmentDD = latestDDAssessment.scoreSummary.employment.toString();
    transportationDD =
      latestDDAssessment.scoreSummary.transportation.toString();
  }

  summaryDDRes = returnDDResponses(latestDDAssessment);
  ddresponses = returnSelfResponses(latestDDAssessment);

  const [currDDResponses, setCurrDDResponses] = useState(ddresponses);
  const [domainGroupDD, setDomainGroupDD] = useState("");
  const [currSelfResponses, setCurrSelfResponses] = useState(responses);
  const [domainGroupSelf, setDomainGroupSelf] = useState("");

  const econDDResponses = ddresponses.filter(
    (res) =>
      res.text === "Employment" ||
      res.text === "Financial" ||
      res.text === "Income"
  );
  const healthDDResponses = ddresponses.filter(
    (res) =>
      res.text === "Food" ||
      res.text === "Health" ||
      res.text === "Social Connections"
  );
  const educationDDResponses = ddresponses.filter(
    (res) => res.text === "Education"
  );
  const safetyDDResponses = ddresponses.filter(
    (res) =>
      res.text === "Dependent Care" ||
      res.text === "Housing" ||
      res.text === "Safety" ||
      res.text === "Transportation"
  );

  responses.map((res) => {
    if (
      res.text === "Dependent Care" ||
      res.text === "Housing" ||
      res.text === "Safety" ||
      res.text === "Transportation"
    ) {
      safetyResponses.push(res);
    } else if (
      res.text === "Food" ||
      res.text === "Health" ||
      res.text === "Social Connections"
    ) {
      healthResponses.push(res);
    } else if (
      res.text === "Employment" ||
      res.text === "Financial" ||
      res.text === "Income"
    ) {
      econResponses.push(res);
    } else if (res.text === "Education") {
      educationResponses.push(res);
    }
  });

  useEffect(() => {
    if (client) {
      const getServiceRequests = async () => {
        const data = await apiService.get(`/referrals?clientId=${client}`);
        if (data && data?.requests) {
          let refs = getLocalTime(data?.requests);
          setReferrals(
            refs.filter(
              (r) =>
                r?.status?.toLowerCase().includes("working") ||
                r?.status?.toLowerCase().includes("reopen")
            )
          );
        }
      }
      getServiceRequests()
    }
  }, [view]);

  const handleFilterChange = (e, domaingroup, content) => {
    e.preventDefault();
    if (content === "datadriven") {
      if (domainGroupDD === domaingroup) {
        setDomainGroupDD("");
        setCurrDDResponses(ddresponses);
      } else {
        setDomainGroupDD(domaingroup);
        if (domaingroup == "safety") {
          setCurrDDResponses(safetyDDResponses);
        } else if (domaingroup == "economic") {
          setCurrDDResponses(econDDResponses);
        } else if (domaingroup == "education") {
          setCurrDDResponses(educationDDResponses);
        } else if (domaingroup == "health") {
          setCurrDDResponses(healthDDResponses);
        }
      }
    } else {
      if (domainGroupSelf === domaingroup) {
        setDomainGroupSelf("");
        setCurrSelfResponses(responses);
      } else {
        setDomainGroupSelf(domaingroup);
        if (domaingroup == "safety") {
          setCurrSelfResponses(safetyResponses);
        } else if (domaingroup == "economic") {
          setCurrSelfResponses(econResponses);
        } else if (domaingroup == "education") {
          setCurrSelfResponses(educationResponses);
        } else if (domaingroup == "health") {
          setCurrSelfResponses(healthResponses);
        }
      }
    }
  };

  const determineCardColor = (res, group, content) => {
    if (content === "datadriven") {
      return determineAssessmentSummaryColor(
        summaryDDMapping[summaryDDRes[group]]
      );
    } else {
      return determineAssessmentSummaryColor(summaryRes[group]);
    }
  };

  const [width, setWidth] = useState(window.innerWidth);
  useEffect(() => {
    const handleResizeWindow = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleResizeWindow);
    return () => {
      window.removeEventListener("resize", handleResizeWindow);
    };
  }, []);

  const checkWidth = () => {
    const wh = getWindowDimensions();
    if (wh.width > 768) {
      return true;
    } else {
      return false;
    }
  };

  const determineBorder = (group, content) => {
    let dgroup;
    if (content === "datadriven") {
      dgroup = domainGroupDD;
    } else {
      dgroup = domainGroupSelf;
    }
    if (dgroup === group) {
      return 2;
    } else {
      return 0;
    }
  };

  const determineBackground = (group, content) => {
    let dgroup;
    if (content === "datadriven") {
      dgroup = domainGroupDD;
    } else {
      dgroup = domainGroupSelf;
    }
    if (dgroup === group) {
      return "rgba(244,244,244,255)";
    } else {
      return "white";
    }
  };

  const renderDomainCards = (viewName) => {
    return (
      <S.SummaryContainer>
        <S.DomainCard
          onClick={(e) => {
            handleFilterChange(e, "safety", viewName);
          }}
          theme={{
            bcolor: determineBackground("safety", viewName),
            border: determineBorder("safety", viewName)
          }}
        >
          <WS.DomainCircle
            theme={{
              bcolor: determineCardColor(summaryRes.safety, "safety", viewName)
            }}
          >
            <Security32
              style={{
                width: "100%",
                height: "100%",
                paddingTop: "3px",
                paddingBottom: "0px",
                color: "white"
              }}
            />
          </WS.DomainCircle>
          <WS.DomainCardTitle>Safety and Security</WS.DomainCardTitle>
          <WS.DomainHeader>
            {viewName === "datadriven"
              ? summaryMapping[summaryDDMapping[summaryDDRes.safety]]
              : summaryMapping[summaryRes.safety]}
          </WS.DomainHeader>
        </S.DomainCard>
        <S.DomainCard
          onClick={(e) => {
            handleFilterChange(e, "health", viewName);
          }}
          theme={{
            bcolor: determineBackground("health", viewName),
            border: determineBorder("health", viewName)
          }}
        >
          <WS.DomainCircle
            theme={{
              bcolor: determineCardColor(summaryRes.health, "health", viewName)
            }}
          >
            <HealthCross32
              style={{ width: "100%", height: "100%", color: "white" }}
            />
          </WS.DomainCircle>
          <WS.DomainCardTitle>Health and Well-being</WS.DomainCardTitle>
          <WS.DomainHeader>
            {viewName === "datadriven"
              ? summaryMapping[summaryDDMapping[summaryDDRes.health]]
              : summaryMapping[summaryRes.health]}
          </WS.DomainHeader>
        </S.DomainCard>
        <S.DomainCard
          onClick={(e) => {
            handleFilterChange(e, "economic", viewName);
          }}
          theme={{
            bcolor: determineBackground("economic", viewName),
            border: determineBorder("economic", viewName)
          }}
        >
          <WS.DomainCircle
            theme={{
              bcolor: determineCardColor(
                summaryRes.economic,
                "economic",
                viewName
              )
            }}
          >
            <PiggyBank32
              style={{ width: "100%", height: "100%", color: "white" }}
            />
          </WS.DomainCircle>
          <WS.DomainCardTitle>Economic Opportunity</WS.DomainCardTitle>
          <WS.DomainHeader>
            {viewName === "datadriven"
              ? summaryMapping[summaryDDMapping[summaryDDRes.economic]]
              : summaryMapping[summaryRes.economic]}
          </WS.DomainHeader>
        </S.DomainCard>
        <S.DomainCard
          onClick={(e) => {
            handleFilterChange(e, "education", viewName);
          }}
          theme={{
            bcolor: determineBackground("education", viewName),
            border: determineBorder("education", viewName)
          }}
        >
          <WS.DomainCircle
            theme={{
              bcolor: determineCardColor(
                summaryRes.education,
                "education",
                viewName
              )
            }}
          >
            <Education32
              style={{
                width: "100%",
                height: "100%",
                paddingTop: "3px",
                paddingBottom: "0px",
                color: "white"
              }}
            />
          </WS.DomainCircle>
          <WS.DomainCardTitle>Education Opportunity</WS.DomainCardTitle>
          <WS.DomainHeader>
            {viewName === "datadriven"
              ? summaryMapping[summaryDDMapping[summaryDDRes.education]]
              : summaryMapping[summaryRes.education]}
          </WS.DomainHeader>
        </S.DomainCard>
      </S.SummaryContainer>
    );
  };

  const renderSelfAssess = () => {
    return (
      <WS.ContentTitleWrapper>
        {width >= 1200 && <h6>Check-In Well-being</h6>}
        <WS.ContentContainer>
          {renderDomainCards("selfasses")}
          <S.InfoCardWrapper>
            <WS.StyledAccordion theme={{ width: width }}>
              {currSelfResponses
                .sort((a, b) => (a.text > b.text ? 1 : -1))
                .map((res, index) => {
                  const resources = referrals?.filter(
                    (e) => e.domain === res.text
                  );
                  return (
                    <WS.AccItemWrapper key={index}>
                      <WS.AccColorCard
                        theme={{ bcolor: responseMapping[res.answer][1] }}
                      />
                      <WS.StyledAccordionItem
                        aria-label={res.text}
                        title={
                          res.text + " — " + responseMapping[res.answer][0]
                        }
                        open={true}
                        theme={{ width: width }}
                      >
                        {resources?.length > 0 ? (
                          resources?.map((r) => {
                            return (
                              <WS.Paragraph key={r.id}>
                                <a
                                  href={`/provider-information?id=${r.orgKey}&serviceId=${r.serviceId}&locationId=${r?.locationId}`}
                                  target="_blank"
                                  rel="noreferrer noopener"
                                >
                                  <h4 style={{ cursor: "pointer" }}>
                                {r.name}
                              </h4>
                                </a>
                              </WS.Paragraph>
                            );
                          })
                        ) : (
                          <WS.Paragraph>
                            <p>No related resources on your care team</p>
                          </WS.Paragraph>
                        )}
                      </WS.StyledAccordionItem>
                    </WS.AccItemWrapper>
                  );
                })}
            </WS.StyledAccordion>
          </S.InfoCardWrapper>
        </WS.ContentContainer>
      </WS.ContentTitleWrapper>
    );
  };

  const renderDataDriven = () => {
    return (
      <WS.ContentTitleWrapper>
        {width >= 1200 && (
          <div style={{ display: "flex" }}>
            <h6>Estimated Well-being</h6>
          </div>
        )}
        <WS.ContentContainer>
          {renderDomainCards("datadriven")}
          <S.InfoCardWrapper>
            <WS.StyledAccordion theme={{ width: width }}>
              {currDDResponses
                .sort((a, b) => (a.text > b.text ? 1 : -1))
                .map((res, index) => {
                  let resources = [];
                  resources = referrals?.filter((e) => e.domain === res.text);
                  let answer = "0";
                  if (res.text === "Income") {
                    answer = incomeDD;
                  } else if (res.text === "Transportation") {
                    answer = transportationDD;
                  } else if (res.text === "Employment") {
                    answer = employmentDD;
                  }
                  return (
                    <WS.AccItemWrapper key={index}>
                      <WS.AccColorCard
                        theme={{ bcolor: responseMapping[answer][1] }}
                      />
                      <WS.StyledAccordionItem
                        aria-label={res.text}
                        title={res.text + " — " + responseMapping[answer][0]}
                        open={true}
                      >
                        {resources && resources.length > 0 ? (
                          resources.map((r) => {
                            return (
                              <WS.Paragraph>
                                <a
                                  href={`/provider-information?id=${r.orgKey}&serviceId=${r.serviceId}&locationId=${r?.locationId}`}
                                  target="_blank"
                                  rel="noreferrer noopener"
                                >
                                  <h4 style={{ cursor: "pointer" }}>
                                {r.name}
                              </h4>
                                </a>
                              </WS.Paragraph>
                            );
                          })
                        ) : (
                          <WS.Paragraph>
                            <p>No related resources on your care team</p>
                          </WS.Paragraph>
                        )}
                      </WS.StyledAccordionItem>
                    </WS.AccItemWrapper>
                  );
                })}
            </WS.StyledAccordion>
          </S.InfoCardWrapper>
        </WS.ContentContainer>
      </WS.ContentTitleWrapper>
    );
  };

  return (
    <WS.PageContainer>
      {width < 1200 ? (
        <>
          <WS.Stydiv>
            <WS.ViewButton
              kind={view === "datadriven" ? "secondary" : "tertiary"}
              onClick={() => setView("datadriven")}
            >
              Estimated Well-being
            </WS.ViewButton>
            <WS.ViewButton
              kind={view === "selfassessed" ? "secondary" : "tertiary"}
              onClick={() => setView("selfassessed")}
            >
              Check-In Well-being
            </WS.ViewButton>
          </WS.Stydiv>
          {view === "datadriven" ? renderDataDriven() : renderSelfAssess()}
        </>
      ) : (
        <WS.Stydiv>
          {renderSelfAssess()}
          {renderDataDriven()}
        </WS.Stydiv>
      )}
    </WS.PageContainer>
  );
};

export const Content = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  max-width: 700px;
  height: 100%;
  justify-content: center;
`;

export default Wellbeing;
