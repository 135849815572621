import styled from "styled-components";

export const Container = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
`;
export const Content = styled.div`
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
`;
export const Heading = styled.h1`
  font-size: clamp(1.25rem, 7vw, 2rem);
  font-weight: 500;
  margin-bottom: 0.25rem;
`;
export const HeadingMain = styled.h1`
  font-size: clamp(1.5rem, 10vw, 3rem);
  font-weight: 700;
  margin-bottom: 0.75rem;
`;

export const SubHeading = styled.h1`
  font-size: 1.25rem;
  font-weight: 400;
`;

export const Img = styled.img`
  width: 12rem;
  height: 12rem;
  margin-bottom: 2rem;
`;
