import styled from "styled-components";
import { Search16 } from "@carbon/icons-react";
import { DatePicker, Button, ToastNotification } from "carbon-components-react";

export const HomepageContainer = styled.div`
  background-color: #d8d8d8;
  width: 100%;
  height: 100vh;
  overflow: auto;
`;

export const ResponsiveImg = styled.img`
  height: 5rem;
  width: 5rem;
  align-self: center;
  border-radius: 50%;
  border: 1px solid #8d8d8d;
`;

export const CustomBookmarkBtn = styled(Button)`
  margin-top: auto;
  margin-bottom: auto;
  margin-left: 1.5rem;
  border-radius: 100%;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  @media screen and (max-width: 767px) {
    margin-left: 0.5rem;
    padding: 14px;
  }
`;

export const HomepageGrid = styled.div`
  display: grid;
  grid-template-rows: auto 90%;
  grid-template-areas:
    "client-search"
    "my-clients";
  width: 95%;
  height: 100%;
  margin: auto;
  @media screen and (max-width: 900px) {
    margin-right: 0rem;
    margin-top: 0rem;
    height: 100%;
    width: 100%;
  }
  @media screen and (max-width: 767px) {
  }
`;

export const ClientSearchDiv = styled.div`
  margin-top: 1rem;
  grid-area: client-search;
  background-color: white;
  border-bottom: 12px solid #c6c6c6;
  @media screen and (max-width: 767px) {
    margin-top: 0rem;
  }
`;

export const DivHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-left: 1.5rem;
  margin-right: 1.5rem;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
`;

export const TextInputContainer = styled.div`
  width: 70%;
  margin-top: 1rem;
  margin-left: 10rem;
  .bx--date-picker.bx--date-picker--single .bx--date-picker__input {
    width: 25rem;
  }
  .bx--text-input {
    width: 25rem;
  }
  justify-content: space-around;

  @media screen and (max-width: 900px) {
    display: flex;
    flex-direction: row;
    margin-left: 0rem;
    width: 100%;
  }
  @media screen and (max-width: 767px) {
    display: flex;
    flex-direction: column;
    margin-left: 1.5rem;
    width: 90%;
  }
`;

export const TextInputDiv = styled.div`
  display: flex;
  //margin-bottom: 1.5rem;

  @media screen and (max-width: 900px) {
    display: flex;
    flex-direction: column;
    width: 20rem;
  }
`;

export const StyledDatePickerInput = styled(DatePicker)`
  width: 25rem;
  margin: 0rem;
  display: flex;
  flex-direction: column;

  @media screen and (max-width: 900px) {
    margin: 0rem;
    width: 20rem;
  }
`;

export const StyledSearchIcon = styled(Search16)`
  margin-left: 3rem;
`;

export const MyClientsDiv = styled.div`
  grid-area: my-clients;
  background-color: white;
  height: fit-content;
  margin-bottom: 1rem;
`;

export const AllClientsDiv = styled.div`
  grid-area: my-clients;
  background-color: white;
  min-height: 92%;
  overflow: auto;
  margin: 1rem;
  padding: 2rem;
  .bx--data-table-content {
    min-height: 50vh;
  }
`;

export const StyledIconButton = styled.button`
  background-color: white;
  border: none;
  cursor: pointer;
`;

export const TableContainer = styled.div`
  margin-top: 1.5rem;
  /* margin-left: auto; */
  width: auto;

  @media screen and (max-width: 900px) {
    margin-top: 0rem;
    display: flex;
    flex-direction: column;
  }
`;

export const ViewMoreButtonContainer = styled.div`
  width: 6rem;
  margin-left: 10rem;
`;

export const ViewMoreButton = styled.button`
  background-color: white;
  border: none;
  color: #0f62fe;
  font-size: 1rem;
  cursor: pointer;
`;

export const SearchButtonDiv = styled.div`
  margin-bottom: 2rem;
  margin-left: 10rem;
  width: 5rem;
  .bx--btn {
    padding-right: 1rem;
  }
  @media screen and (max-width: 900px) {
    margin-bottom: 1rem;
    margin-left: 2rem;
  }
  @media screen and (max-width: 767px) {
    margin-left: 1.5rem;
  }
`;

export const ClearButtonDiv = styled.div`
  margin-left: 5rem;
`;

export const FlexDiv = styled.div`
  display: flex;
`;

export const ToggleSearchBtn = styled(Button)`
  background: none;
  border: none;
  color: blue;
`;

export const StyledToastNotification = styled(ToastNotification)`
  position: absolute;
  right: 5%;
  z-index: 9999;
`;
