import { InlineLoading } from 'carbon-components-react';
import S from './inlineLoading.module.scss';

const InlineLoadingCompact = ({ description, props }) => {
  return (
    <InlineLoading description={description} {...props} className={S.compact} />
  );
};

export default InlineLoadingCompact;
