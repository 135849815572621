import React, { useState, useContext, useEffect } from "react";
import {
  ErrorBoundary,
  Facet,
  SearchProvider,
  SearchBox,
  Results,
  PagingInfo,
  ResultsPerPage,
  Paging,
  WithSearch
} from "@elastic/react-search-ui";
import "@elastic/react-search-ui-views/lib/styles/styles.css";
import { Dropdown, Loading } from 'carbon-components-react';
import kit from "ich-ui-kit";
import { useHistory } from "react-router";
import { getConfig } from "./config/config-helper";
import { parseProviderData, getConnectedDisplay } from './view-helpers';
import { CustomResultsView, CustomResultView, CustomFacetView, Sidebar, Map } from './components';
import { SearchBox as SearchBoxView } from "../../components/SearchBox";
import PatientInContext from "../../components/PatientInContext";
import { ContextProvider } from './context';
import useQuery from "../../hooks/useQuery"
import { useClient } from "../../store/CallLogContext";
import { GeolocationContext } from "../../store/GeolocationContext";
import S from './elasticsearch.module.scss';
import { MWFSearchProvider } from "../../store/MWFSearchContext";

import config from "./config";

const RESULT_OPTIONS = [10, 20, 30, 40, 50];

const ElasticSearch = ({ wasSearched, isLoading, results, resultSearchTerm, showRecommendedResources }) => {
  const query = useQuery();
  const enteredSearch = query.get("q");

  const [showFilters, setshowFilters] = useState(false);
  const [patient, setPatient] = useState();
  const [patientLoading, setPatientLoading] = useState(false);

  const showNeigborhoodCenters =
    resultSearchTerm === "" && (enteredSearch === "" || enteredSearch === null);

  const getPagingInfo = () => {
    if (wasSearched) {
      return (
        <PagingInfo
          view={({ start, end, totalResults, searchTerm }) => (
            <div className={`paging-info  ${S.pagingInfo}`}>
              <span>
                Showing {start} - {end} out of {totalResults}
              </span>
            </div>
          )}
        />
      );
    }
  };


  const getResultsPerPageInfo = () => {
    if(wasSearched) {
      return (
        <ResultsPerPage
          options={RESULT_OPTIONS}
          view={({
            className,
            onChange,
            options,
            value: selectedValue,
            ...rest
          }) => {
            let selectedOption = null;
            const wrapOption = (option) => ({ label: option, value: option });

            if (selectedValue) {
              selectedOption = wrapOption(selectedValue);
          
              if (!options.includes(selectedValue)) options = [selectedValue, ...options];
            }
            
            return (
            <>
              <div className={`sui-results-per-page ${className}`} {...rest}>
                <label for="sui-results-page-page-options-dropdown" className="sui-results-per-page__label">Show</label>
                <Dropdown
                  id= "sui-results-page-page-options-dropdown"
                  className="sui-select sui-select--inline"
                  hideLabel={true}
                  initialSelectedItem = {selectedOption}
                  onChange={(o) => onChange(o.selectedItem.value)}
                  items={options.map(wrapOption)}
                  itemToString={(option) => (option ? option.value : "")}
                  components={{
                    Option: (props) => {
                      props.innerProps[
                        "data-transaction-name"
                      ] = `results per page`;
                      return Option(props);
                    },
                  }}
                />
              </div>
            </>
          )}}
        />
      );
    }
  }

  // Hooks
  let history = useHistory();
  const { user } = useContext(kit.UserContext);
  const { apiService } = useContext(kit.ApiContext);
  const { client } = useClient();

  // Pull in user's zipcode
  const getPatientZipcode = async (patientId) => {
    try {
      setPatientLoading(true);
      const data = await apiService.get(`/clients/${patientId}?assumedId=${user?.superAssumedRole?.leadId || ""}`);
      if (data) {
        setPatient({
          ...data,
          id: patientId,
        });
      } else if (data) {
        setPatient({
          ...data,
          id: patientId,
        });
      }
    } catch (err) {
      console.log(err);
    } finally {
      setPatientLoading(false);
    }
  };

  const determineScrollContainerHeight = (patient, showNavigationText, showRecommendedResources) => {
    if (patient && showNavigationText) {
      return {height: 'calc(100% - 19rem)'};
    } else if ((patient && !showNavigationText) || (!patient && showNavigationText && !showRecommendedResources)) {
      return {height: 'calc(100% - 13rem)'};
    } else {
      return {height: 'calc(100% - 7rem)'};
    }
  };

  useEffect(() => {
    if (history.location.state && history.location.state.patientId && !client) {
      getPatientZipcode(history.location.state.patientId);
    }
  }, [history.location.state?.patientId]);

  useEffect(() => {
    if (client) {
      getPatientZipcode(client.id);
    } else {
      setPatient();
    }
  }, [client?.id]);

  return (
    <ContextProvider>
    <div className={S.wrapper}>
      <div className="App">
        <ErrorBoundary>
          <div
            className={S.bodyContainer}
            style={{
              gridTemplateColumns: wasSearched && !showNeigborhoodCenters ? '250px 1fr' : '1fr'
            }}
          >
            {!showNeigborhoodCenters && (
              <Sidebar
                wasSearched={wasSearched}
                showFilters={showFilters}
                setshowFilters={setshowFilters}
              >
                <Facet
                  className={S.facet}
                  field="connectedService.keyword"
                  label="Connected"
                  view={CustomFacetView}
                  transformDisplay={getConnectedDisplay}
                  filterOptions={(options)=> {
                    return (options || []).filter(({ value }) => value !== '0' )
                  }}
                />
                <Facet
                  className={S.facet}
                  field="taxonomyTerm.keyword"
                  label="Category"
                  isFilterable={true}
                  view={CustomFacetView}
                />
                <Facet
                  className={S.facet}
                  field="coverage.county.keyword"
                  label="Coverage area: County"
                  view={CustomFacetView}
                />
                <Facet
                  className={S.facet}
                  field="coverage.zipPostalCode.keyword"
                  label="Coverage area: Zip code"
                  view={CustomFacetView}
                />
                <Facet
                  className={S.facet}
                  field="regionPhysicalAddress.keyword"
                  label="Service location: County"
                  view={CustomFacetView}
                />
                <Facet
                  className={S.facet}
                  field="zipcodePhysicalAddress.keyword"
                  label="Service location: Zip code"
                  view={CustomFacetView}
                />
              </Sidebar>
            )}
            <div className={S.contentContainer}>
              <div className={S.providersContainer}>
                <div className={S.searchAndResultsContainer}>
                  <div className={S.header}>
                    <SearchBox
                      autocompleteSuggestions={true}
                      view={(props) => (
                        <SearchBoxView
                          {...props}
                          handleFilter={() =>
                            setshowFilters(!showFilters)
                          }
                        />
                      )}
                    />
                  </div>
                  <div className={S.resultsContainer}>
                      {(history.location?.state?.patientId || patient) && (
                        <PatientInContext
                          patient={patient}
                          loading={patientLoading}
                          floating
                          noPadding
                        />
                      )}
                    <div className={S.leftColumnHeader}>
                      {getPagingInfo()}
                      {getResultsPerPageInfo()}
                    </div>
                    {showNeigborhoodCenters && !showRecommendedResources && (
                      <div style={{ margin: '1rem' }}>
                        <h4>Use the search bar above to find services you want or connect with a Neighborhood Navigation Center near you!</h4>
                        <br/>
                        <h5>Neighborhood Navigation Centers:</h5>
                      </div>
                    )}
                    <div className={S.resultsScrollContainer} style={determineScrollContainerHeight(history.location?.state?.patientId || patient, showNeigborhoodCenters, showRecommendedResources)}>
                      <div className={S.resultsInnerContainer}>
                        {isLoading && (
                          <div className={S.loadingContainer}>
                            <Loading withOverlay={false} />
                          </div>
                        )}
                        <Results
                          titleField={getConfig().titleField}
                          urlField={getConfig().urlField}
                          thumbnailField={getConfig().thumbnailField}
                          shouldTrackClickThrough={false}
                          view={CustomResultsView}
                          resultView={(props) => (
                            <CustomResultView
                              {...props}
                              patient={patient}
                            />
                          )}
                        />
                      </div>
                    </div>
                    <div className={S.leftColumnFooter}>
                      {wasSearched && <Paging />}
                    </div>
                  </div>
                </div>
                <Map providers={results?.map(p => parseProviderData(p))} />
              </div>
            </div>
          </div>
        </ErrorBoundary>
      </div>
    </div>
    </ContextProvider>
  );
}

const ElasticSearchPage = () => {
  const { geolocation } = useContext(GeolocationContext)
  let history = useHistory();

  const recommendedResourceIds =
    history?.location?.state?.resources?.map(
        ({ serviceAtLocationKey, serviceLocationId }) => serviceAtLocationKey || serviceLocationId || null
      ) || [];

  return(
    <MWFSearchProvider>
      <SearchProvider config={config({ recommendedResourceIds, geolocation })}>
        <WithSearch mapContextToProps={({ wasSearched, isLoading, results, resultSearchTerm }) => ({ wasSearched, isLoading, results, resultSearchTerm })}>
        {(props) => {
          return <ElasticSearch {...props} showRecommendedResources={recommendedResourceIds?.length} />
        }}
        </WithSearch>
      </SearchProvider>
    </MWFSearchProvider>
  )
};

export default ElasticSearchPage;