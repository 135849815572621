import { useState } from "react";
import { useCompleteProfile } from "../../../../cms";
import { useDimension } from "../../../../../../../hooks/useDimension";
import { TextInputRow, StyledTextInput } from "../AboutMe.styled";
import { shouldAutoComplete } from "../../../../../../../utils";

const ContactInfo2 = ({ setEmergencyContactInfo, setDisableButton }) => {
  const { uiCMS: cms } = useCompleteProfile();
  const { windowSize } = useDimension();
  const [phoneNumber, setPhoneNumber] = useState("");
  const [email, setEmail] = useState("");
  const handlePhoneChange = (e) => {
    e.preventDefault();
    let x = e.target.value
      .replace(/\D/g, "")
      .match(/(\d{0,3})(\d{0,3})(\d{0,4})/);

    let formattedPhone = (e.target.value = !x[2]
      ? x[1]
      : x[1] + "-" + x[2] + (x[3] ? "-" + x[3] : ""));

    setEmergencyContactInfo((state) => ({
      ...state,
      phone: formattedPhone
    }));
    setPhoneNumber(formattedPhone);
  };
  const [isInvalid, setIsInvalid] = useState({
    firstName: false,
    lastName: false,
    email: false,
    relationship: false
  });

  if (windowSize.width < 768) {
    return (
      <div>
        <StyledTextInput
          id="phone"
          labelText={cms.contact.label_mobile}
          placeholder="xxx-xxx-xxxx"
          value={phoneNumber}
          onChange={(e) => handlePhoneChange(e)}
          autoComplete={shouldAutoComplete}
        />
        <StyledTextInput
          labelText={cms.eContact.label_email}
          id="email"
          placeholder="JohnSmith@email.com"
          value={email}
          invalid={isInvalid.email}
          invalidText={cms.error_invalid_email}
          onChange={(e) => {
            setDisableButton(false);
            setEmail(e.target.value);
            setEmergencyContactInfo((state) => ({
              ...state,
              email: e.target.value
            }));
            setIsInvalid((state) => ({
              ...state,
              email:
                e.target.value !== "" && !/\S+@\S+\.\S+/.test(e.target.value)
            }));
          }}
          autoComplete={shouldAutoComplete}
        />
      </div>
    )
  } else {
    return (
        <div>
          <StyledTextInput
              style={{marginBottom: '1rem'}}
              id="phone"
              labelText={cms.contact.label_mobile}
              placeholder="xxx-xxx-xxxx"
              value={phoneNumber}
              onChange={(e) => handlePhoneChange(e)}
              autoComplete={shouldAutoComplete}
            />
            <StyledTextInput
              labelText={cms.eContact.label_email}
              id="email"
              placeholder="JohnSmith@email.com"
              value={email}
              invalid={isInvalid.email}
              invalidText={cms.error_invalid_email}
              onChange={(e) => {
                setDisableButton(false);
                setEmail(e.target.value);
                setEmergencyContactInfo((state) => ({
                  ...state,
                  email: e.target.value
                }));
                setIsInvalid((state) => ({
                  ...state,
                  email:
                    e.target.value !== "" && !/\S+@\S+\.\S+/.test(e.target.value)
                }));
              }}
              autoComplete={shouldAutoComplete}
            />
        </div>
    );
  }
};
export default ContactInfo2;
