import React, { useState, useContext, useEffect } from 'react';
import styled from 'styled-components';
import {
  TextInput,
  Modal,
  Tabs,
  Tab,
  Checkbox
} from 'carbon-components-react';
import kit from 'ich-ui-kit';
import useForm from '../../../../hooks/useForm';
import validate from "./validator";
import { PasswordTooltip } from '../../../../components/PasswordTooltip';
import { useHistory } from 'react-router-dom';
import { shouldAutoComplete } from '../../../../utils';

const WelcomeModal = ({ setIsComplete }) => {
  const history = useHistory();
  const { apiService } = useContext(kit.ApiContext);
  const { user, updateUser, clearUser } = useContext(kit.UserContext);
  const [agreed, setAgreed] = useState(false);
  const [index, setIndex] = useState(0);
  const [showTool, setShowTool] = useState(false);
  const [type1, setType1] = useState('password');
  const [type2, setType2] = useState('password');
  const [loading, setLoading] = useState(false);
  const {
    values,
    setValues,
    errors,
    isDisabled,
    setIsDisabled,
    handleChange,
  } = useForm(validate);

  useEffect(() => {
    setValues({
      password: "",
      confirmPassword: "",
    });

    setIsDisabled({
      confirmPassword: true,
    });
  }, [setValues, setIsDisabled]);

  const handleReset = async (e) => {
    setLoading(true);
    let response = await apiService.put(`/iam/change-credentials?assumedId=${user.superAssumedRole?.leadId || ""}`,
      {
        username: user.username,
        password: values.password,
        currentPassword: values.confirmPassword,
        firstLogin: true
      }
    );
    if (response.ok) {
      updateUser({ firstLogin: null });
      setIsComplete(true);
    } else {
      setLoading(false);
    }
  };

  const togglePasswordVisibility = (passType, set) => {
    set(passType === "password" ? "text" : "password");
  };

  const agreeToTerms = () => {
    setAgreed(!agreed);
    if(!agreed && index === 0){
      setTimeout(() => {
        setIndex(1);
      }, 500)
    }
  }

  const logout = () => {
    clearUser();
    localStorage.clear()
    window.location.href = "/login";
  };

  // window.onbeforeunload = () => {
  //   logout();
  // }

  return (
    <StyledModal
      open={user.firstLogin}
      modalHeading='Welcome to MyWayfinder'
      shouldSubmitOnEnter={false}
      size='sm'
      hasForm
      primaryButtonText='Submit'
      onRequestSubmit={handleReset}
      primaryButtonDisabled={loading || isDisabled.confirmPassword || !agreed}
      secondaryButtonText='Logout'
      onSecondarySubmit={logout}
      preventCloseOnClickOutside
    >
      <ResetFormContainer>
        <Tabs selected={index}>
          <Tab
            id="new-staff-agreement"
            label="Agreement"
          >
            <p style={{marginBottom: "0.5rem"}}>
              I understand and agree that I am being provided with access to the MyWayfinder Platform at the direction and request of an entity that participates in MyWayfinder.  
              I have reviewed and agree to the <a href="/terms-and-conditions" target="_blank" rel="noreferrer noopener">terms and conditions</a> of the MyWayfinder Platform. I understand that before I can access any information on the MyWayfinder 
              Platform I must review and agree to abide by the MyWayfinder Platform’s <a href="/policies-and-procedures" target="_blank" rel="noreferrer noopener">policies and procedures</a> and participate in any training as directed.  
              I agree that my use of MyWayfinder will be in compliance with all applicable laws and the <a href="/policies-and-procedures" target="_blank" rel="noreferrer noopener">policies and procedures</a> of the MyWayfinder Platform and that I will only 
              use the MyWayfinder Platform for lawful purposes.
            </p>
            <Checkbox 
              onClick={agreeToTerms}
              checked={agreed}
              labelText="I agree" 
              id="checked-2"
            />
          </Tab>
          <Tab
            id="new-staff-password-reset"
            label="Password Reset"
            disabled={!agreed}
          >
            <p>
              It looks like you have not updated your password since receiving access to MyWayfinder.
              Please reset your password so that you may access all of the great features provided by MyWayfinder
              to help support your community.
            </p>
            <form>
              <InputDiv>
                <StyledTextInput.ControlledPasswordInput
                  type={type1}
                  togglePasswordVisibility={() => togglePasswordVisibility(type1, setType1)}
                  id="password"
                  labelText="Password"
                  onChange={handleChange}
                  tooltipAlignment={"end"}
                  onClick={() => setShowTool(true)}
                  onBlur={() => setShowTool(false)}
                  autoComplete={shouldAutoComplete}
                ></StyledTextInput.ControlledPasswordInput>
                <StyledTextInput.ControlledPasswordInput
                  type={type2}
                  togglePasswordVisibility={() => togglePasswordVisibility(type2, setType2)}
                  id="confirmPassword"
                  labelText="Confirm Password"
                  onChange={handleChange}
                  invalid={!!errors.confirmPassword}
                  invalidText={errors.confirmPassword}
                  tooltipAlignment={"end"}
                  autoComplete={shouldAutoComplete}
                />
              </InputDiv>
              {showTool && (
                  <PasswordTooltip
                    errors={errors.password || []}
                    open={showTool}
                    showIcon={false}
                    align="start"
                  />
              )}
            </form>
          </Tab>
        </Tabs>
      </ResetFormContainer>
    </StyledModal>
  );
};

const StyledModal = styled(Modal)`
  && .bx--modal-close {
    display: none;
  }
  // && .bx--modal-footer > .bx--btn--secondary {
  //   background-color: darkred;
  // }
  backdrop-filter: blur(0.5rem);

`;

const ResetFormContainer = styled.div`
  min-height: 21rem;
  & h2 {
    font-weight: 500;
    margin-bottom: 2rem;
  }
  & p {
    color: #8d8d8d;
    font-size: 1rem;
    margin-bottom: 4px;
  }
`;

const InputDiv = styled.div`
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  @media screen and (min-width: 900px){
    flex-direction: row;
  }
`;

const StyledTextInput = styled(TextInput)`
  width: 100%;
`;

export default WelcomeModal;
