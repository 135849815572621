import React from "react";

import {
  Card,
  NotificationsGrid,
  TitleRow,
  SubtitleRow,
  AccountRowSmall,
  NotificationRowSmall,
  NotificationRowBig,
  NotificationInfoSecondaryLeft,
  AccountTitleContainer,
  NotificationInfo,
  SingleColumn,
  BorderRowContainer,
  BorderRow,
  Spacer
} from "../components";
import { Toggle, InlineLoading } from "carbon-components-react";

const Notifications = ({
  mfaToggle,
  toggle,
  hasPhone,
  hasEmail,
  mfaLoading,
  notificationsLoading,
  toggleEmailNotifications,
  emailNotificationToggle
}) => {
  return (
    <NotificationsGrid>
      <SingleColumn>
        <TitleRow style={{ paddingBottom: "0" }}>
          Notification Preferences
        </TitleRow>
        <SubtitleRow style={{ marginTop: "0" }}>
          Receive notifications about activity across your account, such as referrals and messages from your clients.
        </SubtitleRow>
        <Card moreWidth>
          <AccountTitleContainer>
            <NotificationRowBig>
              <NotificationInfoSecondaryLeft>
                <h5>Update Account Information Preferences</h5>
                <p>You can opt-in to receive email notifications about your account.</p>
              </NotificationInfoSecondaryLeft>
            </NotificationRowBig>
          </AccountTitleContainer>
          <Spacer />
          <NotificationRowSmall>
            <NotificationInfo>Email</NotificationInfo>
            <Toggle
              aria-label="toggle button"
              toggled={emailNotificationToggle}
              onToggle={toggleEmailNotifications}
              id="toggle-notification-email"
              disabled={!hasEmail}
              labelA={notificationsLoading ? <InlineLoading /> : "Off"}
              labelB={notificationsLoading ? <InlineLoading /> : "On"}
            />
          </NotificationRowSmall>
          <AccountRowSmall>
            Receive information such as referral updates via email
          </AccountRowSmall>
          <BorderRowContainer>
            <BorderRow />
          </BorderRowContainer>
        </Card>
        <Card moreWidth>
          <AccountTitleContainer>
            <NotificationRowBig>
              <NotificationInfoSecondaryLeft>
                <h5>Advanced Access Authentication Preferences</h5>
                <p>
                  Advanced Access Authentication is the security tool that
                  generates the verification code you use to login to your
                  account. You can opt-in to receive an email or a text message.
                  Advanced Access Authentication is required and only one option
                  can be selected.
                </p>
              </NotificationInfoSecondaryLeft>
            </NotificationRowBig>
          </AccountTitleContainer>
          <Spacer />
          <NotificationRowSmall>
            <NotificationInfo>Email</NotificationInfo>
            <Toggle
              aria-label="toggle button"
              toggled={mfaToggle}
              onToggle={toggle}
              id="toggle-mfa-email"
              disabled={!hasEmail}
              labelA={mfaLoading ? <InlineLoading /> : "Off"}
              labelB={mfaLoading ? <InlineLoading /> : "On"}
            />
          </NotificationRowSmall>
          <AccountRowSmall>Receive verification code via email</AccountRowSmall>
          <BorderRowContainer>
            <BorderRow />
          </BorderRowContainer>
          <Spacer />
          <NotificationRowSmall>
            <NotificationInfo>Text message</NotificationInfo>
            <Toggle
              aria-label="toggle button"
              toggled={!mfaToggle}
              onToggle={toggle}
              id="toggle-mfa-text"
              disabled={!hasPhone}
              labelA={mfaLoading ? <InlineLoading /> : "Off"}
              labelB={mfaLoading ? <InlineLoading /> : "On"}
            />
          </NotificationRowSmall>
          <AccountRowSmall>
            Receive verification code via text messages
          </AccountRowSmall>
          <BorderRowContainer>
            <BorderRow />
          </BorderRowContainer>
          <Spacer />
        </Card>
      </SingleColumn>
    </NotificationsGrid>
  );
};
export default Notifications;
