import React, { useState, useEffect, useContext } from "react";
import {
  Grid,
  Row,
  Column,
  SkeletonText,
} from "carbon-components-react";
import { useHistory, useLocation } from "react-router-dom";
import kit from "ich-ui-kit";
import { ProviderCard } from "../../../../components/ServiceCard/v2";
import { SearchProvider } from "../../../resources/context/SearchResultsContext.context";
import { getLocalTimeOnce, parseHtml } from "../../../../utils";

import * as S from "./ReferralDetails.styles";

const Call211Details = () => {
  // General
  const location = useLocation();
  const history = useHistory();
  const { apiService } = useContext(kit.ApiContext);
  const { user } = useContext(kit.UserContext);
  // State - Details
  const [loadingReferral, setLoadingReferral] = useState(false);
  const [details, setDetails] = useState();

  // Functionality - Details
  useEffect(() => {
    fetchDetails();
  }, []);

  const fetchDetails = async () => {
    setLoadingReferral(true);
    const res = await apiService.get(`/calllogs/${location.state?.referralId || null}`)
    if(res.data){
      let temp = res.data;
      if (temp?.resources && temp?.resources?.length > 0) {
        temp.resources = temp?.resources.map(r => ({ ...r, overview: parseHtml(r.overview) }));
      }
      setDetails(temp);
    }
    setLoadingReferral(false);
  }
  
  // Functionality - Helpers
  const close = () => {
    if(location?.state?.from === "callLogDashboard"){
      history.push({
        pathname: "/referral-dashboard",
        state: {
          from: location?.state?.from,
          cameFromTab: history?.location?.state?.cameFromTab
        }
      })
    } else {
      history.push({
        pathname: "/360dashboard/service-requests",
        state: {
          client: history.location?.state?.patientId
        }
      })
    }
  }

  return (
    <S.StyledCard>
      <S.BlueBackLink onClick={close}>
          Back
        </S.BlueBackLink>
      <Grid>
        <Row>
          {loadingReferral ? (
            <Column xs={16} lg={8}>
              <div style={{ paddingTop: "2rem" }}>
                <SkeletonText heading paragraph lineCount={3} />
              </div>
            </Column>
          ) : (
            <>
              <Column xs={16} lg={8}>
                <S.HeaderDiv>
                    <h4>
                        <strong>211 Call Report</strong>
                    </h4>
                    <p>{getLocalTimeOnce(details?.endDateTime)}</p>
                    <p>{details?.displayName}</p>
                </S.HeaderDiv>
              </Column>
              <Column xs={16} lg={8}>
                <S.UpdateStatusContainer>
                  <h5>
                    <strong>Call #{details?.id || "N/A"}</strong>
                  </h5>
                </S.UpdateStatusContainer>
              </Column>
            </>
          )}
        </Row>
        <Row>
          <S.HorizontalRule />
        </Row>
        <br />
        <SearchProvider>
          {details?.resources ? (
            <Column style={{ marginBottom: "3rem" }}>
              <Row>
                <h4>Recommended Services</h4>
              </Row>
              <br />
              <Row style={{ justifyContent: "flex-start" }}>
                {loadingReferral ? (
                  <div style={{ width: "100%" }}>
                    <SkeletonText paragraph lineCount={3} />
                  </div>
                ) : details?.resources.length === 0 ? 
                  (
                  <Column style={{ marginBottom: "3rem" }}>
                    <Row>
                      No resources were recommended.
                    </Row>
                  </Column>
                  ) 
                  : 
                  (
                  <S.SectionWrapper>
                    {(details?.resources || []).map((service, i) => (
                      <ProviderCard 
                        key={i}
                        provider={service}
                        patient={{ id: history.location.state?.patientId }}
                      />
                    ))}
                  </S.SectionWrapper>
                )}
              </Row>
            </Column>
          ) : !loadingReferral ? (
            <Column style={{ marginBottom: "3rem" }}>
              <Row>
                Error getting data. Please try again later.
              </Row>
          </Column>
          ) : (
            <Column style={{ marginBottom: "3rem" }}>
              <Row>
                <SkeletonText heading paragraph lineCount={3} />
              </Row>
          </Column>
          )}
        </SearchProvider>
      </Grid>
    </S.StyledCard>
  );
};

export default Call211Details;
