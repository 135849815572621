import { useState } from "react";
import { useCompleteProfile } from "../../../../cms";
import { ResponseWrapper, StyledButtonGroup, StyledButton } from "../AboutMe.styled";

const HousingSituation = ({ setHousingSituation, setDisableButton, setAboutMeResponse }) => {
  const { uiCMS: cms } = useCompleteProfile();
  const [buttonSelection, setButtonSelection] = useState({
    0: false,
    1: false,
    2: false
  });

  let situationOptions = {
    permanent: {
      text: cms.btn_permanent,
      value: "Permanent",
    },
    temporary: {
      text: cms.btn_temporary,
      value: "Temporary",
    },
    no_current: {
      text: cms.btn_no_current,
      value: "No current housing",
    },
  }


  return (
    <ResponseWrapper>
      <StyledButtonGroup>
        <StyledButton
          theme={{ bcolor: buttonSelection[0] }}
          kind="tertiary"
          onMouseDown={() => {
            setButtonSelection({ 0: true, 1: false, 2: false });
            setHousingSituation(true);
            setDisableButton(false);
            setAboutMeResponse((state) => ({
              ...state,
              housingSituation: situationOptions.permanent.value,
            }));
          }}
        >
          {situationOptions.permanent.text}
        </StyledButton>
        <StyledButton
          theme={{ bcolor: buttonSelection[1] }}
          kind="tertiary"
          onMouseDown={() => {
            setButtonSelection({ 0: false, 1: true, 2: false });
            setHousingSituation(true);
            setDisableButton(false);
            setAboutMeResponse((state) => ({
              ...state,
              housingSituation: situationOptions.temporary.value,
            }));
          }}
          onTouchStart={() => {
            setButtonSelection({ 0: false, 1: true, 2: false });
            setHousingSituation(true);
            setDisableButton(false);
            setAboutMeResponse((state) => ({
              ...state,
              housingSituation: situationOptions.temporary.value,
            }));
          }}
        >
          {situationOptions.temporary.text}
        </StyledButton>
        <StyledButton
          theme={{ bcolor: buttonSelection[2] }}
          kind="tertiary"
          onMouseDown={() => {
            setButtonSelection({ 0: false, 1: false, 2: true });
            setHousingSituation(false);
            setDisableButton(false);
            setAboutMeResponse((state) => ({
              ...state,
              housingSituation: situationOptions.no_current.value,
            }));
          }}
        >
          {situationOptions.no_current.text}
        </StyledButton>
      </StyledButtonGroup>
    </ResponseWrapper>
  );
};

export default HousingSituation;

