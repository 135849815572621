import styled from "styled-components";

export const Item = styled.div`
  .line-break {
    white-space: pre-wrap;
    li {
      white-space: normal;
    }
  }
  margin-bottom: 2rem;
`;

export const PageContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

export const TermsAndConditionsContainer = styled.div`
  max-width: 1366px;
  padding: 1rem;
  @media screen and (min-width: 768px) {
    padding: 2rem;
  }
  ol {
    display: block;
    list-style-type: decimal;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 40px;
  }
  ul {
    display: block;
    list-style-type: disc;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 40px;
  }
`;

export const TermsAndConditionsHeader = styled.h4`
  padding-bottom: 1rem;
  text-decoration: underline;
  text-transform: uppercase;
`;

export const Header = styled.h6`
  margin-bottom: 1rem;
`;
