import React from "react";
import styled from "styled-components";
import {
  User24,
  Map24,
  Logout24,
  Home24,
  Archive24,
  Activity24
} from "@carbon/icons-react";

const MobileNavContainer = styled.div`
  background-color: ${(props) => props.theme.colors.primary.darkBlue};
  height: 100%;
  overflow: auto;
  padding-top: 1rem;
`;

const NavLink = styled.div`
  height: 2.5em;
  padding-left: 1em;
  cursor: pointer;
  color: #f2f4f8;
  display: flex;
  align-items: center;
  & svg {
    margin-right: 5px;
  }

  & div {
    display: inline-flex;
  }

  &:active {
    background-color: #343a3f;
  }
  &:hover {
    background-color: ${(props) => props.theme.colors.primary.mediumBlue};
  }
`;

export const MobileNav = ({ history, loggedIn, close, logout, user, channel }) => {
  const navigate = (route) => {
    if (route === "" && loggedIn) {
      history.push("/");
    } else if (route === "") {
      history.push("/home");
    } else {
      history.push(route);
    }
    close();
  };

  return (
    <MobileNavContainer>
      {loggedIn ? (
        <>
          <NavLink 
            data-id={`nav-home-btn`}
            onClick={() => navigate("/")}
          >
            <Home24 />
            Home
          </NavLink>
          {!user.isSuper && (
            <NavLink 
              data-id={`nav-referral-dashboard-btn`}
              onClick={() => navigate("/referral-dashboard")}
            >
              <Archive24 />
              {user.is211Admin ? "Dashboard" : "Referral Dashboard"}
            </NavLink>
          )}
          {!user.isViewOnly && (
            <NavLink 
              data-id={`nav-service-directory-btn`}
              onClick={() => navigate("/service-directory")}
            >
              <Map24 />
              Service Directory
            </NavLink>
          )}
          {!user.is211Admin && !user.isViewOnly && (
            <NavLink 
              data-id={`nav-impact-btn`}
              onClick={() => navigate("/impact")}
            >
              <Activity24 />
              My Impact
            </NavLink>
          )}
          {!user.isViewOnly && (
            <NavLink 
              data-id={`nav-profile-btn`}
              onClick={() => navigate("/account")}
            >
              <User24 />
              Account
            </NavLink>
          )}
          <NavLink 
            data-id={`nav-logout-btn`}
            onClick={logout}
          >
            <Logout24 />
            {!!user.superAssumedRole ? 'Logout - Assumed Role' : 'Logout'}
          </NavLink>
        </>
      ) : null}
    </MobileNavContainer>
  );
};
