import {
  Checkbox,
  Modal,
  Search,
  TextInput,
  Tile
} from "carbon-components-react";
import kit from "ich-ui-kit";
import React, { useContext, useState } from "react";
import styled from "styled-components";
import { ActivityFeedContext } from "../../../../store/ActivityFeedContext";
import { updateDate } from "../../helpers/filter-helpers";
import { shouldAutoComplete } from "../../../../utils";

const CloseReasonModal = ({
  referrals,
  modalOpen,
  setModalOpen,
  tempStatus,
  setTempStatus,
  referralClosed,
  setDisplayedItems,
  setLoadingInline,
  dateStart,
  dateEnd,
  selectServices,
  assignStatuses,
  referStatuses,
  filterBookmarked,
  parentRefers
}) => {
  const { user } = useContext(kit.UserContext);
  const { apiService } = useContext(kit.ApiContext);
  const {
    setReferralActivityInactive,
    setReferralActivitiesInactive
  } = useContext(ActivityFeedContext);
  const [cancelClose, setCancelClose] = useState(false);
  const [methods, setMethods] = useState([]);
  const [reasons, setReasons] = useState([]);
  const [noteText, setNoteText] = useState("");
  const updateStatus = async (status) => {
    setLoadingInline(referralClosed.id);
    if (noteText) {
      await addNote();
    }
    try {
      //   setLoadingService(true);
      const data = await apiService.put(`/referrals/status?assumedId=${user.superAssumedRole?.leadId || ""}`, {
        patientId: referralClosed?.referreeId,
        serviceId: referralClosed?.serviceId,
        originatorId: referralClosed?.requesterId,
        assignedTo: referralClosed?.assignedTo,
        status,
        referralId: referralClosed?.id
      });
      if (data && data.history) {
        const Mdata = [...referrals];
        Mdata.map((item) => {
          if (item.id === referralClosed?.id) {
            item.status = status;
          }
        });
        updateDate(
          dateStart,
          dateEnd,
          setDisplayedItems,
          Mdata,
          selectServices,
          assignStatuses,
          referStatuses,
          filterBookmarked,
          parentRefers
        );
        await setReferralActivitiesInactive(
          referralClosed.referreeId,
          referralClosed.id,
        );
      }
      //   setLoadingService(false);
    } catch (err) {
      console.log(err);
    }
    setLoadingInline("");
    setNoteText("");
  };

  const addNote = async () => {
    if (noteText === "") {
      return;
    }
    try {
      const data = await apiService.post(`/referrals/note?assumedId=${user.superAssumedRole?.leadId || ""}`, {
        patientId: referralClosed.referreeId,
        text: tempStatus == "Rejected By Receiver" ? "Referral Rejected. Reason: " + noteText : noteText,
        referralId: referralClosed.id,
        serviceId: referralClosed.serviceId,
        originatorId: referralClosed.requesterId,
        locationId: referralClosed.locationId,
        orgSource: referralClosed?.orgSource
      });
      if (data.ok) {
        setNoteText("");
        let dateTime = new Date();
        dateTime = dateTime.toLocaleString();
      }
    } catch (err) {
      console.log(err);
    }
  };

  const updateMethod = (value) => {
    let tempMethod = [...methods];
    if (tempMethod.includes(value)) {
      tempMethod = tempMethod.filter((a) => a !== value);
      setMethods([...tempMethod]);
    } else {
      setMethods([...tempMethod, value]);
    }
  };

  const updateReason = (value) => {
    let tempReason = [...reasons];
    if (tempReason.includes(value)) {
      tempReason = tempReason.filter((a) => a !== value);
      setReasons([...tempReason]);
    } else {
      setReasons([...tempReason, value]);
    }
  };

  const disablePrimary = () => {
    if (tempStatus === "Pending Closure - Unable to Contact") {
      if (noteText && methods.length > 0) {
        return false;
      } else {
        return true;
      }
    } else if (
      tempStatus === "Pending Closure - Resolved" ||
      tempStatus === "Pending Closure - Cancel"
    ) {
      if (noteText) {
        return false;
      } else {
        return true;
      }
    } else if (tempStatus === "Pending Closure - Unable to Resolve") {
      if (noteText && reasons.length > 0) {
        return false;
      } else {
        return true;
      }
    } else if (tempStatus === "Rejected By Receiver") {
      if (noteText) {
        return false;
      } else {
        return true;
      }
    }
  };

  const renderModalContent = () => {
    if (tempStatus === "Pending Closure - Unable to Contact") {
      return (
        <>
          <TitleWrapper>
            <Title>
              Close Referral {referralClosed.id} — Unable to Contact
            </Title>
            <Subtitle>What contact methods were used?</Subtitle>
          </TitleWrapper>
          <legend className="bx--label">Select one or more methods</legend>
          <Checkbox
            onClick={(e) => updateMethod("Email")}
            labelText="Email"
            id="checked"
          />
          <Checkbox
            onClick={(e) => updateMethod("Phone Call")}
            labelText="Phone Call"
            id="checked-2"
          />
          <Checkbox
            onClick={(e) => updateMethod("Text Message")}
            labelText="Text Message"
            id="checked-3"
          />
          <Checkbox
            onClick={(e) => updateMethod("Referral Note")}
            labelText="Referral Note"
            id="checked-4"
          />
          <br />
          <Subtitle>
            The Closed - Unable to Contact status should be used when the client
            has not responded after at least three attempts to contact (please
            indicate methods used to attempt contact).
          </Subtitle>
          <BorderedContainer>
            <NotesInputContainer style={{ backgroundColor: "white" }}>
              <TextInput
                id="note-input"
                labelText="Add Note"
                hideLabel
                placeholder="Add an explanation..."
                value={noteText}
                onChange={(e) => setNoteText(e.target.value)}
                style={{ borderBottom: "none" }}
                // disabled={displayedNotes.length >= 1}
                light
                autoComplete={shouldAutoComplete}
              />
            </NotesInputContainer>
          </BorderedContainer>
        </>
      );
    } else if (tempStatus === "Pending Closure - Unable to Resolve") {
      return (
        <>
          <TitleWrapper>
            <Title>Close Referral {referralClosed.id} — Unresolved</Title>
            <Subtitle>Why was this referral not resolved?</Subtitle>
          </TitleWrapper>
          <legend className="bx--label">Select one or more reasons</legend>
          <Checkbox
            onClick={(e) => updateReason("Client Ineligible for Service")}
            labelText="Client Ineligible for Service"
            id="1"
          />
          <Checkbox
            onClick={(e) => updateReason("Client Declined Service")}
            labelText="Client Declined Service"
            id="2"
          />
          <Checkbox
            onClick={(e) =>
              updateReason("Referred Client to Different Service")
            }
            labelText="Referred Client to Different Service"
            id="3"
          />
          <br />
          <Subtitle>
            The Closed - Unresolved status should be used when the service was
            not provided (please indicate the reason(s) the service was not
            provided).
          </Subtitle>
          <BorderedContainer>
            <NotesInputContainer>
              <TextInput
                id="note-input"
                labelText="Add Note"
                hideLabel
                placeholder="Add an explanation..."
                value={noteText}
                onChange={(e) => setNoteText(e.target.value)}
                style={{ borderBottom: "none" }}
                light
                autoComplete={shouldAutoComplete}
              />
            </NotesInputContainer>
          </BorderedContainer>
        </>
      );
    } else if (
      tempStatus === "Pending Closure - Resolved" ||
      tempStatus === "Pending Closure - Cancel"
    ) {
      return (
        <>
          <TitleWrapper>
            <Title>
              Close Referral {referralClosed.id} — {tempStatus.split(" - ")[1]}
            </Title>
            <Subtitle>Provide a reason for closing this referral</Subtitle>
          </TitleWrapper>
          {tempStatus === "Pending Closure - Resolved" ? (
            <Subtitle>
              The Closed - Resolved status should be used when the service has
              been successfully provided.
            </Subtitle>
          ) : (
            <Subtitle>
              The Closed - Canceled status should be used when the client no
              longer needs or wants the service.
            </Subtitle>
          )}
          <BorderedContainer>
            <NotesInputContainer>
              <TextInput
                id="note-input"
                labelText="Add Note"
                hideLabel
                placeholder="Add reason..."
                value={noteText}
                onChange={(e) => setNoteText(e.target.value)}
                style={{ borderBottom: "none" }}
                light
                autoComplete={shouldAutoComplete}
              />
            </NotesInputContainer>
          </BorderedContainer>
        </>
      );
    } else if (tempStatus === "Rejected By Receiver") {
      return (
        <>
          <TitleWrapper>
            <Title>Reject Referral</Title>
            <Subtitle>
              Please indicate the reason(s) for rejecting this referral.
            </Subtitle>
          </TitleWrapper>
          <BorderedContainer>
            <NotesInputContainer>
              <TextInput
                id="note-input"
                labelText="Add Note"
                hideLabel
                placeholder="Add reason..."
                value={noteText}
                onChange={(e) => setNoteText(e.target.value)}
                style={{ borderBottom: "none" }}
                light
                autoComplete={shouldAutoComplete}
              />
            </NotesInputContainer>
          </BorderedContainer>
        </>
      );
    }
  };
  return (
    <StyledModal
      open={modalOpen}
      primaryButtonDisabled={disablePrimary()}
      primaryButtonText={"Submit"}
      secondaryButtonText={"Cancel"}
      onSecondarySubmit={() => {
        setCancelClose(!cancelClose);
        setReasons([]);
        setMethods([]);
        // setDisplayedStatus(referral.status)
        setModalOpen(false);
        setTempStatus("");
        setNoteText("");
        document.querySelectorAll(".bx--checkbox").forEach((e) => {
          if (e.checked === true) {
            e.click();
          }
        });
      }}
      onRequestClose={() => {
        setCancelClose(!cancelClose);
        setReasons([]);
        setMethods([]);
        // setDisplayedStatus(referral.status)
        setModalOpen(false);
        setTempStatus("");
        setNoteText("");
        document.querySelectorAll(".bx--checkbox").forEach((e) => {
          if (e.checked === true) {
            e.click();
          }
        });
      }}
      onRequestSubmit={() => {
        // update the status first
        if (
          tempStatus === "Pending Closure - Resolved" ||
          tempStatus === "Pending Closure - Cancel"
        ) {
          updateStatus(tempStatus);
        } else if (tempStatus === "Pending Closure - Unable to Contact") {
          let newStatus = methods ? ": " + methods.join(", ") : "";
          newStatus = tempStatus + newStatus;
          updateStatus(newStatus);
        } else if (tempStatus === "Pending Closure - Unable to Resolve") {
          let newStatus = reasons ? ": " + reasons.join(", ") : "";
          newStatus = tempStatus + newStatus;
          updateStatus(newStatus);
        } else if (tempStatus === "Rejected By Receiver") {
          updateStatus(tempStatus);
        }
        setReasons([]);
        setMethods([]);
        setModalOpen(false);
        document.querySelectorAll(".bx--checkbox").forEach((e) => {
          if (e.checked === true) {
            e.click();
          }
        });
      }}
    >
      {renderModalContent()}
    </StyledModal>
  );
};

const StyledModal = styled(Modal)`
  margin-right: 0;
  .bx--btn--primary {
    background-color: #0f62fe;
    :hover {
      background-color: #0f62fe90 !important;
    }
  }
  .bx--btn--secondary {
    background-color: #393939 !important;
    :hover {
      background-color: #39393990 !important;
    }
  }
  .bx--modal-content {
    @media screen and (max-width: 376px) {
      height: auto;
    }
  }
  .bx--modal-container {
    @media screen and (width: 375px) {
      height: 90%;
    }
    @media screen and (max-width: 416px) {
      height: 80%;
      width: 95%;
      top: 10%;
    }
  }
`;

export const StyledSearch = styled(Search)`
  margin-bottom: 1rem;
`;

const ModalWrapper = styled.div`
  margin-right: 0;
  height: 500px;
  width: 100%;
`;

const TitleWrapper = styled.div`
  justify-content: left;
  margin-bottom: 2rem;
`;

const Title = styled.h4``;

const Subtitle = styled.p`
  padding-bottom: 0.5rem;
`;

const BorderedContainer = styled.div`
  width: 100%;
  border: 1px solid #8d8d8d;
  margin-bottom: 1rem;
`;

const NotesInputContainer = styled.div`
  border-top: 1px solid #8d8d8d;
  display: flex;
  align-items: center;
  height: 3rem;
  background-color: #ffffff;
`;

const StyledTile = styled(Tile)`
  padding: 0.5rem 1rem;
  min-height: auto;
  background-color: #fff;
`;

const Timestamp = styled.p`
  color: gray;
  font-size: 0.75rem;
  margin-left: 1rem;
`;

const NotesSubheader = styled.p`
  font-size: 0.75rem;
  color: gray;
  margin-bottom: 0.5rem;
`;

const NoteText = styled.p`
  margin: 0.5rem 0;
`;

const FlexBaseline = styled.div`
  display: flex;
  align-items: baseline;
`;

const NotesContainer = styled.div`
  display: flex;
  flex-direction: column-reverse;
  max-height: 15rem;
  min-height: 15rem;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
`;

export default CloseReasonModal;
