import React, { useState, useContext, useEffect } from "react";
import { Modal } from "carbon-components-react";
import styled from "styled-components";
import kit from "ich-ui-kit";
import { createPortal } from "react-dom";
import { InlineLoading } from "carbon-components-react";
import { useLocation } from "react-router-dom";
import AllClients from "../../pages/new-landing-page/components/AllClients";
import { CallLogContext } from "../../store/CallLogContext";
const mount = document.getElementById("modal-root");

const cnRequestUrl = process.env.REACT_APP_CN360_URL || 'https://www.360collaborative.org/request-assistance';

export const AssistanceRequestModal = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);
  const { user } = useContext(kit.UserContext);
  const { apiService } = useContext(kit.ApiContext);
  const { client: clientFromCallLog } = useContext(CallLogContext);
  const [shouldShowClientSearch, setShouldShowClientSearch] = useState(true);
  let location = useLocation();
  const [client, setClient] = useState();
  const [clientFromProvider, setClientFromProvider] = useState();
  const [clients, setClients] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    try {
      const clientId = location?.state?.client || location?.state?.clientID || location?.state?.patientId;
      setClientFromProvider(clientId);
    } catch (err) {
      console.log(err);
    }
  });

  useEffect(() => {
    if (props.open) {
      fetchClients();
    }
  }, [props?.open])

  useEffect(() => {
    if ((clientFromProvider === "" || clientFromProvider === null || clientFromProvider === undefined)
      && (client === null || client === undefined)
      && (clientFromCallLog === null || clientFromCallLog === undefined)) {
      setShouldShowClientSearch(true);
    } else {
      setShouldShowClientSearch(false);
    }
  }, [clientFromProvider, client])

  const handleOK = async () => {
    setIsLoading(true);
    let patientId = "";
    let clientName = props.patient?.firstName + ' ' + props.patient?.lastName || props.patient?.aliasName

    if (clientFromProvider !== "" && clientFromProvider !== null && clientFromProvider !== undefined) {
      patientId = clientFromProvider;
    }
    if (client !== null && client !== undefined && client.id) {
      patientId = client.id;
      clientName = client.givenName + ' ' + client.familyName
    }

    if (clientFromCallLog !== null && clientFromCallLog !== undefined && clientFromCallLog?.id) {
      patientId = clientFromCallLog?.id;
    }

    try {
      const data = await apiService.post("/referrals/cnrequest", {
        providerName: props.providerName,
        serviceName: props.serviceName,
        name: clientName || "",
        patientId: patientId,
        orgKey: props.orgKey,
        serviceId: props.serviceId,
        orgSource: props.orgSource,
        locationId: props.locationId,
        initiatorId: user.id || ""
      });

      if (data.ok) {
        window.open(
          cnRequestUrl,
          "_blank"
        );
        setIsLoading(false);
      } else {
        setError(true); //if the API call fails
        setIsLoading(false);
      }
    } catch (err) {
      console.log(err);
      setError(true); //if error occurs during API call
      setIsLoading(false);
    }

    props.onClose && props.onClose();
  };

  const getPrimaryBtnText = () => {
    if (shouldShowClientSearch) {
      return "Continue";
    } else {
      if (isLoading) {
        return <InlineLoading description="Sending..." />
      } else {
        return "Create"
      }
    }
  }

  const getOkBtnHandle = () => {
    if (shouldShowClientSearch) {
      return handleClientSelect;
    } else {
      return handleOK;
    }
  }

  const handleClientSelect = () => {
    return
  }

  const closeBtnTapped = () => {
    setClient(null);
    setClientFromProvider(null);
    if (props.onClose) {
      props.onClose();
    }
  }

  const isPrimariBtnDisabled = () => {
    if (shouldShowClientSearch) {
      return true;
    } else {
      if (isLoading) {
        return true;
      } else {
        return false;
      }
    }
  }

  const fetchClients = async () => {
    setLoading(true);
    let patientsData;
    if (user.superAssumedRole?.leadId) {
      patientsData = await apiService.get(
        `/clients?assumedId=${user.superAssumedRole.leadId}`
      );
      if (patientsData) {
        setClients(patientsData || []);
      }
    } else if (!user.is211Admin && !user.isSuper) {
      patientsData = await apiService.get(
        `/clients?assumedId=${user.superAssumedRole?.leadId || ""}`
      );
      if (patientsData) {
        setClients(patientsData || []);
      }
    } else {
      patientsData = await apiService.get(
        `/account/users?assumedId=${
          user.superAssumedRole?.leadId || ""
        }`
      );
      if (patientsData && patientsData.clients) {
        setClients(patientsData.clients.filter((c) => !c.firstLogin));
      }
    }
    setLoading(false);
  };

  const getModalContent = () => {
    if (shouldShowClientSearch) {
      return <AllClients
        clientSelect
        loading={loading}
        clients={clients}
        setClient={setClient}
      />
    } else {
      return (
        <ModalWrapper>
          <p>
            When you click on this link, you will leave our website and go to a different website that we cannot control. We
            also cannot be sure that everything on that website is accurate. We want you to be careful and check the rules and
            privacy information on that website to keep yourself safe. The website will have a privacy policy that will control
            how that website can use or disclose your information, and you should look at that policy.
          </p>
          {error && (
            <ErrorMessage>An error occurred while processing your request.</ErrorMessage>
          )}
        </ModalWrapper>
      )
    }
  }

  return createPortal(
    <Modal
      data-id={`cn-request-modal`}
      key="cn-assessment-modal"
      aria-label="CN Request Modal"
      size="sm"
      open={props.open}
      modalLabel="Assistance Request"
      modalHeading={ shouldShowClientSearch ? "Client Selection" : "Acknowledgment"}
      primaryButtonText={getPrimaryBtnText()}
      primaryButtonDisabled={isPrimariBtnDisabled()}
      secondaryButtonText="Cancel"
      onRequestSubmit={getOkBtnHandle()}
      onRequestClose={closeBtnTapped}
      hasForm
      hasScrollingContent={false}
      shouldSubmitOnEnter={true}
    >
      {getModalContent()}
    </Modal>
    , mount)
};

const ModalWrapper = styled.div`
  padding: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: 100%;
  width: 100%;
`;

const ErrorMessage = styled.div`
  color: red;
  margin-top: 1rem;
`;