import { useState } from "react";
import styled from "styled-components";
import { Select, SelectItem, Button } from "carbon-components-react";

import { useDropdownItems } from "../../../../../components/360ProfileForm/dropdown/dropdownCMS";
import { ethnicityItems } from "../../../../../components/360ProfileForm/dropdown/ethnicity.items";
import { hispanicItems } from "../../../../../components/360ProfileForm/dropdown/hispanic.items";
import { BinaryWrapper, BinaryButtonWrapper, StyledDropdownWrapper } from "../AboutMe.styled";

const Ethnicity = ({ setAboutMeResponse, setDisableButton }) => {
  const { list: listEthnicity } = useDropdownItems({
    init: ethnicityItems,
    link: "list-ethnicity",
  });
  const ethnicity = listEthnicity().reduce((eth, newEth) => {
    eth[newEth.key] = newEth;
    return eth;
  }, {});
  const { list: listHispanic } = useDropdownItems({
    init: hispanicItems,
    link: "list-hispanic",
  });
  const hispanic = listHispanic().reduce((his, newHis) => {
    his[newHis.key] = newHis;
    return his;
  }, {});

  const [ofHispanicOrigin, setOfHispanicOrigin] = useState(null);
  const handleChange = (e) => {
    if (e.target.value !== "default") {
      setDisableButton(false);
      setAboutMeResponse((state) => ({
        ...state,
        ethnicity: "Hispanic Origin",
        hispanicOrigin: e.target.value,
      }));
    } else {
      setDisableButton(true);
    }
  };
  return (
    <BinaryWrapper>
      <BinaryButtonWrapper>
        <StyledButton
          theme={{ bcolor: ofHispanicOrigin === "yes" }}
          kind="tertiary"
          onMouseDown={() => {
            setOfHispanicOrigin("yes");
            setDisableButton(true);
          }}
        >
          {ethnicity.hispanic.show}
        </StyledButton>
        <StyledButton
          theme={{ bcolor: ofHispanicOrigin === "no" }}
          kind="tertiary"
          onMouseDown={() => {
            setOfHispanicOrigin("no");
            setDisableButton(false);
            setAboutMeResponse((state) => ({
              ...state,
              ethnicity: "Not of Hispanic Origin",
              hispanicOrigin: "",
            }));
          }}
        >
          {ethnicity.not_hispanic.show}
        </StyledButton>
      </BinaryButtonWrapper>
      {ofHispanicOrigin === "yes" && (
        <StyledDropdownWrapper style={{marginTop: '1rem'}}>
          <Select
            id="select-1"
            titleText=" "
            defaultValue="default"
            onChange={(e) => handleChange(e)}
          >
            <SelectItem
              text={hispanic.default_hispanic.show}
              value="default"
              id="default"
            />
            <SelectItem
              text={hispanic.mex_american.show}
              value="Mexican, Mexican American, Chicano"
              id="mmc"
            />
            <SelectItem
              text={hispanic.puerto_rican.show}
              value="Puerto Rican"
              id="pr"
            />
            <SelectItem text={hispanic.cuban.show} value="Cuban" id="c" />
            <SelectItem
              text={hispanic.not_listed.show.split("(")[0]}
              value="Other"
              id="other"
            />
          </Select>
        </StyledDropdownWrapper>
      )}
      
    </BinaryWrapper>
  );
};

export default Ethnicity;

const StyledButton = styled(Button)`
  @media screen and (min-width: 768px) {
    color: ${(props) => (props.theme.bcolor ? "white" : "rgba(0,145,179,1)")};
    background-color: ${(props) => (props.theme.bcolor ? "#0f62fe" : "white")};
    &:not(:last-child) {
      margin-right: 0.75rem;
    }
    :hover {
      color: ${(props) => (props.theme.bcolor ? "rgba(0,145,179,1)" : "white")};
      background-color: ${(props) =>
        props.theme.bcolor ? "white" : "rgba(0,145,179,1)"};
    }
    :focus {
      color: ${(props) => (props.theme.bcolor ? "white" : "rgba(0,145,179,1)")};
      background-color: ${(props) => (props.theme.bcolor ? "#0f62fe" : "white")};
      :active {
        color: "white" !important;
        background-color: "#0f62fe";
      }
    }
  }

  @media screen and (max-width: 768px) {
    width: 20rem;
    height: 4px;
    margin-bottom: 1rem;
    &:not(:last-child) {
      margin-right: 0;
    }
  }
`;
