import React from "react";
import * as S from "./contact-us.styled";

import { Item } from "./item";

import { useContactCMS } from "./cms";

const ContactUs = () => {
  const { cms } = useContactCMS();
  return (
    <S.Container>
      <S.BannerContainer
      bgImage={
        cms.background_image
        ? `${process.env.REACT_APP_STRAPI_ENDPOINT}${
            window.innerWidth < 400
              ? cms.background_image?.small?.url
              : window.innerWidth < 600
              ? cms.background_image?.medium?.url
              : cms.background_image?.large?.url
          }`
        : ""
      }
      >
        <S.Heading>{cms.heading}</S.Heading>
        <S.Overlay />
      </S.BannerContainer>

      <S.Items>
        {cms?.items &&
          cms.items.map((item) => (
            <Item
              aria-label={item.title}
              key={item.id}
              heading={item.title}
              paragraphs={item.content}
            />
          ))}
      </S.Items>
    </S.Container>
  );
};

export default ContactUs;
