import styled from "styled-components";
import {
  InlineNotification,
  Link,
  Slider,
  TextInput,
} from "carbon-components-react";
import { DotMark20 } from "@carbon/icons-react";

export const AssessmentsPageContainer = styled.div`
  background-color: #d8d8d8;
  color: black;
  width: 100%;
  height: 100%;
  overflow-x: none;
  overflow-y: scroll;
  z-index: 0;
  align-items: center;
  @media print {
    display: none;
  }
`;

export const AssessmentContainer = styled.div`
  background-color: white;
  margin: 1rem 1rem 2rem 1rem;
  display: grid;
  grid-template-rows: 7rem 45rem;
  grid-template-columns: 35% 65%;
  grid-template-areas:
    "header header"
    "left-column right-column";
  @media (max-width: 900px) {
    display: grid;
    grid-template-rows: auto auto auto auto;
    grid-template-columns: 100%;
    grid-template-areas:
      "header"
      "left-column"
      "right-column";
    margin: 0.2rem 0.2rem 0.2rem 0.2rem;
  }
`;

export const AssessmentsHeader = styled.div`
  grid-area: header;
  display: flex;
  justify-content: space-between;
`;

export const BlueBackLink = styled.button`
  color: #0f62fe;
  font-size: 1rem;
  display: inline-block;
  padding-left: 0;
  cursor: pointer;
  background-color: white;
  border: none;
`;
export const AssessmentDateDiv = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
  margin-left: 1rem;
  & p {
    margin-top: 0.5rem;
  }
`;

export const IconDiv = styled.div`
  display: flex;
  margin-right: 2rem;
`;

export const StyledLink = styled(Link)`
  color: black;
  margin-top: 0.5rem;
`;

export const StyledButton = styled.button`
  border: none;
  background-color: white;
  display: flex;
  flex-direction: column;
  cursor: pointer;
`;

export const LeftColumn = styled.div`
  grid-area: left-column;
  display: flex;
  flex-direction: column;
  height: 98%;
`;

export const SummaryDiv = styled.div`
  background-color: #f6f6f6;
  display: flex;
  flex-direction: column;
  margin-left: 1rem;
  margin-top: 0.5rem;
  width: 95%;
  padding-bottom: 3rem;
  padding-top: 1rem;
  box-shadow: 1px 1px 1px 0 #979797;
  & h5 {
    margin-left: 1rem;
  }
  @media (max-width: 416px) {
    margin-left: 0.6em;
  }
`;

export const BulletItem = styled.div`
  display: flex;
  line-height: 1.3rem;
  margin-left: 2rem;
  margin-bottom: 0.3rem;
`;

export const RecommendedResourceDiv = styled.div`
  background-color: #f6f6f6;
  display: flex;
  flex-direction: column;
  margin-left: 1rem;
  margin-top: 1.5rem;
  width: 95%;
  padding-top: 1rem;
  height: 24.2rem;
  overflow: auto;
  box-shadow: 1px 1px 1px 0 #979797;
  & h5 {
    margin-left: 1rem;
    margin-bottom: 1rem;
  }
  & p {
    margin-left: 1rem;
  }
  @media (max-width: 416px) {
    margin-left: 0.6em;
    height: 18rem;
    margin-top: 1rem;
  }
`;

export const RightColumn = styled.div`
  grid-area: right-column;
  display: flex;
  flex-direction: column;
  align-items: start;
  height: 98%;
`;

export const AssessmentsQandADiv = styled.div`
  width: 95%;
  height: 41.5rem;
  background-color: #f6f6f6;
  margin-left: 1rem;
  margin-top: 0.5rem;
  padding-top: 1rem;
  box-shadow: 1px 1px 1px 0 #979797;
  & h5 {
    margin-left: 1rem;
    margin-bottom: 1rem;
  }
  & p {
    margin-left: 1rem;
  }
  @media (max-width: 416px) {
    margin-left: 1em;
    margin-bottom: 1rem;
    overflow: auto;
    align-items: center;
  }
  @media (max-width: 550px) {
    margin-left: 0.75em;
    margin-bottom: 1rem;
  }
`;

export const SmileyFaceDiv = styled.div`
  margin: 2rem 0rem 2rem 2.3rem;
  display: flex;
  justify-content: center;
  width: 85%;
  @media (max-width: 416px) {
    margin-left: 1.5rem;
  }
  @media (min-width: 416px) and (max-midth: 550px) {
    width: 100%;
    height: 100%;
    overflow: auto;
    align-items: center;
  }
`;

export const SliderContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 60%;
  overflow: auto;
  @media (max-width: 416px) {
    margin-left: -0.5rem;
  }
  @media (min-width: 416px) and (max-midth: 550px) {
    display: flex;
    flex-direction: column;
    overflow: auto;
    align-items: center;
  }
`;

export const AssessmentHeaderContent = styled.div``;

export const BackLinkContainer = styled.div`
  margin-top: 0.5rem;
  margin-left: 1rem;
`;

export const IconDivContent = styled.div`
  margin-top: 3rem;
  margin-right: 1rem;
`;

export const BulletItemDiv = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 1.5rem;
`;

export const StyledDotMark = styled(DotMark20)`
  margin-right: 0.5rem;
`;

export const RedSmileyImg = styled.img`
  height: 3rem;
  margin-right: 2rem;
  @media (max-width: 416px) {
    height: 2rem;
    margin-left: 1rem;
    margin-right: 1rem;
  }
  @media (max-width: 350px) {
    height: 1.7rem;
    margin-left: 1rem;
    margin-right: 1rem;
  }
  @media (min-width: 416px) and (max-width: 550px) {
    height: 2rem;
    margin-left: 2rem;
    margin-right: 2.5rem;
  }
  @media (max-width: 350px) {
    height: 1.5rem;
    margin-left: 1rem;
    margin-right: 1rem;
  }
  @media (max-width: 1040px) and (min-width: 1000px) {
    height: 2.5rem;
    margin-left: 2.5rem;
    margin-right: 2.5rem;
  }
  @media (max-width: 300px) {
    height: 1.3rem;
    margin-left: 1rem;
    margin-right: 1rem;
  }
  @media (max-width: 800px) and (min-width: 699px) {
    height: 2.5rem;
    margin-left: 2rem;
    margin-right: 2rem;
  }
  @media (max-width: 1000px) and (min-width: 900px) {
    height: 2.5rem;
    margin-left: 2rem;
    margin-right: 2rem;
  }
  @media (max-width: 1500px) and (min-width: 1040px) {
    height: 2rem;
    margin-left: 2rem;
    margin-right: 2rem;
  }
  @media (max-width: 699px) and (min-width: 550px) {
    height: 2rem;
    margin-left: 2rem;
    margin-right: 2rem;
  }
  @media (max-width: 470px) and (min-width: 417px) {
    height: 2rem;
    margin-left: 1.5rem;
    margin-right: 1.5rem;
  }
`;
export const OrangeSmileyImg = styled.img`
  height: 3rem;
  margin-left: 4rem;
  margin-right: 2rem;
  @media (max-width: 416px) {
    height: 2rem;
    margin-left: 1rem;
    margin-right: 1rem;
  }
  @media (max-width: 350px) {
    height: 1.7rem;
    margin-left: 1rem;
    margin-right: 1rem;
  }
  @media (min-width: 416px) and (max-width: 550px) {
    height: 2rem;
    margin-left: 2rem;
    margin-right: 2rem;
  }
  @media (max-width: 350px) {
    height: 1.5rem;
    margin-left: 1rem;
    margin-right: 1rem;
  }
  @media (max-width: 1040px) and (min-width: 1000px) {
    height: 2.5rem;
    margin-left: 2.5rem;
    margin-right: 2.5rem;
  }
  @media (max-width: 300px) {
    height: 1.3rem;
    margin-left: 1rem;
    margin-right: 1rem;
  }
  @media (max-width: 800px) and (min-width: 699px) {
    height: 2.5rem;
    margin-left: 2rem;
    margin-right: 2rem;
  }
  @media (max-width: 1000px) and (min-width: 900px) {
    height: 2.5rem;
    margin-left: 2rem;
    margin-right: 2rem;
  }
  @media (max-width: 1500px) and (min-width: 1040px) {
    height: 2rem;
    margin-left: 2rem;
    margin-right: 2rem;
  }
  @media (max-width: 699px) and (min-width: 550px) {
    height: 2rem;
    margin-left: 2rem;
    margin-right: 2rem;
  }
  @media (max-width: 470px) and (min-width: 417px) {
    height: 2rem;
    margin-left: 1.5rem;
    margin-right: 1.5rem;
  }
`;
export const YellowSmileyImg = styled.img`
  height: 3rem;
  margin-left: 3.7rem;
  margin-right: 2rem;
  @media (max-width: 416px) {
    height: 2rem;
    margin-left: 1rem;
    margin-right: 1rem;
  }
  @media (max-width: 350px) {
    height: 1.7rem;
    margin-left: 1rem;
    margin-right: 1rem;
  }
  @media (min-width: 416px) and (max-width: 550px) {
    height: 2rem;
    margin-left: 2rem;
    margin-right: 2rem;
  }
  @media (max-width: 350px) {
    height: 1.5rem;
    margin-left: 1rem;
    margin-right: 1rem;
  }
  @media (max-width: 1040px) and (min-width: 1000px) {
    height: 2.5rem;
    margin-left: 2.5rem;
    margin-right: 2.5rem;
  }
  @media (max-width: 300px) {
    height: 1.3rem;
    margin-left: 1rem;
    margin-right: 1rem;
  }
  @media (max-width: 800px) and (min-width: 699px) {
    height: 2.5rem;
    margin-left: 2rem;
    margin-right: 2rem;
  }
  @media (max-width: 1000px) and (min-width: 900px) {
    height: 2.5rem;
    margin-left: 2rem;
    margin-right: 2rem;
  }
  @media (max-width: 1500px) and (min-width: 1040px) {
    height: 2rem;
    margin-left: 2rem;
    margin-right: 2rem;
  }
  @media (max-width: 699px) and (min-width: 550px) {
    height: 2rem;
    margin-left: 2rem;
    margin-right: 2rem;
  }
  @media (max-width: 470px) and (min-width: 417px) {
    height: 2rem;
    margin-left: 1.5rem;
    margin-right: 1.5rem;
  }
`;
export const LightGreenSmileyImg = styled.img`
  height: 3rem;
  margin-left: 4rem;
  margin-right: 2rem;
  @media (max-width: 416px) {
    height: 2rem;
    margin-left: 1rem;
    margin-right: 1rem;
  }
  @media (max-width: 350px) {
    height: 1.7rem;
    margin-left: 1rem;
    margin-right: 1rem;
  }
  @media (min-width: 416px) and (max-width: 550px) {
    height: 2rem;
    margin-left: 2rem;
    margin-right: 2rem;
  }
  @media (max-width: 350px) {
    height: 1.5rem;
    margin-left: 1rem;
    margin-right: 1rem;
  }
  @media (max-width: 1040px) and (min-width: 1000px) {
    height: 2.5rem;
    margin-left: 2.5rem;
    margin-right: 2.5rem;
  }
  @media (max-width: 300px) {
    height: 1.3rem;
    margin-left: 1rem;
    margin-right: 1rem;
  }
  @media (max-width: 800px) and (min-width: 699px) {
    height: 2.5rem;
    margin-left: 2rem;
    margin-right: 2rem;
  }
  @media (max-width: 1000px) and (min-width: 900px) {
    height: 2.5rem;
    margin-left: 2rem;
    margin-right: 2rem;
  }
  @media (max-width: 1500px) and (min-width: 1040px) {
    height: 2rem;
    margin-left: 2rem;
    margin-right: 2rem;
  }
  @media (max-width: 699px) and (min-width: 550px) {
    height: 2rem;
    margin-left: 2rem;
    margin-right: 2rem;
  }
  @media (max-width: 470px) and (min-width: 417px) {
    height: 2rem;
    margin-left: 1.5rem;
    margin-right: 1.5rem;
  }
`;
export const GreenSmileyImg = styled.img`
  height: 3rem;
  margin-left: 4rem;
  margin-right: 2rem;
  @media (max-width: 416px) {
    height: 2rem;
    margin-left: 1rem;
    margin-right: 1rem;
  }
  @media (max-width: 350px) {
    height: 1.7rem;
    margin-left: 1rem;
    margin-right: 1rem;
  }
  @media (min-width: 416px) and (max-width: 550px) {
    height: 2rem;
    margin-left: 2rem;
    margin-right: 2rem;
  }
  @media (max-width: 350px) {
    height: 1.5rem;
    margin-left: 1rem;
    margin-right: 1rem;
  }
  @media (max-width: 1040px) and (min-width: 1000px) {
    height: 2.5rem;
    margin-left: 2.5rem;
    margin-right: 2.5rem;
  }
  @media (max-width: 300px) {
    height: 1.3rem;
    margin-left: 1rem;
    margin-right: 1rem;
  }
  @media (max-width: 800px) and (min-width: 699px) {
    height: 2.5rem;
    margin-left: 2rem;
    margin-right: 2rem;
  }
  @media (max-width: 1000px) and (min-width: 900px) {
    height: 2.5rem;
    margin-left: 2rem;
    margin-right: 2rem;
  }
  @media (max-width: 1500px) and (min-width: 1040px) {
    height: 2rem;
    margin-left: 2rem;
    margin-right: 2rem;
  }
  @media (max-width: 699px) and (min-width: 550px) {
    height: 2rem;
    margin-left: 2rem;
    margin-right: 2rem;
  }
  @media (max-width: 470px) and (min-width: 417px) {
    height: 2rem;
    margin-left: 1.5rem;
    margin-right: 1.5rem;
  }
`;

export const InnerSliderContainer = styled.div`
  margin-bottom: 1rem;
  @media (max-width: 416px) {
    margin-bottom: 1rem;
    margin-left: 1.5rem;
  }
  @media (max-width: 350px) {
    margin-left: 1rem;
    margin-right: 1rem;
  }
  @media (min-width: 416px) and (max-width: 550px) {
    margin-bottom: 1rem;
    margin-left: 1.5rem;
  }
`;

export const StyledSlider = styled(Slider)`
  width: 33rem;
  @media (max-width: 416px) {
    width: 7rem;
  }
`;

export const ButtonSetContainer = styled.div`
  display: flex;
  width: 80%;
  justify-content: flex-end;
  margin-top: 1.3rem;
  @media (max-width: 416px) {
    margin-top: 1rem;
    margin-right: 1rem;
    width: 7rem;
  }
  @media (max-width: 600px) {
    justify-content: none;
    align-content: center;
    width: 70%;
  }
  .bx--btn--sm {
    padding: calc(0.375rem - 3px) 4px calc(0.375rem - 3px) 14px;
  }
  .bx--btn-set .bx--btn.bx--btn--disabled {
    width: 75px;
  }
`;

export const StyledTextInput = styled(TextInput)`
  width: 50%;
  margin: auto;
  margin-bottom: 1rem;
`;

export const StyledInputDiv = styled.div`
  display: flex;
  flex-direction: row;
`;

export const StyledInlineNotification = styled(InlineNotification)`
  position: absolute;
  margin-top: -0.5rem;
  color: black;
  margin-left: 24vw;
  z-index: 16;
  @media (max-width: 800px) {
    margin-left: 12vw;
  }
  @media (max-width: 416px) {
    .bx--inline-notification__title {
      font-weight: 450;
    }
  }
`;

export const ziptext = styled(StyledInputDiv)`
  @media (max-width: 416px) {
    font-size: 0.75rem;
    letter-spacing: 0.32px;
    display: inline;
    margin-bottom: 0.5rem;
    color: #525252;
    font-weight: bold;
    line-height: 1rem;
    vertical-align: baseline;
    padding: 1.5rem;
  }
`;
