import { useEffect, useState } from "react";
import { useStrapi } from "../../../hooks/useStrapi";

export const useDDCMS = () => {
  const init = {
    heading: "Well-being Assessment",
    btn_continue: "Contiue",
    btn_complete: "Complete Assessment",
    text_thank_you: "Thank you for completing the assessment",
    text_helps_us: "This assessment helps us better provide you with services.",
    // btn_return: "Return to Dashboard",
    btn_return: "View Well-Being Dashboard",
    employement: {
      id: 1,
      heading: null,
      question_1: "What is your current employment status?",
      question_2:
        "Does your employment provide benefits, such as health insurance or retirement plans?",
      question_3: "Are you currently looking for employment?",
      question_4:
        "How likely do you feel it is that you will be promoted at work?",
      question_5: "Does your employment interfere with school attendance?",
      btn_full_time: "Employed Full-Time",
      btn_part_time: "Employed Part-Time",
      btn_temporary: "Employed Temporarily / Seasonally",
      btn_not_employed: "Not Employed",
      btn_retired: "Retired",
      btn_yes: "Yes",
      btn_no: "No",
      btn_looking: "Looking for employment",
      btn_not_working:
        "Not working at a job or business and not looking for employment",
      btn_currently_working:
        "Currently working at a job or business and not looking for employment",
      not_sure: "Not sure",
      btn_very_likely: "Very Likely",
      btn_somewhat_likeky: "Somewhat Likely",
      btn_somewhat_unlikely: "Somewhat Unlikely",
      btn_very_unlikely: "Very Unlikely",
      btn_not_sure: "Not Sure",
      btn_yes_often: "Yes, often",
      btn_yes_sometimes: "Yes, sometimes",
      btn_not_pursuing: "I am not currently pursuing education"
    },
    transportation: {
      id: 1,
      heading: null,
      question_1: "Do you or your household own a motor vehicle?",
      question_2: "Is your vehicle reliable?",
      question_3:
        "How much do you spend on transportation a month, including vehicle maintenance?",
      question_4: "Does public transportation (e.g. RTS) meet your needs?",
      question_5:
        "Does ride sharing (e.g. Uber, Lyft) or taxi service meet your needs?",
      btn_yes: "Yes",
      btn_no: "No",
      btn_usually: "Usually",
      btn_sometimes: "Sometimes",
      btn_rarely: "Rarely",
      btn_never: "Never",
      btn_do_not: "I do not use public transportation",
      btn_do_not_share: "I do not use ride sharing"
    },
    income: {
      id: 1,
      heading: null,
      question_1:
        "What is your personal monthly income, from all sources (e.g. Work, VA Benefits, Disability)?",
      question_2: "How many individuals do you support with this income?",
      btn_myself: "Myself",
      placeholder_enter_number: "Enter a number"
    }
  };
  const { data, loading } = useStrapi({
    cms: `page-dd-assessment`
  });

  const [cms, setCMS] = useState(init);

  useEffect(() => {
    if (loading) {
      setCMS(init);
      return;
    }
    setCMS((state) => ({
      heading: data.heading || state.heading,
      btn_continue: data.btn_continue || state.btn_continue,
      btn_complete: data.btn_complete || state.btn_complete,
      text_thank_you: data.text_thank_you || state.text_thank_you,
      text_helps_us: data.text_helps_us || state.text_helps_us,
      btn_return: /* data.btn_return ||*/ state.btn_return,
      employement: {
        id: 1,
        heading: data.employement.heading || state.employement.heading,
        question_1: data.employement.question_1 || state.employement.question_1,
        question_2: data.employement.question_2 || state.employement.question_2,
        question_3: data.employement.question_3 || state.employement.question_3,
        question_4: data.employement.question_4 || state.employement.question_4,
        question_5: data.employement.question_5 || state.employement.question_5,
        btn_full_time:
          data.employement.btn_full_time || state.employement.btn_full_time,
        btn_part_time:
          data.employement.btn_part_time || state.employement.btn_part_time,
        btn_temporary:
          data.employement.btn_temporary || state.employement.btn_temporary,
        btn_not_employed:
          data.employement.btn_not_employed ||
          state.employement.btn_not_employed,
        btn_retired:
          data.employement.btn_retired || state.employement.btn_retired,
        btn_yes: data.employement.btn_yes || state.employement.btn_yes,
        btn_no: data.employement.btn_no || state.employement.btn_no,
        btn_looking:
          data.employement.btn_looking || state.employement.btn_looking,
        btn_not_working:
          data.employement.btn_not_working || state.employement.btn_not_working,
        btn_currently_working:
          data.employement.btn_currently_working ||
          state.employement.btn_currently_working,
        not_sure: data.employement.not_sure || state.employement.not_sure,
        btn_very_likely:
          data.employement.btn_very_likely || state.employement.btn_very_likely,
        btn_somewhat_likeky:
          data.employement.btn_somewhat_likeky ||
          state.employement.btn_somewhat_likeky,
        btn_somewhat_unlikely:
          data.employement.btn_somewhat_unlikely ||
          state.employement.btn_somewhat_unlikely,
        btn_very_unlikely:
          data.employement.btn_very_unlikely ||
          state.employement.btn_very_unlikely,
        btn_not_sure:
          data.employement.btn_not_sure || state.employement.btn_not_sure,
        btn_yes_often:
          data.employement.btn_yes_often || state.employement.btn_yes_often,
        btn_yes_sometimes:
          data.employement.btn_yes_sometimes ||
          state.employement.btn_yes_sometimes,
        btn_not_pursuing:
          data.employement.btn_not_pursuing ||
          state.employement.btn_not_pursuing
      },
      transportation: {
        id: 1,
        heading: data.transportation.heading || state.transportation.heading,
        question_1:
          data.transportation.question_1 || state.transportation.question_1,
        question_2:
          data.transportation.question_2 || state.transportation.question_2,
        question_3:
          data.transportation.question_3 || state.transportation.question_3,
        question_4:
          data.transportation.question_4 || state.transportation.question_4,
        question_5:
          data.transportation.question_5 || state.transportation.question_5,
        btn_yes: data.transportation.btn_yes || state.transportation.btn_yes,
        btn_no: data.transportation.btn_no || state.transportation.btn_no,
        btn_usually:
          data.transportation.btn_usually || state.transportation.btn_usually,
        btn_sometimes:
          data.transportation.btn_sometimes ||
          state.transportation.btn_sometimes,
        btn_rarely:
          data.transportation.btn_rarely || state.transportation.btn_rarely,
        btn_never:
          data.transportation.btn_never || state.transportation.btn_never,
        btn_do_not:
          data.transportation.btn_do_not || state.transportation.btn_do_not,
        btn_do_not_share:
          data.transportation.btn_do_not_share ||
          state.transportation.btn_do_not_share
      },
      income: {
        id: 1,
        heading: data.income.heading || state.income.heading,
        question_1: data.income.question_1 || state.income.question_1,
        question_2: data.income.question_2 || state.income.question_2,
        btn_myself: data.income.btn_myself || state.income.btn_myself,
        placeholder_enter_number:
          data.income.placeholder_enter_number ||
          state.income.placeholder_enter_number
      }
    }));
  }, [data]);

  return { cms };
};
