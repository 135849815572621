import styled from "styled-components";

export const PageContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

export const PolicyContainer = styled.div`
  overflow: auto;
  padding: 1rem;
  .line-break {
    white-space: pre-wrap;
  }
  max-width: 1366px;
  @media screen and (min-width: 768px) {
    padding: 2rem;
  }
`;

export const TitleWrapper = styled.div`
  margin-top: 1rem;
  display: flex;
  justify-content: center;
`;

export const Title = styled.h3`
  font-weight: 700;
`;

export const Header = styled.h4`
  margin-top: 1rem;
  font-weight: 500;
`;

export const StyledParagraph = styled.div`
  padding-top: 1rem;
  line-height: 1.3rem;
  &:not(:last-child) {
    margin-bottom: 2rem;
  }
`;
