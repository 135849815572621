import { Add16 } from "@carbon/icons-react";
import { Button, Modal, SkeletonText, Tooltip } from "carbon-components-react";
import { useContext, useState } from "react";
import * as S from "./styled";
import kit from "ich-ui-kit";
import AllClients from "../../pages/new-landing-page/components/AllClients";
import MyClients from "../../pages/new-landing-page/components/MyClients";
import { validateUrl } from "../../utils";

// Add "floating" to make it a floating card that is positioned in the top right
// Add "withShadow" in addition to "floating" to add shadow

function PatientInContext({ 
    clients,
    patient,
    setPatient, 
    floating, 
    withShadow, 
    withImage = true, 
    style,
    noPadding,
    loading
}) {
    const { user } = useContext(kit.UserContext)
    const [isOpen, setIsOpen] = useState(false);
    const [isTooltipOpen, setIsTooltipOpen] = useState(false);

    const viewDashboard = () => {
        window.open(validateUrl(`/360dashboard?id=${patient.id}`))
    }

    return (
        <S.Card 
            // floating={floating} 
            withShadow={withShadow} 
            noPadding={noPadding}
            style={style}
        >
            <S.GrayText>Currently searching on behalf of:</S.GrayText>
            {!loading ? (
                <S.FlexContainer 
                    onClick={viewDashboard} 
                    onMouseEnter={() => setIsTooltipOpen(true)}
                    onMouseLeave={() => setIsTooltipOpen(false)}
                    style={{cursor: "pointer", width: "max-content"}}
                >
                    {withImage && (
                        <S.ResponsiveAvatar 
                            alt="Profile Picture"
                            src={patient?.image || "./blank.png"}
                        />
                    )}
                    <Tooltip
                        showIcon={false}
                        open={isTooltipOpen}
                        direction="right"
                        triggerText={patient?.firstName + " " + (patient?.lastName || "")}
                    >
                        View Dashboard
                    </Tooltip>
                </S.FlexContainer>
            ) : user && clients ? (
                // TODO - finish modal popup
                <>
                    <Button 
                        size="sm" 
                        renderIcon={Add16}
                        onClick={() => setIsOpen(!isOpen)}
                    >
                        Add Patient
                    </Button>

                    <Modal 
                        open={isOpen}
                        modalHeading="Add a client in context"
                    >
                        {user?.is211Admin ? (
                            <AllClients 
                                clients={[]}
                                setClient={setPatient}
                                clientSelect
                            />
                        ):(
                            <MyClients clients={[]} /> 
                        )}
                    </Modal>
                </>
            ) : (
                <SkeletonText style={{
                    width: "120px",
                    height: "30px",
                    marginTop: "0.5rem"
                }} />
            )}
        </S.Card>
    )
}

export default PatientInContext;