import styled from "styled-components";

const Pill = styled.div`
  display: flex;
  width: 12rem;
  height: 3.5rem;
  /* margin-right: 1rem; */
  margin-top: 1rem;
  padding: 0.3rem 0.5rem 0.3rem 0.5rem;
  border: 1px solid black;
  border-radius: 50rem;
  background-color: white;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  :hover {
    background-color: aliceblue;
  }

  &&.active {
    background-color: aliceblue;
  }
`;

export default Pill;
