import { useState, useEffect } from "react";
import styled from "styled-components";
import * as S from "../Questions.styles";

const Income1 = ({
  cms,
  domain,
  width,
  setAllowContinue,
  incomeResponse,
  setIncomeResponse,
  setFeedbackIndex,
  setOverallIndex
}) => {

  useEffect(() => {
    if (incomeResponse.q1.answer !== " ") {
      setButtonSelection(incomeResponse.q1.answer);
      setAllowContinue(false);
    } else {
      setAllowContinue(true);
    }
  }, []);

  const buttonLabels = [
    "$0 - $833",
    "$834 - $2,500",
    "$2,501 - $4,917",
    "$4,918 - $7,500",
    "$7,501 +"
  ];
  const [buttonSelection, setButtonSelection] = useState();

  return (
    // <Wrapper>
    <S.TextContainer>
      {/* <h4>Question 6 of 12</h4> */}
      <h5>{cms.income.question_1}</h5>
      <S.Wrapper>
        {width < 769 ? (
          <S.DropdownWrapper>
            <S.StyledDropdown
              ariaLabel="Dropdown"
              id="carbon-dropdown-example"
              items={buttonLabels}
              label={buttonSelection || "Select option..."}
              onChange={(e) => {
                setAllowContinue(false);
                setButtonSelection(e.selectedItem);
                setIncomeResponse((state) => ({
                  ...state,
                  q1: {
                    text: "1 What is your personal monthly income, from all sources (e.g. Work, VA Benefits, Disability)?",
                    answer: e.selectedItem
                  }
                }));
                setFeedbackIndex((index) => index + 1);
                setOverallIndex((index) => index + 1);
              }}
            />
          </S.DropdownWrapper>
        ) : (
          <S.ButtonGroup>
            {buttonLabels.map((label, i) => {
              let bcolor;
              {
                buttonSelection === label ? (bcolor = true) : (bcolor = false);
              }
              return (
                <S.StyledButton
                  key={i}
                  theme={{
                    bcolor: bcolor,
                    width: "98px",
                    padding: "1rem",
                    margin: "1rem"
                  }}
                  kind="tertiary"
                  onClick={() => {
                    setAllowContinue(false);
                    setButtonSelection(label);
                    setIncomeResponse((state) => ({
                      ...state,
                      q1: {
                        text: "1 What is your personal monthly income, from all sources (e.g. Work, VA Benefits, Disability)?",
                        answer: label
                      }
                    }));
                    setFeedbackIndex((index) => index + 1);
                    setOverallIndex((index) => index + 1);
                  }}
                >
                  {label}
                </S.StyledButton>
              );
            })}
          </S.ButtonGroup>
        )}
      </S.Wrapper>
    </S.TextContainer>
  );
};

export default Income1;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
  @media screen and (min-width: 1800px) {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
  @media screen and (max-width: 768px) {
    height: 60%;
    overflow-y: scroll;
  }
`;

export const InputWrapper = styled.div`
  margin-top: 5%;
  display: flex;
  width: 40%;
  justify-content: center;
  h5 {
    margin-right: 0.25rem;
  }
  @media screen and (min-width: 1800px) {
    width: 35%;
  }
  @media screen and (max-width: 768px) {
    margin: 1rem 0rem 2rem 0rem;
    width: 50%;
    align-items: center;
  }
`;
