import { ChevronRight32, Close16, Close32 } from "@carbon/icons-react";
import { motion } from "@carbon/motion";
import { ProgressStep } from "carbon-components-react";
import kit from "ich-ui-kit";
import React, { useContext, useEffect, useState } from "react";
import { CallLogContext } from "../../../../store/CallLogContext";
import AccountTab from "./accountTab";
import * as S from "./callLogPanel.styled";
import IntakeTab from "./intakeTab";
import LocateTab from "./locateTab";

const CallLogPanel = ({ toggleOpen, ...props }) => {
  // For All Client Table - START
  const [allClients, setAllClients] = useState([]);
  const [loading, setLoading] = useState(false);
  const { apiService } = useContext(kit.ApiContext);
  const { user } = useContext(kit.UserContext);

  useEffect(() => {
    let isMounted = true;
    if (!user.firstLogin && user.is211Admin && allClients.length < 50) {
      if (isMounted) {
        fetchClients();
      }
    }
    return () => {
      isMounted = false;
    };
  }, [apiService]);

  const fetchClients = async () => {
    try {
      setLoading(true);
      const patientsData = await apiService.get(
        `/account/users?birthdays=${true}&assumedId=${
          user.superAssumedRole?.leadId || ""
        }`
      );
      if (patientsData.clients && patientsData.clients.length > 0) {
        setAllClients(patientsData.clients);
      }
    } catch (e) {
      console.log(e);
    }
    setLoading(false);
  };
  // For All Client Table - END
  const {
    errors,
    setIsIntakeSubmitted,
    isIntakeSubmitted,
    client,
    setClient,
    clearCallLog
  } = useContext(CallLogContext);
  const [index, setIndex] = useState(0);

  useEffect(() => {
    if (isIntakeSubmitted === false) setIndex(0);
  }, [isIntakeSubmitted]);

  const renderContent = () => {
    switch (index) {
      case 0:
        return <IntakeTab setIndex={setIndex} />;
      case 1:
        return (
          <AccountTab
            setIndex={setIndex}
            allClients={allClients}
            loading={loading}
          />
        );
      case 2:
        return <LocateTab setIndex={setIndex} />;
      default:
        return <IntakeTab setIndex={setIndex} />;
    }
  };

  const handleChange = (i) => {
    setIsIntakeSubmitted(true);
    if (Object.keys(errors).length === 0) {
      setIndex(i);
    }
  };

  return (
    <S.PanelContainer
      {...props}
      theme={{
        motion: motion("entrance", "productive"),
        isOpen: props.expanded
      }}
    >
      <S.Flex row spaceBetween>
        <S.Flex>
          <S.PanelTitle>Enter Call Details</S.PanelTitle>
          {client && (
            <S.SelectedClient onClick={() => setClient(null)}>
              {client.givenName + " " + client.familyName} <Close16 />
            </S.SelectedClient>
          )}
        </S.Flex>

        <S.Flex row gap>
          <S.CloseButton
            kind="cancel"
            renderIcon={Close32}
            iconDescription="Cancel"
            tooltipPosition="bottom"
            hasIconOnly
            onClick={() => clearCallLog()}
            style={{ backgroundColor: "red", borderColor: "red" }}
          />
          <S.CloseButton
            kind="cancel"
            renderIcon={ChevronRight32}
            iconDescription="Close"
            tooltipPosition="bottom"
            hasIconOnly
            onClick={toggleOpen}
          />
        </S.Flex>
      </S.Flex>

      <S.StyledProgressIndicator currentIndex={index} onChange={handleChange}>
        <ProgressStep label="Intake" index={index} />
        <ProgressStep label="Account" index={index} />
        <ProgressStep label="Locate" index={index} />
      </S.StyledProgressIndicator>
      {renderContent()}
    </S.PanelContainer>
  );
};

export default CallLogPanel;
