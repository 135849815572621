import React, { useState, useContext, useEffect } from "react";
import { Grid, Row, Column, Dropdown } from "carbon-components-react";
import { useHistory } from "react-router";
import kit from "ich-ui-kit";
import * as S from "../../../referral-details/ReferralDetails.styles";
import Loading from "carbon-components-react/lib/components/Loading";
import styled from "styled-components";
import { ArrowLeft32 } from "@carbon/icons-react";
import { useDashboardCMS } from "../../cms";

const NeedsRequestDetails = () => {

  const { dashboard, sidebar } = useDashboardCMS();
  const cms = dashboard.needs_request

  const [loading, setIsLoading] = useState(false);
  const [referral, setReferral] = useState({});
  const [referralDetails, setReferralDetails] = useState({});
  const [isError, setIsError] = useState(false);
  const { apiService } = useContext(kit.ApiContext);
  const history = useHistory();
  useEffect(() => {
    if (history.location.state && history.location.state.referral) {
      getNeedsRequestDetails();
      setReferral(history.location.state.referral);
    }
  }, [history.location, referral]);

  const getNeedsRequestDetails = async () => {
    let requestId;
    if (referral.details) {
      requestId = referral.details;
    } else {
      requestId = history.location.state.referral.details;
    }
    setIsLoading(true);
    const response = await apiService.get(
      `/needsrequest?requestId=${requestId}`
    );
    if (response.ok) {
      setReferralDetails(response.rows[0]);
      setIsLoading(false);
    } else {
      setIsLoading(false);
    }
  };

  const updateNeedsRequestStatus = async (selectedValue) => {
    setIsLoading(true);
    const response = await apiService.put(
      "/needsrequest",
      { status: selectedValue, requestId: referralDetails.id }
    );
    if (response.ok) {
      getNeedsRequestDetails();
      setIsLoading(false);
    } else {
      setIsError(true);
      setIsLoading(false);
      setTimeout(() => {
        setIsError(false);
      }, 2500);
    }
  };

  const [width, setWidth] = useState(window.innerWidth);
  const isMobile = 600;
  useEffect(() => {
    const handleResizeWindow = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleResizeWindow);
    return () => {
      window.removeEventListener("resize", handleResizeWindow);
    };
  }, []);

  const formatDate = (date) => {
    if (!date) return "";
    const dateOnly = date.split("T")[0];
    let [year, month, day] = dateOnly.split("-");
    return `${month}/${day}/${year}`;
  };

  return (
    <S.StyledCard>
      <S.BlueBackLink onClick={() => history.goBack()}>
        <ArrowLeft32 />
      </S.BlueBackLink>
      {loading ? <Loading /> :
      <Grid>
        <Row>
          <Column xs={16} lg={8}>
            <S.HeaderDiv>
              <h4>
                <strong>{referralDetails.providerName || "N/A"}</strong>
              </h4>
              <p>{referralDetails.serviceName || "N/A"}</p>
            </S.HeaderDiv>
          </Column>
          <Column xs={16} lg={8}>
            <S.UpdateStatusContainer>
              <FlexEndDiv>
                <StyledDropdown
                  ariaLabel={cms.status_label}
                  items={cms.status_dropdown}
                  itemToString={(item) => (item ? item.text : "")}
                  disabled // disabled altogether for Provider
                  label={referralDetails.status || "Pending"}
                  titleText={`Status: ${referralDetails.status || "Pending"}`}
                  onChange={(e) => {
                    updateNeedsRequestStatus(e.selectedItem.value);
                  }}
                />
              </FlexEndDiv>
              {isError && (
                <ErrorNotification>{cms.error_notification}</ErrorNotification>
              )}
            </S.UpdateStatusContainer>
          </Column>
        </Row>
        <Row>
          <S.HorizontalRule />
        </Row>
        <br />
        <Column style={{ marginBottom: "3rem" }}>
          <Row>
            <h4>{cms.heading}</h4>
          </Row>
          <br />
          <Row>
            <S.SectionWrapper>
              <p>
                <span style={{ fontWeight: "500" }}>{cms.label_date}: </span>
                {new Date(referralDetails.createdAt).toLocaleDateString()}
              </p>
            </S.SectionWrapper>
          </Row>
          <Row>
            <S.SectionWrapper>
              <p>
                <span style={{ fontWeight: "500" }}>{cms.label_emailed}: </span>
                {referralDetails.providerEmail || "N/A"}
              </p>
            </S.SectionWrapper>
          </Row>
          <Row>
            <S.SectionWrapper>
              <p>
                <span style={{ fontWeight: "500" }}>{cms.label_message}: </span>
                {referralDetails.message || "N/A"}
              </p>
            </S.SectionWrapper>
          </Row>
        </Column>
      </Grid>}
    </S.StyledCard>
  );
};

const StyledDropdown = styled(Dropdown)`
  width: 15rem;
  .bx--list-box__field[disabled] {
    background: inherit;
  }
`;

const FlexEndDiv = styled.div`
  margin-top: 1rem;
  display: flex;
  text-align: left;
  justify-content: initial;
  @media screen and (min-width: 1056px) {
    justify-content: flex-end;
    text-align: right;
  }
`;

const ErrorNotification = styled.span`
  margin-top: 0.5rem;
  color: red;
  text-align: left;
  @media screen and (min-width: 1056px) {
    text-align: right;
  }
`;

export default NeedsRequestDetails;
