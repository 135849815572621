import styled from "styled-components";
import { Flex } from "../index.styled";
import { SkeletonPlaceholder, TooltipIcon } from "carbon-components-react";
import { Information16 } from "@carbon/icons-react";

const OverviewCards = ({
  registeredIndividuals,
  totalResources,
  totalReferrals,
  totalNeedsRequests,
  totalCareTeams,
  totalProviderOrgs,
  careLoading,
  needsLoading,
  clientsLoading,
  providersLoading,
  servicesAndReferralsLoading
}) => {
  const cardContents = [
    {
      title: "Total Connected Community Members",
      value: registeredIndividuals,
      loading: clientsLoading
    },
    {
      title: "Total Care Team Connections",
      value: totalCareTeams,
      loading: careLoading,
      tooltip: "Number of clients connected to this provider."
    },
    {
      title: "Total Referrals Received",
      value: totalResources,
      loading: servicesAndReferralsLoading
    },
    {
      title: "Total Referrals Sent",
      value: totalReferrals,
      loading: servicesAndReferralsLoading
    },
    {
      title: "Referral Completion Time",
      value: totalNeedsRequests + ' Days',
      loading: needsLoading
    },
    {
      title: "Total Connected Providers",
      value: totalProviderOrgs,
      loading: providersLoading,
      tooltip: "Number of organizations actively participating in the MyWayfinder Network."
    }
  ];

  return (
    <CardContainer>
      {cardContents.map((el, i) => (
        <FlatCard key={i}>
          <Flex
            style={{ alignItems: "baseline", justifyContent: "space-between" }}
          >
            <CardTitle>{el.title}</CardTitle>
            {el.tooltip && (
              <TooltipIcon
                tooltipText={el.tooltip}
                renderIcon={Information16}
                style={{ position: "relative", top: "2px" }}
                direction="left"
              />
            )}
          </Flex>
          <Flex style={{ alignItems: "baseline" }}>
            {el.loading ? (
              <SkeletonPlaceholder style={{ height: "36px", width: "108px" }} />
            ) : (
              <CardValue>{el.value || 0}</CardValue>
            )}
          </Flex>
        </FlatCard>
      ))}
    </CardContainer>
  );
};

const CardContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 6px;
  margin-bottom: 1rem;
  @media screen and (min-width: 600px) {
    grid-template-columns: 1fr 1fr 1fr;
  }
  @media screen and (min-width: 1200px) {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    gap: 12px;
    margin-bottom: 1.2rem;
  }
`;

const FlatCard = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 6rem;
  margin: auto;
  background-color: white;
  padding: 0.5rem;
  border-radius: 2px; /* 2px rounded corners */
  -webkit-box-shadow: 2px 2px 5px 2px rgba(0, 0, 0, 0.15);
  box-shadow: 2px 2px 5px 2px rgba(0, 0, 0, 0.15);
  @media screen and (min-width: 900px) {
    height: 8rem;
    padding: 1rem;
  }
`;

export const CardTitle = styled.p`
  font-size: 12px;
  line-height @media screen and (min-width: 500px) {
    font-size: 14px;
  }
  @media screen and (min-width: 900px) {
    font-size: 16px;
  }
`;

export const CardValue = styled.h2`
  font-weight: 500;
  margin-right: 4px;
`;

export default OverviewCards;
