import styled from "styled-components";
import { Grid, Column } from "carbon-components-react";

export const StyledGrid = styled(Grid)`
  height: 100%;
  overflow: auto;
`;

export const StyledColumn = styled(Column)`
  text-align: center;
  margin: 1rem 0;
`;

export const ProviderInfoHeader = styled.div`
  display: flex;
  justify-content: center;
`;

export const ProviderInfoHeaderContent = styled.div`
  margin-top: 1rem;
`;

export const BadgesDiv = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 1em;
`;