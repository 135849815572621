import styled from "styled-components";
import { Button } from "carbon-components-react";

export const Container = styled.div`
  margin: 1rem;
  display: flex;
  flex-direction: column;
  text-align: center;
`;

export const StyledButton = styled(Button)`
  margin: 2rem auto 0rem auto;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  @media screen and (max-width: 670px) {
    flex-direction: column;
  }
`;
