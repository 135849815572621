import { SearchProvider } from "../../resources/context/SearchResultsContext.context";
import { MenuContextProvider } from "./menu.context";

export const ContextProvider = ({ children }) => {
  // set state here
  return (
    <SearchProvider>
      <MenuContextProvider>{children}</MenuContextProvider>
    </SearchProvider>
  );
};
