import React from "react";
import footerIcon from "./img/32 Copy 2.svg";
import * as S from "./footer.styled";

const { REACT_APP_DISABLE_FEEDBACK_FEATURE } = process.env;

const AnonymousFooter = (props) => {
  return (
    <S.Container {...props}>
      <S.LogoContent>
        <S.Img src={footerIcon} alt="compass" />
        <S.Logo>MyWayfinder</S.Logo>
      </S.LogoContent>
      <S.Link2 href="/terms-and-conditions">
        Terms of Use
      </S.Link2>
      <S.Link2 href="/policies-and-procedures">
        Privacy Policy
      </S.Link2>
      <S.Link2 href="/about">
        About Us
      </S.Link2>
      {/* <S.Link
        to="#"
        onClick={() => {
          if (REACT_APP_DISABLE_FEEDBACK_FEATURE) {
            return;
          } else {
            window.showCollectorDialog()}
          }
        }
        // onClick={() => {}}
        label="Feedback"
        aria-label="Feedback"
      >
        Feedback
      </S.Link> */}
      <S.Link2 aria-label="frequently-asked-questions" href="/faq">
        Frequently Asked Questions
      </S.Link2>
      <S.Link2 href="/contact-us">
        Get Help
      </S.Link2>
    </S.Container>
  );
};

export default AnonymousFooter;
