import React, { useState, useEffect, useContext } from "react";
import styled from "styled-components";
import { Link, useParams, useHistory, useLocation } from "react-router-dom";
import {
  Button,
  Accordion,
  AccordionItem,
  Link as CarbonLink,
  SkeletonText,
  InlineNotification,
} from "carbon-components-react";
import { Email20 } from "@carbon/icons-react";
import kit from "ich-ui-kit";
import { fetchWithRetry } from "../../utils";

export const UIShellContainer = styled.div`
  position: fixed;
  width: 100%;
`;

export const StyledLink = styled(Link)`
  color: white;
`;

export const ProfilePicture = styled.img`
  height: 150px;
  width: 150px;
  border-radius: 50%;
  text-align: center;
  margin: 8px;
`;

const ProfilePictureBlank = styled.img`
  height: 150px;
  width: 150px;
  border-radius: 50%;
  text-align: center;
  margin: 8px;
  background-color: #f4f4f4;
  border: 1px grey solid;
`;

export const PointsCounter = styled.h1`
  grid-column-start: 1;
  color: blue;
  font-size: 3em;
  font-family: "IBM Plex Mono";
  font-weight: 200;
  margin-bottom: 0.25em;
`;

export const SideNavDropdownsContainer = styled.div`
  margin: 1em;
  height: 6em;
`;

export const AccordianContent = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;

  & img {
    margin-right: 1em;
  }
`;

const NavButton = styled(Button)`
  margin-top: 5em;
`;

export const SideNavButton = ({ history }) => {
  // const patientRecordRegex = RegExp('^/patients/[a-zA-Z0-9-]{36}/record', 'g');
  const patientRecordRegex = RegExp("/record", "g");
  return patientRecordRegex.test(history.location.pathname) ? (
    <Button
      kind="tertiary"
      onClick={() => {
        history.push(history.location.pathname.replace("/record", ""));
      }}
    >
      View Client Dashboard
    </Button>
  ) : (
    <Button
      kind="tertiary"
      onClick={() => {
        history.push(`${history.location.pathname}/record`);
      }}
    >
      View Client Record
    </Button>
  );
};

export const Padding = styled.div`
  flex: ${(props) => props.size};
`;

export const NotificationNumber = styled.div`
  flex: 1;
  font-size: 18;
`;

export const NotificationContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0px;
  background-color: red;
  color: white;
  border-radius: 30px;
  width: ${(props) => {
    if (props.width > 999) {
      return '40px';
    } else if (props.width > 99) {
      return '30px';
    } else {
      return '20px';
    }
  }
  };
  height: ${(props) => {
    if (props.width > 999) {
      return '28px';
    } else if (props.width > 99) {
      return '24px';
    } else {
      return '20px';
    }
  }
  };
  text-align: center;
  display: flex;
  flex-direction: column;
  cursor: pointer;
`;

export const PatientIconContainer = styled.div`
  width: 75px;
  padding: 10px;
  margin: 0 5px 10px 0;
  position: relative;
`;

export const FeedbackNotification = styled(InlineNotification)`
  transition-timing-function: ${(props) => props.theme.motion};
  transition-duration: 240ms;
  color: black;
  box-shadow: 2px 2px 5px 2px rgba(0,0,0,0.15);
  &&.bx--inline-notification {
    max-width: 100%;
    background-color: rgb(255, 209, 68);;
    margin: 0;
  }
  .bx--inline-notification__icon {
    display: none;
  }
  &&.bx--inline-notification--info {
    border-left: none;
  }
`;

export const NotificationBadge = (props) => (
  <NotificationContainer width={props.quantity}>
    <Padding size={1} />
    <NotificationNumber>{props.quantity}</NotificationNumber>
    <Padding size={1} />
  </NotificationContainer>
);

export const PatientIcon = ({
  history,
  src,
  patientId,
  width = "50px",
  title = "",
  notifications = 0,
}) => (
  <PatientIconContainer>
    {notifications > 0 && <NotificationBadge quantity={notifications} />}
    <img
      alt="client"
      src={src}
      style={{ marginRight: "1em", cursor: "pointer" }}
      width={width}
      title={title}
      onClick={
        patientId
          ? () => {
              history.push(`/patients/${patientId}`);
            }
          : null
      }
    />
  </PatientIconContainer>
);

export const ClientSideNav = () => {
  const [client, setClient] = useState(null);
  const { apiService } = useContext(kit.ApiContext);
  const history = useHistory();
  const location = useLocation();
  const { user } = useContext(kit.UserContext);
  const id = location.pathname.split("/")[2];

  const getAge = (dateString) => {
    const today = new Date();
    const birthDate = new Date(dateString);
    let age = today.getFullYear() - birthDate.getFullYear();
    const m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    return age;
  };

  const format = (input) => {
    const pattern = /(\d{4})\-(\d{2})\-(\d{2})/;
    if (!input || !input.match(pattern)) {
      return null;
    }
    return input.replace(pattern, "$2/$3/$1");
  };

  useEffect(() => {
    const getClientRecord = async () => {
      try {
        const response = await fetchWithRetry(
          `${process.env.REACT_APP_API_ENDPOINT}/clients/${id}?assumedId=${user.superAssumedRole?.leadId || ""}`,
          {
            method: "get",
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        if (response.status === 200) {
          const data = await response.json();
          setClient(data);
        }
      } catch (err) {
        console.log(err);
      }
    };
    getClientRecord();
  }, []);

  const renderProfileData = () => {
    if (client) {
      const name = `${client.firstName} ${client.lastName}`;
      return (
        <>
          <ProfilePicture src={client.image || "/Libby.png"} />
          <h3>{name}</h3>
          {/* TODO: have api return age */}
          <p> {getAge(client.dob)} </p>
          <p>{format(client.dob)}</p>
          <p>{client.gender}</p>
          {client.email && (
            <p
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Email20 style={{ marginRight: "8px" }} />
              {client.email}
            </p>
          )}
        </>
      );
    }
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <ProfilePictureBlank />
        <SkeletonText style={{ width: "70%" }} />
        <SkeletonText style={{ width: "15%" }} />
        <SkeletonText style={{ width: "35%" }} />
        <SkeletonText style={{ width: "50%" }} />
      </div>
    );
  };
  return (
    <div style={{ width: "100%", textAlign: "center", padding: "8px" }}>
      {renderProfileData()}
      <SideNavDropdownsContainer>
        <Accordion>
          <AccordionItem title="Preferences" />
          <AccordionItem title="Badges" />
        </Accordion>
      </SideNavDropdownsContainer>
      <SideNavButton history={history} />
      <div style={{ marginTop: "2em" }}>
        <CarbonLink
          target="_blank"
          rel="noopener noreferrer"
          href="https://ibm.box.com/s/r6j82ymdejrgy3t5otymnf1f1743bhwi"
        >
          Documents
        </CarbonLink>
      </div>
    </div>
  );
};
