import React, { useState, useEffect, useContext } from "react";
import { SkeletonPlaceholder, RadioButton, RadioButtonGroup } from "carbon-components-react";
import { useLocation } from "react-router-dom";
import kit from "ich-ui-kit";
import * as S from "./demographic.styled";
import * as Subpage from "../subpage.styled";
import { fetchWithRetry, generateClickToCallLink } from "../../../../utils";
import { useDemographicCMS } from "../../../client-enrollment/components/360ProfileForm/cms";

const Demographic = ({ clientID = null, isMobile = false }) => {
  const { demographic } = useDemographicCMS();
  let location = useLocation();
  const [loading, setLoading] = useState(true);
  const { user } = useContext(kit.UserContext);
  const [show, setShow] = useState({
    fname: "",
    lname: "",
    legalName: "",
    dob: "",
    age: "",
    gender: "",
    sex: "",
    race: "",
    language: "",
    ssn: "",
    marital: "",
    ethinicty: "",
    hispanic: "",
    contact: {
      phone: "",
      email: "",
      address1: "",
      address2: "",
      city: "",
      state: "",
      zip: "",
    },
    emergency: {
      fname: "",
      lname: "",
      rel: "",
      phone: "",
      email: "",
    },
    housingSituation: "",
    homeOccupancy: "",
    homeOccupancyAdults: "",
    homeOccupancyChildren: "",
    preferredContactMethod: ""
  });
  const [isHispanic, setIsHispanic] = useState(false);
  useEffect(() => {
    try {
      setLoading(true);
      const getUserInfo = async () => {
        const response = await fetchWithRetry(
          `${process.env.REACT_APP_API_ENDPOINT}/clients/${location.state.client}?assumedId=${user.superAssumedRole?.leadId || ""}`,
          {
            method: "get",
            headers: {
              Authorization: `Bearer ${user.accessToken} ${user.idToken}`,
            },
          }
        );

        function ageCalculator (birthday) {
          const today = new Date();
          const bDay = new Date(birthday);
          var age = today.getFullYear() - bDay.getFullYear();
          var month = today.getMonth() - bDay.getMonth();
          if (month < 0 || (month === 0 && today.getDate() < bDay.getDate())) 
          {
              age--;
          }
          return age;
        }
        if (response.status === 200) {
          const data = await response.json();
          const unformattedBirthDate = data?.dob;
          const formattedBirthDate = JSON.stringify(unformattedBirthDate);
          var fYear = formattedBirthDate?.substring(1, 5);
          var fMonth = formattedBirthDate?.substring(6, 8);
          var fDay = formattedBirthDate?.substring(9, 11);
          const birthDate = new Date(fYear, fMonth - 1, fDay); 
          data.age = ageCalculator(birthDate);

          if (data.hispanicOrigin !== "") {
            setIsHispanic(true);
          } else {
            setIsHispanic(false);
          }
          setShow({
            fname: data?.firstName,
            lname: data?.lastName,
            nickName: data?.nickName,
            dob: data?.dob,
            age: data?.age,
            gender: data?.genderIdentity,
            sex: data?.gender,
            race: data?.race,
            language: data?.language,
            homeOccupancy: Boolean(data?.homeOccupancy?.length)
              ? data?.homeOccupancy
              : 1,
            homeOccupancyAdults: data?.homeOccupancyAdults ?? '',
            homeOccupancyChildren: data?.homeOccupancyChildren ?? '',
            housingSituation: data?.housingSituation ?? '',
            marital: data?.maritalStatus,
            ethnicity: data?.ethnicity,
            hispanic: data?.hispanicOrigin,
            contact: {
              phone: data?.phone,
              email: data?.email,
              address1: data?.address?.address1,
              address2: data?.address?.address2,
              city: data?.address?.city,
              state: data?.address?.state,
              zip: data?.address?.postalCode,
              preferredContactMethod: data?.communicationpreference
              ? handleCommunicationPreference(data?.communicationpreference)
              : "email"
            },
            emergency: {
              fname: data?.eContactInfo?.firstName || '',
              lname: data?.eContactInfo?.lastName || '',
              rel: data?.eContactInfo?.relationship || '',
              phone: data?.eContactInfo?.phone || '',
              email: data?.eContactInfo?.email || '',
            },
          });
        } else {
          console.log(response);
        }
      };
      getUserInfo();
    } catch (e) {
    } finally {
      setLoading(false);
    }
  }, [location.state]);

  const field = (heading, text = "", isShort = false) => (
    <S.FieldSet>
      <S.HeadingSM>{heading}</S.HeadingSM>
      {loading ? (
        <SkeletonPlaceholder
          style={{ height: "24px", width: isShort ? "47%" : "100%" }}
        />
      ) : (
        (heading === "Phone Number" && generateClickToCallLink(text) ? 
          <S.Link href={generateClickToCallLink(text)}>{text}</S.Link> 
          :
          <S.Text>{text === "" || text === undefined ? <>&nbsp;</> : text}</S.Text>)
      )}
    </S.FieldSet>
  );

  const handleCommunicationPreference = (preference) => {
    if (preference === "mobile" || preference === "text") {
      return "text";
    }
    if (preference === "home" || preference === "phone") {
      return "phone";
    }
    if (preference === "email") {
      return "email";
    }
  };

  return (
    <Subpage.Container>
      <Subpage.InnerContainer>
        <Subpage.Header>
          <h3>
            <strong>Demographic Data</strong>
          </h3>
        </Subpage.Header>

        <S.FormOuterDiv>
          <S.FormInnerDiv>
            <S.PersonalContainer>
              <S.HeadingMD>{demographic.personal.heading}</S.HeadingMD>
              <S.Row>
                {field(demographic.personal.label_firstname, show.fname)}
                {field(demographic.personal.label_lastname, show.lname)}
              </S.Row>
              <S.Row>
                {field(demographic.personal.label_nickname, show.nickName)}
                {field(demographic.personal.label_dob, show.dob)}
              </S.Row>
              <S.Row>
                {field(demographic.personal.label_language, show.language)}{" "}
                {field(demographic.personal.label_age, show.age)}
              </S.Row>
              <S.Row>
                {/* {field(demographic.personal.label_ssn, "")} */}
                {field(demographic.personal.label_marital_status, show.marital)}
              </S.Row>
              <S.Row>
                {field(demographic.personal.label_ethnicity, show.ethnicity)}
                {show.hispanic
                  ? field(demographic.personal.label_hispanic, show.hispanic)
                  : undefined}
              </S.Row>
              <S.Row>
                {field(demographic.personal.label_gender, show.gender)}
              </S.Row>
              <S.Row>{field(demographic.personal.label_race, show.race)}</S.Row>
              <S.Row>
                <S.GroupDiv>
                  {field(
                    demographic.personal.label_occupancy,
                    show.homeOccupancy
                  )}
                  {field(
                    demographic.personal.label_occupancy_adults,
                    show.homeOccupancyAdults
                  )}
                  {field(
                    demographic.personal.label_occupancy_children,
                    show.homeOccupancyChildren
                  )}
                </S.GroupDiv>
              </S.Row>
            </S.PersonalContainer>
          </S.FormInnerDiv>
          <S.FormInnerDiv>
            <S.ContactContainer>
              {/* Contact Information */}
              <S.HeadingMD>{demographic.contact.heading_contact}</S.HeadingMD>
              <S.Row>
                {field(demographic.contact.label_mobile, show.contact.phone)}
                {field(demographic.contact.label_email, show.contact.email)}
              </S.Row>
              <S.Row>
                {field(
                  demographic.contact.label_address_line_1,
                  show.contact.address1
                )}
              </S.Row>
              <S.Row>
                {field(
                  demographic.contact.label_address_line_2,
                  show.contact.address2
                )}
              </S.Row>
              <S.Row>
                {field(demographic.contact.label_city, show.contact.city)}
                {field(demographic.contact.label_state, show.contact.state)}
                {field(demographic.contact.label_zipcode, show.contact.zip)}
              </S.Row>
              <S.Row>
                {field(
                  demographic.contact.label_housing_situation,
                  show.housingSituation
                )}
              </S.Row>

              <S.Row>
                <S.FieldSet>
                  <S.FormTextAreaDiv
                    style={{ display: "flex", flexDirection: "column" }}
                  >
                    <S.HeadingSM>
                      {demographic.contact.label_contact_method}
                    </S.HeadingSM>
                    <RadioButtonGroup
                      name="radio-button-group"
                      valueSelected={handleCommunicationPreference(
                        show.contact.preferredContactMethod
                      )}
                      disabled={true}
                    >
                      <RadioButton
                        id="email-radio"
                        labelText={demographic.contact.radio_email}
                        value="email"
                        name="email"
                      />
                      <RadioButton
                        id="phone-radio"
                        labelText={demographic.contact.radio_phone}
                        value="phone"
                        name="phone"
                      />
                      <RadioButton
                        id="radio-text"
                        labelText={demographic.contact.radio_text}
                        value="text"
                        name="text"
                      />
                    </RadioButtonGroup>
                  </S.FormTextAreaDiv>
                </S.FieldSet>
              </S.Row>
            </S.ContactContainer>
            <S.EmergencyContainer>
              {/* Emergency Contact */}
              <S.HeadingMD>
                {demographic.eContact.heading_emergency_contact}
              </S.HeadingMD>
              <S.Row>
                {field(
                  demographic.eContact.label_firstname,
                  show.emergency.fname
                )}
                {field(
                  demographic.eContact.label_lastname,
                  show.emergency.lname
                )}
              </S.Row>
              <S.Row>
                {field(
                  demographic.eContact.label_relationship,
                  show.emergency.rel,
                  true
                )}
              </S.Row>
              <S.Row>
                {field(demographic.eContact.label_mobile, show.emergency.phone)}
                {field(demographic.eContact.label_email, show.emergency.email)}
              </S.Row>
            </S.EmergencyContainer>
          </S.FormInnerDiv>
        </S.FormOuterDiv>
      </Subpage.InnerContainer>
    </Subpage.Container>
  );
};

export default Demographic;
