import React from "react";
import { motion } from "@carbon/motion";
import { useTheme } from "styled-components";
import { Link } from "./item.styled";

export const Item = ({ to, text, currentPage, setCurrentPage, setIsOpen }) => {
  const theme = useTheme();
  return (
    <Link
      theme={{
        ...theme,
        motion: motion("standard", "expressive"),
        isCurrent: currentPage === to ? true : false
      }}
      onClick={() => {
        setCurrentPage(to);
        setTimeout(() => {
          setIsOpen(false);
        }, 10)
      }}
    >
      {text}
    </Link>
  );
};
