import React from "react";
import * as S from "./about-us.styled";

import { Item } from "./item";

import { useAboutCMS } from "./cms";

const AboutUs = () => {
  const { about } = useAboutCMS();
  return (
    <S.Container>
      <S.BannerContainer
            bgImage={
                about.background_image
                ? `${process.env.REACT_APP_STRAPI_ENDPOINT}${
                    window.innerWidth < 400
                      ? about.background_image?.small?.url
                      : window.innerWidth < 600
                      ? about.background_image?.medium?.url
                      : about.background_image?.large?.url
                  }`
                : ""
            }
      >
        <S.Heading>{about.heading}</S.Heading>
        <S.Overlay />
      </S.BannerContainer>

      <S.Items>
        {about?.items &&
          about.items.map((item) => (
            <Item
              aria-label={item.title}
              key={item.id}
              heading={item.title}
              paragraphs={item.content}
            />
          ))}
      </S.Items>
    </S.Container>
  );
};

export default AboutUs;
