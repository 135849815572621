import styled from "styled-components";

export const Content = styled.div`
  background-color: #d0e2ff;
  background-repeat: no-repeat;
  background-size: 100vw;
  height: calc(100vh - 7rem);
  width: 100%;
  display: grid;
  grid-template-columns: 50% 50%;
  grid-template-rows: auto auto auto auto auto auto auto 9rem;
  grid-template-areas:
    "form form"
    "title title"
    "community community"
    "careteam careteam"
    "helping helping"
    "benefits benefits"
    "thrive thrive"
    "footer footer";
  overflow: auto;
  vertical-align: center;
  @media screen and (max-width: 767px) {
    height: calc(100vh - 17rem);
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
`;

export const Container1 = styled.div`
  background-color: #d0e2ff;
  grid-area: form;
  display: flex;
  height: 95vh;
  width: 100%;
  margin-top: 2em;
  padding-bottom: 5em;

  @media screen and (max-width: 900px) {
    display: flex;
    height: auto;
    flex-direction: column;
    align-content: center;
    text-align: center;
  }
`;

export const ContainerTitle = styled.div`
  background-color: white;
  display: grid;
  grid-area: title;
  width: 100%;
  padding-bottom: 2rem;
`;
export const ContainerHelp = styled.div`
  background-color: white;
  display: flex;
  grid-area: helping;
  width: 100%;
  padding-bottom: 2rem;

  @media screen and (max-width: 900px) {
    display: flex;
    height: auto;
    flex-direction: column;
    align-content: center;
    text-align: center;
  }
`;

export const ContainerBenefits = styled.div`
  background-color: #c6c6c6;
  display: flex;
  grid-area: benefits;
  justify-content: center;
  /* padding-left: 27.5%; */
  width: 100%;
  padding-bottom: 5em;

  @media screen and (max-width: 900px) {
    display: flex;
    height: auto;
    flex-direction: column;
    align-content: center;
    text-align: center;
  }
`;

export const BenefitsMiddle = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  margin-top: 3em;

  @media screen and (max-width: 900px) {
    display: flex;
    height: auto;
    flex-direction: column;
    align-content: center;
    text-align: center;
    margin-top: 2rem;
  }
`;
export const BenefitsLeft = styled.div`
  display: flex;
  flex-direction: column;
  text-align: right;
  margin-top: 7em;

  @media screen and (max-width: 900px) {
    display: flex;
    height: auto;
    flex-direction: column;
    align-content: center;
    text-align: center;
    margin-top: 2rem;
  }
`;
export const BenefitsRight = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;
  margin-top: 7em;

  @media screen and (max-width: 900px) {
    display: flex;
    height: auto;
    flex-direction: column;
    align-content: center;
    text-align: center;
    margin-top: 2rem;
  }
`;

export const ContainerCareTeam = styled.div`
  background-color: white;
  display: flex;
  grid-area: careteam;
  width: 100%;
  padding-bottom: 2rem;

  @media screen and (max-width: 900px) {
    display: flex;
    height: auto;
    flex-direction: column;
    align-content: center;
    text-align: center;
  }
`;

export const ContainerCommunity = styled.div`
  background-color: white;
  display: flex;
  grid-area: community;
  width: 100%;
  padding-bottom: 2rem;

  @media screen and (max-width: 900px) {
    display: flex;
    /* grid-column: span 4; */
    height: auto;
    flex-direction: column;
    align-content: center;
    text-align: center;
  }
`;

export const ContainerThrive = styled.div`
  grid-area: thrive;
  background-color: #d0e2ff;
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const RightCol = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;
  margin-left: 0;
  margin-top: 1%;
  width: 50%;

  @media screen and (max-width: 900px) {
    display: flex;
    height: auto;
    flex-direction: column;
    align-content: center;
    text-align: center;
    margin: 0rem;
    width: 100%;
  }
`;
export const LeftCol = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;
  margin-left: 15%;
  margin-top: 2.5%;
  width: 50%;

  @media screen and (max-width: 900px) {
    display: flex;
    height: auto;
    flex-direction: column;
    align-content: center;
    text-align: center;
    margin: 0rem;
    width: 100%;
  }
`;

export const Page2Title = styled.h1`
  grid-column: span 4;
  text-align: center;
  margin-top: 1em;
`;

export const ThriveTitle = styled.h4`
  text-align: center;
  margin-top: 3em;
  margin-bottom: 3em;
`;

export const ImageMap = styled.img`
  max-width: 50%;

  @media screen and (max-width: 900px) {
    max-width: 100%;
  }
`;

export const ImageProviders = styled.img`
  grid-column: 1 / span 5;
  z-index: 1;
  width: 100%;
`;

export const QuoteDiv = styled.div`
  display: flex;
  z-index: 1;
  width: 100%;

  @media screen and (max-width: 900px) {
    display: flex;
    height: auto;
    flex-direction: column;
    align-content: center;
    text-align: center;
  }
`;
export const QuoteCard = styled.div`
  flex-direction: column;
  margin: auto;
  margin-bottom: 2rem;
  text-align: center;
  margin-top: 0rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;

  @media screen and (max-width: 900px) {
    display: flex;
    height: auto;
    flex-direction: column;
    align-content: center;
    text-align: center;
  }
`;

export const TitleBox = styled.div`
  order: 0;
  text-align: left;
  width: 40%;
  margin-left: 5em;
  font-family: IBMPlexSans-Bold;

  @media screen and (max-width: 900px) {
    margin: auto;
    width: auto;
    text-align: center;
    margin-bottom: 2em;
  }
`;

export const Subtitle = styled.h5`
  text-align: left;
`;

// export const PASeal = styled.img`
//   width: 5rem;
//   position: absolute;
//   bottom: 1rem;
// `;

export const ImageGroup = styled.img`
  margin-top: 6rem;
  margin-left: 5rem;
  width: 50%;

  @media screen and (max-width: 900px) {
    margin: auto;
    width: 75%;
    margin-top: 2rem;
  }
`;

export const TitleParagraph = styled.div`
  font-size: 1.5em;
  margin-top: 1em;
`;

export const EmbedFrame = styled.div`
  order: 1;
  background-color: white;
  color: black;
  width: 90%;
  height: 100%;
  margin-right: 2em;
  margin-top: 1rem;
  padding: 1rem;

  @media screen and (max-width: 900px) {
    width: 100%;
    margin: auto;
    padding: 0rem;
    height: 50rem;
  }
`;

export const FormBox = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 2rem;
  width: 50%;
  height: 100%;
  @media screen and (max-width: 900px) {
    width: 100%;
    padding: 0rem;
  }
`;
