import styled from "styled-components";
import { Button, Accordion, AccordionItem, Tooltip } from "carbon-components-react";

export const ViewButton = styled(Button)`
// padding: 12px 24px 12px 12px;
// min-height: 1rem;
  margin-right: 1rem;
  @media screen and (max-width: 768px){
    font-size: 12px;
    min-height: 0.5rem;
    padding: 6px 24px 6px 12px;
    margin-right: 1rem;
    margin-left: -0.5rem;
  }
`;

export const TakeAssessmentButton = styled(Button)`
align-self: right;
padding: 12px 24px 12px 12px;
// width: 4rem;
min-height: 1rem;
height: 1.5rem;
font-size: 12px;
align-self: end;
`;

export const StyledAccordion = styled(Accordion)`
  position: relative;
  width: ${(props) => props.theme.width + 'px'};
  max-width: 500px;
`;
export const StyledAccordionItem = styled(AccordionItem)`
  width: 500px;
  text-align: left;
  padding: 0rem;
  .bx--accordion__content {
    padding: 1rem;
  }
`;

export const Stydiv = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 1rem;
  @media screen and (max-width: 768px) {
    margin-left: 1rem;
    margin-bottom: 1rem;
  }
`;

export const AccItemWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

export const RowWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const AccColorCard = styled.div`
  width: 10px;
  // padding: 10px 0;
  border-top: 1px solid #e0e0e0;
  border-bottom: 1px solid #e0e0e0;
  display: flex;
  background-color: ${(props) => props.theme.bcolor};
`;

export const AccordionDiv = styled.div`
display: flex;
flex-direction: row;
padding: 0rem;
margin: 0rem;
justify-content: space-between;
`;

export const BackText = styled.div`
margin: 0.25rem 0.25rem;
@media screen and (max-width: 768px){
  font-size: 0.75rem;
}
`;

export const Paragraph = styled.div`
margin-bottom: 0.25rem;
font-size: 1rem;
p {
  font-size: 1rem;
}
h4 {
  font-size: 1rem;
  font-weight: 500;
}
@media screen and (max-width: 768px){
  font-size: 0.75rem;
  p {
    font-size: 0.75rem;
  }
  h4 {
    font-size: 0.75rem;
    font-weight: 600;
  }
}
`;

export const ResourceText = styled.div`
display: flex;
flex-direction: row;
@media screen and (max-width: 768px){
  font-size: 0.75rem;
}
`;

export const ResponseWrapper = styled.div`
width: 130px;
@media screen and (max-width: 768px){
  font-size: 0.75rem;
  width: auto;
  margin-right: 0.75rem;
}
`;

export const ResourceHeader = styled.div`
font-weight: 500;
margin-bottom: 0.25rem;
@media screen and (max-width: 768px){
  font-size: 0.75rem;
}
`;

export const DomainCircle = styled.div`
background-color: ${(props) => props.theme.bcolor};
border-radius: 100%;
padding: 0.5rem;
margin: auto;
width: 50px;
height: 50px;
display: flex;
align-items: center;
justify-content: center;
@media screen and (max-width: 768px){
  margin: auto;
  width: 40px;
  height: 40px;
}
`;

export const DomainHeader = styled.div`
font-weight: 500;
font-size: 0.6rem;
margin-top: 0.25rem;
@media screen and (max-width: 768px){
  font-size: 0.75rem;
}
@media screen and (max-width: 600px){
  font-size: 0.6rem;
}
`;

export const DomainCardTitle = styled.div`
font-size: 0.65rem;
// @media screen and (max-width: 768px){
//   font-size: 0.75rem;
// }
// @media screen and (max-width: 600px){
//   font-size: 0.6rem;
// }
`;

export const ContentContainer = styled.div`
  // height: auto;
  margin-right: 2rem;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: start;
  max-width: 500px;
  background-color: white;
  border: 1px solid rgba(0, 0, 0, 0.2);
  box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.1);
  @media screen and (max-width: 768px){
    background-color: white;
    // border: none;
    // border-top: 1px solid rgba(0, 0, 0, 0.2);
    // box-shadow: none;
  }
  @media screen and (max-width: 500px){
    background-color: white;
    border: none;
    border-top: 1px solid rgba(0, 0, 0, 0.2);
    box-shadow: none;
  }
`;

export const ContentTitleWrapper = styled.div`
// height: 50%;
margin-right: 2rem;
display: flex;
flex-wrap: wrap;
justify-content: start;
flex-direction: row;
max-width: 500px;
margin-bottom: 2rem;
@media (min-width: 1200px){
  height: 50%;
}
`;

export const PageContainer = styled.div`
  height: 100%;
  width: 100%;
  // display: flex;
  // flex-wrap: wrap;
  // flex-direction: row;
  // max-width: 800px;
  overflow-y: scroll;
  @media (max-width: 1200px){
    flex-direction: column;
    margin-bottom: 7rem;
  }
  h6 {
    // margin-left: 1rem;
    margin-bottom: 0.5rem;
    font-size: 18px;
  }
`;

export const SubdomainCard = styled.div`
width: 100%;
display: flex;
height: 7.5rem;
flex-direction: row;
margin: 0.5rem 0.5rem;
padding: 0.25rem 0.5rem 0.5rem 1.5rem;
// background-color: #e6e6e6;
// justify-content: space-around;
border: 1px solid rgba(0, 0, 0, 0.2);
box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.1);
@media screen and (max-width: 768px){
  justify-content: left;
  align-items: left;
  padding: 0.05rem 0.05rem 0.25rem 0.05rem;
  height: 4.5rem;
  width: 100%;
  // margin-right: 5rem;
  overflow-y: scroll;
}
`;

export const DomainInfo = styled.div`
margin: 1rem 1rem 1rem 2rem;
display: flex;
min-width: 22rem;
flex-direction: column;
@media screen and (max-width: 768px){
  width: auto;
  margin: 0.5rem 0rem;
  min-width: 14rem;
}
`;

export const TooltipStyled = styled(Tooltip)`
  min-width: 30rem;
  @media screen and (max-width: 1500px){
    min-width: 20rem;
  }
  @media screen and (max-width: 1300px){
    min-width: 15rem;
  }
`;