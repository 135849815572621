export function convertCellToDateString(cellValue) {
    if(cellValue){
        if(typeof cellValue === "string"){
            if(cellValue.includes(":")){
                return new Date(cellValue).toLocaleDateString();
            } else {
                return cellValue;
            }
        } else {
            return cellValue;
        }
    } else {
        return cellValue;
    }
}