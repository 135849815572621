import styled from "styled-components";
import { Link } from "react-router-dom";
import {
  FluidForm,
  Form,
  SkeletonPlaceholder,
  ToastNotification,
  ButtonSet,
  ModalBody,
  FileUploader,
  SideNavLink,
  TableContainer,
  Dropdown,
} from "carbon-components-react";
import { Edit20, ChevronLeft32, EditOff20 } from "@carbon/icons-react";

export const StyledCheveronLeft32 = styled(ChevronLeft32)`
  background-color: white;
  color: blue;
  width: 3rem;
  height: 3rem;
  padding: 0.5rem;
  cursor: pointer;
  &:hover {
    background-color: #e5e5e5;
  }
`;

export const ImageCardGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 5fr 1fr 0.5fr;
  gap: 15px 15px;
  padding: 20px 10px;
  height: 95%;
  justify-items: center;
  @media screen and (min-width: 900px) {
    grid-template-rows: 3fr 1fr 0.5fr;
    height: 90%;
  }
  @media screen and (min-width: 1100px) {
    padding: 20px 30px;
  }
  @media screen and (min-width: 1300px) {
    padding: 20px 50px;
  }
  @media screen and (min-width: 1500px) {
    padding: 20px 70px;
  }
`;

export const ImageCardSplitGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr;
  gap: 12px;
  width: 100%;
`;

export const ImageCardText = styled.span`
  padding: 0.25rem;
`;

export const AccountCard = styled.div`
  background-color: #fff;
  width: 90%;
  flex: 0 0 auto;
  box-shadow: 0.5px 0.5px 0.5px 0 #979797;
  margin-right: 1rem;
  margin-top: 1rem;
  padding-bottom: 1rem;
  min-height: 20rem;
  ${"" /* margin-left: 0.5rem; */}
  @media (max-width: 900px) {
    width: 99%;
    margin: auto;
    margin-bottom: 1rem;
    height: auto;
  }
`;

export const StyledList = styled.ul`
  margin-top: 0.25rem;
  li {
    margin-bottom: 0.25rem;
  }
`;

export const StyledToastNotification = styled(ToastNotification)`
  position: absolute;
  right: 0;
  top: 5rem;
`;

export const TextAreaDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  margin-bottom: 1rem;
  width: 100%;

  @media (max-width: 900px) {  
    flex-direction: column;
  }
`;

export const CheckboxAreaDiv = styled.div`
  display: flex;
  justify-content: space-around;
  margin-bottom: 1rem;
  @media (max-width: 900px) {
    width: 100%;
    flex-direction: column;
  }
`;

export const UserMgmtTextDiv = styled.div`
  margin-bottom: 0.5rem;
  @media screen and (min-width: 600px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 1rem;
    row-gap: 0.5rem;
  }
  @media screen and (min-width: 900px) {
    width: 100%;
  }
  @media screen and (min-width: 1200px) {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
  @media screen and (min-width: 1600px) {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  }
`;

export const UserMgmtDropdownDiv = styled.div``;

export const UserMgmtTextInputDiv = styled.div`
  margin-top: 0.5rem;
  margin-right: 0.5rem;
  width: 40%;

  & .bx--list-box__menu {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
  }

  

  @media (max-width: 900px) {
    width: 100%;
    & label {
      align-self: flex-start;
    }
  }

  // Overriding Carbon styling to wrap service name on mobile
  @media (max-width: 425px) {
    & .bx--list-box__menu {
      width: auto;
    }

    & .bx--list-box__menu-item,
    & .bx--list-box__menu-item__option {
      height: auto;
    }

    & .bx--checkbox-label {
      white-space: normal !important;
    }
  }
`;

export const UserMgmtTextInputWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;

  @media (max-width: 350px) {
    & .bx--text-input-wrapper {
      width: 100%;
    }
  }
`;

export const UserMgmtAccountTypeDropdownDiv = styled.div`
  margin-top: 0.5rem;
`;

export const UserMgmtAccountTypeDropdown = styled(Dropdown)``;

export const TextInputDiv = styled.div`
  margin-top: 0.5rem;
  margin-right: 0.5rem;
  width: 30%;

  @media (max-width: 900px) {
    width: 100%;
  }
`;

export const RemoveRowDiv = styled.div`
  margin: auto 0% auto auto;
`;

export const StyledFluidForm = styled(Form)`
  overflow: auto;
  // width: 80%;
  @media (max-width: 900px) {
    width: 90%;
  }
`;

export const Container = styled.div`
  width: 100%;
  overflow-x: auto;
  .bx--dropdown--disabled .bx--list-box__label {
    color: #8d8d8d;
  }
`;

export const PageContainer = styled.div`
  height: 100%;
  width: 100%;
  padding-left: 8rem;
  overflow-y: auto;
  /* overflow-x: scroll;
  overflow-y: scroll; */
  .bx--content-switcher__label {
    margin-left: auto;
    margin-right: auto;
  }
  @media (max-width: 900px) {
    min-height: calc(100vh - 3rem);
    // overflow: auto;
    margin: auto;
    padding: 0 3rem;
    padding-bottom: 2rem;
  }
  @media (max-width: 500px) {
    min-height: calc(100vh - 3rem);
    // overflow: auto;
    margin: auto;
    padding: 0 1rem;
    padding-bottom: 2rem;
  }
`;

export const StyledTableContainer = styled(TableContainer)`
  .bx--data-table {
    th:nth-child(5),
    th:nth-child(6),
    th:nth-child(7),
    td:nth-child(5),
    td:nth-child(6),
    td:nth-child(7) {
      display: none;
    }
  }
`;

export const Card = styled.div`
  background-color: #fff;
  // width: 60%;
  width: ${(props) => (props.moreWidth ? "95%" : "80%")};
  flex: 0 0 auto;
  box-shadow: 0.5px 0.5px 0.5px 0 #979797;
  margin-right: 1rem;
  margin-top: 1rem;
  padding-bottom: 2rem;
  min-height: 244px;
  height: auto;
  // overflow: auto;
  @media (max-width: 900px) {
    width: 99%;
    margin: auto;
    margin-bottom: 1rem;
    height: auto;
  }
`;

export const ContactCard = styled(Card)`
  overflow-y: visible;
  width: 95%;
  @media (max-width: 900px) {
    width: 99%;
    margin: auto;
  }
`;

export const ProfileCard = styled.div`
  background-color: #fff;
  width: 90%;
  flex: 0 0 auto;
  box-shadow: 0.5px 0.5px 0.5px 0 #979797;
  margin-right: 1rem;
  margin-top: 1rem;
  padding-bottom: 2rem;
  height: 20rem;
  @media (max-width: 900px) {
    width: 99%;
    margin: auto;
    margin-top: 1rem;
    height: 30rem;
    margin-bottom: 1rem;
  }
`;
export const AccountButtonContainer = styled.div`
  justify-content: center;
`;
export const AccountTitleContainer = styled.div`
  display: flex;
  justify-content: center;
`;

export const AccountRow = styled.div`
  display: flex;
  // flex-direction: column;
  padding: 1rem;
  padding-bottom: 0.25rem;
  margin: 0;
  @media (max-width: 900px) {
    /* width: 99%; */
    margin: auto;
    flex-direction: column;
  }
`;

export const AcountSubRow = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0;
  @media (max-width: 900px) {
    /* width: 99%; */
    margin: auto;
    flex-direction: column;
  }
`;

export const AccountRowSmall = styled.div`
  display: flex;
  padding: 0 0 0 1rem;
  padding-top: 0;
  margin: 0;
`;

export const AccountTitle = styled.span`
  font-size: 1.25rem;
  font-weight: 700;
`;

export const AccountSubtitle = styled.span`
  font-size: 1rem;
  font-weight: 700;
`;

export const AccountInfo = styled.div`
  width: 75%;
  font-size: 1rem;
  margin-top: 0.5rem;
  margin-bottom: auto.5rem;
`;

export const AccountTitleRow = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 1rem;
  margin: 0;
  border-bottom: 1px solid #e0e0e0;
  width: 100%;
`;
export const AccountButtonRow = styled.div`
  display: flex;
  width: 100%;
  padding: 1rem 0.5rem 0rem 1rem;
  margin: 0;
  .bx--form-item {
    flex: inherit;
  }
`;

export const BorderRowContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
`;

export const BorderRow = styled.div`
  display: flex;
  height: 0.5rem;
  width: 95%;
  padding: 0 0 1rem 0rem;
  margin: 0;
  border-bottom: 1px solid #e0e0e0;
  .bx--toggle__switch {
    margin: 0;
  }
`;

export const ButtonRow = styled.div`
  display: flex;
  padding-left: 1rem;
  width: 100%;
  margin: 0;
  .bx--form-item {
    flex: inherit;
  }
  @media (min-width: 900px) {
    padding-left: 40%;
  }
`;

export const ButtonWrapper = styled.div`
  width: 50%;
  padding: 0.15rem;
  .bx--btn {
    min-height: 100%;
    min-width: 75%;
    max-width: 90%;
    text-align: center;
  }
`;

export const ButtonWrapperSave = styled.div`
  width: 100%;
  padding: 0.15rem;
  .bx--btn {
    min-height: 100%;
    min-width: 75%;
    max-width: 90%;
    text-align: center;
  }
`;

export const ButtonWrapperCancel = styled.div`
  width: 70%;
  padding: 0.15rem;
  .bx--btn {
    min-height: 100%;
    min-width: 75%;
    max-width: 90%;
    text-align: center;
  }
`;

export const ButtonWrapperSmall = styled.div`
  width: 25%;
  .bx--btn {
    min-height: 1rem;
    width: 100%;
    text-align: center;
    padding: 1rem;
  }
  @media (max-width: 900px) {
    width: 100%;
    display: flex;
    justify-content: center;
  }
`;

export const ImageContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  width: 15rem;
  height: 15rem;
  border: 1px solid #0f62fe;
  border-radius: 50%;
  overflow: auto;

  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;

  > svg {
    width: 85%;
    height: auto;
    border: 5px solid #0f62fe;
    border-radius: 50%;
    fill: white;
  }
  @media screen and (min-width: 900px) {
    width: 10rem;
    height: 10rem;
  }
`;

export const NotificationRowSmall = styled.div`
  display: flex;
  width: 95%;
  justify-content: space-between;
  padding: 1rem 1rem 1rem 1rem;
  margin: 0;
  .bx--toggle__switch {
    margin: 0;
  }
`;

export const NotificationRowBig = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 1rem;
  margin: 0;
  border-bottom: 1px solid #e0e0e0;
  width: 100%;
`;

export const NotificationInfo = styled.p`
  font-size: 1rem;
  width: 95%;
  font-weight: 700;
`;

export const NotificationInfoSecondaryLeft = styled.div`
  font-size: 1rem;
  width: 95%;
  font-weight: 500;
`;

export const NotificationInfoSecondary = styled.p`
  font-size: 1rem;
  width: 95%;
  text-align: right;
  font-weight: 500;
`;

export const StyledLink = styled(Edit20)`
  color: blue;
  padding-top: 0.25rem;
  vertical-align: center;
  height: auto;
  text-decoration: none;
  cursor: pointer;
`;

export const StyledCancelLink = styled(EditOff20)`
  color: blue;
  padding-top: 0.25rem;
  vertical-align: center;
  height: auto;
  text-decoration: none;
  cursor: pointer;
`;

export const StyleLink = styled(Link)`
  color: blue;
  padding-top: 0.25rem;
  vertical-align: center;
  height: auto;
  text-decoration: none;
  cursor: pointer;
`;

export const DeactivatedStyleLink = styled(StyleLink)`
  color: gray;
  cursor: not-allowed;
`;

export const TitleRow = styled.p`
  min-height: 5rem;
  padding-bottom: 2rem;
  width: 95%;
  flex: 0 0 auto;
  margin-right: 1rem;
  ${"" /* margin-left: 0.5rem; */}
  margin-top: 1.5rem;
  font-size: 2rem;
  font-weight: 500;
  @media (max-width: 900px) {
    text-align: center;
    margin: auto;
    margin-top: 1.5rem;
  }
`;

export const SubtitleRow = styled.p`
  min-height: 5rem;
  padding-bottom: 2rem;
  width: 75%;
  flex: 0 0 auto;
  margin-right: 1rem;
  ${"" /* margin-left: 0.5rem; */}
  margin-top: 1rem;
  font-size: 1.5rem;
  @media (max-width: 900px) {
    width: 100%;
    text-align: center;
    margin-bottom: 1rem;
  }
`;

export const SubtitleRowConsents = styled.p`
  min-height: 5rem;
  width: 75%;
  flex: auto;
  margin-right: 1rem;
  vertical-align: middle;
  ${"" /* margin-left: 0.5rem; */}
  font-size: 1.5rem;
  @media (max-width: 900px) {
    width: 100%;
    text-align: center;
    margin-bottom: 1rem;
  }
`;

export const SubtitleRowSmall = styled.p`
  min-height: 5rem;
  width: 75%;
  flex: auto;
  margin-right: 1rem;
  vertical-align: middle;
  ${"" /* margin-left: 0.5rem; */}
  margin-top: 1rem;
  font-size: 1.5rem;
`;

export const SubtitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  min-height: 5rem;
  padding-bottom: 2rem;
  width: 90%;
  flex: 0 0 auto;
  margin-right: 1rem;
  ${"" /* margin-left: 0.5rem; */}
  margin-top: 1rem;
  font-size: 1.5rem;
  @media (max-width: 900px) {
    flex-direction: column;
    width: 99%;
    margin: auto;
  }
`;

export const AccountGrid = styled.div`
  display: grid;
  grid-template-rows: 0rem 0rem auto auto;
  grid-template-columns: 50% 50%;
  grid-template-areas:
    "header header"
    "a b"
    "left-column right-column"
    "bottom-row bottom-row";
  margin-left: 10rem;
  .bx--modal-content {
    padding-right: 0;
  }
  @media (max-width: 900px) {
    display: grid;
    grid-template-rows: 0rem 0rem auto;
    grid-template-columns: 100%;
    grid-template-areas:
      "header"
      "a"
      "b"
      "left-column"
      "right-column"
      "bottom-row";
    margin: auto;
  }
`;

export const NotificationsGrid = styled.div`
  display: grid;
  grid-template-rows: 20rem 20rem;
  grid-template-columns: 100%;
  grid-template-areas: "a";
  margin-left: 10rem;
  .bx--modal-content {
    padding-right: 0;
  }
  @media (max-width: 900px) {
    display: grid;
    grid-template-rows: 0rem 0rem auto;
    grid-template-columns: 99%;
    grid-template-areas: "a";
    height: 100%;
    margin: auto;
    margin-bottom: 1rem;
  }
`;

export const ProfileGrid = styled.div`
  display: grid;
  grid-template-rows: auto;
  grid-template-columns: 90%;
  grid-template-areas: "a";
  margin-left: 8rem;
  .bx--modal-content {
    padding-right: 0;
  }
  @media (max-width: 900px) {
    display: grid;
    grid-template-rows: auto;
    grid-template-columns: 99%;
    grid-template-areas: "a";
    height: 100%;
    margin: auto;
    margin-bottom: 1rem;
  }
`;

export const CardGrid = styled.div`
  display: grid;
  grid-template-rows: 0rem 0rem auto;
  grid-template-columns: 50% 50%;
  grid-template-areas:
    "header header"
    "a b"
    "left-column right-column";

  .bx--modal-content {
    padding-right: 0;
  }
  @media (max-width: 900px) {
    display: grid;
    grid-template-rows: auto auto auto auto;
    grid-template-columns: 100%;
    grid-template-areas:
      "header"
      "a"
      "b"
      "left-column"
      "right-column";

    width: 100%;
  }
`;

export const SideNavigation = styled.div`
  background-color: ${(props) => props.theme.colors.primary.mediumBlue};
  span.bx--side-nav__link-text {
    color: white;
  }
  .bx--side-nav__navigation {
    background-color: ${(props) => props.theme.colors.primary.mediumBlue};
    color: white;
  }

  .bx--side-nav__link {
    color: white;
  }
  .bx--side-nav__icon > svg {
    fill: white;
  }
  .bx--side-nav__link:hover .bx--side-nav__icon > svg {
    fill: black;
  }
  .bx--side-nav__icon:hover > svg {
    fill: black;
  }

  .bx--side-nav {
    z-index: 0;
    height: calc(100vh - 3rem);
  }
`;

export const SingleColumn = styled.div`
  /* grid-area: center-column; */
  display: flex;
  flex-direction: column;
  @media (max-width: 900px) {
    margin-bottom: 1rem;
  }
`;

export const Spacer = styled.div`
  display: flex;
  height: 1rem;
`;
export const SpacerHalf = styled.div`
  display: flex;
  height: 0.5rem;
`;

export const LeftColumn = styled.div`
  grid-area: left-column;
  display: flex;
  flex-direction: column;
  @media (max-width: 900px) {
    width: 100%;
    margin: auto;
  }
`;

export const RightColumnMain = styled.div`
  grid-area: right-column;
  display: flex;
  flex-direction: column;
  @media (max-width: 900px) {
    width: 100%;
  }
  @media (min-width: 901px) {
    width: 100%;
    margin-top: 6.5rem;
  }
`;

export const RightColumn = styled.div`
  grid-area: right-column;
  display: flex;
  flex-direction: column;
  @media (max-width: 900px) {
    width: 100%;
  }
`;

export const StyledButtonRow = styled(ButtonSet)`
  margin-top: 1rem;
  margin-left: 1rem;
  @media (max-width: 900px) {
    width: 50%;
    margin-left: 0rem;
    padding: 0 1rem 0 1rem;
  }
`;

export const ContactRow = styled.div`
  grid-area: bottom-row;
  width: 100%;
  margin-top: 3rem;
  margin-bottom: 3rem;
`;

export const LoadingTextWrapper = styled.div`
  width: 70%;
  padding: 1rem;
`;

export const ImageLoading = styled(SkeletonPlaceholder)`
  height: 15rem;
  width: 15rem;
  @media screen and (min-width: 900px) {
    height: 10rem;
    width: 10rem;
  }
`;

export const Error = styled.h4`
  margin: auto;
  margin-top: 1rem;
  color: red;
`;

export const StyledModalBody = styled(ModalBody)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  @media (max-width: 900px) {
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    margin-top: 3rem;
  }
`;

export const StyledFileUploader = styled(FileUploader)`
  @media (max-width: 900px) {
    width: auto;
    margin-left: auto;
    margin-right: auto;
  }
`;

export const StyledSideNavLink = styled(SideNavLink)`
  cursor: pointer;
  &:hover {
    background-color: ${(props) =>
      props.theme.colors.accent.mediumBlue} !important;
  }
`;

export const ModalContentContainer = styled.div`
  padding-right: 2rem;
`;

export const QRCodeWrapper = styled.div`
  padding: 1rem;
`;

export const FlexBetweenInputs = styled.div`
  display: flex;
  gap: 1rem;
`;

export const QRCodeBulletin = styled.div`
  align-items: center;
  justify-content: center;
  text-align: center;
  display: flex;
  align-self: center;
  flex-direction: column;
  width: 50%;
  box-shadow: 0.5px 0.5px 0.5px 0 #979797;
  min-height: 40rem;
  height: auto;
  overflow: auto;
  @media (max-width: 900px) {
    width: 99%;
    margin: auto;
    height: auto;
  }
`;
