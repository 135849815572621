//[ ]TODO: This file needs to be modified for the Provider Side 05/17/23 
import { useEffect, useState } from "react";
import { useStrapi } from "../../hooks/useStrapi";

export const useDashboardCMS = () => {
  const init = {
    heading_demographic: "About Me",
    placeholder_search: "Search",
    care_team: {
      id: 1,
      heading_care_team: "My Care Team",
      btn_current: "Current",
      btn_past: "Past",
      btn_find_services: "Find Services",
      btn_create_referral: "Create Referral",
      tableheader_service_name: "Service Name",
      tableheader_organization: "Organization",
      tableheader_start_date: "Start Date",
      tableheader_end_date: "End Date",
      tableheader_reason: "Reason",
      tableheader_actions: "Actions",
      action_remove_care_team: "Remove Care Team",
      text_empty:
        "You don't have anyone on your Care Team! Click below to find providers who can help you.",
      placeholder_search: "Search",
      tableheader_open: "Open Referrals",
      tableheader_closed: "Closed Referrals",
    },
    referrals: {
      id: 1,
      heading_referrals: "Service Requests",
      btn_get_support: "Get Support",
      btn_open: "Open",
      btn_status: "Status",
      tableheader_service_name: "Service Name",
      tableheader_provider_name: "Provider Name",
      tableheader_type: "Type",
      tableheader_status: "Status",
      tableheader_date: "Date Created",
      tableheader_action: "Action",
      action_view: "View",
      text_empty:
        'You don\'t have referrals yet! Click "Create Service Request" to find the help you need.',
      placeholder_search: "Search",
      referral_status: {
        none: "None",
        pending: "Pending",
        accepted_by_referee: "Accepted By Referee",
        rejected_by_referee: "Rejected By Referee",
        working: "Working",
        waiting_for_client: "Waiting for Client",
        waiting_for_provider: "Waiting for Provider",
        rejected_by_receiver: "Rejected By Receiver",
        closed_resolved: "Closed - Resolved",
        closed_unable_to_contact: "Closed - Unable to Contact",
        closed_unable_to_resolved: "Closed - Unable to Resolve",
        closed_cancelled: "Closed - Cancelled",
        reopened: "Reopened",
        cancelled_by_referrer: "Cancelled By Referrer",
        completed: "Completed",
      },
    },
    check_in: {
      id: 1,
      heading_checkin: "Check-Ins",
      subheading_recommendations: "Recommendations",
      subheading_checkin_summary: "Check-In Summary",
      subheading_checkin_domains: "Check-In Domains",
      subheading_legend: "Legend",
      btn_take_checkin: "Take Check-In",
      btn_view_provider: "View Provider",
      btn_view_all: "View All Recommended Services",
      btn_print_results: "Print Results",
      btn_email_results: "Email Results",
      btn_past: "Past",
      btn_recent: "Recent",
      btn_view_checkin: "View Check-In",
      summary_positive_desc: "You are doing really well in these areas",
      summary_negative_desc: "It looks like you could use some support with",
      domains_positive_desc: "You are doing really well in these domains",
      domains_negative_desc: "It looks like you could use some support with",
      text_based_response:
        "Based on your responses we found local service providers who can help you.",
      label_no_answered: "No questions answered",
      tableheader_date: "Date",
      tableheader_action: "Action",
      tableheader_status: "Status",
      text_empty: "You have not yet taken any Check-In.",
      checkin_summary: {
        economic_opportunity: "Economic Opportunity",
        health_and_well_being: "Health and Wellbeing",
        education_opportunity: "Education Opportunity",
        safety_and_security: "Safety and Security",
      },
    },
    overview: {
      heading_current_priorities: "Current Priorities",
      heading_wellbeing: "My Well-being",
      heading_checkin_wellbeing: "Check-In Well-being",
      heading_estimated_wellbeing: "Estimated Well-being",
      text_no_related: "No related resources on your care team",
      text_no_response: "No response",
      btn_get_Data: "Get Data",
      btn_self_report: "Self Report",
      text_checkin_empty:
        'You have not checked in yet! Click "Check-in" to get started.',
      btn_update: "Update",
      tooltip_title: 'What is “estimated well-being?"',
      tooltip_body:
        "Based on information you’ve shared directly with MyWayfinder or given us permission to access, your estimated well-being is a calculated value ranging from “not okay” to “excellent.” The additional information helps you and your care team members to view your dashboard and know a bit more about your situation, without revealing specific details that you may not be comfortable sharing. Taken together, your check-in well-being and estimated well-being help provide a more complete picture so that service providers can better meet your needs.",
      text_last_completed:
        "When you last completed the MyWayfinder Check-in screening, you told us how you feel you are doing in different areas of your life. Sometimes it can help to organize what's most important to you right now, so that you and your Care Team can be on the same page. Click on a card and drag it into position to show what is your highest priority and what is your lowest priority.",
    },
    call_log: {
      heading: "211 Call Report",
      labal_call_id: "Call #",
      heading_recommended: "Recommended Services",
    },
    needs_request: {
      heading: "Needs Request Details",
      error_notification: "An error occured. Please try again later.",
      label_date: "Date",
      label_emailed: "Emailed to",
      label_message: "Message",
      status_label: "Update Status Dropdown",
      placeholder_search: "Search",
      status_dropdown: [
        {
          id: 20,
          text: "Pending",
          value: "Pending",
          key: "pending",
        },
        {
          id: 21,
          text: "Provider Responded",
          value: "Provider Responded",
          key: "provider_responded",
        },
        {
          id: 22,
          text: "Never Responded",
          value: "Never Responded",
          key: "never_responded",
        },
      ],
    },
    cn_request: {
      heading: "CN360 Request Details",
      error_notification: "An error occured. Please try again later.",
      label_date: "Request Submitted",
      status_label: "Update Status Dropdown",
      placeholder_search: "Search",
      status_dropdown: [
        {
          id: 20,
          text: "Pending",
          value: "Pending",
          key: "pending",
        },
        {
          id: 21,
          text: "Provider Responded",
          value: "Provider Responded",
          key: "provider_responded",
        },
        {
          id: 22,
          text: "Never Responded",
          value: "Never Responded",
          key: "never_responded",
        },
      ],
    },
    event_history: {
      date_time: "Date/Time",
      event_name: "Event Name",
      event_summary: "Event Summary",
      heading: "Event History",
      placeholder_search: "Search",
    },
    data_table: {
      previous_page: "Previous Page",
      next_page: "Next Page",
      items_per_page: "Items per page",
      page_number: "Page number",
      of_items: "[x] of [y] items",
      of_pages: "[x] of [y] pages",
    },
  };

  const { data, loading } = useStrapi({
    cms: `page-client-dashboard`,
  });

  const [dashboard, setDashboard] = useState(init);

  useEffect(() => {
    if (loading) {
      setDashboard(init);
      return;
    }
    setDashboard((state) => ({
      heading_demographic:
        data.heading_demographic || state.heading_demographic,
      care_team: {
        heading_care_team:
          data.care_team.heading_care_team || state.care_team.heading_care_team,
        btn_current: data.care_team.btn_current || state.care_team.btn_current,
        btn_past: data.care_team.btn_past || state.care_team.btn_past,
        btn_find_services:
          data.care_team.btn_find_services || state.care_team.btn_find_services,
        btn_create_referral:
          data.care_team.btn_create_referral ||
          state.care_team.btn_create_referral,
        tableheader_service_name:
          data.care_team.tableheader_service_name ||
          state.care_team.tableheader_service_name,
        tableheader_organization:
          data.care_team.tableheader_organization ||
          state.care_team.tableheader_organization,
        tableheader_actions:
          data.care_team.tableheader_actions ||
          state.care_team.tableheader_actions,
        tableheader_start_date:
          data.care_team.tableheader_start_date ||
          state.care_team.tableheader_start_date,
        tableheader_end_date:
          data.care_team.tableheader_end_date ||
          state.care_team.tableheader_end_date,
        tableheader_reason:
          data.care_team.tableheader_reason ||
          state.care_team.tableheader_reason,
        action_remove_care_team:
          data.care_team.action_remove_care_team ||
          state.care_team.action_remove_care_team,
        text_empty: data.care_team.text_empty || state.care_team.text,
        placeholder_search: data.placeholder_search || state.placeholder_search,
        tableheader_open:
          data.care_team.tableheader_open || state.care_team.tableheader_open,
        tableheader_closed:
          data.care_team.tableheader_closed ||
          state.care_team.tableheader_closed,
      },
      referrals: {
        heading_referrals:
          data.referrals.heading_referrals || state.referrals.heading_referrals,
        btn_get_support:
          data.referrals.btn_get_support ||
          state.referrals.btn_get_support,
        btn_open: data.referrals.btn_open || state.referrals.btn_open,
        btn_status: data.referrals.btn_status || state.referrals.btn_status,
        tableheader_service_name:
          data.referrals.tableheader_service_name ||
          state.referrals.tableheader_service_name,
        tableheader_provider_name:
          data.referrals.tableheader_provider_name ||
          state.referrals.tableheader_provider_name,
        tableheader_status:
          data.referrals.tableheader_status ||
          state.referrals.tableheader_status,
        tableheader_date:
          data.referrals.tableheader_date || state.referrals.tableheader_date,
        tableheader_action:
          data.referrals.tableheader_action ||
          state.referrals.tableheader_action,
        action_view: data.referrals.action_view || state.referrals.action_view,
        text_empty: data.referrals.text_empty || state.referrals.text_empty,
        placeholder_search: data.placeholder_search || state.placeholder_search,
        referral_status: {
          none:
            data.referrals.referral_status.none ||
            state.referrals.referral_status.none,
          pending:
            data.referrals.referral_status.pending ||
            state.referrals.referral_status.pending,
          accepted_by_referee:
            data.referrals.referral_status.accepted_by_referee ||
            state.referrals.referral_status.accepted_by_referee,
          rejected_by_referee:
            data.referrals.referral_status.rejected_by_referee ||
            state.referrals.referral_status.rejected_by_referee,
          working:
            data.referrals.referral_status.working ||
            state.referrals.referral_status.working,
          rejected_by_receiver:
            data.referrals.referral_status.rejected_by_receiver ||
            state.referrals.referral_status.rejected_by_receiver,
          closed_resolved:
            data.referrals.referral_status.closed_resolved ||
            state.referrals.referral_status.closed_resolved,
          closed_unable_to_contact:
            data.referrals.referral_status.closed_unable_to_contact ||
            state.referrals.referral_status.closed_unable_to_contact,
          closed_unable_to_resolved:
            data.referrals.referral_status.closed_unable_to_resolved ||
            state.referrals.referral_status.closed_unable_to_resolved,
          closed_cancelled:
            data.referrals.referral_status.closed_cancelled ||
            state.referrals.referral_status.closed_cancelled,
          reopened:
            data.referrals.referral_status.reopened ||
            state.referrals.referral_status.reopened,
          cancelled_by_referrer:
            data.referrals.referral_status.cancelled_by_referrer ||
            state.referrals.referral_status.cancelled_by_referrer,
          completed:
            data.referrals.referral_status.completed ||
            state.referrals.referral_status.completed,
        },
      },
      check_in: {
        heading_checkin:
          data.check_in.heading_checkin || state.check_in.heading_checkin,
        subheading_recommendations:
          data.check_in.subheading_recommendations ||
          state.check_in.subheading_recommendations,
        subheading_checkin_summary:
          data.check_in.subheading_checkin_summary ||
          state.check_in.subheading_checkin_summary,
        subheading_checkin_domains:
          data.check_in.subheading_checkin_domains ||
          state.check_in.subheading_checkin_domains,
        subheading_legend:
          data.check_in.subheading_legend || state.check_in.subheading_legend,
        btn_take_checkin:
          data.check_in.btn_take_checkin || state.check_in.btn_take_checkin,
        btn_view_provider:
          data.check_in.btn_view_provider || state.check_in.btn_view_provider,
        btn_view_all: data.check_in.btn_view_all || state.check_in.btn_view_all,
        btn_print_results:
          data.check_in.btn_print_results || state.check_in.btn_print_results,
        btn_email_results:
          data.check_in.btn_email_results || state.check_in.btn_email_results,
        btn_past: data.check_in.btn_past || state.check_in.btn_past,
        btn_recent: data.check_in.btn_recent || state.check_in.btn_recent,
        btn_view_checkin:
          data.check_in.btn_view_checkin || state.check_in.btn_view_checkin,
        summary_positive_desc:
          data.check_in.summary_positive_desc ||
          state.check_in.summary_positive_desc,
        summary_negative_desc:
          data.check_in.summary_negative_desc ||
          state.check_in.summary_negative_desc,
        domains_positive_desc:
          data.check_in.domains_positive_desc ||
          state.check_in.domains_positive_desc,
        domains_negative_desc:
          data.check_in.domains_negative_desc ||
          state.check_in.domains_negative_desc,
        text_based_response:
          data.check_in.text_based_response ||
          state.check_in.text_based_response,
        label_no_answered:
          data.check_in.label_no_answered || state.check_in.label_no_answered,
        tableheader_date:
          data.check_in.tableheader_date || state.check_in.tableheader_date,
        tableheader_action:
          data.check_in.tableheader_action || state.check_in.tableheader_action,
        tableheader_status:
          data.check_in.tableheader_status || state.check_in.tableheader_status,
        text_empty: data.check_in.text_empty || state.check_in.text_empty,
        checkin_summary: {
          economic_opportunity:
            data.check_in.checkin_summary.economic_opportunity ||
            state.check_in.checkin_summary.economic_opportunity,
          health_and_well_being:
            data.check_in.checkin_summary.health_and_well_being ||
            state.check_in.checkin_summary.health_and_well_being,
          education_opportunity:
            data.check_in.checkin_summary.education_opportunity ||
            state.check_in.checkin_summary.education_opportunity,
          safety_and_security:
            data.check_in.checkin_summary.safety_and_security ||
            state.check_in.checkin_summary.safety_and_security,
        },
      },
      overview: {
        heading_overview:
          data.overview.heading_overview || state.overview.heading_overview,
        heading_current_priorities:
          data.overview.heading_current_priorities ||
          state.overview.heading_current_priorities,
        heading_wellbeing:
          data.overview.heading_wellbeing || state.overview.heading_wellbeing,
        heading_checkin_wellbeing:
          data.overview.heading_checkin_wellbeing ||
          state.overview.heading_checkin_wellbeing,
        heading_estimated_wellbeing:
          data.overview.heading_estimated_wellbeing ||
          state.overview.heading_estimated_wellbeing,
        text_no_related:
          data.overview.text_no_related || state.overview.text_no_related,
        text_no_response:
          data.overview.text_no_response || state.overview.text_no_response,
        btn_get_Data: data.overview.btn_get_Data || state.overview.btn_get_Data,
        btn_self_report:
          data.overview.btn_self_report || state.overview.btn_self_report,
        text_checkin_empty:
          data.overview.text_checkin_empty || state.overview.text_checkin_empty,
        btn_update: data.overview.btn_update || state.overview.btn_update,
        tooltip_title:
          data.overview.tooltip_title || state.overview.tooltip_title,
        tooltip_body: data.overview.tooltip_body || state.overview.tooltip_body,
        text_last_completed:
          data.overview.text_last_completed ||
          state.overview.text_last_completed,
      },
      call_log: {
        heading: data.call_log.heading || state.call_log.heading,
        labal_call_id:
          data.call_log.labal_call_id || state.call_log.labal_call_id,
        heading_recommended:
          data.call_log.heading_recommended ||
          state.call_log.heading_recommended,
      },
      needs_request: {
        heading: data.needs_request.heading || state.needs_request.heading,
        error_notification:
          data.needs_request.error_notification ||
          state.needs_request.error_notification,
        label_date:
          data.needs_request.label_date || state.needs_request.label_date,
        label_emailed:
          data.needs_request.label_emailed || state.needs_request.label_emailed,
        label_message:
          data.needs_request.label_message || state.needs_request.label_message,
        status_label:
          data.needs_request.status_label || state.needs_request.status_label,
        status_dropdown:
          data.needs_request.status_dropdown ||
          state.needs_request.status_dropdown,
        placeholder_search: data.placeholder_search || state.placeholder_search,
      },
      cn_request: {
        heading: data?.cn_request?.heading || state.cn_request.heading,
        error_notification:
          data?.cn_request?.error_notification ||
          state.cn_request.error_notification,
        label_date:
          data?.cn_request?.label_date || state.cn_request.label_date,
        status_label:
          data?.cn_request?.status_label || state.cn_request.status_label,
        status_dropdown:
          data?.cn_request?.status_dropdown ||
          state.cn_request.status_dropdown,
        placeholder_search: data.placeholder_search || state.placeholder_search,
      },
      event_history: {
        date_time:
          data.event_history.date_time || state.event_history.date_time,
        event_name:
          data.event_history.event_name || state.event_history.event_name,
        event_summary:
          data.event_history.event_summary || state.event_history.event_summary,
        heading: data.event_history.heading || state.event_history.heading,
        placeholder_search: data.placeholder_search || state.placeholder_search,
      },
      data_table: {
        previous_page:
          data.data_table.previous_page || state.data_table.previous_page,
        next_page: data.data_table.next_page || state.data_table.next_page,
        items_per_page:
          data.data_table.items_per_page || state.data_table.items_per_page,
        page_number:
          data.data_table.page_number || state.data_table.page_number,
        of_items: data.data_table.of_items || state.data_table.of_items,
        of_pages: data.data_table.of_pages || state.data_table.of_pages,
      },
    }));
  }, [data]);

  const sidebarInit = {
    label_name: "Name",
    label_email: "Email",
    label_mobile: "Mobile",
    overview: "My Well-Being",
    demographic_data: "About Me",
    care_team: "My Care Team",
    service_requests: "Service Requests",
    check_ins: "Check-Ins",
    event_history: "Event History",
  };
  const { data: sidebarData, loading: sidebarLoading } = useStrapi({
    cms: `navigation-sidebar-dashboard`,
  });
  const [sidebar, setSidebar] = useState(sidebarInit);
  useEffect(() => {
    if (sidebarLoading) {
      setSidebar(sidebarInit);
      return;
    }
    setSidebar((state) => ({
      label_name: sidebarData.items.label_name || state.label_name,
      label_email: sidebarData.items.label_email || state.label_email,
      label_mobile: sidebarData.items.label_mobile || state.label_mobile,
      overview: sidebarData.items.overview || state.overview,
      demographic_data:
        sidebarData.items.demographic_data || state.demographic_data,
      care_teams: sidebarData.items.care_team || state.care_team,
      service_requests:
        sidebarData.items.service_requests || state.service_requests,
      check_ins: sidebarData.items.check_ins || state.check_ins,
      event_history: sidebarData.items.event_history || state.event_history,
    }));
  }, [sidebarData]);
  return { dashboard, sidebar };
};
