import styled from "styled-components";
import { Button } from "carbon-components-react";
import { ChevronRight24 } from "@carbon/icons-react";
import { useHistory } from "react-router";
import { useState, useEffect, useContext } from "react";
import kit from "ich-ui-kit";

const Complete = ({
  cms,
  feedbackResponse,
  providerId,
  providerName,
  employmentResponse,
  incomeResponse,
  transportationResponse,
  passClientInfo,
  setCompletedStatus
}) => {
  const history = useHistory();
  const { user, updateUser } = useContext(kit.UserContext);
  const { apiService } = useContext(kit.ApiContext);
  const [clientInfo, setClientInfo] = useState(passClientInfo)
  const [loading, setLoading] = useState(false);
  const [removeLoading, setRemoveLoading] = useState(false);

  useEffect(() => {
    if (typeof setCompletedStatus === "function") {
      setCompletedStatus(true)
    };
  }, []);

  return (
    <Wrapper>
      <TextWrapper style={{ width: "70%" }}>
        <h4>
          {cms.text_thank_you} {passClientInfo?.firstName && ` for ${passClientInfo?.firstName} ${passClientInfo?.lastName}`}!
        </h4>
      </TextWrapper>
      <InterfaceContainer>
        <TextWrapper style={{ width: "90%" }}>
          <p>{cms.text_helps_us}</p>
        </TextWrapper>
      </InterfaceContainer>
      <ButtonWrapper>
        <Button
          kind="secondary"
          onClick={() => {
            history.push({
              pathname: "/360Dashboard/overview",
              state: {
                client: passClientInfo?.id,
                ddincome: incomeResponse,
                ddemployment: employmentResponse,
                ddtransportation: transportationResponse
              }
            });
          }}
        >
          {cms.btn_return}
        </Button>
      </ButtonWrapper>
    </Wrapper>
  );
};

export default Complete;

const Wrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  padding-top: 2.5rem;
  padding-left: 1rem;
  padding-right: 8rem;
  @media screen and (min-height: 880px) and (min-width: 1800px) {
    padding-top: 4rem;
  }
  @media screen and (max-width: 600px) {
    padding: 0;
    padding-left: 1rem;
  }
`;

const ImageWrapper = styled.div`
  width: fit-content;
  height: fit-content;
  margin-top: 2rem;
  @media screen and (max-width: 600px) {
    margin-top: 1rem;
  }
`;

const TextWrapper = styled.div`
  /* width: 70%; */
  margin-top: 1rem;
  p {
    font-size: 17px;
    font-weight: 400;
    margin-bottom: 1rem;
  }
  h4 {
    font-weight: 500;
    line-height: 1.3;
  }
  @media screen and (min-height: 880px) and (min-width: 1600px) {
    p {
      font-weight: 400;
      font-size: 20px;
    }
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  width: 90%;
  justify-content: space-between;
  /* margin-top: 7rem; */
  padding-right: 1rem;
  @media screen and (max-width: 635px) {
    flex-direction: column;
    height: 17%;
  }
`;
const StyledButton = styled(Button)`
  padding-right: 12px;
`;

const InterfaceContainer = styled.div`
  display: flex;
  padding-top: 2rem;
  margin-bottom: 2rem;
  height: 30%;
  @media screen and (min-height: 880px) and (min-width: 1800px) {
    height: 45%;
  }
  @media screen and (max-width: 600px) {
    height: 35%;
  }
`;

const StyledChevronIcon = styled(ChevronRight24)`
  margin-left: 2rem;
  height: 20px;
`;
