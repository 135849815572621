import styled from "styled-components";
import {
  Content,
  HeaderMenuItem,
  SwitcherItem,
  HeaderPanel
} from "carbon-components-react";
import { Link } from "react-router-dom";

export const UIShellContainer = styled.div`
  position: fixed;
`;

export const StyledLink = styled(Link)`
  color: white;
`;

export const StyledContent = styled(Content)`
  /* padding-left: 2rem; */
`;

export const CustomHeaderPanel = styled(HeaderPanel)`
  background: ${(props) => props.theme.colors.primary.darkBlue};
  bottom: auto;
  .bx--header-panel--expanded {
    /* width: 20rem; */
    height: 20rem;
  }
  .bx--switcher__item--divider {
    /* width: 18rem; */
  }
`;

export const CustomSwitcherItem = styled(SwitcherItem)`
  height: 6.5rem;
  color: #ffffff;
  .bx--switcher__item-link {
    height: 100%;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
  }
  & > a:focus {
    outline: none;
  }
  & > a:hover {
    background-color: inherit !important;
    cursor: default !important;
  }
`;

export const StyledHeaderMenuItem = styled(HeaderMenuItem)`
  color: #ffffff !important;
  cursor: pointer;
  & > a:hover {
    background-color: ${(props) =>
      props.theme.colors.primary.mediumBlue} !important;
  }
`;

export const ProviderImageContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  border: 2px solid #fff;
  border-radius: 50%;
  overflow: auto;
  @media screen and (max-width: 670px) {
    width: 6rem;
    height: 6rem;
  }
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;

  > svg {
    width: 85%;
    height: auto;
    border: 1px solid #0f62fe;
    border-radius: 50%;
    fill: white;
  }
`;
