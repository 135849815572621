import styled from "styled-components";

export const ScrollContainer = styled.div`
  width: inherit;
  position: relative;
  overflow-y: auto;
  overflow-x: hidden;
  z-index: 0;
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start;
`;

export const Container = styled.div`
  position: relative;
  // margin: 2rem auto;
  display: flex;
  margin: 0 auto;
  flex-flow: column;
  width: 100%;
  height: 100%;
  max-width: 1500px;
  background-color: #f3f3f3;
  align-items: center;
  @media only screen and (min-width: 375px) {
  }
  @media only screen and (min-width: 540px) {
  }
  @media only screen and (min-width: 768px) {
  }
  @media only screen and (min-width: 1280px) {
    display: grid;
    grid-template-columns: 1.25fr 1.75fr;
    grid-column-gap: 1rem;
    align-items: flex-start;
  }
`;

export const Resources = styled.div`
  width: 100%;
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.2);
  padding: 2rem 0.5rem;
  margin-bottom: 2rem;
`;

export const Content = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(1fr);
  grid-template-rows: auto;
  place-items: center;
  grid-gap: 1rem;
  @media only screen and (min-width: 640px) {
    grid-gap: 1rem;
    grid-template-columns: repeat(auto-fill, minmax(275px, 1fr));
  }

  @media only screen and (min-width: 1280px) {
    align-items: start;
    grid-gap: 1rem;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  }
`;

export const Card = styled.div`
  width: 100%;
  max-width: 400px;
  padding: 1.5rem 0.5rem;
  height: auto;
  border: 1px solid rgba(0, 0, 0, 0.2);
  box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.1);
  background: #ffffff;
  display: flex;
  flex-flow: column nowrap;
  @media only screen and (min-width: 640px) {
    max-width: 350px;
  }
  @media only screen and (min-width: 1280px) {
  }
`;

export const CardAddressWrapper = styled.div`
  line-height: 0.8;
`;

export const CardStreetText = styled.span`
  font-size: 12px;
`;

export const CardCityStateText = styled.span`
  font-size: 10px;
  color: #A9A9A9;
`;

export const Header = styled.div`
  margin: 1.5rem 0;
  padding: 0 1rem;
  width: 100%;
`;

export const CardBody = styled.div`
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  margin-bottom: 1rem;
  @media ${(props) => props.theme.breakpoints.xxs} {
    flex-flow: row nowrap;
    margin-bottom: 1.75rem;
  }
`;
export const Profile = styled.div`
  border-radius: 100%;
  margin-right: 1rem;
  flex-shrink: 0;
  background: #0f61fd;
  width: 80px;
  height: 80px;
  @media only screen and (min-width: 540px) and (max-width: 767px) {
    margin-right: 2rem;
  }
`; // to be replaced with image

export const Heading = styled.h1`
  font-size: clamp(1rem, 7vw, 1.75rem);
  font-weight: 700;
  margin-bottom: 1rem;
`;

export const ProviderHeading = styled.h1`
  font-weight: 700;
  font-size: 1rem;
  text-align: left;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: auto;
  @media ${(props) => props.theme.breakpoints.xxs} {
    width: 160px;
  }
  @media only screen and (min-width: 540px) and (max-width: 767px) {
    width: 180px;
  }
`;

export const TextContainer = styled.p``;
export const TextSmall = styled.p``;

export const Text = styled.p`
  color: #636363;
  font-size: clamp(0.75rem, 7vw, 1rem);
`;

export const Summary = styled.div`
  background: #ffffff;
  width: 100%;
  padding: 1rem;
  margin: 2rem 0;
  border: 1px solid rgba(0, 0, 0, 0.2);
  @media only screen and (min-width: 375px) {
  }
  @media only screen and (min-width: 540px) {
  }
  @media only screen and (min-width: 768px) {
  }
  @media only screen and (min-width: 1280px) {
    padding: 2rem 1rem;
    margin-top: 0;
  }
`;

export const SummaryText = styled.p``;

export const SummaryBtnGroup = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 1.5rem 0 0.5rem;
`;

export const ResultContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  flex-flow: row wrap;
  margin-bottom: 3rem;
  @media only screen and (min-width: 1024px) {
    align-items: flex-start;
  }
`;

export const Circle = styled.div`
  background-color: ${(props) => props.theme.color};
  border-radius: 100%;
  margin: 1rem;
  width: 75px;
  height: 75px;
`;

export const LeftPanel = styled.div`
  width: 100%;
  display: flex;
  flex-flow: column nowrap;
`;

//Fix Spacing for Resources and Summary Containers
export const LegendsContainer = styled.div`
  background: #ffffff;
  margin-bottom: 1rem;
  padding: 0.75rem;
  border: 1px solid rgba(0, 0, 0, 0.2);
  display: flex;
  flex-flow: column nowrap;
`;

export const LegendsContent = styled.div`
  display: flex;
  flex-flow: column wrap;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 3rem;
`;
export const Legends = styled.div`
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
`;
export const LegendsCircle = styled.div`
  width: 1rem;
  height: 1rem;
  border-radius: 100%;
  background-color: ${(props) => props.theme.color};
  margin-bottom: 0.5rem;
`;

export const LegendCircle = styled.div`
  background-color: ${(props) => props.theme.bcolor};
  color: white;
  border-radius: 100%;
  padding: 0.6rem;
  margin: 0.25rem 0.35rem 0.25rem 0rem;
  width: 10px;
  height: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  @media screen and (max-width: 768px) {
    margin: 0rem 0.25rem 0.15rem 0rem;
    width: 7px;
    height: 7px;
  }
`;

export const LegendsHeading = styled.h1`
  font-weight: 700;
  font-size: 1.25rem;
  margin-bottom: 1rem;
`;

export const LegendsText = styled.p`
  font-weight: 500;
  font-size: clamp(0.5rem, 4vw, 1rem);
`;
