import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-flow: column nowrap;
  text-align: left;
  margin-top: 2rem;
  @media (max-height: 900px) {
    padding-bottom: 5rem;
  }
`;
