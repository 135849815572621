import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  position: relative;
  /* background: paleturquoise; */
  padding: 1rem;
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start;
`;
export const Header = styled.div`
  margin: 1.5rem 0 2rem;
`;

export const FormOuterDiv = styled.div`
  width: 100%;
  align-self: center;
  display: flex;
  justify-content: space-around;
  background: #ffffff;
  @media (max-width: 900px) {
    flex-direction: column;
    margin: auto;
  }
`;

export const FormInnerDiv = styled.div`
  display: flex;
  padding: 1rem 1rem;
  flex-direction: column;
  width: 100%;
  @media (max-width: 900px) {
    width: 100%;
  }
`;

export const Content = styled.div`
  align-self: center;
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.1);
  width: clamp(300px, 90%, 1300px);
  display: grid;
  grid-template-areas:
    "personal contact"
    "personal emergency";
  grid-template-rows: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  @media (max-width: 800px) {
    grid-template-areas:
      "personal"
      "contact"
      "emergency";
    grid-template-rows: repeat(3, auto);
    grid-template-columns: 1fr;
  }
`;
export const Row = styled.div`
  display: flex;
  @media (max-width: 800px) {
    flex-flow: column nowrap;
  }
`;
export const PersonalContainer = styled.div`
  grid-area: personal;
`;
export const ContactContainer = styled.div`
  grid-area: contact;
`;
export const EmergencyContainer = styled.div`
  grid-area: emergency;
`;

export const HeadingSM = styled.h6`
  font-weight: 700;
  margin-bottom: 0.5rem;
`;

export const HeadingMD = styled.h4`
  font-weight: 700;
  margin: 1rem 1rem;
  padding-bottom: 1rem;
  border-bottom: 1px solid #000;
`;
export const HeadingLG = styled.h3`
  font-weight: 700;
`;
export const Text = styled.p``;
export const Link = styled.a`
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: 0;
  text-decoration: none;
`;
export const FieldSet = styled.div`
  width: 100%;
  text-align: start;
  margin-bottom: 1rem;
  padding: 0 1rem;
`;

export const FormTextAreaDiv = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
  @media (max-width: 900px) {
    flex-direction: column;
  }
`;

export const LabelText = styled.span`
  font-size: 0.75rem;
  color: #161616;
  font-weight: 500;
  letter-spacing: 0.5px;
  margin: 0.3rem 0;
`;

export const GroupDiv = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 1rem;
  @media (max-width: 900px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;