import styled from "styled-components";
import { Button } from "carbon-components-react";

export const ReferralButton = styled(Button)`
  margin-right: 1rem;
  border: 1px solid ${(props) => props.theme.colors.accent.mediumBlue};
`;

export const FilterDiv = styled.div`
  position: relative;
  display: flex;
`;
