import styled from "styled-components";
import { Button, Loading } from "carbon-components-react";

export const ViewButton = styled(Button)`
  margin-right: 1rem;
  @media screen and (max-width: 768px) {
    font-size: 12px;
    min-height: 0.5rem;
    padding: 6px 24px 6px 12px;
    margin-right: 1rem;
    margin-left: -0.5rem;
  }
`;

export const ScrollContainer = styled.div`
  width: 100%;
  z-index: 0;
  padding: 1rem 2rem 2rem 2rem;
  max-width: 1366px;
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start;
  overflow-x: hidden;
  @media screen and (max-width: 768px) {
    padding: 0rem;
  }

  @media screen and (min-width: 1024px) {
    .bx--loading-overlay {
      width: 120%;
    }
  }
`;

export const CheckinEmpty = styled.div`
  background-color: white;
  border: 1px solid rgba(0, 0, 0, 0.2);
  width: 100%;
  height: 200px;
  padding: 4rem 0rem;
  display: flex;
  justify-content: center;
  flex-flow: column;
  align-items: center;
`;

export const Container = styled.div`
  position: relative;
  margin: auto;
  display: flex;
  flex-flow: column;
  width: 100%;
  max-width: 1366px;
  background-color: #f3f3f3;
  align-items: center;
  @media only screen and (min-width: 375px) {
  }
  @media only screen and (min-width: 540px) {
  }
  @media only screen and (min-width: 768px) {
  }
  @media only screen and (min-width: 1280px) {
    display: grid;
    grid-template-columns: 1.75fr 1.25fr;
    grid-column-gap: 1rem;
    align-items: flex-start;
  }
`;

export const PContent = styled.div`
  position: relative;
  margin-bottom: 1rem;
  width: 100%;
  display: flex;
  flex-direction: row;
  @media screen and (max-width: 768px) {
    margin-bottom: 0rem;
  }
`;
export const WContent = styled.div`
  position: relative;
  margin: 0rem 0rem 0rem 0rem;
  width: 100%;
  max-width: 100%;
  @media screen and (max-width: 768px) {
    width: 100%;
    margin: 0;
  }
`;

export const ScrollButton = styled(Button)`
  height: 25px;
  width: 30px;
  justify-content: center;
  padding: 0;
  margin: auto 0.5rem;
  .bx--btn--secondary {
    background-color: rgba(0, 145, 179, 1);
  }
  @media screen and (max-width: 768px) {
    width: 25px;
    max-height: 20px;
    margin: auto 0.25rem;
  }
  .bx--btn:disabled {
    background-color: #c6c6c6 !important;
  }
`;

export const Header = styled.div`
  margin: 1rem 0.5rem;
  display: flex;
  justify-content: space-between;
  @media screen and (max-width: 768px) {
    margin: 1.5rem 0rem 0.5rem 2rem;
    h3 {
      font-size: 24px;
    }
  }
  @media screen and (max-width: 500px) {
    margin: 1.5rem 0rem 0.5rem 2rem;
    h3 {
      font-size: 24px;
    }
  }
`;

export const SummaryText = styled.p`
  text-align: center;
  justify content: center;
`;

export const DomainContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-flow: row;
  // overflow-y: hidden;
  height: auto;
  @media only screen and (min-width: 1024px) {
    align-items: flex-start;
  }
`;

export const DropContainer = styled.div`
  // display: flex;
  // justify-content: flex-start;
  height: auto;
  flex-flow: row;
  @media only screen and (min-width: 1024px) {
    align-items: flex-start;
  }
`;

export const CardContainer = styled.div`
  // margin: 1rem;
  // padding: 10px;
  // background-color: ${(props) => props.theme.border && "rgba(0, 0, 0, 0.1)"};
  width: 50rem;
  @media only screen and (max-width: 768px) {
    width: 30rem;
    margin: 0rem 1rem;
  }
  @media only screen and (max-width: 500px) {
    width: 30rem;
    margin: 0rem 0rem;
  }
`;

export const Card = styled.div`
  /* height: 5rem; */
  font-size: 0.7rem;
  padding: 0 0 0 1rem;
  border: 1px solid rgba(0, 0, 0, 0.12);
  box-shadow: 4px 4px 6px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-flow: row;
  justify-content: left;
  align-items: left;
  background-color: white;
  &:hover {
    background-color: #e5e5e5;
  }
  @media only screen and (max-width: 768px) {
    margin: 0rem 1rem;
    width: 30rem;
    padding: 0.5rem 0 0 0.5rem;
  }
  @media only screen and (max-width: 500px) {
    margin: 0rem 0rem;
    width: 30rem;
    padding: 0.5rem 0 0 0.5rem;
  }
`;

export const HeaderCard = styled.div`
  height: 4rem;
  font-size: 0.7rem;
  padding: 1rem 0 0 1rem;
  border: 1px solid rgba(0, 0, 0, 0.12);
  box-shadow: 4px 4px 6px rgba(0, 0, 0, 0.1);
  // text-align: center;
  display: flex;
  flex-flow: row;
  justify-content: left;
  align-items: left;
  background-color: white;
  @media only screen and (max-width: 768px) {
    margin: 0rem 1rem;
    width: 30rem;
    padding: 1rem 0 0 0.5rem;
  }
  @media only screen and (max-width: 500px) {
    margin: 0rem 0rem;
    width: 30rem;
    padding: 1rem 0 0 0.5rem;
  }
`;

export const Circle = styled.div`
  background-color: ${(props) => props.theme.color};
  border-radius: 100%;
  padding: 0.5rem;
  margin: 0rem 0rem 0.5rem 0rem;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Img = styled.img`
  width: 4rem;
  height: 4rem;
`;

export const FlipCard = styled.div`
  width: 9rem;
  height: 8rem;
  margin: 0rem;
  font-size: 0.75rem;
  line-height: 1rem;
  border: 1px solid rgba(0, 0, 0, 0.2);
  box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.1);
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => props.theme.bcolor};
  &:hover {
    background-color: #e5e5e5;
    border-color: #e5e5e5;
    border: 1px solid rgba(0, 0, 0, 0.2);
  }
`;

export const FlipCardContainer = styled.div`
  width: 9rem;
  height: 8rem;
  margin: 0.5rem;
`;

export const WellbeingContainer = styled.div`
  height: auto;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  max-width: 900px;
  @media screen and (max-width: 768px) {
    width: 500px;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  max-width: 900px;
  justify-content: center;
`;

export const OuterContent = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  max-width: 900px;
  height: 100%;
  justify-content: center;
`;

export const InnerContent = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
`;

export const DomainGroup = styled.div`
  margin-bottom: 1.5rem;
  padding: 0.5rem;
  border: 1px solid rgba(0, 0, 0, 0.2);
  box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.1);
  background-color: ${(props) => props.theme.bcolor + "90"};
`;

export const DomainGroupM = styled.div`
  margin-bottom: 1.5rem;
  margin: 0 1rem 1.5rem 1rem;
  padding: 0.5rem;
  border: 1px solid rgba(0, 0, 0, 0.2);
  box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.1);
  background-color: ${(props) => props.theme.bcolor + "90"};
`;

export const TitleText = styled.div`
  text-align: center;
  font-size: 0.9rem;
  font-weight: 500;
`;

export const DomainCard = styled.div`
  width: 7.5rem;
  height: 7rem;
  margin: 0.1rem 0.15rem 0.1rem 0.1rem;
  padding: 0.25rem;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border: ${(props) => props.theme.border}px solid rgba(0, 0, 0, 0.1);
  background-color: ${(props) => props.theme.bcolor};
  &:hover {
    border-color: #e5e5e5;
    cursor: pointer;
  }
  @media and (max-width: 768px) {
    height: 7rem;
    width: 7.5rem;
    padding: 0.25rem;
  }
  @media screen and (max-width: 600px) {
    height: 6rem;
    width: 7rem;
    padding: 0.25rem;
  }
  @media screen and (max-width: 500px) {
    height: 6rem;
    width: 7.5rem;
    padding: 0.25rem;
  }
  @media screen and (max-width: 375px) {
    height: 6rem;
    width: 5rem;
    padding: 0.25rem;
  }
`;

export const InfoCardWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  max-width: 500px;
  @media screen and (max-width: 768px) {
    width: auto;
    align-items: left;
    margin: 0;
  }
`;

export const SummaryContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  max-width: 500px;
  justify-content: start;
  width: auto;
  margin: 0rem auto;

  @media screen and (max-width: 1024px) {
    width: auto;
    margin: 0rem auto;
    justify-content: center;
    align-items: center;
    margin: auto;
  }

  @media screen and (max-width: 767px) {
    width: auto;
    margin: 0rem auto;
    justify-content: center;
    align-items: center;
  }
  @media screen and (max-width: 600px) {
    border: 1px solid rgba(0, 0, 0, 0.2);
  }
  @media screen and (max-width: 500px) {
    border: none;
  }
`;

export const GroupWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  margin-bottom: 1.5rem;
  justify-content: center;
  border: 1px solid rgba(0, 0, 0, 0.2);
  box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.1);
`;
export const ButtonTextWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

export const StyledButton = styled(Button)`
  align-self: right;
  padding-right: 10px;
  min-height: 2rem;
  height: 2rem;
  margin: 1rem 1rem 1rem auto;
  @media screen and (min-width: 767px) {
    height: 2.5rem;
  }
  @media screen and (min-width: 1023px) {
    height: 3rem;
  }
`;

export const LegendCircle = styled.div`
  background-color: ${(props) => props.theme.bcolor};
  border-radius: 100%;
  padding: 0.6rem;
  margin: 0.25rem 0.35rem 0.25rem 0rem;
  width: 10px;
  height: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 14px;
  @media screen and (max-width: 768px) {
    margin: 0rem 0.25rem 0.15rem 0rem;
    width: 7px;
    height: 7px;
  }
`;

export const LegendWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-left: 0rem;
  margin-bottom: 1rem;
  @media screen and (max-width: 768px) {
    margin-left: 1rem;
  }
`;

export const LegendText = styled.p`
  margin-right: 1rem;
  @media screen and (max-width: 768px) {
    font-size: 0.6rem;
    margin-right: 0.5rem;
  }
`;

export const RowBlock = styled.p`
  margin-right: 0.5rem;
  max-height: 5rem;
  // overflow-y: scroll;
  width: ${(props) => (props.theme.width ? props.theme.width : "5rem")};
  @media screen and (max-width: 768px) {
    margin-right: 0.5rem;
    width: 3rem;
    overflow-x: scroll;
    font-size: 13px;
  }
  @media screen and (max-width: 500px) {
    margin-right: 0rem;
    width: 3rem;
    font-size: 11px;
  }
`;

export const RowBlockDomain = styled.p`
  margin-right: 0.5rem;
  max-height: 5rem;
  // overflow-y: scroll;
  width: ${(props) => (props.theme.width ? props.theme.width : "5rem")};
  @media screen and (max-width: 768px) {
    margin-right: 0.5rem;
    width: 5rem;
    overflow-x: scroll;
    font-size: 13px;
  }
  @media screen and (max-width: 500px) {
    width: 4rem;
    font-size: 11px;
  }
`;

export const RowBlockHeader = styled.p`
  margin-right: 0.5rem;
  max-height: 5rem;
  // overflow-y: scroll;
  width: ${(props) => (props.theme.width ? props.theme.width : "5rem")};
  @media screen and (max-width: 768px) {
    font-size: 12px;
    margin-right: 0.5rem;
    width: 3rem;
    overflow-x: scroll;
  }
  @media screen and (max-width: 500px) {
    margin-right: 0;
    width: 3rem;
    font-size: 11px;
  }
`;

export const RowBlockHeaderDomain = styled.p`
  margin-right: 0.5rem;
  max-height: 5rem;
  // overflow-y: scroll;
  width: ${(props) => (props.theme.width ? props.theme.width : "5rem")};
  @media screen and (max-width: 768px) {
    font-size: 12px;
    margin-right: 0.5rem;
    width: 5rem;
    overflow-x: scroll;
  }
  @media screen and (max-width: 500px) {
    width: 4rem;
    font-size: 11px;
  }
`;

export const RowBlockHeaderCheckin = styled.p`
  margin-right: 0.5rem;
  max-height: 5rem;
  // overflow-y: scroll;
  width: ${(props) => (props.theme.width ? props.theme.width : "5rem")};
  @media screen and (max-width: 768px) {
    font-size: 12px;
    margin-right: 0.5rem;
    width: 5rem;
    overflow-x: scroll;
  }
  @media screen and (max-width: 768px) {
    margin-right: 0;
    width: 4rem;
  }
`;

export const RowBlockCheckin = styled.p`
  margin-right: 0.5rem;
  max-height: 5rem;
  // overflow-y: scroll;
  display: flex;
  width: ${(props) => (props.theme.width ? props.theme.width : "5rem")};
  @media screen and (max-width: 768px) {
    width: 5rem;
    font-size: 13px;
  }
  @media screen and (max-width: 500px) {
    margin-right: 0;
    width: 3.5rem;
    flex-direction: column;
    font-size: 11px;
  }
`;

export const RowBlockEstimated = styled.p`
  margin-right: 0.5rem;
  max-height: 5rem;
  // overflow-y: scroll;
  width: ${(props) => (props.theme.width ? props.theme.width : "5rem")};
  @media screen and (max-width: 768px) {
    width: 5rem;
    font-size: 13px;
  }
  @media screen and (max-width: 500px) {
    margin-right: 0;
    width: 4.5rem;
    flex-direction: column;
    font-size: 11px;
  }
`;

export const FlexBox = styled.div`
  display: flex;
  @media screen and (max-width: 500px) {
    flex-direction: column;
  }
`;

export const RowBlockResources = styled.p`
  margin-right: 0.5rem;
  min-height: 5rem;
  height: auto;
  width: 10rem;
  overflow: hidden;
  width: 20rem;
  display: flex;
  flex-direction: column;
  @media screen and (max-width: 768px) {
    width: 10rem;
    font-size: 13px;
  }
  @media screen and (max-width: 500px) {
    margin-right: 0;
    width: 6rem;
    font-size: 12px;
  }
`;

export const RowBlockResourcesHeader = styled.p`
  margin-right: 0.5rem;
  max-height: 5rem;
  width: 10rem;
  // overflow-y: scroll;
  width: 20rem;
  display: flex;
  flex-direction: column;
  @media screen and (max-width: 768px) {
    width: 10rem;
    font-size: 12px;
  }
  @media screen and (max-width: 500px) {
    margin-right: 0;
    width: 6rem;
    font-size: 12px;
  }
`;

export const RowBlockCircle = styled.div`
  background-color: ${(props) => props.theme.bcolor};
  border-radius: 100%;
  padding: 0.5rem;
  margin: 0.3rem 0.35rem 0.25rem 0rem;
  width: 10px;
  height: 10px;
  display: flex;

  @media screen and (max-width: 768px) {
    margin: 0rem 0.25rem 0.15rem 0rem;
    width: 7px;
    height: 7px;
  }
  @media screen and (max-width: 500px) {
    padding: 0.35rem;
    width: 5px;
    height: 5px;
  }
`;

export const PriorityUpdatedWrapper = styled.div`
  display: flex;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  flex-direction: row;
  column-gap: 0.5rem;
`;
export const PriorityUpdatedText = styled.div`
  font-weight: bold;
  color: rgba(0,145,179,1);
`;
