import ElasticsearchAPIConnector from "@elastic/search-ui-elasticsearch-connector";
import { recommendedResourceSearchConfig, navigationCenterSearchConfig, baseSearchConfig, onlyZipSearchConfig } from "./templates";

const host = `${process.env.REACT_APP_API_ENDPOINT}/search`;

const index = process.env.REACT_APP_SEARCH_INDEX || 'resources-dev';

const connector = new ElasticsearchAPIConnector(
  {
    host,
    index
  },
  (requestBody, requestState, queryConfig) => {
    // NOTE: request can be customized here before sending, but this can be brittle and should be used sparingly

    const [term, zip] = requestState?.searchTerm?.split(/<zip>(.*?)<\/zip>/g);

    if (requestState?.searchTerm === '' && queryConfig?.recommendedResourceIds?.length) {
      // Search only for recommended resources
      requestBody.query = recommendedResourceSearchConfig({ recommendedResourceIds: queryConfig?.recommendedResourceIds });

    } else if (requestState?.searchTerm === '') {
      // Search only for navigation centers
      requestBody.query = navigationCenterSearchConfig();

    } else if (term?.trim() === '' && !!zip) {
      requestBody.query = onlyZipSearchConfig({ searchTerm: requestState?.searchTerm });
    } else {
      // Applied to all other searches
      requestBody.query = baseSearchConfig({ searchTerm: requestState?.searchTerm });
    }

    // Apply locale to query
    if (requestBody?.query && queryConfig?.locale) {
      requestBody.query.locale = queryConfig.locale || 'en';
    }

    // Apply user's geolocation to query (if available)
    if (requestBody?.query && queryConfig?.geolocation?.lat && queryConfig?.geolocation?.long) {
      requestBody.query.geolocation = queryConfig?.geolocation;
    }

    if (!requestState.searchTerm) return requestBody;
    return requestBody;
  }
)

export default connector;
