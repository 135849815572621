import React, { useState } from "react";
import { FilterDropdown } from "./FilterDropdown";
import * as S from "./filter.styled";

export const Filters = ({ handleFilterClick, activeFilters }) => {
  const [showDropdown, setShowDropdown] = useState(false);
  return (
    <S.FilterDiv>
      <span style={{ position: "relative" }}>
        <S.ReferralButton
          id="open"
          kind={activeFilters.open === true ? "secondary" : "menu"}
          onClick={() => handleFilterClick({ value: "open", text: "Status" })}
        >
          Open
        </S.ReferralButton>
      </span>
      <span style={{ position: "relative" }}>
        <S.ReferralButton
          id="closed"
          kind={activeFilters.status !== "" ? "secondary" : "menu"}
          onClick={() => handleFilterClick({ value: "closed", text: "Closed" })
          }
        >
          Closed
        </S.ReferralButton>
      </span>
    </S.FilterDiv>
  );
};
