import {
  ArrowRight16,
  Menu24,
  Notification24,
  ShoppingCart24,
  User24,
} from "@carbon/icons-react";
import {
  HeaderGlobalAction,
  HeaderGlobalBar,
  HeaderPanel,
  Loading,
  Modal,
  Switcher,
  SwitcherDivider,
  Tooltip,
} from "carbon-components-react";
import kit from "ich-ui-kit";
import React, { useContext, useEffect, useState, useRef } from "react";
import { useHistory, useLocation, Link } from "react-router-dom";
import AnonymousFooter from "../../components/anonymousFooter/footer";
import { useComponentVisible } from "../../hooks/useComponentVisible";
import { ActivityFeedContext } from "../../store/ActivityFeedContext";
import { CallLogContext } from "../../store/CallLogContext";
import { NavContext } from "../../store/NavContext";
import { NotificationBadge, FeedbackNotification } from "./components";
import CallLogPanel from "./components/call-log-panel";
import { MobileNav } from "./components/MobileNav";
import {
  CustomHeaderPanel,
  CustomSwitcherItem,
  ProviderImageContainer,
  StyledContent as Content,
  StyledHeaderMenuItem,
  UIShellContainer,
} from "./components/static";
import { capitalizeFirstLetterMultiple, truncateString } from "../../utils";
import * as S from "./uishell.styled";

const { REACT_APP_DISABLE_FEEDBACK_FEATURE } = process.env;
const showPasswordExpiry = false;

const UIShell = ({ channel, children }) => {
  const { isComponentVisible, setIsComponentVisible, ref } =
    useComponentVisible(false);
  // Contexts
  const { user, clearUser, updateUser } = useContext(kit.UserContext);
  const { apiService } = useContext(kit.ApiContext);
  const { numberUnread, subscribeToActivityFeed, getTotalUnread } =
    useContext(ActivityFeedContext);
  const { mobileNavOpen, setMobileNavOpen, providerImageChange } =
    useContext(NavContext);
  const { activeLog, initiateCallLog, openCallLog, setOpenCallLog, resources } =
    useContext(CallLogContext);
  // General States
  const [showSideNav, setShowSideNav] = useState(true);
  const [loggedIn, setLoggedIn] = useState(false);
  const [switcher, setSwitcher] = useState(false);
  // const [isResourcesMenuHovered, setIsResourcesMenuHovered] = useState(false);
  const [providerImage, setProviderImage] = useState();
  const [exceededRequest, setExceededRequest] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [loadingSession, setLoadingSession] = useState(true);
  const [cartEvent, setCartEvent] = useState();
  const [userName, setUserName] = useState();

  const [passwordExpiry, setPasswordExpiry] = useState("");
  const [profileTooltip, setProfileTooltip] = useState(false);

  const [userOrgName, setUserOrgName] = useState();
  const [orgNameTooltip, setOrgNameTooltip] = useState(false);

  const callLogPanelRef = useRef(null);
  const callLogButtonRef = useRef(null);
  const mountedRef = useRef(false);

  // Location & History
  const history = useHistory();
  let location = useLocation();
  const { pathname } = useLocation();
  // Responsiveness
  const [width, setWidth] = useState(window.innerWidth);
  let mobile = width <= 1100;
  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth);
  };

  useEffect(() => {
    const checkSession = async () => {
      try {
        const currentUser =
          JSON.parse(sessionStorage.getItem("penn-provider")) || user;

        if (currentUser?.accessToken && currentUser?.idToken) {
          let response = await fetch(
            process.env.REACT_APP_API_ENDPOINT + "/iam/checkSession",
            {
              method: "post",
              credentials: "include",
              headers: {
                Authorization: `Bearer ${currentUser?.accessToken} ${currentUser?.idToken}`,
                "Content-Type": "application/json",
              },
              body: JSON.stringify({
                expiresIn: currentUser?.expiresIn,
              }),
            }
          );
          setExceededRequest(false);
          if (response.status === 200) {
            if (loggedIn === false) {
              setLoggedIn(true);
              if (subscribeToActivityFeed) {
                subscribeToActivityFeed();
              }
            }
            setLoadingSession(false);
            return;
          }
          if (response.status === 429) {
            setExceededRequest(true);
            setOpenModal(true);
            setLoadingSession(false);
            return;
          }
          setLoggedIn(false);
          if (user.superAssumedRole?.leadId) {
            await apiService.post(`/iam/assume-role`, {
              orgId: user.superAssumedRole.orgId,
              orgName: user.superAssumedRole.orgName,
              userId: user.superAssumedRole.leadId,
              role: user.superAssumedRole.role,
              action: "logout",
            });
          }
          clearUser();
          channel.postMessage({ key: "logout" });
          sessionStorage.removeItem("penn-provider");
          localStorage.clear();
        }
      } catch (error) {
        console.log(error);
        setLoadingSession(false);
      }
      setLoadingSession(false);
    };

    mountedRef.current = true;

    if (mountedRef) {
      checkSession();
      setInterval(checkSession, 1000 * 60 * 5);
    }

    return () => {
      mountedRef.current = false;
    };
  }, [location]);

  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        callLogPanelRef.current &&
        !callLogPanelRef.current.contains(event.target) &&
        callLogButtonRef.current &&
        !callLogButtonRef.current.contains(event.target)
      ) {
        setOpenCallLog(false);
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [openCallLog]);

  useEffect(() => {
    if (Object.keys(user).filter((key) => key !== "notifications").length) {
      setLoggedIn(true);
    }
  }, []);

  // gets provider image to display on dropdown menu
  useEffect(() => {
    const getProviderImage = async () => {
      const data = await apiService.get(
        `/profileimages?assumedId=${user.superAssumedRole?.leadId || ""}`
      );
      if (data.ok) {
        setProviderImage(data.image);
      } else {
        console.log(data.message);
      }
    };
    if (loggedIn) {
      getProviderImage();
    }
  }, [apiService, loggedIn, user]);

  useEffect(() => {
    if (typeof apiService.adjustHeaders === "function") {
      apiService.adjustHeaders();
    }
    setUserName(
      user.aliasName ? user.aliasName : user.name ? user.name : user.fullName
    );
    async function displayOrgName() {
      if (user.isSuper === true && !user?.superAssumedRole?.orgId) {
        setUserOrgName("");
      } else if (
        user.is211Provider === false &&
        user.is211Admin === false &&
        (user.isSuper === false || user?.superAssumedRole?.orgId)
      ) {
        const providerInfo = await apiService.post(
          `/search/provider?user=${user?.superAssumedRole?.leadId || user.id}`
        );
        const { providers } = providerInfo;
        setUserOrgName(providers?.name);
      }
    }
    displayOrgName();
  }, [user]);

  //updates provider Image
  useEffect(() => {
    const updateProviderImage = () => {
      providerImageChange?.eventType === "add"
        ? setProviderImage(providerImageChange?.img)
        : setProviderImage();
    };
    updateProviderImage();
  }, [providerImageChange]);

  useEffect(() => {
    document.getElementById("content").scrollTo(0, 0);
  }, [pathname]);

  const broadcastLogout = () => {
    localStorage.setItem("logout", JSON.stringify(true));
  };

  window.onstorage = (e) => {
    if (e.key === "logout" || e.key === "passwordChange") {
      logout();
    }
  };

  const clickLogout = async () => {
    if (user.superAssumedRole?.leadId) {
      await apiService.post(`/iam/assume-role`, {
        orgId: user.superAssumedRole.orgId,
        orgName: user.superAssumedRole.orgName,
        userId: user.superAssumedRole.leadId,
        role: user.superAssumedRole.role,
        action: "logout",
      });

      updateUser({
        ...user,
        is211Admin: false,
        superAssumedRole: {},
        isSuper: true,
        isAdmin: false,
        isViewOnly: false,
        name: user.superAssumedRole.oldName,
      });
      history.push("/");
    } else {
      logout();
    }
  };

  const logout = async () => {
    if (user.superAssumedRole?.leadId) {
      await apiService.post(`/iam/assume-role`, {
        orgId: user.superAssumedRole.orgId,
        orgName: user.superAssumedRole.orgName,
        userId: user.superAssumedRole.leadId,
        role: user.superAssumedRole.role,
        action: "logout",
      });
    }
    // broadcastLogout();
    window.location.href = "/login";
    channel?.postMessage({ key: "logout" }); // Notify other tabs
    sessionStorage.removeItem("penn-provider");
    clearUser();
    localStorage.clear();
    setMobileNavOpen(false);
    setShowSideNav(false);
  };

  const toggleSwitcher = (url) => {
    setSwitcher(!switcher);
    history.push(url);
    closeComponentClick();
  };

  const closeComponentClick = () => {
    setIsComponentVisible(!isComponentVisible);
  };

  const closeModal = () => {
    setOpenModal(!openModal);
  };

  const renderHeaderRole = (truncate) => {
    if (user.superAssumedRole?.role) {
      return truncateString(
        capitalizeFirstLetterMultiple(user.superAssumedRole.role),
        truncate ? 10 : null
      );
    } else {
      if (user.isSuper) {
        return "SIP Admin";
      } else if (user.isAdmin) {
        return "Admin";
      } else if (user.isViewOnly) {
        return "View Only";
      } else if (user.is211Admin) {
        return "211 Admin";
      } else if (user.is211Provider) {
        return "211 Staff";
      } else {
        return "Staff";
      }
    }
  };

  useEffect(() => {
    if (user?.passwordExpirationTimestamp) {
      // get difference in days until expiry
      const expiryCountdown = Math.round(
        (new Date(user?.passwordExpirationTimestamp) - new Date()) / 86400000
      );
      setPasswordExpiry(expiryCountdown);
    }
  }, [user]);

  let orgStr = user.superAssumedRole?.orgName || userOrgName;
  const result = String(orgStr).match(/.{1,20}/g);
  return (
    <UIShellContainer>
      {exceededRequest && (
        <Modal
          passiveModal
          onClick={closeModal}
          open={openModal}
          modalHeading="Requests exceeded"
        >
          <p style={{ marginBottom: "1rem" }}>
            You have exceeded the allowed requests. Please wait!
          </p>
        </Modal>
      )}
      <S.Header aria-label="header">
        <S.StyledHeaderName
          prefix=""
          href="/"
          onMouseEnter={() => setOrgNameTooltip(true)}
          onMouseLeave={() => setOrgNameTooltip(false)}
        >
          MyWayfinder Provider
          {(user?.aliasName || user?.name || user?.fullName) && (
            <div style={{ flexDirection: "column", overflow: "hidden" }}>
              <S.StyledUserRole>
                {user?.aliasName
                  ? user?.aliasName
                  : user.name
                  ? user.name
                  : user.fullName}
              </S.StyledUserRole>
              <S.StyledUserRole>
                {orgStr
                  ? result[1]
                    ? result[0] + "... - "
                    : result[0] + " - "
                  : ""}{" "}
                {user && renderHeaderRole(true)}
              </S.StyledUserRole>
            </div>
          )}
          <Tooltip
            showIcon={false}
            open={
              (user?.aliasName || user?.name || user?.fullName) &&
              orgNameTooltip
            }
            direction="bottom"
          >
            <p className=".bx--tooltip__body">
              {user?.aliasName
                ? user?.aliasName
                : user.name
                ? user.name
                : user.fullName}
            </p>
            <p className=".bx--tooltip__body">
              {orgStr + " - " + renderHeaderRole()}
            </p>
          </Tooltip>
        </S.StyledHeaderName>
        {loggedIn && !mobile && (
          <S.HeaderNavigation aria-label="Navigation">
            <StyledHeaderMenuItem
              data-id={`nav-home-btn`}
              href="/"
              aria-label="Home"
            >
              Home
            </StyledHeaderMenuItem>
            {!user.firstLogin && (
              <>
                <StyledHeaderMenuItem
                  data-id={`nav-referral-dashboard-btn`}
                  href="/referral-dashboard"
                  aria-label="Referral Dashboard"
                >
                  {user.is211Admin ? "Dashboard" : "Referral Dashboard"}
                </StyledHeaderMenuItem>
                {!user.isViewOnly && (
                  <StyledHeaderMenuItem
                    data-id={`nav-service-directory-dropdown`}
                    aria-label="Service Directory"
                    // onMouseEnter={() => setIsResourcesMenuHovered(true)}
                    // onMouseLeave={() => setIsResourcesMenuHovered(false)}
                    style={{ paddingRight: "0.6rem" }}
                  >
                    <span
                      style={{ display: "flex" }}
                      onClick={() => {
                        history.push("/service-directory");
                        // setIsResourcesMenuHovered(false);
                      }}
                    >
                      Service Directory
                      {/* <CaretDown16 /> */}
                    </span>
                    {/* {isResourcesMenuHovered && (
                      <DropdownMenu
                        setIsResourcesMenuHovered={setIsResourcesMenuHovered}
                      />
                    )} */}
                  </StyledHeaderMenuItem>
                )}
                {!user.is211Admin && !user.isViewOnly && (
                  <StyledHeaderMenuItem
                    data-id={`nav-feed-btn`}
                    href="/feed"
                    aria-label="Activity Feed"
                  >
                    Activity Feed
                  </StyledHeaderMenuItem>
                )}
                {numberUnread && getTotalUnread() > 0 && !user.is211Admin && (
                  <NotificationBadge quantity={getTotalUnread()} />
                )}
              </>
            )}
          </S.HeaderNavigation>
        )}

        <HeaderGlobalBar>
          {loggedIn &&
          mobile === true &&
          !user.is211Admin &&
          !user.isViewOnly ? (
            <HeaderGlobalAction
              data-id={`nav-feed-btn`}
              aria-label="Activity Feed"
              href="/feed"
            >
              <Notification24
                style={
                  numberUnread && getTotalUnread() > 0 ? { fill: "red" } : {}
                }
              />
              <br />
              <span> </span>
            </HeaderGlobalAction>
          ) : null}
          {loggedIn && !mobile ? (
            <>
              {user.is211Admin &&
                (activeLog ? (
                  <HeaderGlobalAction
                    ref={callLogButtonRef}
                    data-id={`nav-call-log-btn`}
                    className="call-log"
                    aria-label="Call Log"
                    onClick={() => setOpenCallLog(!openCallLog)}
                  >
                    <ShoppingCart24 />
                    {resources && resources?.length > 0 && (
                      <NotificationBadge quantity={resources.length} />
                    )}
                  </HeaderGlobalAction>
                ) : (
                  <HeaderGlobalAction
                    data-id={`nav-call-log-btn`}
                    className="call-log"
                    aria-label="Call Log"
                    style={{ width: "auto", padding: "0 1rem" }}
                    onClick={() => {
                      initiateCallLog();
                      setOpenCallLog(true);
                    }}
                  >
                    New Call Log
                  </HeaderGlobalAction>
                ))}
              {!user.is211Admin && !user.isViewOnly && (
                <S.LinkButton>
                  <a
                    data-id={`nav-feed-btn`}
                    href="/impact"
                    aria-label="Impact"
                    style={{
                      textDecoration: "none",
                      color: "#fff",
                      padding: "0 0.5rem",
                    }}
                  >
                    My Impact
                  </a>
                </S.LinkButton>
              )}
              <HeaderGlobalAction
                data-id={`nav-account-btn`}
                className="user-nav-button"
                aria-label="Profile"
                onClick={() => {
                  setSwitcher(!switcher);
                  closeComponentClick();
                  if (openCallLog) {
                    setOpenCallLog(false);
                  }
                }}
                onMouseEnter={() => setProfileTooltip(true)}
                onMouseLeave={() => setProfileTooltip(false)}
              >
                <User24 />
                {showPasswordExpiry && (
                  <Tooltip
                    showIcon={false}
                    open={profileTooltip}
                    direction="bottom"
                    align="end"
                  >
                    <p className=".bx--tooltip__body">
                      <strong>Profile</strong>
                    </p>
                    {(passwordExpiry || passwordExpiry === 0) &&
                      (passwordExpiry === 1 ? (
                        <p>Password expires {passwordExpiry} day</p>
                      ) : (
                        <p>Password expires {passwordExpiry} days</p>
                      ))}
                  </Tooltip>
                )}
              </HeaderGlobalAction>
              {isComponentVisible ? (
                <div ref={ref}>
                  <CustomHeaderPanel
                    aria-label="Header Panel"
                    expanded={switcher}
                  >
                    <Switcher aria-label="Switcher Container">
                      <CustomSwitcherItem isSelected={false} aria-label="info">
                        <ProviderImageContainer
                          style={{
                            backgroundColor: providerImage ? "white" : "none",
                            backgroundImage: providerImage
                              ? `url(${providerImage})`
                              : `url("/blank.png")`,
                            height: "64px",
                            width: "64px",
                            filter: providerImage
                              ? "brightness(1)"
                              : "brightness(2)",
                          }}
                        />
                        <span style={{ color: "#ffffff" }}>{userName}</span>
                      </CustomSwitcherItem>
                      <SwitcherDivider
                        style={{
                          background: "#ffffff",
                        }}
                      />
                      {!user.firstLogin && (
                        <S.SwitcherItem
                          data-id={`nav-profile-btn`}
                          onClick={() => toggleSwitcher("/account")}
                          aria-label="Profile"
                          style={{ color: "#ffffff" }}
                        >
                          Profile
                        </S.SwitcherItem>
                      )}
                      <S.SwitcherItem
                        data-id={`nav-feedback-btn`}
                        href="/contact-us"
                        onClick={() => {
                          if (REACT_APP_DISABLE_FEEDBACK_FEATURE) {
                            setSwitcher(!switcher);
                            return;
                          } else {
                            window.showCollectorDialog();
                            setSwitcher(!switcher);
                          }
                        }}
                        aria-label="Get Help"
                        style={{ color: "#ffffff" }}
                      >
                        Get Help
                      </S.SwitcherItem>
                      <S.SwitcherItem
                        data-id={`nav-logout-btn`}
                        onClick={clickLogout}
                        aria-label="Logout"
                        style={{
                          display: "flex",
                          alignContent: "center",
                          lineHeight: "1",
                          color: "#ffffff",
                        }}
                      >
                        {!!user.superAssumedRole?.leadId
                          ? "Logout - Assumed Role"
                          : "Logout"}
                        <ArrowRight16 style={{ marginLeft: "1rem" }} />
                      </S.SwitcherItem>
                    </Switcher>
                  </CustomHeaderPanel>
                </div>
              ) : null}
            </>
          ) : null}
          {!loggedIn && !mobile ? (
            <HeaderGlobalAction
              data-id={`nav-login-signup-btn`}
              className="user-nav-button"
              aria-label="Login/Signup"
              onClick={() => history.push("/login")}
              style={{ width: "auto", padding: "0 1rem" }}
            >
              Login/Signup
            </HeaderGlobalAction>
          ) : null}
          {loggedIn && mobile === true ? (
            <>
              {user.is211Admin &&
                (activeLog ? (
                  <HeaderGlobalAction
                    data-id={`nav-call-log-btn`}
                    className="call-log"
                    aria-label="Call Log"
                    onClick={() => setOpenCallLog(!openCallLog)}
                  >
                    <ShoppingCart24 />
                    {resources && resources?.length > 0 && (
                      <NotificationBadge quantity={resources.length} />
                    )}
                  </HeaderGlobalAction>
                ) : (
                  <HeaderGlobalAction
                    data-id={`nav-call-log-btn`}
                    className="call-log"
                    aria-label="Call Log"
                    style={{ width: "auto", padding: "0 1rem" }}
                    onClick={() => {
                      initiateCallLog();
                      setOpenCallLog(true);
                    }}
                  >
                    New Call Log
                  </HeaderGlobalAction>
                ))}
              <HeaderGlobalAction
                data-id={`nav-menu-btn`}
                aria-label="Menu"
                className="mobile-nav-button"
                isActive={mobileNavOpen}
                onClick={() => {
                  setMobileNavOpen(!mobileNavOpen);
                  if (openCallLog) {
                    setOpenCallLog(false);
                  }
                }}
              >
                <Menu24 />
              </HeaderGlobalAction>
            </>
          ) : null}
        </HeaderGlobalBar>
        <HeaderPanel aria-label="Header Panel" expanded={mobileNavOpen}>
          <MobileNav
            loggedIn={loggedIn}
            history={history}
            logout={clickLogout}
            broadcastLogout={broadcastLogout}
            channel={channel}
            user={user}
            close={() => setMobileNavOpen(false)}
          />
        </HeaderPanel>
        {user.is211Admin && (
          <div style={{ height: "auto" }} ref={callLogPanelRef}>
            <CallLogPanel
              aria-label="Call Panel Panel"
              expanded={openCallLog}
              toggleOpen={() => setOpenCallLog(!openCallLog)}
            />
          </div>
        )}
      </S.Header>
      <Content id="content">
        {user &&
          user?.requestFeedback &&
          (location?.pathname === "/" ||
            location?.pathname === "/referral-dashboard") && (
            <FeedbackNotification
              kind="info"
              role="alert"
              title=""
              statusIconDescription="Info"
              hideCloseButton
            >
              <p>
                <strong>We want to hear from you.</strong>{" "}
                <Link to={"/feedback"}>Click here</Link> to complete the
                TogetherNow satisfaction survey!
              </p>
            </FeedbackNotification>
          )}
        {loadingSession ? <Loading /> : children}
        {location?.pathname !== "/login" && <AnonymousFooter />}
      </Content>
    </UIShellContainer>
  );
};

export default UIShell;
