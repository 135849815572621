import { useState, useContext, useLayoutEffect, Suspense, lazy } from 'react';
import { Link, useHistory, useLocation, useParams } from 'react-router-dom';
import useQuery from "../../../hooks/useQuery";

import { Button, TooltipDefinition, Loading } from 'carbon-components-react';
import { ChevronRight32 } from '@carbon/icons-react';
import { Fallback } from '../../loading-screen';
import * as S from './summary.styled';
import kit from 'ich-ui-kit';

import PrintSummary from './print';

import Domain from './icon/domain.icon';
import ProviderIcon from './icon/provider.icon';

import { useCheckinCMS } from './cms';
import { validateUrl, lazyRetry } from '../../../utils';
import Complete from './components/Complete';

// import ShareAssessmentModal from "./shareAssessment";
const ShareAssessmentModal = lazy(() => lazyRetry(() => import('./shareAssessment'), 'shareAssessmentModal'));

const Summary = ({passClientInfo, setCompletedStatus}) => {
  // Contexts
  const { apiService } = useContext(kit.ApiContext);
  const { user } = useContext(kit.UserContext);

  // Hooks
  let history = useHistory();
  let location = useLocation();
  const query = useQuery();

  // States
  const [resources, setResources] = useState([]);
  const [goodDomain, setGoodDomain] = useState([]);
  const [badDomain, setBadDomain] = useState([]);
  const [loading, setLoading] = useState(false);
  const [shareAssessment, setShareAssessments] = useState(false);
  const [summary, setSummary] = useState([]);
  const [questions, setQuestions] = useState([]);
  const [provColor, setProvColor] = useState([]);
  const [zip, setZip] = useState('00000');
  const [ans, setAns] = useState(0);

  const {id: clientIdDirectlyFromURL} = useParams();
  const clientIdFromURL = query.get("client") || clientIdDirectlyFromURL 
 
  let clientId = passClientInfo?.id || location?.state?.client || location?.state?.clientID || clientIdFromURL;
  const [clientID, setClientID] = useState(clientId);

  const { checkin, domain: domains, wellbeing } = useCheckinCMS();

  const handlePrint = () => {
    window.print();
  };

  useLayoutEffect(() => {
   
    const getAssessmentData = async () => {
      try {
        setLoading(true);
        let assessmentId = location.state.id;
        if(!clientID || !assessmentId) return;
        const data = await apiService.get(
          `/assessments?patientId=${clientID}&id=${assessmentId}`
        );
        if (data) {
          // setResources(data.recommendedResources.slice(0, 8));
          setResources(data.providers.slice(0, 8));
          setSummary(data.score);
          setQuestions(data.responses);
          sortDomains(data.responses);
          setLoading(false);
        }
      } catch (err) {
        console.log(err);
      }
    };
    if (location.state?.id) {
      getAssessmentData();
      setZip(location.state.zip);
    } else {
      setLoading(true);
      setTimeout(() => {
        if(location.state) {
          setZip(location.state.zip);
          setResources(location.state.providers.slice(0, 8));
          setSummary(location.state.score);
          sortDomains(location.state.responses);
          setQuestions(location.state.responses);
        }
        setLoading(false);
      }, 1500);
    }
  }, []);

  const sortDomains = (responses) => {
    responses.forEach((response) => {
      setProvColor((state) => [...state, response]);
      if (response.answer <= 3 && response.answer != 0) {
        setAns((state) => state + 1);
        setBadDomain((state) => [...state, response]);
        return;
      }
      if (response.answer != 0) {
        setAns((state) => state + 1);
        setGoodDomain((state) => [...state, response]);
      }
    });
  };

  const getTranslatedDomain = (dn, domainList) => {
    if (!dn || !domainList) return;
    const text = dn.toLowerCase().split(' ').join('_');
    return domains[text];
  };

  const searchResources = (zip, dn) => {
    const domainDisplay = getTranslatedDomain(dn, domains);
    window.open(
      validateUrl(`/service-directory?display=${domainDisplay}&need=${dn}&zip=${zip}`)
    );
  };

  const colorBlindLegend = (status) => {
    if (status) {
      switch (status.toLowerCase()) {
        case 'not ok':
          return <>1</>;
        case 'ok':
          return <>2</>;
        case 'good':
          return <>3</>;
        case 'great':
          return <>4</>;
        case 'excellent':
          return <>5</>;
        default:
          return <></>;
      }
    }
  };

  const legends = [
    {
      color: '#ec5c29',
      text: 'Not OK',
      displayText: wellbeing.not_okay
    },
    {
      color: '#faa41a',
      text: 'OK',
      displayText: wellbeing.okay
    },
    {
      color: '#f4db5f',
      text: 'Good',
      displayText: wellbeing.good
    },
    {
      color: '#4ac1e0',
      text: 'Great',
      displayText: wellbeing.great
    },
    {
      color: '#95c93d',
      text: 'Excellent',
      displayText: wellbeing.excellent
    }
  ];

  return (
    <S.ScrollContainer>
      <Suspense fallback={<Fallback />}>
        <ShareAssessmentModal
          modalOpen={shareAssessment}
          setModalOpen={setShareAssessments}
          page={
            <PrintSummary
              summary={summary}
              recommendedResources={resources}
              questions={questions}
            />
          }
        />
      </Suspense>
      <PrintSummary
        summary={summary}
        recommendedResources={resources}
        questions={questions}
      />

      {loading ? (
        <Loading />
      ) : (
        <>
          <Complete
            cms={{ checkin: checkin, domains: domains, wellbeing: wellbeing }}
            passClientInfo={passClientInfo}
            setCompletedStatus={setCompletedStatus}
          />
          <S.Container>
            <S.LeftPanel>
              <S.LegendsContainer>
                <S.LegendsHeading>{checkin.label_legend}</S.LegendsHeading>
                <S.LegendsContent>
                  {legends.map((legend) => (
                    <S.Legends key={Math.random()}>
                      <S.LegendCircle theme={{ bcolor: legend.color }}>
                        {colorBlindLegend(legend.text)}
                      </S.LegendCircle>
                      <S.LegendsText>{legend.displayText}</S.LegendsText>
                    </S.Legends>
                  ))}
                </S.LegendsContent>
              </S.LegendsContainer>

              <S.Summary>
                <S.Heading>{checkin.subheading_checkin_domains}</S.Heading>
                {goodDomain.length === 0 ? undefined : (
                  <>
                    <S.SummaryText>
                      {checkin.domains_positive_desc}
                    </S.SummaryText>
                    <S.ResultContainer>
                      {goodDomain
                        .filter(
                          ({ text }, index) =>
                            !goodDomain
                              .map((o) => o.text)
                              .includes(text, index + 1)
                        )
                        .map((el) => {
                          return (
                            <Domain
                              key={Math.random()}
                              name={el.text}
                              status={el.answer}
                              cmsData={domains}
                            />
                          );
                        })}
                    </S.ResultContainer>
                  </>
                )}
                {badDomain.length === 0 ? undefined : (
                  <>
                    <S.SummaryText>
                      {checkin.domains_negative_desc}
                    </S.SummaryText>
                    <S.ResultContainer>
                      {badDomain
                        .filter(
                          ({ text }, index) =>
                            !badDomain
                              .map((o) => o.text)
                              .includes(text, index + 1)
                        )
                        .map((el) => {
                          return (
                            <Domain
                              key={Math.random()}
                              handleClick={() =>
                                searchResources(zip, el.text.toLowerCase())
                              }
                              isBad={true}
                              name={el.text}
                              text={getTranslatedDomain(el.text, domains)}
                              status={el.answer}
                              cmsData={domains}
                            />
                          );
                        })}
                    </S.ResultContainer>
                  </>
                )}
                {(badDomain.length === 0) & (goodDomain.length === 0) ? (
                  <h4 style={{ margin: "2rem 0" }}>
                    {checkin.text_non_answered}
                  </h4>
                ) : undefined}
                <S.SummaryBtnGroup>
                  <Button kind="ghost" onClick={() => handlePrint()}>
                    Print
                  </Button>
                  <Button
                    kind="ghost"
                    onClick={() => {
                      setShareAssessments(true);
                    }}
                  >
                    Email
                  </Button>
                </S.SummaryBtnGroup>
              </S.Summary>
            </S.LeftPanel>
            <S.Resources>
              <S.Header>
                <S.Heading>{checkin.subheading_recommendations}</S.Heading>
                <S.Text>{checkin.text_based_response}</S.Text>
                {history.state?.interuption?.hasOpened && (
                  <S.Recommend211Card>
                    <p>
                      You mentioned you were not okay when you checked-in. We
                      recommend contacting 211 by calling 1-877-356-9211.
                    </p>
                  </S.Recommend211Card>
                )}
              </S.Header>
              {ans !== 0 ? (
                <>
                  <S.Content>
                    {resources.map((provider) => {
                      const getProv = provColor.find(
                        (prov) =>
                          prov?.text?.split("").join("").toLowerCase() ===
                          provider?.category?.split("").join("").toLowerCase()
                      );
                      if (!legends[5 - getProv.answer]) return;
                      return (
                        <S.Card key={Math.random()}>
                          <S.CardBody>
                            <S.TextContainer>
                              <TooltipDefinition
                                direction="bottom"
                                triggerClassName="provider-name"
                                tooltipText={provider.name || provider.orgName}
                              >
                                <S.ProviderHeading className="provider-name">
                                  {provider.name || provider.orgName}
                                </S.ProviderHeading>
                              </TooltipDefinition>
                              <p>{provider.category}</p>
                              {provider.address ?? (
                                <S.TextSmall>{provider.address}</S.TextSmall>
                              )}
                              {provider.city && provider.state ? (
                                <S.TextSmall>
                                  {provider.city}, {provider.state}
                                </S.TextSmall>
                              ) : undefined}
                            </S.TextContainer>
                            <ProviderIcon
                              name={provider.category}
                              provider={getProv}
                              cmsData={domains}
                              colorBlind
                            />
                          </S.CardBody>
                          <Button
                            kind="tertiary"
                            as={Link}
                            to={`/provider-information?id=${
                              provider.orgId
                            }&serviceId=${provider.serviceId}&locationId=${
                              provider?.locationId
                            }&orgSource=${provider.orgSource || "211"}`}
                          >
                            {checkin.btn_view_provider}
                          </Button>
                        </S.Card>
                      );
                    })}
                  </S.Content>
                  <Button
                    style={{
                      margin: "2rem 0 0",
                    }}
                    as={Link}
                    to={{
                      pathname: `/service-directory`,
                      state: { fromCheckin: true, resources: resources },
                    }}
                    renderIcon={ChevronRight32}
                  >
                    {checkin.btn_view_all}
                  </Button>
                </>
              ) : undefined}
            </S.Resources>
          </S.Container>
        </>
      )}
    </S.ScrollContainer>
  );
};

export default Summary;
