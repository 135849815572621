import { useLocation } from "react-router-dom";
import styled from "styled-components";

const DashboardHeaderContainer = ({
  backgroundColor,
  title,
  tab,
  setTab,
  user
}) => {
  const location = useLocation();

  if (user.isSuper) {
    return (
      <>
        <Wrapper style={{ backgroundColor: backgroundColor }}>
          <Title>{title}</Title>
          <TabActive>All Services</TabActive>
        </Wrapper>
      </>
    )
  } else if (user.is211Admin) {
    if (!tab) {
      setTab(!location?.state?.from === "callLogDashboard" ? "Call Logs" : "Outbound");

      if(location.pathname === '/referral-dashboard/view-call'){
        setTab("Call Logs")
      }
    }

    return (
      <>
      <Wrapper style={{ backgroundColor: backgroundColor }}>
        <Title>{title}</Title>
        { tab === "My Organization" ? (
          <Flex>
            <Tab style={{cursor: "pointer"}} onClick={() => setTab("Outbound")}>Referrals Sent</Tab>
            <TabActive style={{cursor: "pointer"}}>Referral Stats</TabActive>
            <Tab style={{cursor: "pointer"}} onClick={() => setTab("Call Logs")}>Call Logs</Tab>
          </Flex>
        ) : (tab === "Outbound" || tab === "My Services") ? (
          <Flex>
            <TabActive style={{cursor: "pointer"}}>Referrals Sent</TabActive>
            <Tab style={{cursor: "pointer"}} onClick={() => setTab("My Organization")}>Referral Stats</Tab>
            <Tab style={{cursor: "pointer"}} onClick={() => setTab("Call Logs")}>Call Logs</Tab>
          </Flex>
        ) : tab === "Call Logs" ? (
          <Flex>
            <Tab style={{cursor: "pointer"}} onClick={() => setTab("Outbound")}>Referrals Sent</Tab>
            <Tab style={{cursor: "pointer"}} onClick={() => setTab("My Organization")}>Referral Stats</Tab>
            <TabActive style={{cursor: "pointer"}}>Call Logs</TabActive>
          </Flex>
        ) : (
          <div></div>
        )}
      </Wrapper>
    </>
    )
  } else {
    if (!tab) {
      setTab("My Services")
    }
    return (
    <>
      <Wrapper style={{ backgroundColor: backgroundColor }}>
        <Title>{title}</Title>
        { tab === "My Services" ? (
          <Flex>
            <TabActive style={{cursor: "pointer"}}>Referrals Received</TabActive>
            <Tab style={{cursor: "pointer"}} onClick={() => setTab("Outbound")}>Referrals Sent</Tab>
            <Tab style={{cursor: "pointer"}} onClick={() => setTab("My Organization")}>My Organization</Tab>
          </Flex>
        ) : tab === "My Organization" ? (
          <Flex>
            <Tab style={{cursor: "pointer"}} onClick={() => setTab("My Services")}>Referrals Received</Tab>
            <Tab style={{cursor: "pointer"}} onClick={() => setTab("Outbound")}>Referrals Sent</Tab>
            <TabActive style={{cursor: "pointer"}}>My Organization</TabActive>
          </Flex>
        ) : tab === "Outbound" ? (
          <Flex>
            <Tab style={{cursor: "pointer"}} onClick={() => setTab("My Services")}>Referrals Received</Tab>
            <TabActive style={{cursor: "pointer"}}>Referrals Sent</TabActive>
            <Tab style={{cursor: "pointer"}} onClick={() => setTab("My Organization")}>My Organization</Tab>
          </Flex>
        ) : tab === "Call Logs" ? (
          <Flex>
            <Tab style={{cursor: "pointer"}} onClick={() => setTab("My Services")}>Referrals Received</Tab>
            <Tab style={{cursor: "pointer"}} onClick={() => setTab("Outbound")}>Referrals Sent</Tab>
            <Tab style={{cursor: "pointer"}} onClick={() => setTab("My Organization")}>My Organization</Tab>
          </Flex>
        ) : (
          <div></div>
        )}
      </Wrapper>
    </>
    )}
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0.5rem 1rem 0rem 0.5rem;
  height: 6rem;
  @media screen and (min-width: 500px) {
    padding: 1rem 2rem 0rem 2rem;
    height: 8rem;
  }
  @media screen and (min-width: 900px) {
    padding: 1rem 2rem 0rem 2rem;
    height: 8rem;
  }
`;

const Title = styled.h1`
  font-weight: 400;
  font-size: 32px;
  @media screen and (min-width: 900px) {
    font-size: 36px;
  }
`;

const Tab = styled.div`
  font-weight: 500;
  padding: 0.6rem 1rem;
  width: 8rem;
  background-color: #c6c6c6;
  border-top: 2px solid #a7a7a7;
  color: #525252;
  @media screen and (min-width: 500px) {
    padding: 0.8rem 1rem;
    width: 9rem;
  }
  @media screen and (min-width: 900px) {
    padding: 1rem;
    width: 10rem;
  }
`;

const TabActive = styled.div`
  font-weight: 500;
  padding: 0.6rem 1rem;
  width: 8rem;
  background-color: #f4f4f4;
  border-top: 2px solid blue;
  @media screen and (min-width: 500px) {
    padding: 0.8rem 1rem;
    width: 9rem;
  }
  @media screen and (min-width: 900px) {
    padding: 1rem;
    width: 10rem;
  }
`;

const Flex = styled.div`
  display: flex;
  flex-direction: row;
`;


export default DashboardHeaderContainer;
