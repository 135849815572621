import React from "react";
import {
  DataTable,
  Table,
  TableHead,
  TableRow,
  TableHeader,
  TableBody,
  TableCell
} from "carbon-components-react";
import Empty from "../../../../components/Empty";
import * as S from "./careTeam.styled";
import { convertCellToDateString } from "./helpers";
import { constructServiceOverviewUrl } from "../../../../utils";

const headers = [
  {
    key: "service_name",
    header: "Service Name"
  },
  {
    key: "org_name",
    header: "Organization"
  },
  {
    key: "reason",
    header: "Reason"
  },
  {
    key: "endDate",
    header: "End Date"
  },
  {
    key: "org_id"
  },
  {
    key: "id"
  },
  {
    key: "orgSource"
  },
  {
    key: "serviceId"
  },
  {
    key: "locationId"
  },
  {
    key: "serviceAtLocationId"
  }
];

const PastCareTeam = ({ pastCareTeam }) => {

  return (
    <S.Content>
      {pastCareTeam && pastCareTeam.length > 0 ? (
        <DataTable rows={pastCareTeam} headers={headers}>
          {({ rows, headers, getTableProps, getHeaderProps, getRowProps }) => (
            <Table {...getTableProps()}>
              <TableHead>
                <TableRow>
                  {headers.map((header, idx) => {
                    if (idx >= 4) return;
                    return (
                      <TableHeader {...getHeaderProps({ header })}>
                        {header.header}
                      </TableHeader>
                    );
                  })}
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row, i) => {
                  if (row.id !== undefined)
                    return (
                      <TableRow key={Math.random()} {...getRowProps({ row })}>
                        {row.cells.map((cell, idx) => {
                          if (idx >= 4) return;
                          return (
                            <TableCell key={Math.random()}>
                              {cell.info.header !== "service_name" ? (
                                convertCellToDateString(cell.value)
                              ) : (
                                <>
                                  <a
                                    href={constructServiceOverviewUrl(
                                      row.cells[9].value,
                                      row.cells[6].value
                                    )}
                                    rel="noreferrer noopener"
                                    target="_blank"
                                    style={{
                                      cursor: "pointer",
                                      textDecoration: "none"
                                    }}
                                  >
                                    {cell.value}
                                  </a>
                                </>
                              )}
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    );
                })}
              </TableBody>
            </Table>
          )}
        </DataTable>
      ) : (
        <Empty
          src="/icons/collaboration.svg"
          text="Past Care Team Empty."
          btn={<></>}
          style={{
            minHeight: "60vh",
            marginTop: "1rem"
          }}
        />
      )}
    </S.Content>
  );
};

export default PastCareTeam;
