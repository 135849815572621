import { createContext, useEffect, useState, useContext } from "react";
import { useDemographicCMS } from "../components/360ProfileForm/cms";
import { useStrapi } from "../../../hooks/useStrapi";

const useCompleteProfileCMS = () => {
  const init = {
    heading: null,
    step_1_heading: "Step 1 of 3: Household Information",
    step_1_subheading_lets_start:
      "Let's start with some basic household information.",
    step_1_text_completing:
      " Completing your profile should take about [minutes] to complete.",
    step_1_text_minutes: "5 minutes",
    btn_continue: "Continue",
    step_2_heading: "Step 2 of 3: Personal Information",
    step_2_subheading_lets_continue:
      "Let's continue with some information to better understand you!",
    step_2_text_take: " This will take about [minutes] to complete.",
    step_2_text_minutes: "one or two minutes",
    step_3_heading: "Step 3 of 3: Emergency Contact",
    step_3_subheading_lets_capture:
      "Let's capture an emergency contact just in case.",
    step_3_text: "This is the last question for now - we promise :)",
    // complete_heading:
    //   "You completed your profile and earned the Complete Profile Badge!",
    complete_heading:
    "Profile Completed",
    // complete_text: "MyWayfinder Badges help you celebrate your success. Continue earning badges by completing your first check-in."
    complete_text:
      "Congrats on filling out your client's profile. You can now complete first check-in for your client (optional).",
    btn_take_checkin: "Take Check-in",
    btn_skip: "Skip",
    error_text:
      "An error has occurred while submitting your data. Please restart from the beginning by clicking below:",
    btn_restart: "Restart",
    btn_go_back: "Go back",
    address_text:
      "As you enter your address information, MyWayfinder will autocomplete and you can select your address.",
    address_autocomplete_label: "Enter your address",
    address_label: "Apt/Unit # (Optional)",
    about_me_questions: [
      {
        id: 7,
        title: "Please describe your housing situation",
        question: "Please describe your housing situation"
      },
      {
        id: 8,
        title: "What is your home address?",
        question: "What is your home address?"
      },
      {
        id: 9,
        title: "How many people including yourself live in your household?",
        question: "How many people including yourself live in your household?"
      },
      {
        id: 10,
        title: "What is your Marital Status?",
        question: "What is your Marital Status?"
      },
      {
        id: 11,
        title: "What language do you speak at home?",
        question: "What language do you speak at home?"
      },
      {
        id: 12,
        title: "what is your preferred name?",
        question: "what is your preferred name?"
      },
      {
        id: 13,
        title: "What is your ethnicity?",
        question: "What is your ethnicity?"
      },
      {
        id: 14,
        title: "What is your Race?",
        question: "What is your Race?"
      },
      {
        id: 15,
        title: "What is your gender?",
        question: "What is your gender?"
      },
      // {
      //   id: 16,
      //   title: "Upload a profile image",
      //   question: "Upload a profile image"
      // },
      {
        id: 17,
        title: "Who is your emergency contact?",
        question: "Who is your emergency contact?"
      },
      {
        id: 18,
        title: "Where can they be contacted?",
        question: "Where can they be contacted?"
      }
    ]
  };
  const { data, loading } = useStrapi({
    cms: `page-complete-profile`
  });

  const [cms, setCMS] = useState(init);

  useEffect(() => {
    if (loading) {
      setCMS(init);
      return;
    }
    setCMS((state) => ({
      heading: data.heading || state.heading,
      step_1_heading: data.step_1_heading || state.step_1_heading,
      step_1_subheading_lets_start:
        data.step_1_subheading_lets_start || state.step_1_subheading_lets_start,
      step_1_text_completing:
        data.step_1_text_completing || state.step_1_text_completing,
      step_1_text_minutes:
        data.step_1_text_minutes || state.step_1_text_minutes,
      btn_continue: data.btn_continue || state.btn_continue,
      step_2_heading: data.step_2_heading || state.step_2_heading,
      step_2_subheading_lets_continue:
        data.step_2_subheading_lets_continue ||
        state.step_2_subheading_lets_continue,
      step_2_text_take: data.step_2_text_take || state.step_2_text_take,
      step_2_text_minutes:
        data.step_2_text_minutes || state.step_2_text_minutes,
      step_3_heading: data.step_3_heading || state.step_3_heading,
      step_3_subheading_lets_capture:
        data.step_3_subheading_lets_capture ||
        state.step_3_subheading_lets_capture,
      step_3_text: data.step_3_text || state.step_3_text,
      complete_heading: /* data.complete_heading || */ state.complete_heading,
      complete_text: /* data.complete_text || */ state.complete_text,
      btn_take_checkin: data.btn_take_checkin || state.btn_take_checkin,
      btn_skip: data.btn_skip || state.btn_skip,
      error_text: data.error_text || state.error_text,
      btn_restart: data.btn_restart || state.btn_restart,
      btn_go_back: data.btn_go_back || state.btn_go_back,
      address_text: data.address_text || state.address_text,
      address_autocomplete_label:
        data.address_autocomplete_label || state.address_autocomplete_label,
      address_label: data.address_label || state.address_label,
      about_me_questions: data.about_me_questions || state.about_me_questions
    }));
  }, [data]);

  return { cms };
};

const CompletepProfileContext = createContext();

export const CompleteProfileProvider = (props) => {
  const { demographic } = useDemographicCMS();
  const { cms } = useCompleteProfileCMS();

  const value = {
    stepsCMS: cms,
    uiCMS: demographic
  };
  return <CompletepProfileContext.Provider value={value} {...props} />;
};

export const useCompleteProfile = () => {
  const context = useContext(CompletepProfileContext);
  if (!context)
    throw new Error(
      "useCompleteProfile must be used within CompleteProfileProvider"
    );
  return context;
};
