import { SkeletonText } from 'carbon-components-react';
import kit from 'ich-ui-kit';
import React, { useContext, useEffect, useState } from 'react';
import ProviderCard from '../../../components/ServiceCard';
import { CapacityIcon } from '../../../components/ServiceCard/static';
import { parseHiddenText, parseHtml } from '../../../utils';
import {
  AccountInfo,
  AccountRow,
  AccountRowSmall,
  AccountSubtitle,
  AccountTitle,
  AccountTitleContainer,
  AccountTitleRow,
  Card,
  Container,
  NotificationsGrid,
  SingleColumn,
  SpacerHalf,
  TitleRow
} from '../components';
import { ActionContainer } from './subpage.styled';

const ProviderInfo = ({ profile }) => {
  const { apiService } = useContext(kit.ApiContext);
  const [loading, setLoading] = useState(false);
  const [provider, setProvider] = useState({
    name: '',
    overview: '',
    url: ''
  });
  const [loadingSLLs, setLoadingSLLs] = useState(true);
  const [allSLL, setallSLL] = useState([]);
  const [services, setServices] = useState([]);
  const [setupComplete, setSetupComplete] = useState([]);
  const [isSetupComplete, setIsSetupComplete] = useState(false);
  const [getProviderData, setGetProviderData] = useState(false);
  const [numCompleted, setNumCompleted] = useState(0);
  const [openReferrals, setOpenReferrals] = useState([]);
  const { user } = useContext(kit.UserContext);

  useEffect(() => {
    getProviderInfo();
  }, [apiService, profile?.orgId, getProviderData]);

  useEffect(() => {
    let complete = 0;
    let total = services.length;
    services.map((service) => {
      if (service.stateOfWellbeing && service.capacity) {
        let filtered = allSLL.filter(
          (s) =>
            s?.serviceLocationId.toString() === service.serviceAtLocationKey.toString() &&
            s?.isLead === true
        );
        if (filtered.length > 0) {
          complete += 1;
        }
      }
    });

    setNumCompleted(complete);
    if (complete === total) {
      setIsSetupComplete(true);
    } else {
      setIsSetupComplete(false);
    }
  }, [services, allSLL]);

  useEffect(() => {
    const getServiceLineLeads = async () => {
      setLoadingSLLs(true);
      if (services.length) {
        // let serviceList = services.map((s) => s.serviceId)
        let serviceList = services.map((s) => s?.serviceAtLocationKey);
        serviceList = serviceList.join(',');
        const current = await apiService.get(
          `/account/service-line-leads?serviceLocationIds=${serviceList}&assumedId=${
            user.superAssumedRole?.leadId || ''
          }`
        );
        if (current.serviceLineLeads) {
          setallSLL(current.serviceLineLeads);
        }
        setLoadingSLLs(false);
      }
    } 
    getServiceLineLeads();
  }, [services]);

  const getProviderInfo = async () => {
    setLoading(true);
    const orgID = user.superAssumedRole?.orgId || user?.orgId;
    const userID = user.superAssumedRole?.leadId || user?.id;
    try {
      if (orgID !== '') {
        let providerInfo;
        if (user.isAdmin || user.superAssumedRole?.role === "admin") {
            providerInfo = await apiService.post(
              `/search/provider?org=${orgID}&userId=${userID}`
            );
        } else {
          providerInfo = await apiService.post(
            `/search/provider?assigned=${userID}`
          );
        }
        if (providerInfo.providers) {
          providerInfo = providerInfo.providers;
          let provURL = providerInfo.url
            ? providerInfo.url.toLowerCase()
            : null;

          if (provURL && provURL.indexOf('.') > 0 && provURL.indexOf('.') < 4) {
            provURL = 'https://' + provURL;
          }

          setProvider({ ...providerInfo, url: provURL });
          setServices(providerInfo.services);
          setLoading(false);
          // for each of the services, get all referrals and filter for open
          let referrals = await apiService.get(`/referrals?assumedId=${user.superAssumedRole?.leadId || ""}`);
          if (referrals && referrals.referralsByService && Array.isArray(referrals.referralsByService)) {
            let receivedRefs = referrals.referralsByService;
            // get only the open referrals
            receivedRefs = receivedRefs.filter(
              (ref) =>
                ref.status.includes('Waiting for') ||
                ref.status === 'Pending' ||
                ref.status.includes('About to Expire') ||
                ref.status.includes('Working')
            );
            setOpenReferrals(receivedRefs);
          }
        }
      } else {
        throw new Error('orgID is empty');
      }
    } catch (err) {
      console.log(err);
    }
  };


  return (
    <NotificationsGrid>
      <SingleColumn>
        <TitleRow>Directory Information</TitleRow>
        <Card moreWidth>
          <AccountTitleContainer>
            <AccountTitleRow>
              <AccountTitle>Provider Organization Details</AccountTitle>
              {/* {user && user.isAdmin && <StyledLink onClick={toggleEdit}>Edit</StyledLink>} */}
            </AccountTitleRow>
          </AccountTitleContainer>
          <Container>
            <AccountRow>
              <AccountSubtitle>Provider Name</AccountSubtitle>
            </AccountRow>
            <AccountRowSmall>
              <AccountInfo>
                {loading ? (
                  <SkeletonText style={{ width: '35%' }} />
                ) : (
                  provider.name
                )}
              </AccountInfo>
            </AccountRowSmall>
            <SpacerHalf />
            <AccountRow>
              <AccountSubtitle>Short Description</AccountSubtitle>
            </AccountRow>
            <AccountRowSmall>
              <AccountInfo>
                {loading ? (
                  <SkeletonText paragraph lineCount={3} />
                ) : (
                  parseHiddenText(parseHtml(provider.description))
                )}
              </AccountInfo>
            </AccountRowSmall>
            <SpacerHalf />
            <AccountRow>
              <AccountSubtitle>Website</AccountSubtitle>
            </AccountRow>
            <AccountRowSmall>
              <AccountInfo>
                {loading ? (
                  <SkeletonText style={{ width: '30%' }} />
                ) : (
                  provider.url && (
                    <a href={provider.url} target="_blank" rel="noreferrer noopener">
                      {provider.url}
                    </a>
                  )
                )}
              </AccountInfo>
            </AccountRowSmall>
            <SpacerHalf />
          </Container>
        </Card>
        <Card moreWidth>
          <AccountTitleContainer>
            <AccountTitleRow>
              <AccountTitle>Services</AccountTitle>
              {!loading && numCompleted === services.length ? (
                <ActionContainer style={{ margin: '0' }}>
                  <h5>Setup Complete</h5>
                  <CapacityIcon />
                </ActionContainer>
              ) : (
                <ActionContainer style={{ margin: '0' }}>
                  <h5>
                    {numCompleted}/{services.length} Services Setup
                  </h5>
                </ActionContainer>
              )}
            </AccountTitleRow>
          </AccountTitleContainer>
          {services.map((provider, index) => {
            return (
              <ProviderCard
                isSetupComplete={isSetupComplete}
                setupComplete={setupComplete}
                setSetupComplete={setSetupComplete}
                loadingSLLs={loadingSLLs}
                loading={loading}
                key={index}
                // slls={allSLL.filter((s)=> s?.servicelead === provider.serviceId)}
                slls={allSLL.filter(
                  (s) => parseInt(s.serviceLocationId)
                  === provider.serviceAtLocationKey
                )}
                provider={provider}
                setGetProviderData={setGetProviderData}
                getProviderData={getProviderData}
                openReferrals={openReferrals.filter(
                  (ref) =>
                    ref.serviceId === provider.serviceId &&
                    ref.locationId === provider.locationId
                )}
              />
            );
          })}
        </Card>
      </SingleColumn>
    </NotificationsGrid>
  );
};
export default ProviderInfo;
