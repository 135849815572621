import { useState, useEffect, useContext } from 'react';
import kit from 'ich-ui-kit';
import { CardContainer, CardTitle, CardValue, FlatCard } from "../overview-cards";
import { SkeletonPlaceholder } from "carbon-components-react";

const OverviewCardsCallLogs = () => {
  const { apiService } = useContext(kit.ApiContext);
  const [isLoading, setIsLoading] = useState(false);
  const { user } = useContext(kit.UserContext);

  const [cardsData, setCardsData] = useState([
    {
      text: "Call Logs Sent",
      value: 0
    },
    {
        text: "Resources Sent",
        value: 0
    }
  ]);

  useEffect(() => {
    const getData = async () => {
        setIsLoading(true);
        const stats = await apiService.get(`/stats/calllogs?assumedId=${user.superAssumedRole?.leadId || ""}`);
        if(stats.ok){
          const shallow = cardsData;
          shallow.map((el, i) => {
            switch(i){
              case 0:
                el.value = stats.data.callLogCount;
                break;
              case 1:
                el.value = stats.data.serviceCount;
                break;
            }
          });
          setCardsData(shallow);
        }        
        setIsLoading(false);
    }
    getData();
}, []);

  return (
    <CardContainer>
        {cardsData.map((el) => (
          <FlatCard noHover>
              <CardTitle>{el.text}</CardTitle>
              {isLoading ? <SkeletonPlaceholder/> : <CardValue>{el.value}</CardValue>}
          </FlatCard>
        ))}
    </CardContainer>
  );
};

export default OverviewCardsCallLogs;
