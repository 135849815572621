import React, { useState, useContext } from "react";
import { useTheme } from "styled-components";
import {
  Checkbox,
  RadioButtonGroup,
  RadioButton
} from "carbon-components-react";
import { ChevronLeft32 } from "@carbon/icons-react";
import * as S from "./sidebar.styled";
import { SearchContext } from "../context/SearchResultsContext.context";

export const FilterContainer = ({ heading, children, className, ...otherProps }) => (
  <S.SectionContainer {...otherProps}>
    <S.SectionHeading>{heading}</S.SectionHeading>
    <S.SectionContent>
      <S.SectionFieldset className={className}>{children}</S.SectionFieldset>
    </S.SectionContent>
  </S.SectionContainer>
);

export const Sidebar = ({
  open,
  category,
  setCategory,
  zip,
  language,
  age,
  setSelected,
  clearSearch,
  children
}) => {
  const theme = useTheme();

  const [viewMore, setViewMore] = useState(false);

  const { setSearchClearedClicked } = useContext(SearchContext);

  const determineValue = () => {
    let found = zip.get.find(({ checked }) => checked);
    if (found) {
      return found.score;
    } else {
      return "default-selected";
    }
  };

  return (
    <S.Container
      theme={{
        ...theme,
        isOpen: open.isOpen
      }}
    >
      <S.Header>
        <S.Clear
          onClick={() => {
            setSelected("");
            setCategory({
              domain: "",
              subCategory: [],
              isSubCategory: false
            });
            zip.set([
              { key: "1", label: "Within 5 miles", score: 1, checked: false },
              { key: "2", label: "Within 10 miles", score: 2, checked: false },
              { key: "3", label: "Within 15 miles", score: 3, checked: false },
              { key: "4", label: "Within 20 miles", score: 4, checked: false },
              {
                key: "5",
                label: "Less than 25 miles",
                score: 5,
                checked: false
              },
              {
                key: "6",
                label: "More than 25 miles",
                score: 6,
                checked: false
              }
            ]);
            language.set([
              { key: "spanish", value: "Spanish", checked: false },
              {
                key: "sign",
                value: "American Sign Language/ASL ",
                checked: false
              },
              { key: "russian", value: "Russian", checked: false },
              { key: "french", value: "French", checked: false },
              { key: "italian", value: "Italian", checked: false },
              { key: "somali", value: "Somali", checked: false },
              { key: "arabic", value: "Arabic", checked: false },
              { key: "chinese", value: "Chinese", checked: false },
              { key: "german", value: "German", checked: false },
              { key: "vietnames", value: "Vietnamese", checked: false },
              { key: "korean", value: "Korean", checked: false },
              { key: "hindi", value: "Hindi", checked: false }
            ]);
            age.set([
              {
                key: "09",
                label: "0 - 9 Child",
                min: 0,
                max: 9,
                checked: false
              },
              {
                key: "1017",
                label: "10 - 17 Youth",
                min: 10,
                max: 17,
                checked: false
              },
              {
                key: "1825",
                label: "18 - 25 Young Adult",
                min: 18,
                max: 25,
                checked: false
              },
              {
                key: "2654",
                label: "26 - 54 Adult",
                min: 26,
                max: 54,
                checked: false
              },
              {
                key: "550",
                label: "55+ Older Adult",
                min: 55,
                max: 0,
                checked: false
              }
            ]);
            clearSearch();
            setSearchClearedClicked(true);
          }}
        >
          Clear Filters
        </S.Clear>
        <S.CloseBtn
          aria-label="Close"
          hasIconOnly
          renderIcon={ChevronLeft32}
          onClick={() => {
            open.setIsOpen((state) => !state);
          }}
        />
      </S.Header>
      {children}
      {category?.domain ? (
        <S.SectionContainer>
          <S.SectionHeading>{category.domain}</S.SectionHeading>
          <S.SectionContent>
            {category.subCategory === undefined
              ? undefined
              : category?.subCategory
                  .sort((sub) => (sub.checked ? -1 : 1))
                  .map((sub) => {
                    const key = Object.keys(sub)[0];
                    if (category?.isSubCategory && !sub.checked) return;
                    return (
                      <Checkbox
                        id={key}
                        onClick={() => {
                          if (category?.isSubCategory && sub.checked) {
                            setCategory({
                              domain: "",
                              subCategory: [],
                              isSubCategory: false
                            });
                            setSelected("");
                            return;
                          }
                          setCategory({
                            domain: category.domain,
                            subCategory: category.subCategory.map((s) => {
                              const k = Object.keys(s)[0];
                              if (k === key) {
                                s.checked = !s.checked;
                                return s;
                              }
                              return s;
                            }),
                            isSubCategory: category?.isSubCategory
                          });
                        }}
                        checked={sub.checked}
                        value={key}
                        labelText={sub[key]}
                      />
                    );
                  })}
          </S.SectionContent>
        </S.SectionContainer>
      ) : undefined}
      <S.SectionContainer>
        <S.SectionHeading>Languages</S.SectionHeading>
        <S.SectionContent>
          <S.SectionFieldset className="languages">
            {language.get
              .slice(0, viewMore ? language.get.length : 2)
              .map((item) => {
                return (
                  <Checkbox
                    id={item.key}
                    onClick={() => {
                      const idx = language.get.findIndex(
                        (lang) => lang.key === item.key
                      );
                      language.set((state) => {
                        const temp = [...state];
                        temp[idx] = {
                          ...temp[idx],
                          checked: !temp[idx].checked
                        };
                        return temp;
                      });
                    }}
                    checked={item.checked}
                    value={item.value}
                    labelText={item.value}
                  />
                );
              })}
            <h5
              style={{
                cursor: "pointer"
              }}
              onClick={() => {
                setViewMore((state) => !state);
              }}
            >
              {viewMore ? "View Less" : "View More"}
            </h5>
          </S.SectionFieldset>
        </S.SectionContent>
      </S.SectionContainer>
      <S.SectionContainer>
        <S.SectionHeading>Age Ranges</S.SectionHeading>
        <S.SectionContent>
          <S.SectionFieldset className="age-ranges">
            {age.get.map((item) => (
              <Checkbox
                id={item.key}
                onClick={() => {
                  const idx = age.get.findIndex((el) => el.key === item.key);
                  age.set((state) => {
                    const temp = [...state];
                    temp[idx] = {
                      ...temp[idx],
                      checked: !temp[idx].checked
                    };
                    return temp;
                  });
                }}
                checked={item.checked}
                value={item.value}
                labelText={item.label}
              />
            ))}
          </S.SectionFieldset>
        </S.SectionContent>
      </S.SectionContainer>
      <S.SectionContainer>
        <S.SectionHeading>Distance</S.SectionHeading>
        <S.SectionContent>
          <S.SectionFieldset>
            <RadioButtonGroup
              legend="Group Legend"
              name="radio-button-group"
              orientation="vertical"
              valueSelected={determineValue()}
            >
              <RadioButton
                id="no-filter"
                onClick={() => {
                  zip.set((state) => {
                    const temp = state.map((x) => ({ ...x, checked: false }));
                    return temp;
                  });
                }}
                value="default-selected"
                labelText="None"
              />
              {zip.get.map((item) => (
                <RadioButton
                  id={item.key}
                  onClick={() => {
                    zip.set((state) => {
                      const temp = state.map((x) => {
                        if (x.key === item.key) {
                          return { ...x, checked: true };
                        } else {
                          return { ...x, checked: false };
                        }
                      });
                      return temp;
                    });
                  }}
                  value={item.score}
                  labelText={item.label}
                />
              ))}
            </RadioButtonGroup>
            {/* {zip.get.map((item) => (
              <Checkbox
                id={item.key}
                onClick={() => {
                  const idx = zip.get.findIndex((el) => el.key === item.key);
                  zip.set((state) => {
                    const temp = [...state];
                    temp[idx] = {
                      ...temp[idx],
                      checked: !temp[idx].checked
                    };
                    return temp;
                  });
                }}
                checked={item.checked}
                value={item.value}
                labelText={item.label}
              />
            ))} */}
          </S.SectionFieldset>
        </S.SectionContent>
      </S.SectionContainer>
    </S.Container>
  );
};
