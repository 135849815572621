import React, { useState, useContext, useEffect } from "react";
import kit from "ich-ui-kit";

import { Loading, TableCell } from "carbon-components-react";

import { BookmarkAdd20, BookmarkFilled20 } from "@carbon/icons-react";

import { CustomBookmarkBtn } from "./static.jsx";

const BookmarkButton = ({
  bookmarked,
  addBookmark,
  removeBookmark,
  setClients,
  isSuper
}) => {
  return bookmarked ? (
    <CustomBookmarkBtn
      hasIconOnly
      iconDescription="Un-Bookmark"
      kind="tertiary"
      onClick={removeBookmark}
      disabled={isSuper}
    >
      <BookmarkFilled20 />
    </CustomBookmarkBtn>
  ) : (
    <CustomBookmarkBtn
      hasIconOnly
      iconDescription="Bookmark"
      kind="tertiary"
      onClick={addBookmark}
      disabled={isSuper}
    >
      <BookmarkAdd20 />
    </CustomBookmarkBtn>
  );
};

const BookmarkRow = ({ client, clientId, setClients, isSuper }) => {
  const [width, setWidth] = useState(window.innerWidth);
  const isMobile = 500;
  useEffect(() => {
    const handleResizeWindow = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleResizeWindow);
    return () => {
      window.removeEventListener("resize", handleResizeWindow);
    };
  }, []);

  const [bookmarkLoading, setBookmarkLoading] = useState(false);
  const [clientBookmarked, setClientBookmarked] = useState(client);
  const { apiService } = useContext(kit.ApiContext);
  const { user } = useContext(kit.UserContext);

  const addBookmark = async () => {
    try {
      setBookmarkLoading(true);
      const { id } = await apiService.post(
        `/bookmarks/clients?id=${clientId}&assumedId=${user.superAssumedRole?.leadId || ""}`
      );
      const clients = await apiService.get("/clients");
      setClients(clients);
      if (id) {
        setClientBookmarked(true);
      }
    } catch (err) {
      console.log(err);
    }
    setBookmarkLoading(false);
  };

  const removeBookmark = async () => {
    try {
      setBookmarkLoading(true);
      const { id } = await apiService.delete(
        `/bookmarks/clients?id=${clientId}&assumedId=${user.superAssumedRole?.leadId || ""}`
      );
      const clients = await apiService.get("/clients");
      setClients(clients);

      if (id) {
        setClientBookmarked(false);
      }
    } catch (err) {
      console.log(err);
    }
    setBookmarkLoading(false);
  };

  return (
    <TableCell>
      {bookmarkLoading ? (
        <div
          style={{
            marginTop: "auto",
            marginBottom: "auto",
            marginLeft: "4.2rem",
            width: "35px",
          }}
        >
          <Loading small={true} withOverlay={false} description={Loading} />
        </div>
      ) : (
        <BookmarkButton
          addBookmark={addBookmark}
          removeBookmark={removeBookmark}
          bookmarked={clientBookmarked}
          isSuper={isSuper}
        />
      )}
    </TableCell>
  );
};

export default BookmarkRow;
