import Address from "./Address";
import MaritalStatus from "./MaritalStatus";
import LanguageSpoken from "./LanguageSpoken";
import Nickname from "./Nickname";
import Ethnicity from "./Ethnicity";
import GenderIdentity from "./GenderIdentity";
import ContactInfo from "./ContactInfo";
import ProfilePicture from "./ProfilePicture";
import HousingSituation from "./HousingSituation";
import HousingOccupancy from "./HousingOccupancy";
import Race from "./Race";
import ContactInfo2 from "./ContactInfo2";

const VariableInterface = ({
  index,
  setEmergencyContactInfo,
  setAboutMeResponse,
  setDisableButton,
  setFile,
  setHousingSituation,
  setHousingOccupancy,
  housingOccupancy,
}) => {
  const UI = [
    <HousingSituation
      setAboutMeResponse={setAboutMeResponse}
      setHousingSituation={setHousingSituation}
      setDisableButton={setDisableButton}
    />,
    <Address
      setAboutMeResponse={setAboutMeResponse}
      setDisableButton={setDisableButton}
    />,
    <HousingOccupancy
      housingOccupancy={housingOccupancy}
      setHousingOccupancy={setHousingOccupancy}
      setDisableButton={setDisableButton}
    />,
    <MaritalStatus
      setAboutMeResponse={setAboutMeResponse}
      setDisableButton={setDisableButton}
    />,
    <LanguageSpoken
      setAboutMeResponse={setAboutMeResponse}
      setDisableButton={setDisableButton}
    />,
    <Nickname
      setAboutMeResponse={setAboutMeResponse}
      setDisableButton={setDisableButton}
    />,
    <Ethnicity
      setAboutMeResponse={setAboutMeResponse}
      setDisableButton={setDisableButton}
    />,
    <Race
      setAboutMeResponse={setAboutMeResponse}
      setDisableButton={setDisableButton}
    />,
    <GenderIdentity
      setAboutMeResponse={setAboutMeResponse}
      setDisableButton={setDisableButton}
    />,
    // <ProfilePicture
    //   setAboutMeResponse={setAboutMeResponse}
    //   setFile={setFile}
    //   setDisableButton={setDisableButton}
    // />,
    <ContactInfo
      setEmergencyContactInfo={setEmergencyContactInfo}
      setFile={setFile}
      setDisableButton={setDisableButton}
    />,
    <ContactInfo2
      setEmergencyContactInfo={setEmergencyContactInfo}
      setFile={setFile}
      setDisableButton={setDisableButton}
  />,
  ];
  return UI[index];
};

export default VariableInterface;
