import styled from "styled-components";
import { Button, Dropdown, TextInput } from "carbon-components-react";

export const Wrapper = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  padding-right: 0.5rem;
  @media screen and (min-width: 1800px) {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
`;

export const StyledButton = styled(Button)`
  margin-right: ${(props) => (
    props.theme.margin ? props.theme.margin : "3rem"
  )};
  padding-right: ${(props) => (
    props.theme.padding ? props.theme.padding : "3rem"
  )};
  width: ${(props) => (
    props.theme.width
  )};
  max-height: 105px;
  @media screen and (max-width: 768px) {
    margin-right: 2rem;
  }
  color: ${(props) => (
    props.theme.bcolor ? "white" : "rgba(0,145,179,1)"
  )};
  background-color: ${(props) => (
    props.theme.bcolor ? "#0f62fe" : "white"
  )};
  :hover {
    color: "white";
    background-color: ${(props) => (
      props.theme.bcolor ? "white" : "rgba(0,145,179,1)"
    )};
    }
  :focus {
    color: ${(props) => (
      props.theme.bcolor ? "white" : "rgba(0,145,179,1)"
    )};
    background-color: ${(props) => (
      props.theme.bcolor ? "#0f62fe" : "white"
  )};
  :active {
    color: "white" !important;
    background-color: "#0f62fe"
  }

`;

export const ButtonGroup = styled.div`
  display: flex;
  // width: 80%;
  // justify-content: space-around;
  @media screen and (max-width: 768px) {
    flex-direction: column;
    width: 100%
    height: 100%;
  }
`;

export const DropdownWrapper = styled.div`
  margin: 1rem 0rem 2rem 0rem;
`;

export const StyledDropdown = styled(Dropdown)`
  width: 250px;
@media screen and (max-width: 768px) {

}
`;

export const RadioButtonGroup = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  @media screen and (max-width: 600px) {
    flex-direction: column;
    justify-content: space-evenly;
    height: 100%;
  }
`;

export const SpanL = styled.span `
  margin-right: 1rem;
`;

export const SpanR = styled.span `
  margin-left: 1rem;
`;

export const TextInputWrapper = styled.div`
  width: 100%;
  margin-top: 1rem;
  margin-bottom: 6rem;
  @media screen and (min-height: 880px) and (min-width: 1800px) {
    margin-bottom: 10rem;
  }
  @media screen and (max-width: 768px) {
    margin-bottom: 0rem;
    margin-top: 0rem;
    .bx--form-item {
      align-items: left;
      display: inline;
    }
    .bx--text-input {
      margin-top: 0rem;
      margin-right: 1rem;
      font-size: 12px;
      height: 30px;
    }
  }
`;

export const ButtonWrapper = styled.div`
  margin-top: 5%;
  display: flex;
  width: 40%;
  justify-content: space-between;
  @media screen and (min-width: 1800px) {
    width: 35%;
  }
  @media screen and (max-width: 768px) {
    margin: 1rem 0rem 2rem 0rem;
    width: 50%;
  }
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-left: 0.8rem;
  height: 35%;
  @media screen and (min-height: 880px) and (min-width: 1800px) {
    height: 30%;
    margin-top: 1rem;
  }
  h4 {
    font-size: 18px;
    margin: 0 0 0.5rem 0;
  }
  h5 {
    margin: 0.5rem 0 7rem 0;
    font-size: 24px;
    font-weight: 400;
  }
  h2 {
    font-size: 30px;
    font-weight: 400;
    margin: 0 0 2rem 0;
  }
  @media screen and (max-width: 768px) {
    h4 {
      font-weight: 400;
      line-height: 1;
      font-size: 14px;
      // margin-top: 0.25rem;
      margin-bottom: 0.5rem;
    }
    h5 {
      margin: 0.5rem 0 0 0;
      font-size: 20px;
      font-weight: 400;
    }
    h2 {
      font-size: 30px;
      font-weight: 400;
      margin: 0;
    }
  }
  `;