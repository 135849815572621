import React, { useState, useEffect } from "react";

import {
  TableCell,
} from "carbon-components-react";

const ClientDateOfBirthRow = ({ client }) => {
  const [width, setWidth] = useState(window.innerWidth);
  const isMobile = 500;
  useEffect(() => {
    const handleResizeWindow = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleResizeWindow);
    return () => {
      window.removeEventListener("resize", handleResizeWindow);
    };
  }, []);

  return (
    <TableCell>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          marginTop: "0.5rem",
          marginBottom: "0.5rem",
        }}
      >
       <div
          style={{
            marginLeft: width < isMobile ? "0px" : "12px",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <p>{client}</p>
        </div>
      </div>
     </TableCell>
  );
};

export default ClientDateOfBirthRow;
