export const assessmentQuestions = [
  {
    text: "Dependent Care",
    domain: "dependent care",
    answer: 3,
  },
  {
    text: "Education",
    domain: "education",
    answer: 3,
  },
  {
    text: "Employment",
    domain: "employment",
    answer: 3,
  },
  {
    text: "Financial",
    domain: "financial",
    answer: 3,
  },
  {
    text: "Food",
    domain: "food",
    answer: 3,
  },
  {
    text: "Health",
    domain: "health",
    answer: 3,
  },
  {
    text: "Housing",
    domain: "housing",
    answer: 3,
  },
  {
    text: "Income",
    domain: "income",
    answer: 3,
  },
  {
    text: "Safety",
    domain: "safety",
    answer: 3,
  },
  {
    text: "Social Connections",
    domain: "social connections",
    answer: 3,
  },
  {
    text: "Transportation",
    domain: "transportation",
    answer: 3,
  },
];

export const monthsArray = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export const determineAssessmentSummary = (color) => {
  switch (color) {
    case "GREEN":
      return 5;
    case "LIGHT_GREEN":
      return 4;
    case "YELLOW":
      return 3;
    case "ORANGE":
      return 2;
    case "RED":
      return 1;
    default:
      return 0;
  }
};
