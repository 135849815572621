import React, { createContext, useContext, useState } from "react";
import kit from "ich-ui-kit";

// const LAT = 42.9179801;
// const LONG = -77.6202131;
const LAT = process.env.REACT_APP_MAP_CENTER_LAT || 43.0579801;
const LONG = process.env.REACT_APP_MAP_CENTER_LONG || -77.6172131;

export const SearchContext = createContext();

export const SearchProvider = ({ children }) => {
  const initialMapState = {
    center: [LONG, LAT],
    zoom: [8],
  };
  const { user } = useContext(kit.UserContext);
  const [searchResults, setSearchResults] = useState();
  const [mapState, setMapState] = useState(initialMapState);
  const [numberOfProviders, setNumberOfProviders] = useState();
  const [selectedProvider, setSelectedProvider] = useState(null);
  const [filterType, setFilterType] = useState();
  const [searchClicked, setSearchClicked] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [queryInput1, setQueryInput1] = useState("");
  const [queryInput2, setQueryInput2] = useState("");
  const [availSelected, setAvailSelected] = useState(false);
  const [iconHoverColor, setIconHoverColor] = useState("get");
  const [visibleResults, setVisibleResults] = useState([]);
  const [providerCardList, setProviderCardList] = useState(null);
  const [filteredList, setFilteredList] = useState();
  const [searchResultPages, setSearchResultPages] = useState();
  const [pageNumber, setPageNumber] = useState(1);
  const [numberOfPages, setNumberOfPages] = useState(0);
  const [openDropDown, setOpenDropDown] = useState(false);
  const [spanishSelected, setSpanishSelected] = useState(false);
  const [aslSelected, setAslSelected] = useState(false);
  const [languageSelected, setLanguageSelected] = useState(false);
  const [showStops, setShowStops] = useState(false);
  const [iconHovered, setIconHovered] = useState(false);
  const [bookmarkedSelected, setBookmarkSelected] = useState(false);
  const [bookmarkedProviderCardsList, setBookmarkedProviderCardsList] =
    useState();
  const [bookmarkedProvidersList, setBookmarkedProvidersList] = useState();
  const [bookmarkedIds, setBookmarkedIds] = useState([]);
  const [numberOfBookmarked, setNumberOfBookmarked] = useState();
  const [searchClearedClicked, setSearchClearedClicked] = useState(false);
  const [bookmarkEvent, setBookmarkEvent] = useState();
  const [activeReferralIds, setActiveReferralIds] = useState([]);
  const fetchSearchResults = async (
    searchWord,
    zipCode,
    userId,
    inputType, // user/sub/domain
    filters,
    filterType = null,
    isProvider
  ) => {
    resetMap();
    try {
      setIsLoading(true);
      let response;
      response = await fetch(
        `${process.env.REACT_APP_API_ENDPOINT}/search/provider?text=${searchWord}&zipCode=${zipCode}&inputType=${inputType}&filterType=${filterType}&filters[languages]=${filters.languages.join(',')}&filters[taxCodes]=${filters.taxCodes.join(',')}&filters[ageRanges]=${filters.ageRanges.join(',')}&filters[zipCodes]=${filters.zipCodes.join(',')}&userId=${userId}`,
        {
          method: "post",
          headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${user.accessToken} ${user.idToken}`
          }
        }
      );
      const data = await response.json();
      const { providers } = data;
      if (providers) {
        setSearchResults(Object.values(providers)[0]);
      }
      setQueryInput1(searchWord);
      setQueryInput2(zipCode);
      setIsLoading(false);
    } catch (err) {
      console.log(err);
      setIsLoading(false);
    }
    return;
  };

  const selectProvider = (provider) => {
    setSelectedProvider(provider);
    let long = provider.longitude || LONG;
    let lat = provider.latitude || LAT;
    setMapState({
      zoom: [14],
      center: [long, lat],
    });
  };

  const deselectProvider = () => {
    setSelectedProvider(null);
    resetMap();
  };

  const resetMap = () => {
    setMapState(initialMapState);
  };

  const applyAvailFilter = (list) => {
    return list.filter((item) => {
      return item.props.provider.capacity !== "RED";
    });
  };

  const filterByVisibleResults = (map) => {
    try {
      let renderedFeatures = map.queryRenderedFeatures({
        layers: ["Location-marker"],
      });

      let visibleResults = [];
      if (filteredList === undefined) {
        renderedFeatures.forEach((value) => {
          visibleResults.push(
            providerCardList.filter(
              (provider) =>
                provider.props.provider.locationId === value.properties.locId
            )
          );
        });
      } else {
        renderedFeatures.forEach((value) => {
          visibleResults.push(
            filteredList.filter(
              (provider) =>
                provider.props.provider.locationId === value.properties.locId
            )
          );
        });
      }
      let results = getUniqueFeatures(visibleResults.flat());
      results.sort((a, b) => {
        return Number(b.props.eligable) - Number(a.props.eligable);
      });
      if (results && results.length > 0) {
        results = sortByCapacity(results);
      }
      setVisibleResults(results);
    } catch (e) {
      console.log(e);
    }
  };

  const getUniqueFeatures = (features) => {
    const uniqueFeatures = [];
    const uniqueIds = new Set();
    features.forEach((feature) => {
      const id = feature.key;
      if (!uniqueIds.has(id)) {
        uniqueIds.add(id);
        uniqueFeatures.push(feature);
      }
    });
    return uniqueFeatures;
  };

  const sortByCapacity = (providers) => {
    // assumes providers are already sorted by ORG_REG
    let capacity = [],
      green = [],
      yellow = [],
      unknown = [],
      red = [];
    let count = 0;
    while (providers[count].ORG_REG === "1") {
      if (providers[count].CAPACITY === "GREEN") {
        green.push(providers[count]);
      } else if (providers[count].CAPACITY === "YELLOW") {
        yellow.push(providers[count]);
      } else if (providers[count].CAPACITY === "RED") {
        red.push(providers[count]);
      } else {
        unknown.push(providers[count]);
      }
      count++;
    }
    capacity.push(green);
    capacity.push(yellow);
    capacity.push(unknown);
    capacity.push(red);
    capacity = capacity.flat();
    providers = providers.slice(count);
    providers = capacity.concat(providers);
    return providers;
  };
  const searchInputIsNotEmpty = (_q1, _q2) => {
    return (_q1 !== "" && _q1) || (_q2 !== "" && _q2);
  };
  const clearSearch = () => {
    deselectProvider();
    setSearchResults();
    setNumberOfProviders();
    setFilterType();
    setSearchClicked(false);
    setIsLoading(false);
    setAvailSelected(false);
    setBookmarkSelected(false);
    setSearchResultPages();
    setPageNumber(1);
    setNumberOfPages(0);
    setVisibleResults([]);
    setProviderCardList();
    setLanguageSelected(false);
    setOpenDropDown(false);
    setAslSelected(false);
    setSpanishSelected(false);
  };
  return (
    <SearchContext.Provider
      value={{
        initialMapState,
        fetchSearchResults,
        setSearchResults,
        mapState,
        setMapState,
        searchResults,
        numberOfProviders,
        setNumberOfProviders,
        selectedProvider,
        setSelectedProvider,
        selectProvider,
        deselectProvider,
        setFilterType,
        filterType,
        setSearchClicked,
        searchClicked,
        isLoading,
        setIsLoading,
        queryInput1,
        setQueryInput1,
        queryInput2,
        setQueryInput2,
        availSelected,
        setAvailSelected,
        iconHoverColor,
        setIconHoverColor,
        providerCardList,
        setProviderCardList,
        filterByVisibleResults,
        visibleResults,
        setVisibleResults,
        resetMap,
        setFilteredList,
        searchResultPages,
        setSearchResultPages,
        pageNumber,
        setPageNumber,
        numberOfPages,
        setNumberOfPages,
        openDropDown,
        setOpenDropDown,
        spanishSelected,
        setSpanishSelected,
        aslSelected,
        setAslSelected,
        languageSelected,
        setLanguageSelected,
        searchInputIsNotEmpty,
        clearSearch,
        showStops,
        setShowStops,
        iconHovered,
        setIconHovered,
        bookmarkedSelected,
        setBookmarkSelected,
        bookmarkedProviderCardsList,
        setBookmarkedProviderCardsList,
        numberOfBookmarked,
        setNumberOfBookmarked,
        searchClearedClicked,
        setSearchClearedClicked,
        bookmarkedIds,
        setBookmarkedIds,
        bookmarkedProvidersList,
        setBookmarkedProvidersList,
        applyAvailFilter,
        bookmarkEvent,
        setBookmarkEvent,
        activeReferralIds,
        setActiveReferralIds,
      }}
    >
      {children}
    </SearchContext.Provider>
  );
};
