import React, { useContext, useEffect } from 'react';
import { useHistory } from "react-router";
import kit from "ich-ui-kit";
import { SearchContext } from "../../resources/context/SearchResultsContext.context";
import { useClient } from "../../../store/CallLogContext";

const CustomResultsView = ({
  className,
  children
}) => {

  const { user } = useContext(kit.UserContext);
  const { apiService } = useContext(kit.ApiContext);
  const {
    setBookmarkedIds,
    setBookmarkedProvidersList,
    setActiveReferralIds
  } = useContext(SearchContext);
  let history = useHistory();
  const { client } = useClient();

  const clientId = history.location.state?.patientId || client?.id || null;

  useEffect(() => {
    let isMounted = true;
    const getBookmarked = async () => {
      try {
        const bookmarked = await apiService.get(
          '/bookmarks/providers'
        );
        if (bookmarked?.providers && bookmarked?.providers.length > 0) {
          setBookmarkedProvidersList(bookmarked?.providers);
          setBookmarkedIds(bookmarked?.providers.map(p => `${p.serviceId}|${p.locationId}`));
        }
      } catch (err) {}
    };
    if (isMounted && user && user?.id) {
      getBookmarked();
    }

    return () => {
      isMounted = false;
    };
  }, [user, apiService ]);

  useEffect(() => {
    const getReferrals = async () => {
      try {
        const data = await apiService.get(`/referrals/client/services?clientId=${clientId}`);
        if (data && data.referrals) {
          setActiveReferralIds(data.referrals)
        }
      } catch (error) {
        console.log(error);
      }
    };
    if (clientId) {
      getReferrals();
    }
  }, [history, apiService]);

  return (
    <ul className={className}>{children}</ul>
)};

export default CustomResultsView;
