import {
  CardDiv,
  CardHeader,
  CardSubHeader,
  StyledToggle,
  ToggleHeaderContainer
} from "../index.styled";
import { Loading } from "carbon-components-react";
import { SimpleBarChart } from "@carbon/charts-react";
import "@carbon/charts/styles.css";
import { Diagram20 } from "@carbon/icons-react";

const BarChart = ({ title, subtitle, loading, barData, toggle }) => {
  const options = {
    animations: true,
    axes: {
      left: {
        mapsTo: "group",
        scaleType: "labels"
      },
      bottom: {
        mapsTo: "value"
      }
    },
    height: "300px",
    legend: {
      enabled: false
    },
    toolbar: {
      enabled: false
    },
    grid: {
      y: {
        enabled: false
      },
      x: {
        enabled: loading ? false : true
      }
    },
    tooltip: {
      groupLabel: "Domain",
      customHTML: (data) =>
        data[0] &&
        `<div style="display: flex; flex-direction: column; gap: 0.5rem; padding: 0.5rem; font-size: 14px;">
                    <span><strong>Domain:</strong> ${data[0].group}</span>
                    <span><strong>Value:</strong> ${data[0].value}</span>
                </div>`
    }
  };

  return (
    <CardDiv style={{ minHeight: "376px" }}>
      <ToggleHeaderContainer>
        <CardHeader>{title}</CardHeader>
        {toggle && (
          <StyledToggle
            onClick={toggle}
            size="sm"
            renderIcon={Diagram20}
            hasIconOnly
            iconDescription="Description"
            tooltipPosition="left"
          />
        )}
      </ToggleHeaderContainer>
      {subtitle && <CardSubHeader>{subtitle}</CardSubHeader>}
      <div
        style={{
          margin: "1rem 0.5rem 0rem 0.5rem",
          height: loading ? "70%" : "auto"
        }}
      >
        {loading ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%"
            }}
          >
            <Loading withOverlay={false} />
          </div>
        ) : (
          <SimpleBarChart data={barData} options={options} />
        )}
      </div>
    </CardDiv>
  );
};

export default BarChart;
