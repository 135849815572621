import { useState, useEffect } from "react";
import * as S from "../Questions.styles";

const Employment1 = ({
  cms,
  width,
  domain,
  setAllowContinue,
  employmentResponse,
  setEmploymentResponse,
  setFeedbackIndex,
  setOverallIndex
}) => {
  const buttonLabels = [
    "Employed Full-Time",
    "Employed Part-Time",
    "Employed Temporarily / Seasonally",
    "Not Employed",
    "Retired"
  ];

  const getTranslatedLabel = (label) => {
    switch (label) {
      case "Employed Full-Time":
        return cms.employement.btn_full_time;
      case "Employed Part-Time":
        return cms.employement.btn_part_time;
      case "Employed Temporarily / Seasonally":
        return cms.employement.btn_temporary;
      case "Not Employed":
        return cms.employement.btn_not_employed;
      case "Retired":
        return cms.employement.btn_retired;
      default:
        return "undefined";
    }
  };
  const [buttonSelection, setButtonSelection] = useState();
  useEffect(() => {
    if (employmentResponse.q1.answer === " ") {
      setAllowContinue(true);
    } else {
      setButtonSelection(employmentResponse.q1.answer);
      setAllowContinue(false);
    }
  }, []);

  const nextQuestion = (label) => {
    if (label === "Not Employed") {
      setFeedbackIndex((index) => index + 2);
      setOverallIndex((index) => index + 2);
    } else {
      setFeedbackIndex((index) => index + 1);
      setOverallIndex((index) => index + 1);
    }
  };

  return (
    <S.TextContainer>
      <h5>{cms.employement.question_1}</h5>
      <S.Wrapper>
        {width < 769 ? (
          <S.DropdownWrapper>
            <S.StyledDropdown
              ariaLabel="Dropdown"
              id="carbon-dropdown-example"
              items={buttonLabels}
              label={buttonSelection || "Select likelihood..."}
              onChange={(e) => {
                setButtonSelection(e.selectedItem);
                setEmploymentResponse((state) => ({
                  ...state,
                  q1: {
                    text: "1 What is your current employment status?",
                    answer: e.selectedItem
                  }
                }));
                nextQuestion(e.selectedItem);
              }}
            />
          </S.DropdownWrapper>
        ) : (
          <S.ButtonGroup>
            {buttonLabels.map((label, i) => {
              let bcolor;
              {
                buttonSelection === label ? (bcolor = true) : (bcolor = false);
              }
              return (
                <S.StyledButton
                  key={i}
                  theme={{
                    bcolor: bcolor,
                    width: "115px",
                    padding: "1rem",
                    margin: "1.5rem"
                  }}
                  kind="tertiary"
                  onClick={() => {
                    setEmploymentResponse((state) => ({
                      ...state,
                      q1: {
                        text: "1 What is your current employment status?",
                        answer: label
                      }
                    }));
                    setButtonSelection(label);
                    nextQuestion(label);
                  }}
                >
                  {getTranslatedLabel(label)}
                </S.StyledButton>
              );
            })}
          </S.ButtonGroup>
        )}
      </S.Wrapper>
    </S.TextContainer>
  );
};

export default Employment1;
