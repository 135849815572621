import Employment1 from "./Employment/Employment1";
import Employment2 from "./Employment/Employment2";
import Employment3 from "./Employment/Employment3";
import Employment4 from "./Employment/Employment4";
import Employment5 from "./Employment/Employement5";

import Transportation1 from "./Transportation/Transportation1";
import Transportation2 from "./Transportation/Transportation2";
import Transportation3 from "./Transportation/Transportation3";
import Transportation4 from "./Transportation/Transportation4";
import Transportation5 from "./Transportation/Transportation5";

import Income1 from "./Income/Income1";
import Income2 from "./Income/Income2";

import Question1 from "./Question1";
// import Question2 from "./Question2";
// import Question3 from "./Question3";
// import Question4 from "./Question4";
// import Question5 from "./Question5";
// import Question6 from "./Question6";

const VariableInterface = ({
  cms,
  width,
  index,
  domain,
  setEmploymentResponse,
  setIncomeResponse,
  setTransportationResponse,
  setAllowContinue,
  setFeedbackIndex,
  setOverallIndex,
  incomeResponse,
  transportationResponse,
  employmentResponse
}) => {
  let UI = [];
  switch (domain) {
    case "Transportation":
      UI = [
        <Employment1
          cms={cms}
          domain={domain}
          width={width}
          employmentResponse={employmentResponse}
          setEmploymentResponse={setEmploymentResponse}
          setAllowContinue={setAllowContinue}
          setFeedbackIndex={setFeedbackIndex}
          setOverallIndex={setOverallIndex}
        />,
        <Employment2
          cms={cms}
          domain={domain}
          width={width}
          employmentResponse={employmentResponse}
          setEmploymentResponse={setEmploymentResponse}
          setAllowContinue={setAllowContinue}
          setFeedbackIndex={setFeedbackIndex}
          setOverallIndex={setOverallIndex}
        />,
        <Employment3
          cms={cms}
          domain={domain}
          width={width}
          employmentResponse={employmentResponse}
          setEmploymentResponse={setEmploymentResponse}
          setAllowContinue={setAllowContinue}
          setFeedbackIndex={setFeedbackIndex}
          setOverallIndex={setOverallIndex}
        />,
        <Employment4
          cms={cms}
          domain={domain}
          width={width}
          employmentResponse={employmentResponse}
          setEmploymentResponse={setEmploymentResponse}
          setAllowContinue={setAllowContinue}
          setFeedbackIndex={setFeedbackIndex}
          setOverallIndex={setOverallIndex}
        />,
        <Employment5
          cms={cms}
          domain={domain}
          width={width}
          employmentResponse={employmentResponse}
          setEmploymentResponse={setEmploymentResponse}
          setAllowContinue={setAllowContinue}
          setFeedbackIndex={setFeedbackIndex}
          setOverallIndex={setOverallIndex}
        />,
        <Income1
          cms={cms}
          domain={domain}
          width={width}
          incomeResponse={incomeResponse}
          setIncomeResponse={setIncomeResponse}
          setAllowContinue={setAllowContinue}
          setFeedbackIndex={setFeedbackIndex}
          setOverallIndex={setOverallIndex}
        />,
        <Income2
          cms={cms}
          domain={domain}
          width={width}
          incomeResponse={incomeResponse}
          setIncomeResponse={setIncomeResponse}
          setAllowContinue={setAllowContinue}
          setFeedbackIndex={setFeedbackIndex}
          setOverallIndex={setOverallIndex}
        />,
        <Transportation1
          cms={cms}
          domain={domain}
          width={width}
          transportationResponse={transportationResponse}
          setTransportationResponse={setTransportationResponse}
          setAllowContinue={setAllowContinue}
          setFeedbackIndex={setFeedbackIndex}
          setOverallIndex={setOverallIndex}
        />,
        <Transportation2
          cms={cms}
          domain={domain}
          width={width}
          transportationResponse={transportationResponse}
          setTransportationResponse={setTransportationResponse}
          setAllowContinue={setAllowContinue}
          setFeedbackIndex={setFeedbackIndex}
          setOverallIndex={setOverallIndex}
        />,
        <Transportation4
          cms={cms}
          width={width}
          domain={domain}
          transportationResponse={transportationResponse}
          setTransportationResponse={setTransportationResponse}
          setAllowContinue={setAllowContinue}
          setFeedbackIndex={setFeedbackIndex}
          setOverallIndex={setOverallIndex}
        />,
        <Transportation5
          cms={cms}
          width={width}
          domain={domain}
          transportationResponse={transportationResponse}
          setTransportationResponse={setTransportationResponse}
          setAllowContinue={setAllowContinue}
          setFeedbackIndex={setFeedbackIndex}
          setOverallIndex={setOverallIndex}
        />,
        <Transportation3
          cms={cms}
          domain={domain}
          width={width}
          transportationResponse={transportationResponse}
          setTransportationResponse={setTransportationResponse}
          setAllowContinue={setAllowContinue}
          setFeedbackIndex={setFeedbackIndex}
          setOverallIndex={setOverallIndex}
        />,
      ];
      break;
    case "Employment":
      UI = [
        <Employment1
          cms={cms}
          domain={domain}
          width={width}
          employmentResponse={employmentResponse}
          setEmploymentResponse={setEmploymentResponse}
          setAllowContinue={setAllowContinue}
          setFeedbackIndex={setFeedbackIndex}
          setOverallIndex={setOverallIndex}
        />,
        <Employment2
          cms={cms}
          domain={domain}
          width={width}
          employmentResponse={employmentResponse}
          setEmploymentResponse={setEmploymentResponse}
          setAllowContinue={setAllowContinue}
          setFeedbackIndex={setFeedbackIndex}
          setOverallIndex={setOverallIndex}
        />,
        <Employment3
          cms={cms}
          domain={domain}
          width={width}
          employmentResponse={employmentResponse}
          setEmploymentResponse={setEmploymentResponse}
          setAllowContinue={setAllowContinue}
          setFeedbackIndex={setFeedbackIndex}
          setOverallIndex={setOverallIndex}
        />,
        <Employment4
          cms={cms}
          domain={domain}
          width={width}
          employmentResponse={employmentResponse}
          setEmploymentResponse={setEmploymentResponse}
          setAllowContinue={setAllowContinue}
          setFeedbackIndex={setFeedbackIndex}
          setOverallIndex={setOverallIndex}
        />,
        <Employment5
          cms={cms}
          domain={domain}
          width={width}
          employmentResponse={employmentResponse}
          setEmploymentResponse={setEmploymentResponse}
          setAllowContinue={setAllowContinue}
          setFeedbackIndex={setFeedbackIndex}
          setOverallIndex={setOverallIndex}
        />,
        <Income1
          cms={cms}
          domain={domain}
          width={width}
          incomeResponse={incomeResponse}
          setIncomeResponse={setIncomeResponse}
          setAllowContinue={setAllowContinue}
          setFeedbackIndex={setFeedbackIndex}
          setOverallIndex={setOverallIndex}
        />,
        <Income2
          cms={cms}
          domain={domain}
          width={width}
          incomeResponse={incomeResponse}
          setIncomeResponse={setIncomeResponse}
          setAllowContinue={setAllowContinue}
          setFeedbackIndex={setFeedbackIndex}
          setOverallIndex={setOverallIndex}
        />,
        <Transportation1
          cms={cms}
          domain={domain}
          width={width}
          transportationResponse={transportationResponse}
          setTransportationResponse={setTransportationResponse}
          setAllowContinue={setAllowContinue}
          setFeedbackIndex={setFeedbackIndex}
          setOverallIndex={setOverallIndex}
        />,
        <Transportation2
          cms={cms}
          domain={domain}
          width={width}
          transportationResponse={transportationResponse}
          setTransportationResponse={setTransportationResponse}
          setAllowContinue={setAllowContinue}
          setFeedbackIndex={setFeedbackIndex}
          setOverallIndex={setOverallIndex}
        />,
        <Transportation4
          cms={cms}
          width={width}
          domain={domain}
          transportationResponse={transportationResponse}
          setTransportationResponse={setTransportationResponse}
          setAllowContinue={setAllowContinue}
          setFeedbackIndex={setFeedbackIndex}
          setOverallIndex={setOverallIndex}
        />,
        <Transportation5
          cms={cms}
          width={width}
          domain={domain}
          transportationResponse={transportationResponse}
          setTransportationResponse={setTransportationResponse}
          setAllowContinue={setAllowContinue}
          setFeedbackIndex={setFeedbackIndex}
          setOverallIndex={setOverallIndex}
        />,
        <Transportation3
          cms={cms}
          domain={domain}
          width={width}
          transportationResponse={transportationResponse}
          setTransportationResponse={setTransportationResponse}
          setAllowContinue={setAllowContinue}
          setFeedbackIndex={setFeedbackIndex}
          setOverallIndex={setOverallIndex}
        />,
      ];
      break;
    case "Income":
      UI = [
        <Employment1
          cms={cms}
          domain={domain}
          width={width}
          employmentResponse={employmentResponse}
          setEmploymentResponse={setEmploymentResponse}
          setAllowContinue={setAllowContinue}
          setFeedbackIndex={setFeedbackIndex}
          setOverallIndex={setOverallIndex}
        />,
        <Employment2
          cms={cms}
          domain={domain}
          width={width}
          employmentResponse={employmentResponse}
          setEmploymentResponse={setEmploymentResponse}
          setAllowContinue={setAllowContinue}
          setFeedbackIndex={setFeedbackIndex}
          setOverallIndex={setOverallIndex}
        />,
        <Employment3
          cms={cms}
          domain={domain}
          width={width}
          employmentResponse={employmentResponse}
          setEmploymentResponse={setEmploymentResponse}
          setAllowContinue={setAllowContinue}
          setFeedbackIndex={setFeedbackIndex}
          setOverallIndex={setOverallIndex}
        />,
        <Employment4
          cms={cms}
          domain={domain}
          width={width}
          employmentResponse={employmentResponse}
          setEmploymentResponse={setEmploymentResponse}
          setAllowContinue={setAllowContinue}
          setFeedbackIndex={setFeedbackIndex}
          setOverallIndex={setOverallIndex}
        />,
        <Employment5
          cms={cms}
          domain={domain}
          width={width}
          employmentResponse={employmentResponse}
          setEmploymentResponse={setEmploymentResponse}
          setAllowContinue={setAllowContinue}
          setFeedbackIndex={setFeedbackIndex}
          setOverallIndex={setOverallIndex}
        />,
        <Income1
          cms={cms}
          domain={domain}
          width={width}
          incomeResponse={incomeResponse}
          setIncomeResponse={setIncomeResponse}
          setAllowContinue={setAllowContinue}
          setFeedbackIndex={setFeedbackIndex}
          setOverallIndex={setOverallIndex}
        />,
        <Income2
          cms={cms}
          domain={domain}
          width={width}
          incomeResponse={incomeResponse}
          setIncomeResponse={setIncomeResponse}
          setAllowContinue={setAllowContinue}
          setFeedbackIndex={setFeedbackIndex}
          setOverallIndex={setOverallIndex}
        />,
        <Transportation1
          cms={cms}
          domain={domain}
          width={width}
          transportationResponse={transportationResponse}
          setTransportationResponse={setTransportationResponse}
          setAllowContinue={setAllowContinue}
          setFeedbackIndex={setFeedbackIndex}
          setOverallIndex={setOverallIndex}
        />,
        <Transportation2
          cms={cms}
          domain={domain}
          width={width}
          transportationResponse={transportationResponse}
          setTransportationResponse={setTransportationResponse}
          setAllowContinue={setAllowContinue}
          setFeedbackIndex={setFeedbackIndex}
          setOverallIndex={setOverallIndex}
        />,
        <Transportation4
          cms={cms}
          width={width}
          domain={domain}
          transportationResponse={transportationResponse}
          setTransportationResponse={setTransportationResponse}
          setAllowContinue={setAllowContinue}
          setFeedbackIndex={setFeedbackIndex}
          setOverallIndex={setOverallIndex}
        />,
        <Transportation5
          cms={cms}
          width={width}
          domain={domain}
          transportationResponse={transportationResponse}
          setTransportationResponse={setTransportationResponse}
          setAllowContinue={setAllowContinue}
          setFeedbackIndex={setFeedbackIndex}
          setOverallIndex={setOverallIndex}
        />,
        <Transportation3
          cms={cms}
          domain={domain}
          width={width}
          transportationResponse={transportationResponse}
          setTransportationResponse={setTransportationResponse}
          setAllowContinue={setAllowContinue}
          setFeedbackIndex={setFeedbackIndex}
          setOverallIndex={setOverallIndex}
        />,
      ];
      break;
    default:
      UI = [
        <Question1
        // setDisableButton={setDisableButton}
        // setFeedbackResponse={setFeedbackResponse}
        // setAllowContinue={setAllowContinue}
        />
      ];
  }

  return UI[index];
};

export default VariableInterface;
