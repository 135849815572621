import React from 'react';
import { SelectItem } from 'carbon-components-react';
import * as S from './styled';

export const GetSupport = ({
  connected,
  providerEmail,
  activeReferralId,
  onNeedsRequest,
  onCreateReferral,
  onViewReferral,
  isCNResource,
  handleCNResource,
  hasCapacity=true
}) => {

  const getOptions = () => {
    const options = [
      { text: 'Provide Support', value: 'default' },
      { text: 'Send Needs Request', value: 'Send Needs Request' },
      { text: 'Create Referral', value: 'Create Referral' },
      { text: 'View Referral', value: 'View Referral' },
      { text: 'Request Assistance', value: 'Request Assistance'}
    ];

    let filteredOptions = providerEmail
      ? options
      : options.filter((option) => option.value !== 'Send Needs Request');

    filteredOptions = connected
      ? filteredOptions
      : filteredOptions.filter((option) => option.value !== 'Create Referral' ||
      option.value === 'Request Assistance');

    filteredOptions = activeReferralId
      ? filteredOptions.filter((option) => option.value !== 'Create Referral')
      : filteredOptions.filter((option) => option.value !== 'View Referral');

    filteredOptions = isCNResource
      ? filteredOptions
      : filteredOptions.filter(
          (option) => option.value !== "Request Assistance"
        );

    filteredOptions = hasCapacity
      ? filteredOptions
      : filteredOptions.filter((option) => option.value !== "Create Referral");
      
    return filteredOptions;
  };

  const handleSelectChange = (e) => {
    switch (e.target.value) {
      case 'Send Needs Request':
        onNeedsRequest();
        break;
      case 'View Referral':
        onViewReferral();
        break;
      case 'Create Referral':
        onCreateReferral();
        break;
      case 'Request Assistance':
        handleCNResource();
        break;
      default:
        break;
    }
  };

  const getSupportClicked = (e) => {
    e.stopPropagation();
  }

  const options = getOptions();

  return (
    <S.StyledSelect
      id='get-support-dropdown'
      data-id='get-support-dropdown'
      data-cy='get-support-dropdown'
      display={(options?.length > 1) ? 'true' : undefined }
      labelText=''
      aria-label={'Provide Support'}
      value="placeholder-item"
      onChange={(e) => {
        e.stopPropagation();
        handleSelectChange(e);
      }}
      onClick={getSupportClicked}
    >
      {options.map((option) => {
        return (
          <SelectItem
            key={option.text}
            aria-label={option.text}
            text={option.text}
            value={option.value}
            hidden={option.value === 'default'}
          />
        );
      })}
    </S.StyledSelect>
  );
};
