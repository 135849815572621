import React, { useState, useEffect } from "react";
import * as S from "./icon.styled";

const Icon = ({ name, provider, text, cmsData, colorBlind }) => {
  const [logo, setLogo] = useState({});

  const colors = {
    5: "#95c93d",
    4: "#4ac1e0",
    3: "#f4db5f",
    2: "#faa41a",
    1: "#ec5c29"
  };

  const domains = {
    housing: "/domains/housing.svg",
    health: "/domains/Health.svg",
    financial: "/domains/financial.svg",
    employment: "/domains/employment.svg",
    income: "/domains/legal.svg",
    education: "/domains/education.svg",
    food: "/domains/food.svg",
    transportation: "/domains/transportation.svg",
    safety: "/domains/safety.svg",
    socialconnections: "/domains/socialconnection.svg",
    dependentcare: "/domains/dependentcare.svg"
  };

  const displayText = {
    housing: cmsData.housing,
    health: cmsData.health,
    financial: cmsData.financial,
    employment: cmsData.employment,
    income: cmsData.income,
    education: cmsData.education,
    food: cmsData.food,
    transportation: cmsData.transportation,
    safety: cmsData.safety,
    socialconnections: cmsData.social_connections,
    dependentcare: cmsData.dependent_care
  };

  useEffect(() => {
    const color = colors[provider.answer];
    setLogo({
      icon: domains[name.toLowerCase().split(" ").join("")],
      text: text || name,
      color: color ?? "#e0e0e0"
    });
  }, [name]);

  const determineStatus = (status) => {
    switch(status){
      case "#95c93d":
        return 5;
      case "#4ac1e0":
        return 4;
      case "#f4db5f":
        return 3;
      case "#faa41a":
        return 2;
      case "#ec5c29":
        return 1;
      default:
        return 0;
    }
  }

  if(colorBlind){
    return (
      <S.Container style={{ margin: 0, alignSelf: "flex-start" }}>
          <S.ViewAllCircle theme={{ color: logo.color }}>
            <span>{determineStatus(logo.color)}</span>
            <S.DomainIcon src={logo.icon} alt={logo.text} />
          </S.ViewAllCircle>
        <S.Text>{displayText[name.toLowerCase().split(" ").join("")]}</S.Text>
      </S.Container>
    );
  } else {
    return (
      <S.Container style={{ margin: 0, alignSelf: "flex-start" }}>
        <S.Profile
          theme={{ color: logo.color }}
          style={{
            marginRight: 0,
            marginBottom: "0.5rem",
            width: "50px",
            height: "50px",
            padding: "0.75rem"
          }}
        >
          <img
            style={{ height: "100%", width: "100%", stroke: "#000" }}
            src={logo.icon}
          />
        </S.Profile>
        <S.Text>{displayText[name.toLowerCase().split(" ").join("")]}</S.Text>
      </S.Container>
    );
  }
};

export default Icon;
