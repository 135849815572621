export default function onlyZipSearchConfig({ searchTerm = '' }) {
  return {
    function_score: {
      query: {
        "match_all": {},
        query: searchTerm // <-- custom query - parsed on backend
      },
      score_mode: 'min'
    }
  };
}
