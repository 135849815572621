import { SideNavigation, StyledSideNavLink } from "../../components";
import { SideNav, SideNavItems } from "carbon-components-react";
import {
  User32,
  Document32,
  Credentials32,
  Notification32,
  Recording32,
  FaceAdd32,
  Badge32
} from "@carbon/icons-react";

const DesktopMenu = ({ user, setCurrentPage }) => {
  if (user.superAssumedRole?.leadId) {
    return (
      <SideNavigation>
        <SideNav
          expanded={true}
          aria-label="Side navigation"
          isChildOfHeader
          isPersistent={false}
          isFixedNav={true}
        >
          <SideNavigation>
            <SideNavItems>
              <StyledSideNavLink
                renderIcon={User32}
                onClick={() => {
                  setCurrentPage("Profile");
                }}
              >
                Profile
              </StyledSideNavLink>
              {user && user.superAssumedRole?.role !== "211_provider_admin" && (
                  <StyledSideNavLink
                    renderIcon={Document32}
                    onClick={() => {
                      setCurrentPage("ProviderInfo");
                    }}
                  >
                    Directory Info
                  </StyledSideNavLink>
              )}
              {user && (user.superAssumedRole?.role === "admin" || user.superAssumedRole?.role === "211_provider" || user.superAssumedRole?.role === "view_only") && (
                <StyledSideNavLink
                  renderIcon={Credentials32}
                  onClick={() => {
                    setCurrentPage("UserManagement");
                  }}
                >
                  Provider User Set Up
                </StyledSideNavLink>
              )}
              <>
              {user && !user.superAssumedRole?.role.includes("211") && (
                  <StyledSideNavLink
                    renderIcon={Badge32}
                    onClick={() => {
                      setCurrentPage("Badges");
                    }}
                  >
                    Badges
                  </StyledSideNavLink>
              )}
                  <StyledSideNavLink
                    renderIcon={Recording32}
                    onClick={() => {
                      setCurrentPage("LongitudinalRecord");
                    }}
                  >
                    Event History
                  </StyledSideNavLink>
                </>
            </SideNavItems>
          </SideNavigation>
        </SideNav>
      </SideNavigation>
    );
  } else {
    return (
      <SideNavigation>
        <SideNav
          expanded={true}
          aria-label="Side navigation"
          isChildOfHeader
          isPersistent={false}
          isFixedNav={true}
        >
          <SideNavigation>
            <SideNavItems>
              <StyledSideNavLink
                renderIcon={User32}
                onClick={() => {
                  setCurrentPage("Profile");
                }}
              >
                Profile
              </StyledSideNavLink>
              {user && user.isSuper && !user.isViewOnly ? (
                <StyledSideNavLink
                  renderIcon={Document32}
                  onClick={() => {
                    setCurrentPage("ProviderManagement");
                  }}
                >
                  Provider Management
                </StyledSideNavLink>
              ) : (
                !user.is211Admin && (
                  <StyledSideNavLink
                    renderIcon={Document32}
                    onClick={() => {
                      setCurrentPage("ProviderInfo");
                    }}
                  >
                    Directory Info
                  </StyledSideNavLink>
                )
              )}
              {user && (user.isAdmin || user.is211Admin || user.isViewOnly) && (
                <StyledSideNavLink
                  renderIcon={Credentials32}
                  onClick={() => {
                    setCurrentPage("UserManagement");
                  }}
                >
                  Provider User Set Up
                </StyledSideNavLink>
              )}
              {!user.is211Admin && !user.isSuper && !user.superAssumedRole?.orgId && !user.isViewOnly && (
                <StyledSideNavLink
                  renderIcon={Notification32}
                  onClick={() => {
                    setCurrentPage("Notifications");
                  }}
                >
                  Notification Preferences
                </StyledSideNavLink>
              )}
              {!user.isSuper && !user.is211Admin && (
                <>
                  <StyledSideNavLink
                    renderIcon={Badge32}
                    onClick={() => {
                      setCurrentPage("Badges");
                    }}
                  >
                    Badges
                  </StyledSideNavLink>
                  <StyledSideNavLink
                    renderIcon={Recording32}
                    onClick={() => {
                      setCurrentPage("LongitudinalRecord");
                    }}
                  >
                    Event History
                  </StyledSideNavLink>
                </>
              )}
              {!user.superAssumedRole?.orgId && !user.isViewOnly && (
                <StyledSideNavLink
                  renderIcon={FaceAdd32}
                  onClick={() => {
                    setCurrentPage("Invite");
                  }}
                >
                  Invite Community
                </StyledSideNavLink>
              )}
            </SideNavItems>
          </SideNavigation>
        </SideNav>
      </SideNavigation>
    );
  }

};

export default DesktopMenu;
