import React, { useState } from "react";
import "./create-account.css";
import { useHistory } from "react-router";

import {
  Content,
  Container1,
  ContainerTitle,
  ContainerCareTeam,
  ContainerHelp,
  ContainerBenefits,
  ContainerThrive,
  EmbedFrame,
  TitleBox,
  // PASeal,
  ImageGroup,
  TitleParagraph,
  Page2Title,
  ThriveTitle,
  BenefitsMiddle,
  BenefitsLeft,
  BenefitsRight,
  ContainerCommunity,
  ImageMap,
  RightCol,
  LeftCol,
  FormBox,
  QuoteDiv,
  QuoteCard,
} from "./components";

import useForm from "../../hooks/useForm";
import validate from "./validator";

const CreateAccountPage = () => {
  const { values, setValues, handleChange, handleSubmit } = useForm(validate);
  const history = useHistory();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const dropdownItems = ["Option 1", "Option 2", "Option 3", "Option 4"];
  return (
    <Content>
      <Container1>
        <TitleBox>
          <h1>
            <strong>Support your Community</strong>
          </h1>
          <TitleParagraph>
            MyWayfinder helps providers take care of the community they serve.
          </TitleParagraph>
          <ImageGroup src="/image.png"></ImageGroup>
        </TitleBox>
        <FormBox>
          <EmbedFrame>
            <h5>
              <strong>Create a Provider Account</strong>
            </h5>
            <iframe
              title="Create a Provider Account"
              src={process.env.REACT_APP_SIMPLIGOV_FORM_URL}
              width="100%"
              height="98%"
            ></iframe>
          </EmbedFrame>
        </FormBox>
      </Container1>
      <ContainerTitle>
        <Page2Title>
          <strong>What is MyWayfinder</strong>
        </Page2Title>
      </ContainerTitle>
      <ContainerCommunity>
        <LeftCol>
          <h3>
            <strong>
              With MyWayfinder, community members can find their way to better
              overall well-being by connecting with local service providers.
            </strong>
          </h3>
          <br />
          <h4>
            It's easy for community members to connect with local providers
            using MyWayfinder's free community resource directory. It helps
            people to find new service providers and helps them build closer
            relationships with their existing providers.
          </h4>
        </LeftCol>
        <RightCol>
          <ImageMap src="/image1.jpg"></ImageMap>
        </RightCol>
      </ContainerCommunity>
      <ContainerCareTeam>
        <LeftCol>
          <ImageMap src="/image2.jpg"></ImageMap>
        </LeftCol>
        <RightCol>
          <h3>
            <br />
            <strong>Building a CareTeam</strong>
          </h3>
          <br />
          <h4>
            Community members can add service providers to their personal
            CareTeam. This is how they can connect directly with an organization
            to build a relationship.
          </h4>
        </RightCol>
      </ContainerCareTeam>
      <ContainerHelp>
        <LeftCol style={{ paddingBottom: "2rem" }}>
          <h3>
            <br />
            <strong>CareTeams Mean Better Support</strong>
          </h3>
          <br />
          <h4>
            As part of an individual’s CareTeam, service providers can view a
            360-degree dashboard to better understand and support the needs of
            the individual or family accessing services. This dashboard may
            include more information about the household and their
            self-assessments.
          </h4>
        </LeftCol>
        <RightCol>
          <ImageMap src="/image3.png"></ImageMap>
        </RightCol>
      </ContainerHelp>
      <ContainerBenefits>
        <BenefitsLeft>
          <h4>
            <strong>Connect with New and Existing Community Members</strong>
          </h4>
        </BenefitsLeft>
        <BenefitsMiddle>
          <h4>
            <strong>Why Service Providers Love MyWayfinder</strong>
          </h4>
        </BenefitsMiddle>
        <BenefitsRight>
          <h4>
            <strong>Stronger Community Outcomes for Everyone</strong>
          </h4>
        </BenefitsRight>
      </ContainerBenefits>
      <ContainerThrive>
        <ThriveTitle>
          <strong>Provider Quotes</strong>
        </ThriveTitle>
        <QuoteDiv>
          <QuoteCard>
            <h4>
              <strong>Andrew Episcopo</strong>
            </h4>

            <p2>Educator for Rochester City School District</p2>

            <p2>
              Title I Remedial Reading Teacher at Mary Cariola Children's Center
              <br />
            </p2>
            <br />

            <p>
              <strong>
                "MyWayFinder is a revolutionary leap forward in quickly,
                discretely, and fully connecting people with the services and
                providers they need."
              </strong>
            </p>
          </QuoteCard>
          <QuoteCard>
            <h4>
              <strong>Leah Kazmirerski</strong>
            </h4>
            <p2>
              Founder/CEO of Restoration Rochester
              <br />
            </p2>
            <br />
            <p>
              <strong>
                "MyWayFinder is an amazing tool that allows our participants to
                receive coordinated and person-centered care from many different
                agencies. It really helps streamline the process of getting
                their needs met."
              </strong>
            </p>
          </QuoteCard>
          <QuoteCard>
            <h4>
              <strong>Carlos Santana</strong>
            </h4>
            <p2>
              Community Builder in Action at ACTION FOR A BETTER COMMUNITY
              <br />
            </p2>
            <br />
            <p>
              <strong>
                "With MyWayFinder I could assist the individual that comes to me
                for services. It helps me have all the information I need at my
                fingertips to provide the information they need for the problems
                they are facing - a 'One Stop Shop'."
              </strong>
            </p>
          </QuoteCard>
        </QuoteDiv>
      </ContainerThrive>
    </Content>
  );
};

export default CreateAccountPage;
