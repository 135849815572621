import React, { useState } from "react";
import { Select, SelectItem, TextArea, Button } from "carbon-components-react";
import { ChevronUp16, ChevronDown16, TrashCan16 } from "@carbon/icons-react";
import { CapacityBadge, EligableBadge } from "../../../../Badges/badges";
import { Card } from "../../../../Card/card";
import { needsStatus } from "../intakeTab/options";
import * as S from "../callLogPanel.styled";

const descrText = (_text) => {
  if (_text) {
    let res = new DOMParser().parseFromString(_text, "text/html");
    res = res.body.textContent;
    res = res.replace(/[\u{0080}-\u{FFFF}]/gu, "");
    if (res.length > 150) {
      return res.substring(0, 150) + "...";
    } else return res;
  }
  return "";
};

const ResourceCard = ({
  service,
  updateResourceNote,
  updateNeedsStatus,
  updateResources,
}) => {
  const [displayEligibility, setDisplayEligibility] = useState(false);
  const [displayNote, setDisplayNote] = useState(false);
  const [noteText, setNoteText] = useState('');
  const broadCastCartEvent = (resource) => {
    window.localStorage.setItem("cart-event", JSON.stringify(resource));
  };
  return (
    <Card style={{ minHeight: "30rem" }}>
      <S.TopRow>
        <S.ProviderText>
          <S.ServiceNameWrapper>
            <h4>{service.name || ""}</h4>
          </S.ServiceNameWrapper>
          <h5>{service.orgName || ""}</h5>
        </S.ProviderText>
        <Button
          kind="ghost"
          renderIcon={TrashCan16}
          iconDescription="Remove"
          hasIconOnly
          onClick={(e) => {
            e.stopPropagation();
            updateResources(service);
            broadCastCartEvent(service);
          }}
        />
      </S.TopRow>
      <S.MiddleRow>
        <div>
          <S.BadgeDiv>
            {service.connected && <EligableBadge eligable={service.connected} />}
            {service.connected && <CapacityBadge capacity={service.capacity} />}
          </S.BadgeDiv>
        </div>
      </S.MiddleRow>
      <S.BottomRow>
        <S.DescriptionText>
          <p>{descrText(service.overview)}</p>
          <S.AddressText>
            <p>
              <strong>
                {service.address ? service.address + ", " : ""}
                {service.city ? service.city + ", " : ""}
                {service.state ? service.state + ", " : ""}
                {service.zip ? service.zip : ""}
              </strong>
            </p>
          </S.AddressText>
        </S.DescriptionText>
        <S.Eligiblity
          onClick={(e) => {
            e.stopPropagation();
            setDisplayEligibility(!displayEligibility);
          }}
        >
          <S.ViewEligibility>
            <p>View Eligiblity</p>
            {!displayEligibility ? <ChevronDown16 /> : <ChevronUp16 />}
          </S.ViewEligibility>
          {displayEligibility && (
            <S.EligiblityText>
              <p>{service.eligibility}</p>
            </S.EligiblityText>
          )}
        </S.Eligiblity>
        <Select
          id="needsStatus"
          defaultValue=""
          labelText="Needs Status"
          onChange={(e) => {
            updateNeedsStatus(
              e.target.value,
              service.c360_orgId,
              service.locationId,
              service.serviceId
            );
          }}
        >
          {needsStatus.map(({ text, value }, i) => (
            <SelectItem key={i} text={text} value={value} />
          ))}
        </Select>
        <S.Eligiblity>
          <S.ViewEligibility
            onClick={(e) => {
              e.stopPropagation();
              setDisplayNote(!displayNote);
            }}
          >
            <p>Add Note</p>
            {!displayNote ? <ChevronDown16 /> : <ChevronUp16 />}
          </S.ViewEligibility>
          {displayNote && (
            <S.EligiblityText>
              <TextArea
                id={`${Math.random()}`}
                cols={90}
                maxLength={100}
                labelText="Note"
                hideLabel
                rows={3}
                value={noteText}
                onChange={(e) =>
                  setNoteText(e.target.value)
                }
                onBlur={(e) => {
                  updateResourceNote(
                    e.target.value,
                    service.c360_orgId,
                    service.locationId,
                    service.serviceId
                  )
                }}
              />
            </S.EligiblityText>
          )}
        </S.Eligiblity>
      </S.BottomRow>
    </Card>
  );
};

export default ResourceCard;
