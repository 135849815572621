import { useState, useEffect } from "react";

const useForm = (validate) => {
  const [values, setValues] = useState({});
  const [errors, setErrors] = useState({});
  const [isDisabled, setIsDisabled] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    handleSubmit();
  }, [values]);

  useEffect(() => {
    let disabled = isDisabled;
    Object.keys(values).map((key) => {
      if (values[key].length === 0 || !!errors[key]) {
        disabled = { ...disabled, [key]: true };
      } else {
        disabled = { ...disabled, [key]: false };
      }
    });

    setIsDisabled(disabled);
  }, [values, errors]);

  const handleSubmit = (e) => {
    // if (e) e.preventDefault();
    // console.log(values)
    setErrors(validate(values));
    // console.log(errors)
    setIsSubmitting(true);
  };

  const handleChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    setValues((values) => ({ ...values, [e.target.id]: e.target.value }));
  };

  const handlePhoneChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    let x = e.target.value
      .replace(/\D/g, "")
      .match(/(\d{0,3})(\d{0,3})(\d{0,4})/);

    setValues((values) => ({
      ...values,
      [e.target.id]: (e.target.value = !x[2]
        ? x[1]
        : x[1] + "-" + x[2] + (x[3] ? "-" + x[3] : "")),
    }));
  };

  const handleDatePicker = (arr, str) => {
    setValues({ ...values, dob: str });
  };

  const resetForm = () => {
    setValues({});
    setErrors({});
    setIsSubmitting(false);
  };

  const setEditValues = (val) => {
    setValues(val);
  };

  return {
    handleChange,
    handleSubmit,
    handlePhoneChange,
    handleDatePicker,
    values,
    errors,
    resetForm,
    isSubmitting,
    setEditValues,
    setValues,
    isDisabled,
    setIsDisabled,
    setErrors,
  };
};

export default useForm;
