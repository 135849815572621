import React, { useState, useEffect } from "react";
import * as S from "./icon.styled";

const Icon = ({ name, text, status, handleClick, isBad = false, cmsData }) => {
  const [logo, setLogo] = useState({});

  const colors = {
    5: "#95c93d",
    4: "#4ac1e0",
    3: "#f4db5f",
    2: "#faa41a",
    1: "#ec5c29"
  };

  const domains = {
    housing: "/domains/housing.svg",
    health: "/domains/Health.svg",
    financial: "/domains/financial.svg",
    employment: "/domains/employment.svg",
    income: "/domains/legal.svg",
    education: "/domains/education.svg",
    food: "/domains/food.svg",
    transportation: "/domains/transportation.svg",
    safety: "/domains/safety.svg",
    socialconnections: "/domains/socialconnection.svg",
    dependentcare: "/domains/dependentcare.svg"
  };
  const displayText = {
    housing: cmsData.housing,
    health: cmsData.health,
    financial: cmsData.financial,
    employment: cmsData.employment,
    income: cmsData.income,
    education: cmsData.education,
    food: cmsData.food,
    transportation: cmsData.transportation,
    safety: cmsData.safety,
    socialconnections: cmsData.social_connections,
    dependentcare: cmsData.dependent_care
  };

  useEffect(() => {
    const color = colors[status.toString()];
    setLogo({
      icon: domains[name.toLowerCase().split(" ").join("")],
      text: text,
      color: color
    });
  }, [name, status]);

  return (
    <S.Container
      onClick={handleClick}
      style={{
        margin: "0.5rem 0",
        cursor: handleClick ? "pointer" : "auto"
      }}
    >
          <S.ViewAllCircle theme={{ color: logo.color }}>
            <span>{status}</span>
            <S.DomainIcon src={logo.icon} alt={logo.text} />
          </S.ViewAllCircle>
      <S.Text>{displayText[name.toLowerCase().split(" ").join("")]}</S.Text>
    </S.Container>
  );
};

export default Icon;
