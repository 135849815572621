import { useEffect, useState } from "react";
import { useStrapi } from "../../hooks/useStrapi";

export const useContactCMS = () => {
  const init = {
    heading: "Get Help",
    items: [
      {
        id: 53,
        title: "Help Desk",
        content: "1-888-304-3703"
      },
      {
        id: 54,
        title: "211/LIFE LINE",
        content: "1-877-356-9211"
      }
    ],
    localizations: {
      data: []
    },
    background_image: {small: {url:""}, medium: {url: ""}, large: {url: ""}}
  };
  const { data, loading } = useStrapi({
    cms: `page-contact-us`
  });

  const [cms, setCMS] = useState(init);

  useEffect(() => {
    if (loading) {
      setCMS(init);
      return;
    }
    setCMS((state) => ({
      heading: data.heading || state.heading,
      items: data.items || state.items,
      background_image: data.background_image?.data?.attributes?.formats || state.background_image
    }));
  }, [data]);

  return { cms };
};
