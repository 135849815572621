import styled from "styled-components";
import { useEffect } from "react";
import { Button } from "carbon-components-react";
import { ChevronRight24 } from "@carbon/icons-react";
import { useHistory } from "react-router";
import { useDimension } from "../../../../hooks/useDimension";

const Complete = ({ feedbackResponse, cms, clientID, passClientInfo, setCompletedStatus}) => {
  const history = useHistory();
  const { windowSize } = useDimension();
  useEffect(() => {
    if (typeof setCompletedStatus === "function") {
      setCompletedStatus(true);
    }
  }, []);
  return (
    <Wrapper>
      {windowSize.width > 768 ? (
        <>
          <ImageWrapper>
            <img
              src="/icons/TakeAssessmentBadge.png"
              alt="new member badge"
              style={{
                height: "120px",
                width: "120px",
              }}
            />
          </ImageWrapper>
          <TextWrapper style={{ width: "70%" }}>
            <h4>
              {/* TODO: Replace with CMS version when created in  */}
              Great job! Check-in Assessment is now complete
              {passClientInfo?.firstName &&
                ` for ${
                  passClientInfo?.firstName ||
                  history.location?.state?.clientFirstName
                }`}
              .
            </h4>
          </TextWrapper>
        </>
      ) : (
        <>
          <TextWrapper style={{ width: "90%" }}>
            <h4>
              {/* TODO: Replace with CMS version when created in  */}
              Great job! Check-in Assessment is now complete
              {passClientInfo?.firstName &&
                ` for ${
                  passClientInfo?.firstName ||
                  history.location?.state?.clientFirstName
                } ${
                  passClientInfo?.lastName ||
                  history.location?.state?.clientLastName
                }`}
              .
            </h4>
          </TextWrapper>
          <ImageWrapper>
            <img
              src="/icons/TakeAssessmentBadge.png"
              alt="New Member Badge"
              style={{
                height: "145px",
                width: "145px",
              }}
            />
          </ImageWrapper>
        </>
      )}
      <InterfaceContainer>
        <TextWrapper style={{ width: "auto" }}>
          <p>Below, we have included check-in summary and recommendations</p>

          <p>
            You can now complete a Well-Being Assessment
            {passClientInfo?.firstName &&
              ` on behalf of ${
                passClientInfo?.firstName ||
                history.location?.state?.clientFirstName
              }`}
            .
          </p>
        </TextWrapper>
      </InterfaceContainer>
      <ButtonWrapper>
        <StyledButton
          onClick={() => {
            if (typeof setCompletedStatus === "function") {
              setCompletedStatus(true);
            }
            history.push({
              pathname: `/client-enrollment/${passClientInfo.id}/well-being-assessment`,
              state: { data: true, client: passClientInfo.id },
            });
          }}
        >
          {/* {cms.btn_take_checkin}{" "} */} Well-Being Assessment
          {windowSize.width > 768 && <StyledChevronIcon />}
        </StyledButton>
        <Button
          kind="ghost"
          onClick={() => {
            setCompletedStatus(true);
            history.push({
              pathname: "/360dashboard",
              state: {
                client: passClientInfo.id,
              },
            });
          }}
        >
          {/* {cms.btn_skip} */} Skip
        </Button>
      </ButtonWrapper>
    </Wrapper>
  );
};

export default Complete;

const Wrapper = styled.div`
  background-color: white;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2rem;
  @media screen and (min-height: 880px) and (min-width: 1800px) {
    padding-top: 4rem;
  }
  @media screen and (max-width: 768px) {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
`;

const ImageWrapper = styled.div`
  width: fit-content;
  height: fit-content;
  @media screen and (max-width: 768px) {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 2rem;
    p {
      font-size: 12px;
      font-weight: 400;
    }
  }
`;

const TextWrapper = styled.div`
  margin-top: 1rem;
  margin-bottom: 1rem;
  text-align: center;
  p {
    font-size: 18px;
    font-weight: 400;
    text-align: left;
  }
  h4 {
    font-weight: 500;
    line-height: 1.3;
  }
  @media screen and (min-height: 880px) and (min-width: 1600px) {
    p {
      font-weight: 400;
      font-size: 20px;
      text-align: left;
    }
  }
  @media screen and (max-width: 768px) {
    width: 100%;
    align-items: center;
    margin-left: 1.25rem;
    margin-right: 1.25rem;
    p {
      font-weight: 400;
      font-size: 14px;
      text-align: left;
    }
  }
`;

const GoBackButton = styled.p`
  width: fit-content;
  color: yellowgreen;
  cursor: pointer;
  text-decoration: underline;
`;
const ButtonWrapper = styled.div`
  display: flex;
  width: 90%;
  // flex-direction: column;
  justify-content: center;
  /* margin-top: 7rem; */
  padding-right: 1rem;
  @media screen and (max-width: 768px) {
    flex-direction: column;
    height: 17%;
    width: 100%;
    align-items: center;
    padding-right: 0;
  }
`;
const StyledButton = styled(Button)`
  padding-right: 12px;
  margin-right: 2rem;
  @media screen and (max-width: 768px) {
    padding-right: 0;
    margin-right: 0;
    width: 20rem;
    justify-content: center;
  }
`;

const InterfaceContainer = styled.div`
  display: flex;
  height: 40%;
  @media screen and (min-height: 880px) and (min-width: 1800px) {
    height: 45%;
  }
  @media screen and (max-width: 768px) {
    display: block;
    padding-top: 2rem;
    height: 30%;
  }
`;

const StyledChevronIcon = styled(ChevronRight24)`
  margin-left: 2rem;
  height: 20px;
`;
// const CompleteContainer = styled.div`
//   display: flex;
//   flex-direction: column;
//   height: 100%;
//   /* position: relative; */
// `;
// const TextWrapper = styled.div`
//   padding-left: 10px;
//   padding-top: 10px;
//   @media screen and (min-width: 1800px) {
//     padding-left: 30px;
//   }
// `;

// const ImageContainer = styled.div`
//   position: absolute;
//   top: 50%;
//   left: 50%;
//   transform: translate(-50%, -50%);
// `;

// const StyledImage = styled.img`
//   height: 200px;
//   width: 200px;
//   @media screen and (max-height: 700px) {
//     height: 150px;
//     width: 150px;
//   }
// `;
