import styled from "styled-components";

export const Container = styled.div`
  position: relative;
  overflow: hidden;
  width: 100%;
  min-height: 100%;
`;
export const BannerContainer = styled.div`
  position: relative;
  margin-bottom: 2rem;
  height: 20rem;
  width: 100%;
  background-image: url(${(props) => props.bgImage || "carousel4.jpg"});
  background-size: cover;
  background-position: center center;
  display: flex;
  justify-content: center;
  align-items: center;
  @media ${(props) => props.theme.breakpoints.xs} {
    height: 30rem;
  }
  @media ${(props) => props.theme.breakpoints.sm} {
    height: 40rem;
  }
  @media ${(props) => props.theme.breakpoints.md} {
    height: 35rem;
  }
`;
export const Overlay = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
  z-index: 1;
`;
export const Heading = styled.h1`
  position: absolute;
  font-size: clamp(2.5rem, 6vw, 4.5rem);
  font-weight: 700;
  color: #ffffff;
  z-index: 2;
`;

export const Items = styled.div`
  position: relative;
  height: auto;
  margin: 0 auto;
  width: 100%;
  @media (min-width: 1280px) {
    width: 1000px;
    margin: 5rem auto;
  }
`;
