import styled from "styled-components";
import {
  Button,
  InlineNotification,
  Link,
  Select,
  Modal,
  Checkbox
} from "carbon-components-react";
import {
  CheckmarkOutline24,
  CheckmarkOutline16,
  Information16,
  Location16,
  Unknown16,
  WarningAlt24,
  Edit16

} from "@carbon/icons-react";
import { Card } from "../Card/card";

export const ProviderInfoCard = styled(Card)`
  h5, span {
    font-size: 16px;
    @media (max-width: 1200px) {
      font-size: 14px;
    }
    @media (max-width: 900px) {
      font-size: 16px;
    }
    @media (max-width: 700px) {
      font-size: 14px;
    }
  }
  h6 {
    font-size: 15px;
    margin-top: 0.5rem;
    @media (max-width: 1200px) {
      font-size: 12px;
    }
    @media (max-width: 900px) {
      margin-top: 0.75rem;
      font-size: 15px;
    }
    @media (max-width: 700px) {
      font-size: 12px;
    }
  }

  @media (max-width: 700px) {
    height: 20rem;
    width: min-content;
  }
  display: grid;
  grid-template-areas:
    "top-row top-row"
    "bottom-row bottom-row";
  }
`;

export const StyledLink = styled(Link)`
  color: black;
  margin-top: 0.5rem;
`;

export const CheckIcon = styled(CheckmarkOutline16)`
  margin-right: 0.5rem;
`;

export const WarningCapacityIcon = styled(CheckIcon)`
  fill: orange;
`;

export const AtCapacityIcon = styled(CheckIcon)`
  fill: red;
`;

export const CapacityIcon = styled(CheckmarkOutline24)`
  fill: #42be65;
  margin-left: 8px;
`;

export const Warn = styled(WarningAlt24)`
  fill: red;
  margin-left: 8px;
`;

export const CapacityIconSmall = styled(CheckIcon)`
  fill: #42be65;
`;

export const UnknownIcon = styled(Unknown16)`
  margin-right: 0.5rem;
`;

export const InfoIcon = styled(Information16)`
  margin-left: 5px;
`;

export const StyledButton = styled(Button)`
  width: 50%;
  height: 1rem;
`;

export const TopRow = styled.div`
  display: flex;
  width: auto;
  flex-direction: row;
  max-height: 40px;
  grid-area: top-row;
`;

export const BottomRow = styled.div`
  display: flex;
  flex-direction: row;
  grid-area: bottom-row;
  align-items: center;
  justify-content: space-between;
  @media (max-width: 700px) {
    flex-direction: column;
    align-items: inherit;
    flex-flow: wrap;
  }
`;

export const SetupDiv = styled.div`
  display: flex;
  flex-direction: column;
  @media (max-width: 700px) {
    flex-direction: row;
  }
`;

export const SetupItem = styled.div`
  cursor: pointer;
  display: flex;
  margin: 1rem 1rem 0rem 1rem;
  h5 {
    margin: 0;
    padding: 0;
    font-size: 16px;
    @media (max-width: 1300px) {
      font-size: 14px;
    }
    @media (max-width: 900px) {
      font-size: 14px;
    }
  }
  @media (max-width: 900px) {
    margin: 0.5rem;
    flex-direction: row;
  }
  color: ${(props) => props.canupdate ? props.canupdate : "black"};
`;

export const LeftColumn = styled.div`
  grid-area: left-column;
  display: flex;
  flex-direction: column;
  width: 100%;
  @media (max-width: 900px) {
    width: max-content;
  }
  @media (max-width: 280px) {
    width: max-content;
  }
`;

export const RightColumn = styled.div`
  display: flex;
  width: 280px;
  flex-direction: column;
  // justify-content: space-around;
  height: 98%;
  @media (max-width: 900px) {
    width: auto;
    margin: auto;
    margin-top: 1rem;
  }
`;

export const StyledInlineNotification = styled(InlineNotification)`
  position: absolute;
  margin-top: -0.5rem;
  color: black;
  margin-left: 24vw;
  z-index: 16;
  @media (max-width: 800px) {
    margin-left: 12vw;
  }
  @media (max-width: 416px) {
    .bx--inline-notification__title {
      font-weight: 450;
    }
  }
`;

export const ActionContainer = styled.div`
  height: 10rem;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  @media (max-width: 900px) {
    margin: auto;
  }
`;

export const StyledSelect = styled(Select)``;

export const ProviderInfoHeader = styled.div`
  display: flex;
  @media (max-width: 900px) {
    flex-direction: column;
  }
`;

export const ProviderInfoHeaderContent = styled.div`
  @media (max-width: 900px) {
    width: 95%;
    margin: 0px;
  }
`;

export const ImageContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  width: 8rem;
  height: 8rem;
  border: 1px solid black;
  border-radius: 50%;
  overflow: auto;

  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;

  > svg {
    width: 85%;
    height: auto;
    border: 1px solid black;
    border-radius: 50%;
    fill: white;
  }
  @media (max-width: 900px) {
    margin: auto;
  }
`;

export const Head = styled.div`
  grid-area: head;
`;

export const StyledHeader = styled.h5`
  margin-top: 0.5rem;
  margin-bottom: 0.3rem;
`;

export const InfoWrapper = styled.div`
  margin: 1rem;
`;
export const StyledLocation16 = styled(Location16)`
  color: blue;
  margin-right: 8px;
`;

export const BadgeDiv = styled.div`
  grid-area: badges;
  margin-left: 1rem;
`;

// Dropdown
export const ButtonOption = styled(Button)`
  padding: 1rem;
  @media screen and (min-width: 600px){
    padding: 0.5rem 1rem;
  }
`;

export const ButtonOptionContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 1rem;
  @media screen and (min-width: 600px){
    grid-template-columns: 1fr 1fr;
  }
  @media screen and (min-width: 900px){
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
`;

export const StyledModal = styled(Modal)`
  .bx--modal-header__heading {
    font-weight:600;
  }
  .bx--modal-content {
    padding-right: 1rem;
  }
  .bx--btn--primary {
    background-color: #0f62fe;
  }
  .bx--btn--disabled {
    background-color: #c0c0c0;
    color: #8d8d8d;
  }
`;

export const MinHeightModal = styled(StyledModal)`
  .bx--modal-content {
    min-height: 300px;
  }
`;

export const WellbeingImg = styled.img`
  height: 1.5rem;
  margin-right: 0.75rem;
  @media (max-width: 500px) {
    height: 1rem;
    margin-right: 0.2rem;
  }
  @media (max-width: 1100px){
    height: 1.2rem;
    margin-right: .5rem;
  }
`

export const WellBeingSelectWrapper = styled.div`
  transition: 0.2s;
  &:hover {
    cursor: pointer;
    opacity: 1 !important;
    transform: scale(1.2) !important;
  }
`;

export const WellbeingImgSelect = styled.img`
  height: 2rem;
  width: 2rem;
  @media (min-width: 500px) {
    height: 3rem;
    width: 3rem;
  }
  @media (min-width: 1100px){
    height: 4rem;
    width: 4rem;
  }
`

export const CheckboxStyled = styled(Checkbox)`
  margin-left: 0.75rem;
  @media (max-width: 500px) {
    margin-right: 0.2rem;
  }
  @media (max-width: 1100px){
    margin-right: .5rem;
  }
`


export const StyledPill = styled.div`
  display: flex;
  width: 9rem;
  margin-top: 0.5rem;
  padding: 0.3rem 0.5rem 0.3rem 0.5rem;
  color: white;
  border-radius: 50rem;
  background-color: #5c5c5c;
  align-items: center;
  @media screen and (max-width: 768px) {
    margin-top: 0.25rem;
    font-size: 10px;
    width: 9rem;
    span {
      font-size: 10px;
    }
  }
  span {
    font-size: 11px;
  }
`;

// Connection
export const ConnectionText = styled.h6`
  display: flex;
  align-items: center;
  gap: 3px;
`;

export const ToggleConnectionText = styled.h5`
  @media screen and (max-width: 732px){
    text-align: right;
  }
`;

export const ConnectionTextMobile = styled.span`
  display: block;
  padding-top: 0.1rem;
  @media screen and (min-width: 701px){
    display: none;
  }
`;

export const ConnectionDesktopWrapper = styled(ActionContainer)`
  margin-top: ${(props) => !props.isSetupComplete ? "0.5rem" : "0"};
  // cursor: pointer;
  flex-direction: column;
  align-items: flex;
  @media screen and (max-width: 700px){
    display: none;
  }
`;

export const StyledEdit = styled(Edit16)`
  margin-left: .5rem;
  margin-top .25rem;
`