import React, { useState } from "react";

const useModal = () => {
  const [showModal, setShowModal] = useState(false);

  const toggle = () => {
    setShowModal(!showModal);
  };
  const handleVideoClose = (id) => {
    let sVM = document.getElementById(id);
    let sVMSource = sVM.src;
    sVM.src = "";
    sVM.src = sVMSource;
    toggle();
  };
  return [showModal, toggle, handleVideoClose];
};

export default useModal;
