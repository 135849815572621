import React from "react";
import styled from "styled-components";
import { Modal, TextArea } from "carbon-components-react";

export const FormInputContainer = styled.div`
  margin-bottom: 1rem;
`;

export const FormEmailContainer = styled.div`
  & > div > .bx--label--disabled {
    color: #000000 !important;
  }

  & > div input {
    color: #000000 !important;
  }

  margin-bottom: 1rem;
`;

export const FormInputAreaContainer = styled.div``;

export const ModalContentWrapper = styled.div`
  @media (max-height: 720px) {
    max-height: 55vh;
  }
`;

export const StyledModal = styled(Modal)`
  .bx--modal-container {
    align-items: initial !important;
  }
  ${(props) => !props.isConnected && 
    `.bx--modal-content {
      margin-top: 0;
      padding-top: 0;
    }`
  }
`;

export const Content = styled.div`
  position: relative;
  display: flex;
  flex-flow: column nowrap;
  overflow-y: auto;
  width: 100%;
  padding-bottom: 1.5rem;
`;

export const DisclaimerWrapper = styled.div`
  font-size: 12px;
  color: #525252;
  margin-bottom: 1rem;
`;
