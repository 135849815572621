import React, { useEffect, useState, useRef, lazy, Suspense, useContext } from 'react';
import { Loading } from 'carbon-components-react';
import { useHistory } from 'react-router-dom';
import kit from 'ich-ui-kit';
import S from './feedback.module.scss';
import { lazyRetry } from '../../utils';

import { useFeedbackCMS } from './cms';

const Quiz = lazy(() => lazyRetry(() => import('./components/Quiz'), 'feedbackQuizComponent'));
const Success = lazy(() => lazyRetry(() => import('./components/Success'), 'feedbackSuccessComponent'));
const Denied = lazy(() => lazyRetry(() => import('./components/Denied'), 'feedbackDeniedComponent'));

const FeedbackPage = () => {
    // Refs
  const qnRef = useRef(null);
  // Hooks
  const history = useHistory();
  const { apiService } = useContext(kit.ApiContext);
  const { user } = useContext(kit.UserContext);
  // States
  const [page, setPage] = useState(1);
  const [ctr, setCtr] = useState(1);
  const [loading, setLoading] = useState(false);
  const [answer, setAnswer] = useState({});

  const { content } = useFeedbackCMS();

  useEffect(() => {
    if (user && user.isSuper) {
      setPage(0);
    }
  }, [user]);

  useEffect(() => {
    if (content && content?.items?.initAnswers) {
      setAnswer(content?.items?.initAnswers);
    }
  }, [content]);

  useEffect(() => {
    const initFeedbackProgress = async () => {
      let initState = JSON.parse(sessionStorage.getItem('feedback-progess'));
      if(initState && initState.ctr){
        setCtr(initState.ctr);
      };
      if (initState && initState.answers) {
        setAnswer(initState.answers);
      }
    };
    let isMounted = true;
    if (isMounted) {
      initFeedbackProgress();
    }

    return () => {
      isMounted = false;
    };
  }, []);

  const addAnswer = async (answers, navCtr) => {
    setAnswer(answers);
    let tempCtr = navCtr === content?.items?.length || navCtr > content?.items?.length || navCtr === 0 ? ctr : navCtr;
    let storedData = { ctr: tempCtr, answers };
    sessionStorage.setItem('feedback-progess', JSON.stringify(storedData));
  };

  const submitHandler = async (ans) => {
    setLoading(true);
    try {
      const response = await apiService.post(
        '/providerstaff/feedback', { assessmentId: content?.assessmentId || null, feedback: { ...ans }}
      );
      if (response && response.ok) {
        // success message
        sessionStorage.removeItem('feedback-progess');
        setPage(2);
      }
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  const nextQuestion = (ans) => {
    if (ctr === content?.items?.length) {
      submitHandler(ans);
    } else {
      setCtr(state => state + 1);
    }
  };

  const prevQuestion = () => {
    if ((page === 1 && ctr === 1) || page === 2) {
      if (history?.goBack) {
        history?.goBack();
      } else {
        history.push('/')
      }
    } else if (page === 2) {
      setPage(1);
    } else {
      setCtr(state => state - 1);
    }
  };


  const Content = () => {

    return (
      <div className={S.contentContainer}>
        {loading && <Loading />}
        {page === 0 && (
          <Suspense fallback={<Loading />}>
            <Denied />
          </Suspense>
        )}
        {page === 1 && ( 
          <Suspense fallback={<Loading />}>
            <Quiz
              ctr={ctr}
              cmsData={{}}
              qnRef={qnRef}
              answer={answer}
              addAnswer={addAnswer}
              prevQuestion={prevQuestion}
              nextQuestion={nextQuestion}
              items={content?.items}
              initialHeader={content?.initialHeader}
              initialSubHeader={content?.initialSubHeader}
            />
          </Suspense>
        )}
        {page === 2 && (
          <Suspense fallback={<Loading />}>
            <Success cms={content} close={prevQuestion} />
          </Suspense>
        )}
      </div>
    );
  };

  return (
    <div data-id="provider-feedback-container" className={S.container}>
        <Content />
    </div>
  );
};

export default FeedbackPage;
