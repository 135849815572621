import { useEffect, useState } from "react";
import { useStrapi } from "../../hooks/useStrapi";
import { formTypes } from './utils';

const parseItems = (items) => {
  if (!items || !items?.length) {
    return null;
  }
  return items.map(({ attributes }) => {
    if (attributes) {
      return {
        heading: attributes.heading || '',
        displayText: attributes.displayText || '',
        footerText: attributes.footerText || '',
        responseType: attributes.responseType || '',
        progressText: attributes.progressText || '',
        answerId: attributes.answerId || '',
        continueOnSelect: attributes.continueOnSelect || '',
        required: attributes.required || '',
        imgUrl: attributes.imgUrl || '',
        imgAtl: attributes.imgAtl || '',
        maxLength: attributes.maxLength || '',
        responseOptions: attributes.responseOptions || [],
        extended: attributes.extended?.data?.attributes || null,
      }
    }
    return {}
  });
};

export const useFeedbackCMS = () => {
  const init = {
    assessmentId: 1,
    enabled: false,
    initialHeader: 'TogetherNow Connected Provider Experience Survey',
    initialSubHeader: 'This anonymous survey should take you less than 5 minutes to complete.',
    successHeader: 'Thanks for completing the survey! We look forward to reviewing your responses so that we can continuously improve.',
    successSubHeader: 'Did you know? You can submit improvement ideas using the "Feedback" button in MyWayfinder at any time! It is on the bottom of the page no matter where you are in MyWayfinder.',
    successImgUrl: './icons/smiley-face.png',
    successImgAtl: 'Success Icon',
    items: [
      {
        id: 1,
        heading: "Question 1 of 6",
        displayText: 'Select the statement below that best describes your role.',
        footerText: null,
        responseType: formTypes.RADIO,
        progressText: null,
        answerId: 'resp_1',
        continueOnSelect: true,
        required: false,
        imgUrl: null,
        imgAtl: null,
        responseOptions: [
          {
            id: 1,
            displayText: 'I work in Education.',
            value: 'education'
          },
          {
            id: 2,
            displayText: 'I work in Emergency Services.',
            value: 'emergency services'
          },
          {
            id: 3,
            displayText: 'I work in Government.',
            value: 'government'
          },
          {
            id: 4,
            displayText: 'I work in Healthcare.',
            value: 'healthcare'
          },
          {
            id: 5,
            displayText: 'I work in Human Services.',
            value: 'human services'
          },
          {
            id: 6,
            displayText: 'I work in Legal Services.',
            value: 'legal services'
          },
          {
            id: 7,
            displayText: 'I work in Workforce Development.',
            value: 'workforce development'
          },
          {
            id: 8,
            displayText: 'I work for 211/LIFE LINE.',
            value: '211'
          },
          {
            id: 9,
            displayText: 'None of these.',
            value: 'none'
          },
        ],
      },
      {
        id: 2,
        heading: "Question 2 of 6",
        displayText: 'Overall, how satisfied are you with your experience of being a connected provider in the TogetherNow network?',
        footerText: null,
        imgUrl: './TogetherNow-logo.png',
        imgAtl: 'TogetherNow Logo',
        continueOnSelect: false,
        responseType: formTypes.BUTTON_ARRAY,
        responseOptions: [
          {
            id: 1,
            displayText: 'Very Unsatisfied',
            value: 1
          },
          {
            id: 2,
            displayText: 'Unsatisfied',
            value: 2
          },
          {
            id: 3,
            displayText: 'Neutral',
            value: 3
          },
          {
            id: 4,
            displayText: 'Satisfied',
            value: 4
          },
          {
            id: 5,
            displayText: 'Very Satisfied',
            value: 5
          }
        ],
        progressText: 'TogetherNow',
        answerId: 'resp_2',
        required: false,
        extended: {
          answerId: 'resp_2_ext',
          responseType: formTypes.TEXT,
          displayText: 'Please tell us why you feel this way.',
          required: false,
          continueOnSelect: false,
          maxLength: 2500
        },
      },
      {
        id: 3,
        heading: "Question 3 of 6",
        displayText: 'I feel that being part of TogetherNow and using MyWayfinder helps me deliver high quality service to individuals and families.',
        footerText: null,
        imgUrl: './icons/stars.png',
        imgAtl: 'Star Rating Icon',
        responseType: formTypes.BUTTON_ARRAY,
        responseOptions: [
          {
            id: 1,
            text: "Strongly Disagree",
            displayText: 'Strongly Disagree',
            value: 1
          },
          {
            id: 2,
            text: "Disagree",
            displayText: 'Disagree',
            value: 2
          },
          {
            id: 3,
            text: "Neither Agree nor Disagree",
            displayText: 'Neither Agree nor Disagree',
            value: 3
          },
          {
            id: 4,
            text: "Agree",
            displayText: 'Agree',
            value: 4
          },
          {
            id: 5,
            text: "Strongly Agree",
            displayText: 'Strongly Agree',
            value: 5
          }
        ],
        progressText: 'High Quality Service',
        answerId: 'resp_3',
        continueOnSelect: true,
        required: false
      },
      {
        id: 4,
        heading: "Question 4 of 6",
        displayText: 'Overall, how satisfied are you with your experience of using MyWayfinder?',
        footerText: null,
        imgUrl: './icons/laptop-screen.png',
        imgAtl: 'Laptop Icon',
        responseType: formTypes.BUTTON_ARRAY,
        responseOptions: [
          {
            id: 1,
            text: "Very Unsatisfied",
            displayText: 'Very Unsatisfied',
            value: 1
          },
          {
            id: 2,
            text: "Unsatisfied",
            displayText: 'Unsatisfied',
            value: 2
          },
          {
            id: 3,
            text: "Neutral",
            displayText: 'Neutral',
            value: 3
          },
          {
            id: 4,
            text: "Satisfied",
            displayText: 'Satisfied',
            value: 4
          },
          {
            id: 5,
            text: "Very Satisfied",
            displayText: 'Very Satisfied',
            value: 5
          }
        ],
        progressText: 'MyWayfinder',
        answerId: 'resp_4',
        continueOnSelect: true,
        required: false
      },
      {
        id: 5,
        heading: "Question 5 of 6",
        displayText: 'The information in Mywayfinder helps me to connect with or provide individuals and families with appropriate services to meet their needs.',
        footerText: null,
        imgUrl: './icons/puzzle.png',
        imgAtl: 'Puzzle Icon',
        responseType: formTypes.BUTTON_ARRAY,
        responseOptions: [
          {
            id: 1,
            text: "Strongly Disagree",
            displayText: 'Strongly Disagree',
            value: 1
          },
          {
            id: 2,
            text: "Disagree",
            displayText: 'Disagree',
            value: 2
          },
          {
            id: 3,
            text: "Neither Agree nor Disagree",
            displayText: 'Neither Agree nor Disagree',
            value: 3
          },
          {
            id: 4,
            text: "Agree",
            displayText: 'Agree',
            value: 4
          },
          {
            id: 5,
            text: "Strongly Agree",
            displayText: 'Strongly Agree',
            value: 5
          }
        ],
        progressText: 'Appropriate Service',
        answerId: 'resp_5',
        continueOnSelect: true,
        required: false
      },
      {
        id: 6,
        heading: "Question 6 of 6",
        displayText: 'With MyWayfinder, I\'m able to save time looking for information and can more easily find what I need to deliver efficient service to individuals and families.',
        footerText: null,
        imgUrl: './icons/time.png',
        imgAtl: 'Clock Icon',
        responseType: formTypes.BUTTON_ARRAY,
        responseOptions: [
          {
            id: 1,
            text: "Strongly Disagree",
            displayText: 'Strongly Disagree',
            value: 1
          },
          {
            id: 2,
            text: "Disagree",
            displayText: 'Disagree',
            value: 2
          },
          {
            id: 3,
            text: "Neither Agree nor Disagree",
            displayText: 'Neither Agree nor Disagree',
            value: 3
          },
          {
            id: 4,
            text: "Agree",
            displayText: 'Agree',
            value: 4
          },
          {
            id: 5,
            text: "Strongly Agree",
            displayText: 'Strongly Agree',
            value: 5
          }
        ],
        progressText: 'Efficient Service',
        answerId: 'resp_6',
        continueOnSelect: true,
        required: false
      },
      {
        id: 7,
        heading: "Thanks for completing the survey!",
        displayText: '(Optional) We\'d love to hear your success stories or any challenges that you\'ve faced while helping individuals and families. Please use this section to share more about your experience.',
        footerText: 'Click "Submit" to complete the survey and receive a badge!',
        responseType: formTypes.TEXT,
        progressText: null,
        imgUrl: './icons/smiley-face.png',
        imgAtl: 'Feedback Icon',
        answerId: 'resp_7',
        continueOnSelect: false,
        required: false,
        maxLength: 2500
      }
    ],
    initAnswers: {
      "resp_1": '',
      "resp_2": '',
      "resp_2_ext": '',
      "resp_3": '',
      "resp_4": '',
      "resp_5": '',
      "resp_6": '',
      "resp_7": ''
    }
  };

  const { data, loading } = useStrapi({
    cms: `assessments`,
    populate: 'populate[0]=items&populate[1]=items.responseOptions&populate[2]=items.extended&filters[assessment_id][$eq]=1&filters[enabled][$eq]=true'
  });

  const [content, setContent] = useState(init);

  useEffect(() => {
    if (loading) {
      setContent(init);
      return;
    }
    if (data) {
      setContent((state) => ({
        assessmentId: data.assessment_id || state.assessmentId,
        enabled: data.enabled || state.enabled,
        initialHeader: data.initialHeader || state.initialHeader,
        initialSubHeader: data.initialSubHeader || state.initialSubHeader,
        successHeader: data.successHeader || state.successHeader,
        successSubHeader: data.successSubHeader || state.successSubHeader,
        successImgUrl: data.successImgUrl || state.successImgUrl,
        successImgAtl: data.successImgAtl || state.successImgAtl,
        items: data.items?.data ? parseItems(data.items?.data) : state.items,
        initAnswers: {
          "resp_1": '',
          "resp_2": '',
          "resp_2_ext": '',
          "resp_3": '',
          "resp_4": '',
          "resp_5": '',
          "resp_6": '',
          "resp_7": ''
        }
      }));
    }
  }, [data]);

  return { content };
};
