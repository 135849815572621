import { useState, useEffect } from "react";
import * as S from "../Questions.styles";
import { Dropdown } from "carbon-components-react";
import styled from "styled-components";
// import { TextContainer } from "./Employment2";

const Employment3 = ({
  cms,
  domain,
  width,
  setAllowContinue,
  employmentResponse,
  setEmploymentResponse,
  setFeedbackIndex,
  setOverallIndex
}) => {
  const buttonLabels = [
    "Looking for employment",
    "Not working at a job or business and not looking for employment",
    "Currently working at a job or business and not looking for employment",
    "Not sure"
  ];

  const getTranslatedLabel = (label) => {
    switch (label) {
      case "Looking for employment":
        return cms.employement.btn_looking;
      case "Not working at a job or business and not looking for employment":
        return cms.employement.btn_not_working;
      case "Currently working at a job or business and not looking for employment":
        return cms.employement.btn_currently_working;
      case "Not sure":
        return cms.employement.btn_not_sure;
    }
  };

  const [buttonSelection, setButtonSelection] = useState();
  useEffect(() => {
    if (employmentResponse.q3.answer !== " ") {
      setButtonSelection(employmentResponse.q3.answer);
      setAllowContinue(false);
    } else {
      setAllowContinue(true);
    }
  }, []);

  const nextQuestion = () => {
    if (employmentResponse.q1.answer === "Not Employed") {
      setFeedbackIndex((index) => index + 3);
      setOverallIndex((index) => index + 3);
    } else {
      setFeedbackIndex((index) => index + 1);
      setOverallIndex((index) => index + 1);
    }
  };

  return (
    <S.TextContainer>
      {/* <h4>Question 3 of 11</h4> */}
      <h5>{cms.employement.question_3}</h5>
      <S.Wrapper>
        {width < 769 ? (
          <S.DropdownWrapper>
            <S.StyledDropdown
              ariaLabel="Dropdown"
              id="carbon-dropdown-example"
              items={buttonLabels}
              label={buttonSelection || "Select option..."}
              onChange={(e) => {
                setAllowContinue(false);
                setButtonSelection(e.selectedItem);
                setEmploymentResponse((state) => ({
                  ...state,
                  q3: {
                    text: "3 Are you currently looking for employment?",
                    answer: e.selectedItem
                  }
                }));
                nextQuestion();
              }}
            />
          </S.DropdownWrapper>
        ) : (
          <S.ButtonGroup>
            {buttonLabels.map((label, i) => {
              let bcolor;
              {
                buttonSelection === label ? (bcolor = true) : (bcolor = false);
              }
              return (
                <S.StyledButton
                  key={i}
                  theme={{
                    bcolor: bcolor,
                    width: "150px",
                    padding: "1rem",
                    margin: "1rem"
                  }}
                  kind="tertiary"
                  onClick={() => {
                    setAllowContinue(false);
                    setEmploymentResponse((state) => ({
                      ...state,
                      q3: {
                        text: "3 Are you currently looking for employment?",
                        answer: label
                      }
                    }));
                    setButtonSelection(label);
                    nextQuestion();
                  }}
                >
                  {getTranslatedLabel(label)}
                </S.StyledButton>
              );
            })}
          </S.ButtonGroup>
        )}
      </S.Wrapper>
    </S.TextContainer>
  );
};

export default Employment3;
