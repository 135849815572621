import { useState, useEffect } from "react";
import styled from "styled-components";
import { Select, SelectItem, TextInput } from "carbon-components-react";
import { useDropdownItems } from "../../../../../components/360ProfileForm/dropdown/dropdownCMS";
import { languageItems } from "../../../../../components/360ProfileForm/dropdown/language.items";
import { useCompleteProfile } from "../../../../cms";
import { StyledDropdownWrapper, StyledSelect, StyledTextInput, StyledWrapper, TextInputWrapper } from "../AboutMe.styled";
import { isValidName, shouldAutoComplete } from "../../../../../../../utils";

const LanguageSpoken = ({ setAboutMeResponse, setDisableButton }) => {
  const { uiCMS: cms } = useCompleteProfile();

  const { list: listLanguage } = useDropdownItems({
    init: languageItems,
    link: "list-language"
  });

  const language = listLanguage().reduce((accu, el) => {
    accu[el.key] = el;
    return accu;
  }, {});

  const [otherLanguage, setOtherLanguage] = useState(false);
  const [textInputValue, setTextInputValue] = useState("");
  const [isInvalid, setIsInvalid] = useState(false);

  useEffect(() => {
    setDisableButton(true);
  }, []);

  const handleSelectChange = (e) => {
    if (textInputValue !== "") {
      setTextInputValue("");
    }
    if (e.target.value !== "default") {
      if (e.target.value === "Other") {
        setOtherLanguage(true);
      } else {
        setOtherLanguage(false);
        setAboutMeResponse((state) => ({ ...state, language: e.target.value }));
      }
    } else {
      if (otherLanguage) setOtherLanguage(false);
    }
    setDisableButton(
      e.target.value === "Other" || e.target.value === "default"
    );
  };
  const handleTextChange = (e) => {
    setIsInvalid(e.target.value !== "" && !isValidName(e.target.value));
    setTextInputValue(e.target.value);
    setDisableButton(!isValidName(e.target.value));
    setAboutMeResponse((state) => ({
      ...state,
      language: e.target.value
    }));
  };
  return (
    <StyledWrapper style={{flexDirection: 'column'}}>
      <StyledDropdownWrapper>
        <StyledSelect
          id="language"
          labelText=" "
          defaultValue="default"
          onChange={(e) => handleSelectChange(e)}
        >
          <SelectItem
            text={language.default.show}
            value="default"
            id="default"
          />
          <SelectItem
            text={language.english.show}
            value="English"
            id="english"
          />
          <SelectItem
            text={language.spanish.show}
            value="Spanish"
            id="spanish"
          />
          <SelectItem
            text={language.sign_language.show}
            value="American Sign Language"
            id="asl"
          />
          <SelectItem
            text={language.swahili.show}
            value="Swahili"
            id="swahili"
          />
          <SelectItem text={language.somali.show} value="Somali" id="somali" />
          <SelectItem text={language.french.show} value="French" id="french" />
          <SelectItem text={language.arabic.show} value="Arabic" id="arabic" />
          <SelectItem text={language.karen.show} value="Karen" id="karen" />
          <SelectItem text={language.kareni.show} value="Kareni" id="kareni" />
          <SelectItem text={language.nepal.show} value="Nepal" id="nepal" />
          <SelectItem
            text={language.burmese.show}
            value="Burmese"
            id="burmese"
          />
          <SelectItem
            text={language.not_listed.show}
            value="Other"
            id="other"
          />
        </StyledSelect>
      </StyledDropdownWrapper>
      {otherLanguage && (
        <TextInputWrapper>
          <StyledTextInput
            labelText={cms.language.label}
            placeholder={cms.language.placeholder}
            value={textInputValue}
            invalid={isInvalid}
            invalidText={cms.language.error}
            onChange={(e) => {
              handleTextChange(e);
            }}
            autoComplete={shouldAutoComplete}
          />
        </TextInputWrapper>
      )}
    </StyledWrapper>
  );
};

export default LanguageSpoken;
