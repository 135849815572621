import React from "react";
import * as S from "./faq.styled";
import { MarkdownCard } from "../../components/MarkdownCard";

import { useFaqCMS } from "./cms";

const Faq = () => {
  const { faq } = useFaqCMS();

  return (
    <S.Container>
      <S.BannerContainer
        title={
          faq.background_image
            ? `${process.env.REACT_APP_STRAPI_ENDPOINT}${
                window.innerWidth < 400
                  ? faq.background_image?.small?.url
                  : window.innerWidth < 600
                  ? faq.background_image?.medium?.url
                  : faq.background_image?.large?.url
              }`
            : ""
        }
      >
        <S.Heading>{faq.heading}</S.Heading>
        <S.Overlay />
      </S.BannerContainer>

      <S.Items>
        {faq?.items
          ? faq.items.map((item) => {
              return (
                <MarkdownCard
                  key={item.id}
                  id={item.id}
                  heading={item.title}
                  paragraphs={item.content}
                />
              );
            })
          : undefined}
      </S.Items>
    </S.Container>
  );
};

export default Faq;
