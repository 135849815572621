import styled from "styled-components";
import kit from "ich-ui-kit";

import { useContext, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router";
import { useDimension } from "../../../../../hooks/useDimension";
import useQuery from "../../../../../hooks/useQuery";
import { useCompleteProfile } from "../../cms";

import { Button } from "carbon-components-react";
import { ChevronRight24 } from "@carbon/icons-react";

const Step1of3 = ({ setControllerIndex, setOverallIndex, setAboutMeIndex, clientID, clientInfo }) => {
  const { stepsCMS: cms } = useCompleteProfile();
  const { windowSize } = useDimension();

  const { user } = useContext(kit.UserContext);
  const { apiService } = useContext(kit.ApiContext);

  const location = useLocation();

  const [loading, setLoading] = useState(false);

  return (
    <Wrapper>
      <TextContainer>
        <TextWrapper>
          <h4>
            Complete {clientInfo?.firstName || location?.state?.clientFirstName} {clientInfo?.lastName || location?.state?.clientLastName}
            {(clientInfo?.firstName || location?.state?.clientFirstName) && "'s"} Profile
          </h4>
        </TextWrapper>
        <TextWrapper style={{ width: "75%" }}>
          <p>
            Let's finish setting up {clientInfo?.firstName}
            {(clientInfo?.firstName || location?.state?.clientFirstName) && "'s"} profile. It should take about{" "}
            <strong>5 minutes</strong> to complete.
          </p>
        </TextWrapper>
      </TextContainer>
      <ButtonWrapper>
        <StyledButton
          onClick={() => {
            setControllerIndex((prev) => prev + 1);
            setOverallIndex((p) => p + 1);
          }}
        >
          {cms.btn_continue}
          {windowSize.width > 768 && <StyledChevronIcon />}
        </StyledButton>
      </ButtonWrapper>
    </Wrapper>
  );
};

export default Step1of3;

const Wrapper = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-top: 2.5rem;
  padding-left: 0.5rem;
  padding-right: 10rem;
  @media screen and (min-height: 880px) and (min-width: 1800px) {
    padding-top: 4rem;
  }
  @media screen and (max-width: 600px) {
    padding: 0;
  }
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-left: 0.8rem;
  height: 35%;
  @media screen and (min-height: 880px) and (min-width: 1800px) {
    height: 30%;
    margin-top: 1rem;
  }
  @media screen and (max-width: 768px) {
    margin-top: 3rem;
    height: 45%;
    justify-content: space-around;
  }
`;

const TextWrapper = styled.div`
  margin-top: 2rem;
  p {
    font-size: 18px;
    font-weight: 400;
  }
  h4 {
    font-weight: 500;
    line-height: 1.3;
  }
  @media screen and (min-height: 880px) and (min-width: 1600px) {
    p {
      font-weight: 400;
      font-size: 20px;
    }
  }
  @media screen and (max-width: 768px) {
    margin-top: 0;
  }
`;

const ButtonWrapper = styled.div`
  width: fit-content;
  margin-top: 5rem;
  margin-left: 0.8rem;
  @media screen and (min-height: 880px) {
    margin-top: 7rem;
  }
  @media screen and (max-width: 768px) {
    display: flex;
    width: 100%;
    margin-left: 0;
    justify-content: center;
  }
`;
const StyledButton = styled(Button)`
  padding-right: 12px;
  @media screen and (max-width: 768px) {
    width: 20rem;
    justify-content: center;
  }
`;

const StyledChevronIcon = styled(ChevronRight24)`
  margin-left: 2rem;
  height: 20px;
`;
