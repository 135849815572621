import React from "react";
import ReactMarkdown from "react-markdown";
import * as S from "./item.styled";
import { generateClickToCallLink } from '../../../utils';

const determineLink = (line) => {
  if (generateClickToCallLink(line)) {
    return (
      <S.Text>
        <a href={generateClickToCallLink(line)}>{line}</a>
      </S.Text>
    );
  } else {
    return (
      <S.Text>
        <ReactMarkdown>{line}</ReactMarkdown>
      </S.Text>
    );
  }
};

const renderContent = (content) => {
  if (!content) {
    return '';
  } else if (content.includes('\n')) {
    return content.split('\n').map((line, i) => {
      return determineLink(line);
    });
  } else {
    return determineLink(content);
  }
};

export const Item = ({ label, heading, paragraphs }) => {
  return (
    <S.Container aria-label={label} style={{ whiteSpace: "pre-wrap" }}>
      {heading ? (
        <S.Heading aria-label={label}>{heading}</S.Heading>
      ) : undefined}
      {paragraphs !== "" || paragraphs !== null ? (
          renderContent(paragraphs)
      ) : undefined}
    </S.Container>
  );
};
