import React, { useState, useEffect, useContext } from 'react';
import { Search32, Filter32, Checkmark20, Save20 } from '@carbon/icons-react';
import { InlineLoading } from "carbon-components-react";
import kit from 'ich-ui-kit'
import { SearchBy as EnhancedSearch } from '../../components/EnhancedSearch';
import SavedSearchesModal from "../../pages/resources/newcomponents/SavedSearchesModal";
import * as ES from './searchBox.styled';
import { shouldAutoComplete } from '../../utils';
import { useMWFSearch, addSavedSearch } from '../../store/MWFSearchContext';
import { cleanZipFormatting, extractZipFromSearch } from "../../utils";

const defaultCms = {
  heading_service_directory: "Service Directory",
    btn_clear_filters: "Clear Filters",
    placeholder_search: "Search by service, provider, or need",
    placeholder_zipcode: "Zip code (00000)",
    label_recommended: "Recommended Services",
    label_get_support: "Get Support",
    badge_connected: "Connected Provider",
    badge_cn_resource: "360 CN Resource",
    badge_on_care_team: "On Care Team",
    label_service_capacity: "Service Capacity",
    badge_almost_capacity: "Almost at Capacity",
    badge_no_capacity: "No Capacity",
    badge_out_of_network: "Out of Network",
    capacity_unknown: "Capacity Unknown",
    text_num_org:
      "Number of Organizations Actively Participating in the MyWayfinder Network.",
    label_age_ranges: "Age Ranges",
    label_age_child: "Child",
    label_age_youth: "Youth",
    label_age_young_ad: "Young Adult",
    label_age_adult: "Adult",
    label_age_older: "Older Adult",
    label_languages: "Languages",
    label_distance: "Distance",
    label_distance_none: "None",
    label_distance_within: "Within [x] miles",
    label_distance_less: "Less than [x] miles",
    label_distance_more: "More than [x] miles",
    badge_avl_now: "Available Now",
    btn_availability: "Availability",
    btn_recommended: "Recommended",
    btn_bookmark: "Bookmarked",
    text_recommended_services:
      "Doing a check-in can help us better understand your needs and recommend resources that can support you",
    label_checkin: "Check-In",
    btn_list: "List",
    btn_map: "Map",
    btn_filter: "Filter",
    label_bookmarked_services: "Bookmarked Services",
    label_services_matching: "Services matching your search",
    label_no_search: "No Search Results",
    label_search_services: "Search for Services",
    btn_search: "Search",
    label_resource_map: "Map",
    btn_view_more: "View more",
    btn_view_less: "View less",
    confidential_address_text: "Address is confidential, please call for more information",
    list_actions: {
      id: 3,
      heading: "Actions",
      label: "Actions",
      item: [
        {
          id: 7,
          text: "Add to Care Team",
          value: "Add to Care Team",
          key: "add_care_team"
        },
        {
          id: 8,
          text: "Create Referral",
          value: "Create Referral",
          key: "create_referral"
        },
        {
          id: 9,
          text: "Send Needs Request",
          value: "Send Needs Request",
          key: "send_needs_request"
        },
        {
          id: 10,
          text: "Bookmark",
          value: "Bookmark",
          key: "bookmark"
        },
        {
          id: 15,
          text: "Remove Bookmark",
          value: "Remove Bookmark",
          key: "remove_bookmark"
        },
        {
          id: 18,
          text: "Remove from care team",
          value: "Remove from care team",
          key: "remove_care_team"
        }
      ]
    }
};

export const SearchBox = ({
  value,
  onChange,
  onSubmit,
  setSearchTerm,
  enableDropdown = false,
  domainList = [],
  cms = defaultCms,
  handleFilter,
  enableSavedSearch = true,
}) => {

  const [show, setShow] = useState(false);
  const [savedSearchesIsOpen, setSavedSearchesIsOpen] = useState(false);
  const [zip, setZip] = useState(extractZipFromSearch(value));
  const [wfSearchQuery, setWfSearchQuery] = useState(cleanZipFormatting(value));
  const [searchQueryWithZip, setSearchQueryWithZip] = useState("");
  const [{addSavedSearchesIsLoading, addSavedSearchesError, savedSearchesUpdated}, dispatch] = useMWFSearch();

  const { apiService } = useContext(kit.ApiContext);
  const { user } = useContext(kit.UserContext);

  const submitQuerySearch = () => {
    value = searchQueryWithZip;
    return onSubmit;
  }


  const handleSaveSearchTerm = async () => {
    if(value){
      value = cleanZipFormatting(value);
      addSavedSearch(dispatch, { apiService, user, addSavedSearchesIsLoading, term: value })
    }
  }

  const getSavedSearchButtonIcon = () => {
    if (addSavedSearchesIsLoading) {
      return <InlineLoading style={{minHeight:"auto"}} /> 
    } else if (savedSearchesUpdated) {
      return <Checkmark20 />
    }
    return <Save20 />
  }

  const setCategory = ({ domain }) => {
    if (domain) {
      onChange(domain);
      setShow(false);
    }
  };

  return (
    <>
    {enableSavedSearch && 
      <SavedSearchesModal 
        open={savedSearchesIsOpen}
        close={setSavedSearchesIsOpen}
        zip={zip}
        handleSearch={setSearchTerm}
      />
    }
    <ES.Form id="directory-search-form" onSubmit={submitQuerySearch()}>
      <ES.TextboxContainer
        onBlur={(e) => {
          if (!e.currentTarget.contains(e.relatedTarget)) {
            setTimeout(() => {
              setShow(false);
            }, 150);
          }
        }}
      >
        <ES.FieldSet style={{ zIndex: 5 }}>
          <ES.FlexRow>
            <ES.Search />
            <ES.TextInput
              id='service-directory-search-input'
              data-id='service-directory-search-input'
              aria-label='Search'
              autoComplete={shouldAutoComplete}
              labelText=''
              onFocus={(e) => {
                setShow(true);
              }}
              invalidText={'Search must not be empty'}
              placeholder={cms.placeholder_search}
              onChange={(e) => {
                setWfSearchQuery(e.target.value);
                setSearchQueryWithZip(e.target.value + "<zip>" + zip + "</zip>");
                onChange(e.target.value + "<zip>" + zip + "</zip>");
              }}
              onKeyPress={(e) => {
                if (e.key === 'Enter') {
                  setShow(false);
                }
              }}
              value={wfSearchQuery}
            />
            {enableSavedSearch &&
              <>
                <ES.SaveSearchButton disabled={value?.length === 0 || addSavedSearchesIsLoading} onClick={handleSaveSearchTerm}>
                  {getSavedSearchButtonIcon()}
                </ES.SaveSearchButton>
                <ES.SavedSearchesButton
                  kind="ghost"
                  onClick={() => setSavedSearchesIsOpen(true)}
                >
                  Saved Searches
                </ES.SavedSearchesButton>
                <ES.SavedSearchesButtonMobile
                  kind="ghost"
                  onClick={() => setSavedSearchesIsOpen(true)}
                >
                  Saved
                </ES.SavedSearchesButtonMobile>
              </>
            }
          </ES.FlexRow>
          {enableDropdown && domainList?.length && show ? (
            <EnhancedSearch
              domains={domainList}
              setCategory={setCategory}
              enableSubCategory={false}
              setInputType={() => {}}
              setSelected={() => {}}
              setDisplay={() => {}}
            />
          ) : undefined}
        </ES.FieldSet>
        <ES.FieldSet style={{ zIndex: 2 }}>
          <ES.Location />
          <ES.TextInput
            id='service-directory-zip-input'
            data-id='service-directory-zip-input'
            aria-label='Zipcode'
            labelText=''
            autoComplete={shouldAutoComplete}
            placeholder={
              // zipcodeLoading ? 'Loading...' : cms.placeholder_zipcode
              cms.placeholder_zipcode
            }
            maxLength={5}
            // disabled={zipcodeLoading && !zipcode}
            // disabled={true}
            onChange={(e) => {
              const re = /^[0-9\b]+$/;
              if (re.test(e.target.value) || e.target.value === '') {
                setZip(e.target.value);
                setSearchQueryWithZip(wfSearchQuery + "<zip>" + e.target.value + "</zip>");
                onChange(wfSearchQuery + "<zip>" + e.target.value + "</zip>");
              }
            }}
            onKeyPress={(e) => {
              if (e.key === 'Enter') {
                e.preventDefault();
                setShow(false);
              }
            }}
            value={zip}
            // value={'14609'}
            invalidText="Invalid Zip Code"
          />
        </ES.FieldSet>
      </ES.TextboxContainer>
      <ES.BtnMedVW
        data-id={`service-directory-search-btn`}
        hasIconOnly
        renderIcon={Search32}
        iconDescription="Search"
        type="submit"
        form="directory-search-form"
      />
      <ES.FilterBtnMedVW
        data-id={`service-directory-filters-btn`}
        hasIconOnly
        renderIcon={Filter32}
        iconDescription="Filter"
        kind="ghost"
        onClick={handleFilter}
      />
      <ES.MobileButtonWrapper>
        <ES.BtnSmallVW
          data-id={`service-directory-search-btn-sm`}
          renderIcon={Search32}
          type="submit"
          form="directory-search-form"
        >
          {cms.btn_search}
        </ES.BtnSmallVW>
        <ES.BtnSmallVW
          data-id={`service-directory-filters-btn-sm`}
          renderIcon={Filter32}
          kind="ghost"
          onClick={handleFilter}
        >
          {/* {cms.btn_search} */}
          Filters
        </ES.BtnSmallVW>
      </ES.MobileButtonWrapper>
    </ES.Form>
    </>
  );
};
