import React from "react";
import * as M from "./emptyloading.styled";
import * as S from "../details/ReferralDetails.styles"
import { SkeletonText, Row, Grid, Column, DataTableSkeleton, PaginationNav } from "carbon-components-react";
const EmptyLoading = () => {
  return (
    <S.StyledCard>
      <Grid>
        <Row>
            <Column xs={16} lg={8}>
              <div style={{ paddingTop: "2rem" }}>
                <SkeletonText heading paragraph lineCount={3} />
              </div>
            </Column>
        </Row>
        <Row>
          <S.HorizontalRule />
        </Row>
        <br />
        <Column style={{ marginBottom: "3rem" }}>
          <Row>
            <h4>Referral Details</h4>
          </Row>
          <br />
          <Row style={{ justifyContent: "center" }}>
              <div style={{ width: "100%" }}>
                <SkeletonText paragraph lineCount={3} />
              </div>
          </Row>
          <Row>
          <S.HistoryContainer>
              <S.ResponsiveH5>Referral History</S.ResponsiveH5>
              <DataTableSkeleton
                  showHeader={false}
                  showToolbar={false}
                  columnCount={2}
                  rowCount={2}
                />
          </S.HistoryContainer>
          <PaginationNav
              page={1}
            />
          </Row>
          <Row>
            <S.ResponsiveH5>
                Referral Reason:<SkeletonText heading lineCount={1} />
            </S.ResponsiveH5>
          </Row>
        </Column>
        </Grid>  
      </S.StyledCard>
  );
};

export default EmptyLoading;
