import styled from "styled-components";
import { Button, Tag } from "carbon-components-react";
import { motion } from "@carbon/motion";
import { Link } from "react-router-dom";

export const Card = styled.div`
  width: 95%;
  height: 10rem;
  margin: auto;
  margin-top: 1rem;
  background-color: white;
  padding: 1rem;
  box-shadow: 0px 0px 6px 3px rgba(0, 0, 0, 0.05);
  transition: 0.3s;
  border-radius: 5px; /* 5px rounded corners */
  :hover {
    background-color: aliceblue;
  }

  @media (max-width: 900px) {
    width: 95%;
    height: 18rem;
  }
`;

export const FeedContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 5rem;
  background-color: #d8d8d8;
  width: 100%;
  height: 100%;
  overflow: auto;
`;

// export const CardBadge = styled.div``;

export const FlexFeedCard = styled(Card)`
  //min-height: 25%;
  // background-color: #c2c5b6;
  background-color: white;
  height: auto;
  max-width: 600px;
  &:not(:first-child) {
    margin-top: 1rem;
  }
  margin-bottom: 0rem;
  padding: 0rem 0rem 1rem 2rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  //overflow: hidden;

  @media (max-width: 900px) {
    padding: 1rem;
    width: 95%;
    height: auto;
  }
`;

export const CardRight = styled.div`
  display: flex;
  flex-direction: column;
  align-items: end;
  width: 50%;
  p {
    margin-right: 2rem;
  }
`;

export const RecCardRight = styled.div`
  display: flex;
  flex-direction: column;
  align-items: end;
  p {
    margin-right: 2rem;
  }
`;

// TODO - Build a custom card for every type of notification.
export const ReferCard = styled(FlexFeedCard)`
  display: flex;
  flex-direction: row;
  //min-height: 25%;
  max-width: 50%;
  width: 35%;
  margin-top: 1rem;
  margin-bottom: 0rem;
  padding: 0.5rem;

  @media (max-width: 900px) {
    width: 95%;
    height: 18rem;
  }
`;

// export const FlexFeedChild = styled.div`
//   &:first-child {
//     flex: 2;
//     overflow-wrap: anywhere;
//   }
//   &:last-child {
//     flex: 1;
//     display: flex;
//     flex-direction: column;
//     overflow-wrap: anywhere;
//   }
// `;

export const TagWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const RedTag = styled(Tag)`
  background-color: red;
  color: white;
`;

export const NewBadgeCard = styled(FlexFeedCard)``;
export const RecommendedCard = styled(FlexFeedCard)``;
export const MessageCard = styled(FlexFeedCard)``;

// export const FeedCard = styled(Card)`
//   min-height: 25%;
//   height: auto;
//   width: 35%;
//   margin-top: 2rem;
//   padding: 0.5rem;

//   display: grid;
//   grid-template-rows: auto;
//   grid-template-columns: auto;
//   grid-template-areas:
//     "title title title title title"
//     "expl expl expl badge badge"
//     "info info info badge badge"
//     "buttons buttons buttons buttons buttons";

//   @media (max-width: 900px) {
//     display: grid;
//     grid-template-areas:
//       "title"
//       "expl"
//       "info"
//       "badge"
//       "buttons";
//   }
// `;

export const CardTitle = styled.h4`
  grid-area: title;
  margin: 1.5rem 0rem;
`;

// export const CardTitle = styled.h5`
//   grid-area: title;
// `;

export const CardExplanation = styled.p`
  grid-area: expl;
  margin: 0.5rem 0rem;
  // overflow-wrap: anywhere;
`;

export const CardInfo = styled.p`
  color: #4d4d4d;
  font-size: 0.75rem;
  margin-bottom: 1.5rem;
  grid-area: info;
  margin-top: 0.5rem;
  // overflow-wrap: anywhere;
`;

// export const CardTextGray = styled.p`
//   color: gray;
// `;

export const CardTextGraySmall = styled.p`
  color: gray;
  font-size: small;
`;

export const CardTextGrayXSmall = styled.p`
  color: gray;
  font-size: x-small;
`;

export const CardButtonsWrapper = styled.div`
  display: flex;

  && button:first-of-type {
    margin-right: 1rem;
  }

  @media (max-width: 900px) {
    justify-content: space-between;

    && button:first-of-type {
      margin-right: 0rem;
    }
  }

  @media (max-width: 300px) {
    justify-content: space-between;
    flex-direction: column;

    && button:first-of-type {
      margin-bottom: 1rem;
    }
  }
`;

export const CardButton = styled(Button)`
  margin-top: 0.5rem;
  margin-right: 0.5rem;
  grid-area: buttons;
  margin: 0.1rem;
  margin-bottom: 0.25rem;
  padding: 0rem auto 0rem auto;
  font-size: x-small;
  text-align: center;
  justify-content: center;
  text-justify: center;
  box-shadow: 2px 3px 6px 0 rgba(0, 0, 0, 0.05);
  transition: 0.3s;
  border-radius: 25px;
`;

// export const CardBadgeWrapper = styled.div`
//   grid-area: badge;
//   flex: auto;
//   flex-direction: column;
//   align-self: center;
//   display: flex;
//   align-items: center;
//   justify-content: center;
// `;

export const CardBadge = styled.img`
  height: 60px;
  width: 60px;
`;

export const FeedPanel = styled.div`
  width: auto;
  display: flex;
  padding: 1rem;
  width: 100%;
  overflow: auto;
  @media ${(props) => props.theme.breakpoints.xs} {
    justify-content: center;
  }
`;

export const FeedButton = styled(Link)`
  position: relative;
  text-decoration: none;
  padding: 1rem;
  border: 1px solid rgba(0, 0, 0, 0.5);
  border-radius: 25px;
  min-width: 130px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all;
  transition-timing-function: ${motion.standard};
  transition-duration: 110ms;
  ${(props) => {
    if (props.theme.isActive) {
      return `color: #fff;background: ${props.theme.colors.accent.mediumBlue};`;
    } else {
      return `color: #000;background: #fff;`;
    }
  }}
  &:not(:last-child) {
    margin-right: 0.75rem;
  }
  &:hover {
    background: ${(props) => props.theme.colors.accent.mediumBlue};
    color: #fff;
  }
`;

export const NotificationNumber = styled.div`
  flex: 1;
  font-size: 18;
`;

export const NotificationContainer = styled.div`
  position: absolute;
  top: -5px;
  right: -5px;
  background-color: red;
  color: white;
  padding: 5px;
  border-radius: 30px;
  width: 30px;
  height: 30px;
  text-align: center;
  display: flex;
  flex-direction: column;
  cursor: pointer;
`;

export const Padding = styled.div`
  flex: ${(props) => props.size};
`;

const Container = styled.div`
  position: absolute;
  top: -0.5rem;
  right: -0.25rem;
  background: red;
  width: ${(props) => {
    if (props.width > 999) {
      return "40px";
    } else if (props.width > 99) {
      return "30px";
    } else {
      return "20px";
    }
  }};
  height: ${(props) => {
    if (props.width > 999) {
      return "28px";
    } else if (props.width > 99) {
      return "24px";
    } else {
      return "20px";
    }
  }};
  border-radius: 100%;
  display: grid;
  place-content: center;
  color: #ffffff;
  font-size: 0.75rem;
  font-weight: 500;
`;

export const Notification = (props) => (
  <Container width={props.quantity}>{props.quantity}</Container>
);

// TODO - Build a custom card for every type of notification.

// The above TODO is not necessary anymore since code refactor
// Instead, make any new cards in Card Types using a similar layout
// as the previous created cards

// Commented code below can probably be deleted

// export const ReferCard = styled(FlexFeedCard)`
//   display: flex;
//   flex-direction: row;
//   //min-height: 25%;
//   width: 35%;
//   margin-top: 1rem;
//   margin-bottom: 0rem;
//   padding: 0.5rem;

//   @media (max-width: 900px) {
//     width: 95%;
//     height: 18rem;
//   }
// `;

// export const AccountCreatedCard = styled(FlexFeedCard)`
//   display: flex;
//   flex-direction: row;
//   //min-height: 25%;
//   height: auto;
//   width: 35%;
//   margin-top: 1rem;
//   margin-bottom: 0rem;
//   padding: 1rem;

//   @media (max-width: 900px) {
//     width: 95%;
//     height: 18rem;
//   }
// `;
// export const CareTeamCard = styled(FlexFeedCard)`
//   display: flex;
//   flex-direction: row;
//   width: 35%;
//   margin-top: 1rem;
//   margin-bottom: 0rem;
//   padding: 0.5rem;

//   @media (max-width: 900px) {
//     width: 95%;
//     height: 18rem;
//   }
// `;

// export const ProviderInfoCard = styled(Card)`
//   display: grid;
//   grid-template-rows: auto 5rem;
//   grid-template-columns: 60% 40%;
//   grid-template-areas:
//     "left-column right-column"
//     "badges badges";
//   @media (max-width: 900px) {
//     display: grid;
//     grid-template-rows: auto auto;
//     grid-template-columns: 100%;
//     grid-template-areas:
//       "left-column"
//       "badges"
//       "right-column";
//   }
// `;

// export const NewBadgeCard = styled(FlexFeedCard)``;
// export const RecommendedCard = styled(FlexFeedCard)``;
// export const MessageCard = styled(FlexFeedCard)``;

// export const FeedCard = styled(Card)`
//   min-height: 25%;
//   height: auto;
//   width: 35%;
//   margin-top: 2rem;
//   padding: 0.5rem;

//   display: grid;
//   grid-template-rows: auto;
//   grid-template-columns: auto;
//   grid-template-areas:
//     "title title title title title"
//     "expl expl expl badge badge"
//     "info info info badge badge"
//     "buttons buttons buttons buttons buttons";

//   @media (max-width: 900px) {
//     display: grid;
//     grid-template-areas:
//       "title"
//       "expl"
//       "info"
//       "badge"
//       "buttons";
//   }
// `;

export const CardImage = styled.img`
  border-radius: 100%;
  margin: 0.5rem;
`;

export const ClearButton = styled.div`
  padding: 0.5rem;
  // color: #d8d8d8;
  :hover {
    cursor: pointer;
    background-color: #e5e5e5;
  }
`;
