import { useState, useEffect } from "react";
import * as S from "../Questions.styles";
import { Dropdown } from "carbon-components-react";
import styled from "styled-components";
// import { TextContainer } from "./Employment2";

const Employment5 = ({
  cms,
  domain,
  width,
  setAllowContinue,
  employmentResponse,
  setEmploymentResponse,
  setFeedbackIndex,
  setOverallIndex
}) => {
  const buttonLabels = [
    "Yes, often",
    "Yes, sometimes",
    "No",
    "I am not currently pursuing education"
  ];

  const getTranslatedLabel = (label) => {
    switch (label) {
      case "Yes, often":
        return cms.employement.btn_yes_often;
      case "Yes, sometimes":
        return cms.employement.btn_yes_sometimes;
      case "No":
        return cms.employement.btn_no;
      case "I am not currently pursuing education":
        return cms.employement.btn_not_pursuing;
      default:
        return "undefined";
    }
  };

  const [buttonSelection, setButtonSelection] = useState();
  useEffect(() => {
    if (employmentResponse.q5.answer === " ") {
      setAllowContinue(true);
    } else {
      setButtonSelection(employmentResponse.q5.answer);
      setAllowContinue(false);
    }
  }, []);

  return (
    <S.TextContainer>
      <h5>{cms.employement.question_5}</h5>
      <S.Wrapper>
        {width < 769 ? (
          <S.DropdownWrapper>
            <S.StyledDropdown
              ariaLabel="Dropdown"
              id="carbon-dropdown-example"
              items={buttonLabels}
              label={buttonSelection || "Select option..."}
              onChange={(e) => {
                setAllowContinue(false);
                setButtonSelection(e.selectedItem);
                setEmploymentResponse((state) => ({
                  ...state,
                  q5: {
                    text: "5 Does your employment interfere with school attendance?",
                    answer: e.selectedItem
                  }
                }));
                setFeedbackIndex((index) => index + 1);
                setOverallIndex((index) => index + 1);
              }}
            />
          </S.DropdownWrapper>
        ) : (
          <S.ButtonGroup>
            {buttonLabels.map((label, i) => {
              let bcolor;
              {
                buttonSelection === label ? (bcolor = true) : (bcolor = false);
              }
              return (
                <S.StyledButton
                  key={i}
                  theme={{ bcolor: bcolor, width: "125px" }}
                  kind="tertiary"
                  onClick={() => {
                    setAllowContinue(false);
                    setEmploymentResponse((state) => ({
                      ...state,
                      q5: {
                        text: "5 Does your employment interfere with school attendance?",
                        answer: label
                      }
                    }));
                    setButtonSelection(label);
                    setFeedbackIndex((index) => index + 1);
                    setOverallIndex((index) => index + 1);
                  }}
                >
                  {getTranslatedLabel(label)}
                </S.StyledButton>
              );
            })}
          </S.ButtonGroup>
        )}
      </S.Wrapper>
    </S.TextContainer>
  );
};

export default Employment5;
