import React, { useState } from "react";
import styled from "styled-components";
import S from "./customProgressBar.module.scss";

const CustomProgressBar = ({
  classNameOuter = S.outer,
  classNameInner = S.inner,
  numItems,
  totalNumItems,
  showPercentageText = true,
}) => {
  //   const [backgroundColor, setBackgroundColor] = useState("#ffff");
  //   const [color, setColor] = useState(false);
  return (
    <div className={`${S.container} ${classNameOuter}`}>
      <div
        className={classNameInner}
        style={{ width: `${(numItems / totalNumItems) * 100}%` }}
      >
        {showPercentageText ? `${numItems}/${totalNumItems}` : ``}
      </div>
    </div>
  );
};

export default CustomProgressBar;
