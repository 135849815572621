import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Button } from "carbon-components-react";
import {
  EarthFilled16,
  Location16,
  Person16,
  PhoneFilled16,
  Time16
} from "@carbon/icons-react";
import DOMPurify from "dompurify";
import {
  parseToArray,
  displayAddress,
  formatUrl,
  parseHtml,
  generateClickToCallLink
} from "../../../../utils";

const ProviderInformation = ({ service = {}, incrementTab }) => {
  const [width, setWidth] = useState(window.innerWidth);
  const provURL = formatUrl(service.url);

  const isMobile = 500;

  useEffect(() => {
    const handleResizeWindow = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleResizeWindow);
    return () => {
      window.removeEventListener("resize", handleResizeWindow);
    };
  }, []);

  return (
    <MaxWidthContainer>
      <StyledHeader>Overview:</StyledHeader>
      <div
        dangerouslySetInnerHTML={{
          __html: DOMPurify.sanitize(service.overview, {
            FORBID_ATTR: ["style"]
          })
        }}
      ></div>
      {service.serviceArea && (
        <>
          <StyledHeader>Service Area:</StyledHeader>
          <p>{parseHtml(service.serviceArea)}</p>
        </>
      )}
      {service.eligibility && (
        <>
          <StyledHeader>Eligibility:</StyledHeader>
          <p>{parseHtml(service.eligibility)}</p>
        </>
      )}
      {service.requiredDocs && (
        <>
          <StyledHeader>Required Documents:</StyledHeader>
          <p>{parseHtml(service.requiredDocs)}</p>
        </>
      )}
      {service.fees && (
        <>
          <StyledHeader>Fees:</StyledHeader>
          <p>{parseHtml(service.fees)}</p>
        </>
      )}
      {service.applicationProcess && (
        <>
          <StyledHeader>Application Process:</StyledHeader>
          <p>{parseHtml(service.applicationProcess)}</p>
        </>
      )}
      {service.accessibility && (
        <>
          <StyledHeader>Accessibility:</StyledHeader>
          {!service.accessibility ? (
            <p>No information available at this time</p>
          ) : !service.accessibility.includes("|") ? (
            <p>{service.accessibility}</p>
          ) : (
            parseToArray(service.accessibility).map((val) => {
              return <p>{val}</p>;
            })
          )}
        </>
      )}
      {service.moreInformation && (
        <>
          <StyledHeader>More Information:</StyledHeader>
          <p>{parseHtml(service.moreInformation)}</p>
        </>
      )}
      <div style={{ paddingTop: "1em" }} id="info-list">
        {service?.address && (
          <>
            <InfoWrapper>
              <StyledLocation16 />
              <span>{displayAddress(service)}</span>
            </InfoWrapper>
          </>
        )}
        {service.hours && (
          <>
            <InfoWrapper>
              <StyledTime16 />
              <span>{service.hours}</span>
            </InfoWrapper>
          </>
        )}
        {provURL && (
          <>
            <InfoWrapper>
              <StyledEarthFilled16 />
              <span>
                <a target="_blank" href={provURL} rel="noreferrer noopener">
                  {service.url}
                </a>
              </span>
            </InfoWrapper>
          </>
        )}
        {service.phone && (
          <>
            <InfoWrapper>
              <StyledPhoneFilled16 />
              {generateClickToCallLink(service?.phone) ?
                <a href={generateClickToCallLink(service?.phone)}>{service?.phone}</a>
                :
                <span>{service.phone}</span>
              }
            </InfoWrapper>
          </>
        )}
        {service.contact && (
          <InfoWrapper>
            <StyledPerson16 />
            <span>{service.contact}</span>
          </InfoWrapper>
        )}
        <br />
        <StyledButton 
          data-id={`create-referral-continue-btn`}
          onClick={() => incrementTab()}
        >
          Continue
        </StyledButton>
      </div>
    </MaxWidthContainer>
  );
};

export default ProviderInformation;

const InfoWrapper = styled.div`
  margin-bottom: 1rem;
`;

const StyledLocation16 = styled(Location16)`
  color: blue;
  margin-right: 8px;
`;

const StyledTime16 = styled(Time16)`
  color: blue;
  margin-right: 8px;
`;

const StyledEarthFilled16 = styled(EarthFilled16)`
  color: blue;
  margin-right: 8px;
`;

const StyledPhoneFilled16 = styled(PhoneFilled16)`
  color: blue;
  margin-right: 8px;
`;

const StyledPerson16 = styled(Person16)`
  color: blue;
  margin-right: 8px;
`;

const ProviderHeaderWrapper = styled.div`
  padding-bottom: 1em;
`;
const StyledHeader = styled.h5`
  margin-top: 0.5rem;
  margin-bottom: 0.3rem;
`;

const StyledButton = styled(Button)`
  max-width: 100%;
  width: 100%;
  margin-bottom: 1rem;
`;

const MaxWidthContainer = styled.div`
  max-width: 500px;
  margin: 0 auto;
`;
