import { useState } from "react";
import {
    OverflowMenuItem
} from "carbon-components-react";

const LabelDropdown = ({onClick, clientId, rowNum, text, removeLabel}) => {
    const [isLabelClicked, setIsLabelClicked] = useState(false);

    return (
        <>
            <OverflowMenuItem
                style={{ color: "rgba(0,145,179,1)", backgroundColor: "#fff" }}
                itemText={text}
                onClick={() => setIsLabelClicked(!isLabelClicked)}
                // onMouseEnter={() => setIsLabelClicked(true)}
                onMouseLeave={() => setIsLabelClicked(false)}
            />
            {isLabelClicked && (
                <div 
                    style={{width: "100%"}} 
                    onMouseLeave={() => setIsLabelClicked(false)}
                    onMouseEnter={() => setIsLabelClicked(true)}
                >
                    <ul style={{textAlign: "left"}}>
                        <li 
                            style={{ 
                                padding: "0.5rem 2rem", 
                                backgroundColor: "#CB0000",
                                color: "#fff",
                                fontWeight: "600" 
                            }}
                            onClick={(e) => {
                                onClick(e.target.textContent, clientId, rowNum);
                                setIsLabelClicked(false);
                            }}
                        >
                            Red
                        </li>
                        <li 
                            style={{ 
                                padding: "0.5rem 2rem", 
                                backgroundColor: "#DAD600",
                                color: "#fff",
                                fontWeight: "600" 
                            }}
                            onClick={(e) => {
                                onClick(e.target.textContent, clientId, rowNum);
                                setIsLabelClicked(false);
                            }}                        
                        >
                            Yellow
                        </li>
                        <li 
                            style={{ 
                                padding: "0.5rem 2rem", 
                                backgroundColor: "#1C7B00",
                                color: "#fff",
                                fontWeight: "600" 
                            }}
                            onClick={(e) => {
                                onClick(e.target.textContent, clientId, rowNum);
                                setIsLabelClicked(false);
                            }}
                        >
                            Green
                        </li>
                    </ul>
                </div>
            )}
            {text === 'Edit Label' && (
                <OverflowMenuItem
                    style={{ color: "rgba(0,145,179,1)", backgroundColor: "#fff" }}
                    itemText="Remove Label"
                    onClick={() => {
                        removeLabel(clientId, rowNum);
                        setIsLabelClicked(false);
                    }}
                />
            )}
        </>
    )
}

export default LabelDropdown;