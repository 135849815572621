import React, { useState, useContext, useEffect } from "react";
import {
  AssessmentsPageContainer,
  AssessmentContainer,
  AssessmentsHeader,
  AssessmentHeaderContent,
  AssessmentDateDiv,
  StyledTextInput,
  StyledInputDiv,
  BlueBackLink,
  BackLinkContainer,
  LeftColumn,
  RightColumn,
  SummaryDiv,
  BulletItemDiv,
  BulletItem,
  StyledDotMark,
  RecommendedResourceDiv,
  AssessmentsQandADiv,
  SmileyFaceDiv,
  RedSmileyImg,
  OrangeSmileyImg,
  YellowSmileyImg,
  LightGreenSmileyImg,
  GreenSmileyImg,
  SliderContainer,
  InnerSliderContainer,
  StyledSlider,
  ButtonSetContainer,
  StyledInlineNotification
} from "./components/static.jsx";
import {
  RecommendedResources,
} from "./components/printablePageStatic.jsx";
import { determineAssessmentSummaryColor, shouldAutoComplete } from "../../utils";
import useQuery from "../../hooks/useQuery";
import { useHistory } from "react-router-dom";
import {
  Button,
  ButtonSet,
  Loading,
  StructuredListWrapper,
  StructuredListHead,
  StructuredListRow,
  StructuredListCell,
  StructuredListBody
} from "carbon-components-react";
import { Launch20 } from "@carbon/icons-react";
import kit from "ich-ui-kit";
import {
  monthsArray,
  assessmentQuestions,
} from "./components/utils";

const AssessmentsPage = () => {
  const query = useQuery();
  const assessmentId = query.get("id");
  const patientId = query.get("patientId");
  const [loading, setLoading] = useState(!!assessmentId);
  const [questions, setQuestions] = useState(assessmentQuestions);
  const [summary, setSummary] = useState({
    overall: "GRAY",
    health: "GRAY",
    education: "GRAY",
    economic: "GRAY",
    safety: "GRAY"
  });
  const [recommendedResources, setRecommendedResources] = useState([]);
  const [date, setDate] = useState(new Date());
  const [secondaryButtonText, setSecondaryButtonText] = useState("Cancel");
  const { apiService } = useContext(kit.ApiContext);
  const { user } = useContext(kit.UserContext);
  const history = useHistory();
  const [isSubmitted, setSubmitted] = useState(false);
  const [isSubmittedModal, setModalSubmitted] = useState(false);
  const [isLoggedIn, setLoggedIn] = useState(false);
  const [values, setValues] = useState({ zip: "" });
  const [zip, setZip] = useState("");
  useEffect(() => {
    if (assessmentId) {
      const getAssessmentData = async () => {
        try {
          const data = await apiService.get(
            `/assessments/${assessmentId}&patientId=${patientId}&assumedId=${user.superAssumedRole?.leadId || ""}`
          );
          if (data) {
            setSummary(data.scoreSummary);
            setRecommendedResources(data.recommendedResources);
            setQuestions(data.responses);
            setDate(data.date);
            setZip(data.zipCode);
          }
        } catch (err) {
          console.log(err);
        }
        setLoading(false);
      };

      getAssessmentData();
    } else {
      questions.forEach((question) => (question.answer = 3));
    }
  }, [assessmentId]);

  useEffect(() => {
    if (Object.keys(user).filter((key) => key !== "notifications").length) {
      setLoggedIn(true);
    }
  }, [user]);

  const handleZipChange = (e) => {
    //e.preventDefault();
    const regEx = /^[0-9\b]+$/;
    let preValue = e.target.value;

    if (e.target.value === "" || regEx.test(e.target.value)) {
      setValues((values) => ({ ...values, [e.target.id]: e.target.value }));
      return true;
    } else {
      e.target.value = preValue.substring(0, preValue.length - 1);
    }
    //const { name, value } = e.target;
    //setValues((values) => ({ ...values, [e.target.id]: e.target.value }));
  };

  const day = new Date(date).getDate();
  const month = new Date(date).getMonth();
  const year = new Date(date).getFullYear();

  const DateDisplay = () => {
    let monthAdjusted = false;
    let dayAdjusted = false;
    if (month < 9) {
      monthAdjusted = true;
    }
    if (day < 10) {
      dayAdjusted = true;
    }

    if (monthAdjusted && dayAdjusted) {
      return (
        <p>
          0{month + 1}.0{day}.{year}
        </p>
      );
    } else if (monthAdjusted && !dayAdjusted) {
      return (
        <p>
          0{month + 1}.{day}.{year}
        </p>
      );
    } else if (!monthAdjusted && dayAdjusted) {
      return (
        <p>
          {month + 1}.0{day}.{year}
        </p>
      );
    }
    return (
      <p>
        {month + 1}.{day}.{year}
      </p>
    );
  };

  const isDisabled = () => {
    if (!user || (user && assessmentId)) return true;
    return false;
  };

  const handleChange = (value, index) => {
    let temp = questions;
    temp[index].answer = value;
    setQuestions(temp);
  };

  const handleSubmit = async () => {
    setLoading(true);
    try {
      const data = await apiService.post(
        `/assessments/client?id=${patientId}?assumedId=${user.superAssumedRole?.leadId || ""}`,
        {
          zipcode: values.zip,
          responses: questions,
          locale: 'en',
          searchIndex: process.env.REACT_APP_SEARCH_INDEX || 'resources-dev'
        }
      );
      if (data) {
        setSummary(data.score);
        setSubmitted(true);
        setRecommendedResources(data.providers);
        setSecondaryButtonText("Back");
        setLoading(false);
        setTimeout(() => {
          setSubmitted(false);
        }, 2000);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleClose = () => {
    questions.forEach((question) => (question.answer = 3));
    history.goBack();
  };

  // const PrintablePageComponent = () => {
  //   return (
  //     <PrintablePage id="printable">
  //       <DateContainer>
  //         <div>
  //           <h5>
  //             Your {monthsArray[month]} {year} Assessment
  //           </h5>
  //         </div>
  //         <DateDisplay />
  //       </DateContainer>
  //       <PageContent>
  //         <Row>
  //           <Column>
  //             <Summary>
  //               <h3>Summary</h3>
  //               <h4>
  //                 Overall: {determineAssessmentSummary(summary.overall || "")}/5
  //               </h4>
  //               <ul>
  //                 <li>
  //                   Health and Well-Being:{" "}
  //                   {determineAssessmentSummary(summary.health || "")}
  //                   /5
  //                 </li>
  //                 <li>
  //                   Education Opportunity:{" "}
  //                   {determineAssessmentSummary(summary.education || "")}/5
  //                 </li>
  //                 <li>
  //                   Economic Opportunity:{" "}
  //                   {determineAssessmentSummary(summary.economic || "")}/5
  //                 </li>
  //                 <li>
  //                   Safety and Security:{" "}
  //                   {determineAssessmentSummary(summary.safety || "")}/5
  //                 </li>
  //               </ul>
  //             </Summary>
  //           </Column>
  //         </Row>
  //         <Row>
  //           <Column>
  //             <Questions id="questions">
  //               <h3>Wellness Rankings (1-5)</h3>
  //               <ol>
  //                 {questions.map(({ text, answer }, i) => (
  //                   <li key={(i += 1)}>
  //                     {text} {answer}
  //                   </li>
  //                 ))}
  //               </ol>
  //             </Questions>
  //           </Column>
  //         </Row>
  //         <Row>
  //           <Column>
  //             <RecommendedResources id="resources">
  //               <h3>Recommended Resources</h3>
  //               <div id="listDiv" style={{ overflow: "visible" }}>
  //                 {recommendedResources.length > 0 ? (
  //                   <ul>
  //                     {recommendedResources.map((res) => (
  //                       <li>{res}</li>
  //                     ))}
  //                   </ul>
  //                 ) : (
  //                   <p>
  //                     Based on your assessment responses we will recommend
  //                     resources that can help you
  //                   </p>
  //                 )}
  //               </div>
  //             </RecommendedResources>
  //           </Column>
  //         </Row>
  //       </PageContent>
  //       <Row>
  //         <Column>
  //           <Footer>
  //             <img
  //               src="SIP_LOGO.jpg"
  //               alt="MyWayfinder LOGO"
  //               width="180px"
  //               height="180px"
  //             />
  //           </Footer>
  //         </Column>
  //       </Row>
  //     </PrintablePage>
  //   );
  // };

  // const handlePrint = () => {
  //   window.print();
  // };
  return (
    <>
      {/* <ShareAssessmentModal
        modalOpen={shareAssessment}
        setModalOpen={setShareAssessments}
        page={<PrintablePageComponent />}
      />
      <PrintablePageComponent /> */}
      <AssessmentsPageContainer>
        <AssessmentContainer>
          {loading && <Loading />}
          <AssessmentsHeader>
            <AssessmentHeaderContent>
              {isSubmitted && (
                <StyledInlineNotification
                  iconDescription={"Assessment complete notification"}
                  // timeout={0}
                  kind="success"
                  lowContrast={true}
                  title="Assessment submitted successfully"
                />
              )}
              <BackLinkContainer>
                <BlueBackLink onClick={() => handleClose()}>Back</BlueBackLink>
              </BackLinkContainer>
              <AssessmentDateDiv>
                <h5>
                  Your {monthsArray[month]} {year} Assessment
                </h5>
                <DateDisplay />
              </AssessmentDateDiv>
            </AssessmentHeaderContent>
            {/* <IconDiv>
              <IconDivContent>
                <StyledButton onClick={() => handlePrint()}>
                  <Printer32 />
                  Print
                </StyledButton>
              </IconDivContent>
              <IconDivContent>
                <StyledButton>
                  <Send32
                    onClick={() => {
                      setShareAssessments(true);
                    }}
                  />
                  Share
                </StyledButton>
              </IconDivContent>
            </IconDiv> */}
          </AssessmentsHeader>
          <LeftColumn>
            <SummaryDiv>
              <h5>Summary</h5>
              <BulletItemDiv>
                <BulletItem>
                  <StyledDotMark
                    style={{
                      color: determineAssessmentSummaryColor(summary.overall)
                    }}
                  />
                  <p>Overall Status</p>
                </BulletItem>
                <BulletItem>
                  <StyledDotMark
                    style={{
                      color: determineAssessmentSummaryColor(summary.health)
                    }}
                  />
                  <p>Health and Well-Being</p>
                </BulletItem>
                <BulletItem>
                  <StyledDotMark
                    style={{
                      color: determineAssessmentSummaryColor(summary.education)
                    }}
                  />
                  <p>Education Opportunity</p>
                </BulletItem>
                <BulletItem>
                  <StyledDotMark
                    style={{
                      color: determineAssessmentSummaryColor(summary.economic)
                    }}
                  />
                  <p>Economic Opportunity</p>
                </BulletItem>
                <BulletItem>
                  <StyledDotMark
                    style={{
                      color: determineAssessmentSummaryColor(summary.safety)
                    }}
                  />
                  <p>Safety and Security</p>
                </BulletItem>
              </BulletItemDiv>
            </SummaryDiv>
            <RecommendedResourceDiv>
              <h5>Recommended Resources</h5>
              <RecommendedResources>
                {recommendedResources.length > 0 ? (
                  <StructuredListWrapper style={{ marginBottom: "1rem" }}>
                    <StructuredListHead>
                      <StructuredListRow>
                        <StructuredListCell head={true}>
                          Service Name
                        </StructuredListCell>
                        <StructuredListCell head={true}>
                          Category
                        </StructuredListCell>
                        <StructuredListCell head={true}>
                          Action
                        </StructuredListCell>
                      </StructuredListRow>
                    </StructuredListHead>
                    <StructuredListBody>
                      {recommendedResources.map(({ id, name, category }) => (
                        <StructuredListRow key={id}>
                          <StructuredListCell>{name}</StructuredListCell>
                          <StructuredListCell>{category}</StructuredListCell>
                          <StructuredListCell style={{ cursor: "pointer" }}>
                            <a href={`/ResourcesPage?id=${id}`} target="_blank" rel="noreferrer noopener">
                              <Launch20 />
                            </a>
                          </StructuredListCell>
                        </StructuredListRow>
                      ))}
                    </StructuredListBody>
                  </StructuredListWrapper>
                ) : (
                  <p>
                    Based on your assessment responses we will recommend
                    resources that can help you.
                  </p>
                )}
              </RecommendedResources>
            </RecommendedResourceDiv>
          </LeftColumn>
          <RightColumn>
            <AssessmentsQandADiv className="qna">
              <div className="hnp">
                <h5>Assessment Questions and Answers</h5>
                <p>
                  Please rank your wellness in the following aspects of life
                </p>
              </div>
              <SmileyFaceDiv>
                <RedSmileyImg
                  title="Very Bad"
                  src="/RedFace.svg"
                  alt="red smiley face"
                />
                <OrangeSmileyImg
                  title="Bad"
                  src="/OrangeFace.svg"
                  alt="orange smiley face"
                />
                <YellowSmileyImg
                  title="Neutral"
                  src="/YellowFace.svg"
                  alt="yellow smiley face"
                />
                <LightGreenSmileyImg
                  title="Good"
                  src="/LightGreenFace.svg"
                  alt="light-green smiley face"
                />
                <GreenSmileyImg
                  title="Very Good"
                  src="/GreenFace.svg"
                  alt="green smiley face"
                />
              </SmileyFaceDiv>
              <SliderContainer>
                {questions.map(({ text, answer }, i) => (
                  <InnerSliderContainer key={i}>
                    <StyledSlider
                      labelText={text}
                      value={answer}
                      min={1}
                      max={5}
                      step={1}
                      stepMultiplier={10}
                      noValidate
                      onChange={({ value }) => handleChange(value, i)}
                      disabled={
                        !!assessmentId || secondaryButtonText === "Back"
                      }
                    />
                  </InnerSliderContainer>
                ))}
                <StyledInputDiv className="ziptext">
                  {isDisabled() ? (
                    <p>
                      <strong>Zip Code: </strong>
                      {zip}
                    </p>
                  ) : (
                    <StyledTextInput
                      id="zip"
                      labelText="In order to receive recommended resources, please enter your zipcode"
                      placeholder="Zip Code (5 digits)"
                      maxLength={5}
                      onChange={handleZipChange}
                      // disabled={isDisabled() || secondaryButtonText === "Back"}
                      light
                      autoComplete={shouldAutoComplete}
                    />
                  )}
                </StyledInputDiv>
              </SliderContainer>
              <ButtonSetContainer>
                <ButtonSet>
                  <Button
                    kind="primary"
                    size="sm"
                    style={{ marginRight: "1rem", width: "50%" }}
                    onClick={() => handleClose()}
                    disabled={isDisabled()}
                  >
                    {secondaryButtonText}
                  </Button>
                  {isLoggedIn ? (
                    <Button
                      kind="primary"
                      size="sm"
                      disabled={
                        isDisabled() ||
                        secondaryButtonText === "Back" ||
                        values.zip.length !== 5
                      }
                      onClick={() => handleSubmit()}
                    >
                      Submit
                    </Button>
                  ) : (
                    <Button
                      kind="primary"
                      size="sm"
                      disabled={
                        isDisabled() ||
                        secondaryButtonText === "Back" ||
                        values.zip.length !== 5
                      }
                      onClick={() => setModalSubmitted(true)}
                    >
                      Submit
                    </Button>
                  )}
                </ButtonSet>
              </ButtonSetContainer>
            </AssessmentsQandADiv>
          </RightColumn>
        </AssessmentContainer>
      </AssessmentsPageContainer>
    </>
  );
};

export default AssessmentsPage;
