import { useState } from "react";
import { Select, SelectItem } from "carbon-components-react";
import styled from "styled-components";
import { useDropdownItems } from "../../../../../components/360ProfileForm/dropdown/dropdownCMS";
import { genderItems } from   "../../../../../components/360ProfileForm/dropdown/gender.items";
import { StyledDropdownWrapper, StyledSelect, StyledWrapper } from "../AboutMe.styled";

const GenderIdentity = ({ setAboutMeResponse, setDisableButton }) => {
  const { list: listGender } = useDropdownItems({
    init: genderItems,
    link: "list-gender-identity"
  });

  const gender = listGender().reduce((accu, el) => {
    accu[el.key] = el;
    return accu;
  }, {});

  const handleChange = (e) => {
    if (e.target.value !== "default") {
      setDisableButton(false);
      setAboutMeResponse((state) => ({
        ...state,
        genderIdentity: e.target.value
      }));
    } else {
      setDisableButton(true);
    }
  };

  return (
    <StyledWrapper>
      <StyledDropdownWrapper>
        <StyledSelect
          id="select-3"
          labelText=" "
          defaultValue="default"
          onChange={(e) => {
            handleChange(e);
          }}
        >
          <SelectItem
            text={gender.default_gender.show}
            value="default"
            id="default"
          />
          <SelectItem text={gender.male.show} value="Male" id="male" />
          <SelectItem text={gender.female.show} value="Female" id="female" />
          <SelectItem
            text={gender.non_binary.show}
            value="Non-Binary"
            id="nb"
          />
          <SelectItem text={gender.trans_man.show} value="Trans Man" id="tm" />
          <SelectItem
            text={gender.trans_woman.show}
            value="Trans Woman"
            id="tw"
          />
          <SelectItem
            text={gender.not_listed.show}
            value="Prefer to Self-Identify"
          />
          <SelectItem
            text={gender.not_answer.show}
            value="Prefer not to Answer/Data Not Collected"
          />
        </StyledSelect>
      </StyledDropdownWrapper>
    </StyledWrapper>
  );
};

export default GenderIdentity;
