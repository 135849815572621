import React from "react";
import {
    ShoppingCartClear32
  } from "@carbon/icons-react";

const Empty = () => {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        height: "100%",
        justifyContent: "center",
        flexDirection: "column"
      }}
    >
        <ShoppingCartClear32 />
      <p>Your cart is empty</p>
    </div>
  );
};

export default Empty;
