import React, { useState } from "react";

export const LocaleContext = React.createContext({});

export const LocaleProvider = ({ children }) => {
  const [locale, setLocale] = useState({
    code: "en",
    name: "English (en)"
  });
  return (
    <LocaleContext.Provider value={{ locale, setLocale }}>
      <>{children}</>
    </LocaleContext.Provider>
  );
};

export const LocaleConsumer = LocaleContext.Consumer;
