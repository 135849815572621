import {
  Button,
  DataTable,
  StructuredListSkeleton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
  ToastNotification
} from "carbon-components-react";
import html2canvas from "html2canvas";
import kit from "ich-ui-kit";
import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import InviteInput from "../../../components/Inputs/Invite";
import { QRCodeBulletin, QRCodeWrapper, TitleRow } from "../components";
import { Page } from "./dashboard.styled";
import QRCodeGen from "./qr-code-gen";

const Invite = ({ orgId }) => {
  const { user } = useContext(kit.UserContext);
  const { apiService } = useContext(kit.ApiContext);
  // Data
  const [inputVal, setInputVal] = useState("");
  const [inputType, setInputType] = useState("Email");
  const [isPhoneError, setIsPhoneError] = useState(false);
  const [isEmailError, setIsEmailError] = useState(false);
  const [inviteList, setInviteList] = useState([]);
  // Loading
  const [loading, setLoading] = useState(false);
  const [getDataLoading, setGetDataLoading] = useState(false);
  // Error Management
  const [inList, setInList] = useState(false);
  const [isPhoneNumberInvalid, setIsPhoneNumberInvalid] = useState(false);
  // Notifications
  const [successNotification, setSuccessNotification] = useState(false);
  const [errorNotification, setErrorNotification] = useState(false);

  useEffect(() => {
    const getInviteList = async () => {
      setGetDataLoading(true);
      const data = await apiService.get(`/account/invite/friends-and-family`);
      if (data?.invites && data?.invites?.length > 0) {
        const cleanData = data?.invites?.map((r) => ({
          ...r,
          invitee: r.email ? r.email : r.phone,
          datetime: new Date(r.createdAt).toLocaleString()
        }));
        setInviteList(cleanData);
        setGetDataLoading(false);
      } else {
        setGetDataLoading(false);
      }
    };
    getInviteList();
  }, []);

  useEffect(() => {
    checkIfInList(inputVal);
  }, [inputVal]);

  const rows = inviteList;

  const headers = [
    {
      key: "datetime",
      header: "Date/Time",
      isSortable: true,
      isSortHeader: true
    },
    {
      key: "invitee",
      header: "Invitee"
    }
  ];

  // Helpers
  const checkIfInList = (str) => {
    if (inviteList.some((e) => e.invitee === str)) {
      setInList(true);
      return false;
    } else {
      setInList(false);
      return true;
    }
  };

  // Profile -> Invite Users: QR Code Bulletin DL
  const downloadQRBulletin = async () => {
    // Using html2canvas to convert <QRBulletin> element to a canvas object.
    const pngUrl = await html2canvas(document.getElementById("qr-bulletin"));
    // Converting canvas to .png image.
    var bulletinImage = pngUrl.toDataURL("image/png");
    // Creating dummy invis link on top of visible "download" link.
    let downloadLink = document.createElement("a");
    downloadLink.href = bulletinImage;
    downloadLink.download = "qr-bulletin.png";
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };

  // Handlers
  const handleSubmit = async () => {
    setLoading(true);
    try {
      const data = await apiService.post(
        "/account/invite/provider/friends-and-family",
        {
          userId: user.id,
          userName: user?.aliasName || user.name || user.givenName,
          inputVal,
          inputType,
          orgId
        }
      );
      if (data.ok) {
        const datetime = new Date().toLocaleString();
        setInviteList([
          {
            id: Math.random(),
            datetime: datetime,
            invitee: inputVal
          },
          ...inviteList
        ]);
        setInputVal("");
        setLoading(false);
        setSuccessNotification(true);
        setTimeout(() => {
          setSuccessNotification(false);
        }, 3000);
      } else {
        if (data.message === "Invalid Phone") {
          setIsPhoneNumberInvalid(true);
        }
        setInputVal("");
        setLoading(false);
        setErrorNotification(true);
        setTimeout(() => {
          setErrorNotification(false);
          setIsPhoneNumberInvalid(false);
        }, 3000);
      }
    } catch (err) {
      console.warn(err);
      setLoading(false);
    }
  };
  const resetInputs = () => {
    setInputVal("");
    setIsEmailError(false);
    setIsPhoneError(false);
    setIsPhoneNumberInvalid(false);
  };
  return (
    <>
      <Page style={{ paddingTop: "0" }}>
        {successNotification && (
          <StyledToastNotification
            title="Success"
            subtitle="Your message has been sent successfully."
            kind="success"
            style={{ marginLeft: "10%" }}
          />
        )}
        {errorNotification && (
          <StyledToastNotification
            title="Error"
            subtitle="An error has occured. Please try again later."
            kind="error"
            style={{ marginLeft: "10%" }}
          />
        )}
        <TitleRow>Invite Community</TitleRow>
        <Card style={{ marginTop: "0" }}>
          <HeaderContainer>
            <HeaderTitle>Create New Connections</HeaderTitle>
            <HeaderSubtitle>
              You can invite individuals to learn about, access, and use
              MyWayfinder.
            </HeaderSubtitle>
            <HeaderNote>
              Enter the individual's name and email or phone number to send a
              notification about MyWayfinder.
            </HeaderNote>
            <HeaderNote>
              Note: They will not automatically be added to your Care Team.
            </HeaderNote>
          </HeaderContainer>

          <BodyContainer>
            <InviteInput
              setInputType={setInputType}
              setInputVal={setInputVal}
              inputType={inputType}
              handleSubmit={handleSubmit}
              loading={loading}
              inList={inList}
              inputVal={inputVal}
              resetInputs={resetInputs}
              isPhoneError={isPhoneError}
              isEmailError={isEmailError}
              setIsPhoneError={setIsPhoneError}
              setIsEmailError={setIsEmailError}
              setIsPhoneNumberInvalid={setIsPhoneNumberInvalid}
              isPhoneNumberInvalid={isPhoneNumberInvalid}
              source={"accountPage"}
            />

            {getDataLoading ? (
              <StructuredListSkeleton />
            ) : (
              <DataTable rows={rows} headers={headers}>
                {({
                  rows,
                  headers,
                  getTableProps,
                  getHeaderProps,
                  getRowProps
                }) => (
                  <Table {...getTableProps()}>
                    <TableHead>
                      <TableRow>
                        {headers.map((header) => (
                          <TableHeader
                            {...getHeaderProps({ header })}
                            isSortable={header.isSortable}
                            isSortHeader={header.isSortHeader}
                          >
                            {header.header}
                          </TableHeader>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {rows.map((row) => (
                        <TableRow {...getRowProps({ row })}>
                          {row.cells.map((cell) => (
                            <TableCell key={cell.id}>{cell.value}</TableCell>
                          ))}
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                )}
              </DataTable>
            )}
            {rows.length == 0 && !getDataLoading && (
              <NoDataText>
                Invite someone by entering their information above.
              </NoDataText>
            )}
          </BodyContainer>
        </Card>
        <QRCodeBulletin id="qr-bulletin">
          <div
            style={{
              minHeight: "40rem",
              display: "flex",
              flexDirection: "column",
              alignItems: "space-around",
              justifyContent: "center",
              width: "100%",
              background: "linear-gradient(to bottom, #0091b3 50%, #005d83 0%)"
            }}
          >
            <img src="/MWF-signup-card.png" />
          </div>
        </QRCodeBulletin>
        <Button
          style={{ cursor: "pointer", alignSelf: "center", marginTop: ".5rem" }}
          onClick={downloadQRBulletin}
        >
          Download QR Code Bulletin
        </Button>
      </Page>
    </>
  );
};

// Header
const HeaderContainer = styled.div`
  padding: 1rem 0rem;
  border-bottom: 1px solid #e0e0e0;
  @media screen and (min-width: 767px) {
    padding: 1rem;
  }
`;
const HeaderTitle = styled.h4`
  font-weight: 600;
`;
const HeaderSubtitle = styled.p``;

const HeaderNote = styled.p`
  font-size: 12px;
  color: gray;
`;

// Body
const BodyContainer = styled.div`
  padding: 1rem 0;
  @media screen and (min-width: 767px) {
    padding: 1rem;
  }
`;

// Body - Table
const NoDataText = styled.p`
  width: 100%;
  background-color: white;
  padding: 1rem;
  text-align: center;
`;

// Notifications
const StyledToastNotification = styled(ToastNotification)`
  position: absolute;
`;

const Card = styled.div`
  background-color: #fff;
  overflow: auto;
  padding: 0.25rem 1rem;
  margin: 1.25rem 0;
  border: 1px solid rgba(0, 0, 0, 0.1);
`;

export default Invite;
