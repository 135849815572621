import React, { useState, useContext } from "react";
import kit from "ich-ui-kit";
import socket from "../socket";

export const ActivityFeedContext = React.createContext([]);

export const ActivityFeedProvider = ({ children }) => {
  const { user, updateUser } = useContext(kit.UserContext);
  const { apiService } = useContext(kit.ApiContext);
  let userId = user?.superAssumedRole && user?.superAssumedRole?.leadId ? user.superAssumedRole.leadId : user.id
  const [activityFeed, setActivityFeed] = useState(
    user?.activityFeed ? [...user?.activityFeed] : []
  );
  // const [numberUnread, setNumberUnread] = useState(
  //   user.unreadFeedCount
  //     ? user.unreadFeedCount
  //     : { "care-team": 0, referral: 0 }
  // );
  const [numberUnread, setNumberUnread] = useState({ total: 0, "care-team": 0, referral: 0 });

  const subscribeToActivityFeed = () => {
    socket.emit(
      "activities:subscribe",
      { id: userId, isProvider: true },
      (data) => {
      if (data) {
        let unread = {
          total: data?.unread || 0,
          "care-team": data?.careTeamTotal || 0,
          referral: data?.serviceRequestTotal || 0
        }
        setNumberUnread(unread)
      }
      }
    );
    socket.on("activities:new", (data) => {
      setNumberUnread((prevState) => {
        let newState = {
          ...prevState,
          [data.newType]: prevState[data.newType] + 1

        };
        // newNumberUnread = newState;
        return newState;
      });
      
      if(window?.location?.pathname?.includes('/feed')) {
        getFeed(()=> {})
      }
    });
  };

  const getLatestFeed = () => {
    // socket.emit("activities:latest", { id: userId }, (data) => {
    //   updateFeed(data.activityFeed);
    //   return data.activityFeed;
    // });
  };

  const getFeed = async (cb) => {
    try {
      const data = await apiService.get(`/notifications?assumedId=${user?.superAssumedRole?.leadId || ""}`)
      updateFeed(data.notifications);
    } catch (error) {
      console.log(error)
    } finally {
      cb()
    }
  };

  const updateFeed = (updatedFeed) => {
    // Preserves activity feed in user context as well as localstorage
    setActivityFeed(updatedFeed);
    // updateUser({ ...user, activityFeed: updatedFeed });
  };

  const clearActivity = (notifid, ntype) => {
    const updatedFeed = activityFeed.filter((f) => f.id !== notifid);
    setActivityFeed(updatedFeed);
    updateUser({ ...user, activityFeed: updatedFeed });
    socket.emit("activities:clear", { 
      id: userId, 
      cardId: notifid, 
      ntype: ntype, 
      isProvider: false 
    }, updatedFeed);
  };

  const setActivityInactive = async (notifid, ntype) => {
    const updatedFeed = activityFeed.map((f) => { if (f.id === notifid) {
      return {...f, active: 0}
    } else {
      return {...f}
    }});
    setActivityFeed(updatedFeed);
    updateUser({ ...user, activityFeed: updatedFeed });
    socket.emit("activities:setinactive", {
      id: userId,
      cardId: notifid,
      ntype: ntype,
      isProvider: false
    }, (data) => {
      return;
    });
  };

  const setReferralActivityInactive = async (
    clientId,
    referralId,
    ntype,
    stype,
    currentCard
  ) => {

    const socketInactivate = (userId, cardId, ntype, isProvider) => {
      socket.emit(
        "activities:setinactive",
        { id: userId, cardId, ntype, isProvider },
        (data) => { return; }
      );
    }

    let updatedFeed = activityFeed.map((f) => {
      // collect associated referral card, as well as current card (if provided)
      if (
        (f.referralId === referralId &&
          f.type === ntype &&
          f.subType === stype) ||
          (currentCard && f.id === currentCard.id)
      ) {
        // matching referral card (and optionally current card if id is provided) is inactivated in database
        socketInactivate(userId, f.id, f.type, false)
        return { ...f, active: 0 };
      } else {
        return { ...f };
      }
    });

    // clear out card if it's an expire-warning card
    // if(currentCard && currentCard.subType === 'expire-warning') {
    //     updatedFeed = updatedFeed.filter(f => f.id !== currentCard.id)
    //     socket.emit("activities:clear", { 
    //       id: userId, 
    //       cardId: currentCard.id, 
    //       ntype: ntype, 
    //       isProvider: false 
    //     });
    //   }

    updateFeed(updatedFeed);
    updateUser({ ...user, activityFeed: updatedFeed });
  };

  const setReferralActivitiesInactive = async (
    clientId,
    referralId,
    ntype="referral"
  ) => {

    const socketInactivate = (userId, cardId, ntype, isProvider) => {
      socket.emit(
        "activities:setinactive",
        { id: userId, cardId, ntype, isProvider },
        (data) => { return; }
      );
    }

    let updatedFeed = activityFeed.map((f) => {
      // collect associated referral cards
      if (
        f.referralId === referralId &&
          f.type === ntype
      ) {
        // matching referral cards inactivated in database
        socketInactivate(userId, f.id, f.type, false)
        return { ...f, active: 0 };
      } else {
        return { ...f };
      }
    });
    updateFeed(updatedFeed);
    updateUser({ ...user, activityFeed: updatedFeed });
  };


  const clearReferralActivity = async (
    referralId,
    ntype = "referral",
    stype = "created",
    currentCard
  ) => {

    const socketClear = (userId, cardId, ntype, stype, isProvider) => {
      socket.emit("activities:clear", { 
        id: userId, 
        cardId, 
        ntype, 
        stype,
        isProvider 
      });
    }

    const updatedFeed = activityFeed.filter((f) => {
      // clear from database and filter out:
      // matching referral card and/or optionally current card
      if (
        (f.referralId === referralId &&
        f.type === ntype &&
        f.subType === stype) ||
        (currentCard && f.id === currentCard.id)
      ) {
        socketClear(userId, f.id, f.type, f.subType, false)
      }

      // filter out target referral activities from feed
      return !(
        (f.referralId === referralId &&
         f.type === ntype &&
         f.subType === stype) || 
         (currentCard && f.id !== currentCard.id)
      );
    });

    updateFeed(updatedFeed);
  };

  const readFeedType = (type) => {
    let newNumberUnread;
    if(type === 'needs-request'){
      newNumberUnread = {
        ...numberUnread,
        'needs-request': 0,
        'referral': 0
      };
    } 
    else if(type === 'referrals'){
      newNumberUnread = {
        ...numberUnread,
        'needs-request': 0,
        'referral': 0
      };
    } 
    else if (type === 'care-team'){
      newNumberUnread = {
        ...numberUnread,
        'care-team': 0,
        'access': 0,
        'service-cap': 0
      };
    } else {
      newNumberUnread = {
        ...numberUnread,
        [type]: 0
      };
    }
    setNumberUnread(newNumberUnread);
    updateUser({
      ...user,
      unreadFeedCount: newNumberUnread
    });
    socket.emit("activities:read", { id: userId, type });
  };

  const getTotalUnread = () => {
    let total = 0;
    for (const [key, val] of Object.entries(numberUnread)){
      if(key !== "total" && !isNaN(val)){
        total += val
      }
    }
    return total;
  }

  return (
    <ActivityFeedContext.Provider
      value={{
        activityFeed,
        numberUnread,
        subscribeToActivityFeed,
        readFeedType,
        clearActivity,
        clearReferralActivity,
        setActivityInactive,
        setReferralActivityInactive,
        setReferralActivitiesInactive,
        getLatestFeed,
        getTotalUnread,
        getFeed
      }}
    >
      {children}
    </ActivityFeedContext.Provider>
  );
};

export const ActivitiyFeedConsumer = ActivityFeedContext.Consumer;
