import { useState, useEffect } from "react";
import { useStrapi } from "../../../../../hooks/useStrapi";

export const maritalItems = () => {
  const init = [
    {
      id: 60,
      show: "Select Marital Status",
      text: "Select Marital Status",
      key: "default"
    },
    {
      id: 61,
      show: "Single",
      text: "Single",
      key: "single"
    },
    {
      id: 63,
      show: "Married",
      text: "Married",
      key: "married"
    },
    {
      id: 64,
      show: "Widowed",
      text: "Widowed",
      key: "widowed"
    },
    {
      id: 65,
      show: "Divorced",
      text: "Divorced",
      key: "divorced"
    },
    {
      id: 66,
      show: "Cohabitating",
      text: "Cohabitating",
      key: "cohabitating"
    },
    {
      id: 69,
      show: "Separated",
      text: "Separated",
      key: "separated"
    }
  ];

  return init;
};
